var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { attrs: { id: "ag-grid-demo" } },
        [
          _c(
            "vx-card",
            {
              staticClass: "mb-2",
              attrs: {
                title:
                  _vm.$t(_vm.resources.MailSetting.i18n) ||
                  _vm.resources.MailSetting.name
              }
            },
            [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c(
                    "vs-table",
                    {
                      attrs: { data: _vm.settingEmails },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var data = ref.data
                            return _vm._l(data, function(tr, indextr) {
                              return _c(
                                "vs-tr",
                                { key: indextr },
                                [
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: { data: data[indextr].identifier }
                                    },
                                    [_vm._v(_vm._s(data[indextr].identifier))]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: { data: data[indextr].description }
                                    },
                                    [_vm._v(_vm._s(data[indextr].description))]
                                  ),
                                  _c(
                                    "vs-td",
                                    { attrs: { data: data[indextr].toEmails } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          data[indextr].toEmails.replaceAll(
                                            ";",
                                            ", "
                                          )
                                        )
                                      )
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    [
                                      _c(
                                        "vx-tooltip",
                                        {
                                          attrs: {
                                            text:
                                              _vm.$t(_vm.resources.View.i18n) ||
                                              _vm.resources.View.name
                                          }
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.showPopUpMail(
                                                    data[indextr]
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("feather-icon", {
                                                staticClass: "w-5 h-5 mr-1",
                                                attrs: { icon: "EyeIcon" }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            })
                          }
                        }
                      ])
                    },
                    [
                      _c(
                        "template",
                        { slot: "thead" },
                        [
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Identifier.i18n) ||
                                  _vm.resources.Identifier.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Description.i18n) ||
                                  _vm.resources.Description.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Email.i18n) ||
                                  _vm.resources.Email.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Options.i18n) ||
                                  _vm.resources.Options.name
                              )
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title:
              _vm.$t(_vm.resources.Detail.i18n) || _vm.resources.Detail.name,
            active: _vm.popupActive
          },
          on: {
            "update:active": function($event) {
              _vm.popupActive = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vx-row mb-base" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 mb-2" },
              [
                _c("vs-input", {
                  attrs: {
                    type: "email",
                    "icon-pack": "feather",
                    icon: "icon-email",
                    "icon-no-border": "",
                    label:
                      _vm.$t(_vm.resources.Email.i18n) ||
                      _vm.resources.Email.name
                  },
                  model: {
                    value: _vm.email,
                    callback: function($$v) {
                      _vm.email = $$v
                    },
                    expression: "email"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/6 mb-2 mt-5" },
              [
                _c(
                  "vs-button",
                  {
                    attrs: { color: "primary", type: "filled" },
                    on: {
                      click: function($event) {
                        return _vm.updateEmail(_vm.email)
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(_vm.resources.Create.i18n) ||
                            _vm.resources.Create.name
                        ) +
                        " "
                    )
                  ]
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "vx-col w-full" },
            [
              _c(
                "vs-table",
                {
                  attrs: { data: _vm.emails },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var data = ref.data
                        return _vm._l(data, function(tr, indextr) {
                          return _c(
                            "vs-tr",
                            { key: indextr },
                            [
                              _c("vs-td", { attrs: { data: data[indextr] } }, [
                                _vm._v(_vm._s(data[indextr]))
                              ]),
                              _c(
                                "vx-tooltip",
                                {
                                  attrs: {
                                    text:
                                      _vm.$t(_vm.resources.View.i18n) ||
                                      _vm.resources.View.name
                                  }
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.updateEmail(data[indextr])
                                        }
                                      }
                                    },
                                    [
                                      _c("feather-icon", {
                                        staticClass: "w-5 h-5 mr-1",
                                        attrs: {
                                          icon: "XIcon",
                                          svgClasses: "fill-current text-danger"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        })
                      }
                    }
                  ])
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c("vs-th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.Email.i18n) ||
                              _vm.resources.Email.name
                          )
                        )
                      ]),
                      _c("vs-th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.Options.i18n) ||
                              _vm.resources.Options.name
                          )
                        )
                      ])
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }