<template>
  <div>
    <div
      v-if="tracking.length == 0"
      class="vx-col md:w-1/4 w-full mb-base mt-4"
    >
      <vs-button color="primary" type="filled" @click="StartManagement()">
        {{
          $t(resources.StartManagement.i18n) || resources.StartManagement.name
        }}
      </vs-button>
    </div>
    <div v-if="tracking.length != 0" class="mb-base mt-4">
      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <label class="vs-input--label">{{
            $t(resources.Stage.i18n) || resources.Stage.name
          }}</label>
          <v-select
            v-model="stageId"
            :clearable="false"
            :options="stageList"
            :reduce="(x) => x.id"
            label="name"
            scrollable
          />
        </div>
        <vs-input
          class="vx-col w-full md:w-1/3 mb-2 inputx"
          :label="$t(resources.Subject.i18n) || t(resources.Subject.name)"
          v-model="subject"
        />
        <div class="vx-col md:w-1/3 w-full mb-2">
          <label class="btn btn-primary" style="margin-right: 80px">{{
            $t(resources.Attachment.i18n) || resources.Attachment.name
          }}</label>
          <vs-input type="file" class="w-full" @change="onFileSelected" />
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full">
          <label>{{
            $t(resources.Remark.i18n) || resources.Remark.name
          }}</label>
          <vs-textarea
            class="w-full"
            icon-pack="feather"
            icon-no-border
            icon="icon-clipboard"
            v-model="remark"
            maxlength="9000"
          />
        </div>

        <div class="vx-col md:w-1/6 w-full mb-4">
          <ul class="vx-col w-full" style="margin-top: 20px">
            <li>
              <vs-checkbox v-model="publish">{{
                $t(resources.RemarkPublish.i18n) || resources.RemarkPublish.name
              }}</vs-checkbox>
            </li>
          </ul>
        </div>

        <div class="vx-col mb-2 mt-5">
          <vs-button
            color="primary"
            type="filled"
            class="mb-4"
            @click="changeStage()"
          >
            {{ $t(resources.Save.i18n) || resources.Save.name }}
          </vs-button>
        </div>
      </div>
    </div>
    <vx-timeline :data="comments" />
  </div>
</template>
<script>
import other from "@/assets/resources/enums/other.json";
import resources from "@/i18n/resources.js";
import vSelect from "vue-select";
import axios from "axios";
import VxTimeline from "@/components/timeline/VxTimeline";

export default {
  components: {
    vSelect,
    VxTimeline,
  },
  name: "comments",
  props: {
    purchaseId: String,
    currentStage: Number,
  },
  data() {
    return {
      comments: [],
      tracking: [],
      resources: resources,

      other: other,
      stageId: 0,
      remark: "",
      stageList: [],
      subject: "",
      publish: false,
      email: JSON.parse(localStorage.getItem("userInfo")).email,
      file: "",
    };
  },
  methods: {
    async getComments() {
      this.comments = [];
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Remarks/Search`,
        data: {
          searchKeys: {
            identifier: this.purchaseId,
            entityid: this.other.entity.purchase,
          },
          page: 1,
          rows: 20,
          language: this.$i18n.locale.toUpperCase(),
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.comments = [];
          } else {
            let response = result.data.data;
            for (var i = 0; i < response.length; i++) {
              var color = "warning";

              if (response[i].type == 0 && response[i].publish)
                color = "success";
              else if (response[i].type == 0 && !response[i].publish)
                color = "danger";

              this.comments.push({
                idx: i,
                id: response[i].id,
                color: color,
                icon: response[i].type == 1 ? "MailIcon" : "MessageSquareIcon",
                title: response[i].subject,
                desc: response[i].remark,
                time: response[i].creationDate,
                user: response[i].createdBy,
                type: response[i].type,
                url: response[i].pathUrl,
              });
            }
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    async changeStage() {
      this.$vs.loading();
      if (this.stageId != 0 && this.stageId != null) {
        await axios({
          method: "POST",
          url: `${process.env.VUE_APP_OVAPI}Tracking/createByPurchase`,
          data: {
            identifier: this.purchaseId,
            entityId: this.other.entity.purchase,
            previousStageId: this.currentStage,
            newStageId: this.stageId,
            createdBy: this.email,
          },
          headers: {
            "content-type": "application/json",
            Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
          },
        }).then(
          async (result) => {
            if (!result.data) {
              this.$vs.notify({
                title: this.$i18n.t("Alert"),
                text: this.$i18n.t("MsgNotFoundInfo"),
                color: "warning",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle",
              });
            } else {
              this.$vs.notify({
                title: this.$i18n.t("Success"),
                text: this.$i18n.t("MsgSaved") + "<br/>" + result.data.message,
                color: "success",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle",
              });
              await this.getuploadForm();
            }
          },
          (error) => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError") + "<br/>" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          }
        );
      } else {
        await this.getuploadForm();
      }
      this.$vs.loading.close();
    },

    async StartManagement() {
      this.$vs.loading();

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_OVAPI}Tracking/createByPurchase`,
        data: {
          identifier: this.purchaseId,
          entityId: this.other.entity.purchase,
          previousStageId: this.currentStage,
          newStageId: this.currentStage,
          createdBy: this.email,
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved") + "<br/>" + result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.getComments();
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },

    async getStage() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_OVAPI
        }Stage/${this.$i18n.locale.toUpperCase()}/4`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.stageList = [];
          } else {
            this.stageList = result.data;
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    onFileSelected(event) {
      this.file = event.target.files[0];
    },

    async getuploadForm() {
      let formData = new FormData();
      formData.set("identifier", this.purchaseId);
      formData.set("entityId", this.other.entity.purchase);
      formData.set("remark", this.remark);
      formData.set("subject", this.subject);
      formData.set("type", 0);
      formData.set("createdBy", this.email);
      formData.set("sender", this.email);
      formData.set("language", this.$i18n.locale.toUpperCase());
      formData.set("publish", this.publish);
      if (this.file != null && this.file != "")
        formData.append("file", this.file, this.file.Name);

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Remarks`,
        data: formData,
        headers: {
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
          "Content-Type": "multipart/form-data",
        },
      }).then(
        async (result) => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.subject = "";
            this.stageId = "";
            this.remark = "";
            this.publish = false;
            await this.getComments();
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    async getTracking() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_OVAPI
        }Tracking/${this.$i18n.locale.toUpperCase()}/${this.purchaseId}/${
          this.other.entity.purchase
        }`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.tracking = [];
          } else {
            this.tracking = result.data;
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },
  },
  async created() {
    await this.getComments();
    await this.getTracking();
    await this.getStage();
  },
};
</script>