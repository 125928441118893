<template>
  <div>
    <div id="ag-grid-demo">
      <vx-card
        class="mb-2"
        :title="$t(resources.MailSetting.i18n) || resources.MailSetting.name"
      >
        <div class="vx-col w-full">
          <vs-table :data="settingEmails">
            <template slot="thead">
              <vs-th>{{
                $t(resources.Identifier.i18n) || resources.Identifier.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.Description.i18n) || resources.Description.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.Email.i18n) || resources.Email.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.Options.i18n) || resources.Options.name
              }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].identifier">{{
                  data[indextr].identifier
                }}</vs-td>
                <vs-td :data="data[indextr].description">{{
                  data[indextr].description
                }}</vs-td>
                <vs-td :data="data[indextr].toEmails">{{
                  data[indextr].toEmails.replaceAll(";", ", ")
                }}</vs-td>
                <vs-td>
                  <vx-tooltip
                    :text="$t(resources.View.i18n) || resources.View.name"
                  >
                    <a @click="showPopUpMail(data[indextr])">
                      <feather-icon
                        icon="EyeIcon"
                        class="w-5 h-5 mr-1"
                      ></feather-icon>
                    </a>
                  </vx-tooltip>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </vx-card>
    </div>
    <vs-popup
      :title="$t(resources.Detail.i18n) || resources.Detail.name"
      :active.sync="popupActive"
    >
      <div class="vx-row mb-base">
        <div class="vx-col md:w-1/2 mb-2">
          <vs-input
            type="email"
            icon-pack="feather"
            icon="icon-email"
            icon-no-border
            :label="$t(resources.Email.i18n) || resources.Email.name"
            v-model="email"
          />
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2 mt-5">
          <vs-button color="primary" type="filled" @click="updateEmail(email)">
            {{ $t(resources.Create.i18n) || resources.Create.name }}
          </vs-button>
        </div>
      </div>
      <div class="vx-col w-full">
        <vs-table :data="emails">
          <template slot="thead">
            <vs-th>{{
              $t(resources.Email.i18n) || resources.Email.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.Options.i18n) || resources.Options.name
            }}</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr]">{{ data[indextr] }}</vs-td>
              <vx-tooltip
                :text="$t(resources.View.i18n) || resources.View.name"
              >
                <a @click="updateEmail(data[indextr])">
                  <feather-icon
                    icon="XIcon"
                    svgClasses="fill-current text-danger"
                    class="w-5 h-5 mr-1"
                  ></feather-icon>
                </a>
              </vx-tooltip>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import axios from "axios";

export default {
  data() {
    return {
      resources: resources,
      alerts: alerts,
      language: this.$i18n.locale,
      email: "",
      settingEmails: [],
      mailId: 0,
      emails: [],
      popupActive: false,
    };
  },
  created: function () {
    this.getListMailSetting();
  },
  methods: {
    async getListMailSetting() {
      this.$vs.loading();
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}MailSetting`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.settingEmails = [];
          } else {
            this.settingEmails = result.data;
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },

    showPopUpMail(data) {
      this.mailId = data.id;
      this.popupActive = true;
      this.emails = data.toEmails.split(";");
    },

    async updateEmail(email) {
      this.$vs.loading();
      let _email = this.emails;
      if (this.emails.find((x) => x == email)) {
        let _index = this.emails.findIndex((x) => x == email);
        if (_index > -1) _email.splice(_index, 1);
      } else _email.push(email);
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}MailSetting/Update`,
        data: {
          id: this.mailId,
          toEmails: _email.join(";").toString(),
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError"),
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-check",
            });
            this.emails = _email;
            this.email = "";
            this.getListMailSetting();
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },
  },
};
</script>
