<template>
  <div>
    <vx-card
      :title="$t(resources.Commission.i18n) || resources.Commission.name"
      class="mb-4"
    >
      <div class="vx-row mb-base">
        <div class="vx-col w-full md:w-1/6 mb-2">
          <label  class="vs-input--label">
            {{ $t(resources.StartDate.i18n) || resources.StartDate.name }}
          </label>
          <datepicker
            :language="languages[language]"
            format="d MMMM yyyy"
            v-model="startDate"
          ></datepicker>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2">
          <label class="vs-input--label">{{
            $t(resources.EndDate.i18n) || resources.EndDate.name
          }}</label>
          <datepicker
            :language="languages[language]"
            format="d MMMM yyyy"
            v-model="endDate"
          ></datepicker>
        </div>
        <div class="vx-col md:w-1/6 w-full mt-6">
          <ul class="vx-col w-full">
            <li>
              <vs-checkbox v-model="isBookingDate">{{
                $t(resources.CreationDate.i18n) || resources.CreationDate.name
              }} - {{$t(resources.Booking.i18n) || resources.Booking.name}}</vs-checkbox>
            </li>
          </ul>
        </div>
        <vs-button
          color="primary"
          type="filled"
          icon-pack="feather"
          icon="icon-refresh-ccw"
          class="mt-5"
          @click="cleanDate"
        ></vs-button>
        <div class="vx-col w-full md:w-1/6 mb-2">
          <vs-input
            type="number"
            class="w-full"
            icon-pack="feather"
            icon="icon-user"
            icon-no-border
            :label="$t(resources.DistributorID.i18n) || resources.DistributorID.name"
            v-validate="'min:3'"
            v-model="iboCode"
            name="iboCode"
            maxlength="8"
          />
          <span class="text-danger text-sm">{{ errors.first("iboCode") }}</span>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-hash"
              icon-no-border
              :label="$t(resources.BookingCode.i18n) || resources.BookingCode.name"
              v-validate="'alpha_dash'"
              v-model="bookingCode"
              name="bookingCode"
              maxlength="20"
            />
            <span class="text-danger text-sm">{{ errors.first("bookingCode") }}</span>
          </div>
        </div>
        <div class="vx-col mb-2 mt-5">
          <vs-button color="primary" type="filled" @click="get">{{
            $t(resources.Search.i18n) || resources.Search.name
          }}</vs-button>
        </div>
      </div>
    </vx-card>
    <div id="ag-grid-demo">
      <vx-card :title="$t(resources.List.i18n) || resources.List.name">
        <div class="flex flex-wrap justify-between items-center">
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{
                    currentPage * paginationPageSize - (paginationPageSize - 1)
                  }}
                  -
                  {{
                    dataList.length - currentPage * paginationPageSize > 0
                      ? currentPage * paginationPageSize
                      : dataList.length
                  }}
                  of {{ dataList.length }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
              <vs-dropdown-menu>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <div
            class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
          >
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              :placeholder="$t(resources.Search.i18n) || resources.Search.name"
            />
            <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">
              {{ $t(resources.ExportAs.i18n) || resources.ExportAs.name }}
            </vs-button>
          </div>
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="dataList"
          rowSelection="single"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          @row-clicked="onRowClicked"
        ></ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import resources from "@/i18n/resources.js";
import database from "@/assets/resources/enums/database.json";
import status from "@/assets/resources/enums/status.json";
import alerts from "@/i18n/alerts.js";
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import moment from "moment";

export default {
  components: { AgGridVue, Datepicker },
  data() {
    return {
      database: database,
      resources: resources,
      status: status,
      alerts: alerts,
      dataList: [],
      startDate: new Date(),
      endDate: new Date(),
      language: this.$i18n.locale,
      languages: lang,
      isBookingDate: false,
      bookingCode: "",
      iboCode: "",
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 10,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: "#",
          field: "bookingId",
          filter: true,
          hide: true,
        },
        {
          headerName: this.$i18n.t("DistributorID"),
          field: "iboCode",
          filter: true,
        },
        {
          headerName: this.$i18n.t("CountryOperation"),
          field: "countryCode",
          filter: true,
        },
        {
          headerName: this.$i18n.t("Currency"),
          field: "currencyCode",
          filter: true,
        },
        {
          headerName: this.$i18n.t("Product"),
          field: "productName",
          filter: true,
        },
        {
          headerName: this.$i18n.t("Code"),
          field: "bookingCode",
          filter: true,
        },
        {
          headerName: this.$i18n.t("NetValue"),
          field: "netValue",
          filter: true,
          width: 250,
        },
        {
          headerName: this.$i18n.t("FeeValue"),
          field: "fee",
          filter: true,
        },
        {
          headerName: this.$i18n.t("AirlineCommission"),
          field: "airlineCommision",
          filter: true,
        },
        {
          headerName: this.$i18n.t("ExchangeRate"),
          field: "rateValue",
          filter: true,
        },
        {
          headerName: this.$i18n.t("OrderNumber"),
          field: "infotraxOrder",
          filter: true,
        },
        {
          headerName: this.$i18n.t("Value"),
          field: "commision",
          filter: true,
        },
        {
          headerName: this.$i18n.t("BVPoints"),
          field: "pointsBv",
          filter: true,
        },
        {
          headerName: this.$i18n.t("Period"),
          field: "infotraxWeek",
          filter: true,
        },
        {
          headerName: this.$i18n.t("OrderDate"),
          field: "creationDateOrder",
          filter: true,
        },
        {
          headerName: this.$i18n.t("Stage"),
          field: "stageName",
          filter: true,
        },
        {
          headerName: this.$i18n.t("CreationDate"),
          field: "creationDateBooking",
          filter: true,
        },
      ],
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("level", null);
      } else this.gridOptions.columnApi.setColumnPinned("level", "left");
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 20;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  async created() {
    await this.get();
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    cleanDate() {
      this.startDate = null;
      this.endDate = null;
    },

    async onRowClicked(event) {
      let bookingId = event.node.data.bookingId;
      this.$router.push({
        name: "booking-bookingdetail",
        params: {
          bookingId: bookingId,
        },
      });
    },

    async get() {
      if (this.startDate == null && this.endDate == null && this.iboCode == "" && this.bookingCode == "") {
        this.$vs.notify({
          title: this.$i18n.t("Alert"),
          text: this.$i18n.t("MsgRequiredField"),
          color: "warning",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-x-circle",
        });
        return;
      }
      if (moment(this.startDate).isAfter(moment(this.endDate))) {
        this.$vs.notify({
          title: this.$i18n.t("Alert"),
          text: this.$i18n.t("MsgStartDateAfterEndDate"),
          color: "warning",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-x-circle"
        });
        return;
      }
      this.$vs.loading();
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_COMMISSION}commission/getTourism`,
        data: {
          startDate: moment(this.startDate).format("YYYY-MM-DD"),
          endDate: moment(this.endDate).format("YYYY-MM-DD"),
          isBookingDate: this.isBookingDate,
          iboCode: this.iboCode,
          bookingCode: this.bookingCode,
        },
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale.toUpperCase(),
        },
      }).then(
        (result) => {
          if (result.data.total == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.dataList = [];
          } else {
            this.dataList = result.data.data;
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
};
</script>
