<!-- =========================================================================================
    File Name: VxTimeline.vue
    Description: Timeline Component
    Component Name: VxTimeline
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <ul class="vx-timeline">
    <li v-for="item in data" :key="item.idx" class="mb-base">
      <div class="timeline-icon" :class="`bg-${item.color}`">
        <feather-icon
          :icon="item.icon"
          svgClasses="text-white stroke-current w-5 h-5"
        />
      </div>

      <vs-button
        v-if="item.url != null"
        color="danger"
        type="line"
        icon-pack="material-icons"
        icon="attach_file"
        size="large"
        @click="openDocument(item.url)"
      ></vs-button>

      <small class="activity-e-time">{{ item.time }}</small>
      <p class="font-semibold">
        {{ $t(resources.CreatedBy.i18n) || resources.CreatedBy.name }}:
        {{ item.user }}
      </p>
      <div class="timeline-info mt-1">
        <p class="font-semibold mb-4">
          {{ $t(resources.Subject.i18n) || resources.Subject.name }}:
          {{ item.title }}
        </p>
        <p class="font-semibold">
          {{ $t(resources.Body.i18n) || resources.Body.name }}:
        </p>
        <span
          v-if="item.type == 1 || item.type == 3 || item.type == 4"
          class="activity-desc"
          v-html="item.desc"
        ></span>
        <span v-else class="activity-desc">{{ item.desc }}</span>
      </div>
      <vs-button
        v-if="canDisabled"
        color="danger"
        type="flat"
        icon-pack="feather"
        icon="icon-trash"
        @click="disabled(`${item.id}`)"
      ></vs-button>
    </li>
  </ul>
</template>

<script>
import resources from "@/i18n/resources.js";
import axios from "axios";

export default {
  name: "vx-timeline",
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      resources: resources,
      userSession: JSON.parse(localStorage.getItem("userInfo")).email,
    };
  },
  computed: {
    canDisabled() {
      let user = this.userSession.toLowerCase();
      let listEmails = [
        "a.gomez@visiontravel.net",
        "odalis@visiontravel.net",
        "v.hernandez@visiontravelmail.net",
        user,
      ];

      let isAllow = listEmails.filter((x) => x == user);
      if (isAllow.length > 0) return true;

      return false;
    },
  },
  methods: {
    async disabled(id) {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Remarks/Disabled/${this.$i18n.locale.toUpperCase()}/${id}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          this.data.splice(
            this.data.findIndex((v) => v.id == id),
            1
          );
          this.$vs.notify({
            title: this.$i18n.t("Success"),
            text: result.data.message,
            color: "success",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },
    
    openDocument(url) {
      window.open(url, this.$i18n.t("Transfer"));
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/components/vxTimeline.scss";
</style>
