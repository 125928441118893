var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        {
          staticClass:
            "vx-col md:w-1/4 w-full mb-base profile-img-container pointer-events-none"
        },
        [
          _c(
            "vx-card",
            { staticClass: "text-center" },
            [
              _c("vs-avatar", {
                staticClass: "user-profile-img",
                attrs: { src: _vm.userInfo.photoURL, size: "200px" }
              }),
              _c("h4", [
                _c("strong", [
                  _vm._v(
                    _vm._s(_vm.userInfo.name + " " + _vm.userInfo.lastName)
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-3/4 w-full mb-base" },
        [
          _c(
            "vx-card",
            {
              attrs: {
                title:
                  _vm.$t(_vm.resources.BasicInformation.i18n) ||
                  _vm.resources.BasicInformation.name
              }
            },
            [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/2 w-full mb-base mb-6" },
                  [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            "icon-pack": "feather",
                            icon: "icon-hash",
                            "icon-no-border": "",
                            label:
                              _vm.$t(_vm.resources.DocumentNumber.i18n) ||
                              _vm.resources.DocumentNumber.name,
                            value: _vm.userInfo.documentNumber
                          }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/2 w-full mb-base mb-6" },
                  [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            "icon-pack": "feather",
                            icon: "icon-calendar",
                            "icon-no-border": "",
                            label:
                              _vm.$t(_vm.resources.BirthDate.i18n) ||
                              _vm.resources.BirthDate.name,
                            value: _vm.userInfo.birthDate
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/2 w-full mb-base mb-6" },
                  [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            type: "email",
                            "icon-pack": "feather",
                            icon: "icon-mail",
                            "icon-no-border": "",
                            label:
                              _vm.$t(_vm.resources.Email.i18n) ||
                              _vm.resources.Email.name,
                            value: _vm.userInfo.email
                          }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/2 w-full mb-base mb-6" },
                  [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            "icon-pack": "feather",
                            icon: "icon-smartphone",
                            "icon-no-border": "",
                            label:
                              _vm.$t(_vm.resources.Mobile.i18n) ||
                              _vm.resources.Mobile.name,
                            value: _vm.userInfo.phone
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/2 w-full mb-base mb-6" },
                  [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _vm.userInfo.gender == 1
                          ? _c("vs-input", {
                              staticClass: "w-full",
                              attrs: {
                                "icon-pack": "feather",
                                icon: "icon-user",
                                "icon-no-border": "",
                                label:
                                  _vm.$t(_vm.resources.Gender.i18n) ||
                                  _vm.resources.Gender.name,
                                value: "Female"
                              }
                            })
                          : _c("vs-input", {
                              staticClass: "w-full",
                              attrs: {
                                "icon-pack": "feather",
                                icon: "icon-user",
                                "icon-no-border": "",
                                label:
                                  _vm.$t(_vm.resources.Gender.i18n) ||
                                  _vm.resources.Gender.name,
                                value: "Male"
                              }
                            })
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/2 w-full mb-base mb-6" },
                  [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            "icon-pack": "feather",
                            icon: "icon-map",
                            "icon-no-border": "",
                            label:
                              _vm.$t(_vm.resources.CountryOperation.i18n) ||
                              _vm.resources.CountryOperation.name,
                            value: _vm.userInfo.country
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/2 w-full mb-base mb-6" },
                  [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            "icon-pack": "feather",
                            icon: "icon-compass",
                            "icon-no-border": "",
                            label:
                              _vm.$t(_vm.resources.Nationality.i18n) ||
                              _vm.resources.Nationality.name,
                            value: _vm.userInfo.nationalityName
                          }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/2 w-full mb-base mb-6" },
                  [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            "icon-pack": "feather",
                            icon: "icon-map-pin",
                            "icon-no-border": "",
                            label:
                              _vm.$t(_vm.resources.ResidenceCity.i18n) ||
                              _vm.resources.ResidenceCity.name,
                            value:
                              _vm.userInfo.cityName +
                              ", " +
                              _vm.userInfo.stateName +
                              ", " +
                              _vm.userInfo.countryName
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ]),
              _c("div", { staticClass: "vx-row mb-6" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        "icon-pack": "feather",
                        icon: "icon-map-pin",
                        "icon-no-border": "",
                        label:
                          _vm.$t(_vm.resources.Address.i18n) ||
                          _vm.resources.Address.name,
                        value: _vm.userInfo.address
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full text-right" },
                  [
                    _c(
                      "vs-button",
                      {
                        attrs: { color: "rgb(174,174,174)", type: "filled" },
                        on: {
                          click: function($event) {
                            return _vm.$router.push("/")
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.Return.i18n) ||
                              _vm.resources.Return.name
                          )
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }