<template>
  <div>
    <vx-card
      class="mb-2"
      :title="$t(resources.Product.i18n) || resources.Product.name"
    >
      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full mb-4">
          <h6>{{$t(resources.Name.i18n) || resources.Name.name}}</h6>
          <p>{{productInfo.name}}</p>
        </div>
        <div class="vx-col md:w-1/3 w-full mb-4">
          <h6>{{$t(resources.Code.i18n) || resources.Code.name}}</h6>
          <p>{{productInfo.code}}</p>
        </div>
        <div class="vx-col md:w-1/3 w-full mb-4">
          <h6>{{$t(resources.Type.i18n) || resources.Type.name}}</h6>
          <p>{{productInfo.typeName}}</p>
        </div>
      </div>

    </vx-card>

    <div class="vx-row mb-4">
      <vs-tabs>
        <vs-tab
          :label="$t(resources.Detail.i18n) || resources.Detail.name"
          icon-pack="feather"
          icon="icon-file-text"
        >
        <vx-card>
          <div class="vx-col w-full">
            <vs-table max-items="10" pagination :data="productInfo.productDetail">
              <template slot="thead">
                <vs-th>{{$t(resources.ContactTypeName.i18n) || resources.ContactTypeName.name}}</vs-th>
                <vs-th>{{$t(resources.Installments.i18n) || resources.Installments.name}}</vs-th>
                <vs-th>{{$t(resources.Price.i18n) || resources.Price.name}}</vs-th>
                <vs-th>{{$t(resources.Options.i18n) || resources.Options.name}}</vs-th>
              </template>

              <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="data[indextr].contactTypeName">{{data[indextr].contactTypeName}}</vs-td>
                  <vs-td :data="data[indextr].installment">{{data[indextr].installment}}</vs-td>
                  <vs-td :data="data[indextr].price">{{data[indextr].price}}</vs-td>
                  <vs-td>
                  <vx-tooltip :text="$t(resources.View.i18n) || resources.View.name">
                    <a @click="showPopUpDetail(data[indextr])">
                      <feather-icon icon="EyeIcon" class="w-5 h-5 mr-1"></feather-icon>
                    </a>
                  </vx-tooltip>
                </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </vx-card>
        </vs-tab>
        <vs-tab
          :label="$t(resources.Benefit.i18n) || resources.Benefit.name"
          icon-pack="feather"
          icon="icon-file-text"
        >
        <vx-card>
          <div class="vx-col w-full">
            <vs-table max-items="10" pagination :data="productInfo.productBenefit">
              <template slot="thead">
                <vs-th>{{$t(resources.Name.i18n) || resources.Name.name}}</vs-th>
                <vs-th>{{$t(resources.Description.i18n) || resources.Description.name}}</vs-th>
                <vs-th>{{$t(resources.Amount.i18n) || resources.Amount.name}}</vs-th>
                <vs-th>{{$t(resources.Value.i18n) || resources.Value.name}}</vs-th>
                <vs-th>{{$t(resources.Percent.i18n) || resources.Percent.name}}</vs-th>
                <vs-th>{{$t(resources.Options.i18n) || resources.Options.name}}</vs-th>
              </template>

              <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="data[indextr].name">{{data[indextr].name}}</vs-td>
                  <vs-td :data="data[indextr].description">{{data[indextr].description}}</vs-td>
                  <vs-td :data="data[indextr].amount">{{data[indextr].amount}}</vs-td>
                  <vs-td :data="data[indextr].value">{{data[indextr].value}}</vs-td>
                  <vs-td :data="data[indextr].isPercentage">
                    <feather-icon
                    v-if="data[indextr].isPercentage"
                    icon="CheckIcon"
                    svgClasses="fill-current text-success"
                    class="w-5 h-5 mr-1"
                    ></feather-icon>
                    <feather-icon
                    v-else
                    icon="XIcon"
                    svgClasses="fill-current text-danger"
                    class="w-5 h-5 mr-1"
                    ></feather-icon>
                  </vs-td>
                  <vs-td>
                  <vx-tooltip :text="$t(resources.View.i18n) || resources.View.name">
                    <a @click="showPopUpBenefit(data[indextr])">
                      <feather-icon icon="EyeIcon" class="w-5 h-5 mr-1"></feather-icon>
                    </a>
                  </vx-tooltip>
                </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </vx-card>
        </vs-tab>
      </vs-tabs>
    </div>
    <vs-popup
      :title="$t(resources.Detail.i18n) || resources.Detail.name"
      :active.sync="popUpProductDetail"
    >
      <div class="vx-row mb-base">
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            icon-pack="feather"
            icon="icon-dollar-sign"
            icon-no-border
            type="number"
            size="small"
            :label="$t(resources.Installments.i18n) || resources.Installments.name"
            v-model="productDetail.installment"
          />
        </div>
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            icon-pack="feather"
            icon="icon-dollar-sign"
            icon-no-border
            type="number"
            size="small"
            :label="$t(resources.Price.i18n) || resources.Price.name"
            v-model="productDetail.price"
          />
        </div>
        <div class="vx-col w-full">
          <vs-input
            class="w-full"
            icon-pack="feather"
            icon="icon-dollar-sign"
            icon-no-border
            type="number"
            size="small"
            :label="$t(resources.PriceLevel.i18n) || resources.PriceLevel.name"
            v-model="productDetail.priceLevel"
          />
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2 mt-5">
          <vs-button color="primary" type="filled" @click="updateDetail()">
            {{ $t(resources.Save.i18n) || resources.Save.name }}
          </vs-button>
        </div>
      </div>
    </vs-popup>
    <vs-popup
      :title="$t(resources.Benefit.i18n) || resources.Benefit.name"
      :active.sync="popUpProductBenefit"
    >
      <div class="vx-row mb-base">
        <div class="vx-col w-full mb-2">
          <h6>{{$t(resources.Name.i18n) || resources.Name.name}}</h6>
          <p>{{productBenefit.name}}</p>
        </div>
        <div class="vx-col w-full mb-2">
          <vs-input
            class="w-full"
            icon-pack="feather"
            icon="icon-dollar-sign"
            icon-no-border
            type="number"
            size="small"
            :label="$t(resources.Amount.i18n) || resources.Amount.name"
            v-model="productBenefit.amount"
          />
        </div>
        <div class="vx-col w-full mb-2">
          <vs-input
            class="w-full"
            icon-pack="feather"
            icon="icon-dollar-sign"
            icon-no-border
            type="text"
            size="small"
            :label="$t(resources.Value.i18n) || resources.Value.name"
            v-model="productBenefit.value"
          />
        </div>
        <div class="vx-col w-full mb-2">
          <h6>{{$t(resources.Percent.i18n) || resources.Percent.name}}</h6>
          <vs-switch color="success" v-model="productBenefit.isPercentage">
            <span slot="on">{{$t(resources.Yes.i18n) || resources.Yes.name}}</span>
            <span slot="off">{{$t(resources.No.i18n) || resources.No.name}}</span>
          </vs-switch>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2 mt-5">
          <vs-button color="primary" type="filled" @click="updateBenefit()">
            {{ $t(resources.Save.i18n) || resources.Save.name }}
          </vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import axios from "axios";
import resources from "@/i18n/resources.js";
import database from "@/assets/resources/enums/database.json";

export default {
  components: {},
  data() {
    return {
      resources: resources,
      database: database,
      userSession: JSON.parse(localStorage.getItem("userInfo")).email,
      productId: 0,
      productInfo: {},
      productDetail: {},
      productBenefit: {},
      popUpProductDetail: false,
      popUpProductBenefit: false
    };
  },
  async created() {
    this.$vs.loading();

    this.productId = this.$route.params.id;
    await this.get();

    this.$vs.loading.close();
  },
  methods: {
    async get() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Product/GetBenefitAndDetail/${this.$i18n.locale.toUpperCase()}/${this.productId}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.productInfo = {};
          } else {
            this.productInfo = result.data;
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },
    showPopUpDetail(data){
      this.popUpProductDetail = true;
      this.productDetail = data;

    },
    showPopUpBenefit(data){
      this.popUpProductBenefit = true;
      this.productBenefit = data;

    },
    async updateDetail(){
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}ProductDetail/Update`,
        data: {
          id: this.productDetail.id,
          installment: this.productDetail.installment,
          price: this.productDetail.price,
          priceLevel: this.productDetail.priceLevel,
          language: this.$i18n.locale.toUpperCase()
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text:
                this.$i18n.t("MsgSaved") + "<br/>" + result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.popUpProductDetail = false;
            this.get();
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );

    },
    async updateBenefit(){
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}BenefitProduct/Update`,
        data: {
          id: this.productBenefit.id,
          amount: this.productBenefit.amount,
          price: this.productBenefit.price,
          isPercentage: this.productBenefit.isPercentage,
          value: this.productBenefit.value,
          language: this.$i18n.locale.toUpperCase()
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text:
                this.$i18n.t("MsgSaved") + "<br/>" + result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.popUpProductBenefit = false;
            this.get();
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );

    }
  },
};
</script>
