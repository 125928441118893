<!-- =========================================================================================
    File Name: Profile.vue
    Description: Profile Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="vx-row">
      <div
        class="vx-col md:w-1/4 w-full mb-base profile-img-container pointer-events-none"
      >
        <vx-card class="text-center">
          <vs-avatar
            class="user-profile-img"
            :src="userInfo.photoURL"
            size="200px"
          />
          <h4>
            <strong>{{ userInfo.name + " " + userInfo.lastName }}</strong>
          </h4>
        </vx-card>
      </div>
      <div class="vx-col md:w-3/4 w-full mb-base">
        <vx-card
          :title="
            $t(resources.BasicInformation.i18n) ||
              resources.BasicInformation.name
          "
        >
          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full mb-base mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-hash"
                  icon-no-border
                  :label="
                    $t(resources.DocumentNumber.i18n) ||
                      resources.DocumentNumber.name
                  "
                  :value="userInfo.documentNumber"
                />
              </div>
            </div>
            <div class="vx-col md:w-1/2 w-full mb-base mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-calendar"
                  icon-no-border
                  :label="
                    $t(resources.BirthDate.i18n) || resources.BirthDate.name
                  "
                  :value="userInfo.birthDate"
                />
              </div>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full mb-base mb-6">
              <div class="vx-col w-full">
                <vs-input
                  type="email"
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-mail"
                  icon-no-border
                  :label="$t(resources.Email.i18n) || resources.Email.name"
                  :value="userInfo.email"
                />
              </div>
            </div>
            <div class="vx-col md:w-1/2 w-full mb-base mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-smartphone"
                  icon-no-border
                  :label="$t(resources.Mobile.i18n) || resources.Mobile.name"
                  :value="userInfo.phone"
                />
              </div>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full mb-base mb-6">
              <div class="vx-col w-full">
                <!-- If a gender is equal to 1 -->
                <vs-input
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-user"
                  icon-no-border
                  :label="$t(resources.Gender.i18n) || resources.Gender.name"
                  v-if="userInfo.gender == 1"
                  value="Female"
                />
                <!-- else a gender is any value -->
                <vs-input
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-user"
                  icon-no-border
                  :label="$t(resources.Gender.i18n) || resources.Gender.name"
                  v-else
                  value="Male"
                />
              </div>
            </div>
            <div class="vx-col md:w-1/2 w-full mb-base mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-map"
                  icon-no-border
                  :label="
                    $t(resources.CountryOperation.i18n) ||
                      resources.CountryOperation.name
                  "
                  :value="userInfo.country"
                />
              </div>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full mb-base mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-compass"
                  icon-no-border
                  :label="
                    $t(resources.Nationality.i18n) || resources.Nationality.name
                  "
                  :value="userInfo.nationalityName"
                />
              </div>
            </div>
            <div class="vx-col md:w-1/2 w-full mb-base mb-6">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-map-pin"
                  icon-no-border
                  :label="
                    $t(resources.ResidenceCity.i18n) ||
                      resources.ResidenceCity.name
                  "
                  :value="
                    userInfo.cityName +
                      ', ' +
                      userInfo.stateName +
                      ', ' +
                      userInfo.countryName
                  "
                />
              </div>
            </div>
          </div>

          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-map-pin"
                icon-no-border
                :label="$t(resources.Address.i18n) || resources.Address.name"
                :value="userInfo.address"
              />
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col w-full text-right">
              <vs-button
                color="rgb(174,174,174)"
                type="filled"
                @click="$router.push('/')"
                >{{
                  $t(resources.Return.i18n) || resources.Return.name
                }}</vs-button
              >
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import resources from "@/i18n/resources.js";

export default {
  data() {
    return {
      resources: resources,
      isNavOpen: false,
      userPoll: "",
      userInfo: JSON.parse(localStorage.getItem("userInfo"))
    };
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/pages/profile.scss";
</style>
