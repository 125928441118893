var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-card",
        { staticClass: "mb-4" },
        [
          _c(
            "vs-row",
            {
              staticClass: "mb-base mt-2",
              attrs: { "vs-type": "flex", "vs-justify": "center" }
            },
            [
              _c(
                "vs-col",
                {
                  attrs: {
                    "vs-type": "flex",
                    "vs-justify": "center",
                    "vs-align": "center",
                    "vs-w": "6"
                  }
                },
                [
                  _c(
                    "vs-button",
                    {
                      attrs: { color: "primary", type: "filled", icon: "add" },
                      on: {
                        click: function($event) {
                          return _vm.openAdd(false)
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.Add.i18n) ||
                            _vm.resources.Add.name
                        )
                      )
                    ]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-2",
                      attrs: {
                        color: "rgb(27, 48, 83)",
                        type: "filled",
                        icon: "add"
                      },
                      on: {
                        click: function($event) {
                          return _vm.openAdd(true)
                        }
                      }
                    },
                    [_vm._v("TC empresarial")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-2",
                      attrs: {
                        color: "success",
                        type: "filled",
                        icon: "credit_card"
                      },
                      on: {
                        click: function($event) {
                          _vm.popupRecapture = true
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.Recapture.i18n) ||
                            _vm.resources.Recapture.name
                        )
                      )
                    ]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-2",
                      attrs: { color: "warning", type: "filled", icon: "link" },
                      on: {
                        click: function($event) {
                          _vm.popupGenerate = true
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.Generate.i18n) ||
                            _vm.resources.Generate.name
                        )
                      )
                    ]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-2",
                      attrs: {
                        color: "success",
                        type: "filled",
                        icon: "credit_card"
                      },
                      on: {
                        click: function($event) {
                          return _vm.setAccountBlockpay()
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.Recapture.i18n) ||
                            _vm.resources.Recapture.name
                        ) + " Blockpay"
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-base" },
            [
              _c("h3", { staticClass: "text-center" }, [
                _c("strong", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(_vm.resources.Receipts.i18n) ||
                          _vm.resources.Receipts.name
                      ) +
                      " | " +
                      _vm._s(
                        _vm.$t(_vm.resources.Total.i18n) ||
                          _vm.resources.Total.name
                      ) +
                      ":"
                  )
                ]),
                _vm._v(
                  " " +
                    _vm._s(_vm.currencyCode) +
                    " " +
                    _vm._s(_vm.totalReceipts) +
                    " "
                )
              ]),
              _vm.totalBooking > _vm.totalReceipts
                ? _c(
                    "h5",
                    {
                      staticClass: "text-center mt-2 font-semibold text-danger"
                    },
                    [
                      _vm._v(
                        " El pago debe ser completado para emitir los servicios. Diferencia por: $" +
                          _vm._s(
                            parseFloat(_vm.totalBooking - _vm.totalReceipts)
                              .toFixed(2)
                              .toLocaleString("es-MX")
                          ) +
                          " "
                      )
                    ]
                  )
                : _vm._e(),
              _c(
                "vs-table",
                {
                  staticClass: "mt-base",
                  attrs: { data: _vm.receipts },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var data = ref.data
                        return _vm._l(data, function(tr, indextr) {
                          return _c(
                            "vs-tr",
                            { key: indextr },
                            [
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].id } },
                                [_vm._v(_vm._s(data[indextr].id))]
                              ),
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].merchantName } },
                                [_vm._v(_vm._s(data[indextr].merchantName))]
                              ),
                              _c(
                                "vs-td",
                                {
                                  attrs: {
                                    data: data[indextr].transactionIdMerchant
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(data[indextr].transactionIdMerchant)
                                  )
                                ]
                              ),
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].currencyCode } },
                                [_vm._v(_vm._s(data[indextr].currencyCode))]
                              ),
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].value } },
                                [_vm._v(_vm._s(data[indextr].value))]
                              ),
                              _c(
                                "vs-td",
                                {
                                  attrs: {
                                    data: data[indextr].transactionNumber
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(data[indextr].transactionNumber)
                                  )
                                ]
                              ),
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].cardType } },
                                [_vm._v(_vm._s(data[indextr].cardType))]
                              ),
                              data[indextr].cardNumber != "" &&
                              data[indextr].cardNumber != null
                                ? _c(
                                    "vs-td",
                                    {
                                      attrs: { data: data[indextr].cardNumber }
                                    },
                                    [
                                      _vm._v(
                                        "****" +
                                          _vm._s(data[indextr].cardNumber)
                                      )
                                    ]
                                  )
                                : _c("vs-td", {
                                    attrs: { data: data[indextr].cardNumber }
                                  }),
                              data[indextr].paymentTypeId ==
                                _vm.database.merchantType.EWallet &&
                              data[indextr].statusId ==
                                _vm.status.payment.pending
                                ? _c(
                                    "vs-td",
                                    {
                                      attrs: {
                                        data: data[indextr].merchantType
                                      }
                                    },
                                    [
                                      _c(
                                        "vs-button",
                                        {
                                          attrs: {
                                            color: "success",
                                            type: "line",
                                            "icon-pack": "feather",
                                            icon: "icon-refresh-ccw"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.paymentEwallet(
                                                "" + data[indextr].description,
                                                "" + data[indextr].referenceId
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(data[indextr].stageName)
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : data[indextr].paymentTypeId ==
                                    _vm.database.merchantType.transfer &&
                                  data[indextr].statusId ==
                                    _vm.status.payment.pending
                                ? _c(
                                    "vs-td",
                                    {
                                      attrs: {
                                        data: data[indextr].paymentTypeId
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(data[indextr].state) + " "
                                      ),
                                      _c("vs-button", {
                                        attrs: {
                                          color: "success",
                                          "icon-pack": "feather",
                                          icon: "icon-check"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.updateStage(
                                              "" + _vm.status.payment.paid,
                                              "" + data[indextr].id
                                            )
                                          }
                                        }
                                      }),
                                      _c("vs-button", {
                                        attrs: {
                                          color: "danger",
                                          "icon-pack": "feather",
                                          icon: "icon-x"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.updateStage(
                                              "" + _vm.status.payment.declined,
                                              "" + data[indextr].id
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _c(
                                    "vs-td",
                                    { attrs: { data: data[indextr].state } },
                                    [_vm._v(_vm._s(data[indextr].state))]
                                  ),
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].paymentDate } },
                                [_vm._v(_vm._s(data[indextr].paymentDatetime))]
                              ),
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].createdDate } },
                                [_vm._v(_vm._s(data[indextr].creationDate))]
                              ),
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].createdBy } },
                                [_vm._v(_vm._s(data[indextr].createdBy))]
                              ),
                              _c(
                                "vs-td",
                                [
                                  _c(
                                    "vx-tooltip",
                                    {
                                      attrs: {
                                        text:
                                          _vm.$t(_vm.resources.Edit.i18n) ||
                                          _vm.resources.Edit.name
                                      }
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.getRecord(
                                                data[indextr].id
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("feather-icon", {
                                            staticClass: "w-5 h-5 mr-1",
                                            attrs: { icon: "EditIcon" }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  data[indextr].value > 0
                                    ? _c(
                                        "vx-tooltip",
                                        { attrs: { text: "Devolución cobro" } },
                                        [
                                          _c(
                                            "vs-button",
                                            {
                                              attrs: {
                                                color: "danger",
                                                type: "filled",
                                                "icon-pack": "feather",
                                                icon: "icon-x-circle"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.openPopupReturn(
                                                    data[indextr].id,
                                                    data[indextr]
                                                      .transactionNumber,
                                                    data[indextr].value
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("Devolución")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        })
                      }
                    }
                  ])
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c("vs-th", [_vm._v("#")]),
                      _c("vs-th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.Merchant.i18n) ||
                              _vm.resources.Merchant.name
                          )
                        )
                      ]),
                      _c("vs-th", [_vm._v("Id Merchant")]),
                      _c("vs-th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.Currency.i18n) ||
                              _vm.resources.Currency.name
                          )
                        )
                      ]),
                      _c("vs-th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.Value.i18n) ||
                              _vm.resources.Value.name
                          )
                        )
                      ]),
                      _c("vs-th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.TransactionNumber.i18n) ||
                              _vm.resources.TransactionNumber.name
                          )
                        )
                      ]),
                      _c("vs-th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.CreditCardType.i18n) ||
                              _vm.resources.CreditCardType.name
                          )
                        )
                      ]),
                      _c("vs-th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.CreditCardNumber.i18n) ||
                              _vm.resources.CreditCardNumber.name
                          )
                        )
                      ]),
                      _c("vs-th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.Status.i18n) ||
                              _vm.resources.Status.name
                          )
                        )
                      ]),
                      _c("vs-th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.PaymentDate.i18n) ||
                              _vm.resources.PaymentDate.name
                          )
                        )
                      ]),
                      _c("vs-th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.CreationDate.i18n) ||
                              _vm.resources.CreationDate.name
                          )
                        )
                      ]),
                      _c("vs-th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.CreatedBy.i18n) ||
                              _vm.resources.CreatedBy.name
                          )
                        )
                      ]),
                      _c("vs-th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.Options.i18n) ||
                              _vm.resources.Options.name
                          )
                        )
                      ])
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "vs-tabs",
        [
          _c(
            "vs-tab",
            { attrs: { label: "Registro de tarjetas" } },
            [
              _c(
                "vx-card",
                { staticClass: "mb-4 mt-2" },
                [
                  _c(
                    "vs-table",
                    {
                      staticClass: "mt-base",
                      attrs: { data: _vm.cardsRegistry },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var data = ref.data
                            return _vm._l(data, function(tr, indextr) {
                              return _c(
                                "vs-tr",
                                { key: indextr },
                                [
                                  _c(
                                    "vs-td",
                                    { attrs: { data: data[indextr].id } },
                                    [_vm._v(_vm._s(data[indextr].id))]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: {
                                        data: data[indextr].merchantName
                                      }
                                    },
                                    [_vm._v(_vm._s(data[indextr].merchantName))]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: {
                                        data: data[indextr].currencyCode
                                      }
                                    },
                                    [_vm._v(_vm._s(data[indextr].currencyCode))]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: {
                                        data: data[indextr].transactionNumber
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(data[indextr].transactionNumber)
                                      )
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    { attrs: { data: data[indextr].cardType } },
                                    [_vm._v(_vm._s(data[indextr].cardType))]
                                  ),
                                  data[indextr].cardNumber != "" &&
                                  data[indextr].cardNumber != null
                                    ? _c(
                                        "vs-td",
                                        {
                                          attrs: {
                                            data: data[indextr].cardNumber
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "****" +
                                              _vm._s(data[indextr].cardNumber)
                                          )
                                        ]
                                      )
                                    : _c("vs-td", {
                                        attrs: {
                                          data: data[indextr].cardNumber
                                        }
                                      }),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: { data: data[indextr].createdDate }
                                    },
                                    [_vm._v(_vm._s(data[indextr].creationDate))]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: { data: data[indextr].createdBy }
                                    },
                                    [_vm._v(_vm._s(data[indextr].createdBy))]
                                  ),
                                  _c(
                                    "vs-td",
                                    [
                                      _c(
                                        "vx-tooltip",
                                        {
                                          attrs: {
                                            text:
                                              _vm.$t(_vm.resources.Edit.i18n) ||
                                              _vm.resources.Edit.name
                                          }
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.getRecord(
                                                    data[indextr].id
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("feather-icon", {
                                                staticClass: "w-5 h-5 mr-1",
                                                attrs: { icon: "EditIcon" }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            })
                          }
                        }
                      ])
                    },
                    [
                      _c(
                        "template",
                        { slot: "thead" },
                        [
                          _c("vs-th", [_vm._v("#")]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Merchant.i18n) ||
                                  _vm.resources.Merchant.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Currency.i18n) ||
                                  _vm.resources.Currency.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.TransactionNumber.i18n) ||
                                  _vm.resources.TransactionNumber.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.CreditCardType.i18n) ||
                                  _vm.resources.CreditCardType.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.CreditCardNumber.i18n) ||
                                  _vm.resources.CreditCardNumber.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.CreationDate.i18n) ||
                                  _vm.resources.CreationDate.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.CreatedBy.i18n) ||
                                  _vm.resources.CreatedBy.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Options.i18n) ||
                                  _vm.resources.Options.name
                              )
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "vs-tab",
            { attrs: { label: "Cobros a crédito" } },
            [
              _c("h3", { staticClass: "text-center" }, [
                _c("strong", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(_vm.resources.Receipts.i18n) ||
                          _vm.resources.Receipts.name
                      ) +
                      ":"
                  )
                ]),
                _vm._v(
                  " " +
                    _vm._s(_vm.currencyCode) +
                    " " +
                    _vm._s(_vm.totalCredit.toLocaleString("es-MX")) +
                    " "
                )
              ]),
              _vm.totalBooking > _vm.totalCredit
                ? _c(
                    "h5",
                    {
                      staticClass: "text-center mt-2 font-semibold text-danger"
                    },
                    [
                      _vm._v(
                        " El pago debe ser completado para emitir los servicios. Diferencia por: $" +
                          _vm._s(
                            parseFloat(_vm.totalBooking - _vm.totalCredit)
                              .toFixed(2)
                              .toLocaleString("es-MX")
                          ) +
                          " "
                      )
                    ]
                  )
                : _vm._e(),
              _c(
                "vs-table",
                {
                  staticClass: "mt-base",
                  attrs: { data: _vm.chargeOnCreditList },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var data = ref.data
                        return _vm._l(data, function(tr, indextr) {
                          return _c(
                            "vs-tr",
                            { key: indextr },
                            [
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].id } },
                                [_vm._v(_vm._s(data[indextr].id))]
                              ),
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].merchantName } },
                                [_vm._v(_vm._s(data[indextr].merchantName))]
                              ),
                              _c(
                                "vs-td",
                                {
                                  attrs: {
                                    data: data[indextr].transactionIdMerchant
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(data[indextr].transactionIdMerchant)
                                  )
                                ]
                              ),
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].currencyCode } },
                                [_vm._v(_vm._s(data[indextr].currencyCode))]
                              ),
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].value } },
                                [_vm._v(_vm._s(data[indextr].value))]
                              ),
                              _c(
                                "vs-td",
                                {
                                  attrs: {
                                    data: data[indextr].transactionNumber
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(data[indextr].transactionNumber)
                                  )
                                ]
                              ),
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].cardType } },
                                [_vm._v(_vm._s(data[indextr].cardType))]
                              ),
                              data[indextr].cardNumber != "" &&
                              data[indextr].cardNumber != null
                                ? _c(
                                    "vs-td",
                                    {
                                      attrs: { data: data[indextr].cardNumber }
                                    },
                                    [
                                      _vm._v(
                                        "****" +
                                          _vm._s(data[indextr].cardNumber)
                                      )
                                    ]
                                  )
                                : _c("vs-td", {
                                    attrs: { data: data[indextr].cardNumber }
                                  }),
                              data[indextr].paymentTypeId ==
                                _vm.database.merchantType.EWallet &&
                              data[indextr].statusId ==
                                _vm.status.payment.pending
                                ? _c(
                                    "vs-td",
                                    {
                                      attrs: {
                                        data: data[indextr].merchantType
                                      }
                                    },
                                    [
                                      _c(
                                        "vs-button",
                                        {
                                          attrs: {
                                            color: "success",
                                            type: "line",
                                            "icon-pack": "feather",
                                            icon: "icon-refresh-ccw"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.paymentEwallet(
                                                "" + data[indextr].description,
                                                "" + data[indextr].referenceId
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(data[indextr].stageName)
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : data[indextr].paymentTypeId ==
                                    _vm.database.merchantType.transfer &&
                                  data[indextr].statusId ==
                                    _vm.status.payment.pending
                                ? _c(
                                    "vs-td",
                                    {
                                      attrs: {
                                        data: data[indextr].paymentTypeId
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(data[indextr].state) + " "
                                      ),
                                      _c("vs-button", {
                                        attrs: {
                                          color: "success",
                                          "icon-pack": "feather",
                                          icon: "icon-check"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.updateStage(
                                              "" + _vm.status.payment.paid,
                                              "" + data[indextr].id
                                            )
                                          }
                                        }
                                      }),
                                      _c("vs-button", {
                                        attrs: {
                                          color: "danger",
                                          "icon-pack": "feather",
                                          icon: "icon-x"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.updateStage(
                                              "" + _vm.status.payment.declined,
                                              "" + data[indextr].id
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _c(
                                    "vs-td",
                                    { attrs: { data: data[indextr].state } },
                                    [_vm._v(_vm._s(data[indextr].state))]
                                  ),
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].paymentDate } },
                                [_vm._v(_vm._s(data[indextr].paymentDatetime))]
                              ),
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].createdDate } },
                                [_vm._v(_vm._s(data[indextr].creationDate))]
                              ),
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].createdBy } },
                                [_vm._v(_vm._s(data[indextr].createdBy))]
                              ),
                              _c(
                                "vs-td",
                                [
                                  _c(
                                    "vx-tooltip",
                                    {
                                      attrs: {
                                        text:
                                          _vm.$t(_vm.resources.Edit.i18n) ||
                                          _vm.resources.Edit.name
                                      }
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.getRecord(
                                                data[indextr].id
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("feather-icon", {
                                            staticClass: "w-5 h-5 mr-1",
                                            attrs: { icon: "EditIcon" }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  data[indextr].value > 0
                                    ? _c(
                                        "vx-tooltip",
                                        { attrs: { text: "Devolución cobro" } },
                                        [
                                          _c(
                                            "vs-button",
                                            {
                                              attrs: {
                                                color: "danger",
                                                type: "filled",
                                                "icon-pack": "feather",
                                                icon: "icon-x-circle"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.openPopupReturn(
                                                    data[indextr].id,
                                                    data[indextr]
                                                      .transactionNumber,
                                                    data[indextr].value
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("Devolución")]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        })
                      }
                    }
                  ])
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c("vs-th", [_vm._v("#")]),
                      _c("vs-th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.Merchant.i18n) ||
                              _vm.resources.Merchant.name
                          )
                        )
                      ]),
                      _c("vs-th", [_vm._v("Id Merchant")]),
                      _c("vs-th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.Currency.i18n) ||
                              _vm.resources.Currency.name
                          )
                        )
                      ]),
                      _c("vs-th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.Value.i18n) ||
                              _vm.resources.Value.name
                          )
                        )
                      ]),
                      _c("vs-th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.TransactionNumber.i18n) ||
                              _vm.resources.TransactionNumber.name
                          )
                        )
                      ]),
                      _c("vs-th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.CreditCardType.i18n) ||
                              _vm.resources.CreditCardType.name
                          )
                        )
                      ]),
                      _c("vs-th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.CreditCardNumber.i18n) ||
                              _vm.resources.CreditCardNumber.name
                          )
                        )
                      ]),
                      _c("vs-th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.Status.i18n) ||
                              _vm.resources.Status.name
                          )
                        )
                      ]),
                      _c("vs-th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.PaymentDate.i18n) ||
                              _vm.resources.PaymentDate.name
                          )
                        )
                      ]),
                      _c("vs-th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.CreationDate.i18n) ||
                              _vm.resources.CreationDate.name
                          )
                        )
                      ]),
                      _c("vs-th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.CreatedBy.i18n) ||
                              _vm.resources.CreatedBy.name
                          )
                        )
                      ]),
                      _c("vs-th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.Options.i18n) ||
                              _vm.resources.Options.name
                          )
                        )
                      ])
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          ),
          _c(
            "vs-tab",
            { attrs: { label: "Pagos con tarjetas empresariales" } },
            [
              _c(
                "vx-card",
                { staticClass: "mb-4 mt-2" },
                [
                  _c("h4", { staticClass: "mb-base text-center" }),
                  _c(
                    "vs-table",
                    {
                      staticClass: "mt-base",
                      attrs: { data: _vm.businessCards },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var data = ref.data
                            return _vm._l(data, function(tr, indextr) {
                              return _c(
                                "vs-tr",
                                { key: indextr },
                                [
                                  _c(
                                    "vs-td",
                                    { attrs: { data: data[indextr].id } },
                                    [_vm._v(_vm._s(data[indextr].id))]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: {
                                        data: data[indextr].merchantName
                                      }
                                    },
                                    [_vm._v(_vm._s(data[indextr].merchantName))]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: {
                                        data: data[indextr].currencyCode
                                      }
                                    },
                                    [_vm._v(_vm._s(data[indextr].currencyCode))]
                                  ),
                                  _c(
                                    "vs-td",
                                    { attrs: { data: data[indextr].value } },
                                    [_vm._v(_vm._s(data[indextr].value))]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: {
                                        data: data[indextr].transactionNumber
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(data[indextr].transactionNumber)
                                      )
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    { attrs: { data: data[indextr].cardType } },
                                    [_vm._v(_vm._s(data[indextr].cardType))]
                                  ),
                                  data[indextr].cardNumber != "" &&
                                  data[indextr].cardNumber != null
                                    ? _c(
                                        "vs-td",
                                        {
                                          attrs: {
                                            data: data[indextr].cardNumber
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "****" +
                                              _vm._s(data[indextr].cardNumber)
                                          )
                                        ]
                                      )
                                    : _c("vs-td", {
                                        attrs: {
                                          data: data[indextr].cardNumber
                                        }
                                      }),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: { data: data[indextr].createdDate }
                                    },
                                    [_vm._v(_vm._s(data[indextr].creationDate))]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: { data: data[indextr].createdBy }
                                    },
                                    [_vm._v(_vm._s(data[indextr].createdBy))]
                                  ),
                                  _c(
                                    "vs-td",
                                    [
                                      _c(
                                        "vx-tooltip",
                                        {
                                          attrs: {
                                            text:
                                              _vm.$t(_vm.resources.Edit.i18n) ||
                                              _vm.resources.Edit.name
                                          }
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.getRecord(
                                                    data[indextr].id
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("feather-icon", {
                                                staticClass: "w-5 h-5 mr-1",
                                                attrs: { icon: "EditIcon" }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            })
                          }
                        }
                      ])
                    },
                    [
                      _c(
                        "template",
                        { slot: "thead" },
                        [
                          _c("vs-th", [_vm._v("#")]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Merchant.i18n) ||
                                  _vm.resources.Merchant.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Currency.i18n) ||
                                  _vm.resources.Currency.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Value.i18n) ||
                                  _vm.resources.Value.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.TransactionNumber.i18n) ||
                                  _vm.resources.TransactionNumber.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.CreditCardType.i18n) ||
                                  _vm.resources.CreditCardType.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.CreditCardNumber.i18n) ||
                                  _vm.resources.CreditCardNumber.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.CreationDate.i18n) ||
                                  _vm.resources.CreationDate.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.CreatedBy.i18n) ||
                                  _vm.resources.CreatedBy.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Options.i18n) ||
                                  _vm.resources.Options.name
                              )
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "vs-tab",
            { attrs: { label: "Tarjetas virtuales" } },
            [
              _c(
                "vx-card",
                { staticClass: "mb-4 mt-2" },
                [
                  _c("h4", { staticClass: "mb-base text-center" }),
                  _c(
                    "vs-table",
                    {
                      staticClass: "mt-base",
                      attrs: { data: _vm.otherList },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var data = ref.data
                            return _vm._l(data, function(tr, indextr) {
                              return _c(
                                "vs-tr",
                                { key: indextr },
                                [
                                  _c(
                                    "vs-td",
                                    { attrs: { data: data[indextr].id } },
                                    [_vm._v(_vm._s(data[indextr].id))]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: {
                                        data: data[indextr].merchantName
                                      }
                                    },
                                    [_vm._v(_vm._s(data[indextr].merchantName))]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: {
                                        data: data[indextr].currencyCode
                                      }
                                    },
                                    [_vm._v(_vm._s(data[indextr].currencyCode))]
                                  ),
                                  _c(
                                    "vs-td",
                                    { attrs: { data: data[indextr].value } },
                                    [_vm._v(_vm._s(data[indextr].value))]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: {
                                        data: data[indextr].transactionNumber
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(data[indextr].transactionNumber)
                                      )
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    { attrs: { data: data[indextr].cardType } },
                                    [_vm._v(_vm._s(data[indextr].cardType))]
                                  ),
                                  data[indextr].cardNumber != "" &&
                                  data[indextr].cardNumber != null
                                    ? _c(
                                        "vs-td",
                                        {
                                          attrs: {
                                            data: data[indextr].cardNumber
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "****" +
                                              _vm._s(data[indextr].cardNumber)
                                          )
                                        ]
                                      )
                                    : _c("vs-td", {
                                        attrs: {
                                          data: data[indextr].cardNumber
                                        }
                                      }),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: { data: data[indextr].createdDate }
                                    },
                                    [_vm._v(_vm._s(data[indextr].creationDate))]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: { data: data[indextr].createdBy }
                                    },
                                    [_vm._v(_vm._s(data[indextr].createdBy))]
                                  ),
                                  _c(
                                    "vs-td",
                                    [
                                      _c(
                                        "vx-tooltip",
                                        {
                                          attrs: {
                                            text:
                                              _vm.$t(_vm.resources.Edit.i18n) ||
                                              _vm.resources.Edit.name
                                          }
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.getRecord(
                                                    data[indextr].id
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("feather-icon", {
                                                staticClass: "w-5 h-5 mr-1",
                                                attrs: { icon: "EditIcon" }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            })
                          }
                        }
                      ])
                    },
                    [
                      _c(
                        "template",
                        { slot: "thead" },
                        [
                          _c("vs-th", [_vm._v("#")]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Merchant.i18n) ||
                                  _vm.resources.Merchant.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Currency.i18n) ||
                                  _vm.resources.Currency.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Value.i18n) ||
                                  _vm.resources.Value.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.TransactionNumber.i18n) ||
                                  _vm.resources.TransactionNumber.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.CreditCardType.i18n) ||
                                  _vm.resources.CreditCardType.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.CreditCardNumber.i18n) ||
                                  _vm.resources.CreditCardNumber.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.CreationDate.i18n) ||
                                  _vm.resources.CreationDate.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.CreatedBy.i18n) ||
                                  _vm.resources.CreatedBy.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Options.i18n) ||
                                  _vm.resources.Options.name
                              )
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "vx-card",
        { staticClass: "mb-4 mt-2" },
        [
          _c("h4", { staticClass: "mb-base text-center" }, [
            _vm._v("Asociación de cobros")
          ]),
          _vm._l(_vm.payments, function(item) {
            return _c(
              "div",
              { key: item.idx, staticClass: "mt-4" },
              [
                _c(
                  "vs-table",
                  {
                    attrs: { data: item },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var data = ref.data
                            return _vm._l(data, function(tr, indextr) {
                              return _c(
                                "vs-tr",
                                {
                                  key: indextr,
                                  class:
                                    data[indextr].typeId == 4
                                      ? "text-success font-semibold"
                                      : ""
                                },
                                [
                                  _c(
                                    "vs-td",
                                    { attrs: { data: data[indextr].fatherId } },
                                    [_vm._v(_vm._s(data[indextr].fatherId))]
                                  ),
                                  _c(
                                    "vs-td",
                                    { attrs: { data: data[indextr].type } },
                                    [_vm._v(_vm._s(data[indextr].type))]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: { data: data[indextr].referenceId }
                                    },
                                    [_vm._v(_vm._s(data[indextr].referenceId))]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: {
                                        data: data[indextr].merchantName
                                      }
                                    },
                                    [_vm._v(_vm._s(data[indextr].merchantName))]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: {
                                        data: data[indextr].currencyCode
                                      }
                                    },
                                    [_vm._v(_vm._s(data[indextr].currencyCode))]
                                  ),
                                  _c(
                                    "vs-td",
                                    { attrs: { data: data[indextr].value } },
                                    [_vm._v(_vm._s(data[indextr].value))]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: {
                                        data: data[indextr].transactionNumber
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(data[indextr].transactionNumber)
                                      )
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    { attrs: { data: data[indextr].cardType } },
                                    [_vm._v(_vm._s(data[indextr].cardType))]
                                  ),
                                  data[indextr].cardNumber != "" &&
                                  data[indextr].cardNumber != null
                                    ? _c(
                                        "vs-td",
                                        {
                                          attrs: {
                                            data: data[indextr].cardNumber
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "****" +
                                              _vm._s(data[indextr].cardNumber)
                                          )
                                        ]
                                      )
                                    : _c("vs-td", {
                                        attrs: {
                                          data: data[indextr].cardNumber
                                        }
                                      }),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: {
                                        data: data[indextr].paymentDatetime
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(data[indextr].paymentDatetime)
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            })
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c(
                          "h4",
                          { staticClass: "mb-4 mt-4 text-center font-bold" },
                          [_vm._v(" " + _vm._s(item[0].bookingInfo) + " ")]
                        ),
                        (item[0].paymentTypeId == 3 ||
                          item[0].paymentTypeId == 6 ||
                          item[0].paymentTypeId == 7) &&
                        item[0].productId == 2 &&
                        item[0].supplierId == 1
                          ? _c(
                              "vx-tooltip",
                              { attrs: { text: "Añadir FOP en SABRE" } },
                              [
                                _c(
                                  "vs-button",
                                  {
                                    attrs: {
                                      color: "success",
                                      type: "filled",
                                      "icon-pack": "feather",
                                      icon: "icon-credit-card"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.openpopupFOPSabre()
                                      }
                                    }
                                  },
                                  [_vm._v("SABRE")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "template",
                      { slot: "thead" },
                      [
                        _c("vs-th", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.Main.i18n) ||
                                _vm.resources.Main.name
                            )
                          )
                        ]),
                        _c("vs-th", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.Type.i18n) ||
                                _vm.resources.Type.name
                            )
                          )
                        ]),
                        _c("vs-th", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.ReferenceCode.i18n) ||
                                _vm.resources.ReferenceCode.name
                            )
                          )
                        ]),
                        _c("vs-th", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.Merchant.i18n) ||
                                _vm.resources.Merchant.name
                            )
                          )
                        ]),
                        _c("vs-th", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.Currency.i18n) ||
                                _vm.resources.Currency.name
                            )
                          )
                        ]),
                        _c("vs-th", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.Value.i18n) ||
                                _vm.resources.Value.name
                            )
                          )
                        ]),
                        _c("vs-th", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.TransactionNumber.i18n) ||
                                _vm.resources.TransactionNumber.name
                            )
                          )
                        ]),
                        _c("vs-th", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.CreditCardType.i18n) ||
                                _vm.resources.CreditCardType.name
                            )
                          )
                        ]),
                        _c("vs-th", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.CreditCardNumber.i18n) ||
                                _vm.resources.CreditCardNumber.name
                            )
                          )
                        ]),
                        _c("vs-th", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.PaymentDate.i18n) ||
                                _vm.resources.PaymentDate.name
                            )
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  2
                )
              ],
              1
            )
          })
        ],
        2
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title:
              _vm.$t(_vm.resources.Recapture.i18n) ||
              _vm.resources.Recapture.name,
            active: _vm.popupRecapture
          },
          on: {
            "update:active": function($event) {
              _vm.popupRecapture = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _vm.iboCode == 11670 || _vm.iboCode == 19144
              ? _c("div", { staticClass: "vx-col w-full mb-2" }, [
                  _c("ul", { staticClass: "centerx" }, [
                    _c(
                      "li",
                      [
                        _c(
                          "vs-checkbox",
                          {
                            model: {
                              value: _vm.useAngelCard,
                              callback: function($$v) {
                                _vm.useAngelCard = $$v
                              },
                              expression: "useAngelCard"
                            }
                          },
                          [_vm._v("¿Cobro a crédito de Ángel Duque?")]
                        )
                      ],
                      1
                    )
                  ])
                ])
              : _vm._e(),
            _vm.useAngelCard
              ? _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.CreditCard.i18n) ||
                            _vm.resources.CreditCard.name
                        )
                      )
                    ]),
                    _c(
                      "vs-select",
                      {
                        staticClass: "w-full",
                        attrs: {
                          placeholder:
                            _vm.$t(_vm.resources.Select.i18n) ||
                            _vm.resources.Select.name
                        },
                        model: {
                          value: _vm.angelCarId,
                          callback: function($$v) {
                            _vm.angelCarId = $$v
                          },
                          expression: "angelCarId"
                        }
                      },
                      _vm._l(_vm.angelCardsOption, function(item, index) {
                        return _c("vs-select-item", {
                          key: index,
                          attrs: {
                            value: item.id,
                            text:
                              item.creditCardTypeName +
                              " - **" +
                              item.cardNumber
                          }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              : _c("div", { staticClass: "vx-col w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("label", { staticClass: "vs-input--label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.CreditCard.i18n) ||
                              _vm.resources.CreditCard.name
                          )
                        )
                      ]),
                      _c(
                        "vs-select",
                        {
                          staticClass: "w-full",
                          attrs: {
                            placeholder:
                              _vm.$t(_vm.resources.Select.i18n) ||
                              _vm.resources.Select.name
                          },
                          model: {
                            value: _vm.recaptureCardId,
                            callback: function($$v) {
                              _vm.recaptureCardId = $$v
                            },
                            expression: "recaptureCardId"
                          }
                        },
                        _vm._l(_vm.cardsOption, function(item, index) {
                          return _c("vs-select-item", {
                            key: index,
                            attrs: { value: item.value, text: item.text }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ]),
            _c(
              "div",
              { staticClass: "vx-col w-full mb-2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    placeholder: "0.00",
                    "icon-pack": "feather",
                    icon: "icon-dollar-sign",
                    label:
                      _vm.$t(_vm.resources.Total.i18n) ||
                      _vm.resources.Total.name,
                    maxlength: 10,
                    type: "number"
                  },
                  model: {
                    value: _vm.recaptureValue,
                    callback: function($$v) {
                      _vm.recaptureValue = $$v
                    },
                    expression: "recaptureValue"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            {
              staticClass: "vx-col mb-2 mt-5",
              staticStyle: { "text-align": "right" }
            },
            [
              _c(
                "vs-button",
                {
                  attrs: { color: "primary", type: "filled" },
                  on: {
                    click: function($event) {
                      return _vm.Recapture()
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(_vm.resources.Pay.i18n) || _vm.resources.Pay.name
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title:
              (_vm.$t(_vm.resources.Recapture.i18n) ||
                _vm.resources.Recapture.name) + " Blockpay",
            active: _vm.popupRecaptureBP
          },
          on: {
            "update:active": function($event) {
              _vm.popupRecaptureBP = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full mb-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.AccountNumber.i18n) ||
                        _vm.resources.AccountNumber.name
                    )
                  )
                ]),
                _c("v-select", {
                  attrs: {
                    clearable: false,
                    options: _vm.accountWalletOption,
                    reduce: function(x) {
                      return x.id
                    },
                    label: "name",
                    hint: "" + _vm.accountWalletId
                  },
                  model: {
                    value: _vm.accountWalletId,
                    callback: function($$v) {
                      _vm.accountWalletId = $$v
                    },
                    expression: "accountWalletId"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full mb-2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    placeholder: "0.00",
                    "icon-pack": "feather",
                    icon: "icon-dollar-sign",
                    label:
                      _vm.$t(_vm.resources.Total.i18n) ||
                      _vm.resources.Total.name,
                    maxlength: 10,
                    type: "number"
                  },
                  model: {
                    value: _vm.recaptureValueBlockpay,
                    callback: function($$v) {
                      _vm.recaptureValueBlockpay = $$v
                    },
                    expression: "recaptureValueBlockpay"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            {
              staticClass: "vx-col mb-2 mt-5",
              staticStyle: { "text-align": "right" }
            },
            [
              _c(
                "vs-button",
                {
                  attrs: { color: "primary", type: "filled" },
                  on: {
                    click: function($event) {
                      return _vm.RecaptureBlockpay()
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(_vm.resources.Pay.i18n) || _vm.resources.Pay.name
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            classContent: "popup overFlow",
            title:
              _vm.$t(_vm.resources.Generate.i18n) ||
              _vm.resources.Generate.name,
            active: _vm.popupGenerate
          },
          on: {
            "update:active": function($event) {
              _vm.popupGenerate = $event
            }
          }
        },
        [
          _c("vs-input", {
            staticClass: "w-full mb-2",
            attrs: {
              type: "email",
              "icon-pack": "feather",
              icon: "icon-mail",
              placeholder: "",
              label:
                _vm.$t(_vm.resources.Email.i18n) || _vm.resources.Email.name,
              maxlength: 100
            },
            model: {
              value: _vm.creditCardEmail,
              callback: function($$v) {
                _vm.creditCardEmail = $$v
              },
              expression: "creditCardEmail"
            }
          }),
          _c(
            "ul",
            { staticClass: "centerx", staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "li",
                [
                  _c(
                    "vs-checkbox",
                    {
                      model: {
                        value: _vm.onlyRegister,
                        callback: function($$v) {
                          _vm.onlyRegister = $$v
                        },
                        expression: "onlyRegister"
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.alerts.MsgOnlySaveCard.i18n) ||
                            _vm.alerts.MsgOnlySaveCard.name
                        )
                      )
                    ]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "vx-col mb-2 mt-5",
              staticStyle: { "text-align": "right" }
            },
            [
              _c(
                "vs-button",
                {
                  attrs: { color: "primary", type: "filled" },
                  on: {
                    click: function($event) {
                      return _vm.sendMail()
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(_vm.resources.Send.i18n) ||
                          _vm.resources.Send.name
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          ),
          _vm.paymentLink !== null && _vm.paymentLink !== ""
            ? _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full md:w-3/4 mb-2 mt-base" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        "icon-pack": "feather",
                        icon: "icon-link",
                        "icon-no-border": "",
                        readonly: ""
                      },
                      model: {
                        value: _vm.paymentLink,
                        callback: function($$v) {
                          _vm.paymentLink = $$v
                        },
                        expression: "paymentLink"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col w-full md:w-1/6 mb-4 mt-base" },
                  [
                    _c(
                      "vs-button",
                      {
                        directives: [
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:copy",
                            value: _vm.paymentLink,
                            expression: "paymentLink",
                            arg: "copy"
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:success",
                            value: _vm.onCopy,
                            expression: "onCopy",
                            arg: "success"
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:error",
                            value: _vm.onError,
                            expression: "onError",
                            arg: "error"
                          }
                        ]
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.Copy.i18n) ||
                              _vm.resources.Copy.name
                          )
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.$t(_vm.resources.Issue.i18n) || _vm.resources.Issue.name,
            active: _vm.popupVirtualCard
          },
          on: {
            "update:active": function($event) {
              _vm.popupVirtualCard = $event
            }
          }
        },
        [
          _vm.bookingFlight != undefined && _vm.bookingFlight != null
            ? _c("h4", { staticClass: "mb-4" }, [
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.BookingCode.i18n) ||
                        _vm.resources.BookingCode.name
                    ) +
                      ": #" +
                      _vm._s(_vm.bookingFlight.value)
                  )
                ])
              ])
            : _vm._e(),
          _c("p", { staticClass: "mb-4" }, [
            _vm._v(
              ' Una vez aprobada la transferencia, la reserva de vuelo puede ser emitida, por favor, selecciona con cuál forma de pago quieres emitir la reserva, de lo contrario, hacer clic en el botón "Cancelar". '
            )
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c("label", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.WayToPay.i18n) ||
                      _vm.resources.WayToPay.name
                  )
                )
              ]),
              _c("ul", { staticClass: "leftx" }, [
                _c(
                  "li",
                  [
                    _c(
                      "vs-radio",
                      {
                        attrs: { "vs-value": "7" },
                        model: {
                          value: _vm.wayToPayIssue,
                          callback: function($$v) {
                            _vm.wayToPayIssue = $$v
                          },
                          expression: "wayToPayIssue"
                        }
                      },
                      [_vm._v("Tarjeta de crédito virtual de Amadeus")]
                    )
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c(
                      "vs-radio",
                      {
                        attrs: { "vs-value": "2" },
                        model: {
                          value: _vm.wayToPayIssue,
                          callback: function($$v) {
                            _vm.wayToPayIssue = $$v
                          },
                          expression: "wayToPayIssue"
                        }
                      },
                      [_vm._v("Cash")]
                    )
                  ],
                  1
                )
              ])
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "vx-col mb-2 mt-5",
              staticStyle: { "text-align": "right" }
            },
            [
              _c(
                "vs-button",
                {
                  attrs: { color: "success", type: "filled" },
                  on: {
                    click: function($event) {
                      return _vm.ApproveIssue()
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(_vm.resources.Issue.i18n) ||
                          _vm.resources.Issue.name
                      ) +
                      " "
                  )
                ]
              ),
              _vm._v(" | "),
              _c(
                "vs-button",
                {
                  attrs: { color: "danger", type: "filled" },
                  on: {
                    click: function($event) {
                      _vm.popupVirtualCard = false
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(_vm.resources.Cancel.i18n) ||
                          _vm.resources.Cancel.name
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: "Setear tarjeta en SABRE",
            active: _vm.popupFOPSabre
          },
          on: {
            "update:active": function($event) {
              _vm.popupFOPSabre = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full mb-2" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.CreditCard.i18n) ||
                          _vm.resources.CreditCard.name
                      )
                    )
                  ]),
                  _c(
                    "vs-select",
                    {
                      staticClass: "w-full",
                      attrs: {
                        placeholder:
                          _vm.$t(_vm.resources.Select.i18n) ||
                          _vm.resources.Select.name
                      },
                      model: {
                        value: _vm.cardFopId,
                        callback: function($$v) {
                          _vm.cardFopId = $$v
                        },
                        expression: "cardFopId"
                      }
                    },
                    _vm._l(_vm.cardsOption, function(item, index) {
                      return _c("vs-select-item", {
                        key: index,
                        attrs: { value: item.value, text: item.text }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "vx-col mb-2 mt-5",
              staticStyle: { "text-align": "right" }
            },
            [
              _c(
                "vs-button",
                {
                  attrs: { color: "primary", type: "filled" },
                  on: {
                    click: function($event) {
                      return _vm.setFop()
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(_vm.resources.Pay.i18n) || _vm.resources.Pay.name
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "vs-popup",
        {
          attrs: { title: "Devolución cobro", active: _vm.popupReturn },
          on: {
            "update:active": function($event) {
              _vm.popupReturn = $event
            }
          }
        },
        [
          _c("p", [
            _vm._v(
              " Usted está haciendo la devolución del cobro con número transacción: "
            ),
            _c("strong", [_vm._v(_vm._s(_vm.returns.referenceId))])
          ]),
          _c("div", { staticClass: "vx-row mt-4" }, [
            _c("div", { staticClass: "vx-col md:w-1/2 w-full mb-2" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-dollar-sign",
                      type: "number",
                      label:
                        _vm.$t(_vm.resources.Value.i18n) ||
                        _vm.resources.Value.name,
                      name: "value"
                    },
                    model: {
                      value: _vm.returns.value,
                      callback: function($$v) {
                        _vm.$set(_vm.returns, "value", $$v)
                      },
                      expression: "returns.value"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-col md:w-1/2 w-full mb-2" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-hash",
                      label:
                        _vm.$t(_vm.resources.AuthorizationNumber.i18n) ||
                        _vm.resources.AuthorizationNumber.name,
                      name: "referenceCode"
                    },
                    model: {
                      value: _vm.returns.allocationNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.returns, "allocationNumber", $$v)
                      },
                      expression: "returns.allocationNumber"
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full mb-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.PaymentDate.i18n) ||
                        _vm.resources.PaymentDate.name
                    )
                  )
                ]),
                _c("flat-pickr", {
                  staticClass: "vx-col w-full",
                  attrs: { config: _vm.configdateTimePicker },
                  model: {
                    value: _vm.returns.paymentDate,
                    callback: function($$v) {
                      _vm.$set(_vm.returns, "paymentDate", $$v)
                    },
                    expression: "returns.paymentDate"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            {
              staticClass: "vx-col mb-2 mt-5",
              staticStyle: { "text-align": "right" }
            },
            [
              _c(
                "vs-button",
                {
                  attrs: { color: "primary", type: "filled" },
                  on: {
                    click: function($event) {
                      return _vm.duplicate()
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(_vm.resources.Save.i18n) ||
                          _vm.resources.Save.name
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            fullscreen: "",
            title: _vm.popUpTitle,
            active: _vm.popupAddPayment
          },
          on: {
            "update:active": function($event) {
              _vm.popupAddPayment = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.WayToPay.i18n) ||
                        _vm.resources.WayToPay.name
                    )
                  )
                ]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  attrs: {
                    clearable: false,
                    options: _vm.wayToPayOption,
                    reduce: function(data) {
                      return data.wayToPayId
                    },
                    label: "name",
                    hint: "" + _vm.wayToPayOptionId,
                    disabled: _vm.canUpdate,
                    name: "wayToPay"
                  },
                  on: {
                    input: function($event) {
                      return _vm.setWayToPay("" + _vm.wayToPayOptionId)
                    }
                  },
                  model: {
                    value: _vm.wayToPayOptionId,
                    callback: function($$v) {
                      _vm.wayToPayOptionId = $$v
                    },
                    expression: "wayToPayOptionId"
                  }
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("wayToPay"),
                        expression: "errors.has('wayToPay')"
                      }
                    ],
                    staticClass: "text-danger text-sm"
                  },
                  [_vm._v(_vm._s(_vm.errors.first("wayToPay")))]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Merchant.i18n) ||
                        _vm.resources.Merchant.name
                    )
                  )
                ]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  attrs: {
                    clearable: false,
                    options: _vm.merchantList,
                    reduce: function(data) {
                      return data.merchantId
                    },
                    label: "merchantName",
                    disabled: _vm.canUpdate,
                    name: "merchant"
                  },
                  model: {
                    value: _vm.merchantId,
                    callback: function($$v) {
                      _vm.merchantId = $$v
                    },
                    expression: "merchantId"
                  }
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("merchant"),
                        expression: "errors.has('merchant')"
                      }
                    ],
                    staticClass: "text-danger text-sm"
                  },
                  [_vm._v(_vm._s(_vm.errors.first("merchant")))]
                )
              ],
              1
            ),
            _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|decimal:2",
                        expression: "'required|decimal:2'"
                      }
                    ],
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-dollar-sign",
                      type: "number",
                      label:
                        _vm.$t(_vm.resources.Value.i18n) ||
                        _vm.resources.Value.name,
                      name: "value"
                    },
                    model: {
                      value: _vm.value,
                      callback: function($$v) {
                        _vm.value = $$v
                      },
                      expression: "value"
                    }
                  })
                ],
                1
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("value"),
                      expression: "errors.has('value')"
                    }
                  ],
                  staticClass: "text-danger text-sm"
                },
                [_vm._v(_vm._s(_vm.errors.first("value")))]
              )
            ]),
            _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "max:20|alpha_num",
                        expression: "'max:20|alpha_num'"
                      }
                    ],
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-hash",
                      label:
                        _vm.$t(_vm.resources.AuthorizationNumber.i18n) ||
                        _vm.resources.AuthorizationNumber.name,
                      name: "referenceCode"
                    },
                    model: {
                      value: _vm.referenceCode,
                      callback: function($$v) {
                        _vm.referenceCode = $$v
                      },
                      expression: "referenceCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("referenceCode"),
                      expression: "errors.has('referenceCode')"
                    }
                  ],
                  staticClass: "text-danger text-sm"
                },
                [_vm._v(_vm._s(_vm.errors.first("referenceCode")))]
              )
            ]),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mb-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.PaymentDate.i18n) ||
                        _vm.resources.PaymentDate.name
                    )
                  )
                ]),
                _c("flat-pickr", {
                  staticClass: "vx-col w-full",
                  attrs: { config: _vm.configdateTimePicker },
                  model: {
                    value: _vm.paymentDate,
                    callback: function($$v) {
                      _vm.paymentDate = $$v
                    },
                    expression: "paymentDate"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/4 mb-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Stage.i18n) ||
                        _vm.resources.Stage.name
                    )
                  )
                ]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  attrs: {
                    clearable: false,
                    options: _vm.stageList,
                    reduce: function(x) {
                      return x.id
                    },
                    label: "name",
                    scrollable: "",
                    name: "stage"
                  },
                  model: {
                    value: _vm.stageId,
                    callback: function($$v) {
                      _vm.stageId = $$v
                    },
                    expression: "stageId"
                  }
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("stage"),
                        expression: "errors.has('stage')"
                      }
                    ],
                    staticClass: "text-danger text-sm"
                  },
                  [_vm._v(_vm._s(_vm.errors.first("stage")))]
                )
              ],
              1
            ),
            !_vm.canUpdate
              ? _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full" },
                  [
                    _c("label", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.Remark.i18n) ||
                            _vm.resources.Remark.name
                        )
                      )
                    ]),
                    _c("vs-textarea", {
                      model: {
                        value: _vm.merchantResponse,
                        callback: function($$v) {
                          _vm.merchantResponse = $$v
                        },
                        expression: "merchantResponse"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-hash",
                      label:
                        _vm.$t(_vm.resources.CreatedBy.i18n) ||
                        _vm.resources.CreatedBy.name,
                      name: "createdBy",
                      disabled: ""
                    },
                    model: {
                      value: _vm.createdBy,
                      callback: function($$v) {
                        _vm.createdBy = $$v
                      },
                      expression: "createdBy"
                    }
                  })
                ],
                1
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("createdBy"),
                      expression: "errors.has('createdBy')"
                    }
                  ],
                  staticClass: "text-danger text-sm"
                },
                [_vm._v(_vm._s(_vm.errors.first("createdBy")))]
              )
            ]),
            _vm.paymentId != 0 &&
            _vm.paymentId != null &&
            _vm.oldIdentifier != _vm.identifier
              ? _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full" },
                  [
                    _c("label", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.Comments.i18n) ||
                            _vm.resources.Comments.name
                        )
                      )
                    ]),
                    _c("vs-textarea", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|min:20",
                          expression: "'required|min:20'"
                        }
                      ],
                      attrs: { name: "comments" },
                      model: {
                        value: _vm.comments,
                        callback: function($$v) {
                          _vm.comments = $$v
                        },
                        expression: "comments"
                      }
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("comments"),
                            expression: "errors.has('comments')"
                          }
                        ],
                        staticClass: "text-danger text-sm"
                      },
                      [_vm._v(_vm._s(_vm.errors.first("comments")))]
                    )
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c("h4", { staticClass: "mb-4 mt-4" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(_vm.resources.AdditionalInformation.i18n) ||
                    _vm.resources.AdditionalInformation.name
                ) +
                " "
            )
          ]),
          _vm.wayToPayOptionId == _vm.database.merchantType.virtualCard &&
          _vm.merchantId == _vm.database.merchants.Tp
            ? _c("div", { staticClass: "mb-2" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/6 w-full mb-2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        "icon-pack": "feather",
                        icon: "icon-credit-card",
                        label:
                          _vm.$t(_vm.resources.CreditCard.i18n) ||
                          _vm.resources.CreditCard.name,
                        value: "TP - **4793",
                        readonly: ""
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm.wayToPayOptionId == _vm.database.merchantType.creditCard ||
              _vm.wayToPayOptionId == _vm.database.merchantType.offline ||
              _vm.wayToPayOptionId == _vm.database.merchantType.virtualCard
            ? _c("div", { staticClass: "mb-2" }, [
                _c("div", { staticClass: "vx-row" }, [
                  !_vm.isBusinessCard
                    ? _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                        [
                          _c("ul", { staticClass: "centerx mb-4" }, [
                            _c(
                              "li",
                              [
                                _c(
                                  "vs-checkbox",
                                  {
                                    model: {
                                      value: _vm.haveCardInfo,
                                      callback: function($$v) {
                                        _vm.haveCardInfo = $$v
                                      },
                                      expression: "haveCardInfo"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          _vm.alerts.MsgHaveCardInfo.i18n
                                        ) || _vm.alerts.MsgHaveCardInfo.name
                                      )
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  !_vm.isBusinessCard
                    ? _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                        [
                          _c("ul", { staticClass: "centerx mb-4" }, [
                            _c(
                              "li",
                              [
                                _c(
                                  "vs-checkbox",
                                  {
                                    model: {
                                      value: _vm.isDispersion,
                                      callback: function($$v) {
                                        _vm.isDispersion = $$v
                                      },
                                      expression: "isDispersion"
                                    }
                                  },
                                  [_vm._v("¿Es dispersión de fondos?")]
                                )
                              ],
                              1
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "vx-col md:w-1/2 w-full mb-2" }, [
                    _c("ul", { staticClass: "centerx mb-4" }, [
                      _c(
                        "li",
                        [
                          _c(
                            "vs-checkbox",
                            {
                              model: {
                                value: _vm.isBusinessCard,
                                callback: function($$v) {
                                  _vm.isBusinessCard = $$v
                                },
                                expression: "isBusinessCard"
                              }
                            },
                            [_vm._v("¿Es tarjeta empresarial?")]
                          )
                        ],
                        1
                      )
                    ]),
                    _vm.isBusinessCard
                      ? _c(
                          "ul",
                          { staticClass: "leftx" },
                          _vm._l(_vm.cardsList, function(item) {
                            return _c(
                              "li",
                              { key: item.idx },
                              [
                                _c(
                                  "vs-radio",
                                  {
                                    attrs: { "vs-value": item.id },
                                    model: {
                                      value: _vm.creditCardId1,
                                      callback: function($$v) {
                                        _vm.creditCardId1 = $$v
                                      },
                                      expression: "creditCardId1"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(item.creditCardTypeName) +
                                        " - ***" +
                                        _vm._s(item.cardNumber)
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _vm._e()
                  ])
                ]),
                _vm.haveCardInfo && !_vm.isBusinessCard
                  ? _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                        [
                          _c(
                            "div",
                            { staticClass: "vx-col w-full" },
                            [
                              _c("label", { staticClass: "vs-input--label" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(_vm.resources.Type.i18n) ||
                                      _vm.resources.Type.name
                                  )
                                )
                              ]),
                              _c("v-select", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'"
                                  }
                                ],
                                attrs: {
                                  clearable: false,
                                  options: _vm.creditcardOption,
                                  reduce: function(data) {
                                    return data.cardId
                                  },
                                  label: "name",
                                  hint: "" + _vm.creditCard.creditCardTypeId,
                                  disabled: _vm.canUpdate,
                                  name: "cardType"
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.setInstallmentNumber(
                                      "" + _vm.creditCard.creditCardTypeId
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.creditCard.creditCardTypeId,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.creditCard,
                                      "creditCardTypeId",
                                      $$v
                                    )
                                  },
                                  expression: "creditCard.creditCardTypeId"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("cardType"),
                                  expression: "errors.has('cardType')"
                                }
                              ],
                              staticClass: "text-danger text-sm"
                            },
                            [_vm._v(_vm._s(_vm.errors.first("cardType")))]
                          )
                        ]
                      ),
                      _vm.paymentId != 0 && _vm.paymentId != null
                        ? _c(
                            "div",
                            { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                            [
                              _c(
                                "div",
                                { staticClass: "vx-col w-full" },
                                [
                                  _c("vs-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'"
                                      }
                                    ],
                                    staticClass: "w-full",
                                    attrs: {
                                      "icon-pack": "feather",
                                      icon: "icon-credit-card",
                                      label:
                                        _vm.$t(
                                          _vm.resources.CreditCardNumber.i18n
                                        ) ||
                                        _vm.resources.CreditCardNumber.name,
                                      disabled: ""
                                    },
                                    model: {
                                      value: _vm.creditCard.cardNumber,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.creditCard,
                                          "cardNumber",
                                          $$v
                                        )
                                      },
                                      expression: "creditCard.cardNumber"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        : _c(
                            "div",
                            { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                            [
                              _c(
                                "div",
                                { staticClass: "vx-col w-full" },
                                [
                                  _c("vs-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required|credit_card",
                                        expression: "'required|credit_card'"
                                      }
                                    ],
                                    staticClass: "w-full",
                                    attrs: {
                                      "icon-pack": "feather",
                                      icon: "icon-credit-card",
                                      type: "number",
                                      label:
                                        _vm.$t(
                                          _vm.resources.CreditCardNumber.i18n
                                        ) ||
                                        _vm.resources.CreditCardNumber.name,
                                      name: "cardNumber"
                                    },
                                    model: {
                                      value: _vm.creditCard.cardNumber,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.creditCard,
                                          "cardNumber",
                                          $$v
                                        )
                                      },
                                      expression: "creditCard.cardNumber"
                                    }
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has("cardNumber"),
                                          expression: "errors.has('cardNumber')"
                                        }
                                      ],
                                      staticClass: "text-danger text-sm"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.errors.first("cardNumber"))
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                        [
                          _c(
                            "div",
                            { staticClass: "vx-col w-full" },
                            [
                              _c("vs-input", {
                                staticClass: "w-full",
                                attrs: {
                                  "icon-pack": "feather",
                                  icon: "icon-lock",
                                  maxlength: _vm.maxCvv,
                                  label:
                                    _vm.$t(_vm.resources.SecurityCode.i18n) ||
                                    _vm.resources.SecurityCode.name,
                                  name: "SecurityCode",
                                  disabled: _vm.canUpdate
                                },
                                model: {
                                  value: _vm.creditCard.cardSecurityCode,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.creditCard,
                                      "cardSecurityCode",
                                      $$v
                                    )
                                  },
                                  expression: "creditCard.cardSecurityCode"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("SecurityCode"),
                                  expression: "errors.has('SecurityCode')"
                                }
                              ],
                              staticClass: "text-danger text-sm"
                            },
                            [_vm._v(_vm._s(_vm.errors.first("SecurityCode")))]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col w-full md:w-1/4 mb-4" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.ExpirationYear.i18n) ||
                                  _vm.resources.ExpirationYear.name
                              )
                            )
                          ]),
                          _c(
                            "vs-select",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'"
                                }
                              ],
                              staticClass: "vx-col w-full",
                              attrs: {
                                "icon-no-border": "",
                                disabled: _vm.canUpdate,
                                name: "expirationYear"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.expirationYearChange($event)
                                }
                              },
                              model: {
                                value: _vm.expirationYear,
                                callback: function($$v) {
                                  _vm.expirationYear = $$v
                                },
                                expression: "expirationYear"
                              }
                            },
                            _vm._l(_vm.expirationYearOption, function(
                              item,
                              index
                            ) {
                              return _c("vs-select-item", {
                                key: index,
                                attrs: { value: item, text: item }
                              })
                            }),
                            1
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("stage"),
                                  expression: "errors.has('stage')"
                                }
                              ],
                              staticClass: "text-danger text-sm"
                            },
                            [_vm._v(_vm._s(_vm.errors.first("expirationYear")))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col w-full md:w-1/4 mb-4" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.ExpirationMonth.i18n) ||
                                  _vm.resources.ExpirationMonth.name
                              )
                            )
                          ]),
                          _c(
                            "vs-select",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'"
                                }
                              ],
                              staticClass: "vx-col w-full",
                              attrs: {
                                "icon-no-border": "",
                                disabled: _vm.canUpdate,
                                name: "expirationMonth"
                              },
                              model: {
                                value: _vm.expirationMonth,
                                callback: function($$v) {
                                  _vm.expirationMonth = $$v
                                },
                                expression: "expirationMonth"
                              }
                            },
                            _vm._l(_vm.expirationMonthOption, function(
                              item,
                              index
                            ) {
                              return _c("vs-select-item", {
                                key: index,
                                attrs: { value: item, text: item }
                              })
                            }),
                            1
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("expirationMonth"),
                                  expression: "errors.has('expirationMonth')"
                                }
                              ],
                              staticClass: "text-danger text-sm"
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.errors.first("expirationMonth"))
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                        [
                          _c(
                            "div",
                            { staticClass: "vx-col w-full" },
                            [
                              _c("vs-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: {
                                      required: true,
                                      regex: /^([a-zA-Z ]+)$/
                                    },
                                    expression:
                                      "{ required: true, regex: /^([a-zA-Z ]+)$/ }"
                                  }
                                ],
                                staticClass: "w-full",
                                attrs: {
                                  "icon-pack": "feather",
                                  icon: "icon-user",
                                  label:
                                    _vm.$t(_vm.resources.Name.i18n) ||
                                    _vm.resources.Name.name,
                                  name: "cardHolderName",
                                  disabled: _vm.canUpdate
                                },
                                model: {
                                  value: _vm.creditCard.cardHolderName,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.creditCard,
                                      "cardHolderName",
                                      $$v
                                    )
                                  },
                                  expression: "creditCard.cardHolderName"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("cardHolderName"),
                                  expression: "errors.has('cardHolderName')"
                                }
                              ],
                              staticClass: "text-danger text-sm"
                            },
                            [_vm._v(_vm._s(_vm.errors.first("cardHolderName")))]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                        [
                          _c(
                            "div",
                            { staticClass: "vx-col w-full" },
                            [
                              _c("vs-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: {
                                      required: true,
                                      regex: /^([a-zA-Z ]+)$/
                                    },
                                    expression:
                                      "{ required: true, regex: /^([a-zA-Z ]+)$/ }"
                                  }
                                ],
                                staticClass: "w-full",
                                attrs: {
                                  "icon-pack": "feather",
                                  icon: "icon-user",
                                  label:
                                    _vm.$t(_vm.resources.LastName.i18n) ||
                                    _vm.resources.LastName.name,
                                  name: "cardholderLastName",
                                  disabled: _vm.canUpdate
                                },
                                model: {
                                  value: _vm.creditCard.cardholderLastName,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.creditCard,
                                      "cardholderLastName",
                                      $$v
                                    )
                                  },
                                  expression: "creditCard.cardholderLastName"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("cardholderLastName"),
                                  expression: "errors.has('cardholderLastName')"
                                }
                              ],
                              staticClass: "text-danger text-sm"
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.errors.first("cardholderLastName"))
                              )
                            ]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                        [
                          _c(
                            "div",
                            { staticClass: "vx-col w-full" },
                            [
                              _c("vs-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required|max:20|alpha_num",
                                    expression: "'required|max:20|alpha_num'"
                                  }
                                ],
                                staticClass: "w-full",
                                attrs: {
                                  "icon-pack": "feather",
                                  icon: "icon-hash",
                                  "icon-no-border": "",
                                  label:
                                    _vm.$t(_vm.resources.DocumentNumber.i18n) ||
                                    _vm.resources.DocumentNumber.name,
                                  name: "documentCard",
                                  disabled: _vm.canUpdate
                                },
                                model: {
                                  value: _vm.creditCard.documentNumber,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.creditCard,
                                      "documentNumber",
                                      $$v
                                    )
                                  },
                                  expression: "creditCard.documentNumber"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("documentCard"),
                                  expression: "errors.has('documentCard')"
                                }
                              ],
                              staticClass: "text-danger text-sm"
                            },
                            [_vm._v(_vm._s(_vm.errors.first("documentCard")))]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                        [
                          _c(
                            "div",
                            { staticClass: "vx-col w-full" },
                            [
                              _c("vs-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required|max:100|email",
                                    expression: "'required|max:100|email'"
                                  }
                                ],
                                staticClass: "w-full",
                                attrs: {
                                  "icon-pack": "feather",
                                  icon: "icon-mail",
                                  type: "email",
                                  label:
                                    _vm.$t(_vm.resources.Email.i18n) ||
                                    _vm.resources.Email.name,
                                  name: "emailCard",
                                  disabled: _vm.canUpdate
                                },
                                model: {
                                  value: _vm.creditCard.email,
                                  callback: function($$v) {
                                    _vm.$set(_vm.creditCard, "email", $$v)
                                  },
                                  expression: "creditCard.email"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("emailCard"),
                                  expression: "errors.has('emailCard')"
                                }
                              ],
                              staticClass: "text-danger text-sm"
                            },
                            [_vm._v(_vm._s(_vm.errors.first("emailCard")))]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                        [
                          _c(
                            "div",
                            { staticClass: "vx-col w-full" },
                            [
                              _c("vs-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: {
                                      required: true,
                                      regex: /^[a-zA-Z0-9 ]{5,50}$/
                                    },
                                    expression:
                                      "{ required: true, regex: /^[a-zA-Z0-9 ]{5,50}$/ }"
                                  }
                                ],
                                staticClass: "w-full",
                                attrs: {
                                  "icon-pack": "feather",
                                  icon: "icon-map-pin",
                                  "icon-no-border": "",
                                  label:
                                    _vm.$t(_vm.resources.Address.i18n) ||
                                    _vm.resources.Address.name,
                                  name: "addressCard",
                                  disabled: _vm.canUpdate
                                },
                                model: {
                                  value: _vm.creditCard.address,
                                  callback: function($$v) {
                                    _vm.$set(_vm.creditCard, "address", $$v)
                                  },
                                  expression: "creditCard.address"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("addressCard"),
                                  expression: "errors.has('addressCard')"
                                }
                              ],
                              staticClass: "text-danger text-sm"
                            },
                            [_vm._v(_vm._s(_vm.errors.first("addressCard")))]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                        [
                          _c(
                            "div",
                            { staticClass: "vx-col w-full" },
                            [
                              _c("vs-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: { required: true, regex: /^\d{5}$/ },
                                    expression:
                                      "{ required: true, regex: /^\\d{5}$/ }"
                                  }
                                ],
                                staticClass: "w-full",
                                attrs: {
                                  "icon-pack": "feather",
                                  icon: "icon-hash",
                                  type: "number",
                                  label:
                                    _vm.$t(_vm.resources.ZipCode.i18n) ||
                                    _vm.resources.ZipCode.name,
                                  name: "zipCodeCard",
                                  disabled: _vm.canUpdate
                                },
                                model: {
                                  value: _vm.creditCard.zipCode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.creditCard, "zipCode", $$v)
                                  },
                                  expression: "creditCard.zipCode"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("zipCodeCard"),
                                  expression: "errors.has('zipCodeCard')"
                                }
                              ],
                              staticClass: "text-danger text-sm"
                            },
                            [_vm._v(_vm._s(_vm.errors.first("zipCodeCard")))]
                          )
                        ]
                      ),
                      _vm.paymentId != 0 && _vm.paymentId != ""
                        ? _c(
                            "div",
                            { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                            [
                              _c(
                                "div",
                                { staticClass: "vx-col w-full" },
                                [
                                  _c("vs-input", {
                                    staticClass: "w-full",
                                    attrs: {
                                      "icon-pack": "feather",
                                      icon: "icon-map",
                                      "icon-no-border": "",
                                      label:
                                        _vm.$t(
                                          _vm.resources.ResidenceCity.i18n
                                        ) || _vm.resources.ResidenceCity.name,
                                      disabled: ""
                                    },
                                    model: {
                                      value: _vm.creditCard.cityName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.creditCard,
                                          "cityName",
                                          $$v
                                        )
                                      },
                                      expression: "creditCard.cityName"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                        [
                          _c(
                            "div",
                            { staticClass: "vx-col w-full" },
                            [
                              _c("label", { staticClass: "vs-input--label" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      _vm.resources.InstallmentNumber.i18n
                                    ) || _vm.resources.InstallmentNumber.name
                                  )
                                )
                              ]),
                              _c("v-select", {
                                attrs: {
                                  clearable: false,
                                  options: _vm.installmentNumberOption,
                                  reduce: function(data) {
                                    return data.cardId
                                  },
                                  label: "name",
                                  disabled: _vm.canUpdate
                                },
                                model: {
                                  value: _vm.creditCard.installmentNumber,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.creditCard,
                                      "installmentNumber",
                                      $$v
                                    )
                                  },
                                  expression: "creditCard.installmentNumber"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ])
                  : !_vm.isBusinessCard
                  ? _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                        [
                          _c(
                            "div",
                            { staticClass: "vx-col w-full" },
                            [
                              _c("label", { staticClass: "vs-input--label" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(_vm.resources.CreditCard.i18n) ||
                                      _vm.resources.CreditCard.name
                                  )
                                )
                              ]),
                              _c(
                                "vs-select",
                                {
                                  staticClass: "w-full",
                                  attrs: {
                                    placeholder:
                                      _vm.$t(_vm.resources.Select.i18n) ||
                                      _vm.resources.Select.name
                                  },
                                  model: {
                                    value: _vm.creditCardId,
                                    callback: function($$v) {
                                      _vm.creditCardId = $$v
                                    },
                                    expression: "creditCardId"
                                  }
                                },
                                _vm._l(_vm.cardsOption, function(item, index) {
                                  return _c("vs-select-item", {
                                    key: index,
                                    attrs: {
                                      value: item.value,
                                      text: item.text
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm.wayToPayOptionId == _vm.database.merchantType.EWallet
            ? _c("div", { staticClass: "mb-2" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                    [
                      _c("label", { staticClass: "vs-input--label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.Select.i18n) ||
                              _vm.resources.Select.name
                          )
                        )
                      ]),
                      _c("v-select", {
                        attrs: {
                          clearable: false,
                          options: _vm.walletOption,
                          reduce: function(data) {
                            return data.walletId
                          },
                          label: "name",
                          hint: "" + _vm.walletId,
                          disabled: _vm.canUpdate
                        },
                        on: {
                          input: function($event) {
                            return _vm.setAccountWallet("" + _vm.walletId)
                          }
                        },
                        model: {
                          value: _vm.walletId,
                          callback: function($$v) {
                            _vm.walletId = $$v
                          },
                          expression: "walletId"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.walletId == _vm.database.merchants.BlockPay &&
                  (_vm.paymentId == 0 || _vm.paymentId == null)
                    ? _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.AccountNumber.i18n) ||
                                  _vm.resources.AccountNumber.name
                              )
                            )
                          ]),
                          _c("v-select", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            attrs: {
                              clearable: false,
                              options: _vm.accountWalletOption,
                              reduce: function(x) {
                                return x.id
                              },
                              label: "name",
                              hint: "" + _vm.accountWalletId,
                              name: "accountWalletId",
                              disabled: _vm.canUpdate
                            },
                            model: {
                              value: _vm.accountWalletId,
                              callback: function($$v) {
                                _vm.accountWalletId = $$v
                              },
                              expression: "accountWalletId"
                            }
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("accountWalletId"),
                                  expression: "errors.has('accountWalletId')"
                                }
                              ],
                              staticClass: "text-danger text-sm"
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.errors.first("accountWalletId"))
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.walletId == _vm.database.merchants.Payout &&
                  (_vm.paymentId == 0 || _vm.paymentId == null)
                    ? _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                        [
                          _c(
                            "div",
                            { staticClass: "vx-col w-full" },
                            [
                              _c("vs-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'"
                                  }
                                ],
                                staticClass: "w-full",
                                attrs: {
                                  "icon-pack": "feather",
                                  icon: "icon-user",
                                  type: "number",
                                  label:
                                    _vm.$t(_vm.resources.DistributorID.i18n) ||
                                    _vm.resources.DistributorID.name,
                                  name: "itaCodeWallet",
                                  disabled: _vm.canUpdate
                                },
                                model: {
                                  value: _vm.itaCodeWallet,
                                  callback: function($$v) {
                                    _vm.itaCodeWallet = $$v
                                  },
                                  expression: "itaCodeWallet"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("itaCodeWallet"),
                                  expression: "errors.has('itaCodeWallet')"
                                }
                              ],
                              staticClass: "text-danger text-sm"
                            },
                            [_vm._v(_vm._s(_vm.errors.first("itaCodeWallet")))]
                          )
                        ]
                      )
                    : _vm._e()
                ])
              ])
            : _vm._e(),
          _vm.wayToPayOptionId == _vm.database.merchantType.transfer
            ? _c("div", { staticClass: "mb-2" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                    [
                      _c("label", { staticClass: "vs-input--label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.BankName.i18n) ||
                              _vm.resources.BankName.name
                          )
                        )
                      ]),
                      _c("v-select", {
                        attrs: {
                          clearable: false,
                          options: _vm.bankType,
                          reduce: function(data) {
                            return data.id
                          },
                          label: "name",
                          hint: _vm.transfer.bankTypeId,
                          disabled: _vm.canUpdate
                        },
                        on: {
                          input: function($event) {
                            return _vm.setAccountNumber(_vm.transfer.bankTypeId)
                          }
                        },
                        model: {
                          value: _vm.transfer.bankTypeId,
                          callback: function($$v) {
                            _vm.$set(_vm.transfer, "bankTypeId", $$v)
                          },
                          expression: "transfer.bankTypeId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                    [
                      _c("label", { staticClass: "vs-input--label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.AccountNumber.i18n) ||
                              _vm.resources.AccountNumber.name
                          )
                        )
                      ]),
                      _c("v-select", {
                        attrs: {
                          clearable: false,
                          options: _vm.accountNumberType,
                          reduce: function(data) {
                            return data.id
                          },
                          label: "account",
                          disabled: _vm.canUpdate
                        },
                        model: {
                          value: _vm.transfer.bankAccountId,
                          callback: function($$v) {
                            _vm.$set(_vm.transfer, "bankAccountId", $$v)
                          },
                          expression: "transfer.bankAccountId"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "vx-col mb-2 mt-5",
              staticStyle: { "text-align": "right" }
            },
            [
              _c(
                "vs-button",
                {
                  attrs: { color: "primary", type: "filled" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.valid($event)
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(_vm.resources.Save.i18n) ||
                          _vm.resources.Save.name
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          ),
          _vm.paymentId != 0 && _vm.paymentId != null
            ? _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-calendar",
                          label:
                            _vm.$t(_vm.resources.CreationDate.i18n) ||
                            _vm.resources.CreationDate.name,
                          disabled: ""
                        },
                        model: {
                          value: _vm.creationDate,
                          callback: function($$v) {
                            _vm.creationDate = $$v
                          },
                          expression: "creationDate"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-file-text",
                          label:
                            _vm.$t(_vm.resources.Invoice.i18n) ||
                            _vm.resources.Invoice.name,
                          readonly: ""
                        },
                        model: {
                          value: _vm.invoiceId,
                          callback: function($$v) {
                            _vm.invoiceId = $$v
                          },
                          expression: "invoiceId"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-alert-triangle",
                          label:
                            _vm.$t(_vm.resources.Accertify.i18n) ||
                            _vm.resources.Accertify.name,
                          disabled: ""
                        },
                        model: {
                          value: _vm.accertify,
                          callback: function($$v) {
                            _vm.accertify = $$v
                          },
                          expression: "accertify"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-hash",
                          label:
                            _vm.$t(_vm.resources.AccertifyPoints.i18n) ||
                            _vm.resources.AccertifyPoints.name,
                          disabled: ""
                        },
                        model: {
                          value: _vm.accertifyPoints,
                          callback: function($$v) {
                            _vm.accertifyPoints = $$v
                          },
                          expression: "accertifyPoints"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-file-text",
                          label:
                            _vm.$t(_vm.resources.AccertifyId.i18n) ||
                            _vm.resources.AccertifyId.name,
                          readonly: ""
                        },
                        model: {
                          value: _vm.accertifyId,
                          callback: function($$v) {
                            _vm.accertifyId = $$v
                          },
                          expression: "accertifyId"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full" },
                  [
                    _c("label", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.Answer.i18n) ||
                            _vm.resources.Answer.name
                        )
                      )
                    ]),
                    _c("vs-textarea", {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.merchantResponse,
                        callback: function($$v) {
                          _vm.merchantResponse = $$v
                        },
                        expression: "merchantResponse"
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }