<template>
  <div>
    <div id="ag-grid-demo">
      <vx-card>
        <h4 class="font-semibold mb-base">
          {{ $t(resources.Access.i18n) || resources.Detail.name }}
        </h4>
        <div class="vx-row mb-base">
          <div class="vx-col w-full md:w-1/5 mb-2">
            <label>
              {{ $t(resources.StartDate.i18n) || resources.StartDate.name }}
            </label>
            <datepicker
              :language="languages[language]"
              format="d MMMM yyyy"
              v-model="start_date"
            ></datepicker>
          </div>
          <div class="vx-col w-full md:w-1/5 mb-2">
            <label>
              {{ $t(resources.EndDate.i18n) || resources.EndDate.name }}
            </label>
            <datepicker
              :language="languages[language]"
              format="d MMMM yyyy"
              v-model="end_date"
            ></datepicker>
          </div>
          <div class="vx-col w-full md:w-1/6 mb-2">
            <vs-input
              type="number"
              class="w-full"
              icon-pack="feather"
              icon="icon-user"
              icon-no-border
              :label="
                $t(resources.DistributorID.i18n) || resources.DistributorID.name
              "
              v-model="itaCodeForm"
            />
          </div>
          <div class="vx-col w-full md:w-1/6 mb-2">
            <vs-select
              class="vx-col w-full"
              :label="$t(resources.Status.i18n) || resources.Status.name"
              v-model="status"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="$t(item.i18n) || item.text"
                v-for="(item, index) in statusList"
              />
            </vs-select>
          </div>
          <div class="vx-col w-full md:w-1/6 mb-2 mt-5">
            <vs-button color="primary" type="filled" @click="getListAccess()">
              {{ $t(resources.Search.i18n) || resources.Search.name }}
            </vs-button>
          </div>
        </div>
        <div class="flex flex-wrap justify-between items-center">
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2">
                  {{
                    currentPage * paginationPageSize - (paginationPageSize - 1)
                  }}
                  -
                  {{
                    accessData.length - currentPage * paginationPageSize > 0
                      ? currentPage * paginationPageSize
                      : accessData.length
                  }}
                  of {{ accessData.length }}
                </span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
          <div class="vx-row">
            <div class="mt-2">
              <vs-icon
                icon-pack="material-icons"
                icon="check_circle"
                size="small"
                color="rgba(var(--vs-success), 1)"
              ></vs-icon>
              {{ $t(resources.Active.i18n) || resources.Active.name }}&nbsp;
            </div>
            <div class="mt-2">
              <vs-icon
                icon-pack="material-icons"
                icon="highlight_off"
                size="small"
                color="rgba(var(--vs-danger), 1)"
              ></vs-icon>
              {{ $t(resources.Inactive.i18n) || resources.Inactive.name }}
            </div>
          </div>
          <div
            class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
          >
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              :placeholder="$t(resources.Search.i18n) || resources.Search.name"
            />
            <vs-button
              class="mb-4 md:mb-0 mr-1"
              @click="gridApi.exportDataAsCsv()"
            >
              {{ $t(resources.ExportAs.i18n) || resources.ExportAs.name }}
            </vs-button>
            <vs-button
              color="success"
              class="mb-4 md:mb-0"
              @click="popupActive = true"
            >
              {{ $t(resources.Create.i18n) || resources.Create.name }}
            </vs-button>
          </div>
        </div>

        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="accessData"
          rowSelection="single"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          @row-selected="openConfirm"
        ></ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
    <vs-popup
      :title="$t(resources.Access.i18n) || resources.Access.name"
      :active.sync="popupActive"
    >
      <div class="vx-row mb-base">
        <div class="vx-col w-full mb-2">
          <vs-input
            type="number"
            class="w-full"
            icon-pack="feather"
            icon="icon-user"
            icon-no-border
            :label="
              $t(resources.DistributorID.i18n) || resources.DistributorID.name
            "
            v-model="itaCodeCreate"
          />
        </div>
        <div class="vx-col w-full mb-2">
          <label>
            {{
              $t(resources.ExpirationDate.i18n) || resources.ExpirationDate.name
            }}
          </label>
          <datepicker
            :language="languages[language]"
            format="d MMMM yyyy"
            v-model="expirateDate"
          ></datepicker>
        </div>
        <div class="vx-col w-full mb-2">
          <label class="vs-input--label">{{
            $t(resources.Product.i18n) || resources.Product.name
          }}</label>
          <v-select
            v-model="productSelectId"
            :clearable="false"
            :options="productSelect"
            :reduce="(data) => data.code"
            label="name"
          ></v-select>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2 mt-5">
          <vs-button color="primary" type="filled" @click="createAccess()">
            {{ $t(resources.Create.i18n) || resources.Create.name }}
          </vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import { AgGridVue } from "ag-grid-vue";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import axios from "axios";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import vSelect from "vue-select";

export default {
  components: {
    AgGridVue,
    Datepicker,
    vSelect,
  },
  data() {
    return {
      resources: resources,
      alerts: alerts,
      language: this.$i18n.locale,
      languages: lang,
      start_date: null,
      end_date: null,
      status: "1",
      itaCode: JSON.parse(localStorage.getItem("userInfo")).itaCode,
      email: JSON.parse(localStorage.getItem("userInfo")).email,
      itaCodeForm: "",
      expirateDate: null,
      itaCodeCreate: "",
      popupActive: false,
      statusList: [
        {
          text: "All",
          value: "0,1",
          i18n: "All",
        },
        {
          text: "Active",
          value: "1",
          i18n: "Active",
        },
        {
          text: "Inactive",
          value: "0",
          i18n: "Inactive",
        },
      ],
      activeConfirm: false,
      nodeId: 0,
      nodeItaCode: 0,
      accessData: [],
      productSelectId: "",
      productSelect: [],
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: "Id",
          field: "Id",
          hide: true,
        },
        {
          headerName: this.$i18n.t("Status"),
          field: "Activo",
          width: 110,
          cellRenderer: function (event) {
            let object = "";
            switch (event.node.data.Activo) {
              case false:
                object =
                  '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-danger), 1);">highlight_off</i>';
                break;
              case true:
                object =
                  '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-success), 1);">check_circle</i>';
                break;
              default:
                break;
            }
            return object;
          },
        },
        {
          headerName: this.$i18n.t("DistributorID"),
          field: "CodigoIta",
          filter: true,
          width: 120,
        },
        {
          headerName: this.$i18n.t("ExpirationDate"),
          field: "FechaRenovacion",
          filter: true,
        },
        {
          headerName: this.$i18n.t("Country"),
          field: "CodigoPais",
          filter: true,
          width: 110,
        },
        {
          headerName: this.$i18n.t("CreateDate"),
          field: "FechaCreacion",
          filter: true,
        },
        {
          headerName: this.$i18n.t("CreatedBy"),
          field: "CreadoPor",
          filter: true,
        },
        {
          headerName: this.$i18n.t("ChangeDate"),
          field: "FechaModificacion",
          filter: true,
        },
        {
          headerName: this.$i18n.t("UpdatedBy"),
          field: "ModificadoPor",
          filter: true,
        },
        {
          headerName: this.$i18n.t("Product"),
          field: "Producto",
          filter: true,
          width: 130,
        },
        {
          headerName: this.$i18n.t("IsFinanced"),
          field: "EsFinanciado",
          cellRenderer: function (event) {
            let object = "";
            switch (event.node.data.EsFinanciado) {
              case false:
                object =
                  '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-danger), 1);">highlight_off</i>';
                break;
              case true:
                object =
                  '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-success), 1);">check_circle</i>';
                break;
              default:
                break;
            }
            return object;
          },
        },
      ],
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("level", null);
      } else this.gridOptions.columnApi.setColumnPinned("level", "left");
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 20;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  created: function () {
    this.getListAccess();
    this.getProductByType();
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    async getListAccess() {
      this.$vs.loading();
      let startDate = "";
      let endDate = "";

      if (this.start_date != null && this.end_date != null) {
        startDate = new Date(this.start_date).toLocaleDateString("en-US");
        endDate = new Date(this.end_date).toLocaleDateString("en-US");
      }

      await axios({
        method: "POST",
        url: process.env.VUE_APP_OVAPI + "getListAccess",
        data: {
          distid: this.itaCodeForm,
          state: this.status,
          start_date: startDate,
          end_date: endDate,
        },
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("loggedIn"),
        },
      }).then(
        (result) => {
          if (result.data.rowsAffected[0] == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.accessData = [];
          } else {
            this.accessData = result.data.recordsets[0];
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },

    async createAccess() {
      this.$vs.loading();
      var date = new Date(this.expirateDate).toLocaleDateString("en-US");

      await axios({
        method: "POST",
        url: process.env.VUE_APP_OVAPI + "createAccess",
        data: {
          distid: this.itaCodeCreate,
          expirationDate: date,
          createdBy: this.email,
          productCode: this.productSelectId,
        },
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("loggedIn"),
        },
      }).then(
        (result) => {
          if (result.data.code == 500) {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError"),
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-check",
            });
            setTimeout(() => {
              this.$vs.loading.close();
              window.location.reload();
            }, 1000);
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },

    openConfirm(event) {
      this.nodeId = event.node.data.Id;
      this.nodeItaCode = event.node.data.CodigoIta;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$i18n.t("Confirm"),
        text: this.$i18n
          .t("MsgDisabledQuestion")
          .replace("{0}", event.node.data.CodigoIta),
        accept: this.inactive,
      });
    },

    async inactive() {
      this.$vs.loading();

      await axios({
        method: "POST",
        url: process.env.VUE_APP_OVAPI + "inactiveAccess",
        data: {
          id: this.nodeId,
          createdBy: this.email,
          distid: this.nodeItaCode,
        },
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("loggedIn"),
        },
      }).then(
        (result) => {
          if (result.data.code == 500) {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError"),
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-check",
            });
            setTimeout(() => {
              this.$vs.loading.close();
              window.location.reload();
            }, 1000);
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },

    async getProductByType() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Product/ByType/${this.$i18n.locale.toUpperCase()}/2`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (result.data) {
            this.productSelect = result.data.filter(
              (x) =>
                x.code != "NEO001" &&
                x.code != "PRM001" &&
                x.code != "PRM003" &&
                x.code != "UPVIVE03" &&
                x.name.indexOf("Autoship")
            );
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
};
</script>
