<template>
  <vx-card
    class="email-compose"
    :title="$t(resources.NewMessage.i18n) || resources.NewMessage.name"
  >
    <form>
      <div class="vx-row mb-2">
        <div class="vx-col md:w-1/2 w-full">
          <label class="vs-input--label">{{
            $t(resources.From.i18n) || resources.From.name
          }}</label>
          <v-select
            v-validate="'required'"
            type="text"
            class="w-full"
            icon-pack="feather"
            icon="icon-arrow-down"
            icon-no-border
            :clearable="false"
            :options="fromOption"
            :reduce="(mail) => mail.text"
            label="text"
            v-model="mailFrom"
            name="De"
          />
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <label class="vs-input--label">{{
            $t(resources.To.i18n) || resources.To.name
          }}</label>
          <vs-input
            v-validate="'required'"
            name="mailTo"
            v-model="mailTo"
            class="w-full"
            :maxlength="250"
          />
          <span
            class="text-danger text-sm mt-0"
            v-show="errors.has('mailTo')"
            >{{ errors.first("mailTo") }}</span
          >
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col md:w-1/2 w-full">
          <label class="vs-input--label">{{
            $t(resources.Subject.i18n) || resources.Subject.name
          }}</label>
          <vs-input
            name="mailSubject"
            v-model="mailSubject"
            class="w-full"
            :maxlength="50"
            v-validate="'required'"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('mailSubject')"
            >{{ errors.first("mailSubject") }}</span
          >
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <label class="vs-input--label">{{
            $t(resources.SelectTemplate.i18n) || resources.SelectTemplate.name
          }}</label>
          <v-select
            type="text"
            class="w-full"
            icon-pack="feather"
            icon="icon-arrow-down"
            icon-no-border
            :clearable="true"
            :options="templateOption"
            :reduce="(template) => template.id"
            label="name"
            v-model="template"
            v-on:input="templateBody(`${template}`)"
            :hint="`${template}`"
          />
        </div>
      </div>

      <quill-editor v-model="mailMessage" :options="editorOption" />

      <div class="vx-row mb-2 mt-2">
        <div class="vx-col md:w-1/2 w-full">
          <label class="btn btn-primary" style="margin-right: 80px">{{
            $t(resources.Attachment.i18n) || t(resources.Attachment.name)
          }}</label>
          <vs-input type="file" class="w-full" @change="onFileSelected" />
        </div>
      </div>

      <vs-row vs-type="flex" vs-justify="center">
        <vs-button
          type="filled"
          class="mt-5 block text-left"
          @click.prevent="submitForm"
          >{{ $t(resources.Send.i18n) || resources.Send.name }}</vs-button
        >
      </vs-row>
    </form>
  </vx-card>
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";

import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import axios from "axios";
import auth from "@/assets/resources/auth.json";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import vSelect from "vue-select";
import utilities from "@/assets/resources/utilities.json";

export default {
  components: {
    quillEditor,
    vSelect,
  },
  name: "newMessage",
  props: {
    identifier: Number,
    entityId: Number,
    type: Number,
    emailTo: String,
  },
  data() {
    return {
      auth: auth,
      resources: resources,
      utilities: utilities,
      alerts: alerts,
      activePrompt: false,
      mailFrom: JSON.parse(localStorage.getItem("userInfo")).email,
      mailTo: this.emailTo,
      mailSubject: "",
      mailCC: "",
      mailBCC: "",
      mailMessage: "",
      editorOption: {
        modules: {
          toolbar: [
            [
              "bold",
              "italic",
              "underline",
              "strike",
              "link",
              "blockquote",
              "code-block",
            ],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ font: [] }],
          ],
        },
        placeholder: "Message",
      },
      fromOption: [
        { text: JSON.parse(localStorage.getItem("userInfo")).email },
        { text: "oficinacaracas@visiontravelmail.net" },
        { text: "pagos@visiontravel.net" },
        { text: "servicioalcliente@visiontravel.net" },
         { text: "noreply@visiontravelmail.net "}
      ],
      template: "",
      templateOption: [],
      attachmentDto: {},
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.mailTo != "";
    },
    baseUrl() {
      const path = this.$route.path;
      return path.slice(0, path.lastIndexOf("/"));
    },
    emailMeta() {
      return this.$store.state.email.meta;
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },
  },
  created: function () {
    this.getTemplate();
  },
  methods: {
    clearFields() {
      this.$nextTick(() => {
        this.mailTo = "";
        this.mailFrom = "";
        this.mailSubject = "";
        this.mailCC = "";
        this.mailBCC = "";
        this.mailMessage = "";
        this.template = "";
      });
    },

    async getTemplate() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Mailtemplate/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.templateOption = [];
          } else {
            this.templateOption = result.data;
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    async submitForm() {
      this.$vs.loading();
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          await this.sendMail();
        } else {
          // form have errors
        }
        this.$vs.loading.close();
      });
    },

    async sendMail() {
      // let emailToSend = [];
      // this.mailTo.split(";").forEach((element) => {
      //   emailToSend.push(element);
      // });

      let formData = new FormData();
      formData.set("Subject", this.mailSubject);
      formData.set("Message", this.mailMessage);
      formData.set("Language", `${this.$i18n.locale.toUpperCase()}`);
      formData.set("CreatedBy", this.mailFrom);
      formData.set("ProductType", this.type);
      formData.set("EntityId", this.entityId);
      formData.set("Identifier", this.identifier);
      let _count = 0;
      this.mailTo.split(";").forEach((element) => {
         formData.set(`AddressesList[${_count}]`, element);
         _count++;
      });

     

      if (this.mailFrom != JSON.parse(localStorage.getItem("userInfo")).email)
        formData.append("replyTo", this.mailFrom);

      if (this.attachmentDto.file != null)
        formData.append(
          "Attachment",
          this.attachmentDto.file,
          this.attachmentDto.file.Name
        );

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Emails/TemplateAttachment`,
        data: formData,
        headers: {
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
          "Content-Type": "multipart/form-data",
        },
      }).then(
        async (result) => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgEmailNotSent"),
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.clearFields();
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgEmailSent"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.clearFields();
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    onFileSelected(event) {
      this.attachmentDto.file = event.target.files[0];
    },

    templateBody(idTemplate) {
      let template = this.templateOption.filter((x) => x.id == idTemplate);
      this.mailMessage = template[0].body;
    },
  },
};
</script>

