<template>
  <div>
    <div class="vx-row" v-if="stage !== database.stage.Billing || allowChange">
      <div v-if="tracking.length == 0" class="vx-col md:w-1/4 w-full">
        <vs-button color="primary" type="filled" @click="StartManagement()">
          {{
            $t(resources.StartManagement.i18n) || resources.StartManagement.name
          }}
        </vs-button>
      </div>
    </div>
    <div
      v-if="
        tracking.length !== 0
          // (stage !== database.stage.Billing || allowChange)
      "
    >
      <div class="vx-col md:w-1/2 w-full mt-2 mb-3">
        <ul class="centerx">
          <li>
            <vs-checkbox
              v-model="changeResponsible"
              v-on:change="refreshForm()"
              >{{
                $t(resources.ChangeResponsible.i18n) ||
                  resources.ChangeResponsible.name
              }}</vs-checkbox
            >
          </li>
        </ul>
      </div>
      <div class="vx-col md:w-1/3 w-full mb-2" v-if="changeResponsible">
        <label class="vs-input--label">{{
          $t(resources.Responsible.i18n) || resources.Responsible.name
        }}</label>
        <v-select
          v-model="responsible"
          :clearable="false"
          :options="contactList"
          :reduce="x => x.id"
          label="name"
          scrollable
        />
      </div>
      <div class="vx-col md:w-1/3 w-full mb-3">
        <label class="vs-input--label">{{
          $t(resources.Stage.i18n) || resources.Stage.name
        }}</label>
        <v-select
          v-model="stageId"
          :clearable="false"
          :options="stageOption"
          :reduce="x => x.id"
          label="name"
          :disabled="disabledStage"
          scrollable
        />
      </div>
      <div class="vx-col md:w-1/3 w-full mb-2">
        <vs-textarea
          class="w-full"
          icon-pack="feather"
          icon-no-border
          icon="icon-clipboard"
          size="small"
          :label="$t(resources.Remark.i18n) || resources.Remark.name"
          v-model="remark"
        />
      </div>
      <!-- <ul class="centerx mb-4">
        <li>
          <vs-checkbox v-model="isManaged">{{
            $t(alerts.MsgFinishBooking.i18n) || alerts.MsgFinishBooking.name
          }}</vs-checkbox>
        </li>
      </ul> -->
      <div class="vx-col mb-4" v-if="allowEdit">
        <vs-button color="primary" type="filled" @click="updateBookingStage()">
          {{ $t(resources.Save.i18n) || resources.Save.name }}
        </vs-button>
      </div>
    </div>
    <vs-table
      v-if="tracking.length != 0"
      max-items="6"
      pagination
      :data="tracking"
    >
      <template slot="thead">
        <vs-th align="center">{{
          $t(resources.Date.i18n) || t(resources.Date.name)
        }}</vs-th>
        <vs-th>{{
          $t(resources.Previousstage.i18n) || t(resources.Previousstage.name)
        }}</vs-th>
        <vs-th>{{
          $t(resources.Currentstage.i18n) || t(resources.Currentstage.name)
        }}</vs-th>
        <vs-th>{{
          $t(resources.Responsable.i18n) || t(resources.Responsable.name)
        }}</vs-th>
        <vs-th>{{
          $t(resources.CreatedBy.i18n) || t(resources.CreatedBy.name)
        }}</vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].createdDate">{{
            data[indextr].createdDate
          }}</vs-td>
          <vs-td :data="data[indextr].previousStageName">{{
            data[indextr].previousStageName
          }}</vs-td>
          <vs-td :data="data[indextr].newStageName">{{
            data[indextr].newStageName
          }}</vs-td>
          <vs-td :data="data[indextr].contactName">{{
            data[indextr].contactName
          }}</vs-td>
          <vs-td :data="data[indextr].createdBy">{{
            data[indextr].createdBy
          }}</vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>
<script>
import utilities from "@/assets/resources/utilities.json";
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import axios from "axios";
import vSelect from "vue-select";
import database from "@/assets/resources/enums/database.json";

export default {
  components: {
    vSelect
  },
  name: "tracking",
  props: {
    bookingId: String,
    bookingDetailId: String,
    stage: Number,
    allowEdit: Boolean
  },
  data() {
    return {
      tracking: [],
      alerts: alerts,
      resources: resources,
      utilities: utilities,
      database: database,
      remark: "",
      stageOption: [],
      isManaged: false,
      changeResponsible: false,
      responsible: 0,
      contactList: [],
      contactInfo: JSON.parse(localStorage.getItem("userInfo")),
      stageId: "",
      allowChange: false,
      disabledStage: false
    };
  },
  methods: {
    refreshForm() {
      if (this.changeResponsible) {
        this.responsible = this.contactInfo.id;
        this.remark = `Se cambia de responsable de la reserva`;
      } else {
        this.responsible = "";
        this.remark = "";
      }
    },

    async GetEmployees() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Contact/GetEmployees/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.contactList = [];
          } else {
            this.contactList = result.data.map(x => ({
              id: x.id,
              name: `${x.name} ${x.lastName}`
            }));
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getStage() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_OVAPI}Stage/${this.$i18n.locale}/1`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.stageOption = [];
          } else {
            this.stageOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getTracking() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Tracking/${this.$i18n.locale.toUpperCase()}/${this.bookingDetailId}/5`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.tracking = [];
          } else {
            this.tracking = result.data.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async updateBookingStage() {
      await axios({
        method: "POST",
        url: `${
          process.env.VUE_APP_APIVT
        }BookingDetail/UpdateStage/${this.$i18n.locale.toUpperCase()}`,
        data: {
          bookingId: this.bookingId,
          bookingDetailId: this.bookingDetailId,
          stageId: this.stageId,
          remark: this.remark,
          createdBy: this.contactInfo.email,
          isManaged: this.isManaged,
          managedSot: false,
          responsibleId: this.changeResponsible ? this.responsible : 0
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved") + "<br/>" + result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.stage = this.stageId;
            await this.getTracking();
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
          this.bookingByReference = [];
        }
      );
    },

    async StartManagement() {
      await axios({
        method: "POST",
        url: `${
          process.env.VUE_APP_APIVT
        }Tracking/IsManaged/${this.$i18n.locale.toUpperCase()}`,
        data: {
          identifier: this.bookingDetailId,
          entity: this.utilities.entity.bookingDetail,
          contactId: this.contactInfo.id,
          createdBy: this.contactInfo.email
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved") + "<br/>" + result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            await this.getTracking();
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
          this.bookingByReference = [];
        }
      );
    },

    async getAllow() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }ParameterAplication/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("tokenVT")
        }
      }).then(
        async result => {
          if (result.data.length > 0) {
            let _user = JSON.parse(localStorage.getItem("userInfo"));
            let _list = result.data
              .find(x => x.parameter.name == "CanUpdateStage")
              .value.split(",");

            let _allow = _list.filter(x => x == _user.email);

            if (_allow.length > 0) {
              this.allowChange = true;
            }
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    }
  },
  async created() {
    await this.getAllow();
    await this.getStage();
    await this.getTracking();
    await this.GetEmployees();
    // if (this.stage == 28 || this.stageId == 31) {
    //   this.stageId = 33;
    //   this.disabledStage = true;
    // }
    // else if (this.stage == 7) {
    //   this.stageId = 32;
    //   this.disabledStage = true;
    // }
    // else if (this.stage == 33) {
    //   this.stageOption = this.stageOption.filter(x => x.id == 7 || x.id == 8)
    //   //this.disabledStage = true;
    // }
    // else if (this.stage == 2) {
    //   this.stageOption = this.stageOption.filter(x => x.id == 2 || x.id == 4 || x.id == 7 || x.id == 8)
    // }
    // else if (this.stage == 5 || this.stage == 10) {
    //   this.stageOption = [{"id":6,"name":"Comisión"},{"id":34,"name":"Gestión finalizada"}];
    //   this.stageId = 6;
    // }
  }
};
</script>
