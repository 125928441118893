<template>
  <li>
    <div class="tooltip" v-if="node.status === 'T'">
      <vs-avatar
        class="m-0"
        size="50px"
        src="https://cdn1.visiontravel.net/Images/avatar/default.jpg"
        style="border-style: solid; border-color: rgba(var(--vs-dark),1); border-width: medium; !important;"
      />
      <div class="bottom">
        <p>
          <strong>{{$t(resources.DistributorID.i18n) || resources.DistributorID.name}}</strong>
          : {{node.dist}}
        </p>
        <p>
          <strong>{{$t(resources.Status.i18n) || resources.Status.name}}</strong>
          : {{$t(resources.Terminated.i18n) || resources.Terminated.name}}
        </p>
      </div>
    </div>
    <div class="tooltip" v-else>
      <vs-avatar
        class="m-0"
        :src="`https://cdn1.visiontravel.net/Images/avatar/${node.dist}.jpg`"
        size="50px"
        style="border-style: solid; border-color: rgba(var(--vs-dark),1); border-width: thin; !important;"
        v-if="node.endRankId === '0'"
      />
      <vs-avatar
        class="m-0"
        :src="`https://cdn1.visiontravel.net/Images/avatar/${node.dist}.jpg`"
        size="50px"
        style="border-style: solid; border-color: rgba(var(--vs-success),1); border-width: medium; !important;"
        v-else-if="node.endRankId === '1'"
      />
      <vs-avatar
        class="m-0"
        :src="`https://cdn1.visiontravel.net/Images/avatar/${node.dist}.jpg`"
        size="50px"
        style="border-style: solid; border-color: rgb(173, 170, 214); border-width: medium; !important;"
        v-else-if="node.endRankId === '2'"
      />
      <vs-avatar
        class="m-0"
        :src="`https://cdn1.visiontravel.net/Images/avatar/${node.dist}.jpg`"
        size="50px"
        style="border-style: solid; border-color: rgb(57, 208, 253); border-width: medium; !important;"
        v-else-if="node.endRankId === '3'"
      />
      <vs-avatar
        class="m-0"
        :src="`https://cdn1.visiontravel.net/Images/avatar/${node.dist}.jpg`"
        size="50px"
        style="border-style: solid; border-color: rgb(27, 0, 246); border-width: medium; !important;"
        v-else-if="node.endRankId === '4'"
      />
      <vs-avatar
        class="m-0"
        :src="`https://cdn1.visiontravel.net/Images/avatar/${node.dist}.jpg`"
        size="50px"
        style="border-style: solid; border-color: rgb(255, 252, 35); border-width: medium; !important;"
        v-else-if="node.endRankId === '5'"
      />
      <vs-avatar
        class="m-0"
        :src="`https://cdn1.visiontravel.net/Images/avatar/${node.dist}.jpg`"
        size="50px"
        style="border-style: solid; border-color: rgb(255, 155, 8); border-width: medium; !important;"
        v-else-if="node.endRankId === '6'"
      />
      <vs-avatar
        class="m-0"
        :src="`https://cdn1.visiontravel.net/Images/avatar/${node.dist}.jpg`"
        size="50px"
        style="border-style: solid; border-color:rgb(238, 33, 236); border-width: medium; !important;"
        v-else-if="node.endRankId === '7'"
      />
      <vs-avatar
        class="m-0"
        :src="`https://cdn1.visiontravel.net/Images/avatar/${node.dist}.jpg`"
        size="50px"
        style="border-style: solid; border-color: rgb(238, 33, 33); border-width: medium; !important;"
        v-else-if="node.endRankId === '8'"
      />
      <vs-avatar
        class="m-0"
        :src="`https://cdn1.visiontravel.net/Images/avatar/${node.dist}.jpg`"
        size="50px"
        style="border-style: solid; border-color: rgb(0, 255, 46 ); border-width: medium; !important;"
        v-else-if="node.endRankId === '9'"
      />
      <div class="bottom">
        <p class="text-center">
          <strong>{{$t(resources.BasicInformation.i18n) || resources.BasicInformation.name}}</strong>
        </p>
        <br />
        <p>
          <strong>{{$t(resources.DistributorID.i18n) || resources.DistributorID.name}}</strong>
          : {{node.dist}}
        </p>
        <p v-if="node.status == 'D'">
          <strong>{{$t(resources.Status.i18n) || resources.Status.name}}</strong>
          : {{$t(resources.Distributor.i18n) || resources.Distributor.name}}
        </p>
        <p v-else-if="node.status == 'S'">
          <strong>{{$t(resources.Status.i18n) || resources.Status.name}}</strong>
          : {{$t(resources.Suspended.i18n) || resources.Suspended.name}}
        </p>
        <p v-else>
          <strong>{{$t(resources.Status.i18n) || resources.Status.name}}</strong>
          : {{$t(resources.Terminated.i18n) || resources.Terminated.name}}
        </p>
        <p>
          <strong>{{$t(resources.Name.i18n) || resources.Name.name}}</strong>
          : {{node.name}}
        </p>
        <p>
          <strong>{{$t(resources.Center.i18n) || resources.Center.name}}</strong>
          : 00 {{node.bus_ctr}}
        </p>
        <p v-if="node.side == 'L'">
          <strong>{{$t(resources.Side.i18n) || resources.Side.name}}</strong>
          : {{$t(resources.Left.i18n) || resources.Left.name}}
        </p>
        <p v-else>
          <strong>{{$t(resources.Side.i18n) || resources.Side.name}}</strong>
          : {{$t(resources.Right.i18n) || resources.Right.name}}
        </p>
        <p>
          <strong>{{$t(resources.VolumenPersonal.i18n) || resources.VolumenPersonal.name}}</strong>
          : {{node.vol1}}
        </p>
        <p>
          <strong>{{$t(resources.Volumen.i18n) || resources.Volumen.name}}</strong>
          : {{node.vol3}}
        </p>
        <p>
          <strong>{{$t(resources.EndRank.i18n) || resources.EndRank.name}}</strong>
          : {{node.endRank}}
        </p>
      </div>
    </div>
    <ul v-if="node.children && node.children.length">
      <node :key="child.id" v-for="child in node.children" :node="child"></node>
    </ul>
  </li>
</template>

<script>
import resources from "@/i18n/resources.js";

export default {
  name: "node",
  props: {
    node: Object
  },
  data() {
    return {
      resources: resources
    };
  }
};
</script>
<style>
@import "../../assets/css/tooltipTree.css";
</style>