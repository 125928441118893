// en, es
export default {
  en: {
    // ------------------------------- Alerts and messages -------------------------------
    MsgError: "Please,contact with the system administrator",
    DontHaveCycles: "You haven't made cycles",
    InformationEarnings:
      "If you need to view more information about your earnings by period, please, select the table row. The default data in the view is the last year",
    ColorsTableGenealogy:
      'The "Level" column has its values with colors, the meaning is:',
    MsgNotAllow10Rows: "It only allows maximum 9 levels",
    MsgDistNotExist: "The Distributor ID doesn't exist in your binary tree",
    MsgWelcome: "Welcome back, please login to your account",
    MsgRecoverPassword:
      "Please enter your email address and we'll send you a verification code to reset or update your password.",
    MsgNewPassword: "Please, enter your new password",
    MsgDifferentPassword: "The passwords are differents",
    MsgInvalidPassword:
      "The password must have minimum 9 characters with uppercase, lowercase, numbers and special characters, else the button will not active",
    MsgNotBinaryTree:
      "It has occurred a fail to recover the binary tree. Information isn't correct. Please, check with the administrator",
    MsgFromLevelLess: 'The "{0}" field must be less than "{1}"',
    MsgUserNotExist: "The user isn't a distributor",
    MsgEmployedNotExist: "The user isn't an employed",
    MgsRestorePassword:
      'You can restore your password with the option "Forgot your password?"',
    MsgNotFoundInfo: "Doesn't exist information",
    MsgStatusByPeriod:
      "You can search your qualification or another distributor of your downline in different periods",
    MsgConsiderInformation:
      "Please, you consider the next important information:",
    MsgPayTransaction: "USD $1 will be charged for each transfer requested",
    MsgDaysToPayment:
      "Transfers will be available on your ewallet the next Wednesday after transfer was requested at 16:00 ET or later.",
    MsgPaymentMade: "Payment have been made correctly",
    MsgPaymentNotMade: "Payment haven't been made correctly",
    MsgRefreshPage: "Please, refresh the page to get changes",
    MsgNotEnoughMoney: "You must have least USD $2 to do transfer.",
    MsgNotPayment: "We are not processing transfers request at this time",
    MsgWalletNotExist:
      "The user {0} doesn't exist in E-wallet. Please, you need to create an account to receive payments.",
    MsgYouHaveEarned: "You have earned",
    MsgTipAndFee: "at tips and customized fee",
    MsgStageBill: "Only bookings are on billing will be paid",
    MsgTax:
      'Taxes will be charged for each booking. View "Tax" column in the detail',
    MsgSaved: "The record has been saved success",
    MsgNotSaved: "The record hasn't been saved",
    MsgDisabledQuestion:
      "Are you sure to disable access to the Virtual Office to the distributor {0}?",
    MsgTotalPendingCharges:
      "Will be charged to your transfer. You could there is received double payments or we didn't do the discount USD $1 por other transfers made",
    MsgWriteToCustomerService:
      "If you have something question, you must write to servicioalcliente@visiontravel.net with the description your issue",
    MsgOneTransferAtDay: "You only can make one transfer at day",
    MsgNotHistorical: "Doesn't exist historical information",
    MsgAnotherCountry:
      "You must write the transaction number and payment date because operation country of the ITA is Colombia",
    MsgRequiredField: "You must fill in the required fields",
    MsgCustomizeTheme: "Customize and preview in real time",
    MsgEmailSent: "Email has been sent successfully",
    MsgEmailNotSent: "Email has not been sent",
    MsgWorking: "We're working in a new funcionality. Wait it soon",
    MsgCreateTransaction:
      "Are you sure you want to enable ITA, creating orden, creating Virtual Office access and approve the transaction?",
    MsgPaymentNotComplete:
      "Payment haven't been completed totally or status is not valid",
    MsgAcquireMembership:
      "You are adding a new member to your community with the {0} product for",
    MsgAcquireMembership1: "Are you ready to be part of the best community?",
    MsgAcquireMembership2: "Join right now !",
    MsgPendingMembership: "You owe {0} membership years",
    MsgPurchaseVerify:
      "Your purchase will be verified. Please, you must checked your Virtual Office to know request status",
    MsgPurchaseCompleted: "Your request has completed successfully",
    MsgNotUpgrade: "You don't apply to do an upgrade",
    MsgNotAttachment: "You must upload an attachment",
    MsgSendContract:
      "We have send the membership contract at email {0}. It must be signed by {1} to complete register successfully.",
    MsgCustomizedFee:
      "IBO has configured {0}{1} of fee customized to this product.",
    MsgHaveCardInfo : "Do you have the credit card information?",
    MsgFinishBooking: "Would you like to finish the booking?",
    MsgManaged: "This booking has been managed and finished",
    MsgOnlySaveCard: "Would you only like to save the credit Card?",
    MsgManagedSOT: "Will you manage booking by SOT?",
    MsgManagedBySOT: "This booking has been managed by SOT",
    MsgInvest: "Congratulations! You've done an investment on Block Invest. GOOD LUCK WITH THE EARNINGS!",
    MsgAccommodationInfo: "It's very important to check the accommodation's capacibility that you've choosen with the configuration you'll save. Please, you compare with the following information:",
    MsgStartDateAfterEndDate: "Start date must less than end date",
    MsgItineraryBeforeNow: "Itinerary's dates must greater than now",
    MsgItineraryAfterArrival: "Departure date must less than arrival date",
    MsgCopy: "Text copied successfully",
    //------------------------------- Menu  -------------------------------
    Home: "Home",
    Genealogy: "Genealogy",
    Orders: "Orders",
    General: "General",
    Commissions: "Commissions",
    StatusByPeriod: "Status by period",
    Customized: "Customized",
    Membership: "Membership",
    //------------------------------- Descriptions -------------------------------
    Description: "Description",
    CarryInLastPeriod: "Carry in from last period",
    CurrentPeriod: "Current period",
    CarryNextPeriod: "Carry forward to next period",
    BusinessCenters: "Businesses centers",
    BusinessCenter: "Business center",
    Center: "Center",
    NewCustomers90: "New customers the last 90 days",
    TourismSales90: "Tourism sales the last 90 days",
    TourismSales365: "Tourism sales the last 365 days",
    Last5Periods: "Last five periods",
    Number: "Number",
    PVAmount: "PV Amount",
    Period: "Period",
    DistCountryCode: "Dist. country code",
    ShipToCountry: "Ship to country",
    ShipToName: "Ship to name",
    Date: "Date",
    Options: "Options",
    Next: "Next",
    Back: "Back",
    Finish: "Finish",
    LastWeek: "Last week",
    Currently: "Currently",
    Total: "Total",
    Volumen: "Volumen",
    Available: "Available",
    Profile: "Profile",
    View: "View",
    Download: "Download",
    OrderHistory: "Orders history",
    BasicInformation: "Basic information",
    DocumentNumber: "Document number",
    BirthDate: "Birth date",
    Email: "Email",
    Mobile: "Mobile",
    Gender: "Gender",
    CountryOperation: "Country operation",
    Nationality: "Nationality",
    ResidenceCity: "Residence city",
    Address: "Address",
    Return: "Return",
    AllRightsReserved: "All rights reserved",
    HandCraftedMMade: "Hand-craft and made with",
    Invoice: "Invoice",
    OrderNumber: "Order number",
    OrderDate: "Order date",
    ShipTo: "Ship to",
    BillTo: "Bill to",
    Item: "Item",
    Code: "Code",
    QtyShip: "Quantity ship",
    QtyOrder: "Quantity order",
    TotalVolumen: "Total volumen",
    Price: "Price",
    Tax: "Tax",
    Taxable: "Taxable",
    Subtotal: "Subtotal",
    Comments: "Comments",
    ShippingAndHandling: "Shipping and handling",
    TotalAmountDue: "Total amount due",
    PaymentsReceived: "Payments received",
    ReceiptAmount: "Receipt amount",
    TotalCashReceipts: "Total cash receipts",
    InvoceBalance: "Invoice balance",
    Close: "Close",
    NoAvailableData: "No available data",
    LeftVolumen: "Left volumen",
    RightVolumen: "Right volumen",
    ExportAs: "Export as CSV",
    Logout: "Logout",
    LeftBvTravelPoint: "Left - Travel points (BV)",
    RightBvTravelPoint: "Right - Travel points (BV)",
    Qualifications: "Qualifications",
    Rank: "Rank",
    VolumenPersonal: "Personal volumen",
    ViewEnrolledsTotal: "View enrollees total",
    CyclesRequired: "Cycles required",
    CyclesTotal: "Cycles total made",
    PersonalEnrollees: "Personal enrollees",
    Left: "Left",
    Right: "Right",
    SinceWeek: "Since week",
    UntilWeek: "To week",
    Select: "Select",
    Search: "Search",
    Summary: "Summary",
    StartDate: "Start date",
    EndDate: "End date",
    CheckDate: "Check date",
    CheckAmount: "Check amount",
    EarningsAmount: "Earnings amount",
    CurrencyCode: "Currency code",
    TransactionNumber: "Transaction number",
    EarningsSummaryByPeriod: "Earnings summary by period",
    RetailCommission: "Retail commission",
    FastStart: "Fast start",
    CycleCommission: "Cycle commission",
    MatchingBonus: "Matching bonus",
    BaseBonus: "Base bonus",
    LifestyleBonus: "Lifestyle bonus",
    EarningsByCommissionType: "Earnings by commission type",
    PeriodDetails: "Period details",
    DownloadPDF: "Download PDF",
    Detail: "Detail",
    DistributorID: "Distributor ID",
    Name: "Name",
    PaidRank: "Paid rank",
    PinRank: "Pin rank",
    PercentContributed: "Percent contributed",
    AmountContributed: "Amount contributed",
    Amount: "Amount",
    Associated: "Associated",
    Silver: "Silver",
    Gold: "Gold",
    Platinum: "Platinum",
    Diamond: "Diamond",
    DoubleDiamond: "Double Diamond",
    TripleDiamond: "Triple Diamond",
    Crown: "Crown",
    CrownAmbassador: "Crown Ambassador",
    TotalCommissionsEarned: "Total commissions earned",
    Country: "Country",
    Percent: "Percent",
    Level: "Level",
    PaidLevel: "Paid level",
    Bonus: "Bonus",
    CycleNumber: "Cycle number",
    Step: "Step",
    BinaryTree: "Binary tree",
    Status: "Status",
    RequiredEnrolleds: "Required enrollees",
    EndRank: "End rank",
    Side: "Side",
    Sponsor: "Sponsor",
    FromLevel: "From level",
    UntilLevel: "To level",
    All: "All",
    Customer: "Customer",
    Terminated: "Terminated",
    Distributor: "Distributor",
    MemberType: "Member type",
    Suspended: "Suspended",
    AmountRows: "Amount rows",
    Alert: "Alert",
    Error: "Error",
    Success: "Success",
    Start: "Start",
    End: "End",
    Unclassified: "Unclassified",
    Login: "Login",
    Password: "Password",
    ForgotPassword: "Forgot your password?",
    RecoverPassword: "Recover your password",
    Recover: "Recover",
    Recapture: "Recapture",
    ConfirmPassword: "Confirm password",
    VerificationCode: "Verification code",
    ResetPassword: "Reset password",
    Reset: "Reset",
    CustomizedCommissions: "Customized commissions",
    TipAndCustomizedFee: "Tips and customized fee",
    FeeTotal: "Fee total",
    TipsTotal: "Tips total",
    Pay: "Pay",
    Paid: "Paid",
    WithoutPay: "Without pay",
    BookingCode: "Booking code",
    CreateDate: "Create date",
    PaymentDate: "Payment date",
    Type: "Type",
    Tip: "Tip",
    CustomizedFee: "Customized fee",
    AdjustmentTip: "Tip adjustment",
    AdjustmentCustomizedFee: "Customized fee adjustment",
    Transfer: "Transfer",
    Congratulations: "Congratulations",
    TotalTransactions: "Total transactions",
    Payment: "Payment",
    Settings: "Settings",
    Active: "Active",
    Inactive: "Inactive",
    Access: "Access",
    SearchIta: "Search Ita",
    ExpirationDate: "ExpirationDate",
    CreatedBy: "Created by",
    Create: "Create",
    Confirm: "Confirm",
    TotalPendingCharges: "Total pending charges",
    PendingCharges: "Pending charges",
    ChangeDate: "Change date",
    UpdatedBy: "Updated by",
    LastName: "Last name",
    FinancialCharge: "Financial charge",
    Historical: "Historial",
    NewValue: "New value",
    OldValue: "Old value",
    Action: "Action",
    UpdateEmail: "Update email",
    Save: "Save",
    ThemeCustomized: "Theme Customized",
    LayoutType: "Layout  type",
    Vertical: "Vertical",
    Horizontal: "Horizontal",
    Color: "Color",
    Mode: "Mode",
    Light: "Light",
    Dark: "Dark",
    SemiDark: "SemiDark",
    CollapseSidebar: "Collapse sidebar",
    NavbarColor: "Navbar color",
    NavbarType: "Navbar type",
    FooterType: "Footer type",
    RightToLeft: "Right to left",
    Animation: "Animation",
    ZoomFade: "Zoom fade",
    SlideFade: "Slide fade",
    FadeBottom: "Fade bottom",
    Fade: "Fade",
    ZoomOut: "Zoom out",
    None: "None",
    Hidden: "Hidden",
    Static: "Static",
    Sticky: "Sticky",
    Floating: "Floating",
    Bookings: "Bookings",
    BookingDetail: "Booking detail",
    AccountNumber: "Account number",
    ContactName: "Contact name",
    Source: "Source",
    WayToPay: "Way to pay",
    NetValue: "Net Value",
    TaxesValue: "Taxes Value",
    FeeValue: "Fee Value",
    FeeCustomized: "Fee Customized",
    MerchantValue: "Merchant Value",
    DiscountValue: "Discount Value",
    TotalValue: "Total Value",
    OtherValue: "Other value",
    ProductType: "Product Type",
    Origin: "Origin",
    Destination: "Destination",
    SupplierName: "Supplier",
    Itinerary: "Itinerary",
    Passengers: "Passengers",
    PassengerType: "Passenger Type",
    DocumentType: "Document Type",
    Telephone: "Telephone",
    FareValue: "Fare Value",
    Value: "Value",
    Filters: "Filters",
    Gds: "Gds",
    Managed: "Managed",
    TicketNumber: "Ticket number",
    PaymentMethod: "Payment method",
    Stage: "Stage",
    Yes: "Yes",
    No: "No",
    Commercial: "Commercial",
    RiskAnalysis: "Risk analysis",
    PaymentStage: "PaymentStage",
    Issue: "Issue",
    Billing: "Billing",
    Commission: "Commission",
    Returns: "Returns",
    Cancellations: "Cancellations",
    Changes: "Changes",
    ChargeSuppliers: "Charge suppliers",
    Recovery: "Recovery",
    Fraud: "Fraud",
    Clean: "Clean",
    Documents: "Documents",
    Users: "Users",
    Delete: "Delete",
    Edit: "Edit",
    Print: "Print",
    Actions: "Actions",
    Subject: "Subject",
    GeneratePayments: "Generate payments",
    PaymentInformation: "Payment information",
    MethodOfPayment: "Method of payment",
    CardType: "Card type",
    CardOwner: "Card owner",
    CardNumber: "Card number",
    ExpirationMonth: "Expiration month",
    ExpirationYear: "Expiration year",
    SecurityCode: "Security code",
    ZipCode: "Zip code",
    BillingData: "Billing data",
    CreditCard: "Credit card",
    TotalToPay: "Total to pay",
    Cards: "Cards",
    Merchant: "Merchant",
    Charge: "Charge",
    Send: "Send",
    PaymentLink: "Payment link",
    PayYourReservation: "Pay your reservation",
    CountryCode: "Country code",
    Currency: "Currency",
    AddPassenger: "Add Passenger",
    ExpirationDatePassport: "Expiration Date Passport",
    SpecificationName: "Specification Name",
    SpecificationValue: "Specification Value",
    AddItinerary: "Add Itinerary",
    MyDistributor: "My distributor",
    Responsable: "Responsable",
    Booking: "Booking",
    Tracking: "Tracking",
    BookingByReference: "Booking by Reference",
    Previousstage: "Previous Stage",
    Currentstage: "Current Stage",
    Newstage: "New Stage",
    State: "State",
    FirstName: "First Name",
    Get: "Get",
    Sent: "Sent",
    NewMessage: "New Message",
    To: "To",
    From: "From",
    Attachments: "Attachments",
    SelectTemplate: "Select template",
    Body: "Body",
    StartManagement: "Start management",
    Ita: "Ita",
    Remark: "Remark",
    Results: "results",
    GeneralInformation: "General information",
    Contact: "Contact",
    ReservationType: "Type of reservation",
    TotalTaxes: "Total taxes",
    NetTotal: "Net total",
    Purchases: "Purchases",
    Generate: "Generate",
    Upgrade: "Upgrade",
    MonthlyPayment: "Monthly payment",
    AutomaticMonthlyPayment: "Automatic monthly payment",
    Buy: "Buy",
    DepartureDate: "departure date",
    ArrivalDate: "Arrival date",
    Class: "Class",
    Airline: "Airline",
    Airlines: "Airlines",
    FlightNumber: "Flight number",
    Passenger: "Passenger",
    IsPayed: "Is Payed",
    AddPayment: "Add Payment",
    MsgValidField: "you must fill in required fields",
    NumberTransaction: "Número de transacción",
    BankName: "Bank Name",
    AuthorizationNumber: "Authorization Number",
    CreditCardNumber: "CreditCard Number",
    CreditCardType: "CreditCard Type",
    CreditCardOwner: "CreditCard Owner",
    DuesNumber: "Dues Number",
    CardholderLastName: "Cardholder LastName",
    WriteOrigin: "Write a origin",
    WriteDestination: "Write a destination",
    LenderServices: "Lender services",
    Supplier: "Supplier",
    FlightClass: "Flight class",
    Update: "Update",
    RemarkPublish: "Publish",
    Cancel: "Cancel",
    OpenDocument: "Open document",
    Notifications: "Notifications",
    AssignedTo: "Assigned to",
    Blog: "Blog",
    Language: "Language",
    Title: "Title",
    CreationDate: "Creation date",
    List: "List",
    Preview: "Preview",
    HasAttachment: "Has attachment",
    Transactions: "Transactions",
    GroupedByTransaction: "Grouped by transaction",
    Product: "Product",
    IsFinanced: "Is financed",
    Payments: "Payments",
    Attachment: "Attachment",
    Installments: "Installments",
    FinanceProduct: "Do you want to finance your product?",
    VerifyDate: "Verify date",
    IncludeNeo: "Do you want to buy Neo application?",
    Pending: "Pending",
    Verify: "Verify",
    IncompleteDocumentation: "Incomplete documentation",
    ResidenceCountry: "Residence country",
    ResidenceState: "Residence state",
    Enroller: "Enroller",
    Closer: "Closer",
    Presenter: "Presenter",
    AllCloser: "All closer",
    InstallmentPay: "Your installment is by only",
    BeingMember: "Being member!",
    BeneficiaryName: "Beneficiary name",
    BeneficiaryLastName: "Beneficiary last name",
    MembershipType: "Membership Type",
    Bill: "Bill",
    InstallmentNumber: "Installment Number",
    Indicative: "Indicative",
    NewMember: "New member",
    Management: "Management",
    SendContract: "Send contract",
    SendIOU: "Send IOU",
    SendWelcome: "Send welcome",
    CreateEwallet: "Create E-wallet",
    Generated: "Generated",
    BirthDay: "Birth day",
    BirthMonth: "Birth month",
    BirthYear: "Birth year",
    Male: "Male",
    Female: "Female",
    Other: "Other",
    Hello: "Hello",
    MembershipJoin:
      "You have registrated a new member to our community. His/her distributor ID is:",
    OrderGenerated: "Also, we were generated an invoce with order number:",
    GenerateMembership: "Generate membership",
    Welcome: "Welcome",
    BookingHandmade: "Booking Handmade",
    ItaCode: "Ita Code",
    ContactFormTitle: "Where can we contact you in case of an emergency?",
    TelephoneType: "Telephone Type",
    TelephoneNumber: "Telephone Number",
    Specifications: "Specifications",
    Specification: "Specification",
    Add: "Add",
    AdditionalInformation: "Additional Information",
    InsuranceName: "Insurance Name",
    ActivityName: "Activity Name",
    RentalName: "Rental Name",
    SupplierRentalCode: "Supplier Rental Code",
    Private: "Private",
    Affiliations: "Affiliations",
    Accounting: "Accounting",
    CashRegister: "Cash register",
    PaymentsConfirmed: "Payments confirmed",
    Signature: "Signature",
    Account: "Account",
    PasswordUpdatedDate: "Password updated date",
    Nickname: "Nickname",
    IsFavorite: "Is favorite",
    ItsRegistred: "It's registred",
    MembershipCountry: "Membership country",
    Help: "Help",
    UpdateSignatures: "Update signatures",
    Contracts: "Contracts",
    Answer: "Answer",
    AccountType: "Account type",
    TinaCode: "TINA code",
    OtherProducts: "Other producto",
    Tourism: "Tourism",
    PackagesHelp: "Packages help",
    ExchangeRate: "Exchange rate",
    MailSetting: "Mail Setting",
    Identifier: "Identifier",
    Parameters: "Parameters",
    Products: "Products",
    ContactTypeName: "Contact Type Name",
    Benefit: "Benefit",
    PriceLevel: "Price Level",
    IsCompany: "It's a company",
    Promotion: "Promotion",
    IsRealtor: "It's realtor",
    Accertify: "Accertify",
    AccertifyPoints: "Accertify points",
    AccertifyId: "Accertify ID",
    DetailPrices: "Detail prices",
    SupplierDestination: "Supplier Destination",
    TinaHelp:"Tina Help",
    Packages: "Packages",
    City: "City",
    BookingValue: "Booking value",
    AirlineCommission: "Airline commission",
    Hotels: "Hotels",
    IBO: "Independent Business Owner (IBO)",
    Responsible: "Responsible",
    ChangeResponsible: "Would you like to change the booking responsible?",
    Category: "Category",
    Accommodation: "Accommodation",
    WriteName: "Write a name",
    Longitude: "Longitude",
    Latitude: "Latitude",
    StarNumber: "Star number",
    Outstanding: "Outstanding",
    Disabled: "Disabled",
    DescriptionES: "Spanish Description",
    DescriptionEN: "English Description",
    ReferenceCode: "Reference code",
    BVPoints: "BV points",
    ShortName: "Short name",
    CustomizedAVV: "Customized AVV",
    CustomizedO2T: "Customized One2trip",
    RelationshipItaCode: "Relationship Ita Code",
    ManagementPackage:"Management Package",
    Service:"Service",
    NameEs:"Spanish Name",
    NameEn:"English Name",
    CategoryType:"Category Type",
    AdultAmount:"Adult Amount",
    ChildAmount:"Child Amount",
    InfantAmount:"Infant Amount",
    Days:"Days",
    Nights:"Nights",
    Popular:"Popular",
    MinimumPrice:"Minimum Price",
    IncludeEs: "Include Spanish",
    IncludeEn: "Include English",
    NoIncludeEs: "No Include Spanish",
    NoIncludeEn: "No Include English",
    ImportantInformationEs: "Important Information Spanish",
    ImportantInformationEn: "Important Information English",
    Configuration:"Configuration",
    Application: "Application",
    CancellationPolicy:"Cancellation Policy",
    Images:"Images",
    Main:"Main",
    ImportantInformationAccomodations: "Important information",
    Age: "Age",
    Hotel: "Hotel",
    AgentCommissionValue: "Agent commission value",
    MainBooking: "Main booking",
    Blocks: "Blocks",
    AppliesBothWays: "Applies both ways",
    ChargeIn: "Charge in",
    Discount: "Discount",
    Benefits: "Benefits",
    Points: "Points",
    Plan: "Plan",
    ContentType: "Content type",
    IsFeatured: "Is featured",
    DiscountIn: "Discount in",
    Sales: "Sales",
    CreationDay: "Creation day",
    CreationMonth: "Creation month",
    CreationYear: "Creation year",
    CheckInDay: "Check-in day",
    CheckInMonth: "Check-in month",
    CheckInYear: "Check-in year",
    TicketsIssued: "Tickets issued",
    Copy: "Copy",
    MyCommissions: "My commissions",
    Incentives: "Incentives",
    IssueDate : "IssueDate",
    InitialAgent: "Initial agent",
    FinalAgent: "Final agent",
    Administration: "Administration",
    International: "International",
    AgentEmail: "Agent email",
    IsChange: "Is a change?",
    AmountDays: "Amount days",
    Area: "Area",
    SupportTeam: "Support team",
    Rol: "Rol",
    SendMoney: "Send money",
    SendMoneyEwallet: "Would you like to send money to an E-wallet account?",
    SendMoneyMasspay: "Would you like to send money to an Masspay account?",
    Activities: "Activities",
    UserName: "User name",
    MsgNotTransferEwallet: "We are not processing transfers request to Ewallet at this time",
    Difference: "Difference",
    Receipts: "Receipts",
    Audit: "Audit"
  },
  es: {
    // ------------------------------- Alertas y mensajes -------------------------------
    MsgError: "Por favor, contactarse con el administrador",
    DontHaveCycles: "No has realizado ciclos",
    InformationEarnings:
      "Si necesita ver más información acerca de sus ganancias por período, por favor, seleccione la fila de la tabla. Los datos por defecto en la vista es del último año",
    ColorsTableGenealogy:
      'La columna "Nivel" tiene sus valores con colores, el significado es:',
    MsgNotAllow10Rows: "Solo se permite un máximo de 9 niveles",
    MsgDistNotExist: "El distribuidor ID no existe en su árbol binario",
    MsgWelcome: "Bienvenido de nuevo, por favor inicie sesión en su cuenta",
    MsgRecoverPassword:
      "Por favor, ingrese su correo electrónico y le enviaremos un código de verificación para restablecer o actualizar su contraseña.",
    MsgNewPassword: "Por favor, ingrese su nueva contraseña",
    MsgDifferentPassword: "Las contraseñas son diferentes",
    MsgInvalidPassword:
      "La contraseña debe tener un mínimo de 9 caracteres con mayúsculas, minúsculas, números y caracteres especiales, de lo contrario, el botón no se activará",
    MsgNotBinaryTree:
      "Ha ocurrido un error para recuperar el árbol binario. La información no es correcta. Por favor, verificar con el administrador",
    MsgFromLevelLess: 'El campo "{0}" debe ser menor que "{1}"',
    MsgUserNotExist: "El usuario ingresado no es un ditribuidor",
    MsgEmployedNotExist: "El usuario ingresado no es un empleado",
    MgsRestorePassword:
      'Puede restablecer su contraseña con la opción "¿Olvidó su contraseña?"',
    MsgNotFoundInfo: "No existe información",
    MsgStatusByPeriod:
      "Puede buscar su calificación u otro distribuidor de su línea descendente en diferentes períodos",
    MsgConsiderInformation:
      "Por favor, considere la siguiente información importante:",
    MsgPayTransaction: "Se cobrará USD $1 por cada transferencia realizada",
    MsgDaysToPayment:
      "Las transferencias estarán disponibles en su billetera electrónica el próximo miércoles después de que se solicitó la transferencia a las 16:00 ET, más tarde.",
    MsgPaymentMade: "El pago se ha realizado correctamente",
    MsgPaymentNotMade: "El pago no se ha realizado correctamente",
    MsgRefreshPage: "Por favor, refresque la página para obtener los cambios",
    MsgNotEnoughMoney:
      "Debe tener al menos USD $2 para realizar la transferencia",
    MsgNotPayment: "No estamos procesando transferencias en este momento",
    MsgWalletNotExist:
      "El usuario no existe en la billtera electrónica. Por favor, necesita crear una cuenta para recibir pagos.",
    MsgYouHaveEarned: "Has ganado",
    MsgTipAndFee: "en propinas y fee personalizado",
    MsgStageBill:
      "Únicamente las reservas que estén en facturación serán pagadas",
    MsgTax:
      'Se cobrarán impuestos por cada reserva. Ver la columna "Impuestos" en el detalle',
    MsgSaved: "El registro ha sido guardado con éxito",
    MsgNotSaved: "El registro no ha sido guardado",
    MsgDisabledQuestion:
      "¿Está seguro que sea inhabilitar el acceso a la Oficina virtual al distribuidor {0}?",
    MsgTotalPendingCharges:
      " Se cargará a su transferencia. Es posible que haya recibido pagos dobles o que no hayamos realizado el descuento de USD $ 1 por otras transferencias realizadas",
    MsgWriteToCustomerService:
      "Si tiene alguna pregunta, usted debe escribir a servicioalcliente@visiontravel.net con la descripción de su problema",
    MsgOneTransferAtDay: "Solo puede realizar una transferencia al día",
    MsgNotHistorical: "No existe información histórica",
    MsgAnotherCountry:
      "Debe ingresar el número de transacción y fecha de pago porque el país operación del ITA es Colombia",
    MsgRequiredField: "Debe completar los campos obligatorios",
    MsgCustomizeTheme: "Personaliza y visualiza en tiempo real",
    MsgEmailSent: "El correo ha sido enviado",
    MsgEmailNotSent: "El correo no ha sido enviado",
    MsgWorking:
      "Estamos trabajando en esta nueva funcionalidad. Esperala pronto",
    MsgCreateTransaction:
      "¿Está seguro de que desea habilitar ITA {0}, crear orden, crear acceso a la Oficina Virtual y aprobar la transacción?",
    MsgPaymentNotComplete:
      "El pago no se ha completado totalmente o el estado no es válido",
    MsgAcquireMembership:
      "Estás ingresando un nuevo miembro a tu comunidad con el producto {0} por",
    MsgAcquireMembership1:
      "¿Estás preparado para ser parte de la mejor comunidad?",
    MsgAcquireMembership2: "¡ Afíliate YA !",
    MsgPendingMembership: "Usted debe {0} años de membresía",
    MsgPurchaseVerify:
      "Su compra será verificada. Por favor, debe verificar su Oficina Virtual para conocer el estado de la solicitud",
    MsgPurchaseCompleted: "Su solicitud se ha completado exitosamente",
    MsgNotUpgrade: "No aplica para hacer una actualización",
    MsgNotAttachment: "Usted debe cargar un documento",
    MsgSendContract:
      "Hemos enviado el contrato de membresía al correo electrónico {0}. Debe ser firmado por {1} para completar el registro correctamente.",
    MsgCustomizedFee:
      "El IBO ha configurado {0}{1} de personalizado para este producto.",
    MsgHaveCardInfo: "¿Tiene la información de la tarjeta?",
    MsgFinishBooking: "¿Le gustaría finalizar la reserva?",
    MsgManaged: "Esta reserva ha sido gestionada y finalizada",
    MsgOnlySaveCard: "¿Le gustaría sólo guardar la tarjeta de crédito?",
    MsgManagedSOT: "¿Gestionarás la reserva por SOT?",
    MsgManagedBySOT: "Esta reserva ha sido gestionada por el SOT",
    MsgInvest: "¡Felicitaciones! Has realizado una inversión en Block Invest. ¡TE DESEAMOS BUENA SUERTA CON LAS GANANCIAS!",
    MsgAccommodationInfo: "Es muy importante comprobar la capacidad de la acomodación elegida con la que va a configurar. Por favor, compare con la siguiente información:",
    MsgStartDateAfterEndDate: "La fecha de inicio debe ser menor que la fecha fin",
    MsgItineraryBeforeNow: "Las fechas del itinerario deben ser mayores que la fecha actual",
    MsgItineraryAfterArrival: "La fecha de salida debe ser menor que la fecha de regreso",
    MsgCopy: "Texto copiado exitosamente",
    //------------------------------- Menú -------------------------------
    Home: "Inicio",
    Genealogy: "Genealogía",
    Orders: "Órdenes",
    General: "General",
    Commissions: "Comisiones",
    StatusByPeriod: "Estado por período",
    Customized: "Personalizadas",
    Membership: "Membership",
    //------------------------------- Descripciones -------------------------------

    Description: "Descripción",
    CarryInLastPeriod: "Acumulado desde el último período",
    CurrentPeriod: "Período actual",
    CarryNextPeriod: "Acumulado para el siguiente período",
    BusinessCenters: "Centros de negocio",
    BusinessCenter: "Centro de negocios",
    Center: "Centro",
    NewCustomers90: "Clientes nuevos los últimos 90 días",
    TourismSales90: "Ventas de turismo los últimos 90 días",
    TourismSales365: "Ventas de turismo los últimos 365 días",
    Last5Periods: "Últimos cinco períodos",
    Number: "Número",
    PVAmount: "Cantidad PV",
    Period: "Período",
    DistCountryCode: "Código país dist.",
    ShipToCountry: "País para emitir",
    ShipToName: "Nombre para emitir",
    Date: "Fecha",
    Options: "Opciones",
    Next: "Siguiente",
    Back: "Atrás",
    Finish: "Finalizar",
    LastWeek: "Semana pasada",
    Currently: "Actualmente",
    Total: "Total",
    Volumen: "Volumen",
    Available: "Disponible",
    Profile: "Perfil",
    View: "Ver",
    Download: "Descargar",
    OrderHistory: "Historial de órdenes",
    BasicInformation: "Información básica",
    DocumentNumber: "Número documento",
    BirthDate: "Fecha nacimiento",
    Email: "Correo electrónico",
    Mobile: "Celular",
    Gender: "Género",
    CountryOperation: "País operación",
    Nationality: "Nacionalidad",
    ResidenceCity: "Ciudad de residencia",
    Address: "Dirección",
    Return: "Volver",
    AllRightsReserved: "Todos los derechos reservados",
    HandCraftedMMade: "Hecho a mano y con",
    Invoice: "Factura",
    OrderNumber: "Número orden",
    OrderDate: "Fecha orden",
    ShipTo: "Emitir a",
    BillTo: "Facturar a",
    Item: "Número",
    Code: "Código",
    QtyShip: "Cantidad emitida",
    QtyOrder: "Cantidad orden",
    TotalVolumen: "Volumen total",
    Price: "Precio",
    Tax: "Impuestos",
    Taxable: "Gravable",
    Subtotal: "Subtotal",
    Comments: "Comentarios",
    ShippingAndHandling: "Emisión y manipulación",
    TotalAmountDue: "Importe total adeudado",
    PaymentsReceived: "Pagos recibidos",
    ReceiptAmount: "Cantidad de recibo",
    TotalCashReceipts: "Total recibos de efectivo",
    InvoceBalance: "Saldo de factura",
    Close: "Cerrar",
    NoAvailableData: "No hay datos disponibles",
    LeftVolumen: "Volumen izquierda",
    RightVolumen: "Volumen derecha",
    ExportAs: "Exportar como CSV",
    Logout: "Cerrar sesión",
    LeftBvTravelPoint: "Izquierda - Puntos de viaje (BV)",
    RightBvTravelPoint: "Derecha - Puntos de viaje (BV)",
    Qualifications: "Calificaciones",
    Rank: "Rango",
    VolumenPersonal: "Volumen personal",
    ViewEnrolledsTotal: "Ver total de patrocinados",
    CyclesRequired: "Ciclos requeridos",
    CyclesTotal: "Total de ciclos realizados",
    PersonalEnrollees: "Patrocinados personales",
    Left: "Izquierda",
    Right: "Derecha",
    SinceWeek: "Desde la semana",
    UntilWeek: "Hasta la semana",
    Select: "Seleccione",
    Search: "Buscar",
    Summary: "Resumen",
    EndDate: "Fecha fin",
    CheckDate: "Fecha verificación",
    CheckAmount: "Cantidad verificada",
    EarningsAmount: "Cantidad de ganancias",
    CurrencyCode: "Código moneda",
    TransactionNumber: "Número transacción",
    EarningsSummaryByPeriod: "Resumen de ganancias por período",
    RetailCommission: "Comisión turismo",
    FastStart: "Bono de inicio rápido",
    CycleCommission: "Ciclo de comisión",
    MatchingBonus: "Bono de igualdad",
    BaseBonus: "Bono base",
    LifestyleBonus: "Bono de estilo de vida",
    EarningsByCommissionType: "Ganancias por tipo de comisión",
    PeriodDetails: "Detalle de período",
    DownloadPDF: "Descargar PDF",
    Detail: "Detalle",
    DistributorID: "ID distribuidor",
    Name: "Nombre",
    PaidRank: "Rango pago",
    PinRank: "Pin rango",
    PercentContributed: "Porcentaje contribuído",
    AmountContributed: "Cantidad contribuída",
    Amount: "Cantidad",
    Associated: "Asociado",
    Silver: "Plata",
    Gold: "Oro",
    Platinum: "Platino",
    Diamond: "Diamante",
    DoubleDiamond: "Doble Diamante",
    TripleDiamond: "Triple Diamante",
    Crown: "Corona",
    CrownAmbassador: "Embajador de la Corona",
    TotalCommissionsEarned: "Total de comisiones ganadas",
    Country: "País",
    Percent: "Porcentaje",
    Level: "Nivel",
    PaidLevel: "Nivel pagado",
    Bonus: "Bono",
    CycleNumber: "Ciclo número",
    Step: "Paso",
    BinaryTree: "Árbol binario",
    Status: "Estado",
    RequiredEnrolleds: "Patrocinados requeridos",
    EndRank: "Rango final",
    Side: "Lado",
    Sponsor: "Colocación",
    FromLevel: "Desde el nivel",
    UntilLevel: "Hasta el nivel",
    All: "Todos",
    Customer: "Cliente",
    Terminated: "Terminado",
    Distributor: "Distribuidor",
    MemberType: "Tipo de miembro",
    Suspended: "Suspendido",
    AmountRows: "Cantidad de filas",
    Alert: "Alerta",
    Error: "Error",
    Success: "Éxito",
    Start: "Inicio",
    End: "Fin",
    Unclassified: "Sin clasificar",
    Login: "Iniciar sesión",
    Password: "Contraseña",
    ForgotPassword: "¿Olvidó su contraseña?",
    RecoverPassword: "Recuperar su contraseña",
    Recover: "Recuperar",
    Recapture: "Recobro",
    ConfirmPassword: "Confirmar contraseña",
    VerificationCode: "Código de verificación",
    ResetPassword: "Restablecer contraseña",
    Reset: "Restablecer",
    CustomizedCommissions: "Comisiones personalizadas",
    TipAndCustomizedFee: "Propinas y fee personalizado",
    FeeTotal: "Total fee",
    TipsTotal: "Total propinas",
    Pay: "Pagar",
    WithoutPay: "Sin pagar",
    BookingCode: "Código reserva",
    CreateDate: "Fecha creación",
    PaymentDate: "Fecha pago",
    Type: "Tipo",
    Tip: "Propina",
    CustomizedFee: "Fee personalizado",
    Transfer: "Transferir",
    Congratulations: "Felicitaciones",
    TotalTransactions: "Total transacciones",
    AdjustmentTip: "Ajuste propina",
    AdjustmentCustomizedFee: "Ajuste fee personalizado",
    Payment: "Pago",
    Settings: "Ajustes",
    Active: "Activo",
    Inactive: "Inactivo",
    Access: "Accesos",
    SearchIta: "Buscar Ita",
    ExpirationDate: "Fecha expiración",
    CreatedBy: "Creado por",
    Create: "Crear",
    Confirm: "Confirmar",
    TotalPendingCharges: "Total cargos pendientes",
    PendingCharges: "Cargos pendientes",
    ChangeDate: "Fecha modificación",
    UpdatedBy: "Modificado por",
    LastName: "Apellido",
    FinancialCharge: "Cargo financiero",
    Historical: "Histórico",
    NewValue: "Valor nuevo",
    OldValue: "Valor anterior",
    Action: "Acción",
    UpdateEmail: "Modificar correo electrónico",
    Save: "Guardar",
    ThemeCustomized: "Tema personalizado",
    LayoutType: "Tipo de diseño",
    Vertical: "Vertical",
    Horizontal: "Horizontal",
    Color: "Color",
    Mode: "Modo",
    Light: "Luz",
    Dark: "Oscuro",
    SemiDark: "Semi-oscuro",
    CollapseSidebar: "Colapsar barra lateral",
    NavbarColor: "Color barra de navegación",
    NavbarType: "Tipo barra de navegación",
    FooterType: "Tipo pie de página",
    RightToLeft: "Derecha a izquierda",
    Animation: "Animación",
    ZoomFade: "Desvanecer zoom",
    SlideFade: "Desvanecer página",
    FadeBottom: "Desvanecer hacia abajo",
    Fade: "Desvacenecer",
    ZoomOut: "Disminuir zoom",
    None: "Ninguno",
    Hidden: "Esconder",
    Static: "Estático",
    Sticky: "Adhesivo",
    Floating: "Flotante",
    Bookings: "Reservas",
    BookingDetail: "Detalle de la reserva",
    AccountNumber: "Número de cuenta",
    ContactName: "Nombre de contacto",
    WayToPay: "Forma de pago",
    NetValue: "Valor neto",
    TaxesValue: "Valor impuesto",
    FeeValue: "Valor Fee",
    FeeCustomized: "Fee Personalizado",
    MerchantValue: "Valor Merchant",
    DiscountValue: "Valor de descuento",
    TotalValue: "Valor total",
    OtherValue: "Otro valor",
    ProductType: "Tipo de producto",
    Origin: "Origen",
    Destination: "Destino",
    SupplierName: "Proveedor",
    Itinerary: "Itinerario",
    Passengers: "Pasajeros",
    PassengerType: "Tipo de pasajero",
    DocumentType: "Tipo de documento",
    Telephone: "Teléfono",
    FareValue: "Valor Fare",
    Value: "Valor",
    Filters: "Filtros",
    Gds: "Gds",
    Managed: "Está gestionada",
    TicketNumber: "Número boleto",
    Source: "Fuente",
    PaymentMethod: "Forma pago",
    Stage: "Etapa",
    Paid: "Pagada",
    Yes: "Sí",
    No: "No",
    StartDate: "Fecha inicio",
    Commercial: "Comercial",
    RiskAnalysis: "Análisis de riesgos",
    PaymentStage: "Cobro",
    Issue: "Emisión",
    Billing: "Facturación",
    Commission: "Comisión",
    Returns: "Devoluciones",
    Cancellations: "Anulaciones",
    Changes: "Cambios",
    ChargeSuppliers: "Cobro a proveedores",
    Recovery: "Recuperación",
    Fraud: "Fraude",
    Clean: "Limpiar",
    Documents: "Documentos",
    Tracking: "Trazabilidad",
    Import: "Importar",
    Users: "Usuarios",
    Delete: "Eliminar",
    Edit: "Editar",
    Print: "Imprimir",
    Actions: "Acciones",
    Subject: "Asunto",
    GeneratePayments: "Generar pago",
    PaymentInformation: "Información del pago",
    MethodOfPayment: "Forma de pago",
    CardType: "Tipo tarjeta",
    CardOwner: "Propietario",
    CardNumber: "Número tarjeta",
    ExpirationMonth: "Mes de expiración",
    ExpirationYear: "Año de expiración",
    SecurityCode: "Código de seguridad",
    ZipCode: "Código postal",
    BillingData: "Datos facturación",
    CreditCard: "Tarjeta de crédito",
    TotalToPay: "Total a pagar",
    Cards: "Tarjetas",
    Merchant: "Merchant",
    Charge: "Cobrar",
    Send: "Enviar",
    PaymentLink: "Link de pagos",
    PayYourReservation: "Paga tu reserva",
    CountryCode: "Código país",
    Currency: "Moneda",
    AddPassenger: "Agregar Pasajero",
    ExpirationDatePassport: "Fecha de vencimiento pasaporte",
    SpecificationName: "Nombre de especificación",
    SpecificationValue: "Valor de la especificación",
    AddItinerary: "Agregar Itinerario",
    FirstName: "Nombre",
    Booking: "Reserva",
    BookingByReference: "Obtener Reserva",
    MyDistributor: "Mi distribuidor",
    Previousstage: "Etapa Anterior",
    Currentstage: "Etapa Actual",
    Newstage: "Nueva Etapa",
    Responsable: "Responsable",
    State: "Estado",
    Get: "Obtener",
    Sent: "Enviados",
    NewMessage: "Nuevo Mensaje",
    To: "Para",
    From: "De",
    Attachments: "Adjuntos",
    SelectTemplate: "Seleccione plantilla",
    Body: "Cuerpo",
    StartManagement: "Iniciar gestión",
    Remark: "Observación",
    Ita: "Ita",
    Results: "Resultados",
    GeneralInformation: "Información general",
    Contact: "Contacto",
    ReservationType: "Tipo de reserva",
    TotalTaxes: "Total impuestos",
    NetTotal: "Total neto",
    Purchases: "Compras",
    Generate: "Generar",
    Upgrade: "Promover de nivel",
    MonthlyPayment: "Mensualidad",
    AutomaticMonthlyPayment: "Mensualidad automática",
    Buy: "Comprar",
    DepartureDate: "Fecha de salida",
    ArrivalDate: "Fecha de llegada",
    Class: "Clase",
    Airline: "Aerolínea",
    Airlines: "Aerolíneas",
    FlightNumber: "Número de vuelo",
    Passenger: "Pasajero",
    IsPayed: "Es pagado",
    AddPayment: "Agregar pago",
    MsgValidField: "Agrega Campos solicitados",
    NumberTransaction: "Número de transacción",
    BankName: "Nombre banco",
    AuthorizationNumber: "Número de autorización",
    CreditCardNumber: "Número TC",
    CreditCardType: "Tipo TC",
    CreditCardOwner: "Propietario TC",
    DuesNumber: "Número cuotas",
    CardholderLastName: "Apellido dueño de la tarjeta",
    WriteOrigin: "Escriba un origen",
    WriteDestination: "Escriba un destino",
    LenderServices: "Prestador servicios",
    Supplier: "Proveedor",
    FlightClass: "Clase de vuelo",
    Update: "Modificar",
    RemarkPublish: "Publicar",
    Cancel: "Cancelar",
    OpenDocument: "Abrir documento",
    Notifications: "Notificaciones",
    AssignedTo: "Asignado a",
    Blog: "Blog",
    Language: "Idioma",
    Title: "Título",
    CreationDate: "Fecha creación",
    List: "Lista",
    Preview: "Vista previa",
    HasAttachment: "Tiene adjunto",
    Transactions: "Transacciones",
    GroupedByTransaction: "Agrupado por transacción",
    Product: "Producto",
    IsFinanced: "Es financiado",
    Payments: "Pagos",
    Attachment: "Adjunto",
    Installments: "Coutas",
    FinanceProduct: "¿Quieres financiar tu producto?",
    VerifyDate: "Fecha de verificación",
    IncludeNeo: "¿Quieres comprar la aplicación de Neo?",
    Pending: "Pendiente",
    Verify: "Verificación",
    IncompleteDocumentation: "Documentación incompleta",
    ResidenceCountry: "País de residencia",
    ResidenceState: "Estado de residencia",
    Enroller: "Patrocinador",
    Presenter: "Presentador",
    AllCloser: "Todo cerrador",
    InstallmentPay: "Tu cuota es por solo",
    BeingMember: "¡Ser miembro!",
    Closer: "Cerrador",
    BeneficiaryName: "Nombre beneficiario",
    BeneficiaryLastName: "Apellido beneficiario",
    MembershipType: "Tipo afiliación",
    Bill: "Factura",
    InstallmentNumber: "Couta",
    Indicative: "Indicativo",
    NewMember: "Nuevo miembro",
    Management: "Gestión",
    SendContract: "Enviar contrato",
    SendIOU: "Enviar pagaré",
    SendWelcome: "Enviar bienvenida",
    CreateEwallet: "Crear billetera",
    Generated: "Generada",
    BirthDay: "Día de nacimiento",
    BirthMonth: "Mes de nacimiento",
    BirthYear: "Año de nacimiento",
    Male: "Masculino",
    Female: "Femenino",
    Other: "Otro",
    Hello: "Hola",
    MembershipJoin:
      "Has registrado un nuevo miembro a nuestra comunidad. Su distribuidor ID es:",
    OrderGenerated: "También, se generó una factura con el número de orden:",
    GenerateMembership: "Generar afiliación",
    Welcome: "Bienvenido",
    BookingHandmade: "Reserva hecha a mano",
    ItaCode: "Codígo Ita",
    ContactFormTitle:
      "¿A que teléfono podemos contactarte en caso de emergencia?",
    TelephoneType: "Tipo teléfono",
    TelephoneNumber: "Número telefónico",
    Specifications: "Especificaciones",
    Specification: "Especificacíon",
    Add: "Agregar",
    AdditionalInformation: "Información adicional",
    InsuranceName: "Nombre del seguro",
    ActivityName: "Nombre de la actividad",
    RentalName: "Nombre del alquiler",
    SupplierRentalCode: "código de alquiler del proveedor",
    Private: "Privado",
    Affiliations: "Afiliaciones",
    Accounting: "Contabilidad",
    CashRegister: "Registro de caja",
    PaymentsConfirmed: "Pagos confirmados",
    SignatureStatus: "Firma",
    Account: "Cuenta",
    PasswordUpdatedDate: "Fecha cambio contraseña",
    Nickname: "Alias",
    IsFavorite: "Es favorita",
    ItsRegistred: "Está registrada",
    MembershipCountry: "País de afiliación",
    Help: "Ayuda",
    UpdateSignatures: "Actualizar firmas",
    Contracts: "Contratos",
    Answer: "Respuesta",
    AccountType: "Tipo de cuenta",
    TinaCode: "Código TINA",
    OtherProducts: "Otros productos",
    Tourism: "Turismo",
    PackagesHelp: "Ayuda de paquetes",
    ExchangeRate: "Tasa cambio",
    MailSetting: "Configuración email",
    Identifier: "Identificador",
    Parameters: "Parámetros",
    Products: "Productos",
    ContactTypeName: "Nombre Tipo Contacto",
    Benefit: "Beneficio",
    PriceLevel: "Nivel de precio",
    IsCompany: "Es una compañía",
    Promotion: "Promoción",
    IsRealtor: "Es realtor",
    AccertifyId: "ID Accertify",
    Accertify: "Accertify",
    AccertifyPoints: "Puntos Accertify",
    DetailPrices: "Precios detallados",
    SupplierDestination: "Destino proveedor",
    TinaHelp:"Ayuda Tina",
    Packages: "Paquetes",
    City: "Ciudad",
    BookingValue: "Valor reserva",
    AirlineCommission: "Comisión aerolínea",
    Hotels: "Hoteles",
    IBO: "Independent Business Owner (IBO)",
    Responsible: "Responsable",
    ChangeResponsible: "¿Te gustaría cambiar el responsable de la reserva?",
    Category: "Categoría",
    Accommodation: "Acomodación",
    WriteName: "Escriba un nombre",
    Longitude: "Longitud",
    Latitude: "Latitud",
    StarNumber: "Número de estrellas",
    Outstanding: "Destacado",
    Disabled: "Inhabilitar",
    DescriptionES: "Descripción español",
    DescriptionEN: "Descripción inglés",
    ReferenceCode: "Código referencia",
    BVPoints: "Puntos BV",
    ShortName: "Nombre corto",
    CustomizedAVV: "Personalizadas AVV",
    CustomizedO2T: "Personalizadas One2trip",
    RelationshipItaCode: "Relación Codígo Ita",
    ManagementPackage:"Gestión paquete",
    Service:"Servicio",
    NameEs:"Nombre español",
    NameEn:"Nombre inglés",
    CategoryType:"Tipo Categoria",
    AdultAmount:"Cantidad adulto",
    ChildAmount:"Cantidad niño",
    InfantAmount:"Cantidad infante",
    Days:"Días",
    Nights:"Noches",
    Popular:"Popular",
    MinimumPrice:"Precio mínimo",
    IncludeEn: "Incluye inglés",
    IncludeEs: "Incluye Español",
    NoIncludeEs: "No Incluye español",
    NoIncludeEn: "No Incluye inglés",
    ImportantInformationEs: "Información importante español",
    ImportantInformationEn: "Información importante inglés",
    Configuration:"Configuración",
    Application: "Aplicación",
    CancellationPolicy:"Politica cancelación",
    Images:"Imágenes",
    Main:"Principal",
    Age: "Edad",
    Hotel: "Hotel",
    AgentCommissionValue: "Valor comisión agente",
    MainBooking: "Reserva principal",
    Blocks: "Bloqueos",
    AppliesBothWays: "Aplica ambos sentidos",
    ChargeIn: "Cargo en",
    Discount: "Descuento",
    Benefits: "Beneficios",
    Points: "Puntos",
    Plan: "Plan",
    ContentType: "Tipo contenido",
    IsFeatured: "Es destacado",
    DiscountIn: "Descuento en",
    Sales: "Ventas",
    CreationDay: "Día creación",
    CreationMonth: "Mes creación",
    CreationYear: "Año creación",
    CheckInDay: "Día check-in",
    CheckInMonth: "Mes check-in",
    CheckInYear: "Año check-in",
    TicketsIssued: "Boletos emitidos",
    Copy: "Copiar",
    MyCommissions: "Mis comisiones",
    Incentives: "Incentivos",
    IssueDate : "Fecha emisión",
    InitialAgent: "Agente inicial",
    FinalAgent: "Agente final",
    Administration: "Administración",
    International: "Internacional",
    AgentEmail: "Email asesor",
    IsChange: "¿Es un cambio?",
    AmountDays: "Cantidad de días",
    Area: "Área",
    SupportTeam: "Team de apoyo",
    Rol: "Rol",
    SendMoney: "Enviar dinero",
    SendMoneyEwallet: "¿Te gustaría enviar dinero a una cuenta de E-wallet?",
    SendMoneyMasspay: "¿Te gustaría enviar dinero a una cuenta de Masspay?",
    Activities: "Movimientos",
    UserName: "User name",
    MsgNotTransferEwallet: "No estamos procesando transferencias a Ewallet en este momento",
    Difference: "Diferencia",
    Receipts: "Cobros",
    Audit: "Auditoría"
  }
};
