/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: "",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: "/",
          name: "home",
          component: () => import("./views/Home.vue")
        },
        //Genealogy
        {
          path: "/",
          redirect: "/genealogy/index"
        },
        //Binary tree
        {
          path: "/genealogy/index",
          name: "index",
          component: () => import("./views/genealogy/index.vue")
        },
        //Detail Binary tree
        {
          path: "/genealogy/detail",
          name: "detail",
          component: () => import("./views/genealogy/detail.vue")
        },
        //View ITA status by period
        {
          path: "/genealogy/statusByPeriod",
          name: "statusByPeriod",
          component: () => import("./views/genealogy/statusByPeriod.vue")
        },
        //Orders
        {
          path: "/orders/index",
          name: "index",
          component: () => import("./views/orders/index.vue")
        },
        //User
        {
          path: "/pages/profile",
          name: "page-profile",
          component: () => import("@/views/pages/Profile.vue")
        },
        {
          path: "/pages/incentive-commissions",
          name: "page-incentive-commissions",
          component: () => import("@/views/pages/incentive-commissions.vue")
        },
        //Commissions
        {
          path: "/",
          redirect: "/commissions/Index"
        },
        {
          path: "/commissions/Index",
          name: "commissions",
          component: () => import("./views/commissions/Index.vue")
        },
        {
          path: "/commissions/DetailTotal/:period:transaction:date",
          name: "commissionsDetailTotal",
          component: () => import("./views/commissions/DetailTotal.vue")
        },
        {
          path: "/commissions/Customized",
          name: "Customized",
          component: () => import("./views/commissions/Customized.vue")
        },
        {
          path: "/commissions/NewCustomized",
          name: "CustomizedO2T",
          component: () => import("./views/commissions/NewCustomized.vue")
        },
        {
          path: "/commissions/DetailCustomized",
          name: "DetailCustomized",
          component: () => import("./views/commissions/DetailCustomized.vue")
        },
        //Settings
        {
          path: "/",
          redirect: "/settings/Access"
        },
        {
          path: "/settings/Access",
          name: "settings-access",
          component: () => import("./views/settings/Access.vue")
        },
        {
          path: "/settings/exchangeRate",
          name: "settings-exchangeRate",
          component: () => import("./views/settings/exchangeRate.vue")
        },
        {
          path: "/management/airlines",
          name: "management-airlines",
          component: () => import("./views/management/Airlines.vue")
        },
        {
          path: "/management/blocks",
          name: "management-blocks",
          component: () => import("./views/management/Block.vue")
        },
        {
          path: "/settings/SearchIta",
          name: "settings-searchIta",
          component: () => import("./views/settings/SearchIta.vue")
        },
        {
          path: "/settings/blog",
          name: "settings-blog",
          component: () => import("./views/settings/blog.vue")
        },
        {
          path: "/settings/createBlog",
          name: "settings-createBlog",
          component: () => import("./views/settings/createBlog.vue")
        },
        {
          path: "/settings/Mail",
          name: "settings-mail",
          component: () => import("./views/settings/Mail.vue")
        },
        {
          path: "/settings/Parameters",
          name: "settings-Parameters",
          component: () => import("./views/settings/ParameterAplication.vue")
        },
        {
          path: "/settings/products",
          name: "settings-products",
          component: () => import("./views/settings/products.vue")
        },
        {
          path: "/settings/productDetail/:id",
          name: "settings-productDetail",
          component: () => import("./views/settings/productDetail.vue")
        },
        {
          path: "/settings/Promotion",
          name: "settings-Promotion",
          component: () => import("./views/settings/promotion.vue")
        },
        {
          path: "/settings/SupplierDestination",
          name: "settings-SupplierDestination",
          component: () => import("./views/settings/SupplierDestination.vue")
        },
        {
          path: "/booking/bookingDetail/:bookingId",
          name: "booking-bookingdetail",
          component: () => import("./views/booking/bookingDetail.vue")
        },
        {
          path: "/booking/commission",
          name: "booking-commission",
          component: () => import("./views/booking/commission.vue")
        },
        {
          path: "/booking/audit",
          name: "booking-audit",
          component: () => import("./views/booking/audit.vue")
        },
        {
          path: "/management/service",
          name: "management-service",
          component: () => import("./views/management/Service.vue")
        },
        {
          path: "/management/category",
          name: "management-category",
          component: () => import("./views/management/Category.vue")
        },
        {
          path: "/management/package",
          name: "management-package",
          component: () => import("./views/management/Package.vue")
        },
        {
          path: "/management/package/detail/:id",
          name: "package-package-detail",
          component: () => import("./views/management/PackageDetail.vue")
        },
        {
          path: "/management/Hotels",
          name: "management-Hotels",
          component: () => import("./views/management/Hotels.vue")
        },
        {
          path: "/management/hoteDetail/:id?",
          name: "management-HotelDetail",
          component: () => import("./views/management/hotelDetail.vue")
        },
        {
          path: "/settings/supplier",
          name: "settings-supplier",
          component: () => import("./views/settings/supplier.vue")
        },
        {
          path: "/booking/create",
          name: "booking-Create",
          component: () => import("./views/booking/create.vue")
        },
        {
          path: "/booking/comments",
          name: "bookingComments",
          component: () => import("./views/booking/comments.vue")
        },
        {
          path: "/users/index",
          name: "usersList",
          component: () => import("./views/users/index.vue")
        },
        {
          path: "/users/detail/:contactId/:contactTypeId",
          name: "users-detail",
          component: () => import("./views/users/detail.vue")
        },
        {
          path: "/booking/byReference",
          name: "booking-byReference",
          component: () => import("./views/booking/byReference.vue")
        },
        //Purchases
        {
          path: "/purchases",
          name: "purchases",
          component: () => import("./views/purchases/index.vue")
        },
        {
          path: "/purchases/index",
          name: "purchases-index",
          component: () => import("./views/purchases/index.vue")
        },
        {
          path: "/purchases/affiliations",
          name: "purchases-affiliations",
          component: () => import("./views/purchases/affiliations.vue")
        },
        {
          path: "/purchases/membership/:purchaseId?",
          name: "purchases-membership",
          component: () => import("./views/purchases/membership.vue")
        },
        {
          path: "/purchases/upgrade/:purchaseId?",
          name: "purchases-upgrade",
          component: () => import("./views/purchases/upgrade.vue")
        },
        {
          path: "/purchases/monthlyPayment/:purchaseId?",
          name: "purchases-monthlyPayment",
          component: () => import("./views/purchases/monthlyPayment.vue")
        },
        {
          path: "/purchases/products/:purchaseId?",
          name: "purchases-products",
          component: () => import("./views/purchases/products.vue")
        },
        {
          path: "/purchases/transactions",
          name: "purchases-transactions",
          component: () => import("./views/purchases/transactions.vue")
        },
        //Accounting
        {
          path: "/accounting/payments",
          name: "accounting-payments",
          component: () => import("./views/accounting/payments.vue")
        },
        {
          path: "/accounting/cashRegister",
          name: "accounting-cashRegister",
          component: () => import("./views/accounting/cashRegister.vue")
        },
        {
          path: "/accounting/sales",
          name: "accounting-sales",
          component: () => import("./views/accounting/sales.vue")
        },
        {
          path: "/accounting/flightSales",
          name: "accounting-flightSales",
          component: () => import("./views/accounting/flightSales.vue")
        },
        {
          path: "/payment/settings",
          name: "payment-settings",
          component: () => import("./views/payment/settings.vue")
        },
        {
          path: "/payment/settingsCreate",
          name: "payment-settingsCreate",
          component: () => import("./views/payment/settingsCreate.vue")
        },
        {
          path: "/accounting/index-incentive",
          name: "accounting-index-incentive",
          component: () => import("@/views/accounting/index-incentive.vue")
        },
        //BLOCKPAY
        {
          path: "/blockPay",
          name: "blockPay",
          component: () => import("./views/blockPay/index.vue")
        },
        {
          path: "/blockPay/index",
          name: "blockPay-index",
          component: () => import("./views/blockPay/index.vue")
        },
        {
          path: "/blockPay/employees",
          name: "blockPay-employees",
          component: () => import("@/views/blockPay/employees.vue")
        },
        // //BENEFITS
        {
          path: "/benefit",
          name: "benefit",
          component: () => import("./views/benefit/index.vue")
        },
        {
          path: "/benefit/index",
          name: "benefit-index",
          component: () => import("./views/benefit/index.vue")
        },
        {
          path: "/benefit/point",
          name: "benefit-point",
          component: () => import("./views/benefit/point.vue")
        }
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: "/pages/login",
          name: "page-login",
          component: () => import("@/views/pages/Login.vue")
        },
        {
          path:
            "/payment/online/:language/:isCreditCard/:bookingId/:bookingDetailId?",
          name: "payment-online",
          component: () => import("@/views/payment/online.vue")
        },
        {
          path: "/pages/error-404",
          name: "page-error-404",
          component: () => import("@/views/pages/Error404.vue")
        },
        {
          path: "/pages/forgot-password",
          name: "page-forgot-password",
          component: () => import("@/views/pages/ForgotPassword.vue"),
          meta: {
            rule: "editor"
          }
        },
        {
          path: "/pages/reset-password",
          name: "page-reset-password",
          component: () => import("@/views/pages/ResetPassword.vue"),
          meta: {
            rule: "editor"
          }
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: "*",
      redirect: "/pages/error-404"
    }
  ]
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

router.beforeEach((to, from, next) => {
  let expiryVT = new Date(localStorage.getItem("tokenExpiryVT") * 1000);
  if (
    to.name == "payment-online" ||
    to.path === "/pages/login" ||
    to.path === "/pages/error-404" ||
    to.path == "/pages/forgot-password" ||
    to.path == "/pages/reset-password" ||
    (new Date(Date.now()) < expiryVT && localStorage.getItem("tokenVT") != "")
  ) {
    return next();
  }

  router.push({
    path: "/pages/login",
    query: {
      to: to.path
    }
  });
});

export default router;
