<template>
  <div>
    <div id="ag-grid-demo">
      <vx-card>
        <h4 class="font-semibold mb-base">{{ $t(resources.Search.i18n) || resources.Search.name }}</h4>
        <div class="vx-row mb-base">
          <div class="vx-col w-full md:w-1/4 mb-2">
            <vs-input
              type="number"
              class="w-full"
              icon-pack="feather"
              icon="icon-user"
              icon-no-border
              :label="
                $t(resources.DistributorID.i18n) || resources.DistributorID.name
              "
              v-model="itaCodeForm"
            />
          </div>
          <div class="vx-col w-full md:w-1/6 mb-2 mt-5">
            <vs-button color="primary" type="filled" @click="validateAccess()">
              {{
              $t(resources.Search.i18n) || resources.Search.name
              }}
            </vs-button>
          </div>
        </div>
        <div class="vx-row">
          <vs-table :data="accessData" class="vx-col w-full">
            <template slot="thead">
              <vs-th>
                {{
                $t(resources.Status.i18n) || resources.Status.name
                }}
              </vs-th>
              <vs-th>
                {{
                $t(resources.DistributorID.i18n) || resources.DistributorID.name
                }}
              </vs-th>
              <vs-th>
                {{
                $t(resources.Name.i18n) || resources.Name.name
                }}
              </vs-th>
              <vs-th>
                {{
                $t(resources.Email.i18n) || resources.Email.name
                }}
              </vs-th>
              <vs-th>
                {{
                $t(resources.CountryOperation.i18n) ||
                resources.CountryOperation.name
                }}
              </vs-th>
              <vs-th>
                {{
                $t(resources.ResidenceCity.i18n) || resources.ResidenceCity.name
                }}
              </vs-th>
              <vs-th>
                {{
                $t(resources.Address.i18n) || resources.Address.name
                }}
              </vs-th>
              <vs-th>
                {{
                $t(resources.Mobile.i18n) || resources.Mobile.name
                }}
              </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td
                  :data="data[indextr].statusId"
                  v-if="data[indextr].statusId == '1'"
                  class="text-center"
                >
                  <vs-icon icon-pack="feather" icon="icon-check-circle" color="success"></vs-icon>
                </vs-td>
                <vs-td :data="data[indextr].statusId" v-else class="text-center">
                  <vs-icon icon-pack="feather" icon="icon-x-circle" color="danger"></vs-icon>
                </vs-td>
                <vs-td :data="data[indextr].code">
                  {{
                  data[indextr].code
                  }}
                </vs-td>
                <vs-td
                  :data="data[indextr].name"
                >{{ data[indextr].name }} {{ data[indextr].lastName }}</vs-td>
                <vs-td :data="data[indextr].email">
                  {{
                  data[indextr].email
                  }}
                </vs-td>
                <vs-td :data="data[indextr].countryOperationDefault.countryCode">
                  {{
                  data[indextr].countryOperationDefault.countryCode
                  }}
                </vs-td>
                <vs-td>
                  {{
                  data[indextr].cityName
                  }}
                </vs-td>
                <vs-td>
                  {{
                  data[indextr].address
                  }}
                </vs-td>
                <vs-td>
                  {{
                  data[indextr].phone
                  }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
        <br />

        <vs-divider class="mb-2">
          <h4 class="font-semibold mb-base">
            {{
            $t(resources.CustomizedFee.i18n) || resources.CustomizedFee.name
            }}
          </h4>
        </vs-divider>
        <div class="vx-row">
          <vs-table :data="feeList" class="vx-col w-full">
            <template slot="thead">
              <vs-th>
                {{
                $t(resources.Type.i18n) || resources.Type.name
                }}
              </vs-th>
              <vs-th>
                {{
                $t(resources.Percent.i18n) || resources.Percent.name
                }}
              </vs-th>
              <vs-th>
                {{
                $t(resources.Amount.i18n) || resources.Amount.name
                }}
              </vs-th>
              <vs-th>
                {{
                $t(resources.CreateDate.i18n) || resources.CreateDate.name
                }}
              </vs-th>
              <vs-th>
                {{
                $t(resources.Status.i18n) || resources.Status.name
                }}
              </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].CodProd">
                  {{
                  data[indextr].CodProd
                  }}
                </vs-td>
                <vs-td :data="data[indextr].EsPorcentaje" v-if="data[indextr].EsPorcentaje">
                  <vs-icon
                    icon-pack="material-icons"
                    icon="check_circle"
                    size="small"
                    color="rgba(var(--vs-success), 1)"
                  ></vs-icon>
                </vs-td>
                <vs-td :data="data[indextr].EsPorcentaje" v-else>
                  <vs-icon
                    icon-pack="material-icons"
                    icon="highlight_off"
                    size="small"
                    color="rgba(var(--vs-danger), 1)"
                  ></vs-icon>
                </vs-td>
                <vs-td :data="data[indextr].Valor">
                  {{
                  data[indextr].Valor
                  }}
                </vs-td>
                <vs-td :data="data[indextr].FechaCreacion">
                  {{
                  data[indextr].FechaCreacion
                  }}
                </vs-td>
                <vs-td :data="data[indextr].Activo" v-if="data[indextr].Activo">
                  <vs-icon
                    icon-pack="material-icons"
                    icon="check_circle"
                    size="small"
                    color="rgba(var(--vs-success), 1)"
                  ></vs-icon>
                </vs-td>
                <vs-td :data="data[indextr].Activo" v-else>
                  <vs-icon
                    icon-pack="material-icons"
                    icon="highlight_off"
                    size="small"
                    color="rgba(var(--vs-danger), 1)"
                  ></vs-icon>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>

        <br />
        <vs-divider>
          <h4
            class="font-semibold mb-base"
          >{{ $t(resources.Historical.i18n) || resources.Historical.name }}</h4>
        </vs-divider>

        <div class="flex flex-wrap justify-between items-center">
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2">
                  {{
                  currentPage * paginationPageSize - (paginationPageSize - 1)
                  }}
                  -
                  {{
                  accessData.length - currentPage * paginationPageSize > 0
                  ? currentPage * paginationPageSize
                  : accessData.length
                  }}
                  of {{ accessData.length }}
                </span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
          <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              :placeholder="$t(resources.Search.i18n) || resources.Search.name"
            />
            <vs-button class="mb-4 md:mb-0 mr-1" @click="gridApi.exportDataAsCsv()">
              {{
              $t(resources.ExportAs.i18n) || resources.ExportAs.name
              }}
            </vs-button>
          </div>
        </div>

        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="historicoFee"
          rowSelection="single"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
        ></ag-grid-vue>
        <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" />
      </vx-card>
      <vs-popup
        :title="$t(resources.Update.i18n) || resources.Update.name"
        :active.sync="popupActive"
      >
        <div class="vx-row">
          <div class="vx-col w-full md:w-1/2 mb-2">
            <label>
              {{
              $t(resources.BirthDate.i18n) || resources.BirthDate.name
              }}
            </label>
            <datepicker :language="languages[language]" format="d MMMM yyyy" v-model="birthDate"></datepicker>
          </div>
          <div class="vx-col w-full md:w-1/2 mb-2">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-file"
              icon-no-border
              :label="$t(resources.DocumentNumber.i18n) || resources.DocumentNumber.name"
              v-model="document"
            />
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <vs-input
              type="email"
              class="w-full"
              icon-pack="feather"
              icon="icon-user"
              icon-no-border
              :label="$t(resources.Email.i18n) || resources.Email.name"
              v-model="emailForm"
            />
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full md:w-1/2 mb-2">
            <label
              class="vs-input--label"
            >{{$t(resources.CountryOperation.i18n) || resources.CountryOperation.name}}</label>
            <v-select
              type="text"
              class="w-full"
              icon-pack="feather"
              icon="icon-arrow-down"
              icon-no-border
              :clearable="true"
              :options="countryOperationList"
              :reduce="country=> country.countryCode"
              label="display"
              v-model="countryOperation"
            >
              <template slot="option" slot-scope="option">
                <img :src="option.iconUrl" style="with:9px" />
                {{ option.display }}
              </template>
            </v-select>
          </div>
          <div class="vx-col w-full md:w-1/2 mb-2">
            <label class="vs-input--label">{{$t(resources.Country.i18n) || resources.Country.name}}</label>
            <v-select
              type="text"
              class="w-full"
              icon-pack="feather"
              icon="icon-arrow-down"
              icon-no-border
              :clearable="true"
              :options="countryList"
              :reduce="country=> country.id"
              label="name"
              v-model="country"
              v-on:input="getStateByCountry(`${country}`)"
              :hint="`${country}`"
            />
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full md:w-1/2 mb-2">
            <label class="vs-input--label">{{$t(resources.State.i18n) || resources.State.name}}</label>
            <v-select
              type="text"
              class="w-full"
              icon-pack="feather"
              icon="icon-arrow-down"
              icon-no-border
              :clearable="false"
              :options="stateCountry"
              :reduce="state=> state.id"
              label="name"
              v-model="state"
              v-on:input="getCityByState(`${state}`)"
              :hint="`${state}`"
            />
          </div>
          <div class="vx-col w-full md:w-1/2 mb-2">
            <label
              class="vs-input--label"
            >{{$t(resources.ResidenceCity.i18n) || resources.ResidenceCity.name}}</label>
            <v-select
              type="text"
              class="w-full"
              icon-pack="feather"
              icon="icon-arrow-down"
              icon-no-border
              :clearable="false"
              :options="destinationList"
              :reduce="city=> city.id"
              label="name"
              v-model="residenceCity"
            />
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full mb-2">
            <vs-input
              type="text"
              class="w-full"
              icon-pack="feather"
              icon="icon-home"
              icon-no-border
              :label="$t(resources.Address.i18n) || resources.Address.name"
              v-model="address"
            />
          </div>
          <div class="vx-col w-full mb-2">
            <vs-input
              type="text"
              class="w-full"
              icon-pack="feather"
              icon="icon-phone"
              icon-no-border
              :label="$t(resources.Phone.i18n) || resources.Phone.name"
              v-model="phone"
            />
          </div>
          <div class="vx-col w-full md:w-1/6 mb-2 mt-5">
            <vs-button color="primary" type="filled" @click="update()">
              {{
              $t(resources.Save.i18n) || resources.Save.name
              }}
            </vs-button>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
</template>
<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import { AgGridVue } from "ag-grid-vue";
import Datepicker from "vuejs-datepicker";
import axios from "axios";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import vSelect from "vue-select";
import * as lang from "vuejs-datepicker/src/locale";

export default {
  components: {
    AgGridVue,
    Datepicker,
    vSelect
  },
  data() {
    return {
      popupActive: false,

      resources: resources,
      alerts: alerts,
      language: this.$i18n.locale.toUpperCase(),
      languages: lang,
      status: "1",
      birthDate: "",
      contactId: "",
      itaCode: JSON.parse(localStorage.getItem("userInfo")).itaCode,
      email: JSON.parse(localStorage.getItem("userInfo")).email,
      emailIta: "",
      emailForm: "",
      country: "",
      countryOperation: "",
      document: "",
      residenceCity: "",
      cityName: "",
      stateName: "",
      countryOperationList: [],
      countryList: [],
      state: "",
      stateCountry: [],
      destinationList: [],
      address: "",
      phone: "",
      itaCodeForm: "",
      expirateDate: null,
      nodeId: 0,
      accessData: [],
      feeList: [],
      historicoFee: [],
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: "#",
          field: "IdRegistro",
          filter: true
        },
        {
          headerName: this.$i18n.t("DistributorID"),
          field: "CodigoIta",
          filter: true
        },
        {
          headerName: this.$i18n.t("Field"),
          field: "Campo",
          filter: true
        },
        {
          headerName: this.$i18n.t("OldValue"),
          field: "ValorAnterior",
          filter: true,
          width: 150
        },
        {
          headerName: this.$i18n.t("NewValue"),
          field: "ValorNuevo",
          filter: true
        },
        {
          headerName: this.$i18n.t("Date"),
          field: "FechaCambio",
          width: 150,
          sort: "asc"
        },
        {
          headerName: this.$i18n.t("Action"),
          field: "Accion",
          filter: true
        }
      ]
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("level", null);
      } else this.gridOptions.columnApi.setColumnPinned("level", "left");
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 20;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    }
  },
  created: async function() {
    await this.getCountry();
    await this.getCountryOperation();
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    async validateAccess() {
      this.$vs.loading();

      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Ita/${this.$i18n.locale.toUpperCase()}/${this.itaCodeForm}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (result.data.id != null) {
            await this.getStateByCountry(result.data.countryId);
            await this.getCityByState(result.data.stateId);
            this.emailForm = result.data.email;
            this.emailIta = result.data.email;
            this.contactId = result.data.conctactId;
            this.countryOperation =
              result.data.countryOperationDefault.countryCode;
            this.country = result.data.countryId;
            this.state = result.data.stateId;
            this.residenceCity = result.data.cityId;
            this.address = result.data.address;
            this.phone = result.data.phone;
            this.document = result.data.document;
            let birthdate = result.data.birthDate.split("-");
            this.birthDate = new Date(
              birthdate[0],
              birthdate[1] - 1,
              birthdate[2]
            );

            await this.getNameCity();

            this.accessData = [];
            this.accessData.push(result.data);
            this.getHistoricoFee();
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.accessData = [];
            this.feeList = [];
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      setTimeout(() => {
        this.$vs.loading.close();
      }, 5000);
    },

    async getFeePersonalizado() {
      await axios({
        method: "GET",
        url:
          process.env.VUE_APP_OVAPI + "getFeePersonalizado/" + this.itaCodeForm,
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("loggedIn")
        }
      }).then(
        result => {
          if (result.data.rowsAffected[0] == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.feeList = [];
          } else {
            this.feeList = result.data.recordsets[0];
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getHistoricoFee() {
      await axios({
        method: "GET",
        url: process.env.VUE_APP_OVAPI + "getHistoricoFee/" + this.itaCodeForm,
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("loggedIn")
        }
      }).then(
        result => {
          this.getFeePersonalizado();
          if (result.data.rowsAffected[0] == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotHistorical"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.historicoFee = [];
          } else {
            this.historicoFee = result.data.recordsets[0];
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCountry() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Country/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.countryList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCountryOperation() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }CountryOperation/GetPccCountry/${this.$i18n.locale.toUpperCase()}/2`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.countryOperationList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getNameCity() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_OVAPI
        }destination/getById/${this.$i18n.locale.toUpperCase()}/${
          this.residenceCity
        }`,
        headers: {
          "content-type": "application/json"
        }
      }).then(
        async result => {
          if (result.data) {
            this.cityName = result.data[0].cityName;
            this.stateName = result.data[0].stateName;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getStateByCountry(item) {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }State/GetByCountry/${this.$i18n.locale.toUpperCase()}/${item}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) this.stateCountry = result.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCityByState(item) {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }City/GetByState/${this.$i18n.locale.toUpperCase()}/${item}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) this.destinationList = result.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    this.getCountry();
  }
};
</script>
