<!-- =========================================================================================
    File Name: index.vue
    Description: list with information about orders
    ----------------------------------------------------------------------------------------
========================================================================================== -->

<template>
  <div>
    <div id="ag-grid-demo">
      <vx-card :title="$t(resources.OrderHistory.i18n) || resources.OrderHistory.name">
        <div class="vx-row">
          <div class="vx-col w-full md:w-1/4 mb-base">
            <label>
              {{
              $t(resources.SinceWeek.i18n) || resources.SinceWeek.name
              }}:
            </label>
            <v-select v-model="start_date" label="text" :options="getDate"></v-select>
          </div>
          <div class="vx-col w-full md:w-1/4 mb-base">
            <label>
              {{
              $t(resources.UntilWeek.i18n) || resources.UntilWeek.name
              }}:
            </label>
            <v-select v-model="end_date" label="text" :options="getDate"></v-select>
          </div>
          <div class="vx-col w-full md:w-1/6 mb-base mt-5">
            <vs-button color="primary" type="filled" @click="listOrders()">
              {{
              $t(resources.Search.i18n) || resources.Search.name
              }}
            </vs-button>
          </div>
        </div>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center">
          <!-- ITEMS PER PAGE -->
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2">
                  {{
                  currentPage * paginationPageSize - (paginationPageSize - 1)
                  }}
                  -
                  {{
                  orders.length - currentPage * paginationPageSize > 0
                  ? currentPage * paginationPageSize
                  : orders.length
                  }}
                  of {{ orders.length }}
                </span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
              <vs-dropdown-menu>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              :placeholder="$t(resources.Search.i18n) || resources.Search.name"
            />
            <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">
              {{
              $t(resources.ExportAs.i18n) || resources.ExportAs.name
              }}
            </vs-button>
          </div>
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="orders"
          rowSelection="single"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          @row-selected="onRowSelected"
        ></ag-grid-vue>
        <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" />
      </vx-card>
    </div>

    <!-- POPUP -->
    <div class="demo-alignment">
      <vs-popup
        fullscreen
        :title="$t(resources.Invoice.i18n) || resources.Invoice.name"
        :active.sync="popupActive"
      >
        <div id="invoice-page">
          <!-- INVOICE METADATA -->
          <div class="vx-row leading-loose p-base">
            <div class="vx-col w-full md:w-1/2 mt-base">
              <img src="@/assets/images/logo/logo_vt.png" alt="vuesax-logo" />
            </div>
            <div class="vx-col w-full md:w-1/2 text-right">
              <h1>{{ $t(resources.Invoice.i18n) || resources.Invoice.name }}</h1>
              <div class="invoice__invoice-detail mt-6">
                <h6>{{ $t(resources.OrderNumber.i18n) || resources.Invoice.name }}</h6>
                <p>{{ invoice.odh_number }}</p>

                <h6 class="mt-4">{{ $t(resources.OrderDate.i18n) || resources.OrderDate.name }}</h6>
                <p>{{ invoice.ord_ent_dt }}{{ invoice.ord_ent_tm }}</p>
              </div>
            </div>
            <div class="vx-col w-full md:w-1/2 mt-12">
              <h5>
                <strong>
                  {{
                  $t(resources.BillTo.i18n) || resources.BillTo.name
                  }}:
                </strong>
              </h5>
              <div class="invoice__recipient-info my-4">
                <p>{{ invoice.DST_Display_Name_1 }}</p>
                <p>{{ invoice.DST_Addr }}</p>
                <p>{{ invoice.addressLine2 }}</p>
                <p>{{ invoice.DST_Addr_3 }}</p>
              </div>
              <div class="invoice__recipient-contact">
                <p class="flex items-center">
                  <feather-icon icon="MailIcon" svgClasses="h-4 w-4"></feather-icon>
                  <span class="ml-2">{{ invoice.DST_email_addr }}</span>
                </p>
                <p class="flex items-center">
                  <feather-icon icon="PhoneIcon" svgClasses="h-4 w-4"></feather-icon>
                  <span class="ml-2">{{ invoice.DST_Home_Phone }}</span>
                </p>
              </div>
            </div>
            <div class="vx-col w-full md:w-1/2 mt-base text-right mt-12">
              <h5>
                <strong>
                  {{
                  $t(resources.ShipTo.i18n) || resources.ShipTo.name
                  }}:
                </strong>
              </h5>
              <h5>{{ invoice.ship_to_name }}</h5>
              <div class="invoice__company-info my-4">
                <p>{{ invoice.ship_to_addr_1 }}</p>
                <p>{{ invoice.ship_to_addr_3 }}</p>
                <p>
                  {{ invoice.ship_to_city
                  }}{{ " (Postal code:" + invoice.ship_to_zip + ")" }}
                </p>
                <p></p>
              </div>
              <div class="invoice__company-contact">
                <p class="flex items-center justify-end">
                  <feather-icon icon="MailIcon" svgClasses="h-4 w-4"></feather-icon>
                  <span class="ml-2">{{ invoice.ship_to_email }}</span>
                </p>
                <p class="flex items-center justify-end">
                  <feather-icon icon="PhoneIcon" svgClasses="h-4 w-4"></feather-icon>
                  <span class="ml-2">{{ invoice.ship_to_phone }}</span>
                </p>
              </div>
            </div>
          </div>

          <div v-if="!invoice">
            {{
            $t(resources.NoAvailableData.i18n) ||
            resources.NoAvailableData.name
            }}
          </div>
          <div v-else class="p-base">
            <vs-table hoverFlat :data="invoice">
              <template slot="thead">
                <vs-th>
                  {{
                  $t(resources.Item.i18n) || resources.Item.name
                  }}
                </vs-th>
                <vs-th>
                  {{
                  $t(resources.Code.i18n) || resources.Code.name
                  }}
                </vs-th>
                <vs-th>
                  {{
                  $t(resources.QtyShip.i18n) || resources.QtyShip.name
                  }}
                </vs-th>
                <vs-th>
                  {{
                  $t(resources.QtyOrder.i18n) || resources.QtyOrder.name
                  }}
                </vs-th>
                <vs-th>
                  {{
                  $t(resources.Description.i18n) || resources.Description.name
                  }}
                </vs-th>
                <vs-th>
                  {{
                  $t(resources.Volumen.i18n) || resources.Volumen.name
                  }}
                </vs-th>
                <vs-th>
                  {{
                  $t(resources.TotalVolumen.i18n) || resources.TotalVolumen.name
                  }}
                </vs-th>
                <vs-th>
                  {{
                  $t(resources.Price.i18n) || resources.Price.name
                  }}
                </vs-th>
                <vs-th>
                  {{
                  $t(resources.Total.i18n) || resources.Total.name
                  }}
                </vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data.odl">
                  <vs-td>{{ indextr + 1 }}</vs-td>
                  <vs-td :data="data.odl[indextr].item_code">
                    {{
                    data.odl[indextr].item_code
                    }}
                  </vs-td>
                  <vs-td :data="data.odl[indextr].qty_shipped">
                    {{
                    data.odl[indextr].qty_shipped
                    }}
                  </vs-td>
                  <vs-td :data="data.odl[indextr].qty_ordered">
                    {{
                    data.odl[indextr].qty_ordered
                    }}
                  </vs-td>
                  <vs-td :data="data.odl[indextr].item_name_1">
                    {{
                    data.odl[indextr].item_name_1
                    }}
                  </vs-td>
                  <vs-td :data="data.odl[indextr].price_2">
                    {{
                    data.odl[indextr].price_2
                    }}
                  </vs-td>
                  <vs-td :data="data.pv_amount">{{ data.pv_amount }}</vs-td>
                  <vs-td :data="data.odl[indextr].sales_price">
                    {{
                    data.odl[indextr].sales_price
                    }}
                  </vs-td>
                  <vs-td :data="data.sales_amount">
                    {{
                    data.sales_amount
                    }}
                  </vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-td></vs-td>
                  <vs-td></vs-td>
                  <vs-td></vs-td>
                  <vs-td></vs-td>
                  <vs-td></vs-td>
                  <th>
                    {{
                    $t(resources.TotalVolumen.i18n) ||
                    resources.TotalVolumen.name
                    }}
                  </th>
                  <td>{{ invoice.pv_amount }}</td>
                  <th>{{ $t(resources.Subtotal.i18n) || resources.Subtotal.name }}</th>
                  <td>{{ invoice.sales_amount }}</td>
                </vs-tr>
              </template>
            </vs-table>

            <div class="vx-row">
              <div class="vx-col w-full lg:w-1/2 mb-base p-base">
                <h6>{{ $t(resources.Comments.i18n) || resources.Comments.name }}:</h6>
                <p>{{ invoice.comment_1 }}</p>
                <p>{{ invoice.comment_2 }}</p>
                <p>{{ invoice.comment_3 }}</p>
                <p>{{ invoice.comment_4 }}</p>
                <p>{{ invoice.comment_5 }}</p>
                <p>{{ invoice.comment_6 }}</p>
                <br />
                <h6>
                  <strong>Warehouse 2</strong>
                </h6>
                <p>
                  <strong>{{ invoice.ord_whs_nm1 }}</strong>
                </p>
                <p>{{ invoice.ord_whs_adr1 }}</p>
                <p>{{ invoice.ord_whs_adr2 }}</p>
                <p>{{ invoice.ord_whs_adr3 }}</p>
              </div>
              <div class="vx-col w-full lg:w-1/2 mb-base p-base">
                <table hoverFlat class="w-3/4 ml-auto mt-3">
                  <vs-tr>
                    <th>
                      {{ $t(resources.Tax.i18n) || resources.Tax.name }} ({{
                      $t(resources.Taxable.i18n) || resources.Taxable.name
                      }}
                      0.00 @ {{ invoice.tax_percent }}%)
                    </th>
                    <td>{{ invoice.total_taxable_amount }}</td>
                  </vs-tr>
                  <vs-tr>
                    <th>
                      {{
                      $t(resources.ShippingAndHandling.i18n) ||
                      resources.ShippingAndHandling.name
                      }}
                      ({{ invoice.ord_shv_nm }})
                    </th>
                    <td>{{ invoice.total_invoice_amt }}</td>
                  </vs-tr>
                  <vs-tr>
                    <th>
                      {{
                      $t(resources.TotalAmountDue.i18n) ||
                      resources.TotalAmountDue.name
                      }}
                    </th>
                    <td>{{ invoice.sales_amount }}</td>
                  </vs-tr>
                </table>
              </div>
            </div>
          </div>

          <!-- INVOICE FOOTER -->
          <div class="invoice__footer text-right p-base">
            <h5>
              <strong>
                {{
                $t(resources.PaymentsReceived.i18n) ||
                resources.PaymentsReceived.name
                }}
              </strong>
            </h5>
            <p>
              <strong>
                {{
                $t(resources.ReceiptAmount.i18n) || resources.ReceiptAmount.name
                }}
              </strong>
              {{ invoice.amount_received }}
            </p>
            <p>
              <strong>
                {{
                $t(resources.TotalCashReceipts.i18n) ||
                resources.TotalCashReceipts.name
                }}
              </strong>
              {{ invoice.sales_amount }}
            </p>
            <p>
              <strong>
                {{
                $t(resources.InvoceBalance.i18n) || resources.InvoceBalance.name
                }}
              </strong>
              {{ invoice.invoice_balance }}
            </p>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full text-right">
              <vs-button color="rgb(174,174,174)" type="filled" @click="popupActive = false">
                {{
                $t(resources.Close.i18n) || resources.Close.name
                }}
              </vs-button>
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import vSelect from "vue-select";

export default {
  components: {
    AgGridVue,
    "v-select": vSelect
  },
  data() {
    return {
      alerts: alerts,
      resources: resources,
      orderNumber: "",
      popupActive: false,
      invoice: "",
      itaCode: JSON.parse(localStorage.getItem("userInfo")).itaCode,
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      start_date: null,
      end_date: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: this.$i18n.t("Number"),
          field: "ORDER_NUMBER",
          pinned: "left",
          filter: true,
          cellStyle: function() {
            return {
              color: "rgb(121,97,249)",
              "text-decoration": "underline"
            };
          },
          width: 150
        },
        {
          headerName: this.$i18n.t("PVAmount"),
          field: "PV_AMOUNT",
          filter: true
        },
        {
          headerName: this.$i18n.t("Date"),
          field: "ENTRY_DATE",
          filter: true,
          sort: "desc"
        },
        {
          headerName: this.$i18n.t("Period"),
          field: "PV_PERIOD",
          filter: true
        },
        {
          headerName: this.$i18n.t("DistCountryCode"),
          field: "DST_COUNTRY_CODE",
          filter: true
        },
        {
          headerName: this.$i18n.t("ShipToCountry"),
          field: "SHIP_TO_COUNTRY",
          filter: true
        },
        {
          headerName: this.$i18n.t("ShipToName"),
          field: "SHIP_TO_NAME",
          filter: true
        }
      ],
      orders: ""
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("firstname", null);
      } else this.gridOptions.columnApi.setColumnPinned("firstname", "left");
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 20;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    },
    getDate() {
      let dates = "[";
      let currentDate = new Date();
      let less10 = currentDate.getFullYear() - 10;

      for (var i = 0; i <= 10; i++) {
        for (var j = 1; j <= 52; j++) {
          let year = less10 + i;
          let week = j < 10 ? "0" + j : j;
          dates = dates.concat(
            '{"value":' +
              year.toString() +
              week.toString() +
              ',"text":"' +
              week +
              "/" +
              year +
              '"},'
          );
        }
      }
      dates = dates.substring(0, dates.length - 1).concat("]");
      var obj = JSON.parse(dates);
      obj = obj.sort(this.dates_sort);
      return obj;
    }
  },
  created: function() {
    this.listOrders();
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    dates_sort(a, b) {
      return a.value - b.value;
    },

    //Get list orders by ITA.
    async listOrders() {
      var jsonOrders = "";
      var d = new Date();
      let lastYear = d.getFullYear() - 1;
      let currentPeriod = JSON.parse(localStorage.getItem("periods")).PERIOD[0];

      var firstPeriod =
        this.start_date == null
          ? lastYear + currentPeriod.substring(4)
          : this.start_date.value;
      var lastPeriod =
        this.end_date == null ? currentPeriod : this.end_date.value;

      await axios({
        method: "POST",
        url: process.env.VUE_APP_OVAPI + "ListOrders",
        data: {
          infotoken: localStorage.getItem("tokenInfo"),
          distid: this.itaCode,
          start_pvdate: firstPeriod,
          end_pvdate: lastPeriod,
          fields:
            "ORDER_NUMBER,PV_AMOUNT,PV_YEAR,PV_PERIOD,DST_COUNTRY_CODE,SHIP_TO_COUNTRY,SHIP_TO_NAME,ENTRY_DATE"
        },
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("loggedIn")
        }
      }).then(
        result => {
          var data = result.data.DATA;

          for (var i = 0; i < result.data.ROWCOUNT; i++) {
            if (data.ORDER_NUMBER[i] != "undefined") {
              jsonOrders =
                jsonOrders +
                '{"ORDER_NUMBER":"#' +
                data.ORDER_NUMBER[i] +
                '","PV_AMOUNT":"' +
                data.PV_AMOUNT[i] +
                '","PV_PERIOD":"' +
                data.PV_PERIOD[i] +
                "/" +
                data.PV_YEAR[i] +
                '","DST_COUNTRY_CODE":"' +
                data.DST_COUNTRY_CODE[i] +
                '","SHIP_TO_COUNTRY":"' +
                data.SHIP_TO_COUNTRY[i] +
                '","SHIP_TO_NAME":"' +
                data.SHIP_TO_NAME[i] +
                '","ENTRY_DATE":"' +
                data.ENTRY_DATE[i] +
                '"},';
            }
          }
          jsonOrders =
            "[" + jsonOrders.substring(0, jsonOrders.length - 1) + "]";
        },
        error => {
          this.$vs.notify({
            title: "Error",
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      var obj1 = JSON.parse(jsonOrders);
      this.orders = obj1;
    },

    async onRowSelected(event) {
      let orderId = event.node.data.ORDER_NUMBER.substring(1);
      await axios({
        method: "POST",
        url: process.env.VUE_APP_OVAPI + "getInvoice",
        data: {
          infotoken: localStorage.getItem("tokenInfo"),
          distid: this.itaCode,
          odhnumber: orderId
        },
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("loggedIn")
        }
      }).then(
        result => {
          this.invoice = result.data;
          this.popupActive = true;
        },
        error => {
          this.$vs.notify({
            title: "Error",
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  }
};
</script>
