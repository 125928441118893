export default [{
    text: "Unclassified",
    value: "0",
    cycle: "0",
    enrolled: "0",
    i18n: "Unclassified"
  },
  {
    text: "Associated",
    value: "1",
    cycle: "0",
    enrolled: "2",
    i18n: "Associated"
  },
  {
    text: "Silver",
    value: "2",
    cycle: "1",
    enrolled: "4",
    i18n: "Silver"
  },
  {
    text: "Gold",
    value: "3",
    cycle: "2",
    enrolled: "6",
    i18n: "Gold"
  },
  {
    text: "Platinum",
    value: "4",
    cycle: "4",
    enrolled: "8",
    i18n: "Platinum"
  },
  {
    text: "Diamond",
    value: "5",
    cycle: "8",
    enrolled: "8",
    i18n: "Diamond"
  },
  {
    text: "Double Diamond",
    value: "6",
    cycle: "16",
    enrolled: "8",
    i18n: "DoubleDiamond"
  },
  {
    text: "Triple Diamond",
    value: "7",
    cycle: "32",
    enrolled: "8",
    i18n: "TripleDiamond"
  },
  {
    text: "Crown",
    value: "8",
    cycle: "64",
    enrolled: "8",
    i18n: "Crown"
  },
  {
    text: "Crown Ambassador",
    value: "9",
    cycle: "82",
    enrolled: "8",
    i18n: "CrownAmbassador"
  }
]
