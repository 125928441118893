<!-- =========================================================================================
    File Name: ResetPassword.vue
    Description: Reset Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="h-screen flex w-full bg-img">
    <div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img src="@/assets/images/pages/reset-password.png" alt="login" class="mx-auto" />
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
              <div class="p-8">
                <div class="text-right">
                  <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
                    <span class="cursor-pointer flex i18n-locale">
                      <img
                        class="h-5 w-7"
                        :src="
                          require(`@/assets/images/flags/${$i18n.locale}.png`)
                        "
                        :alt="$i18n.locale"
                      />
                    </span>
                    <vs-dropdown-menu class="w-48 i18n-dropdown vx-navbar-dropdown">
                      <vs-dropdown-item @click="updateLocale('en')">
                        <img class="h-4 w-5 mr-1" src="@/assets/images/flags/en.png" alt="en" />
                        &nbsp;English
                      </vs-dropdown-item>
                      <vs-dropdown-item @click="updateLocale('es')">
                        <img class="h-4 w-5 mr-1" src="@/assets/images/flags/es.png" alt="es" />
                        &nbsp;Español
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </div>
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">
                    {{
                    $t(resources.ResetPassword.i18n) ||
                    resources.ResetPassword.name
                    }}
                  </h4>
                  <p>
                    {{
                    $t(alerts.MsgInvalidPassword.i18n) ||
                    alerts.MsgInvalidPassword.name
                    }}.
                  </p>
                </div>
                <vs-input
                  v-validate="'required|email|min:3'"
                  name="email"
                  icon="icon icon-user"
                  icon-pack="feather"
                  :label-placeholder="
                    $t(resources.Email.i18n) || resources.Email.name
                  "
                  v-model="email"
                  class="w-full no-icon-border"
                />
                <span class="text-danger text-sm">
                  {{
                  errors.first("email")
                  }}
                </span>

                <vs-input
                  v-validate="'required'"
                  type="password"
                  name="password"
                  pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,10}$"
                  icon="icon icon-lock"
                  icon-pack="feather"
                  :label-placeholder="
                    $t(resources.Password.i18n) || resources.Password.name
                  "
                  v-model="password"
                  class="w-full mt-5 no-icon-border"
                />
                <span class="text-danger text-sm">
                  {{
                  errors.first("password")
                  }}
                </span>

                <vs-input
                  v-validate="'required'"
                  type="password"
                  name="confirm password"
                  icon="icon icon-lock"
                  icon-pack="feather"
                  :label-placeholder="
                    $t(resources.ConfirmPassword.i18n) ||
                      resources.ConfirmPassword.name
                  "
                  v-model="confirmPassword"
                  class="w-full mt-5 no-icon-border"
                />
                <span class="text-danger text-sm">
                  {{
                  errors.first("confirm password")
                  }}
                </span>

                <vs-input
                  v-validate="'required'"
                  name="verification code"
                  type="number"
                  icon="icon icon-user"
                  icon-pack="feather"
                  :label-placeholder="
                    $t(resources.VerificationCode.i18n) ||
                      resources.VerificationCode.name
                  "
                  v-model="verificationCode"
                  class="w-full mt-5 no-icon-border"
                />
                <span class="text-danger text-sm">
                  {{
                  errors.first("verification code")
                  }}
                </span>

                <div class="flex flex-wrap justify-between flex-col-reverse sm:flex-row mt-6">
                  <vs-button
                    type="border"
                    to="/pages/login"
                    class="w-full sm:w-auto mb-8 sm:mb-auto mt-3 sm:mt-auto"
                  >
                    {{
                    $t(resources.Return.i18n) || resources.Return.name
                    }}
                  </vs-button>
                  <vs-button
                    class="w-full sm:w-auto"
                    :disabled="!validateForm"
                    @click="changePassword"
                  >
                    {{
                    $t(resources.Reset.i18n) || resources.Reset.name
                    }}
                  </vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import axios from "axios";

export default {
  data() {
    return {
      email: "",
      password: "",
      confirmPassword: "",
      verificationCode: "",
      contactId: 0,

      resources: resources,
      alerts: alerts
    };
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.email != "" &&
        this.password != "" &&
        this.password.length > 8 &&
        this.confirmPassword != "" &&
        this.confirmPassword.length > 8 &&
        this.verificationCode != ""
      );
    }
  },
  methods: {
    updateLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem("language", locale);
    },

    changePassword() {
      var mediumRegex = new RegExp(
        "^((?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])|(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[^a-zA-Z0-9])|(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9])|(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9])).{8,}$"
      );

      if (this.password != this.confirmPassword) {
        this.$vs.notify({
          title: this.$i18n.t("Alert"),
          text: this.$i18n.t("MsgDifferentPassword"),
          color: "warning",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-alert-circle"
        });
      } else if (!mediumRegex.test(this.password)) {
        this.$vs.notify({
          time: 6000,
          title: this.$i18n.t("Alert"),
          text: this.$i18n.t("MsgInvalidPassword"),
          color: "warning",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-alert-circle"
        });
      } else {
        this.$vs.loading();
        this.verifyCode().then(response => {
          if (response.success) {
            this.contactId = response.data;
            this.reset();
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: response.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-alert-circle"
            });
            this.$vs.loading.close();
          }
        });
      }
      this.$vs.loading.close();
    },

    async verifyCode() {
      let response = true;
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_OVAPI}users/verifyCode`,
        data: {
          email: this.email,
          verifyCode: this.verificationCode,
          language: this.$i18n.locale.toUpperCase()
        },
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("loggedIn")
        }
      }).then(
        result => {
          response = result.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      return response;
    },

    async reset() {
      await axios({
        method: "POST",
        url: process.env.VUE_APP_OVAPI + "users/restorePassword",
        data: {
          contactId: this.contactId,
          email: this.email,
          password: this.password,
          confirmPassword: this.confirmPassword,
          verifyCode: this.verificationCode,
          language: this.$i18n.locale
        },
        headers: { "content-type": "application/json" }
      })
        .then(result => {
          if (result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-check"
            });
            this.$router.push("/");
            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-alert-circle"
            });
          }
        })
        .catch(error => {
          if (error.response == undefined)
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError"),
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
        });
    }
  }
};
</script>
