/*=========================================================================================
  File Name: themeConfig.js
  Description: Theme configuration
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: hhttp://www.themeforest.net/user/pixinvent
==========================================================================================*/

// MAIN COLORS - VUESAX THEME COLORS
let colors = {
  primary: localStorage.getItem("primaryColor") == null ? "#7367F0" : localStorage.getItem("primaryColor"),
  success: '#28C76F',
  danger: '#EA5455',
  warning: '#FF9F43',
  dark: '#1E1E1E',
}

let footerType = localStorage.getItem("footerType") == null ? "static" : localStorage.getItem("footerType");
let mainLayoutType = localStorage.getItem("mainLayoutType") == null ? "vertical" : localStorage.getItem("mainLayoutType");
let navbarColor = localStorage.getItem("navbarColor") == null ? "#fff" : localStorage.getItem("navbarColor");
let navbarType = localStorage.getItem("navbarType") == null ? "floating" : localStorage.getItem("navbarType");
let routerTransition = localStorage.getItem("routerTransition") == null ? "zoom-fade" : localStorage.getItem("routerTransition");
let sidebarCollapsed = localStorage.getItem("sidebarCollapsed") == null ? false : JSON.parse(localStorage.getItem("sidebarCollapsed").toLowerCase());
let theme = localStorage.getItem("theme") == null ? "light" : localStorage.getItem("theme");

// CONFIGS
const themeConfig = {
  disableCustomizer: false, // options[Boolean] : true, false(default)
  disableThemeTour: false, // options[Boolean] : true, false(default)
  hideScrollToTop: false, // options[Boolean] : true, false(default)
  rtl: false, // options[Boolean] : true, false(default)
  footerType: footerType, // options[String]  : static(default) / sticky / hidden
  mainLayoutType: mainLayoutType, // options[String]  : vertical(default) / horizontal
  navbarColor: navbarColor, // options[String]  : HEX color / rgb / rgba / Valid HTML Color name - (default: #fff)
  navbarType: navbarType, // options[String]  : floating(default) / static / sticky / hidden
  routerTransition: routerTransition, // options[String]  : zoom-fade / slide-fade / fade-bottom / fade / zoom-out / none(default)
  sidebarCollapsed: sidebarCollapsed, // options[Boolean] : true, false(default)
  theme: theme, // options[String]  : "light"(default), "dark", "semi-dark"

  // Not required yet - WIP
  userInfoLocalStorageKey: "userInfo",

  // NOTE: themeTour will be disabled in screens < 1200. Please refer docs for more info.
}

import Vue from 'vue'
import Vuesax from 'vuesax'
Vue.use(Vuesax, {
  theme: {
    colors
  },
  rtl: themeConfig.rtl
})

export default themeConfig
