<!-- =========================================================================================
    File Name: index.vue
    Description: list with information about orders
    ----------------------------------------------------------------------------------------
========================================================================================== -->

<template>
  <div>
    <vx-card :title="$t(resources.Commissions.i18n) || resources.Commissions.name">
      <h6 class="ml-2 mb-base">
        {{
        $t(alerts.InformationEarnings.i18n) ||
        alerts.InformationEarnings.i18n
        }}.
      </h6>
      <div class="vx-row mt-5 ml-1">
        <div class="vx-col w-full md:w-1/4 mb-base">
          <label>
            {{
            $t(resources.StartDate.i18n) || resources.StartDate.name
            }}:
          </label>
          <datepicker :language="languages[language]" format="d MMMM yyyy" v-model="start_date"></datepicker>
        </div>
        <div class="vx-col w-full md:w-1/4 mb-base">
          <label>{{ $t(resources.EndDate.i18n) || resources.EndDate.name }}:</label>
          <datepicker :language="languages[language]" format="d MMMM yyyy" v-model="end_date"></datepicker>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-base mt-5">
          <vs-button
            color="primary"
            type="filled"
            @click="getEarningsSummary()"
          >{{ $t(resources.Search.i18n) || resources.Search.name }}</vs-button>
        </div>
      </div>
      <vue-apex-charts type="line" height="350" :options="chartOptions" :series="series"></vue-apex-charts>
    </vx-card>

    <div id="ag-grid-demo">
      <vx-card>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center">
          <!-- ITEMS PER PAGE -->
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2">
                  {{
                  currentPage * paginationPageSize - (paginationPageSize - 1)
                  }}
                  -
                  {{
                  earnings.length - currentPage * paginationPageSize > 0
                  ? currentPage * paginationPageSize
                  : earnings.length
                  }}
                  of {{ earnings.length }}
                </span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
              <vs-dropdown-menu>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              :placeholder="$t(resources.Search.i18n) || resources.Search.name"
            />
            <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">
              {{
              $t(resources.ExportAs.i18n) || resources.ExportAs.name
              }}
            </vs-button>
          </div>
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="earnings"
          rowSelection="single"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          @row-selected="onRowSelected"
        ></ag-grid-vue>
        <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" />
      </vx-card>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import ApexCharts from "apexcharts";

export default {
  components: {
    AgGridVue,
    Datepicker,
    VueApexCharts
  },
  data() {
    return {
      itaCode: JSON.parse(localStorage.getItem("userInfo")).itaCode,
      language: this.$i18n.locale,
      languages: lang,
      alerts: alerts,
      resources: resources,
      searchQuery: "",
      earnings: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      start_date: new Date(new Date().getFullYear(), 0, 1)
        .toISOString()
        .slice(0, 10),
      end_date: null,
      currentDatePeriod: "",
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      series: [
        {
          name: "Amount",
          data: [0]
        }
      ],
      chartOptions: {
        chart: {
          id: "chart1"
        },
        colors: ["#28C76F"],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "straight"
        },
        title: {
          text: this.$i18n.t("EarningsSummaryByPeriod"),
          align: "center"
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          categories: [""]
        }
      },
      columnDefs: [
        {
          headerName: this.$i18n.t("Period"),
          field: "PV_DATE",
          pinned: "left",
          filter: true,
          cellStyle: function() {
            return {
              color: "rgb(121,97,249)",
              "text-decoration": "underline"
            };
          },
          width: 150
        },
        {
          headerName: this.$i18n.t("CheckDate"),
          field: "CHECK_DATE",
          filter: true,
          sort: "asc"
        },
        {
          headerName: this.$i18n.t("CheckAmount"),
          field: "CHECK_AMOUNT",
          filter: true
        },
        {
          headerName: this.$i18n.t("EarningsAmount"),
          field: "EARNINGS_AMOUNT",
          filter: true
        },
        {
          headerName: this.$i18n.t("CurrencyCode"),
          field: "CURRENCY_CODE",
          filter: true
        },
        {
          headerName: this.$i18n.t("TransactionNumber"),
          field: "TRANSACTION_NUMBER",
          filter: true
        }
      ]
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("firstname", null);
      } else this.gridOptions.columnApi.setColumnPinned("firstname", "left");
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 20;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    }
  },
  created: function() {
    this.listPeriodDates();
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    //Get period dates.
    async listPeriodDates() {
      let lastPeriod = JSON.parse(localStorage.getItem("periods")).PERIOD[0];

      await axios({
        method: "POST",
        url: process.env.VUE_APP_OVAPI + "listPeriodDates",
        data: {
          infotoken: localStorage.getItem("tokenInfo"),
          periodCount: 52,
          lastPeriod: lastPeriod
        },
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("loggedIn")
        }
      }).then(
        result => {
          var data = result.data.DATA;
          this.currentDatePeriod = data.END_DATE[0];
          this.end_date = new Date(
            this.currentDatePeriod.substring(0, 4) +
              "-" +
              this.currentDatePeriod.substring(4, 6) +
              "-" +
              this.currentDatePeriod.substring(6, 9)
          );
          this.getEarningsSummary();
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    //Get earnings summary by ITA.
    async getEarningsSummary() {
      var jsonEarnings = "";
      let firstPeriod = "";
      let lastPeriod = "";

      if (this.start_date != null && this.end_date != null) {
        firstPeriod = new Date(this.start_date).toISOString().slice(0, 10);
        lastPeriod = new Date(this.end_date).toISOString().slice(0, 10);
      }

      await axios({
        method: "POST",
        url: process.env.VUE_APP_OVAPI + "getEarningsSummary",
        data: {
          infotoken: localStorage.getItem("tokenInfo"),
          distid: this.itaCode,
          startDate: firstPeriod,
          endDate: lastPeriod
        },
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("loggedIn")
        }
      }).then(
        result => {
          var data = result.data.DATA;

          if (data.QERROR === undefined || data.QERROR === null) {
            for (var i = 0; i < result.data.ROWCOUNT; i++) {
              if (data.CHECK_DATE[i] != "undefined") {
                jsonEarnings =
                  jsonEarnings +
                  '{"CHECK_DATE":"' +
                  data.CHECK_DATE[i] +
                  '","CHECK_AMOUNT":"' +
                  data.CHECK_AMOUNT[i] +
                  '","PV_DATE":"' +
                  data.PV_DATE[i] +
                  '","EARNINGS_AMOUNT":"' +
                  data.EARNINGS_AMOUNT[i] +
                  '","TRANSACTION_NUMBER":"' +
                  data.TRANSACTION_NUMBER[i] +
                  '","CURRENCY_CODE":"' +
                  data.CURRENCY_CODE[i] +
                  '"},';
              }
            }
            jsonEarnings =
              "[" + jsonEarnings.substring(0, jsonEarnings.length - 1) + "]";
            var obj1 = JSON.parse(jsonEarnings);
            this.earnings = obj1;
            this.setChart();
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.earnings = [];
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    // Set information in the chart
    setChart() {
      let newCategories = [];
      let newEarnings = [];
      for (var i = 0; i < this.earnings.length; i++) {
        newCategories.push(parseInt(this.earnings[i].PV_DATE));
        newEarnings.push(this.earnings[i].EARNINGS_AMOUNT);
      }

      ApexCharts.exec("chart1", "updateOptions", {
        xaxis: {
          categories: newCategories
        }
      });
      ApexCharts.exec("chart1", "updateSeries", [
        {
          data: newEarnings
        }
      ]);
    },

    async onRowSelected(event) {
      let period = event.node.data.PV_DATE;
      this.$router.push({
        name: "commissionsDetailTotal",
        params: {
          period: period,
          transaction: event.node.data.TRANSACTION_NUMBER,
          date: event.node.data.CHECK_DATE
        }
      });
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  }
};
</script>
