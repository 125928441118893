<!-- =========================================================================================
    File Name: Home.vue
    Description: Home
========================================================================================== -->

<template>
  <div>
    <!-- Home Tittle  -->
    <h4 class="text-center text-dark font-semibold mb-base">
      <feather-icon icon="CalendarIcon" svgClasses="h-8 w-8 mb-1 stroke-current text-success" />&nbsp;
      <strong class="font-semibold text-success">
        {{
        $t(resources.CurrentPeriod.i18n) || resources.CurrentPeriod.name
        }}:
      </strong>
      {{ currentPeriodLabel }}
    </h4>

    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2">
        <div class="vx-row">
          <div class="vx-col w-full md:w-1/3 mb-base">
            <vx-card class="text-center cursor-pointer">
              <feather-icon icon="UsersIcon" svgClasses="h-6 w-6" class="text-primary" />
              <h4 class="mb-2">
                <strong>{{ newClient }}</strong>
              </h4>
              <small>
                {{
                $t(resources.NewCustomers90.i18n) ||
                resources.NewCustomers90.name
                }}
              </small>
            </vx-card>
          </div>
          <div class="vx-col w-full md:w-1/3 mb-base">
            <vx-card class="text-center cursor-pointer">
              <feather-icon icon="ShoppingCartIcon" svgClasses="h-6 w-6" class="text-primary" />
              <h4 class="mb-2">
                <strong>{{ last90days }}</strong>
              </h4>
              <small>
                {{
                $t(resources.TourismSales90.i18n) ||
                resources.TourismSales90.name
                }}
              </small>
            </vx-card>
          </div>
          <div class="vx-col w-full md:w-1/3 mb-base">
            <vx-card class="text-center cursor-pointer">
              <feather-icon icon="CalendarIcon" svgClasses="h-6 w-6" class="text-primary" />
              <h4 class="mb-2">
                <strong>{{ lastYear }}</strong>
              </h4>
              <small>
                {{
                $t(resources.TourismSales365.i18n) ||
                resources.TourismSales365.name
                }}
              </small>
            </vx-card>
          </div>
        </div>
        <!-- Volumens by business center -->
        <volumen :ita-code="itaCode" :period="currentPeriod" v-if="currentPeriod != ''"></volumen>
      </div>

      <!-- Qualifications -->
      <div class="vx-col w-full md:w-1/2">
        <qualification :ita-code="itaCode" :period="currentPeriod" v-if="currentPeriod != ''"></qualification>
      </div>
    </div>

    <!--Charts Volumens -->
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2 mb-base">
        <vx-card
          :title="
            ($t(resources.Volumen.i18n) || resources.Volumen.name) +
              ' - ' +
              ($t(resources.Center.i18n) || resources.Center.name) +
              ' 001'
          "
          class="mb-base"
        >
          <div class="mt-1">
            <vue-apex-charts
              type="bar"
              height="350"
              :options="columnChart1.chartOptions"
              :series="columnChart1.series"
            ></vue-apex-charts>
          </div>
        </vx-card>
      </div>
      <div class="vx-col w-full md:w-1/2 mb-base">
        <vx-card
          :title="
            $t(resources.VolumenPersonal.i18n) || resources.VolumenPersonal.name
          "
          class="mb-base"
        >
          <div class="mt-5">
            <vue-apex-charts
              type="bar"
              height="350"
              :options="columnChart2.chartOptions"
              :series="columnChart2.series"
            ></vue-apex-charts>
          </div>
        </vx-card>
      </div>
    </div>

    <!-- DISPATCHED ORDERS -->
    <div class="vx-row">
      <div class="vx-col w-full mb-base">
        <vx-card
          :title="
            $t(resources.Last5Periods.i18n) || resources.Last5Periods.name
          "
        >
          <div slot="no-body" class="mt-4">
            <vs-table :data="orders">
              <template slot="thead">
                <vs-th>
                  {{
                  $t(resources.Number.i18n) || resources.Number.name
                  }}
                </vs-th>
                <vs-th>
                  {{
                  $t(resources.PVAmount.i18n) || resources.PVAmount.name
                  }}
                </vs-th>
                <vs-th>
                  {{
                  $t(resources.Period.i18n) || resources.Period.name
                  }}
                </vs-th>
                <vs-th width="8%">
                  {{
                  $t(resources.DistCountryCode.i18n) ||
                  resources.DistCountryCode.name
                  }}
                </vs-th>
                <vs-th width="8%">
                  {{
                  $t(resources.ShipToCountry.i18n) ||
                  resources.ShipToCountry.name
                  }}
                </vs-th>
                <vs-th>
                  {{
                  $t(resources.ShipToName.i18n) || resources.ShipToName.name
                  }}
                </vs-th>
                <vs-th>
                  {{
                  $t(resources.Date.i18n) || resources.Date.name
                  }}
                </vs-th>
                <vs-th>
                  {{
                  $t(resources.Options.i18n) || resources.Options.name
                  }}
                </vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr
                  :key="indextr"
                  v-for="(tr, indextr) in data.ROWCOUNT"
                  v-on:click="getInvoice(data.DATA.ORDER_NUMBER[indextr])"
                >
                  <vs-td :data="data.DATA.ORDER_NUMBER[indextr]">
                    <span>#{{ data.DATA.ORDER_NUMBER[indextr] }}</span>
                  </vs-td>
                  <vs-td :data="data.DATA.PV_AMOUNT[indextr]">
                    <span>{{ data.DATA.PV_AMOUNT[indextr] }}</span>
                  </vs-td>
                  <vs-td :data="data.DATA.PV_YEAR[indextr]">
                    <span>
                      {{
                      data.DATA.PV_YEAR[indextr] +
                      "/" +
                      data.DATA.PV_PERIOD[indextr]
                      }}
                    </span>
                  </vs-td>
                  <vs-td :data="data.DATA.DST_COUNTRY_CODE[indextr]">
                    <span>{{ data.DATA.DST_COUNTRY_CODE[indextr] }}</span>
                  </vs-td>
                  <vs-td :data="data.DATA.SHIP_TO_COUNTRY[indextr]">
                    <span>{{ data.DATA.SHIP_TO_COUNTRY[indextr] }}</span>
                  </vs-td>
                  <vs-td :data="data.DATA.SHIP_TO_NAME[indextr]">
                    <span>{{ data.DATA.SHIP_TO_NAME[indextr] }}</span>
                  </vs-td>
                  <vs-td :data="data.DATA.ENTRY_DATE[indextr]">
                    <span>{{ data.DATA.ENTRY_DATE[indextr] }}</span>
                  </vs-td>
                  <vs-td>
                    <vx-tooltip :text="$t(resources.View.i18n) || resources.View.name">
                      <a @click="getInvoice(data.DATA.ORDER_NUMBER[indextr])">
                        <feather-icon icon="EyeIcon" class="w-5 h-5 mr-1"></feather-icon>
                      </a>
                    </vx-tooltip>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </vx-card>
      </div>
    </div>

    <!-- POPUP -->
    <div class="demo-alignment">
      <vs-popup
        fullscreen
        :title="$t(resources.Invoice.i18n) || resources.Invoice.name"
        :active.sync="popupActive"
      >
        <div id="invoice-page">
          <!-- INVOICE METADATA -->
          <div class="vx-row leading-loose p-base">
            <div class="vx-col w-full md:w-1/2 mt-base">
              <img src="@/assets/images/logo/logo_vt.png" alt="vuesax-logo" />
            </div>
            <div class="vx-col w-full md:w-1/2 text-right">
              <h1>{{ $t(resources.Invoice.i18n) || resources.Invoice.name }}</h1>
              <div class="invoice__invoice-detail mt-6">
                <h6>{{ $t(resources.OrderNumber.i18n) || resources.Invoice.name }}</h6>
                <p>{{ invoice.odh_number }}</p>

                <h6 class="mt-4">{{ $t(resources.OrderDate.i18n) || resources.OrderDate.name }}</h6>
                <p>{{ invoice.ord_ent_dt }}{{ invoice.ord_ent_tm }}</p>
              </div>
            </div>
            <div class="vx-col w-full md:w-1/2 mt-12">
              <h5>
                <strong>
                  {{
                  $t(resources.BillTo.i18n) || resources.BillTo.name
                  }}:
                </strong>
              </h5>
              <div class="invoice__recipient-info my-4">
                <p>{{ invoice.DST_Display_Name_1 }}</p>
                <p>{{ invoice.DST_Addr }}</p>
                <p>{{ invoice.addressLine2 }}</p>
                <p>{{ invoice.DST_Addr_3 }}</p>
              </div>
              <div class="invoice__recipient-contact">
                <p class="flex items-center">
                  <feather-icon icon="MailIcon" svgClasses="h-4 w-4"></feather-icon>
                  <span class="ml-2">{{ invoice.DST_email_addr }}</span>
                </p>
                <p class="flex items-center">
                  <feather-icon icon="PhoneIcon" svgClasses="h-4 w-4"></feather-icon>
                  <span class="ml-2">{{ invoice.DST_Home_Phone }}</span>
                </p>
              </div>
            </div>
            <div class="vx-col w-full md:w-1/2 mt-base text-right mt-12">
              <h5>
                <strong>
                  {{
                  $t(resources.ShipTo.i18n) || resources.ShipTo.name
                  }}:
                </strong>
              </h5>
              <h5>{{ invoice.ship_to_name }}</h5>
              <div class="invoice__company-info my-4">
                <p>{{ invoice.ship_to_addr_1 }}</p>
                <p>{{ invoice.ship_to_addr_3 }}</p>
                <p>
                  {{ invoice.ship_to_city
                  }}{{ " (Postal code:" + invoice.ship_to_zip + ")" }}
                </p>
                <p></p>
              </div>
              <div class="invoice__company-contact">
                <p class="flex items-center justify-end">
                  <feather-icon icon="MailIcon" svgClasses="h-4 w-4"></feather-icon>
                  <span class="ml-2">{{ invoice.ship_to_email }}</span>
                </p>
                <p class="flex items-center justify-end">
                  <feather-icon icon="PhoneIcon" svgClasses="h-4 w-4"></feather-icon>
                  <span class="ml-2">{{ invoice.ship_to_phone }}</span>
                </p>
              </div>
            </div>
          </div>

          <div v-if="!invoice">
            {{
            $t(resources.NoAvailableData.i18n) ||
            resources.NoAvailableData.name
            }}
          </div>
          <div v-else class="p-base">
            <vs-table hoverFlat :data="invoice">
              <template slot="thead">
                <vs-th>
                  {{
                  $t(resources.Item.i18n) || resources.Item.name
                  }}
                </vs-th>
                <vs-th>
                  {{
                  $t(resources.Code.i18n) || resources.Code.name
                  }}
                </vs-th>
                <vs-th>
                  {{
                  $t(resources.QtyShip.i18n) || resources.QtyShip.name
                  }}
                </vs-th>
                <vs-th>
                  {{
                  $t(resources.QtyOrder.i18n) || resources.QtyOrder.name
                  }}
                </vs-th>
                <vs-th>
                  {{
                  $t(resources.Description.i18n) || resources.Description.name
                  }}
                </vs-th>
                <vs-th>
                  {{
                  $t(resources.Volumen.i18n) || resources.Volumen.name
                  }}
                </vs-th>
                <vs-th>
                  {{
                  $t(resources.TotalVolumen.i18n) || resources.TotalVolumen.name
                  }}
                </vs-th>
                <vs-th>
                  {{
                  $t(resources.Price.i18n) || resources.Price.name
                  }}
                </vs-th>
                <vs-th>
                  {{
                  $t(resources.Total.i18n) || resources.Total.name
                  }}
                </vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data.odl">
                  <vs-td>{{ indextr + 1 }}</vs-td>
                  <vs-td :data="data.odl[indextr].item_code">
                    {{
                    data.odl[indextr].item_code
                    }}
                  </vs-td>
                  <vs-td :data="data.odl[indextr].qty_shipped">
                    {{
                    data.odl[indextr].qty_shipped
                    }}
                  </vs-td>
                  <vs-td :data="data.odl[indextr].qty_ordered">
                    {{
                    data.odl[indextr].qty_ordered
                    }}
                  </vs-td>
                  <vs-td :data="data.odl[indextr].item_name_1">
                    {{
                    data.odl[indextr].item_name_1
                    }}
                  </vs-td>
                  <vs-td :data="data.odl[indextr].price_2">
                    {{
                    data.odl[indextr].price_2
                    }}
                  </vs-td>
                  <vs-td :data="data.pv_amount">{{ data.pv_amount }}</vs-td>
                  <vs-td :data="data.odl[indextr].sales_price">
                    {{
                    data.odl[indextr].sales_price
                    }}
                  </vs-td>
                  <vs-td :data="data.sales_amount">
                    {{
                    data.sales_amount
                    }}
                  </vs-td>
                </vs-tr>
                <vs-tr>
                  <vs-td></vs-td>
                  <vs-td></vs-td>
                  <vs-td></vs-td>
                  <vs-td></vs-td>
                  <vs-td></vs-td>
                  <th>
                    {{
                    $t(resources.TotalVolumen.i18n) ||
                    resources.TotalVolumen.name
                    }}
                  </th>
                  <td>{{ invoice.pv_amount }}</td>
                  <th>{{ $t(resources.Subtotal.i18n) || resources.Subtotal.name }}</th>
                  <td>{{ invoice.sales_amount }}</td>
                </vs-tr>
              </template>
            </vs-table>

            <div class="vx-row">
              <div class="vx-col w-full lg:w-1/2 mb-base p-base">
                <h6>{{ $t(resources.Comments.i18n) || resources.Comments.name }}:</h6>
                <p>{{ invoice.comment_1 }}</p>
                <p>{{ invoice.comment_2 }}</p>
                <p>{{ invoice.comment_3 }}</p>
                <p>{{ invoice.comment_4 }}</p>
                <p>{{ invoice.comment_5 }}</p>
                <p>{{ invoice.comment_6 }}</p>
                <br />
                <h6>
                  <strong>Warehouse 2</strong>
                </h6>
                <p>
                  <strong>{{ invoice.ord_whs_nm1 }}</strong>
                </p>
                <p>{{ invoice.ord_whs_adr1 }}</p>
                <p>{{ invoice.ord_whs_adr2 }}</p>
                <p>{{ invoice.ord_whs_adr3 }}</p>
              </div>
              <div class="vx-col w-full lg:w-1/2 mb-base p-base">
                <table hoverFlat class="w-3/4 ml-auto mt-3">
                  <vs-tr>
                    <th>
                      {{ $t(resources.Tax.i18n) || resources.Tax.name }} ({{
                      $t(resources.Taxable.i18n) || resources.Taxable.name
                      }}
                      0.00 @ {{ invoice.tax_percent }}%)
                    </th>
                    <td>{{ invoice.total_taxable_amount }}</td>
                  </vs-tr>
                  <vs-tr>
                    <th>
                      {{
                      $t(resources.ShippingAndHandling.i18n) ||
                      resources.ShippingAndHandling.name
                      }}
                      ({{ invoice.ord_shv_nm }})
                    </th>
                    <td>{{ invoice.total_invoice_amt }}</td>
                  </vs-tr>
                  <vs-tr>
                    <th>
                      {{
                      $t(resources.TotalAmountDue.i18n) ||
                      resources.TotalAmountDue.name
                      }}
                    </th>
                    <td>{{ invoice.sales_amount }}</td>
                  </vs-tr>
                </table>
              </div>
            </div>
          </div>

          <!-- INVOICE FOOTER -->
          <div class="invoice__footer text-right p-base">
            <h5>
              <strong>
                {{
                $t(resources.PaymentsReceived.i18n) ||
                resources.PaymentsReceived.name
                }}
              </strong>
            </h5>
            <p>
              <strong>
                {{
                $t(resources.ReceiptAmount.i18n) || resources.ReceiptAmount.name
                }}
              </strong>
              {{ invoice.amount_received }}
            </p>
            <p>
              <strong>
                {{
                $t(resources.TotalCashReceipts.i18n) ||
                resources.TotalCashReceipts.name
                }}
              </strong>
              {{ invoice.sales_amount }}
            </p>
            <p>
              <strong>
                {{
                $t(resources.InvoceBalance.i18n) || resources.InvoceBalance.name
                }}
              </strong>
              {{ invoice.invoice_balance }}
            </p>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full text-right">
              <vs-button color="rgb(174,174,174)" type="filled" @click="popupActive = false">
                {{
                $t(resources.Close.i18n) || resources.Close.name
                }}
              </vs-button>
            </div>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import axios from "axios";
import jspdf from "jspdf";
import logo_base64 from "@/assets/images/logo/logo_base64.js";
import resources from "@/i18n/resources.js";
import Qualification from "../general/qualification.vue";
import Volumen from "../general/volumen.vue";
import ApexCharts from "apexcharts";

export default {
  data() {
    return {
      resources: resources,

      nroCenters: 0,
      newClient: "",
      last90days: "",
      lastYear: "",
      columnChart1: {
        series: [
          {
            name: this.$i18n.t("Left"),
            data: [0]
          },
          {
            name: this.$i18n.t("Right"),
            data: [0]
          }
        ],
        chartOptions: {
          chart: {
            id: "chart1"
          },
          colors: ["#28C76F", "#FF9F43"],
          plotOptions: {
            bar: {
              horizontal: false,
              endingShape: "rounded",
              columnWidth: "55%"
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"]
          },
          xaxis: {
            categories: [this.$i18n.t("LastWeek"), this.$i18n.t("Currently")]
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function(val) {
                return val;
              }
            }
          }
        }
      },
      columnChart2: {
        series: [
          {
            name: this.$i18n.t("Amount"),
            data: [0]
          }
        ],
        chartOptions: {
          chart: {
            id: "chart2"
          },
          colors: ["#FFBD33", "#D43743"],
          plotOptions: {
            bar: {
              horizontal: false,
              endingShape: "flat",
              columnWidth: "50%"
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"]
          },
          xaxis: {
            categories: [this.$i18n.t("LastWeek"), this.$i18n.t("Currently")]
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function(val) {
                return val;
              }
            }
          }
        }
      },
      name: JSON.parse(localStorage.getItem("userInfo")).name,
      itaCode: JSON.parse(localStorage.getItem("userInfo")).itaCode.toString(),
      periods: JSON.parse(localStorage.getItem("periods")), //JSON.parse('{"LABEL": ["",""],"PERIOD":[""]}'),
      orders: "",
      popupActive: false,
      invoice: "",
      isImp: false,
      show: false,
      items: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      nextNum: 5,
      imgLogo: logo_base64.image,
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot"
      ]
    };
  },
  created: function() {
    this.getToken();
  },
  computed: {
    //Current period label
    currentPeriodLabel() {
      return this.periods.LABEL[0];
    },
    currentPeriod() {
      return this.periods.PERIOD[0];
    }
  },
  methods: {
    download(orderId) {
      var doc = new jspdf();

      var img = this.imgLogo;
      //this.getInvoice(orderId);
      let pdfName = "#" + orderId + ".pdf";

      doc.addImage(img, "png", 7, 10, 110, 30, undefined, "none");

      doc.text("Hello, my name is ana", 20, 50);
      doc.save(pdfName);
    },

    //Get invoice of the order
    async getInvoice(orderId) {
      await axios({
        method: "POST",
        url: process.env.VUE_APP_OVAPI + "getInvoice",
        data: {
          infotoken: localStorage.getItem("tokenInfo"),
          distid: this.itaCode,
          odhnumber: orderId
        },
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("loggedIn")
        }
      }).then(
        result => {
          this.invoice = result.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.popupActive = true;
    },

    //Get Token connecting with Infotrax services.
    async getToken() {
      this.listOrders();
      this.getSummary();

      // await axios({
      //   method: "GET",
      //   url: process.env.VUE_APP_OVAPI + "getPeriods",
      //   headers: {
      //     "content-type": "application/json",
      //     Authorization: "bearer " + localStorage.getItem("loggedIn")
      //   }
      // }).then(
      //   result => {
      //     localStorage.setItem("tokenInfo", result.data.infotoken);
      //     localStorage.setItem("periods", JSON.stringify(result.data.DATA));
      //     this.periods = result.data.DATA;
      //   },
      //   error => {
      //     this.$vs.notify({
      //       title: this.$i18n.t("Error"),
      //       text: this.$i18n.t("MsgError") + "<br/>" + error.message,
      //       color: "danger",
      //       iconPack: "feather",
      //       position: "top-right",
      //       icon: "icon-x-circle"
      //     });
      //   }
      // );
    },

    //get Points by ITA.
    async getSummary() {
      let jsonLast;
      let jsonCurrent;

      for (var i = 0; i < 2; i++) {
        await axios({
          method: "POST",
          url: process.env.VUE_APP_OVAPI + "getSummary",
          data: {
            infotoken: localStorage.getItem("tokenInfo"),
            distid: this.itaCode,
            period: this.periods.PERIOD[i]
          },
          headers: {
            "content-type": "application/json",
            Authorization: "bearer " + localStorage.getItem("loggedIn")
          }
        }).then(
          result => {
            if (i == 0) jsonCurrent = result.data.FIELDS;
            else jsonLast = result.data.FIELDS;
          },
          error => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError") + "<br/>" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        );
      }
      this.readPV(jsonLast, jsonCurrent);
    },

    //Get list orders by ITA.
    async listOrders() {
      var firstPeriod = this.periods.PERIOD[this.periods.PERIOD.length - 1];
      var lastPeriod = this.periods.PERIOD[0];

      await axios({
        method: "POST",
        url: process.env.VUE_APP_OVAPI + "ListOrders",
        data: {
          infotoken: localStorage.getItem("tokenInfo"),
          distid: this.itaCode,
          start_pvdate: firstPeriod,
          end_pvdate: lastPeriod,
          fields:
            "ORDER_NUMBER,PV_AMOUNT,PV_YEAR,PV_PERIOD,DST_COUNTRY_CODE,SHIP_TO_COUNTRY,SHIP_TO_NAME,ENTRY_DATE"
        },
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("loggedIn")
        }
      }).then(
        result => {
          this.orders = result.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    //Read Points Value and set in the chart.
    readPV(jsonLast, jsonCurrent) {
      //New clients the last 90 days
      this.newClient = jsonCurrent.find(
        jsonCurrent => jsonCurrent.BQF_Field_Name === "WRK42"
      ).BQF_Field_Value;

      //Sales the last 90 days
      this.last90days = jsonCurrent.find(
        jsonCurrent => jsonCurrent.BQF_Field_Name === "WRK43"
      ).BQF_Field_Value;

      //Sales the last 365 days
      this.lastYear = jsonCurrent.find(
        jsonCurrent => jsonCurrent.BQF_Field_Name === "WRK44"
      ).BQF_Field_Value;

      //Number business centers
      this.nroCenters = jsonCurrent.find(
        jsonCurrent => jsonCurrent.BQF_Field_Name === "WRK10"
      ).BQF_Field_Value;
      localStorage.setItem("numberCenters", this.nroCenters);

      let volLeft = [];
      let volRight = [];
      let volPersonal = [];
      volLeft.push(
        jsonLast.find(x => x.BQF_Field_Name === "VOL4").BQF_Field_Value
      );
      volLeft.push(
        jsonCurrent.find(x => x.BQF_Field_Name === "VOL4").BQF_Field_Value
      );

      volRight.push(
        jsonLast.find(x => x.BQF_Field_Name === "VOL5").BQF_Field_Value
      );
      volRight.push(
        jsonCurrent.find(x => x.BQF_Field_Name === "VOL5").BQF_Field_Value
      );

      volPersonal.push(
        jsonLast.find(x => x.BQF_Field_Name === "VOL1").BQF_Field_Value
      );
      volPersonal.push(
        jsonCurrent.find(x => x.BQF_Field_Name === "VOL1").BQF_Field_Value
      );

      ApexCharts.exec("chart1", "updateSeries", [
        {
          name: this.$i18n.t("Left"),
          data: volLeft
        },
        {
          name: this.$i18n.t("Right"),
          data: volRight
        }
      ]);

      ApexCharts.exec("chart2", "updateSeries", [
        {
          data: volPersonal
        }
      ]);

      //New clients the last 90 days
      this.newClient = jsonCurrent.find(
        jsonCurrent => jsonCurrent.BQF_Field_Name === "WRK42"
      ).BQF_Field_Value;

      //Sales the last 90 days
      this.last90days = jsonCurrent.find(
        jsonCurrent => jsonCurrent.BQF_Field_Name === "WRK43"
      ).BQF_Field_Value;

      //Sales the last 365 days
      this.lastYear = jsonCurrent.find(
        jsonCurrent => jsonCurrent.BQF_Field_Name === "WRK44"
      ).BQF_Field_Value;
    }
  },
  components: { VueApexCharts, Qualification, Volumen }
};
</script>

<style lang="scss">
#dashboard-analytics {
  .greet-user {
    position: relative;
    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: 576px) {
    .decore-left,
    .decore-right {
      width: 140px;
    }
  }
}
</style>
