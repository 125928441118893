var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c("vx-card", { staticClass: "mb-4" }, [
          _c("h4", { staticClass: "mb-4" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(_vm.resources.Filters.i18n) ||
                    _vm.resources.Filters.name
                ) +
                " "
            )
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/5 mb-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(_vm.resources.Date.i18n) ||
                          _vm.resources.Date.name
                      ) +
                      " "
                  )
                ]),
                _c("datepicker", {
                  attrs: {
                    language: _vm.languages[_vm.language],
                    format: "d MMMM yyyy"
                  },
                  model: {
                    value: _vm.date,
                    callback: function($$v) {
                      _vm.date = $$v
                    },
                    expression: "date"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col mb-2 mt-5" },
              [
                _c(
                  "vs-button",
                  {
                    attrs: { color: "primary", type: "filled" },
                    on: {
                      click: function($event) {
                        return _vm.getSummaryBooking()
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Search.i18n) ||
                          _vm.resources.Search.name
                      )
                    )
                  ]
                )
              ],
              1
            )
          ])
        ]),
        _c(
          "vx-card",
          { staticClass: "mb-4" },
          [
            _vm.summaryPayment.length > 0
              ? _c("h2", { staticClass: "text-center text-primary" }, [
                  _c("strong", [_vm._v("Resumen total pagos")])
                ])
              : _vm._e(),
            _c(
              "vs-row",
              {
                staticClass: "mb-4",
                attrs: { "vs-type": "flex", "vs-justify": "center" }
              },
              _vm._l(_vm.summaryPayment, function(item, indextr) {
                return _c(
                  "vs-col",
                  {
                    key: indextr,
                    staticClass: "mr-2 mb-2",
                    attrs: {
                      "vs-type": "flex",
                      "vs-justify": "center",
                      "vs-align": "center",
                      "vs-w": "3",
                      "vs-sm": "3",
                      "vs-xs": "12"
                    }
                  },
                  [
                    _c(
                      "vx-card",
                      {
                        attrs: {
                          "card-background": "success",
                          "content-color": "#fff"
                        }
                      },
                      [
                        _c("h5", { staticClass: "text-center text-white" }, [
                          _vm._v(" " + _vm._s(item.currencyCode) + " ")
                        ]),
                        _c("h1", { staticClass: "text-center text-white" }, [
                          _c("strong", [_vm._v("$" + _vm._s(item.total))])
                        ])
                      ]
                    )
                  ],
                  1
                )
              }),
              1
            ),
            _vm.summaryBooking.length > 0
              ? _c("h2", { staticClass: "text-center text-primary" }, [
                  _c("strong", [_vm._v("Resumen total reservas")])
                ])
              : _vm._e(),
            _c(
              "vs-row",
              {
                staticClass: "mb-4",
                attrs: { "vs-type": "flex", "vs-justify": "center" }
              },
              _vm._l(_vm.summaryBooking, function(item, indextr) {
                return _c(
                  "vs-col",
                  {
                    key: indextr,
                    staticClass: "mr-2 mb-2",
                    attrs: {
                      "vs-type": "flex",
                      "vs-justify": "center",
                      "vs-align": "center",
                      "vs-w": "3",
                      "vs-sm": "3",
                      "vs-xs": "12"
                    }
                  },
                  [
                    _c(
                      "vx-card",
                      {
                        attrs: {
                          "card-background": "success",
                          "content-color": "#fff"
                        }
                      },
                      [
                        _c("h5", { staticClass: "text-center text-white" }, [
                          _vm._v(" " + _vm._s(item.currencyCode) + " ")
                        ]),
                        _c("h1", { staticClass: "text-center text-white" }, [
                          _c("strong", [_vm._v("$" + _vm._s(item.total))])
                        ])
                      ]
                    )
                  ],
                  1
                )
              }),
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { attrs: { id: "ag-grid-demo" } },
          [
            _c(
              "vx-card",
              [
                _c(
                  "div",
                  {
                    staticClass: "flex flex-wrap justify-between items-center"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "mb-4 md:mb-0 mr-4 ag-grid-table-actions-left"
                      },
                      [
                        _c(
                          "vs-dropdown",
                          {
                            staticClass: "cursor-pointer",
                            attrs: { "vs-trigger-click": "" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                              },
                              [
                                _c("span", { staticClass: "mr-2" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.currentPage * _vm.paginationPageSize -
                                        (_vm.paginationPageSize - 1)
                                    ) +
                                      " - " +
                                      _vm._s(
                                        _vm.dataList.length -
                                          _vm.currentPage *
                                            _vm.paginationPageSize >
                                          0
                                          ? _vm.currentPage *
                                              _vm.paginationPageSize
                                          : _vm.dataList.length
                                      ) +
                                      " of " +
                                      _vm._s(_vm.dataList.length)
                                  )
                                ]),
                                _c("feather-icon", {
                                  attrs: {
                                    icon: "ChevronDownIcon",
                                    svgClasses: "h-4 w-4"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "vs-dropdown-menu",
                              [
                                _c(
                                  "vs-dropdown-item",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.gridApi.paginationSetPageSize(
                                          20
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("20")])]
                                ),
                                _c(
                                  "vs-dropdown-item",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.gridApi.paginationSetPageSize(
                                          50
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("50")])]
                                ),
                                _c(
                                  "vs-dropdown-item",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.gridApi.paginationSetPageSize(
                                          100
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("100")])]
                                ),
                                _c(
                                  "vs-dropdown-item",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.gridApi.paginationSetPageSize(
                                          150
                                        )
                                      }
                                    }
                                  },
                                  [_c("span", [_vm._v("150")])]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-wrap items-center justify-between ag-grid-table-actions-right"
                      },
                      [
                        _c("vs-input", {
                          staticClass: "mb-4 md:mb-0 mr-4",
                          attrs: {
                            placeholder:
                              _vm.$t(_vm.resources.Search.i18n) ||
                              _vm.resources.Search.name
                          },
                          on: { input: _vm.updateSearchQuery },
                          model: {
                            value: _vm.searchQuery,
                            callback: function($$v) {
                              _vm.searchQuery = $$v
                            },
                            expression: "searchQuery"
                          }
                        }),
                        _c(
                          "vs-button",
                          {
                            staticClass: "mb-4 md:mb-0",
                            on: {
                              click: function($event) {
                                return _vm.gridApi.exportDataAsCsv()
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(_vm.resources.ExportAs.i18n) ||
                                    _vm.resources.ExportAs.name
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c("ag-grid-vue", {
                  staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
                  attrs: {
                    gridOptions: _vm.gridOptions,
                    columnDefs: _vm.columnDefs,
                    defaultColDef: _vm.defaultColDef,
                    rowData: _vm.dataList,
                    rowSelection: "single",
                    colResizeDefault: "shift",
                    animateRows: true,
                    floatingFilter: true,
                    pagination: true,
                    paginationPageSize: _vm.paginationPageSize,
                    suppressPaginationPanel: true
                  },
                  on: { "row-selected": _vm.onRowSelected }
                }),
                _c("vs-pagination", {
                  attrs: { total: _vm.totalPages, max: _vm.maxPageNumbers },
                  model: {
                    value: _vm.currentPage,
                    callback: function($$v) {
                      _vm.currentPage = $$v
                    },
                    expression: "currentPage"
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }