<template>
  <div>
    <div id="ag-grid-demo">
      <vx-card
        class="mb-2"
        :title="$t(resources.Products.i18n) || resources.Products.name"
      >
        <div class="vx-col w-full">
          <vs-table max-items="10" pagination :data="products">
            <template slot="thead">
              <vs-th>{{
                $t(resources.Code.i18n) || resources.Code.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.Name.i18n) || resources.Name.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.Active.i18n) || resources.Active.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.Options.i18n) || resources.Options.name
              }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].code">{{
                  data[indextr].code
                }}</vs-td>
                <vs-td :data="data[indextr].name">{{
                  data[indextr].name
                }}</vs-td>
                <vs-td v-if="data[indextr].active" :data="data[indextr].active">
                  <feather-icon
                    icon="CheckIcon"
                    svgClasses="fill-current text-success"
                    class="w-5 h-5 mr-1"
                  ></feather-icon>
                </vs-td>
                <vs-td v-else :data="data[indextr].active">
                  <feather-icon
                    icon="XIcon"
                    svgClasses="fill-current text-danger"
                    class="w-5 h-5 mr-1"
                  ></feather-icon>
                </vs-td>
                <vs-td>
                  <vx-tooltip
                    :text="$t(resources.View.i18n) || resources.View.name"
                  >
                    <a
                      @click="
                        $router.push(
                          `/settings/productDetail/${data[indextr].id}`
                        )
                      "
                    >
                      <feather-icon
                        icon="EyeIcon"
                        class="w-5 h-5 mr-1"
                      ></feather-icon>
                    </a>
                  </vx-tooltip>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </vx-card>
    </div>
  </div>
</template>
<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import axios from "axios";

export default {
  data() {
    return {
      resources: resources,
      alerts: alerts,
      language: this.$i18n.locale,
      products: [],
      param: {},
    };
  },
  async created() {
    await this.get();
  },
  methods: {
    async get() {
      this.$vs.loading();
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Product/ByType/${this.$i18n.locale.toUpperCase()}/2`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.products = [];
          } else {
            this.products = result.data;
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },
  },
};
</script>
