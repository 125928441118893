var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.canSearch
      ? _c(
          "div",
          [
            _c(
              "vx-card",
              { staticClass: "mb-4" },
              [
                _vm.canUpdate
                  ? _c(
                      "vs-row",
                      { attrs: { "vs-type": "flex", "vs-justify": "center" } },
                      [
                        _c(
                          "vs-col",
                          {
                            attrs: {
                              "vs-type": "flex",
                              "vs-justify": "center",
                              "vs-align": "center",
                              "vs-w": "3"
                            }
                          },
                          [
                            _c("vs-button", {
                              attrs: {
                                radius: "",
                                color: "success",
                                "icon-pack": "feather",
                                icon: "icon-plus",
                                size: "large"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.cleanFields(true)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c("h4", { staticClass: "mb-4" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(_vm.resources.Filters.i18n) ||
                          _vm.resources.Filters.name
                      ) +
                      " "
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "vx-row" },
                  [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full md:w-1/5 mb-2" },
                      [
                        _c("label", { staticClass: "vs-input--label" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(_vm.resources.StartDate.i18n) ||
                                  _vm.resources.StartDate.name
                              ) +
                              " "
                          )
                        ]),
                        _c("datepicker", {
                          attrs: {
                            language: _vm.languages[_vm.language],
                            format: "d MMMM yyyy"
                          },
                          model: {
                            value: _vm.start_date,
                            callback: function($$v) {
                              _vm.start_date = $$v
                            },
                            expression: "start_date"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-col w-full md:w-1/5 mb-2" },
                      [
                        _c("label", { staticClass: "vs-input--label" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.EndDate.i18n) ||
                                _vm.resources.EndDate.name
                            )
                          )
                        ]),
                        _c("datepicker", {
                          attrs: {
                            language: _vm.languages[_vm.language],
                            format: "d MMMM yyyy"
                          },
                          model: {
                            value: _vm.end_date,
                            callback: function($$v) {
                              _vm.end_date = $$v
                            },
                            expression: "end_date"
                          }
                        })
                      ],
                      1
                    ),
                    _c("vs-button", {
                      staticClass: "mt-5",
                      attrs: {
                        color: "primary",
                        type: "filled",
                        "icon-pack": "feather",
                        icon: "icon-refresh-ccw"
                      },
                      on: {
                        click: function($event) {
                          return _vm.cleanDate()
                        }
                      }
                    }),
                    _c(
                      "div",
                      { staticClass: "vx-col md:w-1/6 w-full mb-2" },
                      [
                        _c("label", { staticClass: "vs-input--label" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.Currency.i18n) ||
                                _vm.resources.Currency.name
                            )
                          )
                        ]),
                        _c("v-select", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          attrs: {
                            placeholder:
                              _vm.$t(_vm.resources.Select.i18n) ||
                              _vm.resources.Select.name,
                            clearable: false,
                            options: _vm.currencyList,
                            reduce: function(item) {
                              return item.id
                            },
                            label: "text"
                          },
                          model: {
                            value: _vm.currencyId,
                            callback: function($$v) {
                              _vm.currencyId = $$v
                            },
                            expression: "currencyId"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-col w-full md:w-1/6 mb-2" },
                      [
                        _c("label", { staticClass: "vs-input--label" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.Stage.i18n) ||
                                _vm.resources.Stage.name
                            )
                          )
                        ]),
                        _c("v-select", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          attrs: {
                            clearable: false,
                            options: _vm.stageList,
                            reduce: function(x) {
                              return x.id
                            },
                            label: "name",
                            scrollable: "",
                            name: "stage"
                          },
                          model: {
                            value: _vm.stageId,
                            callback: function($$v) {
                              _vm.stageId = $$v
                            },
                            expression: "stageId"
                          }
                        }),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("stage"),
                                expression: "errors.has('stage')"
                              }
                            ],
                            staticClass: "text-danger text-sm"
                          },
                          [_vm._v(_vm._s(_vm.errors.first("stage")))]
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "vx-col w-full md:w-1/6 mb-2" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col w-full" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.AccountType.i18n) ||
                                  _vm.resources.AccountType.name
                              )
                            )
                          ]),
                          _c("v-select", {
                            attrs: {
                              clearable: true,
                              options: _vm.accountTypeOption,
                              reduce: function(item) {
                                return item.id
                              },
                              label: "name"
                            },
                            model: {
                              value: _vm.accountType,
                              callback: function($$v) {
                                _vm.accountType = $$v
                              },
                              expression: "accountType"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "vx-col md:w-1/6 w-full mb-2" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col w-full" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.AccountNumber.i18n) ||
                                  _vm.resources.AccountNumber.name
                              )
                            )
                          ]),
                          _c("v-select", {
                            attrs: {
                              id: "accountId",
                              clearable: true,
                              options: _vm.accountList,
                              reduce: function(item) {
                                return item.id
                              },
                              label: "nameWithNumber",
                              name: "destination"
                            },
                            nativeOn: {
                              keyup: function($event) {
                                return _vm.getByText("accountId")
                              }
                            },
                            model: {
                              value: _vm.accountId,
                              callback: function($$v) {
                                _vm.accountId = $$v
                              },
                              expression: "accountId"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "vx-col w-full md:w-1/6 mb-2" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col w-full" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Type.i18n) ||
                                  _vm.resources.Type.name
                              )
                            )
                          ]),
                          _c("v-select", {
                            attrs: {
                              clearable: true,
                              options: _vm.typeOption,
                              reduce: function(item) {
                                return item.id
                              },
                              label: "name"
                            },
                            model: {
                              value: _vm.type,
                              callback: function($$v) {
                                _vm.type = $$v
                              },
                              expression: "type"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "vx-col w-full md:w-1/6 mb-2" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            "icon-pack": "feather",
                            icon: "icon-hash",
                            "icon-no-border": "",
                            label:
                              _vm.$t(_vm.resources.TransactionNumber.i18n) ||
                              _vm.resources.TransactionNumber.name
                          },
                          model: {
                            value: _vm.transactionNumber,
                            callback: function($$v) {
                              _vm.transactionNumber = $$v
                            },
                            expression: "transactionNumber"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-col w-full md:w-1/6 mb-2" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            type: "number",
                            "icon-pack": "feather",
                            icon: "icon-user",
                            "icon-no-border": "",
                            label:
                              _vm.$t(_vm.resources.DistributorID.i18n) ||
                              _vm.resources.DistributorID.name
                          },
                          model: {
                            value: _vm.itaCodeForm,
                            callback: function($$v) {
                              _vm.itaCodeForm = $$v
                            },
                            expression: "itaCodeForm"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-col w-full md:w-1/6 mb-2" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            "icon-pack": "feather",
                            icon: "icon-hash",
                            "icon-no-border": "",
                            label:
                              _vm.$t(_vm.resources.ReferenceCode.i18n) ||
                              _vm.resources.ReferenceCode.name
                          },
                          model: {
                            value: _vm.referenceCode,
                            callback: function($$v) {
                              _vm.referenceCode = $$v
                            },
                            expression: "referenceCode"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-col mb-2 mt-5" },
                      [
                        _c(
                          "vs-button",
                          {
                            attrs: { color: "primary", type: "filled" },
                            on: {
                              click: function($event) {
                                return _vm.get()
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Search.i18n) ||
                                  _vm.resources.Search.name
                              )
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "vx-card",
              { staticClass: "mb-4" },
              [
                _c(
                  "vs-collapse",
                  [
                    _c(
                      "vs-collapse-item",
                      [
                        _c(
                          "div",
                          { attrs: { slot: "header" }, slot: "header" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(_vm.resources.Summary.i18n) ||
                                    _vm.resources.Summary.i18n
                                ) +
                                " "
                            )
                          ]
                        ),
                        _vm.summaryByAccount.length > 0
                          ? _c(
                              "h2",
                              { staticClass: "text-center text-primary" },
                              [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(_vm.resources.AccountType.i18n) ||
                                        _vm.resources.AccountType.name
                                    )
                                  )
                                ])
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "vs-row",
                          {
                            staticClass: "mb-4",
                            attrs: { "vs-type": "flex", "vs-justify": "center" }
                          },
                          _vm._l(_vm.summaryByAccount, function(
                            account,
                            indextr
                          ) {
                            return _c(
                              "vs-col",
                              {
                                key: indextr,
                                staticClass: "mr-2",
                                attrs: {
                                  "vs-type": "flex",
                                  "vs-justify": "center",
                                  "vs-align": "center",
                                  "vs-w": "2",
                                  "vs-sm": "3",
                                  "vs-xs": "12"
                                }
                              },
                              [
                                _c(
                                  "vx-card",
                                  {
                                    attrs: {
                                      "card-background": "success",
                                      "content-color": "#fff"
                                    }
                                  },
                                  [
                                    _c(
                                      "h5",
                                      { staticClass: "text-center text-white" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(account.accountType) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "h1",
                                      { staticClass: "text-center text-white" },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            "$" +
                                              _vm._s(account.value.toFixed(2))
                                          )
                                        ])
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          }),
                          1
                        ),
                        _vm.summaryByActivity.length > 0
                          ? _c(
                              "h2",
                              { staticClass: "text-center text-primary" },
                              [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(_vm.resources.Type.i18n) ||
                                        _vm.resources.Type.name
                                    )
                                  )
                                ])
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "vs-row",
                          {
                            staticClass: "mb-4",
                            attrs: { "vs-type": "flex", "vs-justify": "center" }
                          },
                          _vm._l(_vm.summaryByActivity, function(
                            account,
                            indextr
                          ) {
                            return _c(
                              "vs-col",
                              {
                                key: indextr,
                                staticClass: "mr-2 mb-2",
                                attrs: {
                                  "vs-type": "flex",
                                  "vs-justify": "center",
                                  "vs-align": "center",
                                  "vs-w": "2",
                                  "vs-sm": "3",
                                  "vs-xs": "12"
                                }
                              },
                              [
                                _c(
                                  "vx-card",
                                  {
                                    attrs: {
                                      "card-background": "success",
                                      "content-color": "#fff"
                                    }
                                  },
                                  [
                                    _c(
                                      "h5",
                                      { staticClass: "text-center text-white" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(account.accountType) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "h1",
                                      { staticClass: "text-center text-white" },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            "$" +
                                              _vm._s(account.value.toFixed(2))
                                          )
                                        ])
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { attrs: { id: "ag-grid-demo" } },
              [
                _c(
                  "vx-card",
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-wrap justify-between items-center"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "mb-4 md:mb-0 mr-4 ag-grid-table-actions-left"
                          },
                          [
                            _c(
                              "vs-dropdown",
                              {
                                staticClass: "cursor-pointer",
                                attrs: { "vs-trigger-click": "" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                                  },
                                  [
                                    _c("span", { staticClass: "mr-2" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.currentPage *
                                            _vm.paginationPageSize -
                                            (_vm.paginationPageSize - 1)
                                        ) +
                                          " - " +
                                          _vm._s(
                                            _vm.dataList.length -
                                              _vm.currentPage *
                                                _vm.paginationPageSize >
                                              0
                                              ? _vm.currentPage *
                                                  _vm.paginationPageSize
                                              : _vm.dataList.length
                                          ) +
                                          " of " +
                                          _vm._s(_vm.dataList.length)
                                      )
                                    ]),
                                    _c("feather-icon", {
                                      attrs: {
                                        icon: "ChevronDownIcon",
                                        svgClasses: "h-4 w-4"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "vs-dropdown-menu",
                                  [
                                    _c(
                                      "vs-dropdown-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.gridApi.paginationSetPageSize(
                                              20
                                            )
                                          }
                                        }
                                      },
                                      [_c("span", [_vm._v("20")])]
                                    ),
                                    _c(
                                      "vs-dropdown-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.gridApi.paginationSetPageSize(
                                              50
                                            )
                                          }
                                        }
                                      },
                                      [_c("span", [_vm._v("50")])]
                                    ),
                                    _c(
                                      "vs-dropdown-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.gridApi.paginationSetPageSize(
                                              100
                                            )
                                          }
                                        }
                                      },
                                      [_c("span", [_vm._v("100")])]
                                    ),
                                    _c(
                                      "vs-dropdown-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.gridApi.paginationSetPageSize(
                                              150
                                            )
                                          }
                                        }
                                      },
                                      [_c("span", [_vm._v("150")])]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex flex-wrap items-center justify-between ag-grid-table-actions-right"
                          },
                          [
                            _c("vs-input", {
                              staticClass: "mb-4 md:mb-0 mr-4",
                              attrs: {
                                placeholder:
                                  _vm.$t(_vm.resources.Search.i18n) ||
                                  _vm.resources.Search.name
                              },
                              on: { input: _vm.updateSearchQuery },
                              model: {
                                value: _vm.searchQuery,
                                callback: function($$v) {
                                  _vm.searchQuery = $$v
                                },
                                expression: "searchQuery"
                              }
                            }),
                            _c(
                              "vs-button",
                              {
                                staticClass: "mb-4 md:mb-0",
                                on: {
                                  click: function($event) {
                                    return _vm.gridApi.exportDataAsCsv()
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(_vm.resources.ExportAs.i18n) ||
                                        _vm.resources.ExportAs.name
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    ),
                    _c("ag-grid-vue", {
                      staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
                      attrs: {
                        gridOptions: _vm.gridOptions,
                        columnDefs: _vm.columnDefs,
                        defaultColDef: _vm.defaultColDef,
                        rowData: _vm.dataList,
                        rowSelection: "single",
                        colResizeDefault: "shift",
                        animateRows: true,
                        floatingFilter: true,
                        pagination: true,
                        paginationPageSize: _vm.paginationPageSize,
                        suppressPaginationPanel: true
                      },
                      on: { "row-selected": _vm.onRowSelected }
                    }),
                    _c("vs-pagination", {
                      attrs: { total: _vm.totalPages, max: _vm.maxPageNumbers },
                      model: {
                        value: _vm.currentPage,
                        callback: function($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "vs-popup",
              {
                attrs: {
                  title:
                    _vm.$t(_vm.resources.Description.i18n) ||
                    _vm.resources.Description.i18n,
                  active: _vm.popupDetail
                },
                on: {
                  "update:active": function($event) {
                    _vm.popupDetail = $event
                  }
                }
              },
              [
                _c(
                  "h1",
                  { staticClass: "text-center text-primary font-bold mb-base" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(_vm.resources.Answer.i18n) ||
                            _vm.resources.Answer.i18n
                        ) +
                        ": "
                    )
                  ]
                ),
                _c("p", { staticClass: "text-center mb-base" }, [
                  _vm._v(_vm._s(_vm.description))
                ])
              ]
            ),
            _c(
              "vs-popup",
              {
                attrs: {
                  title:
                    _vm.$t(_vm.resources.Create.i18n) ||
                    _vm.resources.Create.i18n,
                  active: _vm.popupCreate
                },
                on: {
                  "update:active": function($event) {
                    _vm.popupCreate = $event
                  }
                }
              },
              [
                _c("div", { staticClass: "vx-row" }, [
                  _c("div", { staticClass: "vx-col w-full md:w-1/2 mb-2" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c("label", { staticClass: "vs-input--label" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.Type.i18n) ||
                                _vm.resources.Type.name
                            )
                          )
                        ]),
                        _c("v-select", {
                          attrs: {
                            clearable: true,
                            options: _vm.typeOption,
                            reduce: function(item) {
                              return item.id
                            },
                            label: "name"
                          },
                          model: {
                            value: _vm.typeId,
                            callback: function($$v) {
                              _vm.typeId = $$v
                            },
                            expression: "typeId"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "vx-col md:w-1/2 w-full mb-2" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c("label", { staticClass: "vs-input--label" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.AccountNumber.i18n) ||
                                _vm.resources.AccountNumber.name
                            )
                          )
                        ]),
                        _c("v-select", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          attrs: {
                            id: "account",
                            clearable: true,
                            options: _vm.accountList,
                            reduce: function(item) {
                              return item.id
                            },
                            label: "nameWithNumber",
                            name: "account"
                          },
                          on: {
                            input: function($event) {
                              return _vm.setAccountInfo("" + _vm.account)
                            }
                          },
                          nativeOn: {
                            keyup: function($event) {
                              return _vm.getByText("account")
                            }
                          },
                          model: {
                            value: _vm.account,
                            callback: function($$v) {
                              _vm.account = $$v
                            },
                            expression: "account"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("account"),
                            expression: "errors.has('account')"
                          }
                        ],
                        staticClass: "text-danger text-sm"
                      },
                      [_vm._v(_vm._s(_vm.errors.first("account")))]
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full md:w-1/2 mb-2" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          readonly: "",
                          "icon-pack": "feather",
                          icon: "icon-user",
                          "icon-no-border": "",
                          label:
                            _vm.$t(_vm.resources.Name.i18n) ||
                            _vm.resources.Name.name,
                          name: "contactName"
                        },
                        model: {
                          value: _vm.contactName,
                          callback: function($$v) {
                            _vm.contactName = $$v
                          },
                          expression: "contactName"
                        }
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("contactName"),
                              expression: "errors.has('contactName')"
                            }
                          ],
                          staticClass: "text-danger text-sm"
                        },
                        [_vm._v(_vm._s(_vm.errors.first("contactName")))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full md:w-1/2 mb-2" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          readonly: "",
                          "icon-pack": "feather",
                          icon: "icon-mail",
                          "icon-no-border": "",
                          label:
                            _vm.$t(_vm.resources.Email.i18n) ||
                            _vm.resources.Email.name,
                          name: "contactEmail"
                        },
                        model: {
                          value: _vm.contactEmail,
                          callback: function($$v) {
                            _vm.contactEmail = $$v
                          },
                          expression: "contactEmail"
                        }
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("contactEmail"),
                              expression: "errors.has('contactEmail')"
                            }
                          ],
                          staticClass: "text-danger text-sm"
                        },
                        [_vm._v(_vm._s(_vm.errors.first("contactEmail")))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full md:w-1/2 mb-2" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-hash",
                          "icon-no-border": "",
                          label:
                            _vm.$t(_vm.resources.ReferenceCode.i18n) ||
                            _vm.resources.ReferenceCode.name,
                          name: "referenceCode"
                        },
                        model: {
                          value: _vm.referenceId,
                          callback: function($$v) {
                            _vm.referenceId = $$v
                          },
                          expression: "referenceId"
                        }
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("referenceCode"),
                              expression: "errors.has('referenceCode')"
                            }
                          ],
                          staticClass: "text-danger text-sm"
                        },
                        [_vm._v(_vm._s(_vm.errors.first("referenceCode")))]
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "vx-col md:w-1/2 w-full mb-2" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|decimal:2",
                              expression: "'required|decimal:2'"
                            }
                          ],
                          staticClass: "w-full",
                          attrs: {
                            "icon-pack": "feather",
                            icon: "icon-dollar-sign",
                            type: "number",
                            label:
                              _vm.$t(_vm.resources.Value.i18n) ||
                              _vm.resources.Value.name,
                            name: "value"
                          },
                          model: {
                            value: _vm.value,
                            callback: function($$v) {
                              _vm.value = $$v
                            },
                            expression: "value"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("value"),
                            expression: "errors.has('value')"
                          }
                        ],
                        staticClass: "text-danger text-sm"
                      },
                      [_vm._v(_vm._s(_vm.errors.first("value")))]
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full md:w-1/2 mb-2" },
                    [
                      _c("label", { staticClass: "vs-input--label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.Stage.i18n) ||
                              _vm.resources.Stage.name
                          )
                        )
                      ]),
                      _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        attrs: {
                          clearable: false,
                          options: _vm.stageList,
                          reduce: function(x) {
                            return x.id
                          },
                          label: "name",
                          scrollable: "",
                          name: "stage"
                        },
                        model: {
                          value: _vm.stageId,
                          callback: function($$v) {
                            _vm.stageId = $$v
                          },
                          expression: "stageId"
                        }
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("stage"),
                              expression: "errors.has('stage')"
                            }
                          ],
                          staticClass: "text-danger text-sm"
                        },
                        [_vm._v(_vm._s(_vm.errors.first("stage")))]
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "vx-col md:w-1/2 w-full mb-2" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            "icon-pack": "feather",
                            icon: "icon-user",
                            label:
                              _vm.$t(_vm.resources.CreatedBy.i18n) ||
                              _vm.resources.CreatedBy.name,
                            readonly: ""
                          },
                          model: {
                            value: _vm.emailSession,
                            callback: function($$v) {
                              _vm.emailSession = $$v
                            },
                            expression: "emailSession"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("label", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.Remark.i18n) ||
                              _vm.resources.Remark.name
                          )
                        )
                      ]),
                      _c("vs-textarea", {
                        model: {
                          value: _vm.observation,
                          callback: function($$v) {
                            _vm.observation = $$v
                          },
                          expression: "observation"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "vx-col mb-2 mt-5",
                      staticStyle: { "text-align": "right" }
                    },
                    [
                      _c(
                        "vs-button",
                        {
                          attrs: { color: "primary", type: "filled" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.valid()
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(_vm.resources.Send.i18n) ||
                                  _vm.resources.Send.name
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]
            )
          ],
          1
        )
      : _c("div", [
          _c("h2", { staticClass: "text-center text-danger" }, [
            _vm._v(
              " Usted no tiene los permisos suficientes para ver este reporte. "
            )
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }