<template>
  <div>
    <div id="ag-grid-demo">
      <vx-card
        class="mb-2"
        :title="$t(resources.Parameters.i18n) || resources.Parameters.name"
      >
        <div class="vx-col w-full">
          <vs-table
            max-items="10"
            pagination
            :data="parameters"
            @search="handleSearch"
            search
          >
            <template slot="thead">
              <vs-th>{{
                $t(resources.Name.i18n) || resources.Name.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.Description.i18n) || resources.Description.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.Value.i18n) || resources.Value.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.Active.i18n) || resources.Active.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.Options.i18n) || resources.Options.name
              }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].parameter.name">{{
                  data[indextr].parameter.name
                }}</vs-td>
                <vs-td :data="data[indextr].parameter.description">{{
                  data[indextr].parameter.description
                }}</vs-td>
                <vs-td :data="data[indextr].value">{{
                  data[indextr].value
                }}</vs-td>
                <vs-td v-if="data[indextr].active" :data="data[indextr].active">
                  <feather-icon
                    icon="CheckIcon"
                    svgClasses="fill-current text-success"
                    class="w-5 h-5 mr-1"
                  ></feather-icon>
                </vs-td>
                <vs-td v-else :data="data[indextr].active">
                  <feather-icon
                    icon="XIcon"
                    svgClasses="fill-current text-danger"
                    class="w-5 h-5 mr-1"
                  ></feather-icon>
                </vs-td>
                <vs-td>
                  <vx-tooltip
                    :text="$t(resources.View.i18n) || resources.View.name"
                  >
                    <a @click="showPopUp(data[indextr])">
                      <feather-icon
                        icon="EyeIcon"
                        class="w-5 h-5 mr-1"
                      ></feather-icon>
                    </a>
                  </vx-tooltip>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </vx-card>
    </div>
    <vs-popup
      :title="$t(resources.Detail.i18n) || resources.Detail.name"
      :active.sync="popupActive"
    >
      <div class="vx-row mb-base">
        <div class="vx-col w-full mb-2">
          <vs-textarea
            :label="$t(resources.Value.i18n) || t(resources.Value.name)"
            class="vx-col w-full md:w-1/2 mb-4"
            v-model="param.value"
          />
        </div>
        <div class="vx-col w-full mb-2">
          <vs-switch color="success" v-model="paramActive">
            <span slot="on">{{
              $t(resources.Yes.i18n) || resources.Yes.name
            }}</span>
            <span slot="off">{{
              $t(resources.No.i18n) || resources.No.name
            }}</span>
          </vs-switch>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2 mt-5">
          <vs-button color="primary" type="filled" @click="update()">
            {{ $t(resources.Save.i18n) || resources.Save.name }}
          </vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import axios from "axios";

export default {
  data() {
    return {
      resources: resources,
      alerts: alerts,
      language: this.$i18n.locale,
      parameters: [],
      param: {},
      paramActive: false,
      popupActive: false,
    };
  },
  async created() {
    await this.get();
  },
  methods: {
    handleSearch(searching) {
      if (searching.length >= 3) {
        this.parameters = this.parameters.filter(
          (x) =>
            x.description.toLowerCase().includes(searching.toLowerCase()) ||
            x.name.toLowerCase().includes(searching.toLowerCase())
        );
      }
    },

    async get() {
      this.$vs.loading();
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}ParameterAplication`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.parameters = [];
          } else {
            this.parameters = result.data;
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },

    showPopUp(data) {
      this.param = data;
      this.paramActive = data.active;
      this.popupActive = true;
    },

    async update() {
      this.$vs.loading();
      let contactInfo = JSON.parse(localStorage.getItem("userInfo"));
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}ParameterAplication/Update`,
        data: {
          id: this.param.id,
          value: this.param.value,
          active: this.paramActive,
          updatedBy: contactInfo.email,
          language: this.language,
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        async (result) => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError"),
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-check",
            });
            await this.get();
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },
  },
};
</script>
