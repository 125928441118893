var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-card",
        {
          staticClass: "mb-2",
          attrs: {
            title:
              _vm.$t(_vm.resources.Product.i18n) || _vm.resources.Product.name
          }
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col md:w-1/3 w-full mb-4" }, [
              _c("h6", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.Name.i18n) || _vm.resources.Name.name
                  )
                )
              ]),
              _c("p", [_vm._v(_vm._s(_vm.productInfo.name))])
            ]),
            _c("div", { staticClass: "vx-col md:w-1/3 w-full mb-4" }, [
              _c("h6", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.Code.i18n) || _vm.resources.Code.name
                  )
                )
              ]),
              _c("p", [_vm._v(_vm._s(_vm.productInfo.code))])
            ]),
            _c("div", { staticClass: "vx-col md:w-1/3 w-full mb-4" }, [
              _c("h6", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.Type.i18n) || _vm.resources.Type.name
                  )
                )
              ]),
              _c("p", [_vm._v(_vm._s(_vm.productInfo.typeName))])
            ])
          ])
        ]
      ),
      _c(
        "div",
        { staticClass: "vx-row mb-4" },
        [
          _c(
            "vs-tabs",
            [
              _c(
                "vs-tab",
                {
                  attrs: {
                    label:
                      _vm.$t(_vm.resources.Detail.i18n) ||
                      _vm.resources.Detail.name,
                    "icon-pack": "feather",
                    icon: "icon-file-text"
                  }
                },
                [
                  _c("vx-card", [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c(
                          "vs-table",
                          {
                            attrs: {
                              "max-items": "10",
                              pagination: "",
                              data: _vm.productInfo.productDetail
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var data = ref.data
                                  return _vm._l(data, function(tr, indextr) {
                                    return _c(
                                      "vs-tr",
                                      { key: indextr },
                                      [
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: {
                                              data:
                                                data[indextr].contactTypeName
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                data[indextr].contactTypeName
                                              )
                                            )
                                          ]
                                        ),
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: {
                                              data: data[indextr].installment
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(data[indextr].installment)
                                            )
                                          ]
                                        ),
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: { data: data[indextr].price }
                                          },
                                          [_vm._v(_vm._s(data[indextr].price))]
                                        ),
                                        _c(
                                          "vs-td",
                                          [
                                            _c(
                                              "vx-tooltip",
                                              {
                                                attrs: {
                                                  text:
                                                    _vm.$t(
                                                      _vm.resources.View.i18n
                                                    ) || _vm.resources.View.name
                                                }
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.showPopUpDetail(
                                                          data[indextr]
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("feather-icon", {
                                                      staticClass:
                                                        "w-5 h-5 mr-1",
                                                      attrs: { icon: "EyeIcon" }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  })
                                }
                              }
                            ])
                          },
                          [
                            _c(
                              "template",
                              { slot: "thead" },
                              [
                                _c("vs-th", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        _vm.resources.ContactTypeName.i18n
                                      ) || _vm.resources.ContactTypeName.name
                                    )
                                  )
                                ]),
                                _c("vs-th", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(_vm.resources.Installments.i18n) ||
                                        _vm.resources.Installments.name
                                    )
                                  )
                                ]),
                                _c("vs-th", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(_vm.resources.Price.i18n) ||
                                        _vm.resources.Price.name
                                    )
                                  )
                                ]),
                                _c("vs-th", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(_vm.resources.Options.i18n) ||
                                        _vm.resources.Options.name
                                    )
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _c(
                "vs-tab",
                {
                  attrs: {
                    label:
                      _vm.$t(_vm.resources.Benefit.i18n) ||
                      _vm.resources.Benefit.name,
                    "icon-pack": "feather",
                    icon: "icon-file-text"
                  }
                },
                [
                  _c("vx-card", [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        _c(
                          "vs-table",
                          {
                            attrs: {
                              "max-items": "10",
                              pagination: "",
                              data: _vm.productInfo.productBenefit
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var data = ref.data
                                  return _vm._l(data, function(tr, indextr) {
                                    return _c(
                                      "vs-tr",
                                      { key: indextr },
                                      [
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: { data: data[indextr].name }
                                          },
                                          [_vm._v(_vm._s(data[indextr].name))]
                                        ),
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: {
                                              data: data[indextr].description
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(data[indextr].description)
                                            )
                                          ]
                                        ),
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: {
                                              data: data[indextr].amount
                                            }
                                          },
                                          [_vm._v(_vm._s(data[indextr].amount))]
                                        ),
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: { data: data[indextr].value }
                                          },
                                          [_vm._v(_vm._s(data[indextr].value))]
                                        ),
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: {
                                              data: data[indextr].isPercentage
                                            }
                                          },
                                          [
                                            data[indextr].isPercentage
                                              ? _c("feather-icon", {
                                                  staticClass: "w-5 h-5 mr-1",
                                                  attrs: {
                                                    icon: "CheckIcon",
                                                    svgClasses:
                                                      "fill-current text-success"
                                                  }
                                                })
                                              : _c("feather-icon", {
                                                  staticClass: "w-5 h-5 mr-1",
                                                  attrs: {
                                                    icon: "XIcon",
                                                    svgClasses:
                                                      "fill-current text-danger"
                                                  }
                                                })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "vs-td",
                                          [
                                            _c(
                                              "vx-tooltip",
                                              {
                                                attrs: {
                                                  text:
                                                    _vm.$t(
                                                      _vm.resources.View.i18n
                                                    ) || _vm.resources.View.name
                                                }
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.showPopUpBenefit(
                                                          data[indextr]
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("feather-icon", {
                                                      staticClass:
                                                        "w-5 h-5 mr-1",
                                                      attrs: { icon: "EyeIcon" }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  })
                                }
                              }
                            ])
                          },
                          [
                            _c(
                              "template",
                              { slot: "thead" },
                              [
                                _c("vs-th", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(_vm.resources.Name.i18n) ||
                                        _vm.resources.Name.name
                                    )
                                  )
                                ]),
                                _c("vs-th", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(_vm.resources.Description.i18n) ||
                                        _vm.resources.Description.name
                                    )
                                  )
                                ]),
                                _c("vs-th", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(_vm.resources.Amount.i18n) ||
                                        _vm.resources.Amount.name
                                    )
                                  )
                                ]),
                                _c("vs-th", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(_vm.resources.Value.i18n) ||
                                        _vm.resources.Value.name
                                    )
                                  )
                                ]),
                                _c("vs-th", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(_vm.resources.Percent.i18n) ||
                                        _vm.resources.Percent.name
                                    )
                                  )
                                ]),
                                _c("vs-th", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(_vm.resources.Options.i18n) ||
                                        _vm.resources.Options.name
                                    )
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title:
              _vm.$t(_vm.resources.Detail.i18n) || _vm.resources.Detail.name,
            active: _vm.popUpProductDetail
          },
          on: {
            "update:active": function($event) {
              _vm.popUpProductDetail = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vx-row mb-base" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-dollar-sign",
                    "icon-no-border": "",
                    type: "number",
                    size: "small",
                    label:
                      _vm.$t(_vm.resources.Installments.i18n) ||
                      _vm.resources.Installments.name
                  },
                  model: {
                    value: _vm.productDetail.installment,
                    callback: function($$v) {
                      _vm.$set(_vm.productDetail, "installment", $$v)
                    },
                    expression: "productDetail.installment"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-dollar-sign",
                    "icon-no-border": "",
                    type: "number",
                    size: "small",
                    label:
                      _vm.$t(_vm.resources.Price.i18n) ||
                      _vm.resources.Price.name
                  },
                  model: {
                    value: _vm.productDetail.price,
                    callback: function($$v) {
                      _vm.$set(_vm.productDetail, "price", $$v)
                    },
                    expression: "productDetail.price"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-dollar-sign",
                    "icon-no-border": "",
                    type: "number",
                    size: "small",
                    label:
                      _vm.$t(_vm.resources.PriceLevel.i18n) ||
                      _vm.resources.PriceLevel.name
                  },
                  model: {
                    value: _vm.productDetail.priceLevel,
                    callback: function($$v) {
                      _vm.$set(_vm.productDetail, "priceLevel", $$v)
                    },
                    expression: "productDetail.priceLevel"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/6 mb-2 mt-5" },
              [
                _c(
                  "vs-button",
                  {
                    attrs: { color: "primary", type: "filled" },
                    on: {
                      click: function($event) {
                        return _vm.updateDetail()
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(_vm.resources.Save.i18n) ||
                            _vm.resources.Save.name
                        ) +
                        " "
                    )
                  ]
                )
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title:
              _vm.$t(_vm.resources.Benefit.i18n) || _vm.resources.Benefit.name,
            active: _vm.popUpProductBenefit
          },
          on: {
            "update:active": function($event) {
              _vm.popUpProductBenefit = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vx-row mb-base" }, [
            _c("div", { staticClass: "vx-col w-full mb-2" }, [
              _c("h6", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.Name.i18n) || _vm.resources.Name.name
                  )
                )
              ]),
              _c("p", [_vm._v(_vm._s(_vm.productBenefit.name))])
            ]),
            _c(
              "div",
              { staticClass: "vx-col w-full mb-2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-dollar-sign",
                    "icon-no-border": "",
                    type: "number",
                    size: "small",
                    label:
                      _vm.$t(_vm.resources.Amount.i18n) ||
                      _vm.resources.Amount.name
                  },
                  model: {
                    value: _vm.productBenefit.amount,
                    callback: function($$v) {
                      _vm.$set(_vm.productBenefit, "amount", $$v)
                    },
                    expression: "productBenefit.amount"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full mb-2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-dollar-sign",
                    "icon-no-border": "",
                    type: "text",
                    size: "small",
                    label:
                      _vm.$t(_vm.resources.Value.i18n) ||
                      _vm.resources.Value.name
                  },
                  model: {
                    value: _vm.productBenefit.value,
                    callback: function($$v) {
                      _vm.$set(_vm.productBenefit, "value", $$v)
                    },
                    expression: "productBenefit.value"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full mb-2" },
              [
                _c("h6", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Percent.i18n) ||
                        _vm.resources.Percent.name
                    )
                  )
                ]),
                _c(
                  "vs-switch",
                  {
                    attrs: { color: "success" },
                    model: {
                      value: _vm.productBenefit.isPercentage,
                      callback: function($$v) {
                        _vm.$set(_vm.productBenefit, "isPercentage", $$v)
                      },
                      expression: "productBenefit.isPercentage"
                    }
                  },
                  [
                    _c("span", { attrs: { slot: "on" }, slot: "on" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.Yes.i18n) ||
                            _vm.resources.Yes.name
                        )
                      )
                    ]),
                    _c("span", { attrs: { slot: "off" }, slot: "off" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.No.i18n) || _vm.resources.No.name
                        )
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/6 mb-2 mt-5" },
              [
                _c(
                  "vs-button",
                  {
                    attrs: { color: "primary", type: "filled" },
                    on: {
                      click: function($event) {
                        return _vm.updateBenefit()
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(_vm.resources.Save.i18n) ||
                            _vm.resources.Save.name
                        ) +
                        " "
                    )
                  ]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }