<template>
  <div>
    <vs-row vs-type="flex" vs-justify="flex-end">
      <vx-tooltip
        v-if="
          productId == database.products.AIR ||
            productId == database.products.HOT ||
            productId == database.products.BAG ||
            productId == database.products.SEG
        "
        :text="$t(resources.Add.i18n) || resources.Add.name"
      >
        <vs-button
          radius
          class="mr-0 mb-2"
          color="primary"
          icon-pack="feather"
          @click="showPopUp(0, false)"
          type="border"
          icon="icon-plus"
        ></vs-button>
      </vx-tooltip>
    </vs-row>
    <vs-table :data="itineraryDetail">
      <template slot="thead">
        <vs-th>{{ $t(resources.Origin.i18n) || resources.Origin.name }}</vs-th>
        <vs-th>{{
          $t(resources.Destination.i18n) || resources.Destination.name
        }}</vs-th>
        <vs-th>{{
          $t(resources.StartDate.i18n) || resources.StartDate.name
        }}</vs-th>
        <vs-th>{{
          $t(resources.EndDate.i18n) || resources.EndDate.name
        }}</vs-th>
        <vs-th>{{
          $t(resources.SupplierName.i18n) || resources.SupplierName.name
        }}</vs-th>
        <vs-th>{{
          $t(resources.LenderServices.i18n) || resources.LenderServices.name
        }}</vs-th>
        <vs-th>{{
          $t(resources.Options.i18n) || resources.Options.name
        }}</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td
            v-if="data[indextr].productId == database.products.AIR"
            :data="data[indextr].origin"
            >({{ data[indextr].originCode }}) {{ data[indextr].origin }}</vs-td
          >
          <vs-td
            v-if="data[indextr].productId == database.products.AIR"
            :data="data[indextr].destination"
            >({{ data[indextr].destinationCode }})
            {{ data[indextr].destination }}</vs-td
          >
          <vs-td
            v-if="data[indextr].productId != database.products.AIR"
            :data="data[indextr].origin"
            >{{ data[indextr].origin }}</vs-td
          >
          <vs-td
            v-if="data[indextr].productId != database.products.AIR"
            :data="data[indextr].destination"
            >{{ data[indextr].destination }}</vs-td
          >
          <vs-td :data="data[indextr].departureDate">{{
            data[indextr].departureDate
          }}</vs-td>

          <vs-td :data="data[indextr].arrivalDate">{{
            data[indextr].arrivalDate
          }}</vs-td>
          <vs-td :data="data[indextr].supplierName">{{
            data[indextr].supplierName
          }}</vs-td>
          <vs-td :data="data[indextr].serviceSupplierName">{{
            data[indextr].serviceSupplierName
          }}</vs-td>
          <vs-td>
            <vx-tooltip :text="$t(resources.Edit.i18n) || resources.Edit.name">
              <a @click="showPopUp(data[indextr], true)">
                <feather-icon
                  icon="EditIcon"
                  class="w-5 h-5 mr-1"
                ></feather-icon>
              </a>
            </vx-tooltip>
            <vx-tooltip
              :text="$t(resources.Delete.i18n) || resources.Delete.name"
            >
              <a @click="remove(data[indextr])">
                <feather-icon
                  icon="XIcon"
                  svgClasses="fill-current text-danger"
                  class="w-5 h-5 mr-1"
                ></feather-icon>
              </a>
            </vx-tooltip>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <vs-popup
      :title="$t(resources.Itinerary.i18n) || resources.Itinerary.name"
      :active.sync="popup"
    >
      <div class="vx-row">
        <div class="vx-col md:w-1/2 mb-2 w-full">
          <label>{{
            $t(resources.Origin.i18n) || resources.Origin.name
          }}</label>
          <v-select
            id="searchOrigin"
            @keyup.native="getCityByName('Origin')"
            v-model="origin"
            :clearable="true"
            :options="originOption"
            :placeholder="
              $t(resources.WriteOrigin.i18n) || resources.WriteOrigin.name
            "
            :reduce="origin => origin.id"
            label="name"
          />
        </div>
        <div
          class="vx-col md:w-1/2 mb-2 w-full"
          v-if="productId != database.products.HOT"
        >
          <label>{{
            $t(resources.Destination.i18n) || resources.Destination.name
          }}</label>
          <v-select
            id="searchDestination"
            @keyup.native="getCityByName('Destination')"
            v-model="destination"
            :clearable="true"
            :options="destinationOption"
            :placeholder="
              $t(resources.WriteDestination.i18n) ||
                resources.WriteDestination.name
            "
            :reduce="destination => destination.id"
            label="name"
          />
        </div>

        <div
          class="vx-col md:w-1/2 mb-2 w-full"
          v-if="productId == database.products.HOT"
        >
          <label>{{
            $t(resources.LenderServices.i18n) || resources.LenderServices.name
          }}</label>
          <v-select
            id="searchLender"
            @keyup.native="getHotelByName()"
            v-model="lender"
            :clearable="true"
            :options="lenderOption"
            :placeholder="$t(resources.Select.i18n) || resources.Select.name"
            :reduce="lender => lender.id"
            label="name"
          />
        </div>

        <div
          class="vx-col md:w-1/2 mb-2 w-full"
          v-if="
            productId == database.products.AIR ||
              productId == database.products.BAG
          "
        >
          <label>{{
            $t(resources.LenderServices.i18n) || resources.LenderServices.name
          }}</label>
          <v-select
            id="searchLender"
            @keyup.native="getAirlineByText()"
            v-model="lender"
            :clearable="true"
            :options="lenderOption"
            :placeholder="$t(resources.Select.i18n) || resources.Select.name"
            :reduce="lender => lender.id"
            label="name"
          />
        </div>

        <div class="vx-col md:w-1/2 mb-2 w-full">
          <label>{{
            $t(resources.Supplier.i18n) || resources.Supplier.name
          }}</label>
          <v-select
            v-model="gds"
            :clearable="true"
            :options="gdsOption"
            :placeholder="$t(resources.Select.i18n) || resources.Select.name"
            :reduce="gds => gds.id"
            label="name"
          />
        </div>
        <div class="vx-col md:w-1/2 mb-2 w-full">
          <label class="vs-input--label">{{
            $t(resources.DepartureDate.i18n) || resources.DepartureDate.name
          }}</label>
          <flat-pickr
            class="vx-col w-full"
            :config="configdateTimePicker"
            v-model="departureDate"
            placeholder="yyyy-MM-dd Hh:mm"
          />
        </div>
        <div class="vx-col md:w-1/2 mb-2 w-full">
          <label class="vs-input--label">{{
            $t(resources.ArrivalDate.i18n) || resources.ArrivalDate.name
          }}</label>
          <flat-pickr
            class="vx-col w-full"
            :config="configdateTimePicker"
            v-model="arrivalDate"
            placeholder="yyyy-MM-dd Hh:mm"
          />
        </div>
        <div class="vx-col w-full md:w-1/2 mb-4" v-if="productId != 3">
          <label>{{
            $t(resources.FlightClass.i18n) || resources.FlightClass.name
          }}</label>
          <vs-input
            class="w-full"
            icon-pack="feather"
            icon-no-border
            maxlength="2"
            v-model="flightClass"
          />
        </div>
        <div class="vx-col w-full md:w-1/2 mb-4" v-if="productId != 3">
          <label>{{
            $t(resources.FlightNumber.i18n) || resources.FlightNumber.name
          }}</label>
          <vs-input
            class="w-full"
            icon-pack="feather"
            icon-no-border
            type="number"
            maxlength="10"
            v-model="flightNumber"
          />
        </div>

        <div class="vx-col w-full md:w-1/2 mt-4">
          <vs-button color="primary" type="filled" @click="saveItinerary()">
            {{ $t(resources.Add.i18n) || resources.Add.name }}
          </vs-button>
        </div>
      </div>
    </vs-popup>

    <vs-popup
      :title="$t(resources.Update.i18n) || resources.Update.name"
      :active.sync="popupEdit"
    >
      <div class="vx-row">
        <div class="vx-col md:w-1/2 mb-2 w-full">
          <label>{{
            $t(resources.Origin.i18n) || resources.Origin.name
          }}</label>
          <vs-input
            class="w-full"
            icon-pack="feather"
            icon-no-border
            maxlength="2"
            v-model="origin"
            disabled
          />
        </div>
        <div class="vx-col md:w-1/2 mb-2 w-full">
          <label>{{
            $t(resources.Destination.i18n) || resources.Destination.name
          }}</label>
          <vs-input
            class="w-full"
            icon-pack="feather"
            icon-no-border
            maxlength="2"
            v-model="destination"
            disabled
          />
        </div>

        <div class="vx-col md:w-1/2 mb-2 w-full">
          <label class="vs-input--label">{{
            $t(resources.DepartureDate.i18n) || resources.DepartureDate.name
          }}</label>
          <flat-pickr
            class="vx-col w-full"
            :config="configdateTimePicker"
            v-model="departureDate"
            placeholder="yyyy-MM-dd Hh:mm"
          />
        </div>
        <div class="vx-col md:w-1/2 mb-2 w-full">
          <label class="vs-input--label">{{
            $t(resources.ArrivalDate.i18n) || resources.ArrivalDate.name
          }}</label>
          <flat-pickr
            class="vx-col w-full"
            :config="configdateTimePicker"
            v-model="arrivalDate"
            placeholder="yyyy-MM-dd Hh:mm"
          />
        </div>
        <div class="vx-col md:w-1/2 mb-2 w-full">
          <label>{{
            $t(resources.Supplier.i18n) || resources.Supplier.name
          }}</label>
          <v-select
            v-model="gds"
            :clearable="true"
            :options="gdsOption"
            :placeholder="$t(resources.Select.i18n) || resources.Select.name"
            :reduce="gds => gds.id"
            label="name"
          />
        </div>
        <div class="vx-col w-full md:w-1/2 mb-4" v-if="productId != 3">
          <label>{{
            $t(resources.FlightClass.i18n) || resources.FlightClass.name
          }}</label>
          <vs-input
            class="w-full"
            icon-pack="feather"
            icon-no-border
            maxlength="2"
            v-model="flightClass"
          />
        </div>
        <div class="vx-col w-full md:w-1/2 mb-4" v-if="productId != 3">
          <label>{{
            $t(resources.FlightNumber.i18n) || resources.FlightNumber.name
          }}</label>
          <vs-input
            class="w-full"
            icon-pack="feather"
            icon-no-border
            type="number"
            maxlength="10"
            v-model="flightNumber"
          />
        </div>

        <div class="vx-col w-full md:w-1/2 mt-4">
          <vs-button color="primary" type="filled" @click="edit()">
            {{ $t(resources.Save.i18n) || resources.Save.name }}
          </vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import resources from "@/i18n/resources.js";
import vSelect from "vue-select";
import axios from "axios";
import * as lang from "vuejs-datepicker/src/locale";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import database from "@/assets/resources/enums/database.json";
import "vue-simple-suggest/dist/styles.css";

export default {
  components: {
    vSelect,
    flatPickr
  },
  name: "itinerary",
  props: {
    bookingId: String,
    productId: Number,
    bookingDetailId: Number,
    itineraryDetailDto: Array
  },
  data() {
    return {
      emailSession: JSON.parse(localStorage.getItem("userInfo")).email,
      configdateTimePicker: {
        enableTime: true,
        dateFormat: "d-M-Y H:i"
      },
      resources: resources,
      database: database,
      stage: 0,
      remark: "",
      popup: false,
      popupEdit: false,
      itineraryDetail: [],
      chosen: "",
      id: "",
      origin: "",
      originOption: [],
      destination: "",
      destinationOption: [],
      gds: "",
      gdsOption: [],
      lender: "",
      lenderOption: [],
      language: this.$i18n.locale.toUpperCase(),
      languages: lang,
      departureDate: "",
      arrivalDate: "",
      flightClass: "",
      flightNumber: "0",
      datetimeEmpty: "",
      itineraryDto: null
    };
  },
  methods: {
    async saveItinerary() {
      this.itineraryDto = {};
      let _gds = this.gdsOption.filter(x => x.id == this.gds);

      this.itineraryDto = {
        bookingId: this.bookingId,
        bookingDetailId: this.bookingDetailId,
        originId: this.origin,
        destinationId:
          this.productId == this.database.products.HOT
            ? this.origin
            : this.destination,
        originDate: moment(this.departureDate).format("YYYY-MM-DD HH:mm"),
        destintationDate: moment(this.arrivalDate).format("YYYY-MM-DD HH:mm"),
        supplierId: _gds[0].id,
        entityId: 0,
        identifier: 0,
        createdBy: JSON.parse(localStorage.getItem("userInfo")).email,
        flightNumber: this.flightNumber,
        flightClass: this.flightClass
      };

      if (
        this.productId == this.database.products.AIR ||
        this.productId == this.database.products.BAG
      ) {
        let _serviceProvider = this.lenderOption.find(
          x => x.id == this.lender
        );
        this.itineraryDto.entityId = this.database.entity.airline;
        this.itineraryDto.identifier = _serviceProvider.id;
      } else if (this.productId == this.database.products.HOT) {
        this.itineraryDto.entityId = this.database.entity.hotel;
        this.itineraryDto.identifier = this.lender;
      }
     else if (this.productId == this.database.products.SEG) {
        this.itineraryDto.entityId = this.database.entity.insurance;
        this.itineraryDto.identifier = 0;
        let _ini = this.originOption.find(x => x.id == this.origin);
        let _fin = this.destinationOption.find(x => x.id == this.destination);

        this.itineraryDto.originId = _ini.capitalId;
        this.itineraryDto.destinationId = _fin.capitalId;
      } else {
        this.itineraryDto.entityId = this.database.entity.unclassified;
        this.itineraryDto.identifier = 0;
      }

      this.popup = false;

      await axios({
        method: "POST",
        url: `${
          process.env.VUE_APP_APIVT
        }Itinerary/${this.$i18n.locale.toUpperCase()}`,
        data: this.itineraryDto,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.itineraryDetail = [];
          } else {
            this.itineraryDetail = result.data.data.filter(
              x => x.bookingDetailId == this.bookingDetailId
            );
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async edit() {
      this.itineraryDto = {};

      this.itineraryDto = {
        id: this.id,
        bookingId: this.bookingId,
        bookingDetailId: this.bookingDetailId,
        arrivalDate: moment(this.arrivalDate).format("YYYY-MM-DD HH:mm"),
        departureDate: moment(this.departureDate).format("YYYY-MM-DD HH:mm"),
        supplierId: this.gds,
        flightNumber: this.flightNumber,
        cabinType: this.flightClass,
        language: this.$i18n.locale.toUpperCase(),
        updatedBy: this.emailSession
      };
      this.popupEdit = false;

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Itinerary/Update`,
        data: this.itineraryDto,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.itineraryDetail = [];
          } else {
            await this.getItinerary();
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getItinerary() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Itinerary/${this.$i18n.locale.toUpperCase()}/${this.bookingId}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.itineraryDetail = [];
          } else {
            this.itineraryDetail = result.data.data.filter(
              x => x.bookingDetailId == this.bookingDetailId
            );
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async showPopUp(item, isEdit) {
      if (isEdit) {
        this.id = item.id;
        this.origin = item.origin;
        this.destination = item.destination;
        this.departureDate = new Date(item.departureDate);
        this.arrivalDate = new Date(item.arrivalDate);
        this.flightClass = item.cabinType;
        this.flightNumber = item.flightNumber;
        this.productId = item.productId;
        this.lender = `${item.serviceSupplierId} - ${item.serviceSupplierName}`;
        this.gds = item.supplierId;
        this.supplierId = item.supplierId;
        this.popupEdit = true;
      } else {
        this.id = "";
        this.origin = "";
        this.destination = "";
        this.gds = "";
        this.lender = "";
        this.departureDate = "";
        this.arrivalDate = "";
        this.flightClass = "";
        this.flightNumber = "";
        this.popup = true;
        if (this.productId == database.products.SEG) this.getCountryInsurance();
      }
    },

    async getCityByName(from) {
      let getElementId;
      if (from == `Origin`)
        getElementId = document.getElementById("searchOrigin");
      else getElementId = document.getElementById("searchDestination");

      let textSearch = getElementId.__vue__.search;
      let _searchApi = "";

      if (textSearch.length >= 3) {
        this.$vs.loading();
        if (this.productId == this.database.products.HOT)
          _searchApi = `City/GetByText/${this.$i18n.locale.toUpperCase()}/${textSearch}`;
        if (
          this.productId == this.database.products.AIR ||
          this.productId == this.database.products.CAR ||
          this.productId == this.database.products.BAG
        )
          _searchApi = `Airport/GetByText/${this.$i18n.locale.toUpperCase()}/${textSearch}`;
        if (this.productId == this.database.products.SEG)
          _searchApi = `Country/GetForInsurance/${this.$i18n.locale.toUpperCase()}`;
        if (this.productId == this.database.products.TDO)
          _searchApi = `City/GetByCity/${this.$i18n.locale.toUpperCase()}/${textSearch}`;

        await axios({
          method: "GET",
          url: `${process.env.VUE_APP_APIVT}${_searchApi}`,
          headers: {
            "content-type": "application/json",
            Authorization: `bearer ${localStorage.getItem("tokenVT")}`
          }
        }).then(
          result => {
            if (!result.data) {
              this.$vs.notify({
                title: this.$i18n.t("Alert"),
                text: this.$i18n.t("MsgNotFoundInfo"),
                color: "warning",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle"
              });
              this.originOption = [];
              this.destinationOption = [];
            } else {
              if (from == "Origin") {
                this.originOption = result.data;
              } else this.destinationOption = result.data;
            }
          },
          error => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError") + "<br/>" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.originOption = [];
            this.destinationOption = [];
          }
        );
        this.$vs.loading.close();
      }
    },

    async getGds() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Supplier`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.gdsOption = [];
          } else {
            this.gdsOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getAirlineByText() {
      let getElementId = document.getElementById("searchLender");
      let textSearch = getElementId.__vue__.search;

      if (textSearch.length == 3) {
        await axios({
          method: "GET",
          url: `${process.env.VUE_APP_APIVT}Airline/GetByText/${textSearch}`,
          headers: {
            "content-type": "application/json",
            Authorization: `bearer ${localStorage.getItem("tokenVT")}`
          }
        }).then(
          result => {
            if (result.data == 0) {
              this.$vs.notify({
                title: this.$i18n.t("Alert"),
                text: this.$i18n.t("MsgNotFoundInfo"),
                color: "warning",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle"
              });
              this.lenderOption = [];
            } else {
              this.lenderOption = result.data;
            }
          },
          error => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError") + "<br/>" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.lenderOption = [];
          }
        );
      }
    },

    async getHotelByName() {
      let getElementId;
      getElementId = document.getElementById("searchLender");

      let textSearch = getElementId.__vue__.search;

      if (textSearch.length >= 3) {
        this.$vs.loading();

        await axios({
          method: "GET",
          url: `${
            process.env.VUE_APP_APIVT
          }Hotel/GetByName/${this.$i18n.locale.toUpperCase()}/${textSearch}`,
          headers: {
            "content-type": "application/json",
            Authorization: `bearer ${localStorage.getItem("tokenVT")}`
          }
        }).then(
          result => {
            if (!result.data) {
              this.$vs.notify({
                title: this.$i18n.t("Alert"),
                text: this.$i18n.t("MsgNotFoundInfo"),
                color: "warning",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle"
              });
              this.lenderOption = [];
            } else {
              this.lenderOption = result.data;
            }
          },
          error => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError") + "<br/>" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.originOption = [];
            this.destinationOption = [];
          }
        );
        this.$vs.loading.close();
      }
    },

    async getCountryInsurance() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Country/GetForInsurance/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.originOption = [];
            this.destinationOption = [];
          } else {
            this.originOption = result.data;
            this.destinationOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async remove(item) {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Itinerary/Disabled/${this.$i18n.locale.toUpperCase()}/${item.id}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            await this.getItinerary();
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    }
  },
  async created() {
    await this.getGds();
    if (this.bookingId !== "0") this.getItinerary();
    else this.itineraryDetail = this.itineraryDetailDto;
  }
};
</script>
