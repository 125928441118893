var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "vx-row mb-4" },
        [
          _c(
            "vx-card",
            {
              attrs: {
                title:
                  _vm.$t(_vm.resources.Booking.i18n) ||
                  _vm.resources.Booking.name
              }
            },
            [
              _vm.managedSot
                ? _c("h4", { staticClass: "text-center text-danger mb-4" }, [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.alerts.MsgManagedBySOT.i18n) ||
                            _vm.alerts.MsgManagedBySOT.name
                        ) + "."
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.isManaged
                ? _c("h4", { staticClass: "text-center text-success mb-4" }, [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.alerts.MsgManaged.i18n) ||
                            _vm.alerts.MsgManaged.name
                        ) + "."
                      )
                    ])
                  ])
                : _vm._e(),
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-mail",
                          "icon-no-border": "",
                          readonly: "",
                          label: "Voucher"
                        },
                        model: {
                          value: _vm.voucherEmail,
                          callback: function($$v) {
                            _vm.voucherEmail = $$v
                          },
                          expression: "voucherEmail"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-hash",
                          "icon-no-border": "",
                          label:
                            _vm.$t(_vm.resources.AccountNumber.i18n) ||
                            _vm.resources.AccountNumber.name,
                          readonly: ""
                        },
                        model: {
                          value: _vm.accountNumber,
                          callback: function($$v) {
                            _vm.accountNumber = $$v
                          },
                          expression: "accountNumber"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-mail",
                          "icon-no-border": "",
                          label:
                            "IBO - " +
                            (_vm.$t(_vm.resources.Email.i18n) ||
                              _vm.resources.Email.name),
                          readonly: ""
                        },
                        model: {
                          value: _vm.iboEmail,
                          callback: function($$v) {
                            _vm.iboEmail = $$v
                          },
                          expression: "iboEmail"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-user",
                          "icon-no-border": "",
                          readonly: "",
                          label:
                            _vm.$t(_vm.resources.ContactName.i18n) ||
                            _vm.resources.ContactName.name
                        },
                        model: {
                          value: _vm.contactName,
                          callback: function($$v) {
                            _vm.contactName = $$v
                          },
                          expression: "contactName"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          "icon-no-border": "",
                          icon: "icon-mail",
                          readonly: "",
                          label:
                            (_vm.$t(_vm.resources.Contact.i18n) ||
                              _vm.resources.Contact.name) +
                            " - " +
                            (_vm.$t(_vm.resources.Email.i18n) ||
                              _vm.resources.Email.name)
                        },
                        model: {
                          value: _vm.email,
                          callback: function($$v) {
                            _vm.email = $$v
                          },
                          expression: "email"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-map",
                          "icon-no-border": "",
                          readonly: "",
                          label:
                            _vm.$t(_vm.resources.CountryOperation.i18n) ||
                            _vm.resources.CountryOperation.name
                        },
                        model: {
                          value: _vm.countryOperation,
                          callback: function($$v) {
                            _vm.countryOperation = $$v
                          },
                          expression: "countryOperation"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-hash",
                          "icon-no-border": "",
                          readonly: "",
                          label:
                            _vm.$t(_vm.resources.Code.i18n) ||
                            _vm.resources.Code.name
                        },
                        model: {
                          value: _vm.bookingCode,
                          callback: function($$v) {
                            _vm.bookingCode = $$v
                          },
                          expression: "bookingCode"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-dollar-sign",
                          "icon-no-border": "",
                          readonly: "",
                          label:
                            _vm.$t(_vm.resources.NetValue.i18n) ||
                            _vm.resources.NetValue.name,
                          value: _vm.netValue.toLocaleString("es-MX")
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          "icon-no-border": "",
                          readonly: "",
                          icon: "icon-dollar-sign",
                          label:
                            _vm.$t(_vm.resources.TaxesValue.i18n) ||
                            _vm.resources.Source.name,
                          value: _vm.taxValue.toLocaleString("es-MX")
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-dollar-sign",
                          "icon-no-border": "",
                          readonly: "",
                          label:
                            _vm.$t(_vm.resources.FeeValue.i18n) ||
                            _vm.resources.FeeValue.name,
                          value: _vm.feeValue.toLocaleString("es-MX")
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-dollar-sign",
                          "icon-no-border": "",
                          readonly: "",
                          label:
                            _vm.$t(_vm.resources.MerchantValue.i18n) ||
                            _vm.resources.MerchantValue.name,
                          value: _vm.merchantValue.toLocaleString("es-MX")
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-dollar-sign",
                          "icon-no-border": "",
                          readonly: "",
                          label:
                            _vm.$t(_vm.resources.AgentCommissionValue.i18n) ||
                            _vm.resources.AgentCommissionValue.name,
                          value: _vm.agentCommissionValue.toLocaleString(
                            "es-MX"
                          )
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-dollar-sign",
                          "icon-no-border": "",
                          readonly: "",
                          label:
                            _vm.$t(_vm.resources.BookingValue.i18n) ||
                            _vm.resources.BookingValue.name,
                          value: _vm.totalValue.toLocaleString("es-MX")
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          "icon-no-border": "",
                          readonly: "",
                          icon: "icon-dollar-sign",
                          label:
                            _vm.$t(_vm.resources.DiscountValue.i18n) ||
                            _vm.resources.DiscountValue.name,
                          value: _vm.discountValue.toLocaleString("es-MX")
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          "icon-no-border": "",
                          icon: "icon-dollar-sign",
                          readonly: "",
                          label:
                            _vm.$t(_vm.resources.FeeCustomized.i18n) ||
                            _vm.resources.FeeCustomized.name,
                          value: _vm.feeCustomized.toLocaleString("es-MX")
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          "icon-no-border": "",
                          icon: "icon-dollar-sign",
                          readonly: "",
                          label:
                            _vm.$t(_vm.resources.TotalValue.i18n) ||
                            _vm.resources.TotalValue.name,
                          value: (
                            _vm.totalValue +
                            _vm.feeCustomized +
                            _vm.discountValue
                          ).toLocaleString("es-MX")
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm.countryOperation == "COL"
                  ? _c("div", { staticClass: "vx-col md:w-1/6 w-full mb-2" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col w-full" },
                        [
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: {
                              "icon-pack": "feather",
                              icon: "icon-clipboard",
                              "icon-no-border": "",
                              readonly: "",
                              label:
                                _vm.$t(_vm.resources.Invoice.i18n) ||
                                _vm.resources.Invoice.name
                            },
                            model: {
                              value: _vm.invoice,
                              callback: function($$v) {
                                _vm.invoice = $$v
                              },
                              expression: "invoice"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm.countryOperation == "COL" &&
                _vm.bookingDetail[0].currencyCode == "COP"
                  ? _c(
                      "div",
                      { staticClass: "vx-col md:w-1/6 w-full mt-6" },
                      [
                        _c(
                          "vs-button",
                          {
                            attrs: { color: "success", type: "filled" },
                            on: {
                              click: function($event) {
                                return _vm.validateInvoice()
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Generate.i18n) ||
                                  _vm.resources.Generate.name
                              )
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ]
          ),
          _c(
            "vs-popup",
            {
              attrs: {
                classContent: "popup overFlow",
                title:
                  (_vm.$t(_vm.resources.FeeCustomized.i18n) ||
                    _vm.resources.FeeCustomized.name) +
                  " - " +
                  (_vm.$t(_vm.resources.Remark.i18n) ||
                    _vm.resources.Remark.name),
                active: _vm.popupObservation
              },
              on: {
                "update:active": function($event) {
                  _vm.popupObservation = $event
                }
              }
            },
            [
              _c("vs-textarea", {
                staticClass: "w-full",
                attrs: {
                  "icon-pack": "feather",
                  "icon-no-border": "",
                  icon: "icon-clipboard"
                },
                model: {
                  value: _vm.remark,
                  callback: function($$v) {
                    _vm.remark = $$v
                  },
                  expression: "remark"
                }
              }),
              _c(
                "div",
                {
                  staticClass: "vx-col mb-2 mt-5",
                  staticStyle: { "text-align": "right" }
                },
                [
                  _c(
                    "vs-button",
                    {
                      attrs: { color: "primary", type: "filled" },
                      on: {
                        click: function($event) {
                          _vm.popupObservation = false
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(_vm.resources.Save.i18n) ||
                              _vm.resources.Save.name
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-row mb-4" },
        [
          _c(
            "vs-tabs",
            [
              _c(
                "vs-tab",
                {
                  attrs: {
                    label:
                      _vm.$t(_vm.resources.Detail.i18n) ||
                      _vm.resources.Detail.name,
                    "icon-pack": "feather",
                    icon: "icon-file-text"
                  }
                },
                [
                  _c(
                    "vs-row",
                    {
                      staticClass: "mb-base mt-2",
                      attrs: { "vs-type": "flex", "vs-justify": "center" }
                    },
                    [
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-type": "flex",
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "3"
                          }
                        },
                        [
                          _c(
                            "vs-button",
                            {
                              attrs: {
                                color: "primary",
                                type: "filled",
                                icon: "add"
                              },
                              on: {
                                click: function($event) {
                                  _vm.popupAddDetail = true
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(_vm.resources.Add.i18n) ||
                                    _vm.resources.Add.name
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._l(_vm.bookingDetail, function(detail, index) {
                    return _c(
                      "vx-card",
                      { key: index, staticClass: "mb-4" },
                      [
                        _c(
                          "h3",
                          { staticClass: "font-weight-bold" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(detail.productName) +
                                " - #" +
                                _vm._s(detail.bookingReference) +
                                " "
                            ),
                            (_vm.emailSession == "a.gomez@visiontravel.net" ||
                              _vm.emailSession ==
                                "y.villa@visiontravelmail.net") &&
                            (detail.stageId == 31 || detail.stageId == 33)
                              ? _c(
                                  "vs-button",
                                  {
                                    staticClass: "small",
                                    attrs: { color: "success", type: "filled" },
                                    on: {
                                      click: function($event) {
                                        return _vm.setAudited(
                                          detail.bookingId,
                                          detail.bookingDetailId,
                                          detail.bookingReference
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("Auditada")]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c("p", [
                          _c("small", [
                            _vm._v(
                              "ID: " +
                                _vm._s(detail.bookingDetailId) +
                                "| " +
                                _vm._s(
                                  _vm.$t(_vm.resources.CreationDate.i18n) ||
                                    _vm.resources.CreationDate.name
                                ) +
                                ": " +
                                _vm._s(detail.createdDate) +
                                " | " +
                                _vm._s(
                                  _vm.$t(_vm.resources.CreatedBy.i18n) ||
                                    _vm.resources.CreatedBy.name
                                ) +
                                ": " +
                                _vm._s(detail.createdBy) +
                                " | " +
                                _vm._s(detail.bookingSourceName) +
                                " | " +
                                _vm._s(
                                  _vm.$t(_vm.resources.Stage.i18n) ||
                                    _vm.resources.Stage.name
                                ) +
                                ": " +
                                _vm._s(detail.stageName) +
                                " "
                            )
                          ])
                        ]),
                        _c(
                          "vs-collapse",
                          { staticClass: "mt-4", attrs: { type: "border" } },
                          [
                            _c(
                              "vs-collapse-item",
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "font-weight-bold",
                                    attrs: { slot: "header" },
                                    slot: "header"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            _vm.resources.DetailPrices.i18n
                                          ) || _vm.resources.DetailPrices.name
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-center text-primary font-semibold"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(detail.msgCustomizedFee) +
                                        " "
                                    )
                                  ]
                                ),
                                _c("div", { staticClass: "vx-row mt-4" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "vx-col w-full md:w-1/4 mb-2"
                                    },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "vs-input--label" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                _vm.resources.CountryOperation
                                                  .i18n
                                              ) ||
                                                _vm.resources.CountryOperation
                                                  .name
                                            )
                                          )
                                        ]
                                      ),
                                      _c("v-select", {
                                        attrs: {
                                          clearable: true,
                                          options: _vm.countryOption,
                                          reduce: function(item) {
                                            return item.countryCode
                                          },
                                          label: "countryName",
                                          disabled:
                                            detail.bookingSourceId == 2 ||
                                            detail.bookingSourceId == 21
                                              ? false
                                              : true
                                        },
                                        model: {
                                          value: _vm.countryOperation,
                                          callback: function($$v) {
                                            _vm.countryOperation = $$v
                                          },
                                          expression: "countryOperation"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "vx-col md:w-1/4 w-full mb-2"
                                    },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "vs-input--label" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                _vm.resources.Currency.i18n
                                              ) || _vm.resources.Currency.name
                                            )
                                          )
                                        ]
                                      ),
                                      _c("v-select", {
                                        attrs: {
                                          clearable: false,
                                          options: _vm.currencyList,
                                          reduce: function(data) {
                                            return data.id
                                          },
                                          label: "name",
                                          disabled:
                                            detail.bookingSourceId == 2 ||
                                            detail.bookingSourceId == 21
                                              ? false
                                              : true
                                        },
                                        model: {
                                          value: detail.currencyId,
                                          callback: function($$v) {
                                            _vm.$set(detail, "currencyId", $$v)
                                          },
                                          expression: "detail.currencyId"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "vx-col md:w-1/4 w-full mb-6"
                                    },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "vs-input--label" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                _vm.resources.ProductType.i18n
                                              ) ||
                                                _vm.resources.ProductType.name
                                            )
                                          )
                                        ]
                                      ),
                                      _c("v-select", {
                                        attrs: {
                                          clearable: true,
                                          options: _vm.productType,
                                          reduce: function(product) {
                                            return product.id
                                          },
                                          label: "name",
                                          disabled:
                                            detail.bookingSourceId == 2 ||
                                            detail.bookingSourceId == 21
                                              ? false
                                              : true
                                        },
                                        model: {
                                          value: detail.productId,
                                          callback: function($$v) {
                                            _vm.$set(detail, "productId", $$v)
                                          },
                                          expression: "detail.productId"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "vx-col md:w-1/4 w-full mb-4"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "vx-col w-full" },
                                        [
                                          _c("vs-input", {
                                            staticClass: "w-full",
                                            attrs: {
                                              "icon-pack": "feather",
                                              icon: "icon-dollar-sign",
                                              "icon-no-border": "",
                                              label:
                                                _vm.$t(
                                                  _vm.resources.BookingCode.i18n
                                                ) ||
                                                _vm.resources.BookingCode.name
                                            },
                                            model: {
                                              value: detail.bookingReference,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  detail,
                                                  "bookingReference",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "detail.bookingReference"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "vx-col md:w-1/4 w-full mb-4"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "vx-col w-full" },
                                        [
                                          _c("vs-input", {
                                            staticClass: "w-full",
                                            attrs: {
                                              "icon-pack": "feather",
                                              icon: "icon-dollar-sign",
                                              "icon-no-border": "",
                                              type: "number",
                                              label:
                                                _vm.$t(
                                                  _vm.resources.NetValue.i18n
                                                ) ||
                                                _vm.resources.NetValue.name,
                                              disabled:
                                                detail.bookingSourceId == 2 ||
                                                detail.bookingSourceId == 21
                                                  ? false
                                                  : true
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.sumValues(index)
                                              }
                                            },
                                            model: {
                                              value: detail.value,
                                              callback: function($$v) {
                                                _vm.$set(detail, "value", $$v)
                                              },
                                              expression: "detail.value"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "vx-col md:w-1/4 w-full mb-4"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "vx-col w-full" },
                                        [
                                          _c("vs-input", {
                                            staticClass: "w-full",
                                            attrs: {
                                              "icon-pack": "feather",
                                              "icon-no-border": "",
                                              icon: "icon-dollar-sign",
                                              type: "number",
                                              label:
                                                _vm.$t(
                                                  _vm.resources.TaxesValue.i18n
                                                ) ||
                                                _vm.resources.TaxesValue.name,
                                              disabled:
                                                detail.bookingSourceId == 2 ||
                                                detail.bookingSourceId == 21
                                                  ? false
                                                  : true
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.sumValues(index)
                                              }
                                            },
                                            model: {
                                              value: detail.tax,
                                              callback: function($$v) {
                                                _vm.$set(detail, "tax", $$v)
                                              },
                                              expression: "detail.tax"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "vx-col md:w-1/4 w-full mb-4"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "vx-col w-full" },
                                        [
                                          _c("vs-input", {
                                            staticClass: "w-full",
                                            attrs: {
                                              "icon-pack": "feather",
                                              icon: "icon-dollar-sign",
                                              "icon-no-border": "",
                                              type: "number",
                                              label:
                                                _vm.$t(
                                                  _vm.resources.FeeValue.i18n
                                                ) ||
                                                _vm.resources.FeeValue.name,
                                              disabled:
                                                detail.bookingSourceId == 2 ||
                                                detail.bookingSourceId == 21
                                                  ? false
                                                  : true
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.sumValues(index)
                                              }
                                            },
                                            model: {
                                              value: detail.fee,
                                              callback: function($$v) {
                                                _vm.$set(detail, "fee", $$v)
                                              },
                                              expression: "detail.fee"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "vx-col md:w-1/4 w-full mb-4"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "vx-col w-full" },
                                        [
                                          _c("vs-input", {
                                            staticClass: "w-full",
                                            attrs: {
                                              "icon-pack": "feather",
                                              "icon-no-border": "",
                                              type: "number",
                                              icon: "icon-dollar-sign",
                                              label:
                                                _vm.$t(
                                                  _vm.resources.MerchantValue
                                                    .i18n
                                                ) ||
                                                _vm.resources.MerchantValue.name
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.sumValues(index)
                                              }
                                            },
                                            model: {
                                              value: detail.merchant,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  detail,
                                                  "merchant",
                                                  $$v
                                                )
                                              },
                                              expression: "detail.merchant"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  detail.bookingSourceId != 2 &&
                                  detail.bookingSourceId != 21 &&
                                  _vm.emailSession !=
                                    "a.gomez@visiontravel.net" &&
                                  _vm.emailSession !=
                                    "felipe@visiontravel.net" &&
                                  _vm.emailSession !=
                                    "v.hernandez@visiontravelmail.net"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vx-col md:w-1/4 w-full mb-4"
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "vx-col w-full" },
                                            [
                                              _c("vs-input", {
                                                staticClass: "w-full",
                                                attrs: {
                                                  "icon-pack": "feather",
                                                  icon: "icon-dollar-sign",
                                                  "icon-no-border": "",
                                                  type: "number",
                                                  label:
                                                    _vm.$t(
                                                      _vm.resources
                                                        .AgentCommissionValue
                                                        .i18n
                                                    ) ||
                                                    _vm.resources
                                                      .AgentCommissionValue
                                                      .name,
                                                  readonly: ""
                                                },
                                                model: {
                                                  value:
                                                    detail.agentCommissionValue,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      detail,
                                                      "agentCommissionValue",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "detail.agentCommissionValue"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vx-col md:w-1/4 w-full mb-4"
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "vx-col w-full" },
                                            [
                                              _c("vs-input", {
                                                staticClass: "w-full",
                                                attrs: {
                                                  "icon-pack": "feather",
                                                  icon: "icon-dollar-sign",
                                                  "icon-no-border": "",
                                                  type: "number",
                                                  label:
                                                    _vm.$t(
                                                      _vm.resources
                                                        .AgentCommissionValue
                                                        .i18n
                                                    ) ||
                                                    _vm.resources
                                                      .AgentCommissionValue.name
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.sumValues(index)
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    detail.agentCommissionValue,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      detail,
                                                      "agentCommissionValue",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "detail.agentCommissionValue"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "vx-col md:w-1/4 w-full mb-4"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "vx-col w-full" },
                                        [
                                          _c("vs-input", {
                                            staticClass: "w-full",
                                            attrs: {
                                              "icon-pack": "feather",
                                              icon: "icon-dollar-sign",
                                              "icon-no-border": "",
                                              type: "number",
                                              readonly: "",
                                              label:
                                                _vm.$t(
                                                  _vm.resources.BookingValue
                                                    .i18n
                                                ) ||
                                                _vm.resources.BookingValue.name
                                            },
                                            model: {
                                              value: detail.total,
                                              callback: function($$v) {
                                                _vm.$set(detail, "total", $$v)
                                              },
                                              expression: "detail.total"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "vx-col md:w-1/4 w-full mb-4"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "vx-col w-full" },
                                        [
                                          _c(
                                            "vx-tooltip",
                                            {
                                              attrs: {
                                                text:
                                                  detail.descriptionDiscountIn,
                                                position: "top"
                                              }
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "vs-input--label",
                                                  attrs: { for: "" }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        _vm.resources
                                                          .DiscountValue.i18n
                                                      ) ||
                                                        _vm.resources
                                                          .DiscountValue.name
                                                    ) + " "
                                                  ),
                                                  _c("feather-icon", {
                                                    attrs: {
                                                      icon: "InfoIcon",
                                                      svgClasses: "h-4 w-4"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _c("vs-input", {
                                            staticClass: "w-full",
                                            attrs: {
                                              "icon-pack": "feather",
                                              icon: "icon-dollar-sign",
                                              "icon-no-border": "",
                                              type: "number"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.sumValues(index)
                                              }
                                            },
                                            model: {
                                              value: detail.discount,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  detail,
                                                  "discount",
                                                  $$v
                                                )
                                              },
                                              expression: "detail.discount"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "vx-col md:w-1/4 w-full mb-4"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "vx-col w-full" },
                                        [
                                          _c("vs-input", {
                                            staticClass: "w-full",
                                            attrs: {
                                              "icon-pack": "feather",
                                              "icon-no-border": "",
                                              icon: "icon-dollar-sign",
                                              type: "number",
                                              label:
                                                _vm.$t(
                                                  _vm.resources.FeeCustomized
                                                    .i18n
                                                ) ||
                                                _vm.resources.FeeCustomized
                                                  .name,
                                              disabled:
                                                detail.bookingSourceId == 2 ||
                                                detail.bookingSourceId == 21
                                                  ? false
                                                  : true
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.sumValues(
                                                  index,
                                                  false,
                                                  true
                                                )
                                              }
                                            },
                                            model: {
                                              value: detail.feeCustomized,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  detail,
                                                  "feeCustomized",
                                                  $$v
                                                )
                                              },
                                              expression: "detail.feeCustomized"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "vx-col md:w-1/4 w-full mb-4"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "vx-col w-full" },
                                        [
                                          _c("vs-input", {
                                            staticClass: "w-full",
                                            attrs: {
                                              "icon-pack": "feather",
                                              "icon-no-border": "",
                                              icon: "icon-dollar-sign",
                                              type: "number",
                                              readonly: "",
                                              label:
                                                _vm.$t(
                                                  _vm.resources.TotalValue.i18n
                                                ) ||
                                                _vm.resources.TotalValue.name
                                            },
                                            model: {
                                              value: detail.totalComplete,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  detail,
                                                  "totalComplete",
                                                  $$v
                                                )
                                              },
                                              expression: "detail.totalComplete"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "vx-col md:w-1/4 w-full mb-4"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "vx-col w-full" },
                                        [
                                          _c("vs-input", {
                                            staticClass: "w-full",
                                            attrs: {
                                              "icon-pack": "feather",
                                              "icon-no-border": "",
                                              icon: "icon-dollar-sign",
                                              type: "number",
                                              readonly:
                                                detail.stageId ==
                                                  _vm.database.stage.Issue ||
                                                detail.stageId ==
                                                  _vm.database.stage.Risk
                                                  ? false
                                                  : true,
                                              label:
                                                _vm.$t(
                                                  _vm.resources
                                                    .AirlineCommission.i18n
                                                ) ||
                                                _vm.resources.AirlineCommission
                                                  .name
                                            },
                                            model: {
                                              value:
                                                detail.airLineCommissionValue,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  detail,
                                                  "airLineCommissionValue",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "detail.airLineCommissionValue"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "vx-col md:w-1/4 w-full mb-4"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "vx-col w-full" },
                                        [
                                          _c("vs-input", {
                                            staticClass: "w-full",
                                            attrs: {
                                              "icon-pack": "feather",
                                              "icon-no-border": "",
                                              icon: "icon-dollar-sign",
                                              type: "number",
                                              label:
                                                _vm.$t(
                                                  _vm.resources.OtherValue.i18n
                                                ) ||
                                                _vm.resources.OtherValue.name
                                            },
                                            model: {
                                              value: detail.otherValue,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  detail,
                                                  "otherValue",
                                                  $$v
                                                )
                                              },
                                              expression: "detail.otherValue"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "vx-col md:w-1/4 w-full" },
                                    [
                                      _c(
                                        "ul",
                                        {
                                          staticClass: "centerx",
                                          staticStyle: { "margin-top": "20px" }
                                        },
                                        [
                                          _c(
                                            "li",
                                            [
                                              _c(
                                                "vs-checkbox",
                                                {
                                                  model: {
                                                    value: detail.isChange,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        detail,
                                                        "isChange",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "detail.isChange"
                                                  }
                                                },
                                                [_vm._v("¿Es un cambio?")]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]),
                                _c(
                                  "vs-divider",
                                  {
                                    staticClass: "mt-4 mb-2",
                                    attrs: { color: "success" }
                                  },
                                  [
                                    _c("h5", { staticClass: "text-success" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              _vm.resources.Commissions.i18n
                                            ) || _vm.resources.Commissions.name
                                          ) +
                                          " "
                                      )
                                    ])
                                  ]
                                ),
                                _c("div", { staticClass: "vx-row mb-4" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "vx-col md:w-1/5 w-full mb-2"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "vx-col w-full" },
                                        [
                                          _c("vs-input", {
                                            staticClass: "w-full",
                                            attrs: {
                                              "icon-pack": "feather",
                                              icon: "icon-hash",
                                              "icon-no-border": "",
                                              readonly:
                                                detail.stageId ==
                                                _vm.database.stage.Billing
                                                  ? false
                                                  : true,
                                              label:
                                                _vm.$t(
                                                  _vm.resources.OrderNumber.i18n
                                                ) ||
                                                _vm.resources.OrderNumber.name
                                            },
                                            model: {
                                              value: detail.infotraxOrder,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  detail,
                                                  "infotraxOrder",
                                                  $$v
                                                )
                                              },
                                              expression: "detail.infotraxOrder"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "vx-col md:w-1/5 w-full mb-2"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "vx-col w-full" },
                                        [
                                          _c("vs-input", {
                                            staticClass: "w-full",
                                            attrs: {
                                              "icon-pack": "feather",
                                              "icon-no-border": "",
                                              icon: "icon-calendar",
                                              readonly: "",
                                              label:
                                                _vm.$t(
                                                  _vm.resources.Period.i18n
                                                ) || _vm.resources.Period.name
                                            },
                                            model: {
                                              value: detail.infotraxWeek,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  detail,
                                                  "infotraxWeek",
                                                  $$v
                                                )
                                              },
                                              expression: "detail.infotraxWeek"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "vx-col md:w-1/5 w-full mb-2"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "vx-col w-full" },
                                        [
                                          _c("vs-input", {
                                            staticClass: "w-full",
                                            attrs: {
                                              "icon-pack": "feather",
                                              "icon-no-border": "",
                                              icon: "icon-calendar",
                                              readonly:
                                                detail.stageId ==
                                                _vm.database.stage.Billing
                                                  ? false
                                                  : true,
                                              label:
                                                _vm.$t(
                                                  _vm.resources.CreationDate
                                                    .i18n
                                                ) ||
                                                _vm.resources.CreationDate.name
                                            },
                                            model: {
                                              value: detail.creationDateOrder,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  detail,
                                                  "creationDateOrder",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "detail.creationDateOrder"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "vx-col md:w-1/5 w-full mb-2"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "vx-col w-full" },
                                        [
                                          _c("vs-input", {
                                            staticClass: "w-full",
                                            attrs: {
                                              "icon-pack": "feather",
                                              icon: "icon-dollar-sign",
                                              "icon-no-border": "",
                                              readonly:
                                                detail.stageId ==
                                                _vm.database.stage.Billing
                                                  ? false
                                                  : true,
                                              label:
                                                _vm.$t(
                                                  _vm.resources.Value.i18n
                                                ) || _vm.resources.Value.name
                                            },
                                            model: {
                                              value: detail.itacommission,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  detail,
                                                  "itacommission",
                                                  $$v
                                                )
                                              },
                                              expression: "detail.itacommission"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "vx-col md:w-1/5 w-full mb-2"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "vx-col w-full" },
                                        [
                                          _c("vs-input", {
                                            staticClass: "w-full",
                                            attrs: {
                                              "icon-pack": "feather",
                                              icon: "icon-package",
                                              "icon-no-border": "",
                                              readonly:
                                                detail.stageId ==
                                                _vm.database.stage.Billing
                                                  ? false
                                                  : true,
                                              label:
                                                _vm.$t(
                                                  _vm.resources.BVPoints.i18n
                                                ) || _vm.resources.BVPoints.name
                                            },
                                            model: {
                                              value: detail.bvPoints,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  detail,
                                                  "bvPoints",
                                                  $$v
                                                )
                                              },
                                              expression: "detail.bvPoints"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ]),
                                detail.allowEdit ||
                                _vm.emailSession ==
                                  "a.gomez@visiontravel.net" ||
                                  _vm.emailSession ==
                                    "v.hernandez@visiontravelmail.net" ||
                                  _vm.emailSession ==
                                    "m.agudelo@visiontravelmail.net"
                                  ? _c(
                                      "vs-row",
                                      {
                                        attrs: {
                                          "vs-type": "flex",
                                          "vs-justify": "flex-end"
                                        }
                                      },
                                      [
                                        _c(
                                          "vs-button",
                                          {
                                            staticClass: "mr-0 mb-2",
                                            on: {
                                              click: function($event) {
                                                return _vm.postDetail(detail)
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  _vm.resources.Save.i18n
                                                ) || _vm.resources.Save.name
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "vs-tabs",
                          {
                            staticStyle: {
                              width: "auto",
                              height: "auto",
                              overflow: "auto"
                            }
                          },
                          [
                            _c(
                              "vs-tab",
                              {
                                attrs: {
                                  label:
                                    _vm.$t(_vm.resources.Passengers.i18n) ||
                                    _vm.resources.Passengers.name
                                }
                              },
                              [
                                _c("passenger", {
                                  attrs: {
                                    bookingId: "" + _vm.bookingId,
                                    productId: "" + detail.productId,
                                    bookingDetailId: "" + detail.bookingDetailId
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "vs-tab",
                              {
                                attrs: {
                                  label:
                                    _vm.$t(_vm.resources.Itinerary.i18n) ||
                                    _vm.resources.Passengers.name
                                }
                              },
                              [
                                _c("itinerary", {
                                  attrs: {
                                    bookingId: "" + _vm.bookingId,
                                    productId: detail.productId,
                                    bookingDetailId: detail.bookingDetailId
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "vs-tab",
                              {
                                attrs: {
                                  label:
                                    _vm.$t(_vm.resources.Tracking.i18n) ||
                                    _vm.resources.Tracking.name
                                }
                              },
                              [
                                _c("tracking", {
                                  attrs: {
                                    bookingId: "" + _vm.bookingId,
                                    bookingDetailId:
                                      "" + detail.bookingDetailId,
                                    stage: detail.stageId,
                                    allowEdit: true
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("vs-row", {
                          staticClass: "mb-base mt-2",
                          attrs: { "vs-type": "flex", "vs-justify": "center" }
                        })
                      ],
                      1
                    )
                  })
                ],
                2
              ),
              _c(
                "vs-tab",
                {
                  attrs: {
                    label:
                      _vm.$t(_vm.resources.Payment.i18n) ||
                      _vm.resources.Payment.name,
                    "icon-pack": "feather",
                    icon: "icon-credit-card"
                  }
                },
                [
                  _vm.bookingDetail.length > 0
                    ? _c("payment", {
                        attrs: {
                          bookingId: "" + _vm.bookingId,
                          countryOperationCode: "" + _vm.countryOperation,
                          productId: _vm.productId,
                          supplierId: _vm.supplierId,
                          currencyCode: "" + _vm.bookingDetail[0].currencyCode,
                          contactId: _vm.bookingDetail[0].contactId,
                          itaContactId: _vm.bookingDetail[0].itaContactId,
                          bookingList: _vm.detailsList,
                          iboCode: _vm.itaCode
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "vs-tab",
                {
                  attrs: {
                    label:
                      _vm.$t(_vm.resources.Comments.i18n) ||
                      _vm.t(_vm.resources.Comments.name),
                    "icon-pack": "feather",
                    icon: "icon-message-circle"
                  },
                  on: { click: _vm.getComments }
                },
                [
                  _c("div", { staticClass: "vx-row" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full mb-4" },
                      [
                        _c(
                          "vx-card",
                          [
                            _c(
                              "div",
                              [
                                _c("h4", { staticClass: "mb-2" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(_vm.resources.Comments.i18n) ||
                                          _vm.t(_vm.resources.Comments.name)
                                      ) +
                                      " "
                                  )
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "vx-row" },
                                  [
                                    _c("vs-input", {
                                      staticClass:
                                        "vx-col w-full md:w-1/2 mb-2 inputx",
                                      attrs: {
                                        label:
                                          _vm.$t(_vm.resources.Subject.i18n) ||
                                          _vm.t(_vm.resources.Subject.name)
                                      },
                                      model: {
                                        value: _vm.remarkDto.subject,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.remarkDto,
                                            "subject",
                                            $$v
                                          )
                                        },
                                        expression: "remarkDto.subject"
                                      }
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "vx-col md:w-1/2 w-full" },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "btn btn-primary" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  _vm.resources.Attachment.i18n
                                                ) ||
                                                  _vm.t(
                                                    _vm.resources.Attachment
                                                      .name
                                                  )
                                              )
                                            )
                                          ]
                                        ),
                                        _c("vs-input", {
                                          staticClass: "w-full",
                                          attrs: { type: "file" },
                                          on: { change: _vm.onFileSelected }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("vs-textarea", {
                                  staticClass: "vx-col w-full md:w-1/2 mb-4",
                                  attrs: {
                                    label:
                                      _vm.$t(_vm.resources.Comments.i18n) ||
                                      _vm.t(_vm.resources.Comments.name),
                                    maxlength: "9000"
                                  },
                                  model: {
                                    value: _vm.remarkDto.remark,
                                    callback: function($$v) {
                                      _vm.$set(_vm.remarkDto, "remark", $$v)
                                    },
                                    expression: "remarkDto.remark"
                                  }
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-col md:w-1/4 w-full mb-2"
                                  },
                                  [
                                    _c(
                                      "ul",
                                      {
                                        staticClass: "vx-col w-full",
                                        staticStyle: { "margin-top": "20px" }
                                      },
                                      [
                                        _c(
                                          "li",
                                          [
                                            _c(
                                              "vs-checkbox",
                                              {
                                                model: {
                                                  value: _vm.remarkDto.publish,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.remarkDto,
                                                      "publish",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "remarkDto.publish"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.resources
                                                        .RemarkPublish.i18n
                                                    ) ||
                                                      _vm.resources
                                                        .RemarkPublish.name
                                                  )
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "vs-button",
                                  {
                                    staticClass: "mb-4",
                                    attrs: { color: "primary", type: "filled" },
                                    on: {
                                      click: function($event) {
                                        return _vm.getuploadForm()
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(_vm.resources.Save.i18n) ||
                                          _vm.t(_vm.resources.Save.name)
                                      )
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c("vx-timeline", { attrs: { data: _vm.comments } })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "vs-tab",
                {
                  attrs: {
                    label:
                      _vm.$t(_vm.resources.Documents.i18n) ||
                      _vm.t(_vm.resources.Documents.name),
                    "icon-pack": "feather",
                    icon: "icon-file"
                  },
                  on: { click: _vm.getDocuments }
                },
                [
                  _c("div", { staticClass: "vx-row" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full mb-4" },
                      [
                        _c("vx-card", [
                          _c(
                            "ul",
                            { staticClass: "vx-timeline" },
                            _vm._l(_vm.documents, function(item) {
                              return _c(
                                "li",
                                { key: item.id, staticClass: "mb-4" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "timeline-icon",
                                      class: "bg-" + item.color
                                    },
                                    [
                                      _c("feather-icon", {
                                        attrs: {
                                          icon: "FileIcon",
                                          svgClasses:
                                            "text-white stroke-current w-5 h-5"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "small",
                                    { staticClass: "activity-e-time" },
                                    [_vm._v(_vm._s(item.time))]
                                  ),
                                  _c("p", { staticClass: "font-semibold" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            _vm.resources.CreatedBy.i18n
                                          ) || _vm.resources.CreatedBy.name
                                        ) +
                                        ": " +
                                        _vm._s(item.user) +
                                        " "
                                    )
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "timeline-info mt-1" },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "font-semibold mb-2" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  _vm.resources.Subject.i18n
                                                ) || _vm.resources.Subject.name
                                              ) +
                                              ": " +
                                              _vm._s(item.title) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "a",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.openDocument(item.url)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                _vm.resources.OpenDocument.i18n
                                              ) ||
                                                _vm.resources.OpenDocument.name
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ])
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "vs-tab",
                {
                  staticStyle: { "align-text": "right" },
                  attrs: {
                    label:
                      _vm.$t(_vm.resources.Email.i18n) ||
                      _vm.t(_vm.resources.Email.name),
                    "icon-pack": "feather",
                    icon: "icon-mail"
                  }
                },
                [
                  _c("newMessage", {
                    attrs: {
                      identifier: _vm.bookingId,
                      entityId: _vm.other.entity.booking,
                      type: 1,
                      emailTo:
                        _vm.voucherEmail + ";" + _vm.iboEmail + ";" + _vm.email
                    }
                  })
                ],
                1
              ),
              _c(
                "vs-tab",
                {
                  attrs: {
                    label:
                      _vm.$t(_vm.resources.BillingData.i18n) ||
                      _vm.resources.BillingData.name,
                    "icon-pack": "feather",
                    icon: "icon-clipboard"
                  }
                },
                [
                  _c("billing-data", {
                    attrs: {
                      bookingId: _vm.bookingId,
                      allowEdit: _vm.bookingDetail[0].allowEdit
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title:
              _vm.$t(_vm.resources.Create.i18n) || _vm.resources.Create.name,
            active: _vm.popupAddDetail
          },
          on: {
            "update:active": function($event) {
              _vm.popupAddDetail = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vx-row mb-base" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Currency.i18n) ||
                        _vm.resources.Currency.name
                    )
                  )
                ]),
                _c("v-select", {
                  attrs: {
                    clearable: false,
                    options: _vm.currencyList,
                    reduce: function(data) {
                      return data.id
                    },
                    label: "name"
                  },
                  model: {
                    value: _vm.createDetail.currencyId,
                    callback: function($$v) {
                      _vm.$set(_vm.createDetail, "currencyId", $$v)
                    },
                    expression: "createDetail.currencyId"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.ProductType.i18n) ||
                        _vm.resources.ProductType.name
                    )
                  )
                ]),
                _c("v-select", {
                  attrs: {
                    clearable: true,
                    options: _vm.productType,
                    reduce: function(product) {
                      return product.id
                    },
                    label: "name"
                  },
                  model: {
                    value: _vm.createDetail.productId,
                    callback: function($$v) {
                      _vm.$set(_vm.createDetail, "productId", $$v)
                    },
                    expression: "createDetail.productId"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "vx-col md:w-1/2 w-full" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-dollar-sign",
                      "icon-no-border": "",
                      label:
                        _vm.$t(_vm.resources.BookingCode.i18n) ||
                        _vm.resources.BookingCode.name
                    },
                    on: {
                      blur: function($event) {
                        _vm.createDetail.bookingReference = _vm.createDetail.bookingReference.replace(
                          /\s+/g,
                          ""
                        )
                      }
                    },
                    model: {
                      value: _vm.createDetail.bookingReference,
                      callback: function($$v) {
                        _vm.$set(_vm.createDetail, "bookingReference", $$v)
                      },
                      expression: "createDetail.bookingReference"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-col md:w-1/2 w-full" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-dollar-sign",
                      "icon-no-border": "",
                      type: "number",
                      label:
                        _vm.$t(_vm.resources.NetValue.i18n) ||
                        _vm.resources.NetValue.name
                    },
                    on: {
                      change: function($event) {
                        return _vm.sumValues(0, true)
                      }
                    },
                    model: {
                      value: _vm.createDetail.value,
                      callback: function($$v) {
                        _vm.$set(_vm.createDetail, "value", $$v)
                      },
                      expression: "createDetail.value"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-col md:w-1/2 w-full" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      "icon-no-border": "",
                      icon: "icon-dollar-sign",
                      type: "number",
                      label:
                        _vm.$t(_vm.resources.TaxesValue.i18n) ||
                        _vm.resources.TaxesValue.name
                    },
                    on: {
                      change: function($event) {
                        return _vm.sumValues(0, true)
                      }
                    },
                    model: {
                      value: _vm.createDetail.tax,
                      callback: function($$v) {
                        _vm.$set(_vm.createDetail, "tax", $$v)
                      },
                      expression: "createDetail.tax"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-col md:w-1/2 w-full" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-dollar-sign",
                      "icon-no-border": "",
                      type: "number",
                      label:
                        _vm.$t(_vm.resources.FeeValue.i18n) ||
                        _vm.resources.FeeValue.name
                    },
                    on: {
                      change: function($event) {
                        return _vm.sumValues(0, true)
                      }
                    },
                    model: {
                      value: _vm.createDetail.fee,
                      callback: function($$v) {
                        _vm.$set(_vm.createDetail, "fee", $$v)
                      },
                      expression: "createDetail.fee"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-col md:w-1/2 w-full" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      "icon-no-border": "",
                      type: "number",
                      icon: "icon-dollar-sign",
                      label:
                        _vm.$t(_vm.resources.MerchantValue.i18n) ||
                        _vm.resources.MerchantValue.name
                    },
                    on: {
                      change: function($event) {
                        return _vm.sumValues(0, true)
                      }
                    },
                    model: {
                      value: _vm.createDetail.merchant,
                      callback: function($$v) {
                        _vm.$set(_vm.createDetail, "merchant", $$v)
                      },
                      expression: "createDetail.merchant"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-col md:w-1/2 w-full" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-dollar-sign",
                      "icon-no-border": "",
                      type: "number",
                      label:
                        _vm.$t(_vm.resources.DiscountValue.i18n) ||
                        _vm.resources.DiscountValue.name
                    },
                    on: {
                      change: function($event) {
                        return _vm.sumValues(0, true)
                      }
                    },
                    model: {
                      value: _vm.createDetail.discount,
                      callback: function($$v) {
                        _vm.$set(_vm.createDetail, "discount", $$v)
                      },
                      expression: "createDetail.discount"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-col md:w-1/2 w-full" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-dollar-sign",
                      "icon-no-border": "",
                      type: "number",
                      label:
                        _vm.$t(_vm.resources.AgentCommissionValue.i18n) ||
                        _vm.resources.AgentCommissionValue.name
                    },
                    on: {
                      change: function($event) {
                        return _vm.sumValues(0, true)
                      }
                    },
                    model: {
                      value: _vm.createDetail.agentCommission,
                      callback: function($$v) {
                        _vm.$set(_vm.createDetail, "agentCommission", $$v)
                      },
                      expression: "createDetail.agentCommission"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-col md:w-1/2 w-full" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-dollar-sign",
                      "icon-no-border": "",
                      type: "number",
                      readonly: "",
                      label:
                        _vm.$t(_vm.resources.BookingValue.i18n) ||
                        _vm.resources.BookingValue.name
                    },
                    model: {
                      value: _vm.createDetail.total,
                      callback: function($$v) {
                        _vm.$set(_vm.createDetail, "total", $$v)
                      },
                      expression: "createDetail.total"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-col md:w-1/2 w-full" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      "icon-no-border": "",
                      icon: "icon-dollar-sign",
                      type: "number",
                      label:
                        _vm.$t(_vm.resources.FeeCustomized.i18n) ||
                        _vm.resources.FeeCustomized.name
                    },
                    on: {
                      change: function($event) {
                        return _vm.sumValues(0, true)
                      }
                    },
                    model: {
                      value: _vm.createDetail.feeCustomized,
                      callback: function($$v) {
                        _vm.$set(_vm.createDetail, "feeCustomized", $$v)
                      },
                      expression: "createDetail.feeCustomized"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-col md:w-1/2 w-full" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      "icon-no-border": "",
                      icon: "icon-dollar-sign",
                      type: "number",
                      readonly: "",
                      label:
                        _vm.$t(_vm.resources.TotalValue.i18n) ||
                        _vm.resources.TotalValue.name
                    },
                    model: {
                      value: _vm.createDetail.totalValue,
                      callback: function($$v) {
                        _vm.$set(_vm.createDetail, "totalValue", $$v)
                      },
                      expression: "createDetail.totalValue"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-col md:w-1/2 w-full" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-user",
                      label:
                        _vm.$t(_vm.resources.CreatedBy.i18n) ||
                        _vm.resources.CreatedBy.name,
                      readonly: ""
                    },
                    model: {
                      value: _vm.createDetail.createdBy,
                      callback: function($$v) {
                        _vm.$set(_vm.createDetail, "createdBy", $$v)
                      },
                      expression: "createDetail.createdBy"
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.ReferenceCode.i18n) ||
                        _vm.resources.ReferenceCode.name
                    )
                  )
                ]),
                _c("v-select", {
                  attrs: {
                    clearable: false,
                    options: _vm.bookingDetail,
                    reduce: function(data) {
                      return data.bookingDetailId
                    },
                    label: "bookingReference"
                  },
                  model: {
                    value: _vm.createDetail.bookingDetailId,
                    callback: function($$v) {
                      _vm.$set(_vm.createDetail, "bookingDetailId", $$v)
                    },
                    expression: "createDetail.bookingDetailId"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 mb-2 w-full" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Supplier.i18n) ||
                        _vm.resources.Supplier.name
                    )
                  )
                ]),
                _c("v-select", {
                  attrs: {
                    clearable: true,
                    options: _vm.supplierOption,
                    placeholder:
                      _vm.$t(_vm.resources.Select.i18n) ||
                      _vm.resources.Select.name,
                    reduce: function(item) {
                      return item.id
                    },
                    label: "name"
                  },
                  model: {
                    value: _vm.createDetail.supplierId,
                    callback: function($$v) {
                      _vm.$set(_vm.createDetail, "supplierId", $$v)
                    },
                    expression: "createDetail.supplierId"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "vx-col md:w-1/2 w-full" }, [
              _c(
                "ul",
                {
                  staticClass: "centerx",
                  staticStyle: { "margin-top": "20px" }
                },
                [
                  _c(
                    "li",
                    [
                      _c(
                        "vs-checkbox",
                        {
                          model: {
                            value: _vm.createDetail.isChange,
                            callback: function($$v) {
                              _vm.$set(_vm.createDetail, "isChange", $$v)
                            },
                            expression: "createDetail.isChange"
                          }
                        },
                        [_vm._v("¿Es un cambio?")]
                      )
                    ],
                    1
                  )
                ]
              )
            ]),
            _c("div", { staticClass: "vx-col md:w-1/2 w-full" }, [
              _c(
                "ul",
                {
                  staticClass: "centerx",
                  staticStyle: { "margin-top": "20px" }
                },
                [
                  _c(
                    "li",
                    [
                      _c(
                        "vs-checkbox",
                        {
                          model: {
                            value: _vm.createDetail.copyItinerary,
                            callback: function($$v) {
                              _vm.$set(_vm.createDetail, "copyItinerary", $$v)
                            },
                            expression: "createDetail.copyItinerary"
                          }
                        },
                        [_vm._v("Copiar itinerarios")]
                      )
                    ],
                    1
                  )
                ]
              )
            ]),
            _c("div", { staticClass: "vx-col md:w-1/2 w-full" }, [
              _c(
                "ul",
                {
                  staticClass: "centerx",
                  staticStyle: { "margin-top": "20px" }
                },
                [
                  _c(
                    "li",
                    [
                      _c(
                        "vs-checkbox",
                        {
                          model: {
                            value: _vm.createDetail.copyPassenger,
                            callback: function($$v) {
                              _vm.$set(_vm.createDetail, "copyPassenger", $$v)
                            },
                            expression: "createDetail.copyPassenger"
                          }
                        },
                        [_vm._v("Copiar Pasajeros")]
                      )
                    ],
                    1
                  )
                ]
              )
            ])
          ]),
          _c(
            "div",
            { staticClass: "vx-col mb-2 mt-5" },
            [
              _c(
                "vs-button",
                {
                  attrs: { color: "primary", type: "filled" },
                  on: {
                    click: function($event) {
                      return _vm.saveDetail()
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(_vm.resources.Save.i18n) ||
                          _vm.resources.Save.name
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }