<template>
  <div>
    <div id="ag-grid-demo">
      <vx-card class="mb-4">
        <vs-row vs-type="flex" vs-justify="center">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <vs-button
              radius
              color="success"
              icon-pack="feather"
              icon="icon-plus"
              size="large"
              @click="popupCreate = true"
            ></vs-button>
          </vs-col>
        </vs-row>

        <h4 class="mb-4">
          {{ $t(resources.Filters.i18n) || resources.Filters.name }}
        </h4>
        <div class="vx-row mb-2">
          <div class="vx-col w-full md:w-1/4 mb-2">
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                $t(resources.SupplierName.i18n) || resources.SupplierName.name
              }}</label>
              <v-select
                v-model="supplierId"
                :clearable="true"
                :options="suppliersList"
                :reduce="item => item.id"
                label="name"
              />
            </div>
          </div>
          <div class="vx-col w-full md:w-1/4 mb-2">
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                $t(resources.Country.i18n) || resources.Country.name
              }}</label>
              <v-select
                v-model="countryOperationId"
                :clearable="true"
                :options="countriesList"
                :reduce="item => item.countryId"
                label="countryName"
              />
            </div>
          </div>
          <div class="vx-col w-full md:w-1/4 mb-2">
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                $t(resources.Type.i18n) || resources.Type.name
              }}</label>
              <v-select
                v-model="type"
                :clearable="true"
                :options="typesList"
                :reduce="item => item.id"
                label="name"
              />
            </div>
          </div>
          <div class="vx-col w-full md:w-1/6 mb-2">
            <vs-select
              class="vx-col w-full"
              :label="$t(resources.Status.i18n) || resources.Status.name"
              v-model="active"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="$t(item.i18n) || item.text"
                v-for="(item, index) in statusOption"
              />
            </vs-select>
          </div>
          <div class="vx-col w-full mb-2 mt-5">
            <vs-button
              color="primary"
              type="filled"
              class="mr-1"
              @click="get()"
            >
              {{ $t(resources.Search.i18n) || resources.Search.name }}
            </vs-button>
          </div>
        </div>
      </vx-card>

      <vx-card>
        <h4 class="font-semibold mb-base">
          {{ $t(resources.Blocks.i18n) || resources.Blocks.name }}
        </h4>
        <div class="flex flex-wrap justify-between items-center">
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2">
                  {{
                    currentPage * paginationPageSize - (paginationPageSize - 1)
                  }}
                  -
                  {{
                    dataList.length - currentPage * paginationPageSize > 0
                      ? currentPage * paginationPageSize
                      : dataList.length
                  }}
                  of {{ dataList.length }}
                </span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
          <div
            class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
          >
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              :placeholder="$t(resources.Search.i18n) || resources.Search.name"
            />
            <vs-button
              class="mb-4 md:mb-0 mr-1"
              @click="gridApi.exportDataAsCsv()"
            >
              {{ $t(resources.ExportAs.i18n) || resources.ExportAs.name }}
            </vs-button>
          </div>
        </div>

        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="dataList"
          rowSelection="single"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          @row-clicked="onRowClicked"
        ></ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>

    <!--Popup Update-->
    <div class="centerx">
      <vs-popup
        classContent="popup overFlow"
        :title="$t(resources.Update.i18n) || resources.Update.name"
        :active.sync="popupUpdate"
      >
        <div class="vx-row mb-4">
          <div class="vx-col w-full mb-2">
            <vs-input
              type="text"
              class="w-full"
              :label="$t(resources.Type.i18n) || resources.Type.name"
              v-model="updateDto.blockTypeName"
              readonly
            />
          </div>
          <div class="vx-col w-full md:w-1/2 mb-2">
            <vs-input
              type="text"
              class="w-full"
              :label="$t(resources.Supplier.i18n) || resources.Supplier.name"
              v-model="updateDto.supplierName"
              readonly
            />
          </div>
          <div class="vx-col w-full md:w-1/2 mb-2">
            <vs-input
              type="text"
              class="w-full"
              :label="$t(resources.Country.i18n) || resources.Country.name"
              v-model="updateDto.countryCode"
              readonly
            />
          </div>
          <div class="vx-col w-full md:w-1/2 mb-2">
            <vs-input
              type="text"
              class="w-full"
              :label="$t(resources.Start.i18n) || resources.Start.name"
              v-model="updateDto.start"
              readonly
            />
          </div>
          <div class="vx-col w-full md:w-1/2 mb-2">
            <vs-input
              type="text"
              class="w-full"
              :label="$t(resources.End.i18n) || resources.End.name"
              v-model="updateDto.end"
              readonly
            />
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <ul class="vx-col w-full mt-4">
              <li>
                <vs-checkbox v-model="updateDto.active">{{
                  $t(resources.Active.i18n) || resources.Active.name
                }}</vs-checkbox>
              </li>
            </ul>
          </div>
        </div>
        <div class="vx-col mb-2 mt-5" style="text-align: right">
          <vs-button color="primary" type="filled" @click="update()">
            {{ $t(resources.Save.i18n) || resources.Save.name }}
          </vs-button>
        </div>
      </vs-popup>
    </div>

    <!--Popup Update-->
    <div class="centerx">
      <vs-popup
        classContent="popup overFlow"
        :title="$t(resources.Create.i18n) || resources.Create.name"
        :active.sync="popupCreate"
      >
        <div class="vx-row mb-4">
          <div class="vx-col w-full md:w-1/2 mb-2">
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                $t(resources.SupplierName.i18n) || resources.SupplierName.name
              }}</label>
              <v-select
                v-model="createDto.supplierId"
                :options="suppliersList"
                :reduce="item => item.id"
                label="name"
                v-on:input="clearFields()"
                v-validate="'required'"
                name="supplier"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('supplier')"
                >{{ errors.first("supplier") }}</span
              >
            </div>
          </div>
          <div class="vx-col w-full md:w-1/2 mb-2">
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                $t(resources.Type.i18n) || resources.Type.name
              }}</label>
              <v-select
                v-model="createDto.blockTypeId"
                :options="typesList"
                :reduce="item => item.id"
                label="name"
                v-validate="'required'"
                name="type"
              />
              <span class="text-danger text-sm" v-show="errors.has('type')">{{
                errors.first("type")
              }}</span>
            </div>
          </div>

          <div
            class="vx-col md:w-1/2 mb-2 w-full"
            v-if="
              createDto.supplierId != null &&
                createDto.blockTypeId != null &&
                createDto.blockTypeId != ''
            "
          >
            <label>{{
              $t(resources.Start.i18n) || resources.Start.name
            }}</label>
            <v-select
              id="searchStart"
              @keyup.native="getData('start')"
              v-model="createDto.start"
              :clearable="true"
              :options="startOption"
              :placeholder="
                $t(resources.WriteDestination.i18n) ||
                  resources.WriteDestination.name
              "
              :reduce="x => x.code"
              label="name"
              v-validate="'required'"
              name="start"
            />
            <span class="text-danger text-sm" v-show="errors.has('start')">{{
              errors.first("start")
            }}</span>
          </div>

          <div
            class="vx-col md:w-1/2 mb-2 w-full"
            v-if="
              createDto.supplierId != null &&
                createDto.blockTypeId != null &&
                createDto.blockTypeId != '' &&
                createDto.blockTypeId != database.blockType.AirlineClass &&
                createDto.blockTypeId != database.blockType.Flight
            "
          >
            <label>{{ $t(resources.End.i18n) || resources.End.name }}</label>
            <v-select
              id="searchEnd"
              @keyup.native="getData('end')"
              v-model="createDto.end"
              :clearable="true"
              :options="endOption"
              :placeholder="
                $t(resources.WriteDestination.i18n) ||
                  resources.WriteDestination.name
              "
              :reduce="x => x.code"
              label="name"
              name="end"
            />
            <span class="text-danger text-sm" v-show="errors.has('end')">{{
              errors.first("end")
            }}</span>
          </div>

          <div
            class="vx-col md:w-1/2 mb-2 w-full"
            v-else-if="
              createDto.supplierId != null &&
              (createDto.blockTypeId == database.blockType.AirlineClass || 
                createDto.blockTypeId == database.blockType.Flight)
            "
          >
            <label>{{ $t(resources.End.i18n) || resources.End.name }}</label>
            <vs-input
              type="text"
              class="w-full"
              v-model="createDto.end"
              v-validate="'required'"
              name="end"
            />
            <span class="text-danger text-sm" v-show="errors.has('end')">{{
              errors.first("end")
            }}</span>
          </div>

          <div class="vx-col md:w-1/2 w-full mb-2">
            <ul class="vx-col w-full mt-4">
              <li>
                <vs-checkbox v-model="createDto.appliesBothWays">{{
                  $t(resources.AppliesBothWays.i18n) ||
                    resources.AppliesBothWays.name
                }}</vs-checkbox>
              </li>
            </ul>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <ul class="vx-col w-full mt-4">
              <li>
                <vs-checkbox v-model="createDto.active">{{
                  $t(resources.Active.i18n) || resources.Active.name
                }}</vs-checkbox>
              </li>
            </ul>
          </div>
        </div>
        <vs-divider position="center"
          ><strong>{{
            $t(resources.Settings.i18n) || resources.Settings.name
          }}</strong></vs-divider
        >
        <div class="vx-row">
          <div class="vx-col w-full md:w-1/2 mb-2">
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                $t(resources.Country.i18n) || resources.Country.name
              }}</label>
              <v-select
                v-model="countryAddId"
                :clearable="true"
                :options="countriesList"
                :reduce="item => item.countryId"
                label="countryName"
              />
            </div>
          </div>
          <div class="vx-col w-full md:w-1/6 mb-2 mt-5">
            <vs-button color="primary" type="filled" @click="addCountry()">
              {{ $t(resources.Add.i18n) || resources.Add.name }}
            </vs-button>
          </div>
        </div>

        <div class="vx-row mb-base">
          <div class="vx-col w-full mb-2">
            <vs-table :data="countriesConfig">
              <template slot="thead">
                <vs-th>{{
                  $t(resources.Country.i18n) || resources.Country.name
                }}</vs-th>
                <vs-th>{{
                  $t(resources.Options.i18n) || resources.Options.name
                }}</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="data[indextr].countryName">{{
                    data[indextr].countryName
                  }}</vs-td>
                  <vs-td>
                    <vx-tooltip
                      :text="
                        $t(resources.Disabled.i18n) || resources.Disabled.name
                      "
                      class="w-5 h-5 mr-1"
                    >
                      <a @click="deleteCountryList(data[indextr])">
                        <feather-icon
                          icon="XIcon"
                          svgClasses="fill-current text-danger"
                          class="w-5 h-5 mr-1"
                        ></feather-icon>
                      </a>
                    </vx-tooltip>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
        <div class="vx-col mb-2 mt-5" style="text-align: right">
          <vs-button
            color="primary"
            type="filled"
            @click.prevent="validCreate()"
          >
            {{ $t(resources.Save.i18n) || resources.Save.name }}
          </vs-button>
        </div>
      </vs-popup>
    </div>
  </div>
</template>
<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import vSelect from "vue-select";
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import moment from "moment";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import database from "@/assets/resources/enums/database.json";

export default {
  components: {
    AgGridVue,
    vSelect
  },
  data() {
    return {
      resources: resources,
      alerts: alerts,
      language: this.$i18n.locale,
      database: database,
      popupUpdate: false,
      popupCreate: false,
      emailSession: JSON.parse(localStorage.getItem("userInfo")).email,
      dataList: [],
      typesList: [],
      suppliersList: [],
      countriesList: [],
      statusOption: [
        {
          text: "Active",
          value: "true",
          i18n: "Active"
        },
        {
          text: "Inactive",
          value: "false",
          i18n: "Inactive"
        }
      ],
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      supplierId: "",
      type: "",
      active: "true",
      countryOperationId: "",
      id: "",
      updateDto: {},
      createDto: { active: true },
      countryAddId: "",
      countryOperations: [],
      startOption: [],
      endOption: [],
      countriesConfig: [],
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: "#",
          field: "id",
          hide: true
        },
        {
          headerName: this.$i18n.t("Type"),
          field: "blockTypeName",
          filter: true,
          width: 200
        },
        {
          headerName: this.$i18n.t("Supplier"),
          field: "supplierName",
          filter: true,
          width: 200
        },
        {
          headerName: this.$i18n.t("Country"),
          field: "countryCode",
          filter: true
        },
        {
          headerName: this.$i18n.t("Start"),
          field: "start",
          filter: true
        },
        {
          headerName: this.$i18n.t("End"),
          field: "end",
          filter: true
        },
        {
          headerName: this.$i18n.t("Status"),
          field: "active",
          width: 110,
          cellRenderer: function(event) {
            let object = "";
            if (event.node.data.active) {
              object =
                '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-success), 1);">check_circle</i>';
            } else
              object =
                '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-danger), 1);">highlight_off</i>';

            return object;
          }
        },
        {
          headerName: this.$i18n.t("CreationDate"),
          field: "creationDate",
          filter: true,
          width: 200,
          cellRenderer: function(event) {
            return moment(event.node.data.creationDate).format(
              "YYYY-MM-DD hh:mm:ss"
            );
          }
        },
        {
          headerName: this.$i18n.t("CreatedBy"),
          field: "createdBy",
          filter: true,
          width: 220
        },
        {
          headerName: this.$i18n.t("ChangeDate"),
          field: "updateDate",
          filter: true,
          width: 200,
          cellRenderer: function(event) {
            return moment(event.node.data.updateDate).format(
              "YYYY-MM-DD hh:mm:ss"
            );
          }
        },
        {
          headerName: this.$i18n.t("UpdatedBy"),
          field: "updateBy",
          filter: true,
          width: 220
        }
      ]
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("level", null);
      } else this.gridOptions.columnApi.setColumnPinned("level", "left");
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 20;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    }
  },
  async created() {
    await this.get();
    await this.getSupplier();
    await this.getType();
    await this.getCountries();
  },
  methods: {
    clearFields() {
      this.createDto.start = "";
      this.createDto.end = "";
      this.startOption = [];
      this.endOption = [];
    },

    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    async onRowClicked(event) {
      this.updateDto = event.node.data;
      this.updateDto.creationDate = moment(event.node.data.creationDate).format(
        "YYYY-MM-DD hh:mm:ss"
      );
      this.popupUpdate = true;
    },

    async get() {
      this.$vs.loading();

      let _keys = {
        active: this.active
      };

      if (
        this.supplierId != null &&
        this.supplierId != "" &&
        this.supplierId != 0
      )
        _keys["supplierid"] = this.supplierId;
      if (
        this.countryOperationId != null &&
        this.countryOperationId != "" &&
        this.countryOperationId != 0
      )
        _keys["countryoperationid"] = this.countryOperationId;
      if (this.type != null && this.type != "" && this.type != 0)
        _keys["type"] = this.type;

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Block/ListByFilters`,
        data: {
          searchKeys: _keys,
          page: 1,
          rows: 5000,
          language: "ES"
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data.data != null && result.data.data.length > 0) {
            this.dataList = result.data.data;
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.dataList = [];
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async getSupplier() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Supplier`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.suppliersList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getType() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }BlockType/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.typesList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCountries() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }CountryOperation/GetPccCountry/${this.$i18n.locale.toUpperCase()}/1`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data.length > 0) {
            this.countriesList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getData(from) {
      let getElementId;
      if (from == `start`)
        getElementId = document.getElementById("searchStart");
      else getElementId = document.getElementById("searchEnd");

      let textSearch = getElementId.__vue__.search;
      let _searchApi = "";
      if (
        this.createDto.blockTypeId == this.database.blockType.Airline ||
        this.createDto.blockTypeId ==
          this.database.blockType.AirlineCombination ||
        this.createDto.blockTypeId == this.database.blockType.AirlineClass ||
        this.createDto.blockTypeId == this.database.blockType.Flight
      )
        _searchApi = `Airline/GetByText/${textSearch}`;
      else if (
        this.createDto.blockTypeId == this.database.blockType.Destination ||
        this.createDto.blockTypeId == this.database.blockType.Route
      )
        _searchApi = `Airport/GetByText/${this.$i18n.locale.toUpperCase()}/${textSearch}`;

      if (textSearch.length == 3) {
        await axios({
          method: "GET",
          url: `${process.env.VUE_APP_APIVT}${_searchApi}`,
          headers: {
            "content-type": "application/json",
            Authorization: `bearer ${localStorage.getItem("tokenVT")}`
          }
        }).then(
          result => {
            if (result.data.length > 0) {
              let _response = [];
              if (
                this.createDto.blockTypeId == this.database.blockType.Airline ||
                this.createDto.blockTypeId ==
                  this.database.blockType.AirlineCombination ||
                this.createDto.blockTypeId == this.database.blockType.Flight
              ) {
                result.data.forEach(element => {
                  let _detail = element.supplierAirlines.filter(
                    x => x.supplierId == this.createDto.supplierId
                  );
                  if (_detail.length > 0)
                    _response.push({
                      id: element.id,
                      name: element.name,
                      code: _detail[0].code
                    });
                });
              } else {
                _response = result.data;
              }

              if (from == "start") {
                this.startOption = _response;
              } else this.endOption = _response;
            }
          },
          error => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError") + "<br/>" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.originOption = [];
            this.startOption = [];
          }
        );
      }
    },

    deleteCountryList(item) {
      let _data = this.countriesConfig.filter(
        x => x.countryId == item.countryId
      );

      if (_data != undefined && _data != null) {
        let _index = this.countriesConfig.findIndex(
          x => x.countryId == item.countryId
        );
        if (_index > -1) this.countriesConfig.splice(_index, 1);
        if (_index > -1) this.countryOperations.splice(_index, 1);
      }
    },

    addCountry() {
      let countriesConfig = this.countriesConfig.filter(
        x => x.countryId == this.countryAddId
      );

      if (countriesConfig.length == 0) {
        let name = this.countriesList.find(
          x => x.countryId == this.countryAddId
        ).countryName;

        this.countriesConfig.push({
          countryName: name,
          countryId: this.countryAddId
        });

        this.countryOperations.push(this.countryAddId);
      }
    },

    validCreate() {
      this.$validator.validateAll().then(async result => {
        if (result && this.countryOperations.length > 0) {
          await this.create();
        } else {
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: this.$i18n.t("MsgRequiredField"),
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      });
    },

    async create() {
      this.$vs.loading();
      this.createDto.countryOperations = this.countryOperations;
      this.createDto.createdBy = this.emailSession;
      this.createDto.language = this.$i18n.locale.toUpperCase();

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Block/Create`,
        data: this.createDto,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (result.data.success) {
            await this.get();
            this.createDto = { active: true };
            this.countriesConfig = [];
            this.countryOperations = [];
            this.popupCreate = false;

            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async update() {
      this.$vs.loading();

      this.updateDto.updatedBy = this.emailSession;
      this.updateDto.language = this.$i18n.locale.toUpperCase();

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Block/Update`,
        data: this.updateDto,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (result.data.success) {
            await this.get();
            this.popup = false;

            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  }
};
</script>
