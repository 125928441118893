var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { attrs: { id: "ag-grid-demo mt-5" } },
        [
          _c("vx-card", [
            _c(
              "div",
              { staticClass: "flex flex-wrap float-right mb-base" },
              [
                _c(
                  "vs-button",
                  {
                    attrs: {
                      to: "/commissions/Customized",
                      color: "success",
                      size: "large",
                      type: "filled",
                      "icon-pack": "feather",
                      icon: "icon-dollar-sign"
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(_vm.resources.Transfer.i18n) ||
                            _vm.resources.Transfer.name
                        ) +
                        " "
                    )
                  ]
                )
              ],
              1
            ),
            _c("h4", { staticClass: "font-semibold mb-base" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(_vm.resources.Detail.i18n) ||
                      _vm.resources.Detail.name
                  ) +
                  " - " +
                  _vm._s(
                    _vm.$t(_vm.resources.TipAndCustomizedFee.i18n) ||
                      _vm.resources.TipAndCustomizedFee.name
                  ) +
                  " "
              )
            ]),
            _c(
              "div",
              { staticClass: "vx-row mb-base" },
              [
                _c(
                  "div",
                  { staticClass: "vx-col w-full md:w-1/4 mb-2" },
                  [
                    _c("label", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(_vm.resources.StartDate.i18n) ||
                              _vm.resources.StartDate.name
                          ) +
                          " (" +
                          _vm._s(
                            _vm.$t(_vm.resources.Payment.i18n) ||
                              _vm.resources.Payment.name
                          ) +
                          "): "
                      )
                    ]),
                    _c("datepicker", {
                      attrs: {
                        language: _vm.languages[_vm.language],
                        format: "d MMMM yyyy"
                      },
                      model: {
                        value: _vm.start_date,
                        callback: function($$v) {
                          _vm.start_date = $$v
                        },
                        expression: "start_date"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col w-full md:w-1/4 mb-2" },
                  [
                    _c("label", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(_vm.resources.EndDate.i18n) ||
                              _vm.resources.EndDate.name
                          ) +
                          " (" +
                          _vm._s(
                            _vm.$t(_vm.resources.Payment.i18n) ||
                              _vm.resources.Payment.name
                          ) +
                          "): "
                      )
                    ]),
                    _c("datepicker", {
                      attrs: {
                        language: _vm.languages[_vm.language],
                        format: "d MMMM yyyy"
                      },
                      model: {
                        value: _vm.end_date,
                        callback: function($$v) {
                          _vm.end_date = $$v
                        },
                        expression: "end_date"
                      }
                    })
                  ],
                  1
                ),
                _c("vs-button", {
                  staticClass: "mt-5",
                  attrs: {
                    color: "primary",
                    type: "filled",
                    "icon-pack": "feather",
                    icon: "icon-refresh-ccw"
                  },
                  on: {
                    click: function($event) {
                      return _vm.cleanDate()
                    }
                  }
                }),
                _c(
                  "div",
                  { staticClass: "vx-col w-full md:w-1/5 mb-2" },
                  [
                    _c(
                      "vs-select",
                      {
                        staticClass: "vx-col w-full",
                        attrs: {
                          label:
                            _vm.$t(_vm.resources.Type.i18n) ||
                            _vm.resources.Type.name
                        },
                        model: {
                          value: _vm.chargeType,
                          callback: function($$v) {
                            _vm.chargeType = $$v
                          },
                          expression: "chargeType"
                        }
                      },
                      _vm._l(_vm.chargeTypeList, function(item, index) {
                        return _c("vs-select-item", {
                          key: index,
                          attrs: {
                            value: item.value,
                            text: _vm.$t(item.i18n) || item.text
                          }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col w-full md:w-1/5 mb-2" },
                  [
                    _c(
                      "vs-select",
                      {
                        staticClass: "vx-col w-full",
                        attrs: {
                          label:
                            _vm.$t(_vm.resources.Status.i18n) ||
                            _vm.resources.Status.name
                        },
                        model: {
                          value: _vm.status,
                          callback: function($$v) {
                            _vm.status = $$v
                          },
                          expression: "status"
                        }
                      },
                      _vm._l(_vm.statusList, function(item, index) {
                        return _c("vs-select-item", {
                          key: index,
                          attrs: {
                            value: item.value,
                            text: _vm.$t(item.i18n) || item.text
                          }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col w-full md:w-1/5 mb-2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        type: "number",
                        "icon-pack": "feather",
                        icon: "icon-user",
                        "icon-no-border": "",
                        label:
                          _vm.$t(_vm.resources.DistributorID.i18n) ||
                          _vm.resources.DistributorID.name
                      },
                      model: {
                        value: _vm.itaCodeForm,
                        callback: function($$v) {
                          _vm.itaCodeForm = $$v
                        },
                        expression: "itaCodeForm"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col w-full md:w-1/5 mb-2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        "icon-pack": "feather",
                        icon: "icon-hash",
                        "icon-no-border": "",
                        label:
                          _vm.$t(_vm.resources.BookingCode.i18n) ||
                          _vm.resources.BookingCode.name
                      },
                      model: {
                        value: _vm.bookingCode,
                        callback: function($$v) {
                          _vm.bookingCode = $$v
                        },
                        expression: "bookingCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "vs-checkbox",
                  {
                    model: {
                      value: _vm.includeColombia,
                      callback: function($$v) {
                        _vm.includeColombia = $$v
                      },
                      expression: "includeColombia"
                    }
                  },
                  [_vm._v("Colombia")]
                ),
                _c(
                  "vs-checkbox",
                  {
                    on: {
                      change: function($event) {
                        return _vm.clearTable()
                      }
                    },
                    model: {
                      value: _vm.isGrouped,
                      callback: function($$v) {
                        _vm.isGrouped = $$v
                      },
                      expression: "isGrouped"
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.GroupedByTransaction.i18n) ||
                          _vm.resources.GroupedByTransaction.name
                      )
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "vx-col w-full md:w-1/6 mb-2 mt-5" },
                  [
                    _c(
                      "vs-button",
                      {
                        attrs: { color: "primary", type: "filled" },
                        on: {
                          click: function($event) {
                            return _vm.getTipAndFee()
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(_vm.resources.Search.i18n) ||
                                _vm.resources.Search.name
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            !_vm.isGrouped
              ? _c(
                  "div",
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-wrap justify-between items-center"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "mb-4 md:mb-0 mr-4 ag-grid-table-actions-left"
                          },
                          [
                            _c(
                              "vs-dropdown",
                              {
                                staticClass: "cursor-pointer",
                                attrs: { "vs-trigger-click": "" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                                  },
                                  [
                                    _c("span", { staticClass: "mr-2" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.currentPage *
                                              _vm.paginationPageSize -
                                              (_vm.paginationPageSize - 1)
                                          ) +
                                          " - " +
                                          _vm._s(
                                            _vm.tipFeeData.length -
                                              _vm.currentPage *
                                                _vm.paginationPageSize >
                                              0
                                              ? _vm.currentPage *
                                                  _vm.paginationPageSize
                                              : _vm.tipFeeData.length
                                          ) +
                                          " of " +
                                          _vm._s(_vm.tipFeeData.length) +
                                          " "
                                      )
                                    ]),
                                    _c("feather-icon", {
                                      attrs: {
                                        icon: "ChevronDownIcon",
                                        svgClasses: "h-4 w-4"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "vs-dropdown-menu",
                                  [
                                    _c(
                                      "vs-dropdown-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.gridApi.paginationSetPageSize(
                                              20
                                            )
                                          }
                                        }
                                      },
                                      [_c("span", [_vm._v("20")])]
                                    ),
                                    _c(
                                      "vs-dropdown-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.gridApi.paginationSetPageSize(
                                              50
                                            )
                                          }
                                        }
                                      },
                                      [_c("span", [_vm._v("50")])]
                                    ),
                                    _c(
                                      "vs-dropdown-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.gridApi.paginationSetPageSize(
                                              100
                                            )
                                          }
                                        }
                                      },
                                      [_c("span", [_vm._v("100")])]
                                    ),
                                    _c(
                                      "vs-dropdown-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.gridApi.paginationSetPageSize(
                                              150
                                            )
                                          }
                                        }
                                      },
                                      [_c("span", [_vm._v("150")])]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("div", { staticClass: "vx-row" }, [
                          _c(
                            "div",
                            { staticClass: "mt-2" },
                            [
                              _c("vs-icon", {
                                attrs: {
                                  "icon-pack": "material-icons",
                                  icon: "check_circle",
                                  size: "small",
                                  color: "rgba(var(--vs-success), 1)"
                                }
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(_vm.resources.Paid.i18n) ||
                                      _vm.resources.Paid.name
                                  ) +
                                  " "
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "mt-2" },
                            [
                              _c("vs-icon", {
                                attrs: {
                                  "icon-pack": "material-icons",
                                  icon: "highlight_off",
                                  size: "small",
                                  color: "rgba(var(--vs-danger), 1)"
                                }
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(_vm.resources.WithoutPay.i18n) ||
                                      _vm.resources.WithoutPay.name
                                  ) +
                                  " "
                              )
                            ],
                            1
                          )
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex flex-wrap items-center justify-between ag-grid-table-actions-right"
                          },
                          [
                            _c("vs-input", {
                              staticClass: "mb-4 md:mb-0 mr-4",
                              attrs: {
                                placeholder:
                                  _vm.$t(_vm.resources.Search.i18n) ||
                                  _vm.resources.Search.name
                              },
                              on: { input: _vm.updateSearchQuery },
                              model: {
                                value: _vm.searchQuery,
                                callback: function($$v) {
                                  _vm.searchQuery = $$v
                                },
                                expression: "searchQuery"
                              }
                            }),
                            _c(
                              "vs-button",
                              {
                                staticClass: "mb-4 md:mb-0",
                                on: {
                                  click: function($event) {
                                    return _vm.gridApi.exportDataAsCsv()
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(_vm.resources.ExportAs.i18n) ||
                                        _vm.resources.ExportAs.name
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    ),
                    _c("ag-grid-vue", {
                      staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
                      attrs: {
                        gridOptions: _vm.gridOptions,
                        columnDefs: _vm.columnDefs,
                        defaultColDef: _vm.defaultColDef,
                        rowData: _vm.tipFeeData,
                        rowSelection: "single",
                        colResizeDefault: "shift",
                        animateRows: true,
                        floatingFilter: true,
                        pagination: true,
                        paginationPageSize: _vm.paginationPageSize,
                        suppressPaginationPanel: true
                      },
                      on: { "row-selected": _vm.onRowSelected }
                    }),
                    _c("vs-pagination", {
                      attrs: { total: _vm.totalPages, max: _vm.maxPageNumbers },
                      model: {
                        value: _vm.currentPage,
                        callback: function($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage"
                      }
                    })
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-wrap justify-between items-center"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "mb-4 md:mb-0 mr-4 ag-grid-table-actions-left"
                          },
                          [
                            _c(
                              "vs-dropdown",
                              {
                                staticClass: "cursor-pointer",
                                attrs: { "vs-trigger-click": "" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                                  },
                                  [
                                    _c("span", { staticClass: "mr-2" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.currentPage *
                                              _vm.paginationPageSize -
                                              (_vm.paginationPageSize - 1)
                                          ) +
                                          " - " +
                                          _vm._s(
                                            _vm.tipFeeData.length -
                                              _vm.currentPage *
                                                _vm.paginationPageSize >
                                              0
                                              ? _vm.currentPage *
                                                  _vm.paginationPageSize
                                              : _vm.tipFeeData.length
                                          ) +
                                          " of " +
                                          _vm._s(_vm.tipFeeData.length) +
                                          " "
                                      )
                                    ]),
                                    _c("feather-icon", {
                                      attrs: {
                                        icon: "ChevronDownIcon",
                                        svgClasses: "h-4 w-4"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "vs-dropdown-menu",
                                  [
                                    _c(
                                      "vs-dropdown-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.gridApi.paginationSetPageSize(
                                              20
                                            )
                                          }
                                        }
                                      },
                                      [_c("span", [_vm._v("20")])]
                                    ),
                                    _c(
                                      "vs-dropdown-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.gridApi.paginationSetPageSize(
                                              50
                                            )
                                          }
                                        }
                                      },
                                      [_c("span", [_vm._v("50")])]
                                    ),
                                    _c(
                                      "vs-dropdown-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.gridApi.paginationSetPageSize(
                                              100
                                            )
                                          }
                                        }
                                      },
                                      [_c("span", [_vm._v("100")])]
                                    ),
                                    _c(
                                      "vs-dropdown-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.gridApi.paginationSetPageSize(
                                              150
                                            )
                                          }
                                        }
                                      },
                                      [_c("span", [_vm._v("150")])]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex flex-wrap items-center justify-between ag-grid-table-actions-right"
                          },
                          [
                            _c("vs-input", {
                              staticClass: "mb-4 md:mb-0 mr-4",
                              attrs: {
                                placeholder:
                                  _vm.$t(_vm.resources.Search.i18n) ||
                                  _vm.resources.Search.name
                              },
                              on: { input: _vm.updateSearchQuery },
                              model: {
                                value: _vm.searchQuery,
                                callback: function($$v) {
                                  _vm.searchQuery = $$v
                                },
                                expression: "searchQuery"
                              }
                            }),
                            _c(
                              "vs-button",
                              {
                                staticClass: "mb-4 md:mb-0",
                                on: {
                                  click: function($event) {
                                    return _vm.gridApi.exportDataAsCsv()
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(_vm.resources.ExportAs.i18n) ||
                                        _vm.resources.ExportAs.name
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    ),
                    _c("ag-grid-vue", {
                      staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
                      attrs: {
                        gridOptions: _vm.gridOptions,
                        columnDefs: _vm.columnGrouped,
                        defaultColDef: _vm.defaultColDef,
                        rowData: _vm.tipFeeData,
                        rowSelection: "single",
                        colResizeDefault: "shift",
                        animateRows: true,
                        floatingFilter: true,
                        pagination: true,
                        paginationPageSize: _vm.paginationPageSize,
                        suppressPaginationPanel: true
                      }
                    }),
                    _c("vs-pagination", {
                      attrs: { total: _vm.totalPages, max: _vm.maxPageNumbers },
                      model: {
                        value: _vm.currentPage,
                        callback: function($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage"
                      }
                    })
                  ],
                  1
                )
          ])
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title:
              _vm.$t(_vm.resources.Comments.i18n) ||
              _vm.resources.Comments.name,
            active: _vm.popupActive
          },
          on: {
            "update:active": function($event) {
              _vm.popupActive = $event
            }
          }
        },
        [_c("p", [_vm._v(_vm._s(_vm.comments))])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }