<template>
  <div>
    <vx-card class="mb-4">
      <vs-row vs-type="flex" vs-justify="center">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <vs-button
            radius
            color="success"
            icon-pack="feather"
            icon="icon-plus"
            size="large"
            @click="$router.push('/management/hoteDetail/0').catch(() => {})"
          ></vs-button>
        </vs-col>
      </vs-row>

      <h4 class="mb-4">
        {{ $t(resources.Filters.i18n) || resources.Filters.name }}
      </h4>
      <div class="vx-row mb-2">
        <div class="vx-col w-full md:w-1/3 mb-2">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.SupplierName.i18n) || resources.SupplierName.name
            }}</label>
            <v-select
              v-model="supplier"
              :clearable="true"
              :options="supplierList"
              :reduce="supplier => supplier.id"
              label="name"
            />
          </div>
        </div>
        <div class="vx-col md:w-1/3 mb-2">
          <label>{{
            $t(resources.Destination.i18n) || resources.Destination.name
          }}</label>
          <v-select
            id="searchCity"
            @keyup.native="getCityByName('destination')"
            v-model="destination"
            :clearable="true"
            :options="destinationOption"
            :placeholder="
              $t(resources.WriteDestination.i18n) ||
                resources.WriteDestination.name
            "
            :reduce="destination => destination.id"
            label="name"
          />
        </div>
        <div class="vx-col w-full md:w-1/3 mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon-no-border
              :label="$t(resources.Code.i18n) || resources.Code.name"
              @keypress="onlyNumber"
              maxlength="10"
              v-model="code"
            />
          </div>
        </div>
        <div class="vx-col md:w-1/3 mb-2">
          <label>{{ $t(resources.Name.i18n) || resources.Name.name }}</label>
          <v-select
            id="searchHotel"
            @keyup.native="getHotelByName('hotel')"
            v-model="hotel"
            :clearable="true"
            :options="hotelOption"
            :placeholder="
              $t(resources.WriteName.i18n) || resources.WriteName.name
            "
            :reduce="hotel => hotel.id"
            label="name"
          />
        </div>
        <div class="vx-col w-full md:w-1/3 mb-2">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.Category.i18n) || resources.Category.name
            }}</label>
            <v-select
              v-model="category"
              :clearable="true"
              :options="categoryList"
              :reduce="category => category.id"
              label="name"
            />
          </div>
        </div>
        <div class="vx-col w-full md:w-1/3 mb-2">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.Accommodation.i18n) || resources.Accommodation.name
            }}</label>
            <v-select
              v-model="accommodation"
              :clearable="true"
              :options="accommodationList"
              :reduce="accommodation => accommodation.id"
              label="name"
            />
          </div>
        </div>
        <div class="vx-col w-full mb-2 mt-5">
          <vs-button
            color="primary"
            type="filled"
            class="mr-1"
            @click="addfilters()"
          >
            {{ $t(resources.Search.i18n) || resources.Search.name }}
          </vs-button>
          <vs-button color="warning" type="filled" @click="cleanFilters()">{{
            $t(resources.Clean.i18n) || resources.Clean.name
          }}</vs-button>
        </div>
      </div>
    </vx-card>

    <div id="ag-grid-demo">
      <vx-card>
        <div class="flex flex-wrap justify-between items-center">
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{
                    currentPage * paginationPageSize - (paginationPageSize - 1)
                  }}
                  -
                  {{
                    dataList.length - currentPage * paginationPageSize > 0
                      ? currentPage * paginationPageSize
                      : dataList.length
                  }}
                  of {{ dataList.length }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <div
            class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
          >
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              :placeholder="$t(resources.Search.i18n) || resources.Search.name"
            />
            <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">
              {{ $t(resources.ExportAs.i18n) || resources.ExportAs.name }}
            </vs-button>
          </div>
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="dataList"
          rowSelection="single"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          @row-clicked="onRowClicked"
        ></ag-grid-vue>
        <vs-pagination
          :total="totalNumberPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>

  </div>
</template>
<script>
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import resources from "@/i18n/resources.js";
import vSelect from "vue-select";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
    vSelect
  },
  data() {
    return {
      resources: resources,
      language: this.$i18n.locale,
      typeId: 2,
      gridApi: null,
      rows: 20,
      supplier: 0,
      supplierCreate: 0,
      supplierList: [],
      supplierHotels: [],
      destination: 0,
      destinationCreate: 0,
      destinationOption: [],
      code: "",
      codeCreate: "",
      hotel: 0,
      hotelOption: [],
      hotelName: "",
      category: 0,
      categoryCreate: 0,
      categoryList: [],
      accommodation: 0,
      accommodationCreate: 0,
      accommodationList: [],
      dataList: [],
      searchQuery: "",
      value: "",
      maxPageNumbers: 10,
      NumberPage: 0,
      gridOptions: {},
      popupCreate: false,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: this.$i18n.t("Code"),
          field: "code",
          filter: true,
          width: 200
        },
        {
          headerName: this.$i18n.t("Type"),
          field: "accomodationTypeName",
          filter: true,
          width: 200
        },
        {
          headerName: this.$i18n.t("Name"),
          field: "name",
          filter: true,
          width: 450
        },
        {
          headerName: this.$i18n.t("City"),
          field: "cityName",
          filter: true,
          width: 400,
          cellRenderer: function(event) {
            return `${event.node.data.cityName}, ${event.node.data.stateName}, ${event.node.data.countryName}`;
          }
        },
        {
          headerName: this.$i18n.t("Category"),
          field: "categoryName",
          filter: true,
          width: 200
        },
        {
          headerName: this.$i18n.t("Status"),
          field: "active",
          width: 120,
          cellRenderer: function(event) {
            let object = "";
            switch (event.node.data.active) {
              case false:
                object =
                  '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-danger), 1);">highlight_off</i>';
                break;
              case true:
                object =
                  '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-success), 1);">check_circle</i>';
                break;
            }
            return object;
          }
        }
      ],
      totalNumberPages: 1000,
      description: "",
      longitude: "",
      latitude: "",
      address: "",
      email: "",
      starNumber: "",
      active: true,
      activeList: [
        { text: "No", value: false, i18n: "No" },
        { text: "Si", value: true, i18n: "Yes" }
      ],
      outstanding: false,
      outstandingList: [
        { text: "No", value: false, i18n: "No" },
        { text: "Si", value: true, i18n: "Yes" }
      ],
      idHotel: 0,
      create: true,
      phone: 0
    };
  },

  async created() {},
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 20;
    },
    totalPages() {
      if (this.totalNumberPages)
        return this.totalNumberPages.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.NumberPage = val;
        if (
          localStorage.getItem("tokenVT") != null &&
          localStorage.getItem("tokenVT") != undefined
        ) {
          this.addfilters();
          this.getCategory();
          this.getAccommodationType();
          this.getSupplier();
        }
      }
    }
  },

  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    activePopup(openPopUp) {
      if (openPopUp == "create") {
        this.create = true;
        this.cleanFiltersPopup();
        this.popupCreate = true;
      } else {
        this.create = false;
        this.popupCreate = true;
      }
    },

    async addfilters() {
      this.$vs.loading.close();
      this.filterData = {};

      if (this.supplier != 0 && this.supplier != null && !isNaN(this.supplier))
        this.$set(this.filterData, "supplier", this.supplier);

      if (this.destination != "" && this.destination != null)
        this.$set(this.filterData, "destination", this.destination);

      if (this.code != "" && this.code != null)
        this.$set(this.filterData, "code", this.code);

      if (this.hotel != "" && this.hotel != null)
        this.$set(this.filterData, "hotel", this.hotel);

      if (this.category != 0 && this.category != null && !isNaN(this.category))
        this.$set(this.filterData, "category", this.category);

      if (
        this.accommodation != 0 &&
        this.accommodation != null &&
        !isNaN(this.accommodation)
      )
        this.$set(this.filterData, "accommodation", this.accommodation);

      this.getHotels();
    },

    async getHotels() {
      this.$vs.loading();
      let searchKeysData = this.filterData;

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Hotel/ListByFilters`,
        data: {
          searchKeys: searchKeysData,
          page: this.NumberPage,
          rows: this.rows,
          language: this.$i18n.locale.toUpperCase()
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.dataList = result.data;
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.dataList = [];
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async getSupplier() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Supplier`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.supplierList = result.data;
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.supplierList = [];
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCategory() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Category/CategoryHotels`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.categoryList = result.data;
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.categoryList = [];
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getAccommodationType() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}AccommodationType`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.accommodationList = result.data;
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.accommodationList = [];
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCityByName(item) {
      let getElementId;
      let textSearch;

      if (item == "destination") {
        getElementId = document.getElementById("searchCity");
        textSearch = getElementId.__vue__.search;
      } else {
        getElementId = document.getElementById("searchCityCreate");
        textSearch = getElementId.__vue__.search;
      }

      let _searchApi = `City/GetByCity/${this.$i18n.locale.toUpperCase()}/${textSearch}`;

      if (textSearch.length >= 3) {
        await axios({
          method: "GET",
          url: `${process.env.VUE_APP_APIVT}${_searchApi}`,
          headers: {
            "content-type": "application/json",
            Authorization: `bearer ${localStorage.getItem("tokenVT")}`
          }
        }).then(
          result => {
            if (!result.data) {
              this.$vs.notify({
                title: this.$i18n.t("Alert"),
                text: this.$i18n.t("MsgNotFoundInfo"),
                color: "warning",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle"
              });
              this.destinationOption = [];
            } else {
              this.destinationOption = result.data;
            }
          },
          error => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError") + "<br/>" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.destinationOption = [];
          }
        );
      }
    },

    async getHotelByName() {
      let getElementId = document.getElementById("searchHotel");
      let textSearch = getElementId.__vue__.search;
      let _searchApi = `Hotel/GetByName/${this.$i18n.locale.toUpperCase()}/${textSearch}`;

      if (textSearch.length == 3) {
        await axios({
          method: "GET",
          url: `${process.env.VUE_APP_APIVT}${_searchApi}`,
          headers: {
            "content-type": "application/json",
            Authorization: `bearer ${localStorage.getItem("tokenVT")}`
          }
        }).then(
          result => {
            if (!result.data) {
              this.$vs.notify({
                title: this.$i18n.t("Alert"),
                text: this.$i18n.t("MsgNotFoundInfo"),
                color: "warning",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle"
              });
              this.hotelOption = [];
            } else {
              this.hotelOption = result.data;
            }
          },
          error => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError") + "<br/>" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.hotelOption = [];
          }
        );
      }
    },

    async validation() {
      if (this.create) this.createHotel();
      else this.updateHotel();
    },

    async cleanFilters() {
      (this.supplier = 0),
        (this.destination = ""),
        (this.code = ""),
        (this.nameHotel = ""),
        (this.category = 0),
        (this.accommodation = 0),
        this.addfilters();
    },

    async cleanFiltersPopup() {
      this.hotelName = "";
      (this.description = ""),
        (this.longitude = ""),
        (this.latitude = ""),
        (this.categoryCreate = 0),
        (this.accommodationCreate = 0),
        (this.address = ""),
        (this.email = ""),
        (this.destinationCreate = 0),
        (this.starNumber = ""),
        (this.codeCreate = ""),
        (this.outstanding = false),
        (this.active = false),
        (this.supplierCreate = 0),
        (this.idHotel = 0);
    },

    async onRowClicked(event) {
      let hotelData = event.node.data;
      this.idHotel = hotelData.id;
      this.$router.push({
        name: "management-HotelDetail",
        params: {
          id: hotelData.id
        }
      });
    },

    addSupplier(item) {
      let supplierHotel = this.supplierHotels.filter(x => x.id == item);

      if (supplierHotel.length == 0) {
        let supplier = this.supplierList.filter(x => x.id == item);
        this.supplierHotels.push(supplier[0]);
      }
    },

    updateSupplier(item) {
      if (this.supplierHotels.find(x => x.id == item)) {
        let _index = this.supplierHotels.findIndex(x => x.id == item);
        if (_index > -1) this.supplierHotels.splice(_index, 1);
      }
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  }
};
</script>
