<!-- =========================================================================================
    File Name: GeneratePayments.vue
    Description: GeneratePayments Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    class="h-screen flex w-full vx-row no-gutter items-center justify-center"
  >
    <div class="vx-row w-full md:w-3/4">
      <vx-card
        class="mb-base"
        :title="$t(resources.Booking.i18n) || resources.Booking.name"
      >
        <div class="vx-row">
          <div class="vx-col md:w-1/3 w-full mb-4">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-hash"
                icon-no-border
                disabled
                :label="$t(resources.IBO.i18n) || resources.IBO.name"
                v-model="accountNumber"
              />
            </div>
          </div>
          <div class="vx-col md:w-1/5 w-full mb-4">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-hash"
                icon-no-border
                disabled
                :label="$t(resources.Code.i18n) || resources.Code.name"
                v-model="bookingReference"
              />
            </div>
          </div>
          <div class="vx-col md:w-1/5 w-full mb-4">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-clipboard"
                icon-no-border
                disabled
                :label="
                  $t(resources.CountryOperation.i18n) ||
                    resources.CountryOperation.name
                "
                v-model="countryCode"
              />
            </div>
          </div>

          <div class="vx-col md:w-1/5 w-full mb-4">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-dollar-sign"
                icon-no-border
                disabled
                :label="
                  $t(resources.TotalValue.i18n) || resources.TotalValue.name
                "
                v-model="totalValue"
              />
            </div>
          </div>
        </div>
      </vx-card>
      <vx-card
        :title="
          $t(resources.PaymentInformation.i18n) ||
            resources.PaymentInformation.name
        "
        class="mb-2"
      >
        <div class="vx-row mb-4" v-if="wayToPayId != 6">
          <div class="vx-col md:w-1/3 w-full">
            <label class="vs-input--label">{{
              $t(resources.WayToPay.i18n) || resources.WayToPay.name
            }}</label>
            <v-select
              v-model="wayToPayId"
              :clearable="true"
              :options="wayToPayList"
              :reduce="x => x.wayToPayId"
              label="name"
              v-on:input="setWayToPay(`${wayToPayId}`)"
              :hint="`${wayToPayId}`"
            />
          </div>
        </div>
        <div
          v-if="
            wayToPayId == database.merchantType.creditCard || wayToPayId == 6
          "
        >
          <div class="vx-row mb-2">
            <div class="vx-col w-full md:w-1/6 mb-2">
              <vs-select
                class="w-full vs-component vs-con-input-label vs-input is-label-placeholder"
                icon-no-border
                :placeholder="
                  $t(resources.CardType.i18n) || resources.CardType.name
                "
                @change="onChangeCardType(cardType)"
                v-model="cardType"
                v-validate="'required'"
                name="cardType"
              >
                <vs-select-item
                  :key="index"
                  :value="item.cardId"
                  :text="item.name"
                  v-for="(item, index) in cardTypeOption"
                />
              </vs-select>
              <span
                class="text-danger text-sm"
                v-show="errors.has('cardType')"
                >{{ errors.first("cardType") }}</span
              >
            </div>
            <div class="vx-col w-full md:w-1/4 mb-2">
              <vs-input
                id="cardNumber"
                class="w-full"
                icon-pack="feather"
                icon="icon-hash"
                icon-no-border
                :label-placeholder="
                  $t(resources.CardNumber.i18n) || resources.CardNumber.name
                "
                maxlength="16"
                @keypress="onlyNumber"
                v-model="cardNumber"
                v-validate="'required'"
                name="cardNumber"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('cardNumber')"
                >{{ errors.first("cardNumber") }}</span
              >
            </div>
            <div class="vx-col w-full md:w-1/5 mb-2">
              <vs-select
                class="w-full input-select-con vs-component vs-con-input-label vs-input is-label-placeholder"
                icon-no-border
                :placeholder="
                  $t(resources.ExpirationYear.i18n) ||
                    resources.ExpirationYear.name
                "
                v-model="expirationYear"
                v-on:change="expirationYearChange($event)"
                v-validate="'required'"
                name="expirationYear"
              >
                <vs-select-item
                  :key="index"
                  :value="item"
                  :text="item"
                  v-for="(item, index) in expirationYearOption"
                />
              </vs-select>
              <span
                class="text-danger text-sm"
                v-show="errors.has('expirationYear')"
                >{{ errors.first("expirationYear") }}</span
              >
            </div>
            <div class="vx-col w-full md:w-1/5 mb-2">
              <vs-select
                class="w-full input-select-con vs-component vs-con-input-label vs-input is-label-placeholder"
                icon-no-border
                :placeholder="
                  $t(resources.ExpirationMonth.i18n) ||
                    resources.ExpirationMonth.name
                "
                v-model="expirationMonth"
                v-validate="'required'"
                name="expirationMonth"
              >
                <vs-select-item
                  :key="index"
                  :value="item"
                  :text="item"
                  v-for="(item, index) in expirationMonthOption"
                />
              </vs-select>
              <span
                class="text-danger text-sm"
                v-show="errors.has('expirationMonth')"
                >{{ errors.first("expirationMonth") }}</span
              >
            </div>
            <div class="vx-col w-full md:w-1/6 mb-2">
              <vs-input
                id="securityCode"
                type="password"
                class="w-full"
                icon-pack="feather"
                icon="icon-lock"
                icon-no-border
                :label-placeholder="
                  $t(resources.SecurityCode.i18n) || resources.SecurityCode.name
                "
                maxlength="3"
                @keypress="onlyNumber"
                v-model="securityCode"
                v-validate="'required'"
                name="securityCode"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('securityCode')"
                >{{ errors.first("securityCode") }}</span
              >
            </div>
          </div>
          <vs-divider position="center"
            ><strong>{{
              $t(resources.BasicInformation.i18n) ||
                resources.BasicInformation.name
            }}</strong></vs-divider
          >
          <div class="vx-row">
            <div class="vx-col w-full md:w-1/2 mb-2">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-user"
                icon-no-border
                :label-placeholder="
                  $t(resources.Name.i18n) || resources.Name.name
                "
                v-model="name"
                v-validate="{ required: true, regex: /^[a-zA-Z ]+$/ }"
                name="name"
              />
              <span class="text-danger text-sm" v-show="errors.has('name')">{{
                errors.first("name")
              }}</span>
            </div>
            <div class="vx-col w-full md:w-1/2 mb-2">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-user"
                icon-no-border
                :label-placeholder="
                  $t(resources.LastName.i18n) || resources.LastName.name
                "
                v-model="lastName"
                v-validate="{ required: true, regex: /^[a-zA-Z ]+$/ }"
                name="lastName"
              />
              <span
              class="text-danger text-sm"
              v-show="errors.has('lastName')"
              >{{ errors.first("lastName") }}</span>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full md:w-1/2 mb-2">
              <vs-input
                type="number"
                class="w-full"
                icon-pack="feather"
                icon="icon-hash"
                icon-no-border
                :label-placeholder="
                  $t(resources.DocumentNumber.i18n) ||
                    resources.DocumentNumber.name
                "
                v-model="documentNumber"
                v-validate="'required|max:20'"
                name="documentNumber"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('documentNumber')"
                >{{ errors.first("documentNumber") }}</span
              >
            </div>
            <div class="vx-col w-full md:w-1/2 mb-2">
              <vs-input
                type="email"
                class="w-full"
                icon-pack="feather"
                icon="icon-mail"
                icon-no-border
                :label-placeholder="
                  $t(resources.Email.i18n) || resources.Email.name
                "
                maxlength="50"
                v-model="email"
                v-validate="'required'"
                name="email"
              />
              <span class="text-danger text-sm" v-show="errors.has('email')">{{
                errors.first("email")
              }}</span>
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col md:w-1/3 w-full mb-2">
              <vs-input
                type="text"
                class="w-full"
                icon-pack="feather"
                icon="icon-clipboard"
                icon-no-border
                :label-placeholder="
                  $t(resources.Address.i18n) || resources.Address.name
                "
                v-model="address"
                v-validate="{ required: true, regex: /^[a-zA-Z0-9 ]{5,50}$/ }"
                name="address"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('address')"
                >{{ errors.first("address") }}</span
              >
            </div>
            <div class="vx-col md:w-1/3 w-full mb-2">
              <vs-input
                type="number"
                class="w-full"
                icon-pack="feather"
                icon="icon-phone"
                icon-no-border
                :label-placeholder="
                  $t(resources.Phone.i18n) || resources.Phone.name
                "
                v-model="Telephone"
                v-validate="'required|max:20'"
                name="Telephone"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('Telephone')"
                >{{ errors.first("Telephone") }}</span
              >
            </div>
            <div class="vx-col md:w-1/3 w-full mb-2">
              <vs-input
                type="text"
                class="w-full"
                icon-pack="feather"
                icon="icon-hash"
                icon-no-border
                :label-placeholder="
                  $t(resources.ZipCode.i18n) || resources.ZipCode.name
                "
                maxlength="10"
                v-model="zipCode"
                v-validate="{ required: true, regex: /^\d{5}$/ }"
                name="zipCode"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('zipCode')"
                >{{ errors.first("zipCode") }}</span
              >
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col md:w-1/3 w-full mb-2">
              <label class="vs-input--label">{{
                $t(resources.Country.i18n) || resources.Country.name
              }}</label>
              <v-select
                v-model="countryId"
                :clearable="false"
                :options="countryOption"
                :reduce="country => country.id"
                label="name"
                v-on:input="getStateByCountry(`${countryId}`)"
                :hint="`${countryId}`"
                v-validate="'required'"
                name="country"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('country')"
                >{{ errors.first("country") }}</span
              >
            </div>

            <div class="vx-col md:w-1/3 w-full mb-2">
              <label class="vs-input--label">{{
                $t(resources.State.i18n) || resources.State.name
              }}</label>
              <v-select
                v-model="stateId"
                :clearable="false"
                :options="stateCountryOption"
                :reduce="state => state.id"
                label="name"
                v-on:input="getCityByState(`${stateId}`)"
                :hint="`${stateId}`"
                v-validate="'required'"
                name="state"
              />
              <span class="text-danger text-sm" v-show="errors.has('state')">{{
                errors.first("state")
              }}</span>
            </div>
            <div class="vx-col md:w-1/3 w-full mb-2">
              <label class="vs-input--label">{{
                $t(resources.City.i18n) || resources.City.name
              }}</label>
              <v-select
                v-model="city"
                :clearable="false"
                :options="cityOption"
                :reduce="city => city.id"
                label="name"
                v-validate="'required'"
                name="destination"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('destination')"
                >{{ errors.first("destination") }}</span
              >
            </div>
            <div
              class="vx-col md:w-1/3 w-full mb-2"
              v-if="
                installmentNumberOption && installmentNumberOption.length > 0
              "
            >
              <div class="vx-col w-full">
                <label class="vs-input--label">{{
                  $t(resources.InstallmentNumber.i18n) ||
                    resources.InstallmentNumber.name
                }}</label>
                <v-select
                  v-model="installmentNumber"
                  :clearable="false"
                  :options="installmentNumberOption"
                  :reduce="data => data"
                  label="name"
                />
              </div>
            </div>
          </div>
        </div>

        <div v-if="wayToPayId == database.merchantType.EWallet">
          <div class="vx-row">
            <div class="vx-col md:w-1/3 w-full mb-2">
              <label class="vs-input--label">{{
                $t(resources.Select.i18n) || resources.Select.name
              }}</label>
              <v-select
                v-model="merchantId"
                :clearable="false"
                :options="walletOption"
                :reduce="data => data.walletId"
                label="name"
                v-on:input="setAccountWallet(`${merchantId}`)"
                :hint="`${merchantId}`"
                v-validate="'required'"
                name="account"
              ></v-select>
              <span
                class="text-danger text-sm"
                v-show="errors.has('account')"
                >{{ errors.first("account") }}</span
              >
            </div>
            <div
              class="vx-col md:w-1/3 w-full mb-2"
              v-if="merchantId == database.merchants.BlockPay && itaCode != 1"
            >
              <label class="vs-input--label">{{
                $t(resources.AccountNumber.i18n) || resources.AccountNumber.name
              }}</label>
              <v-select
                v-model="accountWalletId"
                :clearable="false"
                :options="accountWalletOption"
                :reduce="x => x.id"
                label="name"
                :hint="`${accountWalletId}`"
                name="accountWalletId"
                v-validate="'required'"
              ></v-select>
              <span
                class="text-danger text-sm"
                v-show="errors.has('accountWalletId')"
                >{{ errors.first("accountWalletId") }}</span
              >
            </div>
            <div
              class="vx-col md:w-1/3 w-full mb-2"
              v-if="merchantId == database.merchants.BlockPay && itaCode == 1"
            >
              <div class="vx-col w-full">
                <label class="vs-input--label">{{
                  $t(resources.AccountNumber.i18n) ||
                    resources.AccountNumber.name
                }}</label>
                <v-select
                  id="accountId"
                  @keyup.native="getByText('accountId')"
                  v-model="accountId"
                  :clearable="true"
                  :options="accountDestinationList"
                  :reduce="item => item.id"
                  label="nameWithNumber"
                  name="destination"
                  v-validate="'required'"
                  v-on:input="getContactInfo(`${accountId}`)"
                  :hint="`${accountId}`"
                />
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('destination')"
                  >{{ errors.first("destination") }}</span
                >
              </div>
            </div>
            <div
              class="vx-col md:w-1/3 w-full mb-2"
              v-if="merchantId == database.merchants.BlockPay && itaCode == 1"
            >
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-user"
                  :label="$t(resources.Name.i18n) || resources.Name.name"
                  v-model="accountName"
                  disabled
                />
              </div>
            </div>
            <div
              class="vx-col md:w-1/3 w-full mb-2"
              v-if="merchantId == database.merchants.Payout"
            >
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-user"
                  type="number"
                  :label="
                    $t(resources.DistributorID.i18n) ||
                      resources.DistributorID.name
                  "
                  v-model="eWalletPayment.itaCodeWallet"
                  v-validate="'required'"
                  name="itaCodeWallet"
                />
              </div>
              <span
                class="text-danger text-sm"
                v-show="errors.has('itaCodeWallet')"
                >{{ errors.first("itaCodeWallet") }}</span
              >
            </div>
          </div>
        </div>

        <div class="mb-2" v-if="wayToPayId == database.merchantType.transfer">
          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full mb-2">
              <label class="vs-input--label">{{
                $t(resources.BankName.i18n) || resources.BankName.name
              }}</label>
              <v-select
                v-model="transferPayment.bankTypeId"
                :clearable="false"
                :options="bankType"
                :reduce="data => data.id"
                label="name"
                v-on:input="setAccountNumber(transferPayment.bankTypeId)"
                :hint="transferPayment.bankTypeId"
              />
            </div>
            <div class="vx-col md:w-1/2 w-full mb-2">
              <label class="vs-input--label">{{
                $t(resources.AccountNumber.i18n) || resources.AccountNumber.name
              }}</label>
              <v-select
                v-model="transferPayment.bankAccountId"
                :clearable="false"
                :options="accountNumberType"
                :reduce="data => data.id"
                label="account"
              />
            </div>
            <div class="vx-col md:w-1/2 w-full mb-2">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-hash"
                  size="small"
                  type="number"
                  :label="
                    $t(resources.DocumentNumber.i18n) ||
                      resources.DocumentNumber.name
                  "
                  v-model="transferPayment.documentId"
                  name="documentCard"
                  v-validate="'required|max:20|alpha_num'"
                />
              </div>
              <span
                class="text-danger text-sm"
                v-show="errors.has('documentCard')"
                >{{ errors.first("documentCard") }}</span
              >
            </div>
            <div class="vx-col md:w-1/2 w-full mb-2">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-hash"
                  size="small"
                  :label="
                    $t(resources.ReferenceCode.i18n) ||
                      resources.ReferenceCode.name
                  "
                  v-model="transferPayment.referenceCode"
                  name="referenceCode"
                  v-validate="'required|max:20|alpha_num'"
                />
              </div>
              <span
                class="text-danger text-sm"
                v-show="errors.has('referenceCode')"
                >{{ errors.first("referenceCode") }}</span
              >
            </div>
            <div class="vx-col md:w-1/2 w-full mb-2">
              <label class="btn btn-primary" style="margin-right: 80px">{{
                $t(resources.Attachment.i18n) || resources.Attachment.name
              }}</label>
              <vs-input type="file" class="w-full" @change="onFileSelected" />
            </div>
          </div>
        </div>

        <!-- <div class="vx-row mb-base" v-if="isCreditCard == 0">
          <div class="vx-col w-full">
            <h2 class="text-center font-semibold text-success">
              {{ $t(resources.Value.i18n) || resources.Value.name }}:
              <strong>{{ currency }} {{ totalToPay }}</strong>
            </h2>
          </div>
        </div> -->
        <vs-row vs-type="flex" vs-justify="center">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <vs-button
              class="mr-3 mb-2 w-full"
              @click.prevent="validInformation()"
              :disabled="payed"
              v-if="isCreditCard == 1"
              >{{ $t(resources.Save.i18n) || resources.Save.name }}</vs-button
            >
            <vs-button
              class="mr-3 mb-2 w-full"
              @click.prevent="validInformation()"
              :disabled="payed"
              v-else
              >{{ $t(resources.Pay.i18n) || resources.Pay.name }}</vs-button
            >
          </vs-col>
        </vs-row>
      </vx-card>
      <vx-card
        class="mt-base mb-2"
        :title="
          $t(resources.PaymentsReceived.i18n) || resources.PaymentsReceived.name
        "
      >
        <vs-table pagination max-items="10" :data="paymentList">
          <template slot="thead">
            <vs-th>{{
              $t(resources.WayToPay.i18n) || resources.WayToPay.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.Description.i18n) || resources.Description.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.Value.i18n) || resources.Value.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.TransactionNumber.i18n) ||
                resources.TransactionNumber.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.CreationDate.i18n) || resources.CreationDate.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.Status.i18n) || resources.Status.name
            }}</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].merchantType">{{
                data[indextr].paymentType
              }}</vs-td>
              <vs-td
                v-if="
                  data[indextr].paymentTypeId == 3 ||
                    data[indextr].paymentTypeId == 6
                "
                :data="data[indextr].cardNumber"
                >{{ data[indextr].cardType }} - ***{{
                  data[indextr].cardNumber
                }}</vs-td
              >

              <vs-td
                v-if="data[indextr].paymentTypeId == 2"
                :data="data[indextr].bankName"
                >{{ data[indextr].bankName }}</vs-td
              >
              <vs-td :data="data[indextr].value">{{
                data[indextr].value
              }}</vs-td>
              <vs-td :data="data[indextr].transactionNumber">{{
                data[indextr].transactionNumber
              }}</vs-td>
              <vs-td :data="data[indextr].paymentDate"
                >{{ data[indextr].paymentDate }}
                {{ data[indextr].paymentTime }}</vs-td
              >
              <vs-td :data="data[indextr].state">{{
                data[indextr].state
              }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vx-card>
    </div>
  </div>
</template>

<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import axios from "axios";
import auth from "@/assets/resources/auth.json";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import vSelect from "vue-select";
import utilities from "@/assets/resources/utilities.json";
import database from "@/assets/resources/enums/database.json";

export default {
  components: {
    vSelect
  },
  data() {
    return {
      database: database,
      utilities: utilities,
      auth: auth,
      resources: resources,
      alerts: alerts,
      email: "",
      cardType: "",
      cardTypeOption: [],
      cardOwner: "",
      expirationMonth: "",
      currentYear: 0,
      currentMonth: 0,
      expirationMonthOption: [],
      expirationYear: "",
      expirationYearOption: [],
      securityCode: "",
      zipCode: "",
      name: "",
      lastName: "",
      documentNumber: "",
      Telephone: "",
      address: "",
      totalToPay: 0,
      cardNumber: "",
      bookingCode: "",
      countryCode: "",
      currency: "",
      bookingId: 0,
      supplierId: 0,
      productId: 0,
      countryOption: [],
      stateCountryOption: [],
      city: "",
      cityOption: [],
      wayToPayId: "",
      contactId: 0,
      paymentList: [],
      stateId: "",
      countryId: "",
      payed: false,
      totalPayment: 0,
      totalValue: 0,
      bookingReference: "",
      accountNumber: "",
      bookingDetailId: 0,
      wayToPayList: [],
      merchantId: "",
      walletOption: [],
      accountWalletId: "",
      accountWalletOption: "",
      eWalletPayment: {},
      itaContactId: "",
      totalToPayConfig: 0,
      totalOtherValue: 0,
      itaEmail: "",
      transferPayment: {},
      accountNumberType: [],
      installmentNumberOption: [],
      installmentNumber: 1,
      itaCode: 0,
      accountName: "",
      accountId: "",
      accountDestinationList: [],
      merchantCode: "",
      bookingSourceId: ""
    };
  },
  async created() {
    this.$vs.loading();
    this.language = this.$route.params.language;
    this.bookingId = this.$route.params.bookingId;
    this.isCreditCard = this.$route.params.isCreditCard;
    this.bookingDetailId = this.$route.params.bookingDetailId;

    await this.getToken();
    await this.getCountries();
    await this.getYearsCrediCard();
    await this.getDetail();
    await this.getPayments();
    this.$vs.loading.close();
  },
  methods: {
    validInformation() {
      this.$validator.validateAll().then(async result => {
        if (result) {
          if (this.wayToPayId == this.database.merchantType.transfer)
            await this.payTransfer();
          else await this.payBooking();
        } else {
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: this.$i18n.t("MsgRequiredField"),
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      });
    },

    async getToken() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Token`,
        data: {
          username: "a.gomez@visiontravel.net",
          password: "4n4M4r14.321"
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          localStorage.setItem("tokenVT", result.data.token);
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCountries() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Country/${this.language}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.countryOption = [];
          } else {
            this.countryOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getYearsCrediCard() {
      var currentDate = new Date()
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "/");
      this.currentYear = parseInt(currentDate.split("/")[0]);
      this.currentMonth = parseInt(currentDate.split("/")[1]);

      this.expirationYearOption = [];
      this.expirationMonthOption = [];
      var iYear = 0;
      for (let item = this.currentYear; iYear <= 20; item++) {
        iYear++;
        this.expirationYearOption.push(item);
      }

      for (let item = this.currentMonth; item <= 12; item++) {
        this.expirationMonthOption.push(item);
      }
    },

    async getDetail() {
      let _searchKeys = { bookingid: this.bookingId, onlyDetail: true };

      if (this.bookingDetailId != undefined && this.bookingDetailId != 0)
        _searchKeys["bookingDetailId"] = this.bookingDetailId;

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Booking/ListByFilters`,
        data: {
          searchKeys: _searchKeys,
          page: 1,
          rows: 1,
          language: this.language
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (result.data.success) {
            let response = result.data.data.find(x => x.isMain);

            this.bookingDetailId = response.bookingDetailId;
            this.bookingCode = response.bookingCode;
            this.countryCode = response.countryCode;
            this.currency = response.currencyCode;
            this.supplierId = response.supplierId;
            this.productId = response.productId;
            this.contactId = response.contactId;
            this.itaContactId = response.itaContactId;
            this.accountNumber = `${response.itaCode} - ${response.itaName} ${response.itaLastName}`;
            this.itaEmail = response.itaEmail;
            this.itaCode = response.itaCode;
            this.merchantCode = response.merchantCode;
            this.bookingSourceId = response.bookingSourceId;

            let _bookings = "";
            result.data.data
              .filter(x => x.stageId != this.database.stage.Cancellations && x.stageId != this.database.stage.Commission && x.stageId != this.database.stage.ManagmentFinished)
              .forEach(item => {
                this.totalValue += item.totalComplete;
                _bookings += `, ${item.bookingReference}`;

                if (item.otherValue != null && item.otherValue != 0) {
                  this.totalToPay += item.otherValue;
                  this.totalOtherValue += item.otherValue;
                } else {
                  if (
                    item.supplierId == 1 &&
                    item.productId == this.database.products.AIR &&
                    item.countryCode == "ARG"
                  )
                    this.totalToPay +=
                      item.fee +
                      item.feeCustomized +
                      item.merchant +
                      item.agentCommissionValue +
                      item.discount;
                  else if (
                    (item.supplierId == 1 || item.supplierId == 149 || item.supplierId == 168 || item.supplierId == 232 || item.supplierId == 235) &&
                    item.productId == this.database.products.AIR &&
                    item.countryCode != "ARG"
                  )
                    this.totalToPay +=
                      item.fee +
                      item.merchant +
                      item.feeCustomized +
                      item.agentCommissionValue +
                      item.discount;
                  else if (
                    item.supplierId == 2 &&
                    item.productId == this.database.products.AIR
                  )
                    this.totalToPay += item.totalComplete;
                  else this.totalToPay += item.totalComplete;
                }
              });
            this.totalToPay = this.totalToPay.toFixed(2);
            this.totalToPayConfig = this.totalToPay;
            this.bookingReference = _bookings.substring(1);

            await this.getWayToPay();
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async payTransfer() {
      this.$vs.loading();

      let formData = new FormData();
      formData.set("AccountId", this.transferPayment.bankAccountId);
      formData.set("Amount", this.totalToPay);
      formData.set("ApplicationId", this.database.application.FOC);
      formData.set("ContactId", this.itaContactId);
      formData.set("CountryCode", this.countryCode);
      formData.set("CreatedBy", this.itaEmail);
      formData.set("CurrencyCode", this.currency);
      formData.set("DocumentId", this.transferPayment.documentId);
      formData.set("Identifier", this.bookingId);
      formData.set("Language", this.language.toUpperCase());
      formData.set("ProductId", this.productId);
      formData.set("ReferenceCode", this.transferPayment.referenceCode);
      formData.set("SupplierId", this.supplierId);
      formData.set("TransferType", this.utilities.entity.booking);

      formData.append(
        "File",
        this.transferPayment.file,
        this.transferPayment.file.Name
      );

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Payment/PayTransfer`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (this.isCreditCard) {
            if (!result.data.data.success) {
              this.$vs.dialog({
                color: "warning",
                title: this.$i18n.t("Alert"),
                text: this.$i18n.t("MsgNotSaved")
              });
              this.payed = false;
              this.$vs.loading.close();
            } else {
              this.payed = true;
              this.$vs.dialog({
                color: "success",
                title: this.$i18n.t("Success"),
                text: `${this.$i18n.t(
                  "MsgSaved"
                )}. Ahora puedes cerrar esta página, nuestros asesores continuarán con el tráamite de tu reserva.`
              });
              this.$vs.loading.close();
            }
          } else {
            if (!result.data.data.success) {
              this.$vs.dialog({
                color: "warning",
                title: this.$i18n.t("Alert"),
                text: this.$i18n.t("MsgPaymentNotMade")
              });
              this.payed = false;
              this.$vs.loading.close();
            } else {
              this.payed = true;
              this.$vs.dialog({
                color: "success",
                title: this.$i18n.t("Success"),
                text: `${this.$i18n.t(
                  "MsgPaymentMade"
                )}. Ahora puedes cerrar esta página, nuestros asesores continuarán con el tráamite de tu reserva.`
              });
              this.$vs.loading.close();
            }
          }
          await this.getPayments();
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
          this.$vs.loading.close();
        }
      );
    },

    async payBooking() {
      this.$vs.loading();

      let _data = {
        applicationId: this.database.application.FOC,
        contactId: this.itaContactId,
        createdBy:
          this.wayToPayId == this.database.merchantType.EWallet
            ? this.itaEmail
            : this.email,
        currencyCode: this.currency,
        countryCode: this.countryCode,
        identifier: this.bookingId,
        language: this.language.toUpperCase(),
        productId: this.productId,
        supplierId: this.supplierId,
        amount: this.totalToPay,
        paymentType: this.utilities.entity.booking,
        wayToPayId: this.wayToPayId,
        payment: {}
      };
      if (
        this.wayToPayId == this.database.merchantType.creditCard ||
        this.wayToPayId == this.database.merchantType.offline
      ) {
        _data.payment = {
          creditCardTypeId: this.cardType,
          contactId: this.contactId,
          cardNumber: this.cardNumber,
          securityCode: this.securityCode,
          expirationMonth: this.expirationMonth,
          expirationYear: this.expirationYear,
          documentNumber: this.documentNumber,
          name: this.name,
          lastName: this.lastName,
          address: this.address,
          phone: this.Telephone,
          zipCode: this.zipCode,
          email: this.email,
          cityId: this.city,
          installmentNumber: this.installmentNumber
        };
      } else if (this.wayToPayId == this.database.merchantType.EWallet) {
        if (this.merchantId == this.database.merchants.Payout) {
          _data.payment.charge = false;
          _data.payment.userName = this.eWalletPayment.itaCodeWallet;
        } else if (this.merchantId == this.database.merchants.BlockPay) {
          _data.payment.charge = true;
          _data.payment.userName = this.accountWalletId;
          _data.payment.accountTypeId = this.accountWalletOption.find(
            x => x.id == this.accountWalletId
          ).typeAccountId;
        }

        _data.payment.WalletId = this.merchantId;
        _data.payment.amount = this.totalToPay;
        _data.payment.language = this.$i18n.locale;
      }

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Payment/PayCreditCard`,
        data: _data,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (this.isCreditCard) {
            if (!result.data.data.success) {
              this.$vs.dialog({
                color: "warning",
                title: this.$i18n.t("Alert"),
                text: this.$i18n.t("MsgNotSaved")
              });
              this.payed = false;
              this.$vs.loading.close();
            } else {
              this.payed = true;
              this.$vs.dialog({
                color: "success",
                title: this.$i18n.t("Success"),
                text: `${this.$i18n.t(
                  "MsgSaved"
                )}. Ahora puedes cerrar esta página, nuestros asesores continuarán con el tráamite de tu reserva.`
              });
              this.$vs.loading.close();
            }
          } else {
            if (!result.data.data.success) {
              this.$vs.dialog({
                color: "warning",
                title: this.$i18n.t("Alert"),
                text: this.$i18n.t("MsgPaymentNotMade")
              });
              this.payed = false;
              this.$vs.loading.close();
            } else {
              this.payed = true;
              this.$vs.dialog({
                color: "success",
                title: this.$i18n.t("Success"),
                text: `${this.$i18n.t(
                  "MsgPaymentMade"
                )}. Ahora puedes cerrar esta página, nuestros asesores continuarán con el tráamite de tu reserva.`
              });
              this.$vs.loading.close();
            }
          }
          await this.getPayments();
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
          this.$vs.loading.close();
        }
      );
    },

    expirationYearChange(event) {
      var yearSelect = event;
      this.expirationMonthOption = [];

      if (this.currentYear === yearSelect) {
        for (let item = this.currentMonth; item <= 12; item++) {
          this.expirationMonthOption.push(item);
        }
      } else {
        for (let item = 1; item <= 12; item++) {
          this.expirationMonthOption.push(item);
        }
      }
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },

    onlyText($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (
        (keyCode < 65 || keyCode > 122 || keyCode == 0) &&
        keyCode !== 241 &&
        keyCode !== 32
      ) {
        $event.preventDefault();
      }
    },

    async getStateByCountry(item) {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }State/GetByCountry/${this.language.toUpperCase()}/${item}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) this.stateCountryOption = result.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCityByState(item) {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }City/GetByState/${this.language.toUpperCase()}/${item}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) this.cityOption = result.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getWayToPay() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}WayToPay/GetAvailable`,
        data: {
          applicationId: this.database.application.FOC,
          countryCode: this.countryCode,
          productId: this.productId,
          supplierId: this.supplierId,
          language: this.language.toUpperCase(),
          currencyCode: this.currency,
          contactId: this.itaContactId
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.cardTypeOption = [];
          } else {
            this.wayToPayList = result.data.wayToPay;

            if (this.isCreditCard == 0) {
              this.wayToPayList = result.data.wayToPay.filter(
                x => x.wayToPayId !== 6
              );
              this.wayToPayId = this.wayToPayList[0].wayToPayId;
              this.setWayToPay(this.wayToPayId);
            } else {
              this.wayToPayId = 6;
              this.setWayToPay(6);
            }

            // if(["", "BE", "TF"].includes(this.merchantCode) && this.supplierId === 2 && [8, 23].includes(this.bookingSourceId)) {
            //     this.wayToPayList = this.wayToPayList.filter(
            //       x => x.wayToPayId !== 3
            //   );
            //   this.wayToPayId = this.wayToPayList[0].wayToPayId;
            //   this.setWayToPay(this.wayToPayList[0].wayToPayId)
            // }
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
          this.cardTypeOption = [];
        }
      );
    },

    setWayToPay(item) {
      let _merchantType = this.wayToPayList.filter(x => x.wayToPayId == item);

      if (item == 1) {
        this.walletOption = _merchantType[0].options[0].wallets.map(x => x);
        if (this.totalOtherValue > 0) this.totalToPay = this.totalOtherValue;
        else this.totalToPay = this.totalValue;
      }
      if (item == 2) {
        this.bankType = _merchantType[0].options[0].banks.map(x => x);
        this.totalToPay = this.totalValue;
      }
      if (item == 8) {
        this.totalToPay = (this.totalValue * 1.05).toFixed(2);
      }
      if (item == 3 || item == 6) {
        this.cardTypeOption = _merchantType[0].options.map(x => x);
        if (this.totalOtherValue > 0) this.totalToPay = this.totalOtherValue;
        else this.totalToPay = this.totalToPayConfig;
      }
    },

    setAccountNumber(item) {
      let _account = this.bankType
        .filter(x => x.id == item)
        .map(x => x.accounts);
      this.accountNumberType = _account[0].map(x => x);
    },

    setAccountWallet(item) {
      let _accounts = this.walletOption.find(x => x.walletId == item).accounts;
      this.accountWalletOption = _accounts;
    },

    onFileSelected(event) {
      this.transferPayment.file = event.target.files[0];
    },

    onChangeCardType(event) {
      this.cardNumber = "";
      this.securityCode = "";

      let _creditcard = this.cardTypeOption.find(x => x.cardId == event);
      this.installmentNumberOption = _creditcard.installmentNumber;

      if (event != 1) {
        let chosenCard = this.cardTypeOption.filter(x => x.cardId == event);
        let cardNumberLength = chosenCard[0].length[0];
        let securityCodeLength = chosenCard[0].maxCvv[0];

        document
          .getElementById("cardNumber")
          .setAttribute("maxlength", cardNumberLength);
        document
          .getElementById("securityCode")
          .setAttribute("maxlength", securityCodeLength);
      } else {
        document.getElementById("cardNumber").setAttribute("maxlength", "16");
        document.getElementById("securityCode").setAttribute("maxlength", "3");
      }
    },

    async getPayments() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Payment/ByBooking/${this.language.toUpperCase()}/${this.bookingId}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data.success) {
            this.paymentList = result.data.data;
          } else {
            this.paymentList = [];
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getByText(destination) {
      let getElementId;
      if (destination == "accountId")
        getElementId = document.getElementById("accountId");

      let text = getElementId.__vue__.search;

      if (text.length >= 3) {
        await axios({
          method: "GET",
          url: `${
            process.env.VUE_APP_APIVT
          }Account/GetByText/${this.$i18n.locale.toUpperCase()}/${text}`,
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tokenVT")}`
          }
        }).then(
          result => {
            if (!result.data.success) {
              this.$vs.notify({
                title: this.$i18n.t("Alert"),
                text: this.$i18n.t("MsgNotFoundInfo"),
                color: "warning",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle"
              });
              this.accountDestinationList = [];
            } else {
              if (destination == "accountId")
                this.accountDestinationList = result.data.data;
            }
          },
          error => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError") + "<br/>" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        );
      }
    },

    getContactInfo() {
      let _data = this.accountDestinationList.find(x => x.id == this.accountId);
      this.accountName = _data.contactName;
    }
  }
};
</script>
