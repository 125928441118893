<template>
  <div>
    <div id="ag-grid-demo">
      <vx-card>
        <h4 class="font-semibold mb-base">
          {{ $t(resources.Airlines.i18n) || resources.Airlines.name }}
        </h4>
        <div class="flex flex-wrap justify-between items-center">
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2">
                  {{
                    currentPage * paginationPageSize - (paginationPageSize - 1)
                  }}
                  -
                  {{
                    dataList.length - currentPage * paginationPageSize > 0
                      ? currentPage * paginationPageSize
                      : dataList.length
                  }}
                  of {{ dataList.length }}
                </span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
          <div
            class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
          >
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              :placeholder="$t(resources.Search.i18n) || resources.Search.name"
            />
            <vs-button
              class="mb-4 md:mb-0 mr-1"
              @click="gridApi.exportDataAsCsv()"
            >
              {{ $t(resources.ExportAs.i18n) || resources.ExportAs.name }}
            </vs-button>
            <vs-button class="mb-4 md:mb-0 mr-1" @click="openPopup()">
              {{ $t(resources.Create.i18n) || resources.Create.name }}
            </vs-button>
          </div>
        </div>

        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="dataList"
          rowSelection="single"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          @row-clicked="onRowClicked"
        ></ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
    <!--Popup Update-->
    <div class="centerx">
      <vs-popup
        classContent="popup overFlow"
        :title="action"
        :active.sync="popup"
      >
        <div class="vx-row mb-4">
          <div class="vx-col w-full mb-2">
            <vs-input
              type="text"
              class="w-full"
              :label="$t(resources.Name.i18n) || resources.Name.name"
              v-model="name"
            />
          </div>

          <div class="vx-col w-full mb-2">
            <vs-input
              type="text"
              class="w-full"
              :label="$t(resources.ShortName.i18n) || resources.ShortName.name"
              v-model="shortName"
            />
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <ul class="vx-col w-full mt-4">
              <li>
                <vs-checkbox v-model="foidRequired">FOID</vs-checkbox>
              </li>
            </ul>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <ul class="vx-col w-full mt-4">
              <li>
                <vs-checkbox v-model="active">{{
                  $t(resources.Active.i18n) || resources.Active.name
                }}</vs-checkbox>
              </li>
            </ul>
          </div>
        </div>
        <vs-divider position="center"
          ><strong>{{
            $t(resources.Settings.i18n) || resources.Settings.name
          }}</strong></vs-divider
        >
        <div class="vx-row">
          <div class="vx-col w-full md:w-1/2 mb-2">
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                $t(resources.SupplierName.i18n) || resources.SupplierName.name
              }}</label>
              <v-select
                v-model="supplierDto.supplierId"
                :clearable="true"
                :options="suppliersList"
                :reduce="x => x.id"
                label="name"
              />
            </div>
          </div>
          <div class="vx-col md:w-1/2 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                :label="$t(resources.Code.i18n) || resources.Code.name"
                v-model="supplierDto.code"
              />
            </div>
          </div>
          <div class="vx-col w-full md:w-1/6 mb-2 mt-2">
            <vs-button color="primary" type="filled" @click="addSupplier()">
              {{ $t(resources.Add.i18n) || resources.Add.name }}
            </vs-button>
          </div>
        </div>

        <div class="vx-row mb-base">
          <div class="vx-col w-full mb-2">
            <vs-table :data="suppliersConfig">
              <template slot="thead">
                <vs-th>{{
                  $t(resources.Supplier.i18n) || resources.Supplier.name
                }}</vs-th>
                <vs-th>{{
                  $t(resources.Code.i18n) || resources.Code.name
                }}</vs-th>
                <vs-th>{{
                  $t(resources.Options.i18n) || resources.Options.name
                }}</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="data[indextr].supplierName">{{
                    data[indextr].supplierName
                  }}</vs-td>
                  <vs-td :data="data[indextr].code">{{
                    data[indextr].code
                  }}</vs-td>
                  <vs-td>
                    <vx-tooltip
                      :text="
                        $t(resources.Disabled.i18n) || resources.Disabled.name
                      "
                      class="w-5 h-5 mr-1"
                    >
                      <a @click="deleteSupplierList(data[indextr])">
                        <feather-icon
                          icon="XIcon"
                          svgClasses="fill-current text-danger"
                          class="w-5 h-5 mr-1"
                        ></feather-icon>
                      </a>
                    </vx-tooltip>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
        <div class="vx-col mb-2 mt-5" style="text-align: right">
          <vs-button color="primary" type="filled" @click="validInformation()">
            {{ $t(resources.Save.i18n) || resources.Save.name }}
          </vs-button>
        </div>
      </vs-popup>
    </div>
  </div>
</template>
<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import vSelect from "vue-select";
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
    vSelect
  },
  data() {
    return {
      resources: resources,
      alerts: alerts,
      language: this.$i18n.locale,
      popup: false,
      emailSession: JSON.parse(localStorage.getItem("userInfo")).email,
      dataList: [],
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      action: this.$i18n.t("Create"),
      id: 0,
      name: "",
      shortName: "",
      active: "",
      foidRequired: "",
      supplierDto: {},
      suppliersList: [],
      suppliersConfig: [],
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: "#",
          field: "id",
          hide: true
        },
        {
          headerName: this.$i18n.t("Name"),
          field: "name",
          filter: true,
          width: 450
        },
        {
          headerName: this.$i18n.t("ShortName"),
          field: "shortName",
          filter: true,
          width: 450
        },
        {
          headerName: "FOID",
          field: "foidRequired",
          width: 110,
          cellRenderer: function(event) {
            let object = "";
            if (event.node.data.foidRequired) {
              object =
                '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-success), 1);">check_circle</i>';
            } else
              object =
                '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-danger), 1);">highlight_off</i>';

            return object;
          }
        },
        {
          headerName: this.$i18n.t("Status"),
          field: "active",
          width: 110,
          cellRenderer: function(event) {
            let object = "";
            if (event.node.data.active) {
              object =
                '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-success), 1);">check_circle</i>';
            } else
              object =
                '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-danger), 1);">highlight_off</i>';

            return object;
          }
        }
      ]
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("level", null);
      } else this.gridOptions.columnApi.setColumnPinned("level", "left");
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 20;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    }
  },
  async created() {
    await this.get();
    await this.getSupplier();
  },
  methods: {
    openPopup() {
      this.id = "";
      this.name = "";
      this.shortName = "";
      this.active = true;
      this.foidRequired = false;
      this.supplierDto = {};
      this.suppliersConfig = [];
      this.action = this.$i18n.t("Create");
      this.popup = true;
    },

    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    async onRowClicked(event) {
      this.id = event.node.data.id;
      this.name = event.node.data.name;
      this.shortName = event.node.data.shortName;
      this.active = event.node.data.active;
      this.foidRequired = event.node.data.foidRequired;
      this.suppliersConfig = this.dataList.find(
        x => x.id == event.node.data.id
      ).supplierAirlines;
      this.action = this.$i18n.t("Update");
      this.popup = true;
    },

    async validInformation() {
      if (this.id == undefined || this.id == null || this.id == 0)
        await this.create();
      else await this.update();
    },

    async get() {
      this.$vs.loading();
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Airline/Get`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.dataList = result.data;
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.dataList = [];
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async getSupplier() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Supplier`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.suppliersList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    deleteSupplierList(item) {
      let _data = this.suppliersConfig.filter(
        x => x.supplierId == item.supplierId && x.code == item.code
      );

      if (_data != undefined && _data != null) {
        let _index = this.suppliersConfig.findIndex(
          x => x.supplierId == item.supplierId && x.code == item.code
        );
        if (_index > -1) this.suppliersConfig.splice(_index, 1);
      }
    },

    addSupplier() {
      let suppliersConfig = this.suppliersConfig.filter(
        x => x.supplierId == this.supplierDto.supplierId
      );

      if (suppliersConfig.length == 0) {
        let name = this.suppliersList.find(
          x => x.id == this.supplierDto.supplierId
        ).name;

        this.suppliersConfig.push({
          airlineId: 0,
          supplierId: this.supplierDto.supplierId,
          supplierName: name,
          code: this.supplierDto.code,
          active: true
        });
      }
    },

    async create() {
      this.$vs.loading();

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Airline/Create`,
        data: {
          name: this.name,
          shortName: this.shortName,
          active: this.active,
          foidRequired: this.foidRequired,
          createdBy: this.emailSession,
          supplierAirlines: this.suppliersConfig,
          language: this.$i18n.locale.toUpperCase()
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (result.data.success) {
            await this.get();
            this.popup = false;

            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async update() {
      this.$vs.loading();

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Airline/Update`,
        data: {
          id: this.id,
          name: this.name,
          shortName: this.shortName,
          active: this.active,
          foidRequired: this.foidRequired,
          language: this.$i18n.locale.toUpperCase()
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (result.data.success) {
            await this.get();
            this.popup = false;

            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  }
};
</script>
