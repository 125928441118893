<template>
  <div>
    <vx-card
      class="mb-2"
      :title="$t(resources.Detail.i18n) || resources.Detail.name"
    >
      <div class="vx-row">
        <div class="vx-col md:w-1/4 w-full mb-4">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              :label="$t(resources.Name.i18n) || resources.Name.name"
              v-model="hotelDto.name"
              name="name"
              v-validate="'required|min:10|max:100'"
            />
            <span class="text-danger text-sm" v-show="errors.has('name')">{{
              errors.first("name")
            }}</span>
          </div>
        </div>
        <div class="vx-col w-full md:w-1/4 mb-2">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.Category.i18n) || resources.Category.name
            }}</label>
            <v-select
              v-model="category"
              :clearable="true"
              :options="categoryList"
              :reduce="category => category.id"
              label="nameEs"
              name="category"
              v-validate="'required'"
            />
            <span class="text-danger text-sm" v-show="errors.has('category')">{{
              errors.first("category")
            }}</span>
          </div>
        </div>
        <div class="vx-col w-full md:w-1/4 mb-2">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.Accommodation.i18n) || resources.Accommodation.name
            }}</label>
            <v-select
              v-model="accommodation"
              :clearable="true"
              :options="accommodationTypeList"
              :reduce="accommodation => accommodation.id"
              label="name"
              name="accommodation"
              v-validate="'required'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('accommodation')"
              >{{ errors.first("accommodation") }}</span
            >
          </div>
        </div>
        <div class="vx-col md:w-1/4 w-full">
          <label class="vs-input--label">{{
            $t(resources.Country.i18n) || resources.Country.name
          }}</label>
          <v-select
            v-model="countryId"
            :clearable="false"
            :options="countryList"
            :reduce="country => country.id"
            label="name"
            v-on:input="getStateByCountry(`${countryId}`)"
            :hint="`${countryId}`"
            name="country"
            v-validate="'required'"
          />
          <span class="text-danger text-sm" v-show="errors.has('country')">{{
            errors.first("country")
          }}</span>
        </div>
        <div class="vx-col md:w-1/4 w-full mb-2">
          <label class="vs-input--label">{{
            $t(resources.State.i18n) || resources.State.name
          }}</label>
          <v-select
            v-model="stateId"
            :clearable="false"
            :options="stateList"
            :reduce="state => state.id"
            label="name"
            v-on:input="getCityByState(`${stateId}`)"
            :hint="`${stateId}`"
            name="state"
            v-validate="'required'"
          />
          <span class="text-danger text-sm" v-show="errors.has('state')">{{
            errors.first("state")
          }}</span>
        </div>
        <div class="vx-col md:w-1/4 w-full mb-2">
          <label class="vs-input--label">{{
            $t(resources.Destination.i18n) || resources.Destination.name
          }}</label>
          <v-select
            v-model="destinationId"
            :clearable="false"
            :options="destinationOption"
            :reduce="item => item.id"
            label="name"
            name="destination"
            v-validate="'required'"
          />
          <span
            class="text-danger text-sm"
            v-show="errors.has('destination')"
            >{{ errors.first("destination") }}</span
          >
        </div>
        <div class="vx-col md:w-1/4 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              :label="$t(resources.Latitude.i18n) || resources.Latitude.name"
              v-model="hotelDto.latitude"
              name="latitude"
              v-validate="'required|min:2|max:10'"
            />
            <span class="text-danger text-sm" v-show="errors.has('latitude')">{{
              errors.first("latitude")
            }}</span>
          </div>
        </div>
        <div class="vx-col md:w-1/4 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              :label="$t(resources.Longitude.i18n) || resources.Longitude.name"
              v-model="hotelDto.longitude"
              name="longitude"
              v-validate="'required|min:2|max:10'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('longitude')"
              >{{ errors.first("longitude") }}</span
            >
          </div>
        </div>
        <div class="vx-col md:w-1/4 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              :label="$t(resources.Address.i18n) || resources.Address.name"
              v-model="hotelDto.address"
              v-validate="{
                required: true,
                regex: /^([a-zA-Z0-9 ]+)$/
              }"
              name="address"
            />
            <span class="text-danger text-sm" v-show="errors.has('address')">{{
              errors.first("address")
            }}</span>
          </div>
        </div>
        <div class="vx-col md:w-1/4 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              :label="$t(resources.Email.i18n) || resources.Email.name"
              v-model="hotelDto.email"
              v-validate="'required|max:100|email'"
              name="email"
            />
            <span class="text-danger text-sm" v-show="errors.has('email')">{{
              errors.first("email")
            }}</span>
          </div>
        </div>
        <div class="vx-col md:w-1/4 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              :label="
                $t(resources.StarNumber.i18n) || resources.StarNumber.name
              "
              v-model="hotelDto.numberStars"
              v-validate="'required|decimal:1'"
              name="numberStars"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('numberStars')"
              >{{ errors.first("numberStars") }}</span
            >
          </div>
        </div>
        <div class="vx-col md:w-1/4 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              :label="$t(resources.Telephone.i18n) || resources.Telephone.name"
              v-model="hotelDto.phone"
              v-validate="'required|max:40'"
              name="phone"
            />
            <span class="text-danger text-sm" v-show="errors.has('phone')">{{
              errors.first("phone")
            }}</span>
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full" style="padding-top: 10px">
          <div class="vx-col w-full">
            <vs-textarea
              class="w-full"
              rows="4"
              :label="
                $t(resources.DescriptionES.i18n) || resources.DescriptionES.name
              "
              v-model="hotelDto.descriptionEs"
              v-validate="'required|min:30|max:5000'"
              name="descriptionEs"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('descriptionEs')"
              >{{ errors.first("descriptionEs") }}</span
            >
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full" style="padding-top: 10px">
          <div class="vx-col w-full">
            <vs-textarea
              class="w-full"
              rows="4"
              :label="
                $t(resources.DescriptionEN.i18n) || resources.DescriptionEN.name
              "
              v-model="hotelDto.descriptionEn"
              v-validate="'required|min:30|max:5000'"
              name="descriptionEn"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('descriptionEn')"
              >{{ errors.first("descriptionEn") }}</span
            >
          </div>
        </div>
        <div class="vx-col md:w-1/6 w-full mb-2">
          <ul class="vx-col w-full" style="margin-top: 20px">
            <li>
              <vs-checkbox v-model="hotelDto.isFeatured">{{
                $t(resources.Outstanding.i18n) || resources.Outstanding.name
              }}</vs-checkbox>
            </li>
          </ul>
        </div>
        <div class="vx-col md:w-1/6 w-full mb-2">
          <ul class="vx-col w-full" style="margin-top: 20px">
            <li>
              <vs-checkbox v-model="hotelDto.active">{{
                $t(resources.Active.i18n) || resources.Active.name
              }}</vs-checkbox>
            </li>
          </ul>
        </div>
        <vs-divider position="center"
          ><strong>{{
            $t(resources.Supplier.i18n) || resources.Supplier.name
          }}</strong></vs-divider
        >
        <div class="vx-col w-full md:w-1/4 mb-2">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.SupplierName.i18n) || resources.SupplierName.name
            }}</label>
            <v-select
              v-model="supplierAdd.supplierId"
              :clearable="true"
              :options="supplierList"
              :reduce="supplierCreate => supplierCreate.id"
              label="name"
            />
          </div>
        </div>
        <div class="vx-col md:w-1/4 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              :label="$t(resources.Code.i18n) || resources.Code.name"
              v-model="supplierAdd.code"
            />
          </div>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2 mt-5">
          <vs-button color="primary" type="filled" @click="addSupplier()">
            {{ $t(resources.Add.i18n) || resources.Add.name }}
          </vs-button>
        </div>
        <div class="vx-col w-full">
          <vs-table :data="supplierHotels">
            <template slot="thead">
              <vs-th>{{
                $t(resources.SupplierName.i18n) || resources.SupplierName.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.Code.i18n) || resources.Code.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.Options.i18n) || resources.Options.name
              }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].name">{{
                  data[indextr].name
                }}</vs-td>
                <vs-td :data="data[indextr].name">{{
                  data[indextr].code
                }}</vs-td>
                <vs-td :data="data[indextr].name">
                  <vx-tooltip
                    :text="
                      $t(resources.Disabled.i18n) || resources.Disabled.name
                    "
                    class="w-5 h-5 mr-1"
                  >
                    <a @click="updateSupplier(data[indextr].id)">
                      <feather-icon
                        icon="XIcon"
                        svgClasses="fill-current text-danger"
                        class="w-5 h-5 mr-1"
                      ></feather-icon>
                    </a>
                  </vx-tooltip>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
        <div class="vx-col w-full text-right mt-2">
          <vs-button color="primary" type="filled" @click.prevent="validation">
            {{ $t(resources.Save.i18n) || resources.Save.name }}
          </vs-button>
        </div>
      </div>
    </vx-card>

    <div id="ag-grid-demo">
      <vx-card class="mt-4">
        <h4 class="font-semibold mb-base">
          {{ $t(resources.Accommodation.i18n) || resources.Accommodation.name }}
        </h4>
        <div class="flex flex-wrap justify-between items-center">
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2">
                  {{
                    currentPage * paginationPageSize - (paginationPageSize - 1)
                  }}
                  -
                  {{
                    accommodationList.length -
                      currentPage * paginationPageSize >
                    0
                      ? currentPage * paginationPageSize
                      : accommodationList.length
                  }}
                  of {{ accommodationList.length }}
                </span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
          <div
            class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
          >
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              :placeholder="$t(resources.Search.i18n) || resources.Search.name"
            />
            <vs-button
              class="mb-4 md:mb-0 mr-1"
              @click="gridApi.exportDataAsCsv()"
            >
              {{ $t(resources.ExportAs.i18n) || resources.ExportAs.name }}
            </vs-button>
            <vs-button class="mb-4 md:mb-0 mr-1" @click="popupCreate = true">
              {{ $t(resources.Create.i18n) || resources.Create.name }}
            </vs-button>
          </div>
        </div>

        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="accommodationList"
          rowSelection="single"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          @row-clicked="onRowClicked"
        ></ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>

    <!--Popup Create-->
    <div class="centerx">
      <vs-popup
        classContent="popup overFlow"
        :title="titlepopupCreate"
        :active.sync="popupCreate"
      >
        <div class="vx-row mb-4">
          <div class="vx-col md:w-1/2 w-full mb-2">
            <vs-input
              type="text"
              class="w-full"
              :label="$t(resources.NameEs.i18n) || resources.NameEs.name"
              v-model="createDto.nameEs"
              v-validate="'min:3|max:49'"
              name="nameEs"
            />
            <span class="text-danger text-sm" v-show="errors.has('nameEs')">{{
              errors.first("nameEs")
            }}</span>
          </div>
          <div class="vx-col md:w-1/2 w-full mb-2">
            <vs-input
              type="text"
              class="w-full"
              maxlength="50"
              :label="$t(resources.NameEn.i18n) || resources.NameEn.name"
              v-model="createDto.nameEn"
              v-validate="'min:3|max:49'"
              name="nameEn"
            />
            <span class="text-danger text-sm" v-show="errors.has('nameEn')">{{
              errors.first("nameEn")
            }}</span>
          </div>
          <div class="vx-col md:w-1/2 w-full mb-2">
            <vs-input
              type="number"
              class="w-full"
              :label="
                $t(resources.AdultAmount.i18n) || resources.AdultAmount.name
              "
              v-model="createDto.adultAmount"
              name="adultAmount"
              v-validate="'min_value:1|max_value:20'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('adultAmount')"
              >{{ errors.first("adultAmount") }}</span
            >
          </div>
          <div class="vx-col md:w-1/2 w-full mb-2">
            <vs-input
              type="number"
              class="w-full"
              :label="
                $t(resources.ChildAmount.i18n) || resources.ChildAmount.name
              "
              v-model="createDto.childAmount"
              name="childAmount"
              v-validate="'min_value:0|max_value:10'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('childAmount')"
              >{{ errors.first("childAmount") }}</span
            >
          </div>
          <div class="vx-col md:w-1/2 w-full mb-2">
            <vs-input
              type="number"
              class="w-full"
              :label="
                $t(resources.InfantAmount.i18n) || resources.InfantAmount.name
              "
              v-model="createDto.infantAmount"
              name="infantAmount"
              v-validate="'min_value:0|max_value:10'"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('infantAmount')"
              >{{ errors.first("infantAmount") }}</span
            >
          </div>
          <div class="vx-col w-full">
            <label>{{
              $t(resources.DescriptionES.i18n) || resources.DescriptionES.name
            }}</label>
            <vs-textarea
              v-model="createDto.descriptionEs"
              name="descriptionEs"
              v-validate="'min:5|max:300'"
              rows="7"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('descriptionEs')"
              >{{ errors.first("descriptionEs") }}</span
            >
          </div>
          <div class="vx-col w-full">
            <label>{{
              $t(resources.DescriptionEN.i18n) || resources.DescriptionEN.name
            }}</label>
            <vs-textarea
              v-model="createDto.descriptionEn"
              name="descriptionEn"
              v-validate="'min:5|max:300'"
              rows="7"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('descriptionEn')"
              >{{ errors.first("descriptionEn") }}</span
            >
          </div>
        </div>
        <div class="vx-col mb-2" style="text-align: right">
          <vs-button
            color="primary"
            type="filled"
            @click="validateAccomodation()"
          >
            {{ $t(resources.Save.i18n) || resources.Save.name }}
          </vs-button>
        </div>
      </vs-popup>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import resources from "@/i18n/resources.js";
import database from "@/assets/resources/enums/database.json";
import vSelect from "vue-select";
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";

export default {
  components: { vSelect, AgGridVue },
  data() {
    return {
      resources: resources,
      database: database,
      userSession: JSON.parse(localStorage.getItem("userInfo")).email,
      hotelId: 0,
      hotelDto: {},
      categoryList: [],
      accommodationTypeList: [],
      accommodation: "",
      category: "",
      destinationOption: [],
      destinationId: "",
      countryList: [],
      stateList: [],
      countryId: "",
      stateId: "",
      supplierHotels: [],
      supplierAdd: {},
      supplierList: [],
      accommodationList: [],
      popupCreate: false,
      titlepopupCreate: this.$i18n.t("Create"),
      NumberPage: 0,
      rows: 10,
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      updateDto: {},
      createDto: {},
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: "#",
          field: "id",
          filter: true,
          hide: true
        },
        {
          headerName: this.$i18n.t("NameEs"),
          field: "nameEs",
          filter: true,
          width: 300
        },
        {
          headerName: this.$i18n.t("NameEn"),
          field: "nameEn",
          filter: true,
          width: 300
        },
        {
          headerName: this.$i18n.t("AdultAmount"),
          field: "adultAmount",
          filter: true,
          width: 200
        },
        {
          headerName: this.$i18n.t("ChildAmount"),
          field: "childAmount",
          filter: true,
          width: 200
        },
        {
          headerName: this.$i18n.t("InfantAmount"),
          field: "infantAmount",
          filter: true,
          width: 200
        },
        {
          headerName: this.$i18n.t("Status"),
          field: "active",
          cellRenderer: function(event) {
            let object = "";
            if (event.node.data.active) {
              object =
                '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-success), 1);">check_circle</i>';
            } else
              object =
                '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-danger), 1);">highlight_off</i>';

            return object;
          }
        }
      ]
    };
  },
  async created() {
    this.$vs.loading();

    this.hotelId = this.$route.params.id;
    await this.getCountry();
    if (this.hotelId != 0) {
      await this.get();
      await this.getAccommodation();
    }
    await this.getCategory();
    await this.getAccommodationType();
    await this.getSupplier();

    this.$vs.loading.close();
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 20;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    }
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("level", null);
      } else this.gridOptions.columnApi.setColumnPinned("level", "left");
    }
  },
  methods: {
    async onRowClicked(event) {
      this.createDto = event.node.data;
      this.titlepopupCreate = this.$i18n.t("Update");
      this.popupCreate = true;
    },

    async validateAccomodation() {
      if (
        this.createDto.id != undefined &&
        this.createDto.id != null &&
        this.createDto.id != 0
      )
        await this.updateAccommodation();
      else await this.createAccommodation();
    },

    async get() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Hotel/ListByFilters`,
        data: {
          searchKeys: {
            hotel: this.hotelId
          },
          page: this.NumberPage,
          rows: this.rows,
          language: this.$i18n.locale.toUpperCase()
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.hotelDto = {};
          } else {
            this.hotelDto = result.data[0];
            this.category = this.hotelDto.categoryId;
            this.accommodation = this.hotelDto.accommodationTypeId;
            this.countryId = this.hotelDto.countryId;
            await this.getStateByCountry(this.countryId);
            this.stateId = this.hotelDto.stateId;
            await this.getCityByState(this.stateId);

            this.destinationId = this.hotelDto.cityId;
            this.supplierHotels = this.hotelDto.suppliers.map(x => ({
              code: x.code,
              id: x.supplierId,
              active: x.active,
              name: x.supplierName
            }));
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCategory() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Category/CategoryHotels`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.categoryList = result.data;
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.categoryList = [];
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getAccommodationType() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}AccommodationType`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.accommodationTypeList = result.data;
          } else {
            this.accommodationTypeList = [];
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCityByName() {
      let getElementId;
      let textSearch;

      getElementId = document.getElementById("searchCityCreate");
      textSearch = getElementId.__vue__.search;

      let _searchApi = `City/GetByCity/${this.$i18n.locale.toUpperCase()}/${textSearch}`;

      if (textSearch.length >= 3) {
        await axios({
          method: "GET",
          url: `${process.env.VUE_APP_APIVT}${_searchApi}`,
          headers: {
            "content-type": "application/json",
            Authorization: `bearer ${localStorage.getItem("tokenVT")}`
          }
        }).then(
          result => {
            if (!result.data) {
              this.$vs.notify({
                title: this.$i18n.t("Alert"),
                text: this.$i18n.t("MsgNotFoundInfo"),
                color: "warning",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle"
              });
              this.destinationOption = [];
            } else {
              this.destinationOption = result.data;
            }
          },
          error => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError") + "<br/>" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.destinationOption = [];
          }
        );
      }
    },

    addSupplier() {
      let supplierHotel = this.supplierHotels.filter(
        x => x.id == this.supplierAdd.supplierId
      );

      if (supplierHotel.length == 0) {
        let supplier = this.supplierList.filter(
          x => x.id == this.supplierAdd.supplierId
        );
        supplier[0].code = this.supplierAdd.code;
        this.supplierHotels.push(supplier[0]);
      }
    },

    async getSupplier() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Supplier`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.supplierList = result.data;
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.supplierList = [];
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    updateSupplier(item) {
      if (this.supplierHotels.find(x => x.id == item)) {
        let _index = this.supplierHotels.findIndex(x => x.id == item);
        if (_index > -1) this.supplierHotels.splice(_index, 1);
      }
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },

    async createHotel() {
      this.$vs.loading();
      let suppliers = this.supplierHotels.map(x => ({
        Code: x.code,
        SupplierId: x.id,
        Active: this.active,
        CreatedBy: this.userSession
      }));

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Hotel/Create`,
        data: {
          Suppliers: suppliers,
          Name: this.hotelDto.name,
          DescriptionEn: this.hotelDto.descriptionEn,
          DescriptionEs: this.hotelDto.descriptionEs,
          Longitude: this.hotelDto.longitude,
          Latitude: this.hotelDto.latitude,
          CategoryId: this.category,
          AccommodationTypeId: this.accommodation,
          Address: this.hotelDto.address,
          Email: this.hotelDto.email,
          CityId: this.destinationId,
          CreatedBy: this.userSession,
          NumberStars: this.hotelDto.numberStars,
          IsFeatured: this.hotelDto.isFeatured,
          phone: this.hotelDto.phone,
          active: this.hotelDto.active,
          code: ""
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotSaved"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async updateHotel() {
      this.$vs.loading();
      let suppliers = this.supplierHotels.map(x => ({
        Code: x.code,
        SupplierId: x.id,
        Active: this.active,
        CreatedBy: this.userSession
      }));

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Hotel/Update`,
        data: {
          Id: this.hotelId,
          Suppliers: suppliers,
          Name: this.hotelDto.name,
          DescriptionEn: this.hotelDto.descriptionEn,
          DescriptionEs: this.hotelDto.descriptionEs,
          Longitude: this.hotelDto.longitude,
          Latitude: this.hotelDto.latitude,
          CategoryId: this.category,
          AccommodationTypeId: this.accommodation,
          Address: this.hotelDto.address,
          Email: this.hotelDto.email,
          CityId: this.destinationId,
          Active: this.hotelDto.active,
          NumberStars: this.hotelDto.numberStars,
          IsFeatured: this.hotelDto.isFeatured,
          phone: this.hotelDto.phone,
          CreatedBy: this.userSession,
          Code: this.hotelDto.code
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.popupCreate = false;
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotSaved"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async validation() {
      this.$validator.validateAll().then(async result => {
        if (result) {
          if (this.hotelId == 0) await this.createHotel();
          else await this.updateHotel();
        } else {
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: this.$i18n.t("MsgRequiredField"),
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      });
    },

    async getAccommodation() {
      this.$vs.loading();
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Accommodation/ByHotelId/${this.hotelId}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.accommodationList = result.data;
            //this.hotelName = result.data[0].hotelName;
          } else {
            this.accommodationList = [];
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    async createAccommodation() {
      this.$vs.loading();
      this.createDto.hotelId = this.hotelId;
      this.createDto.language = this.$i18n.locale.toUpperCase();
      this.createDto.createdBy = this.userSession;
      this.createDto.active = true;

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Accommodation`,
        data: this.createDto,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (result.data) {
            await this.getAccommodation();
            this.popupCreate = false;

            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async updateAccommodation() {
      this.$vs.loading();
      this.createDto.language = this.$i18n.locale.toUpperCase();
      this.createDto.createdBy = this.userSession;

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Accommodation/Update`,
        data: this.createDto,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (result.data.success) {
            this.titlepopupCreate = this.$i18n.t("Create");
            this.popupCreate = false;
            await this.getAccommodation();

            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async getStateByCountry(item) {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }State/GetByCountry/${this.$i18n.locale.toUpperCase()}/${item}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) this.stateList = result.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCityByState(item) {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }City/GetByState/${this.$i18n.locale.toUpperCase()}/${item}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) this.destinationOption = result.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCountry() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Country/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.countryList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  }
};
</script>
