var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-row",
        { attrs: { "vs-type": "flex", "vs-justify": "flex-end" } },
        [
          _vm.productId == _vm.database.products.AIR ||
          _vm.productId == _vm.database.products.HOT ||
          _vm.productId == _vm.database.products.BAG ||
          _vm.productId == _vm.database.products.SEG
            ? _c(
                "vx-tooltip",
                {
                  attrs: {
                    text:
                      _vm.$t(_vm.resources.Add.i18n) || _vm.resources.Add.name
                  }
                },
                [
                  _c("vs-button", {
                    staticClass: "mr-0 mb-2",
                    attrs: {
                      radius: "",
                      color: "primary",
                      "icon-pack": "feather",
                      type: "border",
                      icon: "icon-plus"
                    },
                    on: {
                      click: function($event) {
                        return _vm.showPopUp(0, false)
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "vs-table",
        {
          attrs: { data: _vm.itineraryDetail },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var data = ref.data
                return _vm._l(data, function(tr, indextr) {
                  return _c(
                    "vs-tr",
                    { key: indextr },
                    [
                      data[indextr].productId == _vm.database.products.AIR
                        ? _c(
                            "vs-td",
                            { attrs: { data: data[indextr].origin } },
                            [
                              _vm._v(
                                "(" +
                                  _vm._s(data[indextr].originCode) +
                                  ") " +
                                  _vm._s(data[indextr].origin)
                              )
                            ]
                          )
                        : _vm._e(),
                      data[indextr].productId == _vm.database.products.AIR
                        ? _c(
                            "vs-td",
                            { attrs: { data: data[indextr].destination } },
                            [
                              _vm._v(
                                "(" +
                                  _vm._s(data[indextr].destinationCode) +
                                  ") " +
                                  _vm._s(data[indextr].destination)
                              )
                            ]
                          )
                        : _vm._e(),
                      data[indextr].productId != _vm.database.products.AIR
                        ? _c(
                            "vs-td",
                            { attrs: { data: data[indextr].origin } },
                            [_vm._v(_vm._s(data[indextr].origin))]
                          )
                        : _vm._e(),
                      data[indextr].productId != _vm.database.products.AIR
                        ? _c(
                            "vs-td",
                            { attrs: { data: data[indextr].destination } },
                            [_vm._v(_vm._s(data[indextr].destination))]
                          )
                        : _vm._e(),
                      _c(
                        "vs-td",
                        { attrs: { data: data[indextr].departureDate } },
                        [_vm._v(_vm._s(data[indextr].departureDate))]
                      ),
                      _c(
                        "vs-td",
                        { attrs: { data: data[indextr].arrivalDate } },
                        [_vm._v(_vm._s(data[indextr].arrivalDate))]
                      ),
                      _c(
                        "vs-td",
                        { attrs: { data: data[indextr].supplierName } },
                        [_vm._v(_vm._s(data[indextr].supplierName))]
                      ),
                      _c(
                        "vs-td",
                        { attrs: { data: data[indextr].serviceSupplierName } },
                        [_vm._v(_vm._s(data[indextr].serviceSupplierName))]
                      ),
                      _c(
                        "vs-td",
                        [
                          _c(
                            "vx-tooltip",
                            {
                              attrs: {
                                text:
                                  _vm.$t(_vm.resources.Edit.i18n) ||
                                  _vm.resources.Edit.name
                              }
                            },
                            [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.showPopUp(data[indextr], true)
                                    }
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    staticClass: "w-5 h-5 mr-1",
                                    attrs: { icon: "EditIcon" }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "vx-tooltip",
                            {
                              attrs: {
                                text:
                                  _vm.$t(_vm.resources.Delete.i18n) ||
                                  _vm.resources.Delete.name
                              }
                            },
                            [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.remove(data[indextr])
                                    }
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    staticClass: "w-5 h-5 mr-1",
                                    attrs: {
                                      icon: "XIcon",
                                      svgClasses: "fill-current text-danger"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                })
              }
            }
          ])
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.Origin.i18n) ||
                      _vm.resources.Origin.name
                  )
                )
              ]),
              _c("vs-th", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.Destination.i18n) ||
                      _vm.resources.Destination.name
                  )
                )
              ]),
              _c("vs-th", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.StartDate.i18n) ||
                      _vm.resources.StartDate.name
                  )
                )
              ]),
              _c("vs-th", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.EndDate.i18n) ||
                      _vm.resources.EndDate.name
                  )
                )
              ]),
              _c("vs-th", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.SupplierName.i18n) ||
                      _vm.resources.SupplierName.name
                  )
                )
              ]),
              _c("vs-th", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.LenderServices.i18n) ||
                      _vm.resources.LenderServices.name
                  )
                )
              ]),
              _c("vs-th", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.Options.i18n) ||
                      _vm.resources.Options.name
                  )
                )
              ])
            ],
            1
          )
        ],
        2
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title:
              _vm.$t(_vm.resources.Itinerary.i18n) ||
              _vm.resources.Itinerary.name,
            active: _vm.popup
          },
          on: {
            "update:active": function($event) {
              _vm.popup = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 mb-2 w-full" },
              [
                _c("label", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Origin.i18n) ||
                        _vm.resources.Origin.name
                    )
                  )
                ]),
                _c("v-select", {
                  attrs: {
                    id: "searchOrigin",
                    clearable: true,
                    options: _vm.originOption,
                    placeholder:
                      _vm.$t(_vm.resources.WriteOrigin.i18n) ||
                      _vm.resources.WriteOrigin.name,
                    reduce: function(origin) {
                      return origin.id
                    },
                    label: "name"
                  },
                  nativeOn: {
                    keyup: function($event) {
                      return _vm.getCityByName("Origin")
                    }
                  },
                  model: {
                    value: _vm.origin,
                    callback: function($$v) {
                      _vm.origin = $$v
                    },
                    expression: "origin"
                  }
                })
              ],
              1
            ),
            _vm.productId != _vm.database.products.HOT
              ? _c(
                  "div",
                  { staticClass: "vx-col md:w-1/2 mb-2 w-full" },
                  [
                    _c("label", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.Destination.i18n) ||
                            _vm.resources.Destination.name
                        )
                      )
                    ]),
                    _c("v-select", {
                      attrs: {
                        id: "searchDestination",
                        clearable: true,
                        options: _vm.destinationOption,
                        placeholder:
                          _vm.$t(_vm.resources.WriteDestination.i18n) ||
                          _vm.resources.WriteDestination.name,
                        reduce: function(destination) {
                          return destination.id
                        },
                        label: "name"
                      },
                      nativeOn: {
                        keyup: function($event) {
                          return _vm.getCityByName("Destination")
                        }
                      },
                      model: {
                        value: _vm.destination,
                        callback: function($$v) {
                          _vm.destination = $$v
                        },
                        expression: "destination"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.productId == _vm.database.products.HOT
              ? _c(
                  "div",
                  { staticClass: "vx-col md:w-1/2 mb-2 w-full" },
                  [
                    _c("label", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.LenderServices.i18n) ||
                            _vm.resources.LenderServices.name
                        )
                      )
                    ]),
                    _c("v-select", {
                      attrs: {
                        id: "searchLender",
                        clearable: true,
                        options: _vm.lenderOption,
                        placeholder:
                          _vm.$t(_vm.resources.Select.i18n) ||
                          _vm.resources.Select.name,
                        reduce: function(lender) {
                          return lender.id
                        },
                        label: "name"
                      },
                      nativeOn: {
                        keyup: function($event) {
                          return _vm.getHotelByName()
                        }
                      },
                      model: {
                        value: _vm.lender,
                        callback: function($$v) {
                          _vm.lender = $$v
                        },
                        expression: "lender"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.productId == _vm.database.products.AIR ||
            _vm.productId == _vm.database.products.BAG
              ? _c(
                  "div",
                  { staticClass: "vx-col md:w-1/2 mb-2 w-full" },
                  [
                    _c("label", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.LenderServices.i18n) ||
                            _vm.resources.LenderServices.name
                        )
                      )
                    ]),
                    _c("v-select", {
                      attrs: {
                        id: "searchLender",
                        clearable: true,
                        options: _vm.lenderOption,
                        placeholder:
                          _vm.$t(_vm.resources.Select.i18n) ||
                          _vm.resources.Select.name,
                        reduce: function(lender) {
                          return lender.id
                        },
                        label: "name"
                      },
                      nativeOn: {
                        keyup: function($event) {
                          return _vm.getAirlineByText()
                        }
                      },
                      model: {
                        value: _vm.lender,
                        callback: function($$v) {
                          _vm.lender = $$v
                        },
                        expression: "lender"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 mb-2 w-full" },
              [
                _c("label", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Supplier.i18n) ||
                        _vm.resources.Supplier.name
                    )
                  )
                ]),
                _c("v-select", {
                  attrs: {
                    clearable: true,
                    options: _vm.gdsOption,
                    placeholder:
                      _vm.$t(_vm.resources.Select.i18n) ||
                      _vm.resources.Select.name,
                    reduce: function(gds) {
                      return gds.id
                    },
                    label: "name"
                  },
                  model: {
                    value: _vm.gds,
                    callback: function($$v) {
                      _vm.gds = $$v
                    },
                    expression: "gds"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 mb-2 w-full" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.DepartureDate.i18n) ||
                        _vm.resources.DepartureDate.name
                    )
                  )
                ]),
                _c("flat-pickr", {
                  staticClass: "vx-col w-full",
                  attrs: {
                    config: _vm.configdateTimePicker,
                    placeholder: "yyyy-MM-dd Hh:mm"
                  },
                  model: {
                    value: _vm.departureDate,
                    callback: function($$v) {
                      _vm.departureDate = $$v
                    },
                    expression: "departureDate"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 mb-2 w-full" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.ArrivalDate.i18n) ||
                        _vm.resources.ArrivalDate.name
                    )
                  )
                ]),
                _c("flat-pickr", {
                  staticClass: "vx-col w-full",
                  attrs: {
                    config: _vm.configdateTimePicker,
                    placeholder: "yyyy-MM-dd Hh:mm"
                  },
                  model: {
                    value: _vm.arrivalDate,
                    callback: function($$v) {
                      _vm.arrivalDate = $$v
                    },
                    expression: "arrivalDate"
                  }
                })
              ],
              1
            ),
            _vm.productId != 3
              ? _c(
                  "div",
                  { staticClass: "vx-col w-full md:w-1/2 mb-4" },
                  [
                    _c("label", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.FlightClass.i18n) ||
                            _vm.resources.FlightClass.name
                        )
                      )
                    ]),
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        "icon-pack": "feather",
                        "icon-no-border": "",
                        maxlength: "2"
                      },
                      model: {
                        value: _vm.flightClass,
                        callback: function($$v) {
                          _vm.flightClass = $$v
                        },
                        expression: "flightClass"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.productId != 3
              ? _c(
                  "div",
                  { staticClass: "vx-col w-full md:w-1/2 mb-4" },
                  [
                    _c("label", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.FlightNumber.i18n) ||
                            _vm.resources.FlightNumber.name
                        )
                      )
                    ]),
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        "icon-pack": "feather",
                        "icon-no-border": "",
                        type: "number",
                        maxlength: "10"
                      },
                      model: {
                        value: _vm.flightNumber,
                        callback: function($$v) {
                          _vm.flightNumber = $$v
                        },
                        expression: "flightNumber"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/2 mt-4" },
              [
                _c(
                  "vs-button",
                  {
                    attrs: { color: "primary", type: "filled" },
                    on: {
                      click: function($event) {
                        return _vm.saveItinerary()
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(_vm.resources.Add.i18n) ||
                            _vm.resources.Add.name
                        ) +
                        " "
                    )
                  ]
                )
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title:
              _vm.$t(_vm.resources.Update.i18n) || _vm.resources.Update.name,
            active: _vm.popupEdit
          },
          on: {
            "update:active": function($event) {
              _vm.popupEdit = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 mb-2 w-full" },
              [
                _c("label", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Origin.i18n) ||
                        _vm.resources.Origin.name
                    )
                  )
                ]),
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    "icon-pack": "feather",
                    "icon-no-border": "",
                    maxlength: "2",
                    disabled: ""
                  },
                  model: {
                    value: _vm.origin,
                    callback: function($$v) {
                      _vm.origin = $$v
                    },
                    expression: "origin"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 mb-2 w-full" },
              [
                _c("label", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Destination.i18n) ||
                        _vm.resources.Destination.name
                    )
                  )
                ]),
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    "icon-pack": "feather",
                    "icon-no-border": "",
                    maxlength: "2",
                    disabled: ""
                  },
                  model: {
                    value: _vm.destination,
                    callback: function($$v) {
                      _vm.destination = $$v
                    },
                    expression: "destination"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 mb-2 w-full" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.DepartureDate.i18n) ||
                        _vm.resources.DepartureDate.name
                    )
                  )
                ]),
                _c("flat-pickr", {
                  staticClass: "vx-col w-full",
                  attrs: {
                    config: _vm.configdateTimePicker,
                    placeholder: "yyyy-MM-dd Hh:mm"
                  },
                  model: {
                    value: _vm.departureDate,
                    callback: function($$v) {
                      _vm.departureDate = $$v
                    },
                    expression: "departureDate"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 mb-2 w-full" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.ArrivalDate.i18n) ||
                        _vm.resources.ArrivalDate.name
                    )
                  )
                ]),
                _c("flat-pickr", {
                  staticClass: "vx-col w-full",
                  attrs: {
                    config: _vm.configdateTimePicker,
                    placeholder: "yyyy-MM-dd Hh:mm"
                  },
                  model: {
                    value: _vm.arrivalDate,
                    callback: function($$v) {
                      _vm.arrivalDate = $$v
                    },
                    expression: "arrivalDate"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 mb-2 w-full" },
              [
                _c("label", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Supplier.i18n) ||
                        _vm.resources.Supplier.name
                    )
                  )
                ]),
                _c("v-select", {
                  attrs: {
                    clearable: true,
                    options: _vm.gdsOption,
                    placeholder:
                      _vm.$t(_vm.resources.Select.i18n) ||
                      _vm.resources.Select.name,
                    reduce: function(gds) {
                      return gds.id
                    },
                    label: "name"
                  },
                  model: {
                    value: _vm.gds,
                    callback: function($$v) {
                      _vm.gds = $$v
                    },
                    expression: "gds"
                  }
                })
              ],
              1
            ),
            _vm.productId != 3
              ? _c(
                  "div",
                  { staticClass: "vx-col w-full md:w-1/2 mb-4" },
                  [
                    _c("label", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.FlightClass.i18n) ||
                            _vm.resources.FlightClass.name
                        )
                      )
                    ]),
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        "icon-pack": "feather",
                        "icon-no-border": "",
                        maxlength: "2"
                      },
                      model: {
                        value: _vm.flightClass,
                        callback: function($$v) {
                          _vm.flightClass = $$v
                        },
                        expression: "flightClass"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.productId != 3
              ? _c(
                  "div",
                  { staticClass: "vx-col w-full md:w-1/2 mb-4" },
                  [
                    _c("label", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.FlightNumber.i18n) ||
                            _vm.resources.FlightNumber.name
                        )
                      )
                    ]),
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        "icon-pack": "feather",
                        "icon-no-border": "",
                        type: "number",
                        maxlength: "10"
                      },
                      model: {
                        value: _vm.flightNumber,
                        callback: function($$v) {
                          _vm.flightNumber = $$v
                        },
                        expression: "flightNumber"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/2 mt-4" },
              [
                _c(
                  "vs-button",
                  {
                    attrs: { color: "primary", type: "filled" },
                    on: {
                      click: function($event) {
                        return _vm.edit()
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(_vm.resources.Save.i18n) ||
                            _vm.resources.Save.name
                        ) +
                        " "
                    )
                  ]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }