var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tree" }, [
    _c(
      "ul",
      { staticClass: "tree-list" },
      [_c("node-tree", { attrs: { node: _vm.treeData } })],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }