var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-card",
        {
          staticClass: "mb-4",
          attrs: {
            title:
              _vm.$t(_vm.resources.Packages.i18n) || _vm.resources.Packages.name
          }
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col md:w-1/3 w-full mb-2" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("label", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.NameEs.i18n) ||
                          _vm.resources.NameEs.name
                      )
                    )
                  ]),
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|min:3|max:49",
                        expression: "'required|min:3|max:49'"
                      }
                    ],
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-clipboard",
                      "icon-no-border": "",
                      name: "nameEs"
                    },
                    model: {
                      value: _vm.packageDto.nameEs,
                      callback: function($$v) {
                        _vm.$set(_vm.packageDto, "nameEs", $$v)
                      },
                      expression: "packageDto.nameEs"
                    }
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("nameEs"),
                          expression: "errors.has('nameEs')"
                        }
                      ],
                      staticClass: "text-danger text-sm"
                    },
                    [_vm._v(_vm._s(_vm.errors.first("nameEs")))]
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-col md:w-1/3 w-full mb-2" }, [
              _c("label", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.NameEn.i18n) ||
                      _vm.resources.NameEn.name
                  )
                )
              ]),
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|min:3|max:49",
                        expression: "'required|min:3|max:49'"
                      }
                    ],
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-clipboard",
                      "icon-no-border": "",
                      name: "nameEn"
                    },
                    model: {
                      value: _vm.packageDto.nameEn,
                      callback: function($$v) {
                        _vm.$set(_vm.packageDto, "nameEn", $$v)
                      },
                      expression: "packageDto.nameEn"
                    }
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("nameEs"),
                          expression: "errors.has('nameEs')"
                        }
                      ],
                      staticClass: "text-danger text-sm"
                    },
                    [_vm._v(_vm._s(_vm.errors.first("nameEn")))]
                  )
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full mb-2" },
              [
                _c("label", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.ExpirationDate.i18n) ||
                        _vm.resources.ExpirationDate.name
                    )
                  )
                ]),
                _c("datepicker", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  attrs: {
                    language: _vm.languages[_vm.language],
                    format: "d MMMM yyyy",
                    name: "expirationDate"
                  },
                  model: {
                    value: _vm.packageDto.outOfDate,
                    callback: function($$v) {
                      _vm.$set(_vm.packageDto, "outOfDate", $$v)
                    },
                    expression: "packageDto.outOfDate"
                  }
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("expirationDate"),
                        expression: "errors.has('expirationDate')"
                      }
                    ],
                    staticClass: "text-danger text-sm"
                  },
                  [_vm._v(_vm._s(_vm.errors.first("expirationDate")))]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Country.i18n) ||
                        _vm.resources.Country.name
                    )
                  )
                ]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  attrs: {
                    clearable: false,
                    options: _vm.countryList,
                    reduce: function(country) {
                      return country.id
                    },
                    label: "name",
                    hint: "" + _vm.packageDto.countryId,
                    name: "country"
                  },
                  on: {
                    input: function($event) {
                      return _vm.getStateByCountry(
                        "" + _vm.packageDto.countryId
                      )
                    }
                  },
                  model: {
                    value: _vm.packageDto.countryId,
                    callback: function($$v) {
                      _vm.$set(_vm.packageDto, "countryId", $$v)
                    },
                    expression: "packageDto.countryId"
                  }
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("country"),
                        expression: "errors.has('country')"
                      }
                    ],
                    staticClass: "text-danger text-sm"
                  },
                  [_vm._v(_vm._s(_vm.errors.first("country")))]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full mb-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.State.i18n) ||
                        _vm.resources.State.name
                    )
                  )
                ]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  attrs: {
                    clearable: false,
                    options: _vm.stateCountry,
                    reduce: function(state) {
                      return state.id
                    },
                    label: "name",
                    hint: "" + _vm.packageDto.stateId,
                    name: "state"
                  },
                  on: {
                    input: function($event) {
                      return _vm.getCityByState("" + _vm.packageDto.stateId)
                    }
                  },
                  model: {
                    value: _vm.packageDto.stateId,
                    callback: function($$v) {
                      _vm.$set(_vm.packageDto, "stateId", $$v)
                    },
                    expression: "packageDto.stateId"
                  }
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("state"),
                        expression: "errors.has('state')"
                      }
                    ],
                    staticClass: "text-danger text-sm"
                  },
                  [_vm._v(_vm._s(_vm.errors.first("state")))]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full mb-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Destination.i18n) ||
                        _vm.resources.Destination.name
                    )
                  )
                ]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  attrs: {
                    clearable: false,
                    options: _vm.destinationList,
                    reduce: function(city) {
                      return city.id
                    },
                    label: "name",
                    name: "destination"
                  },
                  model: {
                    value: _vm.packageDto.destinationId,
                    callback: function($$v) {
                      _vm.$set(_vm.packageDto, "destinationId", $$v)
                    },
                    expression: "packageDto.destinationId"
                  }
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("destination"),
                        expression: "errors.has('destination')"
                      }
                    ],
                    staticClass: "text-danger text-sm"
                  },
                  [_vm._v(_vm._s(_vm.errors.first("destination")))]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full" },
              [
                _c("label", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.DescriptionES.i18n) ||
                        _vm.resources.DescriptionES.name
                    )
                  )
                ]),
                _c("vs-textarea", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|min:30|max:10000",
                      expression: "'required|min:30|max:10000'"
                    }
                  ],
                  attrs: { rows: "5", name: "descriptionEs" },
                  model: {
                    value: _vm.packageDto.descriptionEs,
                    callback: function($$v) {
                      _vm.$set(_vm.packageDto, "descriptionEs", $$v)
                    },
                    expression: "packageDto.descriptionEs"
                  }
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("descriptionEs"),
                        expression: "errors.has('descriptionEs')"
                      }
                    ],
                    staticClass: "text-danger text-sm"
                  },
                  [_vm._v(_vm._s(_vm.errors.first("descriptionEs")))]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full" },
              [
                _c("label", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.DescriptionEN.i18n) ||
                        _vm.resources.DescriptionEN.name
                    )
                  )
                ]),
                _c("vs-textarea", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|min:30|max:10000",
                      expression: "'required|min:30|max:10000'"
                    }
                  ],
                  attrs: { rows: "5", name: "descriptionEn" },
                  model: {
                    value: _vm.packageDto.descriptionEn,
                    callback: function($$v) {
                      _vm.$set(_vm.packageDto, "descriptionEn", $$v)
                    },
                    expression: "packageDto.descriptionEn"
                  }
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("descriptionEn"),
                        expression: "errors.has('descriptionEn')"
                      }
                    ],
                    staticClass: "text-danger text-sm"
                  },
                  [_vm._v(_vm._s(_vm.errors.first("descriptionEn")))]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full" },
              [
                _c("label", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.IncludeEs.i18n) ||
                        _vm.resources.IncludeEs.name
                    )
                  )
                ]),
                _c("vs-textarea", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|min:30|max:10000",
                      expression: "'required|min:30|max:10000'"
                    }
                  ],
                  attrs: { rows: "5", name: "includeEs" },
                  model: {
                    value: _vm.packageDto.includeEs,
                    callback: function($$v) {
                      _vm.$set(_vm.packageDto, "includeEs", $$v)
                    },
                    expression: "packageDto.includeEs"
                  }
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("includeEs"),
                        expression: "errors.has('includeEs')"
                      }
                    ],
                    staticClass: "text-danger text-sm"
                  },
                  [_vm._v(_vm._s(_vm.errors.first("includeEs")))]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full" },
              [
                _c("label", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.IncludeEn.i18n) ||
                        _vm.resources.IncludeEn.name
                    )
                  )
                ]),
                _c("vs-textarea", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|min:30|max:10000",
                      expression: "'required|min:30|max:10000'"
                    }
                  ],
                  attrs: { rows: "5", name: "includeEn" },
                  model: {
                    value: _vm.packageDto.includeEn,
                    callback: function($$v) {
                      _vm.$set(_vm.packageDto, "includeEn", $$v)
                    },
                    expression: "packageDto.includeEn"
                  }
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("includeEn"),
                        expression: "errors.has('includeEn')"
                      }
                    ],
                    staticClass: "text-danger text-sm"
                  },
                  [_vm._v(_vm._s(_vm.errors.first("includeEn")))]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full" },
              [
                _c("label", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.NoIncludeEs.i18n) ||
                        _vm.resources.NoIncludeEs.name
                    )
                  )
                ]),
                _c("vs-textarea", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|min:30|max:10000",
                      expression: "'required|min:30|max:10000'"
                    }
                  ],
                  attrs: { rows: "5", name: "noIncludeEs" },
                  model: {
                    value: _vm.packageDto.noIncludeEs,
                    callback: function($$v) {
                      _vm.$set(_vm.packageDto, "noIncludeEs", $$v)
                    },
                    expression: "packageDto.noIncludeEs"
                  }
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("noIncludeEs"),
                        expression: "errors.has('noIncludeEs')"
                      }
                    ],
                    staticClass: "text-danger text-sm"
                  },
                  [_vm._v(_vm._s(_vm.errors.first("noIncludeEs")))]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full" },
              [
                _c("label", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.NoIncludeEn.i18n) ||
                        _vm.resources.NoIncludeEn.name
                    )
                  )
                ]),
                _c("vs-textarea", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|min:30|max:10000",
                      expression: "'required|min:30|max:10000'"
                    }
                  ],
                  attrs: { rows: "5", name: "NoIncludeEn" },
                  model: {
                    value: _vm.packageDto.noIncludeEn,
                    callback: function($$v) {
                      _vm.$set(_vm.packageDto, "noIncludeEn", $$v)
                    },
                    expression: "packageDto.noIncludeEn"
                  }
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("NoIncludeEn"),
                        expression: "errors.has('NoIncludeEn')"
                      }
                    ],
                    staticClass: "text-danger text-sm"
                  },
                  [_vm._v(_vm._s(_vm.errors.first("NoIncludeEn")))]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full" },
              [
                _c("label", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.ImportantInformationEs.i18n) ||
                        _vm.resources.ImportantInformationEs.name
                    )
                  )
                ]),
                _c("vs-textarea", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|min:30|max:10000",
                      expression: "'required|min:30|max:10000'"
                    }
                  ],
                  attrs: { rows: "5", name: "importantInformationEs" },
                  model: {
                    value: _vm.packageDto.importantInformationEs,
                    callback: function($$v) {
                      _vm.$set(_vm.packageDto, "importantInformationEs", $$v)
                    },
                    expression: "packageDto.importantInformationEs"
                  }
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("importantInformationEs"),
                        expression: "errors.has('importantInformationEs')"
                      }
                    ],
                    staticClass: "text-danger text-sm"
                  },
                  [_vm._v(_vm._s(_vm.errors.first("importantInformationEs")))]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full" },
              [
                _c("label", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.ImportantInformationEn.i18n) ||
                        _vm.resources.ImportantInformationEn.name
                    )
                  )
                ]),
                _c("vs-textarea", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|min:30|max:10000",
                      expression: "'required|min:30|max:10000'"
                    }
                  ],
                  attrs: { rows: "5", name: "importantInformationEn" },
                  model: {
                    value: _vm.packageDto.importantInformationEn,
                    callback: function($$v) {
                      _vm.$set(_vm.packageDto, "importantInformationEn", $$v)
                    },
                    expression: "packageDto.importantInformationEn"
                  }
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("importantInformationEn"),
                        expression: "errors.has('importantInformationEn')"
                      }
                    ],
                    staticClass: "text-danger text-sm"
                  },
                  [_vm._v(_vm._s(_vm.errors.first("importantInformationEn")))]
                )
              ],
              1
            ),
            _c("div", { staticClass: "vx-col md:w-1/6 w-full mb-2" }, [
              _c("ul", { staticClass: "vx-col w-full mt-5" }, [
                _c(
                  "li",
                  [
                    _c(
                      "vs-checkbox",
                      {
                        model: {
                          value: _vm.packageDto.isPopular,
                          callback: function($$v) {
                            _vm.$set(_vm.packageDto, "isPopular", $$v)
                          },
                          expression: "packageDto.isPopular"
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.Popular.i18n) ||
                              _vm.resources.Popular.name
                          )
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ]),
            _c("div", { staticClass: "vx-col md:w-1/6 w-full mb-2" }, [
              _c("ul", { staticClass: "vx-col w-full mt-5" }, [
                _c(
                  "li",
                  [
                    _c(
                      "vs-checkbox",
                      {
                        model: {
                          value: _vm.packageDto.active,
                          callback: function($$v) {
                            _vm.$set(_vm.packageDto, "active", $$v)
                          },
                          expression: "packageDto.active"
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.Active.i18n) ||
                              _vm.resources.Active.name
                          )
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ]),
            _c(
              "div",
              {
                staticClass: "vx-col mb-2 mt-5",
                staticStyle: { "text-align": "right" }
              },
              [
                _c(
                  "vs-button",
                  {
                    attrs: { color: "primary", type: "filled" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.validInformation($event)
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(_vm.resources.Save.i18n) ||
                            _vm.resources.Save.name
                        ) +
                        " "
                    )
                  ]
                )
              ],
              1
            )
          ])
        ]
      ),
      _vm.packageId != 0
        ? _c(
            "div",
            { staticClass: "vx-row mb-4" },
            [
              _c(
                "vx-card",
                {
                  staticClass: "mb-4",
                  attrs: {
                    title:
                      _vm.$t(_vm.resources.Detail.i18n) ||
                      _vm.resources.Detail.name
                  }
                },
                [
                  _c(
                    "vs-row",
                    { attrs: { "vs-type": "flex", "vs-justify": "center" } },
                    [
                      _c(
                        "vs-col",
                        {
                          attrs: {
                            "vs-type": "flex",
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "3"
                          }
                        },
                        [
                          _c("vs-button", {
                            attrs: {
                              type: "border",
                              color: "primary",
                              "icon-pack": "feather",
                              icon: "icon-plus",
                              size: "large"
                            },
                            on: {
                              click: function($event) {
                                return _vm.openCreateDetail()
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c(
                        "vs-table",
                        {
                          attrs: {
                            "max-items": "10",
                            pagination: "",
                            search: "",
                            data: _vm.details
                          },
                          on: { search: _vm.handleSearch },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var data = ref.data
                                  return _vm._l(data, function(tr, indextr) {
                                    return _c(
                                      "vs-tr",
                                      { key: indextr },
                                      [
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: {
                                              data: data[indextr].hotelName
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(data[indextr].hotelName)
                                            )
                                          ]
                                        ),
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: {
                                              data:
                                                data[indextr].accommodationName
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                data[indextr].accommodationName
                                              )
                                            )
                                          ]
                                        ),
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: {
                                              data: data[indextr].startDate
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm
                                                  .moment(
                                                    data[indextr].startDate[0]
                                                  )
                                                  .format("YYYY-MM-DD")
                                              )
                                            )
                                          ]
                                        ),
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: {
                                              data: data[indextr].endDate
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm
                                                  .moment(
                                                    data[indextr].endDate[0]
                                                  )
                                                  .format("YYYY-MM-DD")
                                              )
                                            )
                                          ]
                                        ),
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: { data: data[indextr].value }
                                          },
                                          [_vm._v(_vm._s(data[indextr].value))]
                                        ),
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: {
                                              data: data[indextr].adultAmount
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(data[indextr].adultAmount)
                                            )
                                          ]
                                        ),
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: {
                                              data: data[indextr].childAmount
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(data[indextr].childAmount)
                                            )
                                          ]
                                        ),
                                        _c(
                                          "vs-td",
                                          {
                                            attrs: {
                                              data: data[indextr].infantAmount
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(data[indextr].infantAmount)
                                            )
                                          ]
                                        ),
                                        _c(
                                          "vs-td",
                                          [
                                            _c(
                                              "vx-tooltip",
                                              {
                                                attrs: {
                                                  text:
                                                    _vm.$t(
                                                      _vm.resources.View.i18n
                                                    ) || _vm.resources.View.name
                                                }
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.showPopUpDetail(
                                                          data[indextr]
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("feather-icon", {
                                                      staticClass:
                                                        "w-5 h-5 mr-1",
                                                      attrs: { icon: "EyeIcon" }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            ),
                                            _c(
                                              "vx-tooltip",
                                              {
                                                attrs: {
                                                  text:
                                                    _vm.$t(
                                                      _vm.resources.Delete.i18n
                                                    ) ||
                                                    _vm.resources.Delete.name
                                                }
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.deleteDetailPackage(
                                                          data[indextr]
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("feather-icon", {
                                                      staticClass:
                                                        "w-5 h-5 mr-1",
                                                      attrs: {
                                                        icon: "XIcon",
                                                        svgClasses:
                                                          "fill-current text-danger"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            ),
                                            _c(
                                              "vx-tooltip",
                                              {
                                                attrs: {
                                                  text:
                                                    _vm.$t(
                                                      _vm.resources.Service.i18n
                                                    ) ||
                                                    _vm.resources.Service.name
                                                }
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.showPopUpService(
                                                          data[indextr]
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("feather-icon", {
                                                      staticClass:
                                                        "w-5 h-5 mr-1",
                                                      attrs: {
                                                        icon: "SettingsIcon"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  })
                                }
                              }
                            ],
                            null,
                            false,
                            3866994149
                          )
                        },
                        [
                          _c(
                            "template",
                            { slot: "thead" },
                            [
                              _c("vs-th", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(_vm.resources.Hotel.i18n) ||
                                      _vm.resources.Hotel.name
                                  )
                                )
                              ]),
                              _c("vs-th", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(_vm.resources.Accommodation.i18n) ||
                                      _vm.resources.Accommodation.name
                                  )
                                )
                              ]),
                              _c("vs-th", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(_vm.resources.StartDate.i18n) ||
                                      _vm.resources.StartDate.name
                                  )
                                )
                              ]),
                              _c("vs-th", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(_vm.resources.EndDate.i18n) ||
                                      _vm.resources.EndDate.name
                                  )
                                )
                              ]),
                              _c("vs-th", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(_vm.resources.Value.i18n) ||
                                      _vm.resources.Value.name
                                  )
                                )
                              ]),
                              _c("vs-th", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(_vm.resources.AdultAmount.i18n) ||
                                      _vm.resources.AdultAmount.name
                                  )
                                )
                              ]),
                              _c("vs-th", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(_vm.resources.ChildAmount.i18n) ||
                                      _vm.resources.ChildAmount.name
                                  )
                                )
                              ]),
                              _c("vs-th", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(_vm.resources.InfantAmount.i18n) ||
                                      _vm.resources.InfantAmount.name
                                  )
                                )
                              ]),
                              _c("vs-th", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(_vm.resources.Options.i18n) ||
                                      _vm.resources.Options.name
                                  )
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "vx-row mb-4" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/3 w-full mb-4" },
                  [
                    _c(
                      "vx-card",
                      {
                        staticClass: "mb-2",
                        attrs: {
                          title:
                            _vm.$t(_vm.resources.Configuration.i18n) ||
                            _vm.resources.Configuration.name
                        }
                      },
                      [
                        _c(
                          "vs-row",
                          {
                            staticClass: "mb-2",
                            attrs: { "vs-type": "flex", "vs-justify": "center" }
                          },
                          [
                            _c(
                              "vs-col",
                              {
                                attrs: {
                                  "vs-type": "flex",
                                  "vs-justify": "center",
                                  "vs-align": "center",
                                  "vs-w": "3"
                                }
                              },
                              [
                                _c("vs-button", {
                                  attrs: {
                                    type: "border",
                                    color: "primary",
                                    "icon-pack": "feather",
                                    icon: "icon-plus",
                                    size: "large"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.popUpConfigurationPackage = true
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col w-full" },
                          [
                            _c(
                              "vs-table",
                              {
                                attrs: {
                                  "max-items": "10",
                                  pagination: "",
                                  data: _vm.packageConfigurationOption
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var data = ref.data
                                        return _vm._l(data, function(
                                          tr,
                                          indextr
                                        ) {
                                          return _c(
                                            "vs-tr",
                                            { key: indextr },
                                            [
                                              _c(
                                                "vs-td",
                                                {
                                                  attrs: {
                                                    data:
                                                      data[indextr]
                                                        .countryOperationName
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      data[indextr]
                                                        .countryOperationName
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "vs-td",
                                                {
                                                  attrs: {
                                                    data:
                                                      data[indextr].currencyCode
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      data[indextr].currencyCode
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "vs-td",
                                                {
                                                  attrs: {
                                                    data: data[indextr].isMain
                                                  }
                                                },
                                                [
                                                  data[indextr].isMain
                                                    ? _c("feather-icon", {
                                                        staticClass:
                                                          "w-5 h-5 mr-1",
                                                        attrs: {
                                                          icon: "CheckIcon",
                                                          svgClasses:
                                                            "fill-current text-success"
                                                        }
                                                      })
                                                    : _c("feather-icon", {
                                                        staticClass:
                                                          "w-5 h-5 mr-1",
                                                        attrs: {
                                                          icon: "XIcon",
                                                          svgClasses:
                                                            "fill-current text-danger"
                                                        }
                                                      })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "vs-td",
                                                [
                                                  _c(
                                                    "vx-tooltip",
                                                    {
                                                      attrs: {
                                                        text:
                                                          _vm.$t(
                                                            _vm.resources.Delete
                                                              .i18n
                                                          ) ||
                                                          _vm.resources.Delete
                                                            .name
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.deletePackageConfiguration(
                                                                data[indextr]
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("feather-icon", {
                                                            staticClass:
                                                              "w-5 h-5 mr-1",
                                                            attrs: {
                                                              icon: "XIcon",
                                                              svgClasses:
                                                                "fill-current text-danger"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        })
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3580581972
                                )
                              },
                              [
                                _c(
                                  "template",
                                  { slot: "thead" },
                                  [
                                    _c("vs-th", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            _vm.resources.CountryOperation.i18n
                                          ) ||
                                            _vm.resources.CountryOperation.name
                                        )
                                      )
                                    ]),
                                    _c("vs-th", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            _vm.resources.CurrencyCode.i18n
                                          ) || _vm.resources.CurrencyCode.name
                                        )
                                      )
                                    ]),
                                    _c("vs-th", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(_vm.resources.Main.i18n) ||
                                            _vm.resources.Main.name
                                        )
                                      )
                                    ]),
                                    _c("vs-th", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(_vm.resources.Options.i18n) ||
                                            _vm.resources.Options.name
                                        )
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/3 w-full mb-4" },
                  [
                    _c(
                      "vx-card",
                      {
                        staticClass: "mb-2",
                        attrs: {
                          title:
                            _vm.$t(_vm.resources.Category.i18n) ||
                            _vm.resources.Category.name
                        }
                      },
                      [
                        _c(
                          "vs-row",
                          {
                            staticClass: "mb-2",
                            attrs: { "vs-type": "flex", "vs-justify": "center" }
                          },
                          [
                            _c(
                              "vs-col",
                              {
                                attrs: {
                                  "vs-type": "flex",
                                  "vs-justify": "center",
                                  "vs-align": "center",
                                  "vs-w": "3"
                                }
                              },
                              [
                                _c("vs-button", {
                                  attrs: {
                                    type: "border",
                                    color: "primary",
                                    "icon-pack": "feather",
                                    icon: "icon-plus",
                                    size: "large"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.popUpCategory = true
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col w-full" },
                          [
                            _c(
                              "vs-table",
                              {
                                attrs: {
                                  "max-items": "10",
                                  pagination: "",
                                  data: _vm.packageCategoryOption
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var data = ref.data
                                        return _vm._l(data, function(
                                          tr,
                                          indextr
                                        ) {
                                          return _c(
                                            "vs-tr",
                                            { key: indextr },
                                            [
                                              _c(
                                                "vs-td",
                                                {
                                                  attrs: {
                                                    data:
                                                      data[indextr].categoryName
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      data[indextr].categoryName
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "vs-td",
                                                [
                                                  _c(
                                                    "vx-tooltip",
                                                    {
                                                      attrs: {
                                                        text:
                                                          _vm.$t(
                                                            _vm.resources.Delete
                                                              .i18n
                                                          ) ||
                                                          _vm.resources.Delete
                                                            .name
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.deletePackageCategory(
                                                                data[indextr]
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("feather-icon", {
                                                            staticClass:
                                                              "w-5 h-5 mr-1",
                                                            attrs: {
                                                              icon: "XIcon",
                                                              svgClasses:
                                                                "fill-current text-danger"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        })
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3234475438
                                )
                              },
                              [
                                _c(
                                  "template",
                                  { slot: "thead" },
                                  [
                                    _c("vs-th", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(_vm.resources.Name.i18n) ||
                                            _vm.resources.Name.name
                                        )
                                      )
                                    ]),
                                    _c("vs-th", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(_vm.resources.Options.i18n) ||
                                            _vm.resources.Options.name
                                        )
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/3 w-full mb-4" },
                  [
                    _c(
                      "vx-card",
                      {
                        staticClass: "mb-2",
                        attrs: {
                          title:
                            _vm.$t(_vm.resources.Application.i18n) ||
                            _vm.resources.Application.name
                        }
                      },
                      [
                        _c(
                          "vs-row",
                          {
                            staticClass: "mb-2",
                            attrs: { "vs-type": "flex", "vs-justify": "center" }
                          },
                          [
                            _c(
                              "vs-col",
                              {
                                attrs: {
                                  "vs-type": "flex",
                                  "vs-justify": "center",
                                  "vs-align": "center",
                                  "vs-w": "3"
                                }
                              },
                              [
                                _c("vs-button", {
                                  attrs: {
                                    type: "border",
                                    color: "primary",
                                    "icon-pack": "feather",
                                    icon: "icon-plus",
                                    size: "large"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.popUpApplication = true
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col w-full" },
                          [
                            _c(
                              "vs-table",
                              {
                                attrs: {
                                  "max-items": "10",
                                  pagination: "",
                                  data: _vm.packageApplication
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var data = ref.data
                                        return _vm._l(data, function(
                                          tr,
                                          indextr
                                        ) {
                                          return _c(
                                            "vs-tr",
                                            { key: indextr },
                                            [
                                              _c(
                                                "vs-td",
                                                {
                                                  attrs: {
                                                    data:
                                                      data[indextr]
                                                        .applicationName
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      data[indextr]
                                                        .applicationName
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "vs-td",
                                                [
                                                  _c(
                                                    "vx-tooltip",
                                                    {
                                                      attrs: {
                                                        text:
                                                          _vm.$t(
                                                            _vm.resources.Delete
                                                              .i18n
                                                          ) ||
                                                          _vm.resources.Delete
                                                            .name
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.deletePackageApplication(
                                                                data[indextr]
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("feather-icon", {
                                                            staticClass:
                                                              "w-5 h-5 mr-1",
                                                            attrs: {
                                                              icon: "XIcon",
                                                              svgClasses:
                                                                "fill-current text-danger"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        })
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2400997124
                                )
                              },
                              [
                                _c(
                                  "template",
                                  { slot: "thead" },
                                  [
                                    _c("vs-th", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(_vm.resources.Name.i18n) ||
                                            _vm.resources.Name.name
                                        )
                                      )
                                    ]),
                                    _c("vs-th", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(_vm.resources.Options.i18n) ||
                                            _vm.resources.Options.name
                                        )
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c(
                      "vx-card",
                      {
                        staticClass: "mb-2",
                        attrs: {
                          title:
                            _vm.$t(_vm.resources.Supplier.i18n) ||
                            _vm.resources.Supplier.name
                        }
                      },
                      [
                        _c(
                          "vs-row",
                          {
                            staticClass: "mb-2",
                            attrs: { "vs-type": "flex", "vs-justify": "center" }
                          },
                          [
                            _c(
                              "vs-col",
                              {
                                attrs: {
                                  "vs-type": "flex",
                                  "vs-justify": "center",
                                  "vs-align": "center",
                                  "vs-w": "3"
                                }
                              },
                              [
                                _c("vs-button", {
                                  attrs: {
                                    type: "border",
                                    color: "primary",
                                    "icon-pack": "feather",
                                    icon: "icon-plus",
                                    size: "large"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.popUpSupplier = true
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col w-full" },
                          [
                            _c(
                              "vs-table",
                              {
                                attrs: {
                                  "max-items": "10",
                                  pagination: "",
                                  data: _vm.supplierPackage
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var data = ref.data
                                        return _vm._l(data, function(
                                          tr,
                                          indextr
                                        ) {
                                          return _c(
                                            "vs-tr",
                                            { key: indextr },
                                            [
                                              _c(
                                                "vs-td",
                                                {
                                                  attrs: {
                                                    data:
                                                      data[indextr].supplierName
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      data[indextr].supplierName
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "vs-td",
                                                {
                                                  attrs: {
                                                    data:
                                                      data[indextr]
                                                        .supplierAddress
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      data[indextr]
                                                        .supplierAddress
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "vs-td",
                                                {
                                                  attrs: {
                                                    data:
                                                      data[indextr]
                                                        .supplierEmail
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      data[indextr]
                                                        .supplierEmail
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "vs-td",
                                                {
                                                  attrs: {
                                                    data:
                                                      data[indextr]
                                                        .supplierPhone
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      data[indextr]
                                                        .supplierPhone
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "vs-td",
                                                [
                                                  _c(
                                                    "vx-tooltip",
                                                    {
                                                      attrs: {
                                                        text:
                                                          _vm.$t(
                                                            _vm.resources.Delete
                                                              .i18n
                                                          ) ||
                                                          _vm.resources.Delete
                                                            .name
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.deleteSupplierPackage(
                                                                data[indextr]
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("feather-icon", {
                                                            staticClass:
                                                              "w-5 h-5 mr-1",
                                                            attrs: {
                                                              icon: "XIcon",
                                                              svgClasses:
                                                                "fill-current text-danger"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        })
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2179728208
                                )
                              },
                              [
                                _c(
                                  "template",
                                  { slot: "thead" },
                                  [
                                    _c("vs-th", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(_vm.resources.Name.i18n) ||
                                            _vm.resources.Name.name
                                        )
                                      )
                                    ]),
                                    _c("vs-th", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(_vm.resources.Address.i18n) ||
                                            _vm.resources.Address.name
                                        )
                                      )
                                    ]),
                                    _c("vs-th", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(_vm.resources.Email.i18n) ||
                                            _vm.resources.Email.name
                                        )
                                      )
                                    ]),
                                    _c("vs-th", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            _vm.resources.Telephone.i18n
                                          ) || _vm.resources.Telephone.name
                                        )
                                      )
                                    ]),
                                    _c("vs-th", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(_vm.resources.Options.i18n) ||
                                            _vm.resources.Options.name
                                        )
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c(
                "vx-card",
                {
                  staticClass: "mb-2",
                  attrs: {
                    title:
                      _vm.$t(_vm.resources.Images.i18n) ||
                      _vm.resources.Images.name
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "vx-row mb-4" },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mb-2" },
                        [
                          _c("label", { staticClass: "btn btn-primary" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Attachment.i18n) ||
                                  _vm.t(_vm.resources.Attachment.name)
                              )
                            )
                          ]),
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: { type: "file", multiple: "" },
                            on: { change: _vm.onFileSelected }
                          })
                        ],
                        1
                      ),
                      _c(
                        "vs-button",
                        {
                          staticClass: "mt-4",
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.getuploadForm()
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.Save.i18n) ||
                                _vm.t(_vm.resources.Save.name)
                            )
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "carousel-example" },
                    [
                      _c(
                        "swiper",
                        { attrs: { options: _vm.swiperOption } },
                        [
                          _vm._l(_vm.imageList, function(item) {
                            return _c("swiper-slide", { key: item.id }, [
                              _c("img", {
                                staticClass: "responsive",
                                attrs: {
                                  src: item.pathImage,
                                  alt: item.packageName
                                }
                              }),
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteImage(item.id)
                                    }
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    staticClass: "w-9 h-9 mr-1",
                                    attrs: {
                                      icon: "TrashIcon",
                                      svgClasses: "text-danger"
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          }),
                          _c("div", {
                            staticClass:
                              "swiper-button-prev swiper-button-white",
                            attrs: { slot: "button-prev" },
                            slot: "button-prev"
                          }),
                          _c("div", {
                            staticClass:
                              "swiper-button-next swiper-button-white",
                            attrs: { slot: "button-next" },
                            slot: "button-next"
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "vs-popup",
        {
          attrs: {
            fullscreen: "",
            title:
              _vm.$t(_vm.resources.Detail.i18n) || _vm.resources.Detail.name,
            active: _vm.popUpProductDetail
          },
          on: {
            "update:active": function($event) {
              _vm.popUpProductDetail = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vx-row mb-base" }, [
            _c("div", { staticClass: "vx-col w-full md:w-1/4" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Hotels.i18n) ||
                          _vm.resources.Hotels.name
                      )
                    )
                  ]),
                  _c("v-select", {
                    attrs: {
                      clearable: true,
                      options: _vm.hotelOption,
                      reduce: function(data) {
                        return data.hotelId
                      },
                      label: "hotelName",
                      hint: "" + _vm.packageDetailDto.hotelId
                    },
                    on: {
                      input: function($event) {
                        return _vm.getAccommodation(
                          "" + _vm.packageDetailDto.hotelId
                        )
                      }
                    },
                    model: {
                      value: _vm.packageDetailDto.hotelId,
                      callback: function($$v) {
                        _vm.$set(_vm.packageDetailDto, "hotelId", $$v)
                      },
                      expression: "packageDetailDto.hotelId"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-col w-full md:w-1/4" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Accommodation.i18n) ||
                          _vm.resources.Accommodation.name
                      )
                    )
                  ]),
                  _c("v-select", {
                    attrs: {
                      clearable: true,
                      options: _vm.accommodationOption,
                      reduce: function(data) {
                        return data.id
                      },
                      label: "nameEs",
                      hint: "" + _vm.packageDetailDto.accommodationId
                    },
                    on: {
                      input: function($event) {
                        return _vm.getAccommodationDetail(
                          "" + _vm.packageDetailDto.accommodationId
                        )
                      }
                    },
                    model: {
                      value: _vm.packageDetailDto.accommodationId,
                      callback: function($$v) {
                        _vm.$set(_vm.packageDetailDto, "accommodationId", $$v)
                      },
                      expression: "packageDetailDto.accommodationId"
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/2 mb-4" },
              [
                _c(
                  "vs-divider",
                  { staticClass: "mt-4 mb-2", attrs: { color: "success" } },
                  [
                    _c("h5", { staticClass: "text-success" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(_vm.resources.AdditionalInformation.i18n) ||
                              _vm.resources.AdditionalInformation.name
                          ) +
                          " "
                      )
                    ])
                  ]
                ),
                _c("div", { staticClass: "vx-col w-full" }, [
                  _c("p", { staticClass: "mb-2" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(_vm.alerts.MsgAccommodationInfo.i18n) ||
                            _vm.alerts.MsgAccommodationInfo.i18n
                        ) +
                        " " +
                        _vm._s(
                          _vm.$t(_vm.resources.AdultAmount.i18n) ||
                            _vm.resources.AdultAmount.name
                        ) +
                        ": "
                    ),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.accommodationDetail.adultAmount))
                    ]),
                    _vm._v(
                      ", " +
                        _vm._s(
                          _vm.$t(_vm.resources.ChildAmount.i18n) ||
                            _vm.resources.ChildAmount.name
                        ) +
                        ": "
                    ),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.accommodationDetail.childAmount))
                    ]),
                    _vm._v(
                      ", " +
                        _vm._s(
                          _vm.$t(_vm.resources.InfantAmount.i18n) ||
                            _vm.resources.InfantAmount.name
                        ) +
                        ": "
                    ),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.accommodationDetail.infantAmount))
                    ]),
                    _vm._v(". ")
                  ])
                ])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/4" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-dollar-sign",
                    "icon-no-border": "",
                    type: "number",
                    label:
                      _vm.$t(_vm.resources.Price.i18n) ||
                      _vm.resources.Price.name
                  },
                  model: {
                    value: _vm.packageDetailDto.value,
                    callback: function($$v) {
                      _vm.$set(_vm.packageDetailDto, "value", $$v)
                    },
                    expression: "packageDetailDto.value"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "vx-col w-full mb-2 md:w-1/3" }, [
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col md:w-1/2 w-full" }, [
                  _c("ul", { staticClass: "vx-col w-full mt-5" }, [
                    _c(
                      "li",
                      [
                        _c(
                          "vs-checkbox",
                          {
                            model: {
                              value: _vm.packageDetailDto.isCommissionRate,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.packageDetailDto,
                                  "isCommissionRate",
                                  $$v
                                )
                              },
                              expression: "packageDetailDto.isCommissionRate"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Percent.i18n) ||
                                  _vm.resources.Percent.name
                              )
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "vx-col w-full md:w-1/2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        "icon-pack": "feather",
                        icon: "icon-dollar-sign",
                        "icon-no-border": "",
                        type: "number",
                        label:
                          _vm.$t(_vm.resources.Commission.i18n) ||
                          _vm.resources.Commission.name
                      },
                      model: {
                        value: _vm.packageDetailDto.commissionValue,
                        callback: function($$v) {
                          _vm.$set(_vm.packageDetailDto, "commissionValue", $$v)
                        },
                        expression: "packageDetailDto.commissionValue"
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _c("div", { staticClass: "vx-col w-full mb-2 md:w-1/3" }, [
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col md:w-1/2 w-full" }, [
                  _c("ul", { staticClass: "vx-col w-full mt-5" }, [
                    _c(
                      "li",
                      [
                        _c(
                          "vs-checkbox",
                          {
                            model: {
                              value: _vm.packageDetailDto.isIboRate,
                              callback: function($$v) {
                                _vm.$set(_vm.packageDetailDto, "isIboRate", $$v)
                              },
                              expression: "packageDetailDto.isIboRate"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Percent.i18n) ||
                                  _vm.resources.Percent.name
                              ) + " - IBO"
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "vx-col w-full md:w-1/2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        "icon-pack": "feather",
                        icon: "icon-dollar-sign",
                        "icon-no-border": "",
                        type: "number",
                        label:
                          (_vm.$t(_vm.resources.Commission.i18n) ||
                            _vm.resources.Commission.name) + " - IBO"
                      },
                      model: {
                        value: _vm.packageDetailDto.iboValue,
                        callback: function($$v) {
                          _vm.$set(_vm.packageDetailDto, "iboValue", $$v)
                        },
                        expression: "packageDetailDto.iboValue"
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "vx-row mb-base" }, [
            _c("div", { staticClass: "vx-col w-full md:w-3/4" }, [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full md:w-1/4" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        "icon-pack": "feather",
                        icon: "icon-hash",
                        "icon-no-border": "",
                        type: "number",
                        label:
                          _vm.$t(_vm.resources.AdultAmount.i18n) ||
                          _vm.resources.AdultAmount.name
                      },
                      model: {
                        value: _vm.packageDetailDto.adultAmount,
                        callback: function($$v) {
                          _vm.$set(_vm.packageDetailDto, "adultAmount", $$v)
                        },
                        expression: "packageDetailDto.adultAmount"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col w-full md:w-3/4" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.Age.i18n) ||
                            _vm.resources.Age.name
                        )
                      )
                    ]),
                    _c("vs-slider", {
                      attrs: { color: "success" },
                      model: {
                        value: _vm.adultAge,
                        callback: function($$v) {
                          _vm.adultAge = $$v
                        },
                        expression: "adultAge"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v("s "),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full md:w-1/4" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        "icon-pack": "feather",
                        icon: "icon-hash",
                        "icon-no-border": "",
                        type: "number",
                        label:
                          _vm.$t(_vm.resources.ChildAmount.i18n) ||
                          _vm.resources.ChildAmount.name
                      },
                      model: {
                        value: _vm.packageDetailDto.childAmount,
                        callback: function($$v) {
                          _vm.$set(_vm.packageDetailDto, "childAmount", $$v)
                        },
                        expression: "packageDetailDto.childAmount"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col w-full md:w-3/4" },
                  [
                    _c("vs-slider", {
                      attrs: { color: "success" },
                      model: {
                        value: _vm.childAge,
                        callback: function($$v) {
                          _vm.childAge = $$v
                        },
                        expression: "childAge"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full md:w-1/4" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        "icon-pack": "feather",
                        icon: "icon-hash",
                        "icon-no-border": "",
                        type: "number",
                        label:
                          _vm.$t(_vm.resources.InfantAmount.i18n) ||
                          _vm.resources.InfantAmount.name
                      },
                      model: {
                        value: _vm.packageDetailDto.infantAmount,
                        callback: function($$v) {
                          _vm.$set(_vm.packageDetailDto, "infantAmount", $$v)
                        },
                        expression: "packageDetailDto.infantAmount"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col w-full md:w-3/4" },
                  [
                    _c("vs-slider", {
                      attrs: { color: "success" },
                      model: {
                        value: _vm.infantAge,
                        callback: function($$v) {
                          _vm.infantAge = $$v
                        },
                        expression: "infantAge"
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/4" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.CancellationPolicy.i18n) ||
                        _vm.resources.CancellationPolicy.name
                    )
                  )
                ]),
                _c("vs-textarea", {
                  attrs: { rows: "4" },
                  model: {
                    value: _vm.packageDetailDto.cancellationPolicy,
                    callback: function($$v) {
                      _vm.$set(_vm.packageDetailDto, "cancellationPolicy", $$v)
                    },
                    expression: "packageDetailDto.cancellationPolicy"
                  }
                })
              ],
              1
            )
          ]),
          _c("vs-divider", { attrs: { position: "center" } }, [
            _c("strong", [
              _vm._v(
                _vm._s(
                  _vm.$t(_vm.resources.Date.i18n) || _vm.resources.Date.name
                )
              )
            ])
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col md:w-1/6 w-full mb-4 mt-5" }, [
              _c("ul", { staticClass: "centerx" }, [
                _c(
                  "li",
                  [
                    _c(
                      "vs-checkbox",
                      {
                        model: {
                          value: _vm.isRangeDate,
                          callback: function($$v) {
                            _vm.isRangeDate = $$v
                          },
                          expression: "isRangeDate"
                        }
                      },
                      [_vm._v("¿Es un rango de fechas?")]
                    )
                  ],
                  1
                )
              ])
            ]),
            _vm.isRangeDate
              ? _c("div", { staticClass: "vx-col md:w-1/6 w-full mb-4" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-hash",
                          number: "",
                          label:
                            _vm.$t(_vm.resources.AmountDays.i18n) ||
                            _vm.resources.AmountDays.name
                        },
                        model: {
                          value: _vm.amountDays,
                          callback: function($$v) {
                            _vm.amountDays = $$v
                          },
                          expression: "amountDays"
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "vx-row mb-base" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/6" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.StartDate.i18n) ||
                        _vm.resources.StartDate.name
                    )
                  )
                ]),
                _c("datepicker", {
                  attrs: {
                    language: _vm.languages[_vm.language],
                    format: "d MMMM yyyy"
                  },
                  model: {
                    value: _vm.startDate,
                    callback: function($$v) {
                      _vm.startDate = $$v
                    },
                    expression: "startDate"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/6" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.EndDate.i18n) ||
                        _vm.resources.EndDate.name
                    )
                  )
                ]),
                _c("datepicker", {
                  attrs: {
                    language: _vm.languages[_vm.language],
                    format: "d MMMM yyyy"
                  },
                  model: {
                    value: _vm.endDate,
                    callback: function($$v) {
                      _vm.endDate = $$v
                    },
                    expression: "endDate"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/6 mt-4" },
              [
                _c(
                  "vs-button",
                  {
                    attrs: { color: "primary", type: "filled" },
                    on: {
                      click: function($event) {
                        return _vm.addDates()
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(_vm.resources.Add.i18n) ||
                            _vm.resources.Add.name
                        ) +
                        " "
                    )
                  ]
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "vx-row mb-base" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/2 mb-2" },
              [
                _c(
                  "vs-table",
                  {
                    attrs: { data: _vm.datesList },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var data = ref.data
                          return _vm._l(data, function(tr, indextr) {
                            return _c(
                              "vs-tr",
                              { key: indextr },
                              [
                                _c(
                                  "vs-td",
                                  { attrs: { data: data[indextr].startDate } },
                                  [_vm._v(_vm._s(data[indextr].startDate))]
                                ),
                                _c(
                                  "vs-td",
                                  { attrs: { data: data[indextr].endDate } },
                                  [_vm._v(_vm._s(data[indextr].endDate))]
                                ),
                                _c(
                                  "vs-td",
                                  [
                                    _c(
                                      "vx-tooltip",
                                      {
                                        staticClass: "w-5 h-5 mr-1",
                                        attrs: {
                                          text:
                                            _vm.$t(
                                              _vm.resources.Disabled.i18n
                                            ) || _vm.resources.Disabled.name
                                        }
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteDateList(
                                                  data[indextr]
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("feather-icon", {
                                              staticClass: "w-5 h-5 mr-1",
                                              attrs: {
                                                icon: "XIcon",
                                                svgClasses:
                                                  "fill-current text-danger"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          })
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "template",
                      { slot: "thead" },
                      [
                        _c("vs-th", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.StartDate.i18n) ||
                                _vm.resources.StartDate.name
                            )
                          )
                        ]),
                        _c("vs-th", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.EndDate.i18n) ||
                                _vm.resources.EndDate.name
                            )
                          )
                        ]),
                        _c("vs-th", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.Options.i18n) ||
                                _vm.resources.Options.name
                            )
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  2
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "vx-col w-full md:w-1/6 mb-2" },
            [
              _c(
                "vs-button",
                {
                  attrs: { color: "primary", type: "filled" },
                  on: {
                    click: function($event) {
                      return _vm.createDetailPackage()
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(_vm.resources.Save.i18n) ||
                          _vm.resources.Save.name
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title:
              _vm.$t(_vm.resources.Detail.i18n) || _vm.resources.Detail.name,
            active: _vm.popUpProductDetailUpdate
          },
          on: {
            "update:active": function($event) {
              _vm.popUpProductDetailUpdate = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vx-row mb-base" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.StartDate.i18n) ||
                        _vm.resources.StartDate.name
                    )
                  )
                ]),
                _c("datepicker", {
                  attrs: {
                    language: _vm.languages[_vm.language],
                    format: "d MMMM yyyy",
                    readonly: ""
                  },
                  model: {
                    value: _vm.packageDetailDto.startDate,
                    callback: function($$v) {
                      _vm.$set(_vm.packageDetailDto, "startDate", $$v)
                    },
                    expression: "packageDetailDto.startDate"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.EndDate.i18n) ||
                        _vm.resources.EndDate.name
                    )
                  )
                ]),
                _c("datepicker", {
                  attrs: {
                    readonly: "",
                    language: _vm.languages[_vm.language],
                    format: "d MMMM yyyy"
                  },
                  model: {
                    value: _vm.packageDetailDto.endDate,
                    callback: function($$v) {
                      _vm.$set(_vm.packageDetailDto, "endDate", $$v)
                    },
                    expression: "packageDetailDto.endDate"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    readonly: "",
                    "icon-pack": "feather",
                    icon: "icon-hash",
                    "icon-no-border": "",
                    type: "number",
                    label:
                      _vm.$t(_vm.resources.AdultAmount.i18n) ||
                      _vm.resources.AdultAmount.name
                  },
                  model: {
                    value: _vm.packageDetailDto.adultAmount,
                    callback: function($$v) {
                      _vm.$set(_vm.packageDetailDto, "adultAmount", $$v)
                    },
                    expression: "packageDetailDto.adultAmount"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    readonly: "",
                    "icon-pack": "feather",
                    icon: "icon-hash",
                    "icon-no-border": "",
                    type: "number",
                    label:
                      _vm.$t(_vm.resources.ChildAmount.i18n) ||
                      _vm.resources.ChildAmount.name
                  },
                  model: {
                    value: _vm.packageDetailDto.childAmount,
                    callback: function($$v) {
                      _vm.$set(_vm.packageDetailDto, "childAmount", $$v)
                    },
                    expression: "packageDetailDto.childAmount"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    readonly: "",
                    "icon-pack": "feather",
                    icon: "icon-hash",
                    "icon-no-border": "",
                    type: "number",
                    label:
                      _vm.$t(_vm.resources.InfantAmount.i18n) ||
                      _vm.resources.InfantAmount.name
                  },
                  model: {
                    value: _vm.packageDetailDto.infantAmount,
                    callback: function($$v) {
                      _vm.$set(_vm.packageDetailDto, "infantAmount", $$v)
                    },
                    expression: "packageDetailDto.infantAmount"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full mb-2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    readonly: "",
                    "icon-pack": "feather",
                    icon: "icon-dollar-sign",
                    "icon-no-border": "",
                    type: "number",
                    label:
                      _vm.$t(_vm.resources.Price.i18n) ||
                      _vm.resources.Price.name
                  },
                  model: {
                    value: _vm.packageDetailDto.value,
                    callback: function($$v) {
                      _vm.$set(_vm.packageDetailDto, "value", $$v)
                    },
                    expression: "packageDetailDto.value"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "vx-col w-full" }, [
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col md:w-1/4 w-full" }, [
                  _c("ul", { staticClass: "vx-col w-full mt-5" }, [
                    _c(
                      "li",
                      [
                        _c(
                          "vs-checkbox",
                          {
                            model: {
                              value: _vm.packageDetailDto.isCommissionRate,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.packageDetailDto,
                                  "isCommissionRate",
                                  $$v
                                )
                              },
                              expression: "packageDetailDto.isCommissionRate"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Percent.i18n) ||
                                  _vm.resources.Percent.name
                              )
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "vx-col w-full md:w-3/4" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        readonly: "",
                        "icon-pack": "feather",
                        icon: "icon-dollar-sign",
                        "icon-no-border": "",
                        type: "number",
                        label:
                          _vm.$t(_vm.resources.Commission.i18n) ||
                          _vm.resources.Commission.name
                      },
                      model: {
                        value: _vm.packageDetailDto.commissionValue,
                        callback: function($$v) {
                          _vm.$set(_vm.packageDetailDto, "commissionValue", $$v)
                        },
                        expression: "packageDetailDto.commissionValue"
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _c("div", { staticClass: "vx-col w-full mb-2" }, [
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col md:w-1/4 w-full" }, [
                  _c("ul", { staticClass: "vx-col w-full mt-5" }, [
                    _c(
                      "li",
                      [
                        _c(
                          "vs-checkbox",
                          {
                            model: {
                              value: _vm.packageDetailDto.isIboRate,
                              callback: function($$v) {
                                _vm.$set(_vm.packageDetailDto, "isIboRate", $$v)
                              },
                              expression: "packageDetailDto.isIboRate"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Percent.i18n) ||
                                  _vm.resources.Percent.name
                              ) + " - IBO"
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "vx-col w-full md:w-3/4" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        readonly: "",
                        "icon-pack": "feather",
                        icon: "icon-dollar-sign",
                        "icon-no-border": "",
                        type: "number",
                        label:
                          (_vm.$t(_vm.resources.Commission.i18n) ||
                            _vm.resources.Commission.name) + " - IBO"
                      },
                      model: {
                        value: _vm.packageDetailDto.iboValue,
                        callback: function($$v) {
                          _vm.$set(_vm.packageDetailDto, "iboValue", $$v)
                        },
                        expression: "packageDetailDto.iboValue"
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      readonly: "",
                      "icon-pack": "feather",
                      icon: "icon-dollar-sign",
                      "icon-no-border": "",
                      label:
                        _vm.$t(_vm.resources.Hotels.i18n) ||
                        _vm.resources.Hotels.name
                    },
                    model: {
                      value: _vm.packageDetailDto.hotelName,
                      callback: function($$v) {
                        _vm.$set(_vm.packageDetailDto, "hotelName", $$v)
                      },
                      expression: "packageDetailDto.hotelName"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      readonly: "",
                      "icon-pack": "feather",
                      icon: "icon-dollar-sign",
                      "icon-no-border": "",
                      label:
                        _vm.$t(_vm.resources.Accommodation.i18n) ||
                        _vm.resources.Accommodation.name
                    },
                    model: {
                      value: _vm.packageDetailDto.accommodationName,
                      callback: function($$v) {
                        _vm.$set(_vm.packageDetailDto, "accommodationName", $$v)
                      },
                      expression: "packageDetailDto.accommodationName"
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("label", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.CancellationPolicy.i18n) ||
                        _vm.resources.CancellationPolicy.name
                    )
                  )
                ]),
                _c("vs-textarea", {
                  attrs: { rows: "5", readonly: "" },
                  model: {
                    value: _vm.packageDetailDto.cancellationPolicy,
                    callback: function($$v) {
                      _vm.$set(_vm.packageDetailDto, "cancellationPolicy", $$v)
                    },
                    expression: "packageDetailDto.cancellationPolicy"
                  }
                })
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title:
              _vm.$t(_vm.resources.Service.i18n) || _vm.resources.Service.name,
            active: _vm.popUpProductService
          },
          on: {
            "update:active": function($event) {
              _vm.popUpProductService = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vx-row mb-base" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Service.i18n) ||
                          _vm.resources.Service.name
                      )
                    )
                  ]),
                  _c("v-select", {
                    attrs: {
                      clearable: true,
                      options: _vm.serviceOption,
                      reduce: function(data) {
                        return data.id
                      },
                      label: "nameEs"
                    },
                    model: {
                      value: _vm.productServiceDto.serviceId,
                      callback: function($$v) {
                        _vm.$set(_vm.productServiceDto, "serviceId", $$v)
                      },
                      expression: "productServiceDto.serviceId"
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/6 mb-2 mt-5" },
              [
                _c(
                  "vs-button",
                  {
                    attrs: { color: "primary", type: "filled" },
                    on: {
                      click: function($event) {
                        return _vm.createProductService()
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(_vm.resources.Create.i18n) ||
                            _vm.resources.Create.name
                        ) +
                        " "
                    )
                  ]
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "vx-col w-full" },
            [
              _c(
                "vs-table",
                {
                  attrs: { data: _vm.serviceProductOption },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var data = ref.data
                        return _vm._l(data, function(tr, indextr) {
                          return _c(
                            "vs-tr",
                            { key: indextr },
                            [
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].serviceName } },
                                [_vm._v(_vm._s(data[indextr].serviceName))]
                              ),
                              _c(
                                "vx-tooltip",
                                {
                                  attrs: {
                                    text:
                                      _vm.$t(_vm.resources.View.i18n) ||
                                      _vm.resources.View.name
                                  }
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteProductService(
                                            data[indextr]
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("feather-icon", {
                                        staticClass: "w-5 h-5 mr-1",
                                        attrs: {
                                          icon: "XIcon",
                                          svgClasses: "fill-current text-danger"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        })
                      }
                    }
                  ])
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c("vs-th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.Name.i18n) ||
                              _vm.resources.Name.name
                          )
                        )
                      ]),
                      _c("vs-th", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.Options.i18n) ||
                              _vm.resources.Options.name
                          )
                        )
                      ])
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ]
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title:
              _vm.$t(_vm.resources.Detail.i18n) || _vm.resources.Detail.name,
            active: _vm.popUpConfigurationPackage
          },
          on: {
            "update:active": function($event) {
              _vm.popUpConfigurationPackage = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vx-row mb-base" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.CurrencyCode.i18n) ||
                        _vm.resources.CurrencyCode.name
                    )
                  )
                ]),
                _c("v-select", {
                  attrs: {
                    clearable: true,
                    options: _vm.currencyType,
                    reduce: function(info) {
                      return info.id
                    },
                    label: "display"
                  },
                  model: {
                    value: _vm.packageConfigurationDto.currencyId,
                    callback: function($$v) {
                      _vm.$set(_vm.packageConfigurationDto, "currencyId", $$v)
                    },
                    expression: "packageConfigurationDto.currencyId"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.CountryOperation.i18n) ||
                        _vm.resources.CountryOperation.name
                    )
                  )
                ]),
                _c("v-select", {
                  attrs: {
                    clearable: true,
                    options: _vm.countryOption,
                    reduce: function(item) {
                      return item.countryId
                    },
                    label: "countryName"
                  },
                  model: {
                    value: _vm.packageConfigurationDto.countryOperationId,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.packageConfigurationDto,
                        "countryOperationId",
                        $$v
                      )
                    },
                    expression: "packageConfigurationDto.countryOperationId"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "ul",
                {
                  staticClass: "vx-col w-full",
                  staticStyle: { "margin-top": "20px" }
                },
                [
                  _c(
                    "li",
                    [
                      _c(
                        "vs-checkbox",
                        {
                          model: {
                            value: _vm.packageConfigurationDto.isMain,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.packageConfigurationDto,
                                "isMain",
                                $$v
                              )
                            },
                            expression: "packageConfigurationDto.isMain"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.Main.i18n) ||
                                _vm.resources.Main.name
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ]),
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/6 mb-2 mt-5" },
              [
                _c(
                  "vs-button",
                  {
                    attrs: { color: "primary", type: "filled" },
                    on: {
                      click: function($event) {
                        return _vm.createPackageConfiguration()
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(_vm.resources.Save.i18n) ||
                            _vm.resources.Save.name
                        ) +
                        " "
                    )
                  ]
                )
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title:
              _vm.$t(_vm.resources.Category.i18n) ||
              _vm.resources.Category.name,
            active: _vm.popUpCategory
          },
          on: {
            "update:active": function($event) {
              _vm.popUpCategory = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vx-row mb-base" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Name.i18n) || _vm.resources.Name.name
                    )
                  )
                ]),
                _c("v-select", {
                  attrs: {
                    clearable: true,
                    options: _vm.categoryOption,
                    reduce: function(item) {
                      return item.id
                    },
                    label: "nameEs"
                  },
                  model: {
                    value: _vm.category.categoryId,
                    callback: function($$v) {
                      _vm.$set(_vm.category, "categoryId", $$v)
                    },
                    expression: "category.categoryId"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/6 mb-2 mt-5" },
              [
                _c(
                  "vs-button",
                  {
                    attrs: { color: "primary", type: "filled" },
                    on: {
                      click: function($event) {
                        return _vm.createPackageCategory()
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(_vm.resources.Save.i18n) ||
                            _vm.resources.Save.name
                        ) +
                        " "
                    )
                  ]
                )
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title:
              _vm.$t(_vm.resources.Application.i18n) ||
              _vm.resources.Application.name,
            active: _vm.popUpApplication
          },
          on: {
            "update:active": function($event) {
              _vm.popUpApplication = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vx-row mb-base" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Name.i18n) || _vm.resources.Name.name
                    )
                  )
                ]),
                _c("v-select", {
                  attrs: {
                    clearable: true,
                    options: _vm.applicationOption,
                    reduce: function(item) {
                      return item.id
                    },
                    label: "name"
                  },
                  model: {
                    value: _vm.application.applicationId,
                    callback: function($$v) {
                      _vm.$set(_vm.application, "applicationId", $$v)
                    },
                    expression: "application.applicationId"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/6 mb-2 mt-5" },
              [
                _c(
                  "vs-button",
                  {
                    attrs: { color: "primary", type: "filled" },
                    on: {
                      click: function($event) {
                        return _vm.createApplication()
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(_vm.resources.Save.i18n) ||
                            _vm.resources.Save.name
                        ) +
                        " "
                    )
                  ]
                )
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title:
              _vm.$t(_vm.resources.Supplier.i18n) ||
              _vm.resources.Supplier.name,
            active: _vm.popUpSupplier
          },
          on: {
            "update:active": function($event) {
              _vm.popUpSupplier = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vx-row mb-base" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Name.i18n) || _vm.resources.Name.name
                    )
                  )
                ]),
                _c("v-select", {
                  attrs: {
                    clearable: true,
                    options: _vm.supplierOption,
                    reduce: function(item) {
                      return item.id
                    },
                    label: "name"
                  },
                  model: {
                    value: _vm.supplierId,
                    callback: function($$v) {
                      _vm.supplierId = $$v
                    },
                    expression: "supplierId"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/6 mb-2 mt-5" },
              [
                _c(
                  "vs-button",
                  {
                    attrs: { color: "primary", type: "filled" },
                    on: {
                      click: function($event) {
                        return _vm.createSupplierPackage()
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(_vm.resources.Save.i18n) ||
                            _vm.resources.Save.name
                        ) +
                        " "
                    )
                  ]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }