<template>
  <div>
    <vx-card class="mb-4">
      <vs-row vs-type="flex" vs-justify="center">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <vs-button
            radius
            color="success"
            icon-pack="feather"
            icon="icon-plus"
            size="large"
            @click="
              $router.push('/management/package/detail/0').catch(() => {})
            "
          ></vs-button>
        </vs-col>
      </vs-row>

      <h4 class="mb-4">
        {{ $t(resources.Filters.i18n) || resources.Filters.name }}
      </h4>
      <div class="vx-row mb-2">
        <div class="vx-col md:w-1/4 mb-2">
          <label>{{
            $t(resources.Destination.i18n) || resources.Destination.name
          }}</label>
          <v-select
            id="searchCity"
            @keyup.native="getCityByName('destination')"
            v-model="destination"
            :clearable="true"
            :options="destinationOption"
            :placeholder="
              $t(resources.WriteDestination.i18n) ||
                resources.WriteDestination.name
            "
            :reduce="destination => destination.id"
            label="name"
          />
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2">
          <vs-select
            class="vx-col w-full"
            :label="$t(resources.Status.i18n) || resources.Status.name"
            v-model="status"
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="$t(item.i18n) || item.text"
              v-for="(item, index) in statusOption"
            />
          </vs-select>
        </div>
        <div class="vx-col w-full mb-2 mt-5">
          <vs-button
            color="primary"
            type="filled"
            class="mr-1"
            @click="get()"
          >
            {{ $t(resources.Search.i18n) || resources.Search.name }}
          </vs-button>
        </div>
      </div>
    </vx-card>

    <div id="ag-grid-demo">
      <vx-card>
        <h4 class="font-semibold mb-base">
          {{ $t(resources.Packages.i18n) || resources.Packages.name }}
        </h4>
        <div class="flex flex-wrap justify-between items-center">
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2">
                  {{
                    currentPage * paginationPageSize - (paginationPageSize - 1)
                  }}
                  -
                  {{
                    dataList.length - currentPage * paginationPageSize > 0
                      ? currentPage * paginationPageSize
                      : dataList.length
                  }}
                  of {{ dataList.length }}
                </span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
          <div
            class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
          >
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              :placeholder="$t(resources.Search.i18n) || resources.Search.name"
            />
            <vs-button
              class="mb-4 md:mb-0 mr-1"
              @click="gridApi.exportDataAsCsv()"
            >
              {{ $t(resources.ExportAs.i18n) || resources.ExportAs.name }}
            </vs-button>
          </div>
        </div>

        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="dataList"
          rowSelection="single"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          @row-clicked="onRowClicked"
        ></ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
  </div>
</template>
<script>
import utilities from "@/assets/resources/utilities.json";
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import axios from "axios";
import vSelect from "vue-select";
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
    vSelect
  },
  data() {
    return {
      alerts: alerts,
      resources: resources,
      utilities: utilities,
      dataList: [],
      destinationOption: [],
      destination: "",
      searchQuery: "",
      status: "true",
      statusOption: [
        // {
        //   text: "All",
        //   value: "",
        //   i18n: "All"
        // },
        {
          text: "Active",
          value: "true",
          i18n: "Active"
        },
        {
          text: "Inactive",
          value: "false",
          i18n: "Inactive"
        }
      ],
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: "#",
          field: "PackageId",
          filter: true,
          hide: true
        },
        {
          headerName: this.$i18n.t("City"),
          field: "cityName",
          filter: true,
          width: 300
        },
        {
          headerName: this.$i18n.t("NameEs"),
          field: "nameEs",
          filter: true,
          width: 250
        },
        {
          headerName: this.$i18n.t("NameEn"),
          field: "nameEn",
          filter: true,
          width: 250
        },
        {
          headerName: this.$i18n.t("ExpirationDate"),
          field: "ExpirationDate",
          filter: true
        },
        {
          headerName: this.$i18n.t("Popular"),
          field: "isPopular",
          width: 110,
          cellRenderer: function(event) {
            let object = "";
            if (event.node.data.isPopular) {
              object =
                '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-success), 1);">check_circle</i>';
            } else
              object =
                '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-danger), 1);">highlight_off</i>';

            return object;
          }
        },
        {
          headerName: this.$i18n.t("Active"),
          field: "active",
          width: 110,
          cellRenderer: function(event) {
            let object = "";
            if (event.node.data.active) {
              object =
                '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-success), 1);">check_circle</i>';
            } else
              object =
                '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-danger), 1);">highlight_off</i>';

            return object;
          }
        },
        {
          headerName: this.$i18n.t("CreatedBy"),
          field: "createdBy",
          filter: true
        },
        {
          headerName: this.$i18n.t("CreationDate"),
          field: "creationDate",
          filter: true
        }
      ]
    };
  },
  methods: {
    async getCityByName(item) {
      let getElementId;
      let textSearch;

      if (item == "destination") {
        getElementId = document.getElementById("searchCity");
        textSearch = getElementId.__vue__.search;
      } else {
        getElementId = document.getElementById("searchCityCreate");
        textSearch = getElementId.__vue__.search;
      }

      let _searchApi = `City/GetByCity/${this.$i18n.locale.toUpperCase()}/${textSearch}`;

      if (textSearch.length >= 3) {
        await axios({
          method: "GET",
          url: `${process.env.VUE_APP_APIVT}${_searchApi}`,
          headers: {
            "content-type": "application/json",
            Authorization: `bearer ${localStorage.getItem("tokenVT")}`
          }
        }).then(
          result => {
            if (!result.data) {
              this.$vs.notify({
                title: this.$i18n.t("Alert"),
                text: this.$i18n.t("MsgNotFoundInfo"),
                color: "warning",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle"
              });
              this.destinationOption = [];
            } else {
              this.destinationOption = result.data;
            }
          },
          error => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError") + "<br/>" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.destinationOption = [];
          }
        );
      }
    },

    async get() {
      this.$vs.loading();
      let _keys = {};
      if (this.status != "") _keys["active"] = this.status;
      if (this.destination != "" && this.destination != 0) _keys["destinationId"] = this.destination;

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Package/ListByFilters`,
        data: {
          searchKeys: _keys,
          page: 1,
          rows: 500,
          language: "EN",
          orderBy: "FechaCreacion-DESC"
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data.data != null) {
            this.dataList = result.data.data;
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.dataList = [];
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    async onRowClicked(event) {
      this.$router.push(`/management/package/detail/${event.data.packageId}`);
      // this.$router.push({
      //     name: "package-package-detail",
      //     params: {
      //       Id: event.data.packageId,
      //     },
      //   });
    },
    router() {
      this.$router.push({
        name: "package-package-detail",
        params: {
          Id: 0
        }
      });
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 20;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    }
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("level", null);
      } else this.gridOptions.columnApi.setColumnPinned("level", "left");
    }
  },
  async created() {
    await this.get();
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  }
};
</script>
