<!-- =========================================================================================
    File Name: Home.vue
    Description: Home Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <vx-card class="mb-2">
      <vs-row vs-type="flex" vs-justify="center">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
          <vs-button
            radius
            color="success"
            icon-pack="material-icons"
            icon="add"
            size="large"
            @click="$router.push({ name: 'booking-Create' })"
          ></vs-button>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
          <vs-button
            radius
            color="danger"
            type="gradient"
            icon-pack="feather"
            icon="icon-briefcase"
            size="large"
            @click="$router.push({ name: 'booking-byReference' })"
          ></vs-button>
        </vs-col>
      </vs-row>
      <h4 class="mb-4">
        {{ $t(resources.Filters.i18n) || resources.Filters.name }}
      </h4>
      <div class="vx-row">
        <div class="vx-col w-full md:w-1/6 mb-1">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon-no-border
              :label="
                $t(resources.BookingCode.i18n) || resources.BookingCode.name
              "
              v-validate="'alpha_dash'"
              v-model="bookingCode"
              name="bookingCode"
              maxlength="20"
            />
            <span class="text-danger text-sm">{{
              errors.first("bookingCode")
            }}</span>
          </div>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-1">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon-no-border
              :label="
                $t(resources.DistributorID.i18n) || resources.DistributorID.name
              "
              @keypress="onlyNumber"
              v-validate="'numeric|min_value:1'"
              v-model="distributorID"
              name="distributorID"
              maxlength="10"
            />
            <span class="text-danger text-sm">{{
              errors.first("distributorID")
            }}</span>
          </div>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-1">
          <label class="vs-input--label">
            {{ $t(resources.StartDate.i18n) || resources.StartDate.name }}
          </label>
          <datepicker
            :language="languages[language]"
            format="d MMMM yyyy"
            v-model="startCreationDate"
          ></datepicker>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-1">
          <label class="vs-input--label">
            {{ $t(resources.EndDate.i18n) || resources.EndDate.name }}
          </label>
          <datepicker
            :language="languages[language]"
            format="d MMMM yyyy"
            v-model="endCreationDate"
          ></datepicker>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-1">
          <div class="vx-col w-full">
            <vs-select
              class="w-full"
              icon-no-border
              :label="$t(resources.Managed.i18n) || resources.Managed.name"
              v-model="managed"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="$t(item.i18n) || item.text"
                v-for="(item, index) in managedOption"
              />
            </vs-select>
          </div>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-1">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.WayToPay.i18n) || resources.WayToPay.name
            }}</label>
            <v-select
              v-model="merchantTypeCode"
              :clearable="true"
              :options="merchantTypeList"
              :reduce="item => item.code"
              label="name"
            />
          </div>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-1">
          <vs-select
            class="vx-col w-full"
            :label="$t(resources.Paid.i18n) || resources.Paid.name"
            v-model="isPaid"
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="$t(item.i18n) || item.text"
              v-for="(item, index) in isPaidList"
            />
          </vs-select>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-1">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.Supplier.i18n) || resources.Supplier.name
            }}</label>
            <v-select
              v-model="gds"
              :clearable="true"
              :options="gdsOption"
              :reduce="gds => gds.id"
              label="name"
            />
          </div>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-1">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.Country.i18n) || resources.Country.name
            }}</label>
            <v-select
              v-model="country"
              :clearable="true"
              :options="countryOption"
              :reduce="item => item.countryCode"
              label="countryName"
            />
          </div>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-1">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.Stage.i18n) || resources.Stage.name
            }}</label>
            <v-select
              v-model="stage"
              :clearable="true"
              :options="stageOption"
              :reduce="item => item.id"
              label="name"
            />
          </div>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-1">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.Type.i18n) || resources.Type.name
            }}</label>
            <v-select
              v-model="type"
              :clearable="true"
              :options="typeOption"
              :reduce="item => item.id"
              label="name"
            />
          </div>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-1">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.Source.i18n) || resources.Source.name
            }}</label>
            <v-select
              v-model="source"
              :clearable="true"
              :options="sourceOption"
              :reduce="item => item.id"
              label="name"
            />
          </div>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-1">
        <label  class="vs-input--label">{{
            $t(resources.Airline.i18n) || resources.Airline.name
          }}</label>
          <v-select
            id="searchLender"
            @keyup.native="getAirlineByText()"
            v-model="airlineId"
            :clearable="true"
            :options="airlineOption"
            :placeholder="$t(resources.Select.i18n) || resources.Select.name"
            :reduce="item => item.id"
            label="name"
          />
        </div>
        <div class="vx-col w-full md:w-1/6 mb-1">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon-no-border
              :label="
                $t(resources.TicketNumber.i18n) || resources.TicketNumber.name
              "
              v-validate="'alpha_dash'"
              v-model="ticketNumber"
              name="ticketNumber"
              maxlength="20"
            />
            <span class="text-danger text-sm">{{
              errors.first("ticketNumber")
            }}</span>
          </div>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-1">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon-no-border
              :label="$t(resources.Invoice.i18n) || resources.Invoice.name"
              v-validate="'alpha_dash'"
              v-model="invoice"
              name="invoice"
              maxlength="20"
            />
            <span class="text-danger text-sm">{{
              errors.first("invoice")
            }}</span>
          </div>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-1">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon-no-border
              :label="$t(resources.CreatedBy.i18n) || resources.CreatedBy.name"
              v-validate="'email'"
              v-model="createdBy"
              name="createdBy"
              maxlength="150"
            />
            <span class="text-danger text-sm">{{
              errors.first("createdBy")
            }}</span>
          </div>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-1">
          <div class="vx-col w-full">
            <vs-select
              class="w-full"
              icon-no-border
              :label="$t(resources.IsChange.i18n) || resources.IsChange.name"
              v-model="isChange"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="$t(item.i18n) || item.text"
                v-for="(item, index) in changeOptions"
              />
            </vs-select>
          </div>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-1">
          <div class="vx-col w-full">
            <vs-select
              class="w-full"
              icon-no-border
              :label="$t(resources.Discount.i18n) || resources.Discount.name"
              v-model="discount"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="$t(item.i18n) || item.text"
                v-for="(item, index) in discountOptions"
              />
            </vs-select>
          </div>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-1">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon-no-border
              :label="
                $t(resources.OrderNumber.i18n) || resources.OrderNumber.name
              "
              @keypress="onlyNumber"
              v-validate="'numeric|min_value:1'"
              v-model="orderNumber"
              name="orderNumber"
              maxlength="20"
            />
            <span class="text-danger text-sm">{{
              errors.first("orderNumber")
            }}</span>
          </div>
        </div>

        <div class="vx-col mt-5">
          <vs-button
            color="primary"
            type="filled"
            class="mr-1"
            @click="addfilters"
            >{{ $t(resources.Search.i18n) || resources.Search.name }}</vs-button
          >
          <vs-button color="warning" type="filled" @click="cleanFilters">{{
            $t(resources.Clean.i18n) || resources.Clean.name
          }}</vs-button>
        </div>
      </div>
    </vx-card>
      <vx-card :title="$t(resources.Bookings.i18n) || resources.Bookings.name">
        <div class="flex flex-wrap justify-between items-center">
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{
                    currentPage * paginationPageSize - (paginationPageSize - 1)
                  }}
                  -
                  {{
                    accessData.length - currentPage * paginationPageSize > 0
                      ? currentPage * paginationPageSize
                      : accessData.length
                  }}
                  of {{ totalRows }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <div
            class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
          >
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              :placeholder="$t(resources.Search.i18n) || resources.Search.name"
            />
            <vs-button
              class="mb-4 md:mb-0 mr-1"
              @click="gridApi.exportDataAsCsv()"
            >
              {{ $t(resources.ExportAs.i18n) || resources.ExportAs.name }}
            </vs-button>
          </div>
        </div>

        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="accessData"
          rowSelection="single"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          @row-clicked="onRowClicked"
        ></ag-grid-vue>
        <vs-pagination
          :total="totalNumberPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
  </div>
</template>

<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import vSelect from "vue-select";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import moment from "moment";

export default {
  components: {
    AgGridVue,
    vSelect,
    Datepicker
  },
  data() {
    return {
      languages: lang,
      language: this.$i18n.locale,
      bookingCode: localStorage.getItem("bookingCode"),
      createdBy: localStorage.getItem("createdBy"),
      distributorID: localStorage.getItem("itaCode"),
      gds: parseInt(localStorage.getItem("supplierId")),
      gdsOption: [],
      source: parseInt(localStorage.getItem("bookingSourceId")),
      sourceOption: [],
      orderNumber: "",
      resources: resources,
      alerts: alerts,
      activeConfirm: false,
      accessData: [],
      totalRows: 0,
      totalNumberPages: 0,
      country: localStorage.getItem("countryCode"),
      countryOption: [],
      type: parseInt(localStorage.getItem("productId")),
      typeOption: [],
      managed: localStorage.getItem("isManaged"),
      discount: localStorage.getItem("isDiscount"),
      stage: parseInt(localStorage.getItem("stageId")),
      isPaid: localStorage.getItem("isPaid"),
      invoice: localStorage.getItem("invoice"),
      isPaidList: [
        {
          text: "All",
          value: "",
          i18n: "All"
        },
        {
          text: "Yes",
          value: "1",
          i18n: "Yes"
        },
        {
          text: "No",
          value: "0",
          i18n: "No"
        }
      ],
      managedOption: [
        { text: "All", value: "", i18n: "All" },
        { text: "No", value: "0", i18n: "No" },
        { text: "Yes", value: "1", i18n: "Yes" }
      ],
      stageOption: [],
      discountOptions: [
        { text: "All", value: "", i18n: "All" },
        { text: "No", value: "0", i18n: "No" },
        { text: "Yes", value: "1", i18n: "Yes" }
      ],
      isChange: localStorage.getItem("isChange"),
      changeOptions: [
        { text: "All", value: "", i18n: "All" },
        { text: "No", value: "0", i18n: "No" },
        { text: "Yes", value: "1", i18n: "Yes" }
      ],
      merchantTypeCode: localStorage.getItem("merchantTypeCode"),
      merchantTypeList: [],
      startCreationDate: localStorage.getItem("startCreationDate"),
      endCreationDate: localStorage.getItem("endCreationDate"),
      airlineOption: [],
      airlineId: "",
      ticketNumber: "",
      filterData: {},
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 10,
      gridApi: null,
      rows: 20,
      NumberPage: 0,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: this.$i18n.t("Paid"),
          field: "isPaid",
          width: 110,
          cellRenderer: function(event) {
            let object = "";
            switch (event.node.data.isPaid) {
              case 0:
                object =
                  '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-danger), 1);">highlight_off</i>';
                break;
              case 1:
                object =
                  '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-success), 1);">check_circle</i>';
                break;
              default:
                object =
                  '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-danger), 1);">highlight_off</i>';
                break;
            }
            return object;
          }
        },
        {
          headerName: this.$i18n.t("WayToPay"),
          field: "merchantCode",
          filter: true,
          width: 150
        },
        {
          headerName: this.$i18n.t("MainBooking"),
          field: "bookingCode",
          filter: true
        },
        {
          headerName: this.$i18n.t("Code"),
          field: "bookingReference",
          filter: true,
          width: 150
        },
        {
          headerName: this.$i18n.t("Ita"),
          field: "itaCode",
          filter: true,
          width: 120
        },
        {
          headerName: this.$i18n.t("Type"),
          field: "productCode",
          filter: true,
          width: 120
        },
        {
          headerName: this.$i18n.t("Country"),
          field: "countryCode",
          filter: true,
          width: 120
        },
        {
          headerName: this.$i18n.t("Stage"),
          field: "stageName",
          filter: true,
          width: 150
        },
        {
          headerName: this.$i18n.t("AssignedTo"),
          field: "responsible",
          filter: true,
          width: 150
        },
        {
          headerName: this.$i18n.t("CreateDate"),
          field: "createdDate",
          filter: true
        },
        {
          headerName: this.$i18n.t("StartDate"),
          field: "startDate",
          filter: true
        },
        {
          headerName: this.$i18n.t("Source"),
          field: "bookingSourceName",
          filter: true
        },
        {
          headerName: this.$i18n.t("Invoice"),
          field: "invoice",
          filter: true
        },
        {
          headerName: this.$i18n.t("IsChange"),
          field: "isChange",
          width: 110,
          cellRenderer: function(event) {
            let object = "";
            if (event.node.data.isChange) {
              object =
                '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-success), 1);">check_circle</i>';
            } else
              object =
                '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-danger), 1);">highlight_off</i>';

            return object;
          }
        },
        {
          headerName: this.$i18n.t("Signature"),
          field: "signatureStatusId",
          width: 110,
          cellRenderer: function(event) {
            let object = "";
            if (event.node.data.signatureStatusId == 2) {
              object =
                '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-success), 1);">check_circle</i>';
            } else if (event.node.data.signatureStatusId == 1)
              object =
                '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-warning), 1);">warning</i>';
            else
              object =
                '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-danger), 1);">highlight_off</i>';

            return object;
          }
        },
        {
          headerName: this.$i18n.t("OrderNumber"),
          field: "infotraxOrder",
          filter: true,
          width: 150
        },
        {
          headerName: this.$i18n.t("Currency"),
          field: "currencyCode",
          filter: true
        },
        {
          headerName: this.$i18n.t("Passenger"),
          field: "passengerInfo",
          filter: true
        },
        {
          headerName: this.$i18n.t("NetValue"),
          field: "value",
          filter: true
        },
        {
          headerName: this.$i18n.t("Tax"),
          field: "tax",
          filter: true
        },
        {
          headerName: this.$i18n.t("FeeValue"),
          field: "fee",
          filter: true
        },
        {
          headerName: this.$i18n.t("MerchantValue"),
          field: "merchant",
          filter: true
        },
        {
          headerName: this.$i18n.t("AgentCommissionValue"),
          field: "agentCommissionValue",
          filter: true
        },
        {
          headerName: this.$i18n.t("BookingValue"),
          field: "total",
          filter: true
        },
        {
          headerName: this.$i18n.t("DiscountValue"),
          field: "discount",
          filter: true
        },
        {
          headerName: this.$i18n.t("FeeCustomized"),
          field: "feeCustomized",
          filter: true
        },
        {
          headerName: this.$i18n.t("Total"),
          field: "totalComplete",
          filter: true
        },
        {
          headerName: this.$i18n.t("PaymentsReceived"),
          field: "totalPayment",
          filter: true
        },
        {
          headerName: this.$i18n.t("Difference"),
          field: "difference",
          filter: true
        },
        {
          headerName: `${this.$i18n.t("ExchangeRate")} - ${this.$i18n.t(
            "CustomizedFee"
          )}`,
          field: "customizedRateValue",
          filter: true,
          width: 220
        },
        {
          headerName: `${this.$i18n.t("Value")} - ${this.$i18n.t(
            "Commission"
          )}`,
          field: "itacommission",
          filter: true
        },
        {
          headerName: `${this.$i18n.t("BVPoints")}`,
          field: "bvPoints",
          filter: true
        },
        {
          headerName: this.$i18n.t("CreatedBy"),
          field: "createdBy",
          filter: true,
          width: 220
        }
      ]
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("level", null);
      } else this.gridOptions.columnApi.setColumnPinned("level", "left");
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 20;
    },
    totalPages() {
      if (this.totalNumberPages)
        return this.totalNumberPages.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    }
  },
  async created() {
    await this.addfilters();
    //await this.getTokenIfx();
    await this.getMerchantType();
    await this.getCountries();
    await this.getProducts();
    await this.getBookingSource();
    await this.getGds();
    await this.getStage();
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    async addfilters(event = null) {
      this.filterData = {};

      if (this.managed != "" && this.managed != null) {
        this.$set(this.filterData, "isManaged", this.managed);
        localStorage.setItem("isManaged", this.managed);
      } else localStorage.setItem("isManaged", "");

      if (this.bookingCode != "" && this.bookingCode != null) {
        this.$set(this.filterData, "bookingCode", this.bookingCode);
        localStorage.setItem("bookingCode", this.bookingCode);
      } else localStorage.setItem("bookingCode", "");

      if (this.distributorID != "" && this.distributorID != null) {
        this.$set(this.filterData, "itaCode", this.distributorID);
        localStorage.setItem("itaCode", this.distributorID);
      } else localStorage.setItem("itaCode", "");

      if (this.gds != 0 && this.gds != null && !isNaN(this.gds)) {
        this.$set(this.filterData, "supplierId", this.gds);
        localStorage.setItem("supplierId", this.gds);
      } else localStorage.setItem("supplierId", 0);

      if (this.source != 0 && this.source != null && !isNaN(this.source)) {
        this.$set(this.filterData, "bookingSourceId", this.source);
        localStorage.setItem("bookingSourceId", this.source);
      } else localStorage.setItem("bookingSourceId", 0);

      if (this.country != "" && this.country != null) {
        this.$set(this.filterData, "countryCode", this.country);
        localStorage.setItem("countryCode", this.country);
      } else localStorage.setItem("countryCode", "");

      if (this.type != 0 && this.type != null && !isNaN(this.type)) {
        this.$set(this.filterData, "productId", this.type);
        localStorage.setItem("productId", this.type);
      } else localStorage.setItem("productId", 0);

      if (this.stage != 0 && this.stage != null && !isNaN(this.stage)) {
        this.$set(this.filterData, "stageId", this.stage);
        localStorage.setItem("stageId", this.stage);
      } else localStorage.setItem("stageId", 0);

      if (this.orderNumber != "" && this.orderNumber != null) {
        this.$set(this.filterData, "orderNumber", this.orderNumber);
        localStorage.setItem("orderNumber", this.orderNumber);
      } else localStorage.setItem("orderNumber", "");

      if (this.merchantTypeCode != "" && this.merchantTypeCode != null) {
        this.$set(this.filterData, "merchantCode", this.merchantTypeCode);
        localStorage.setItem("merchantTypeCode", this.merchantTypeCode);
      } else localStorage.setItem("merchantTypeCode", "");

      if (this.isPaid != "" && this.isPaid != null) {
        this.$set(this.filterData, "isPaid", this.isPaid);
        localStorage.setItem("isPaid", this.isPaid);
      } else localStorage.setItem("isPaid", "");

      if (this.isChange != "" && this.isChange != null) {
        this.$set(this.filterData, "isChange", this.isChange);
        localStorage.setItem("isChange", this.isChange);
      } else localStorage.setItem("isChange", "");

      if (this.startCreationDate != "" && this.startCreationDate != null) {
        let _date = moment(this.startCreationDate).format("YYYY-MM-DD");
        this.$set(this.filterData, "startCreationDate", _date);
        localStorage.setItem("startCreationDate", _date);
      } else localStorage.setItem("startCreationDate", "");

      if (this.endCreationDate != "" && this.endCreationDate != null) {
        let _date = moment(this.endCreationDate).format("YYYY-MM-DD");
        this.$set(this.filterData, "endCreationDate", _date);
        localStorage.setItem("endCreationDate", _date);
      } else localStorage.setItem("endCreationDate", "");

      if (this.createdBy != "" && this.createdBy != null) {
        this.$set(this.filterData, "createdBy", this.createdBy);
        localStorage.setItem("createdBy", this.createdBy);
      } else localStorage.setItem("createdBy", "");

      if (this.discount != "" && this.discount != null) {
        this.$set(this.filterData, "isDiscount", this.discount);
        localStorage.setItem("isDiscount", this.discount);
      } else localStorage.setItem("isDiscount", "");

      if (this.invoice != "" && this.invoice != null) {
        this.$set(this.filterData, "invoice", this.invoice);
        localStorage.setItem("invoice", this.invoice);
      } else localStorage.setItem("invoice", "");

      if (this.airlineId != 0 && this.airlineId != null && !isNaN(this.airlineId)) {
        this.$set(this.filterData, "airlineId", this.airlineId);
      }

      this.getBookings(event);
    },

    async cleanFilters() {
      this.bookingCode = "";
      this.distributorID = "";
      this.gds = 0;
      this.source = "";
      this.country = "";
      this.type = 0;
      this.stage = 0;
      this.managed = "";
      this.orderNumber = "";
      this.isPaid = "";
      this.isChange = "";
      this.merchantTypeCode = "";
      this.startCreationDate = "";
      this.endCreationDate = "";
      this.createdBy = "";
      this.discount = "";
      this.invoice = "";
      this.airlineId = "";
      this.filterData = {};
      this.$set(this.filterData, "isManaged", this.managed);
      localStorage.setItem("bookingCode", "");
      localStorage.setItem("itaCode", "");
      localStorage.setItem("supplierId", this.gds);
      localStorage.setItem("bookingSourceId", 0);
      localStorage.setItem("countryCode", "");
      localStorage.setItem("productId", this.type);
      localStorage.setItem("stageId", this.stage);
      localStorage.setItem("orderNumber", this.orderNumber);
      localStorage.setItem("merchantTypeCode", this.merchantTypeCode);
      localStorage.setItem("isPaid", this.isPaid);
      localStorage.setItem("isChange", this.isChange);
      localStorage.setItem("startCreationDate", this.startCreationDate);
      localStorage.setItem("endCreationDate", this.endCreationDate);
      localStorage.setItem("createdBy", "");
      localStorage.setItem("isDiscount", "");
      localStorage.setItem("invoice", this.invoice);

      this.addfilters();
    },

    async getMerchantType() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }MerchantType/GetAll/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.merchantTypeList = [];
          } else {
            this.merchantTypeList = result.data.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getTokenIfx() {
      await axios({
        method: "GET",
        url: process.env.VUE_APP_OVAPI + "getPeriods",
        headers: {
          "Access-Control-Allow-Headers": "Content-Type",
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("loggedIn")
        }
      }).then(
        result => {
          localStorage.setItem("tokenInfo", result.data.infotoken);
          localStorage.setItem("periods", JSON.stringify(result.data.DATA));
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCountries() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }CountryOperation/GetPccCountry/${this.$i18n.locale.toUpperCase()}/1`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.countryOption = [];
          } else {
            this.countryOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getProducts() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Product/bytype/${this.$i18n.locale.toUpperCase()}/1`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.typeOption = [];
          } else {
            this.typeOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getGds() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Supplier`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.gdsOption = [];
          } else {
            this.gdsOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getBookingSource() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }BookingSource/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.sourceOption = [];
          } else {
            this.sourceOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getStage() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_OVAPI}Stage/${this.$i18n.locale}/1`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.stageOption = [
              {
                id: "",
                name: this.$i18n.t("All")
              }
            ];
          } else {
            result.data.push({
              id: "",
              name: this.$i18n.t("All")
            });
            this.stageOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getAirlineByText() {
      let getElementId = document.getElementById("searchLender");
      let textSearch = getElementId.__vue__.search;

      if (textSearch.length == 3) {
        await axios({
          method: "GET",
          url: `${process.env.VUE_APP_APIVT}Airline/GetByText/${textSearch}`,
          headers: {
            "content-type": "application/json",
            Authorization: `bearer ${localStorage.getItem("tokenVT")}`
          }
        }).then(
          result => {
            if (result.data == 0) {
              this.$vs.notify({
                title: this.$i18n.t("Alert"),
                text: this.$i18n.t("MsgNotFoundInfo"),
                color: "warning",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle"
              });
              this.airlineOption = [];
            } else {
              this.airlineOption = result.data;
            }
          },
          error => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError") + "<br/>" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.lenderOption = [];
          }
        );
      }
    },

    async getBookings(event) {
      if (
        moment(this.startCreationDate).isAfter(moment(this.endCreationDate))
      ) {
        this.$vs.notify({
          title: this.$i18n.t("Alert"),
          text: this.$i18n.t("MsgStartDateAfterEndDate"),
          color: "warning",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-x-circle"
        });
        return;
      }
      this.$validator.validateAll().then(async result => {
        if (!result) {
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: this.$i18n.t("MsgRequiredField"),
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
          return;
        }
        this.$vs.loading();
        let searchKeysData = this.filterData;

        await axios({
          method: "POST",
          url: `${process.env.VUE_APP_APIVT}Booking/ListByFilters`,
          data: {
            searchKeys: searchKeysData,
            page: this.NumberPage,
            rows: this.rows,
            language: this.$i18n.locale.toUpperCase(),
            orderBy: "FechaCreacion-DESC"
          },
          headers: {
            "content-type": "application/json",
            Authorization: `bearer ${localStorage.getItem("tokenVT")}`
          }
        }).then(
          result => {
            if (!result.data.success) {
              if (event !== null) {
                this.$vs.notify({
                  title: this.$i18n.t("Alert"),
                  text: this.$i18n.t("MsgNotFoundInfo"),
                  color: "warning",
                  iconPack: "feather",
                  position: "top-right",
                  icon: "icon-x-circle"
                });
              }
              this.accessData = [];
              this.totalRows = 0;
              this.totalNumberPages = 0;
            } else {
              this.accessData = result.data.data;
              this.totalRows = result.data.total;
              this.totalNumberPages = Math.round(result.data.total / this.rows);
            }
          },
          error => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError") + "<br/>" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.accessData = [];
          }
        );
        this.$vs.loading.close();
      });
    },

    async onRowClicked(event) {
      let bookingId = event.node.data.bookingId;
      this.$router.push({
        name: "booking-bookingdetail",
        params: {
          bookingId: bookingId
        }
      });
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  }
};
</script>
