<!-- =========================================================================================
    File Name: index.vue
    Description: list with information about genealogy
    ----------------------------------------------------------------------------------------
========================================================================================== -->

<template>
  <div>
    <vx-card class="mb-2">
      <h2
        class="ml-2 font-semibold mb-2"
      >{{ $t(resources.BinaryTree.i18n) || resources.BinaryTree.name }}</h2>
      <h6 class="mb-1">
        {{
        $t(alerts.ColorsTableGenealogy.i18n) ||
        alerts.ColorsTableGenealogy.i18n
        }}
      </h6>
      <vs-row>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-lg="1"
          vs-sm="6"
          vs-xs="6"
        >
          <vs-icon
            icon-pack="material-icons"
            icon="fiber_manual_record"
            size="small"
            color="success"
          ></vs-icon>
          {{ $t(resources.Distributor.i18n) || resources.Distributor.name }}
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-lg="1"
          vs-sm="6"
          vs-xs="6"
        >
          <vs-icon
            icon-pack="material-icons"
            icon="fiber_manual_record"
            size="small"
            color="warning"
          ></vs-icon>
          {{ $t(resources.Suspended.i18n) || resources.Suspended.name }}
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-lg="1"
          vs-sm="6"
          vs-xs="6"
        >
          <vs-icon icon-pack="material-icons" icon="fiber_manual_record" size="small" color="dark"></vs-icon>
          {{ $t(resources.Terminated.i18n) || resources.Terminated.name }}
        </vs-col>
      </vs-row>

      <div class="vx-row">
        <div class="vx-col w-full md:w-1/6 mb-base">
          <vs-input
            type="number"
            class="w-full"
            icon-pack="feather"
            icon="icon-user"
            icon-no-border
            :label="
              $t(resources.DistributorID.i18n) || resources.DistributorID.name
            "
            v-model="itaCodeForm"
          />
        </div>
        <div class="vx-col w-full md:w-1/6 mb-base">
          <vs-input
            type="number"
            class="w-full"
            icon-pack="feather"
            icon="icon-hash"
            icon-no-border
            :label="$t(resources.FromLevel.i18n) || resources.FromLevel.name"
            v-model="fromLevel"
          />
        </div>
        <div class="vx-col w-full md:w-1/6 mb-base">
          <vs-input
            type="number"
            class="w-full"
            icon-pack="feather"
            icon="icon-hash"
            icon-no-border
            :label="$t(resources.UntilLevel.i18n) || resources.UntilLevel.name"
            v-model="untilLevel"
          />
        </div>
        <div class="vx-col w-full md:w-1/6 mb-base">
          <vs-select
            class="vx-col w-full mb-base"
            :label="$t(resources.MemberType.i18n) || resources.MemberType.name"
            v-model="memberType"
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="$t(item.i18n) || item.text"
              v-for="(item, index) in types"
            />
          </vs-select>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-base">
          <vs-input
            type="number"
            class="w-full"
            icon-pack="feather"
            icon="icon-hash"
            icon-no-border
            :label="$t(resources.AmountRows.i18n) || resources.AmountRows.name"
            v-model="amountRow"
          />
        </div>
        <div class="vx-col w-full md:w-1/6 mb-base mt-4">
          <vs-button
            color="primary"
            type="filled"
            :disabled="!validateForm"
            @click="treeReport()"
          >{{ $t(resources.Search.i18n) || resources.Search.name }}</vs-button>
        </div>
      </div>
    </vx-card>

    <div id="ag-grid-demo">
      <vx-card>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center">
          <!-- ITEMS PER PAGE -->
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2">
                  {{
                  currentPage * paginationPageSize - (paginationPageSize - 1)
                  }}
                  -
                  {{
                  treeData.length - currentPage * paginationPageSize > 0
                  ? currentPage * paginationPageSize
                  : treeData.length
                  }}
                  of {{ treeData.length }}
                </span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
              <vs-dropdown-menu>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              :placeholder="$t(resources.Search.i18n) || resources.Search.name"
            />
            <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">
              {{
              $t(resources.ExportAs.i18n) || resources.ExportAs.name
              }}
            </vs-button>
          </div>
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="treeData"
          rowSelection="single"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
        ></ag-grid-vue>
        <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" />
      </vx-card>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import resources from "@/i18n/resources.js";
import ranks from "@/assets/resources/ranks.js";
import alerts from "@/i18n/alerts.js";

export default {
  components: {
    AgGridVue
  },
  data() {
    return {
      itaCode: JSON.parse(localStorage.getItem("userInfo")).itaCode,
      itaCodeForm: JSON.parse(localStorage.getItem("userInfo")).itaCode,
      fromLevel: 0,
      untilLevel: 6,
      ranks: ranks,
      resources: resources,
      alerts: alerts,
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      treeData: "",
      memberType: 0,
      amountRow: 1000,
      types: [
        {
          text: "All",
          value: "0",
          i18n: "All"
        },
        {
          text: "distributor",
          value: "D",
          i18n: "distributor"
        },
        {
          text: "Customer",
          value: "C",
          i18n: "Customer"
        },
        {
          text: "Terminated",
          value: "T",
          i18n: "Terminated"
        }
      ],
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: this.$i18n.t("Level"),
          field: "level",
          pinned: "left",
          filter: true,
          cellStyle: function(event) {
            let status = event.node.data.status;
            let style;
            switch (status) {
              case "D":
                style = {
                  color: "rgba(var(--vs-success), 1) !important",
                  "font-weight": "bold"
                };
                break;
              case "S":
                style = {
                  color: "rgba(var(--vs-warning), 1) !important",
                  "font-weight": "bold"
                };
                break;
              case "T":
                style = {
                  color: "rgba(var(--vs-dark), 1) !important",
                  "font-weight": "bold"
                };
                break;
              default:
                break;
            }
            return style;
          },
          width: 90
        },
        {
          headerName: this.$i18n.t("Center"),
          field: "bus_ctr",
          filter: true,
          width: 120
        },
        {
          headerName: this.$i18n.t("DistributorID"),
          field: "dist_id",
          filter: true
        },
        {
          headerName: this.$i18n.t("Name"),
          field: "name",
          filter: true
        },
        {
          headerName: this.$i18n.t("Enroller"),
          field: "sponsor",
          filter: true
        },
        {
          headerName: this.$i18n.t("Name"),
          field: "sponsor_name",
          filter: true
        },
        {
          headerName: this.$i18n.t("Side"),
          field: "sponsor_side",
          filter: true,
          width: 90
        },
        {
          headerName: this.$i18n.t("EndRank"),
          field: "end_rank",
          filter: true
        },
        {
          headerName: this.$i18n.t("VolumenPersonal"),
          field: "vol1",
          filter: true
        },
        {
          headerName: this.$i18n.t("Volumen"),
          field: "vol3",
          filter: true
        },
        {
          headerName: this.$i18n.t("PaidRank"),
          field: "paid_rank",
          filter: true
        },
        {
          headerName: this.$i18n.t("Status"),
          field: "status",
          filter: true,
          width: 100
        }
      ]
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("level", null);
      } else this.gridOptions.columnApi.setColumnPinned("level", "left");
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 20;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    },
    validateForm() {
      if (this.fromLevel > this.untilLevel) {
        let message = this.$i18n
          .t("MsgFromLevelLess")
          .replace("{0}", this.$i18n.t("FromLevel"))
          .replace("{1}", this.$i18n.t("UntilLevel"));

        this.$vs.notify({
          title: this.$i18n.t("Alert"),
          text: message,
          color: "warning",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-alert-circle"
        });
      }

      return !this.errors.any() && this.fromLevel < this.untilLevel;
    }
  },
  created: function() {
    this.treeReport();
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    async treeReport() {
      this.$vs.loading();

      let query =
        this.memberType != 0
          ? "status=" +
            this.memberType +
            "&level >= " +
            this.fromLevel +
            "&level<=" +
            this.untilLevel
          : "level >= " + this.fromLevel + "&level<=" + this.untilLevel;
      await axios({
        method: "POST",
        url: process.env.VUE_APP_OVAPI + "treeReport",
        data: {
          infotoken: localStorage.getItem("tokenInfo"),
          distid: this.itaCodeForm,
          qstr: query,
          returnfields:
            "dist-id, name,bus-ctr,sponsor,sponsor-name, sponsor-side, level, status, end-rank,paid-rank,vol3,vol1",
          limit: this.amountRow
        },
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("loggedIn")
        }
      }).then(
        result => {
          this.setTree(result.data.DATA);
        },
        error => {
          this.$vs.notify({
            title: "Error",
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    setTree(data) {
      let json = "[";

      if (data != undefined) {
        for (var i = 0; i < data.DIST_ID.length; i++) {
          let side =
            data.SPONSOR_SIDE[i] == "L"
              ? this.$i18n.t("Left")
              : this.$i18n.t("Right");

          let paidRank = this.ranks.find(
            ranks => ranks.value == data.PAID_RANK[i]
          ).i18n;

          let endRank = this.ranks.find(
            ranks => ranks.value == data.END_RANK[i]
          ).i18n;
          json = json.concat(
            '{"dist_id":"' +
              data.DIST_ID[i] +
              '","name":"' +
              data.NAME[i] +
              '","bus_ctr":"00' +
              data.BUS_CTR[i] +
              '", "level":"' +
              data.LEVEL[i] +
              '","sponsor":"' +
              data.SPONSOR[i] +
              '","sponsor_name":"' +
              data.SPONSOR_NAME[i] +
              '","sponsor_side":"' +
              side +
              '","status":"' +
              data.STATUS[i] +
              '","paid_rank":"' +
              this.$i18n.t(paidRank) +
              '","vol3":"' +
              '","end_rank":"' +
              this.$i18n.t(endRank) +
              '","vol3":"' +
              data.VOL3[i] +
              '","vol1":"' +
              data.VOL1[i] +
              '"},'
          );
        }
        json = json.substring(0, json.length - 1) + "]";
        let obj = JSON.parse(json);
        this.treeData = obj;
      } else this.treeData = [];
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  }
};
</script>
