var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    [
      _c("div", { staticClass: "vx-row mb-2" }, [
        _c("div", { staticClass: "vx-col md:w-1/6 w-full mb-base" }, [
          _c(
            "div",
            { staticClass: "vx-col w-full" },
            [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.Type.i18n) || _vm.resources.Type.name
                  )
                )
              ]),
              _c("v-select", {
                staticClass: "w-full",
                attrs: {
                  type: "text",
                  "icon-pack": "feather",
                  icon: "icon-arrow-down",
                  "icon-no-border": "",
                  clearable: true,
                  options: _vm.typeOption,
                  reduce: function(x) {
                    return x.id
                  },
                  label: "name"
                },
                model: {
                  value: _vm.type,
                  callback: function($$v) {
                    _vm.type = $$v
                  },
                  expression: "type"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "vx-col md:w-1/6 w-full mb-base" }, [
          _c(
            "div",
            { staticClass: "vx-col w-full" },
            [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.ContentType.i18n) ||
                      _vm.resources.ContentType.name
                  )
                )
              ]),
              _c("v-select", {
                staticClass: "w-full",
                attrs: {
                  type: "text",
                  "icon-pack": "feather",
                  icon: "icon-arrow-down",
                  "icon-no-border": "",
                  clearable: true,
                  options: _vm.contentTypeOption,
                  reduce: function(x) {
                    return x.id
                  },
                  label: "name"
                },
                model: {
                  value: _vm.contentType,
                  callback: function($$v) {
                    _vm.contentType = $$v
                  },
                  expression: "contentType"
                }
              })
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/6 w-full" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(
                _vm._s(
                  _vm.$t(_vm.resources.Language.i18n) ||
                    _vm.resources.Language.name
                )
              )
            ]),
            _c("v-select", {
              staticClass: "w-full",
              attrs: {
                type: "text",
                "icon-pack": "feather",
                icon: "icon-arrow-down",
                "icon-no-border": "",
                clearable: true,
                options: _vm.languageOption,
                reduce: function(language) {
                  return language.internationalStandard
                },
                label: "name"
              },
              model: {
                value: _vm.language,
                callback: function($$v) {
                  _vm.language = $$v
                },
                expression: "language"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col md:w-1/4 w-full" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(
                _vm._s(
                  _vm.$t(_vm.resources.Title.i18n) || _vm.resources.Title.name
                )
              )
            ]),
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "w-full",
              attrs: { name: "mailSubject", maxlength: 50 },
              model: {
                value: _vm.title,
                callback: function($$v) {
                  _vm.title = $$v
                },
                expression: "title"
              }
            }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("description"),
                    expression: "errors.has('description')"
                  }
                ],
                staticClass: "text-danger text-sm"
              },
              [_vm._v(_vm._s(_vm.errors.first("description")))]
            )
          ],
          1
        ),
        _c("div", { staticClass: "vx-col md:w-1/6 w-full mb-2" }, [
          _c("ul", { staticClass: "vx-col w-full mt-5" }, [
            _c(
              "li",
              [
                _c(
                  "vs-checkbox",
                  {
                    model: {
                      value: _vm.isFeatured,
                      callback: function($$v) {
                        _vm.isFeatured = $$v
                      },
                      expression: "isFeatured"
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.IsFeatured.i18n) ||
                          _vm.resources.IsFeatured.name
                      )
                    )
                  ]
                )
              ],
              1
            )
          ])
        ])
      ]),
      _c("quill-editor", {
        attrs: { options: _vm.editorOption },
        model: {
          value: _vm.description,
          callback: function($$v) {
            _vm.description = $$v
          },
          expression: "description"
        }
      }),
      _c(
        "div",
        { staticClass: "vx-col md:w-1/2 w-full mb-2 mt-2" },
        [
          _c(
            "label",
            {
              staticClass: "btn btn-primary",
              staticStyle: { "margin-right": "80px" }
            },
            [_vm._v("Adjunto")]
          ),
          _c("vs-input", {
            staticClass: "w-full",
            attrs: { type: "file" },
            on: { change: _vm.onFileSelected }
          })
        ],
        1
      ),
      _c(
        "vs-row",
        { attrs: { "vs-type": "flex", "vs-justify": "center" } },
        [
          _c(
            "vs-button",
            {
              staticClass: "mt-5 block text-left",
              attrs: { type: "filled" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.submitForm($event)
                }
              }
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t(_vm.resources.Save.i18n) || _vm.resources.Save.name
                )
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }