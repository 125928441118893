var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vx-card",
    [
      _c(
        "div",
        [
          _c("h4", { staticClass: "mb-2" }, [
            _vm._v(
              _vm._s(
                _vm.$t(_vm.resources.Comments.i18n) ||
                  _vm.t(_vm.resources.Comments.name)
              )
            )
          ]),
          _c(
            "div",
            { staticClass: "vx-row" },
            [
              _c("vs-input", {
                staticClass: "vx-col w-full md:w-1/2 mb-2 inputx",
                attrs: {
                  label:
                    _vm.$t(_vm.resources.Subject.i18n) ||
                    _vm.t(_vm.resources.Subject.name)
                }
              }),
              _c(
                "div",
                { staticClass: "vx-col md:w-1/2 w-full mb-2" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "btn btn-primary",
                      staticStyle: { "margin-right": "80px" }
                    },
                    [_vm._v("Adjunto")]
                  ),
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: { type: "file" },
                    model: {
                      value: _vm.file,
                      callback: function($$v) {
                        _vm.file = $$v
                      },
                      expression: "file"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("vs-textarea", {
            staticClass: "vx-col w-full md:w-1/2 mb-4",
            attrs: {
              label:
                _vm.$t(_vm.resources.Comments.i18n) ||
                _vm.t(_vm.resources.Comments.name)
            }
          }),
          _c(
            "vs-button",
            {
              staticClass: "mb-base",
              attrs: { color: "primary", type: "filled" },
              on: {
                click: function($event) {
                  return _vm.getuploadForm()
                }
              }
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t(_vm.resources.Save.i18n) ||
                    _vm.t(_vm.resources.Save.name)
                )
              )
            ]
          )
        ],
        1
      ),
      _c(
        "vs-tabs",
        [
          _c(
            "vs-tab",
            {
              attrs: {
                label:
                  _vm.$t(_vm.resources.Comments.i18n) ||
                  _vm.t(_vm.resources.Comments.name),
                "icon-pack": "feather",
                icon: "icon-message-circle"
              }
            },
            [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full mb-base" },
                  [
                    _c(
                      "vx-card",
                      [_c("vx-timeline", { attrs: { data: _vm.comments } })],
                      1
                    )
                  ],
                  1
                )
              ])
            ]
          ),
          _c(
            "vs-tab",
            {
              attrs: {
                label:
                  _vm.$t(_vm.resources.Documents.i18n) ||
                  _vm.t(_vm.resources.Documents.name),
                "icon-pack": "feather",
                icon: "icon-file"
              }
            },
            [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full mb-base" },
                  [
                    _c(
                      "vx-card",
                      { attrs: { title: "Activity" } },
                      [
                        _c("vx-timeline", {
                          attrs: { data: _vm.timelineDocuments }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ]
          ),
          _c(
            "vs-tab",
            {
              attrs: {
                label:
                  _vm.$t(_vm.resources.Trace.i18n) ||
                  _vm.t(_vm.resources.Trace.name),
                "icon-pack": "feather",
                icon: "icon-list"
              }
            },
            [
              _c(
                "div",
                [
                  _c(
                    "vs-table",
                    {
                      attrs: { data: _vm.trace },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var data = ref.data
                            return _vm._l(data, function(tr, indextr) {
                              return _c(
                                "vs-tr",
                                { key: indextr },
                                [
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: { data: data[indextr].createdDate }
                                    },
                                    [_vm._v(_vm._s(data[indextr].createdDate))]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: {
                                        data: data[indextr].previousStageName
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          data[indextr].previousStageName == ""
                                            ? "-"
                                            : data[indextr].previousStageName ==
                                                ""
                                        )
                                      )
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: {
                                        data: data[indextr].newStageName
                                      }
                                    },
                                    [_vm._v(_vm._s(data[indextr].newStageName))]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: { data: data[indextr].contactName }
                                    },
                                    [_vm._v(_vm._s(data[indextr].contactName))]
                                  )
                                ],
                                1
                              )
                            })
                          }
                        }
                      ])
                    },
                    [
                      _c(
                        "template",
                        { slot: "thead" },
                        [
                          _c("vs-th", { attrs: { align: "center" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Date.i18n) ||
                                  _vm.t(_vm.resources.Date.name)
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Previousstage.i18n) ||
                                  _vm.t(_vm.resources.Previousstage.name)
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Currentstage.i18n) ||
                                  _vm.t(_vm.resources.Currentstage.name)
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Responsable.i18n) ||
                                  _vm.t(_vm.resources.Responsable.name)
                              )
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }