<!-- =========================================================================================
    File Name: Profile.vue
    Description: Profile Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="vx-col w-full mb-2">
      <vs-row vs-type="flex" vs-justify="center" class="mb-2">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center">
          <h2 class="mb-1 text-primary">
            {{
              $t(resources.MonthlyPayment.i18n) || resources.MonthlyPayment.name
            }}
          </h2>
        </vs-col>
      </vs-row>
      <form-wizard
        ref="checkoutWizard"
        color="rgba(var(--vs-primary), 1)"
        :title="null"
        :subtitle="null"
        :hide-buttons="true"
      >
        <tab-content
          :title="
            $t(resources.PaymentMethod.i18n) || resources.PaymentMethod.name
          "
          icon="feather icon-credit-card"
          class="mb-5"
        >
          <div class="vx-row">
            <div class="vx-col lg:w-3/4 w-full relative">
              <vx-card
                class="mb-2"
                :title="$t(resources.Payment.i18n) || resources.Payment.name"
              >
                <div class="vx-col md:w-1/2 w-full mb-2">
                  <label class="vs-input--label">{{
                    $t(resources.WayToPay.i18n) || resources.WayToPay.name
                  }}</label>
                  <v-select
                    v-model="wayToPayOptionId"
                    :clearable="true"
                    :options="wayToPayOption"
                    :reduce="(data) => data.wayToPayId"
                    label="name"
                    v-on:input="setWayToPay(`${wayToPayOptionId}`)"
                    :hint="`${wayToPayOptionId}`"
                  />
                </div>
              </vx-card>

              <vx-card
                v-if="wayToPayOptionId == database.merchantType.creditCard"
                :title="
                  $t(resources.CreditCard.i18n) || resources.CreditCard.name
                "
              >
                <div class="vx-row">
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <label class="vs-input--label">{{
                        $t(resources.Type.i18n) || resources.Type.name
                      }}</label>
                      <v-select
                        v-model="creditcardPayment.creditCardTypeId"
                        :clearable="false"
                        :options="creditcardOption"
                        :reduce="(data) => data.cardId"
                        label="name"
                        v-on:input="
                          setInstallmentNumber(
                            `${creditcardPayment.creditCardTypeId}`
                          )
                        "
                        :hint="`${creditcardPayment.creditCardTypeId}`"
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-credit-card"
                        size="small"
                        type="number"
                        :label="
                          $t(resources.CreditCardNumber.i18n) ||
                          resources.CreditCardNumber.name
                        "
                        v-model="creditcardPayment.cardNumber"
                        v-validate="'required|credit_card'"
                        name="cardNumber"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('cardNumber')"
                        >{{ errors.first("cardNumber") }}</span
                      >
                    </div>
                  </div>
                  <div
                    class="vx-col md:w-1/3 w-full mb-2"
                    v-if="
                      installmentNumberOption &&
                      installmentNumberOption.length > 0
                    "
                  >
                    <div class="vx-col w-full">
                      <label class="vs-input--label">{{
                        $t(resources.InstallmentNumber.i18n) ||
                        resources.InstallmentNumber.name
                      }}</label>
                      <v-select
                        v-model="creditcardPayment.installmentNumber"
                        :clearable="false"
                        :options="installmentNumberOption"
                        :reduce="(data) => data.cardId"
                        label="name"
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-hash"
                        size="small"
                        type="password"
                        :maxlength="maxCvv"
                        :label="
                          $t(resources.SecurityCode.i18n) ||
                          resources.SecurityCode.name
                        "
                        v-model="creditcardPayment.cardSecurityCode"
                        name="SecurityCode"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('SecurityCode')"
                      >{{ errors.first("SecurityCode") }}</span
                    >
                  </div>
                  <div class="vx-col w-full md:w-1/3 mb-4">
                    <label>{{
                      $t(resources.ExpirationYear.i18n) ||
                      resources.ExpirationYear.name
                    }}</label>
                    <vs-select
                      icon-no-border
                      :placeholder="
                        $t(resources.ExpirationYear.i18n) ||
                        resources.ExpirationYear.name
                      "
                      v-model="expirationYear"
                      v-on:change="expirationYearChange($event)"
                    >
                      <vs-select-item
                        :key="index"
                        :value="item"
                        :text="item"
                        v-for="(item, index) in expirationYearOption"
                      />
                    </vs-select>
                  </div>
                  <div class="vx-col w-full md:w-1/3 mb-4">
                    <label>{{
                      $t(resources.ExpirationMonth.i18n) ||
                      resources.ExpirationMonth.name
                    }}</label>
                    <vs-select
                      icon-no-border
                      :placeholder="
                        $t(resources.ExpirationMonth.i18n) ||
                        resources.ExpirationMonth.name
                      "
                      v-model="expirationMonth"
                    >
                      <vs-select-item
                        :key="index"
                        :value="item"
                        :text="item"
                        v-for="(item, index) in expirationMonthOption"
                      />
                    </vs-select>
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-user"
                        size="small"
                        :label="$t(resources.Name.i18n) || resources.Name.name"
                        v-model="creditcardPayment.cardHolderName"
                        v-validate="{ required: true, regex: /^([a-zA-Z ]+)$/ }"
                        name="cardHolderName"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('cardHolderName')"
                      >{{ errors.first("cardHolderName") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-user"
                        size="small"
                        :label="
                          $t(resources.LastName.i18n) || resources.LastName.name
                        "
                        v-model="creditcardPayment.cardholderLastName"
                        v-validate="{ required: true, regex: /^([a-zA-Z ]+)$/ }"
                        name="cardholderLastName"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('cardholderLastName')"
                      >{{ errors.first("cardholderLastName") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-hash"
                        size="small"
                        type="number"
                        :label="
                          $t(resources.ZipCode.i18n) || resources.ZipCode.name
                        "
                        v-model="creditcardPayment.zipCode"
                        v-validate="'required|max:6'"
                        name="zipCodeCard"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('zipCodeCard')"
                      >{{ errors.first("zipCodeCard") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-mail"
                        size="small"
                        type="email"
                        :label="
                          $t(resources.Email.i18n) || resources.Email.name
                        "
                        v-model="creditcardPayment.email"
                        v-validate="'required|max:100|email'"
                        name="emailCard"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('emailCard')"
                      >{{ errors.first("emailCard") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-map-pin"
                        icon-no-border
                        size="small"
                        :label="
                          $t(resources.Address.i18n) || resources.Address.name
                        "
                        v-model="creditcardPayment.address"
                        v-validate="{
                          required: true,
                          regex: /^([a-zA-Z0-9 ]+)$/,
                        }"
                        name="addressCard"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('addressCard')"
                      >{{ errors.first("addressCard") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-hash"
                        icon-no-border
                        size="small"
                        :label="
                          $t(resources.DocumentNumber.i18n) ||
                          resources.DocumentNumber.name
                        "
                        v-model="creditcardPayment.documentNumber"
                        v-validate="'required|max:20|alpha_num'"
                        name="documentCard"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('documentCard')"
                      >{{ errors.first("documentCard") }}</span
                    >
                  </div>

                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-dollar-sign"
                        size="small"
                        type="number"
                        :label="
                          $t(resources.Value.i18n) || resources.Value.name
                        "
                        v-model="creditcardPayment.amount"
                        v-validate="'required|decimal:2'"
                        name="amountCard"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('amountCard')"
                      >{{ errors.first("amountCard") }}</span
                    >
                  </div>
                </div>
                <div class="vx-col mb-2 mt-5" style="text-align: right">
                  <vs-button
                    color="primary"
                    type="filled"
                    @click.prevent="validPayment()"
                  >
                    {{ $t(resources.Save.i18n) || resources.Save.name }}
                  </vs-button>
                </div>
              </vx-card>
              <vx-card
                v-if="wayToPayOptionId == database.merchantType.EWallet"
                :title="$t(resources.EWallet.i18n) || resources.EWallet.name"
              >
                <div class="vx-row">
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-user"
                        size="small"
                        type="number"
                        :label="
                          $t(resources.DistributorID.i18n) ||
                          resources.DistributorID.name
                        "
                        v-model="eWalletPayment.itaCodeWallet"
                        v-validate="'required|max:10|numeric'"
                        name="itaCodeWallet"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('itaCodeWallet')"
                      >{{ errors.first("itaCodeWallet") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-dollar-sign"
                        size="small"
                        type="number"
                        :label="
                          $t(resources.Value.i18n) || resources.Value.name
                        "
                        v-model="eWalletPayment.amount"
                        v-validate="'required|decimal:2'"
                        name="amountCard"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('amountCard')"
                      >{{ errors.first("amountCard") }}</span
                    >
                  </div>
                  <div class="vx-col mb-2 mt-5" style="text-align: right">
                    <vs-button
                      color="primary"
                      type="filled"
                      @click.prevent="validPayment()"
                    >
                      {{ $t(resources.Save.i18n) || resources.Save.name }}
                    </vs-button>
                  </div>
                </div>
              </vx-card>

              <vx-card
                v-if="wayToPayOptionId == database.merchantType.transfer"
                :title="$t(resources.Transfer.i18n) || resources.Transfer.name"
              >
                <div class="vx-row">
                  <div class="vx-col md:w-1/2 w-full mb-2">
                    <label class="vs-input--label">{{
                      $t(resources.BankName.i18n) || resources.BankName.name
                    }}</label>
                    <v-select
                      v-model="transferPayment.bankTypeId"
                      :clearable="false"
                      :options="bankType"
                      :reduce="(data) => data.id"
                      label="name"
                      v-on:input="setAccountNumber(transferPayment.bankTypeId)"
                      :hint="transferPayment.bankTypeId"
                    />
                  </div>
                  <div class="vx-col md:w-1/2 w-full mb-2">
                    <label class="vs-input--label">{{
                      $t(resources.AccountNumber.i18n) ||
                      resources.AccountNumber.name
                    }}</label>
                    <v-select
                      v-model="transferPayment.bankAccountId"
                      :clearable="false"
                      :options="accountNumberType"
                      :reduce="(data) => data.id"
                      label="account"
                    />
                  </div>
                  <div class="vx-col md:w-1/2 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-hash"
                        size="small"
                        type="number"
                        :label="
                          $t(resources.DocumentNumber.i18n) ||
                          resources.DocumentNumber.name
                        "
                        v-model="transferPayment.documentId"
                        name="documentCard"
                        v-validate="'required|max:20|alpha_num'"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('documentCard')"
                      >{{ errors.first("documentCard") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/2 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-hash"
                        size="small"
                        :label="
                          $t(resources.ReferenceCode.i18n) ||
                          resources.ReferenceCode.name
                        "
                        v-model="transferPayment.referenceCode"
                        name="referenceCode"
                        v-validate="'required|max:20|alpha_num'"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('referenceCode')"
                      >{{ errors.first("referenceCode") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/2 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-dollar-sign"
                        size="small"
                        type="number"
                        :label="
                          $t(resources.Value.i18n) || resources.Value.name
                        "
                        v-model="transferPayment.amount"
                        name="amountCard"
                        v-validate="'required|decimal:2'"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('amountCard')"
                      >{{ errors.first("amountCard") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/2 w-full mb-2">
                    <label class="btn btn-primary" style="margin-right: 80px">{{
                      $t(resources.Attachment.i18n) || resources.Attachment.name
                    }}</label>
                    <vs-input
                      type="file"
                      class="w-full"
                      @change="onFileSelected"
                    />
                  </div>
                </div>
                <div class="vx-col mb-2 mt-5" style="text-align: right">
                  <vs-button
                    color="primary"
                    type="filled"
                    @click.prevent="validPayment()"
                  >
                    {{ $t(resources.Save.i18n) || resources.Save.name }}
                  </vs-button>
                </div>
              </vx-card>
              <vx-card
                :title="
                  $t(resources.PaymentsReceived.i18n) ||
                  resources.PaymentsReceived.name
                "
              >
              <vs-row vs-type="flex" vs-justify="center" class="mb-base mt-2">
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
                  <vs-button
                    color="primary"
                    type="filled"
                    icon="add"
                    @click="openAdd()"
                    >{{ $t(resources.Add.i18n) || resources.Add.name }}
                  </vs-button>
                  </vs-col>
                </vs-row>
                <vs-table pagination max-items="10" :data="paymentList">
                  <template slot="thead">
                    <vs-th>{{
                      $t(resources.WayToPay.i18n) || resources.WayToPay.name
                    }}</vs-th>
                    <vs-th>{{
                      $t(resources.Description.i18n) ||
                      resources.Description.name
                    }}</vs-th>
                    <vs-th>{{
                      $t(resources.Value.i18n) || resources.Value.name
                    }}</vs-th>
                    <vs-th>{{
                      $t(resources.TransactionNumber.i18n) ||
                      resources.TransactionNumber.name
                    }}</vs-th>
                    <vs-th>{{
                      $t(resources.CreationDate.i18n) ||
                      resources.CreationDate.name
                    }}</vs-th>
                    <vs-th>{{
                      $t(resources.Status.i18n) || resources.Status.name
                    }}</vs-th>
                    <vs-th>{{
                      $t(resources.Options.i18n) || resources.Options.name
                    }}</vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                      <vs-td
                        v-if="
                          data[indextr].merchantTypeId ==
                          database.merchantType.transfer
                        "
                        :data="data[indextr].merchantType"
                      >
                        <vs-button
                          color="success"
                          type="line"
                          icon-pack="feather"
                          icon="icon-download-cloud"
                          @click="
                            openDocument(
                              `${urlVision}Payments/purchases/${data[indextr].attachmentId}.${data[indextr].attachmentExtension}`
                            )
                          "
                          >{{ data[indextr].merchantType }}</vs-button
                        >
                      </vs-td>
                      <vs-td v-else :data="data[indextr].merchantType">{{
                        data[indextr].merchantType
                      }}</vs-td>
                      <vs-td :data="data[indextr].information">{{
                        data[indextr].information
                      }}</vs-td>
                      <vs-td :data="data[indextr].value">{{
                        data[indextr].value
                      }}</vs-td>
                      <vs-td :data="data[indextr].transactionNumber">{{
                        data[indextr].transactionNumber
                      }}</vs-td>
                      <vs-td :data="data[indextr].creationDate">{{
                        data[indextr].creationDate
                      }}</vs-td>
                      <vs-td
                        v-if="
                          data[indextr].merchantTypeId ==
                            database.merchantType.EWallet &&
                          data[indextr].stageId == status.payment.pending
                        "
                        :data="data[indextr].merchantType"
                      >
                        <vs-button
                          color="success"
                          type="line"
                          icon-pack="feather"
                          icon="icon-refresh-ccw"
                          @click="
                            paymentEwallet(
                              `${data[indextr].information}`,
                              `${data[indextr].referenceId}`
                            )
                          "
                          >{{ data[indextr].stageName }}</vs-button
                        >
                      </vs-td>
                      <vs-td
                        v-else-if="
                          data[indextr].merchantTypeId ==
                            database.merchantType.transfer &&
                          data[indextr].stageId == status.payment.pending
                        "
                        :data="data[indextr].merchantType"
                      >
                        {{ data[indextr].stageName }}
                        <vs-button
                          color="success"
                          icon-pack="feather"
                          icon="icon-check"
                          @click="
                            paymentTransfer(
                              `${status.payment.paid}`,
                              `${data[indextr].referenceId}`
                            )
                          "
                        ></vs-button>
                        <vs-button
                          color="danger"
                          icon-pack="feather"
                          icon="icon-x"
                          @click="
                            paymentTransfer(
                              `${status.payment.declined}`,
                              `${data[indextr].referenceId}`
                            )
                          "
                        ></vs-button>
                      </vs-td>
                      <vs-td v-else :data="data[indextr].stageName">{{
                        data[indextr].stageName
                      }}</vs-td>
                      <vs-td>
                        <vs-button
                          color="primary"
                          type="line"
                          icon-pack="feather"
                          icon="icon-eye"
                          @click="
                            openResponseMerchant(
                              `${data[indextr].merchantResponse}`
                            )
                          "
                        ></vs-button>
                        <vs-button
                          color="primary"
                          type="line"
                          icon-pack="feather"
                          icon="icon-edit"
                          @click="
                            openEditPay(
                              data[indextr].stageId,
                              `${data[indextr].id}`
                            )
                          "
                        ></vs-button>
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </vx-card>
            </div>

            <!-- RIGHT COL: CONTINUE WITH SAVED ADDRESS -->
            <div class="vx-col lg:w-1/4 w-full">
              <billing :data="billingData" :installment="1"></billing>
              <vs-row
                vs-type="flex"
                vs-justify="center"
                class="mb-base"
                v-if="enabledButton"
              >
                <vs-col vs-type="flex" vs-justify="center" vs-align="center">
                  <vs-button
                    color="primary"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-heart"
                    size="large"
                    class="mt-4"
                    @click="validate()"
                    >{{
                      $t(resources.Generate.i18n) || resources.Generate.name
                    }}</vs-button
                  >
                </vs-col>
              </vs-row>
            </div>
          </div>
        </tab-content>
      </form-wizard>
      <vx-card
        :title="$t(resources.Management.i18n) || resources.Management.name"
        v-if="stageId != 0"
      >
        <h3 class="text-center text-primary mb-4">
          <strong
            >{{
              $t(resources.DistributorID.i18n) || resources.DistributorID.name
            }}:</strong
          >
          {{ itaCode }}&nbsp;|&nbsp;
          <strong
            >{{
              $t(resources.OrderNumber.i18n) || resources.OrderNumber.name
            }}:</strong
          >
          {{ orderNumber }}
        </h3>

        <vs-row vs-type="flex" vs-justify="center" class="mb-2">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center">
            <vs-button
              color="warning"
              type="filled"
              icon-pack="feather"
              icon="icon-edit"
              @click="popupEditOrder = true"
              >{{ $t(resources.Update.i18n) || resources.Update.name }} -
              {{
                $t(resources.OrderNumber.i18n) || resources.OrderNumber.name
              }}</vs-button
            >
          </vs-col>
        </vs-row>

        <h3 class="text-center text-success">
          <strong
            >{{ $t(resources.Stage.i18n) || resources.Stage.name }}:</strong
          >
          {{ stageName }}
        </h3>
        <vs-tabs>
          <vs-tab
            :label="$t(resources.Comments.i18n) || t(resources.Comments.name)"
            icon-pack="feather"
            icon="icon-message-circle"
          >
            <comments
              :purchaseId="`${purchaseId}`"
              :currentStage="stageId"
            ></comments>
          </vs-tab>
          <vs-tab
            :label="$t(resources.Tracking.i18n) || t(resources.Tracking.name)"
            icon-pack="feather"
            icon="icon-list"
          >
            <tracking
              :purchaseId="`${purchaseId}`"
              :currentStage="stageId"
            ></tracking>
          </vs-tab>
          <vs-tab
            style="align-text: right"
            :label="$t(resources.Email.i18n) || t(resources.Email.name)"
            icon-pack="feather"
            icon="icon-mail"
          >
            <newMessage
              :identifier="purchaseId"
              :entityId="other.entity.purchase"
              :type="2"
              :emailTo="emailIta"
            />
          </vs-tab>
        </vs-tabs>
      </vx-card>
    </div>
    <vs-popup
      :title="$t(resources.Detail.i18n) || resources.Detail.i18n"
      :active.sync="popupResMer"
    >
      <h1 class="text-center text-primary font-bold mb-base">
        {{ $t(resources.Answer.i18n) || resources.Answer.i18n }}:
      </h1>
      <p class="text-center mb-base">{{ responseMerchant }}</p>
    </vs-popup>
    <vs-popup
      :title="$t(resources.Update.i18n) || resources.Update.i18n"
      :active.sync="popupEdit"
    >
      <div class="vx-row">
        <div class="vx-col w-full md:w-1/2 mb-2">
          <label class="vs-input--label">{{
            $t(resources.Stage.i18n) || resources.Stage.name
          }}</label>
          <v-select
            v-model="stagePayment"
            :clearable="false"
            :options="stagePaymentList"
            :reduce="(x) => x.id"
            label="name"
            scrollable
          />
        </div>
        <div class="vx-col md:w-1/2 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-hash"
              label="ID"
              v-model="identifierPurchase"
              v-validate="'required|numeric'"
            />
          </div>
        </div>
        <div class="vx-col mb-base mt-5" style="text-align: right">
          <vs-button
            color="primary"
            type="filled"
            @click="updateIdentifierStage()"
          >
            {{ $t(resources.Save.i18n) || resources.Save.name }}
          </vs-button>
        </div>
      </div>
    </vs-popup>
    <vs-popup
      :title="$t(resources.Update.i18n) || resources.Update.i18n"
      :active.sync="popupEditOrder"
    >
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-hash"
              :label="
                $t(resources.OrderNumber.i18n) || resources.OrderNumber.name
              "
              v-model="orderNumber"
              v-validate="'required|numeric'"
            />
          </div>
        </div>
        <div class="vx-col mb-base mt-5" style="text-align: right">
          <vs-button
            color="primary"
            type="filled"
            @click="updateOrderAndStage()"
          >
            {{ $t(resources.Save.i18n) || resources.Save.name }}
          </vs-button>
        </div>
      </div>
    </vs-popup>
    <vs-popup
      :title="$t(resources.Upgrade.i18n) || resources.Upgrade.i18n"
      :active.sync="popup"
    >
      <h1 class="text-center text-primary font-bold mb-base">
        {{ $t(resources.Hello.i18n) || resources.Hello.i18n }}
        {{ contactDto.name }},
      </h1>
      <h4 class="text-center mb-base">
        {{ $t(resources.OrderGenerated.i18n) || resources.OrderGenerated.i18n }}
        <strong>{{ orderNumber }}</strong>
      </h4>
    </vs-popup>
    <!--Popup add payment-->
    <vs-popup fullscreen :title="popUpTitle" :active.sync="popupAddPayment">
      <div class="vx-row">
        <div class="vx-col md:w-1/4 w-full">
          <label class="vs-input--label">{{
            $t(resources.WayToPay.i18n) || resources.WayToPay.name
          }}</label>
          <v-select
            v-model="wayToPayOptionId"
            :clearable="false"
            :options="wayToPayOption"
            :reduce="(data) => data.wayToPayId"
            label="name"
            v-on:input="setWayToPay(`${wayToPayOptionId}`)"
            :hint="`${wayToPayOptionId}`"
            :disabled="canUpdate"
            v-validate="'required'"
            name="wayToPay"
          />
          <span class="text-danger text-sm" v-show="errors.has('wayToPay')">{{
            errors.first("wayToPay")
          }}</span>
        </div>
        <div class="vx-col md:w-1/4 w-full">
          <label class="vs-input--label">{{
            $t(resources.Merchant.i18n) || resources.Merchant.name
          }}</label>
          <v-select
            v-model="merchantId"
            :clearable="false"
            :options="merchantList"
            :reduce="(data) => data.merchantId"
            label="merchantName"
            :disabled="canUpdate"
            v-validate="'required'"
            name="merchant"
          />
          <span class="text-danger text-sm" v-show="errors.has('merchant')">{{
            errors.first("merchant")
          }}</span>
        </div>
        <div class="vx-col md:w-1/4 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-dollar-sign"
              type="number"
              :label="$t(resources.Value.i18n) || resources.Value.name"
              v-model="value"
              v-validate="'required|decimal:2'"
              name="value"
            />
          </div>
          <span class="text-danger text-sm" v-show="errors.has('value')">{{
            errors.first("value")
          }}</span>
        </div>
        <div class="vx-col md:w-1/4 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-hash"
              :label="
                $t(resources.AuthorizationNumber.i18n) ||
                resources.AuthorizationNumber.name
              "
              v-model="referenceCodeOffline"
              name="referenceCodeOffline"
              v-validate="'max:20|alpha_num'"
            />
          </div>
          <span
            class="text-danger text-sm"
            v-show="errors.has('referenceCodeOffline')"
            >{{ errors.first("referenceCodeOffline") }}</span
          >
        </div>
        <div class="vx-col md:w-1/4 w-full mb-2">
          <label class="vs-input--label">{{
            $t(resources.PaymentDate.i18n) || resources.PaymentDate.name
          }}</label>
          <flat-pickr
            class="vx-col w-full"
            :config="configdateTimePicker"
            v-model="paymentDate"
          />
        </div>
        <div class="vx-col w-full md:w-1/4 mb-2">
          <label class="vs-input--label">{{
            $t(resources.Stage.i18n) || resources.Stage.name
          }}</label>
          <v-select
            v-model="stageId"
            :clearable="false"
            :options="stageList"
            :reduce="(x) => x.id"
            label="name"
            scrollable
            v-validate="'required'"
            name="stage"
          />
          <span class="text-danger text-sm" v-show="errors.has('stage')">{{
            errors.first("stage")
          }}</span>
        </div>
        <div class="vx-col md:w-1/4 w-full" v-if="!canUpdate">
          <label>{{
            $t(resources.Remark.i18n) || resources.Remark.name
          }}</label>
          <vs-textarea v-model="merchantResponse" />
        </div>
        <div class="vx-col md:w-1/4 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-hash"
              :label="$t(resources.CreatedBy.i18n) || resources.CreatedBy.name"
              v-model="createdBy"
              name="createdBy"
              disabled
            />
          </div>
          <span class="text-danger text-sm" v-show="errors.has('createdBy')">{{
            errors.first("createdBy")
          }}</span>
        </div>
      </div>

      <h4 class="mb-4 mt-4">
        {{
          $t(resources.AdditionalInformation.i18n) ||
          resources.AdditionalInformation.name
        }}
      </h4>
      <div
        class="mb-2"
        v-if="wayToPayOptionId == database.merchantType.creditCard || wayToPayOptionId == database.merchantType.offline"
      >
        <ul class="centerx mb-4">
          <li>
            <vs-checkbox v-model="haveCardInfo">{{
              $t(alerts.MsgHaveCardInfo.i18n) || alerts.MsgHaveCardInfo.name
            }}</vs-checkbox>
          </li>
        </ul>

        <div class="vx-row" v-if="haveCardInfo">
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                $t(resources.Type.i18n) || resources.Type.name
              }}</label>
              <v-select
                v-model="creditCard.creditCardTypeId"
                :clearable="false"
                :options="creditcardOption"
                :reduce="(data) => data.cardId"
                label="name"
                v-on:input="
                  setInstallmentNumber(`${creditCard.creditCardTypeId}`)
                "
                :hint="`${creditCard.creditCardTypeId}`"
                :disabled="canUpdate"
                v-validate="'required'"
                name="cardType"
              />
            </div>
            <span class="text-danger text-sm" v-show="errors.has('cardType')">{{
              errors.first("cardType")
            }}</span>
          </div>
          <div
            class="vx-col md:w-1/4 w-full mb-2"
            v-if="paymentId != 0 && paymentId != null"
          >
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-credit-card"
                :label="
                  $t(resources.CreditCardNumber.i18n) ||
                  resources.CreditCardNumber.name
                "
                v-model="creditCard.cardNumber"
                v-validate="'required'"
                disabled
              />
            </div>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2" v-else>
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-credit-card"
                type="number"
                :label="
                  $t(resources.CreditCardNumber.i18n) ||
                  resources.CreditCardNumber.name
                "
                v-model="creditCard.cardNumber"
                v-validate="'required|credit_card'"
                name="cardNumber"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('cardNumber')"
                >{{ errors.first("cardNumber") }}</span
              >
            </div>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-lock"
                :maxlength="maxCvv"
                :label="
                  $t(resources.SecurityCode.i18n) || resources.SecurityCode.name
                "
                v-model="creditCard.cardSecurityCode"
                name="SecurityCode"
                :disabled="canUpdate"
              />
            </div>
            <span
              class="text-danger text-sm"
              v-show="errors.has('SecurityCode')"
              >{{ errors.first("SecurityCode") }}</span
            >
          </div>
          <div class="vx-col w-full md:w-1/4 mb-4">
            <label class="vs-input--label">{{
              $t(resources.ExpirationYear.i18n) || resources.ExpirationYear.name
            }}</label>
            <vs-select
              class="vx-col w-full"
              icon-no-border
              v-model="expirationYear"
              v-on:change="expirationYearChange($event)"
              :disabled="canUpdate"
              v-validate="'required'"
              name="expirationYear"
            >
              <vs-select-item
                :key="index"
                :value="item"
                :text="item"
                v-for="(item, index) in expirationYearOption"
              />
            </vs-select>
            <span class="text-danger text-sm" v-show="errors.has('stage')">{{
              errors.first("expirationYear")
            }}</span>
          </div>
          <div class="vx-col w-full md:w-1/4 mb-4">
            <label class="vs-input--label">{{
              $t(resources.ExpirationMonth.i18n) ||
              resources.ExpirationMonth.name
            }}</label>
            <vs-select
              class="vx-col w-full"
              icon-no-border
              v-model="expirationMonth"
              :disabled="canUpdate"
              v-validate="'required'"
              name="expirationMonth"
            >
              <vs-select-item
                :key="index"
                :value="item"
                :text="item"
                v-for="(item, index) in expirationMonthOption"
              />
            </vs-select>
            <span
              class="text-danger text-sm"
              v-show="errors.has('expirationMonth')"
              >{{ errors.first("expirationMonth") }}</span
            >
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-user"
                :label="$t(resources.Name.i18n) || resources.Name.name"
                v-model="creditCard.cardHolderName"
                v-validate="{ required: true, regex: /^([a-zA-Z ]+)$/ }"
                name="cardHolderName"
                :disabled="canUpdate"
              />
            </div>
            <span
              class="text-danger text-sm"
              v-show="errors.has('cardHolderName')"
              >{{ errors.first("cardHolderName") }}</span
            >
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-user"
                :label="$t(resources.LastName.i18n) || resources.LastName.name"
                v-model="creditCard.cardholderLastName"
                v-validate="{ required: true, regex: /^([a-zA-Z ]+)$/ }"
                name="cardholderLastName"
                :disabled="canUpdate"
              />
            </div>
            <span
              class="text-danger text-sm"
              v-show="errors.has('cardholderLastName')"
              >{{ errors.first("cardholderLastName") }}</span
            >
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-hash"
                icon-no-border
                :label="
                  $t(resources.DocumentNumber.i18n) ||
                  resources.DocumentNumber.name
                "
                v-model="creditCard.documentNumber"
                v-validate="'required|max:20|alpha_num'"
                name="documentCard"
                :disabled="canUpdate"
              />
            </div>
            <span
              class="text-danger text-sm"
              v-show="errors.has('documentCard')"
              >{{ errors.first("documentCard") }}</span
            >
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-mail"
                type="email"
                :label="$t(resources.Email.i18n) || resources.Email.name"
                v-model="creditCard.email"
                v-validate="'required|max:100|email'"
                name="emailCard"
                :disabled="canUpdate"
              />
            </div>
            <span
              class="text-danger text-sm"
              v-show="errors.has('emailCard')"
              >{{ errors.first("emailCard") }}</span
            >
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-map-pin"
                icon-no-border
                :label="$t(resources.Address.i18n) || resources.Address.name"
                v-model="creditCard.address"
                v-validate="{
                  required: true,
                  regex: /^([a-zA-Z0-9 ]+)$/,
                }"
                name="addressCard"
                :disabled="canUpdate"
              />
            </div>
            <span
              class="text-danger text-sm"
              v-show="errors.has('addressCard')"
              >{{ errors.first("addressCard") }}</span
            >
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-hash"
                type="number"
                :label="$t(resources.ZipCode.i18n) || resources.ZipCode.name"
                v-model="creditCard.zipCode"
                v-validate="'required|numeric'"
                name="zipCodeCard"
                :disabled="canUpdate"
              />
            </div>
            <span
              class="text-danger text-sm"
              v-show="errors.has('zipCodeCard')"
              >{{ errors.first("zipCodeCard") }}</span
            >
          </div>
          <div
            class="vx-col md:w-1/4 w-full mb-2"
            v-if="paymentId != 0 && paymentId != ''"
          >
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-map"
                icon-no-border
                :label="
                  $t(resources.ResidenceCity.i18n) ||
                  resources.ResidenceCity.name
                "
                v-model="creditCard.cityName"
                disabled
              />
            </div>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                $t(resources.InstallmentNumber.i18n) ||
                resources.InstallmentNumber.name
              }}</label>
              <v-select
                v-model="creditCard.installmentNumber"
                :clearable="false"
                :options="installmentNumberOption"
                :reduce="(data) => data.cardId"
                label="name"
                :disabled="canUpdate"
              />
            </div>
          </div>
        </div>
        <div class="vx-row" v-else>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                $t(resources.CreditCard.i18n) || resources.CreditCard.name
              }}</label>
              <vs-select
                class="w-full"
                :placeholder="$t(resources.Select.i18n) || resources.Select.name"
                v-model="creditCardId"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in cardsOption"
                />
              </vs-select>
            </div>
          </div>
        </div>
      </div>

      <div
        class="mb-2"
        v-if="wayToPayOptionId == database.merchantType.EWallet"
      >
        <div class="vx-row">
          <div class="vx-col md:w-1/4 w-full mb-2">
            <label class="vs-input--label">{{
              $t(resources.Select.i18n) || resources.Select.name
            }}</label>
            <v-select
              v-model="walletId"
              :clearable="false"
              :options="walletOption"
              :reduce="(data) => data.walletId"
              label="name"
              v-on:input="setAccountWallet(`${walletId}`)"
              :hint="`${walletId}`"
              :disabled="canUpdate"
            ></v-select>
          </div>
          <div
            class="vx-col md:w-1/4 w-full mb-2"
            v-if="
              walletId == database.merchants.BlockPay &&
              (paymentId == 0 || paymentId == null)
            "
          >
            <label class="vs-input--label">{{
              $t(resources.AccountNumber.i18n) || resources.AccountNumber.name
            }}</label>
            <v-select
              v-model="accountWalletId"
              :clearable="false"
              :options="accountWalletOption"
              :reduce="(x) => x.id"
              label="name"
              :hint="`${accountWalletId}`"
              name="accountWalletId"
              v-validate="'required'"
              :disabled="canUpdate"
            ></v-select>
            <span
              class="text-danger text-sm"
              v-show="errors.has('accountWalletId')"
              >{{ errors.first("accountWalletId") }}</span
            >
          </div>
          <div
            class="vx-col md:w-1/4 w-full mb-2"
            v-if="
              walletId == database.merchants.Payout &&
              (paymentId == 0 || paymentId == null)
            "
          >
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-user"
                type="number"
                :label="
                  $t(resources.DistributorID.i18n) ||
                  resources.DistributorID.name
                "
                v-model="itaCodeWallet"
                v-validate="'required'"
                name="itaCodeWallet"
                :disabled="canUpdate"
              />
            </div>
            <span
              class="text-danger text-sm"
              v-show="errors.has('itaCodeWallet')"
              >{{ errors.first("itaCodeWallet") }}</span
            >
          </div>
        </div>
      </div>

      <div
        class="mb-2"
        v-if="wayToPayOptionId == database.merchantType.transfer"
      >
        <div class="vx-row">
          <div class="vx-col md:w-1/4 w-full mb-2">
            <label class="vs-input--label">{{
              $t(resources.BankName.i18n) || resources.BankName.name
            }}</label>
            <v-select
              v-model="transfer.bankTypeId"
              :clearable="false"
              :options="bankType"
              :reduce="(data) => data.id"
              label="name"
              v-on:input="setAccountNumber(transfer.bankTypeId)"
              :hint="transfer.bankTypeId"
              :disabled="canUpdate"
            />
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <label class="vs-input--label">{{
              $t(resources.AccountNumber.i18n) || resources.AccountNumber.name
            }}</label>
            <v-select
              v-model="transfer.bankAccountId"
              :clearable="false"
              :options="accountNumberType"
              :reduce="(data) => data.id"
              label="account"
              :disabled="canUpdate"
            />
          </div>
        </div>
      </div>

      <div class="vx-col mb-2 mt-5" style="text-align: right">
        <vs-button color="primary" type="filled" @click.prevent="valid()">
          {{ $t(resources.Save.i18n) || resources.Save.name }}
        </vs-button>
      </div>

      <div class="vx-row" v-if="paymentId != 0 && paymentId != null">
        <div class="vx-col md:w-1/4 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-calendar"
              :label="
                $t(resources.CreationDate.i18n) || resources.CreationDate.name
              "
              v-model="creationDate"
              disabled
            />
          </div>
        </div>
        <div class="vx-col md:w-1/4 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-file-text"
              :label="$t(resources.Invoice.i18n) || resources.Invoice.name"
              v-model="invoiceId"
              disabled
            />
          </div>
        </div>
        <div class="vx-col md:w-1/4 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-alert-triangle"
              :label="$t(resources.Accertify.i18n) || resources.Accertify.name"
              v-model="accertify"
              disabled
            />
          </div>
        </div>
        <div class="vx-col md:w-1/4 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-hash"
              :label="
                $t(resources.AccertifyPoints.i18n) ||
                resources.AccertifyPoints.name
              "
              v-model="accertifyPoints"
              disabled
            />
          </div>
        </div>
        <div class="vx-col md:w-1/4 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-file-text"
              :label="
                $t(resources.AccertifyId.i18n) || resources.AccertifyId.name
              "
              v-model="accertifyId"
              disabled
            />
          </div>
        </div>
        <div class="vx-col md:w-1/4 w-full">
          <label>{{
            $t(resources.Answer.i18n) || resources.Answer.name
          }}</label>
          <vs-textarea v-model="merchantResponse" disabled />
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import axios from "axios";
import resources from "@/i18n/resources.js";
import database from "@/assets/resources/enums/database.json";
import other from "@/assets/resources/enums/other.json";
import status from "@/assets/resources/enums/status.json";
import vSelect from "vue-select";
import alerts from "@/i18n/alerts.js";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import billing from "@/views/purchases/billing.vue";
import tracking from "../purchases/tracking.vue";
import comments from "../purchases/comments.vue";
import newMessage from "../email/newMessage.vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";

export default {
  components: {
    vSelect,
    FormWizard,
    TabContent,
    billing,
    tracking,
    comments,
    newMessage,
    flatPickr,
  },
  data() {
    return {
      urlVision: process.env.VUE_APP_VISION,
      database: database,
      other: other,
      status: status,
      resources: resources,
      alerts: alerts,
      popup: false,
      itaCode: 0,
      contactId: 0,
      countryCode: "USA",
      wayToPayOption: [],
      wayToPayOptionId: 0,
      creditcardPayment: {},
      creditcardOption: [],
      installmentNumberType: [],
      bankType: [],
      installmentNumberOption: [],
      purchaseId: 0,
      expirationYearOption: [],
      expirationMonthOption: [],
      currentYear: 0,
      currentMonth: 0,
      expirationYear: 0,
      expirationMonth: 0,
      billingData: [],
      transferPayment: {},
      eWalletPayment: {},
      accountNumberType: [],
      maxCvv: 0,
      productPrice: {},
      products: [],
      renewal_date: JSON.parse(localStorage.getItem("userInfo")).renewalDate,
      upgradeList: [],
      radioUpgradeId: "",
      productId: "",
      contactDto: {},
      colorAlert: "warning",
      paymentList: [],
      productsPrice: [],
      orderNumber: 0,
      stageId: 0,
      stageName: "",
      paymentConfirmed: 0,
      emailSession: JSON.parse(localStorage.getItem("userInfo")).email,
      emailIta: "",
      popupResMer: false,
      popupEdit: false,
      responseMerchant: "",
      paymentId: "",
      stagePayment: "",
      stagePaymentList: [],
      identifierPurchase: 0,
      popupEditOrder: false,
      walletOption:[],
      merchantId:"",
      creditCard:{},
      transfer: {},
      walletId:"",
      accountWalletId:0,
      referenceCodeOffline: "",
      value:0,
      merchantResponse: "",
      createdBy:"",
      merchantList:[],
      configdateTimePicker: {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
      },
      stageList: [],
      accountWalletOption:[],
      haveCardInfo: false,
      cardsOption: [],
      creditCardId: 0,
      popUpTitle: this.$i18n.t("Create"),
      popupAddPayment: false,
      paymentDate: new Date(),

    };
  },
  computed: {
    enabledButton() {
      let totalPay = 0;
      let products = this.billingData.filter(
        (x) => x.orderType == this.other.billingOrder.product
      );

      let _wayToPayTotal = this.billingData.filter(
        (x) => x.orderType == this.other.billingOrder.wayToPay
      );

      for (let index = 0; index < products.length; index++) {
        totalPay += products[index].productValue;
      }

      if (_wayToPayTotal.length > 0) {
        let _payments = _wayToPayTotal.reduce(
          (sum, current) => sum + parseFloat(current.productValue),
          0
        );
        if (totalPay <= _payments && this.orderNumber == 0) {
          return true;
        }
        return false;
      } else return false;
    },
    canUpdate() {
      if (this.paymentId != 0 && this.paymentId != null) return true;
      else return false;
    },
  },
  methods: {
    async getById() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_MEMBERSHIP}purchase/getById/${this.purchaseId}`,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale.toUpperCase(),
        },
      }).then(
        async (result) => {
          if (result.data.success && result.data.code != 204) {
            this.billingData = [];
            let response = result.data.data;
            this.itaCode = response.itaCode;
            this.contactId = response.contactId;
            this.productId = response.detail[0].productId;
            this.orderNumber = response.orderNumber;
            this.stageId = response.stageId;
            this.stageName = response.stageName;
            this.emailIta = response.emailIta.toLowerCase();
            response.detail.forEach((element) => {
              this.billingData.push({
                productId: element.productId,
                productName: element.productName,
                currencyCode: element.currencyCode,
                orderType: this.other.billingOrder.product,
                productValue: element.value * element.amount,
                productTax: 0,
                productCode: element.productCode,
                priceLevel: element.priceLevel,
                imageUrl: `https://cdn1.visiontravel.net/Images/products/VIVE001.png`,
              });
            });
            this.billingData[0].productTax = response.additionalValue;
            this.authCode =
              response.payment.length == 0
                ? 0
                : response.payment[0].transactionNumber;
            this.paymentList = response.payment;
            this.paymentConfirmed = response.paymentsConfirmed;

            this.billingData.push({
              productId: 0,
              productName: this.$i18n.t("Payments"),
              currencyCode: response.currencyCode,
              orderType: this.other.billingOrder.wayToPay,
              productValue: response.totalPayments,
              productCode: response.productCode,
            });
            await this.getWayToPay();
          } else {
            this.$vs.dialog({
              color: "warning",
              title: this.$i18n.t("Alert"),
              text: this.$i18n
                .t("MsgNotMonthlyPayment")
                .replace("{0}", this.renewal_date),
              accept: this.acceptDialog,
            });
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    async getWayToPay() {
      let data = {
        applicationId: this.database.application.OV,
        countryCode: this.countryCode,
        productId: this.productId,
        supplierId: this.database.supplier.visionTravel,
        language: this.$i18n.locale.toUpperCase().toUpperCase(),
        currencyCode: this.billingData[0].currencyCode,
      };

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}WayToPay/GetAvailable`,
        data: data,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.wayToPayOption = [];
          } else {
            this.wayToPayOption = result.data.wayToPay;
            this.setWayToPay(this.wayToPayOptionId);
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
          this.merchantOption = [];
        }
      );
    },

    async createPayment() {
      this.$vs.loading();
      let _wayToPayTotal = 0;
      let _productTotal = 0;
      let _otherValue = 0;
      let _taxesValue = 0;
      if (this.billingData.length > 0) {
        if (
          this.billingData.filter(
            (x) => x.orderType == this.other.billingOrder.wayToPay
          ).length > 0
        ) {
          _wayToPayTotal = this.billingData
            .filter((x) => x.orderType == this.other.billingOrder.wayToPay)
            .reduce(
              (sum, current) => sum + parseFloat(current.productValue),
              0
            );
        }

        if (
          this.billingData.filter(
            (x) => x.orderType == this.other.billingOrder.product
          ).length > 0
        ) {
          _productTotal = this.billingData
            .filter((x) => x.orderType == this.other.billingOrder.product)
            .reduce(
              (sum, current) => sum + parseFloat(current.productValue),
              0
            );
        }
        _otherValue = this.productPrice.other;
        _taxesValue = this.productPrice.taxes;
      }

      let _data = {};
      let _payment = {};
      if (this.purchaseId) _data.purchaseId = this.purchaseId;
      else _data.purchaseId = 0;

      _data.language = this.$i18n.locale.toUpperCase();
      _data.currencyCode = this.billingData[0].currencyCode;
      _data.countryCode = this.countryCode;
      _data.contactId = this.contactDto.id;
      _data.itaCode = this.contactDto.itaCode;
      _data.applicationId = this.database.application.OV;
      _data.supplierId = this.database.supplier.visionTravel;
      _data.createdBy = this.emailSession;
      _data.purchaseTypeId = 2;
      _data.products = [];
      _data.wayToPayId = this.wayToPayOptionId;
      _data.rateValue = 1;
      _data.taxesValue = _taxesValue;
      _data.otherValue = _otherValue;
      _data.installment = 1;

      let productList = this.billingData.filter(
        (x) => x.orderType == this.other.billingOrder.product
      );
      for (let x = 0; x < productList.length; x++) {
        this.products.push(productList[x].productCode);
        this.productsPrice.push(productList[x].productValue);
        _data.products.push(productList[x].productId);
      }
      _data.products = _data.products.sort();

      if (this.wayToPayOptionId == this.database.merchantType.creditCard) {
        _payment.creditCardTypeId = this.creditcardPayment.creditCardTypeId;
        _payment.cardNumber = this.creditcardPayment.cardNumber;
        _payment.securityCode = this.creditcardPayment.cardSecurityCode;
        _payment.documentNumber = this.creditcardPayment.documentNumber;
        _payment.name = this.creditcardPayment.cardHolderName;
        _payment.lastName = this.creditcardPayment.cardholderLastName;
        _payment.address = this.creditcardPayment.address;
        _payment.zipCode = this.creditcardPayment.zipCode;
        _payment.email = this.creditcardPayment.email;
        _payment.amount = this.creditcardPayment.amount;
        _payment.currencyCode = this.billingData[0].currencyCode;
        _payment.expirationMonth = this.expirationMonth;
        _payment.expirationYear = this.expirationYear;
        _wayToPayTotal += this.creditcardPayment.amount;
        _data.isPurchase = _productTotal == _wayToPayTotal;
        if (
          this.creditcardPayment.installmentNumber &&
          this.creditcardPayment.installmentNumber != 0
        )
          this.installmentNumber = this.creditcardPayment.installmentNumber;
        else this.installmentNumber = 1;

        _payment.installmentNumber = this.installmentNumber;
      } else if (this.wayToPayOptionId == this.database.merchantType.transfer) {
        _payment.accountId = this.transferPayment.bankAccountId;
        _payment.documentId = this.transferPayment.documentId;
        _payment.referenceCode = this.transferPayment.referenceCode;
        _payment.amount = this.transferPayment.amount;
        _payment.currencyCode = this.billingData[0].currencyCode;
        _payment.language = this.$i18n.locale.toUpperCase();
        _wayToPayTotal += this.transferPayment.amount;
        _data.isPurchase = _productTotal == _wayToPayTotal;
      } else if (this.wayToPayOptionId == this.database.merchantType.EWallet) {
        _payment.WalletId = 6;
        _payment.userName = this.eWalletPayment.itaCodeWallet;
        _payment.amount = this.eWalletPayment.amount;
        _payment.charge = false;
        _payment.language = this.$i18n.locale.toUpperCase();
      }

      _data.payment = _payment;

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Membership/BuyProduct`,
        data: _data,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        async (result) => {
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgPaymentMade"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.authCode = result.data.data.payResult.allocationNumber;
            this.purchaseId = result.data.data.purchaseId;

            this.addPay(_data);
            if (this.wayToPayOptionId == this.database.merchantType.transfer) {
              await this.getuploadForm(
                result.data.data.purchaseId,
                result.data.data.paymentId
              );
            }

            await this.getPayments();
            this.creditcardPayment = {};
            this.transferPayment = {};
            this.eWalletPayment = {};
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
          this.$vs.loading.close();
        }
      );
      this.$vs.loading.close();
    },

    async getuploadForm(purchaseId, paymentId) {
      this.$vs.loading();
      let formData = new FormData();
      formData.set("purchaseId", purchaseId);
      formData.set("paymentId", paymentId);
      formData.set("language", this.$i18n.locale.toUpperCase());
      formData.append(
        "file",
        this.transferPayment.file,
        this.transferPayment.file.Name
      );

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Membership/AddAttachment`,
        data: formData,
        headers: {
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
          "Content-Type": "multipart/form-data",
        },
      }).then(
        (result) => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },

    addPay(item) {
      let _merchantType = this.wayToPayOption.filter(
        (x) => x.wayToPayId == item.wayToPayId
      );
      this.billingData.push({
        productId: this.billingData[0].productId,
        productName: _merchantType[0].name,
        currencyCode: this.billingData[0].currencyCode,
        orderType: this.other.billingOrder.wayToPay,
        productValue: item.payment.amount,
        productCode: this.billingData[0].productCode,
      });
    },

    async getYearsCrediCard() {
      var currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, "/");
      this.currentYear = parseInt(currentDate.split("/")[0]);
      this.currentMonth = parseInt(currentDate.split("/")[1]);

      this.expirationYearOption = [];
      this.expirationMonthOption = [];
      var iYear = 0;
      for (let item = this.currentYear; iYear <= 20; item++) {
        iYear++;
        this.expirationYearOption.push(item);
      }

      for (let item = this.currentMonth; item <= 12; item++) {
        this.expirationMonthOption.push(item);
      }
    },

    async expirationYearChange(year) {
      var yearSelect = year;
      this.expirationMonthOption = [];

      if (this.currentYear === yearSelect) {
        for (let item = this.currentMonth; item <= 12; item++) {
          this.expirationMonthOption.push(item);
        }
      } else {
        for (let item = 1; item <= 12; item++) {
          this.expirationMonthOption.push(item);
        }
      }
    },

    setAccountNumber(item) {
      let _account = this.bankType
        .filter((x) => x.id == item)
        .map((x) => x.accounts);
      this.accountNumberType = _account[0].map((x) => x);
    },

    onFileSelected(event) {
      this.transferPayment.file = event.target.files[0];
    },

    setWayToPay(item) {
      let _merchantType = this.wayToPayOption.filter(
        (x) => x.wayToPayId == item
      );
      if (item == 1)
        this.walletOption = _merchantType[0].options[0].wallets.map((x) => x);
      if (item == 2)
        this.bankType = _merchantType[0].options[0].banks.map((x) => x);
      if (item == 3)
        this.creditcardOption = _merchantType[0].options.map((x) => x);
    },

    setInstallmentNumber(item) {
      let _creditcard = this.creditcardOption.find((x) => x.cardId == item);
      this.maxCvv = _creditcard.maxCvv;
      this.installmentNumberOption = _creditcard.installmentNumber;
    },

    async setContact() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Ita/${this.$i18n.locale.toUpperCase().toUpperCase()}/${this.itaCode}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (result.data.id != null) {
            this.contactDto = {
              id: result.data.conctactId,
              contactType: result.data.contactType,
              name: result.data.name,
              lastName: result.data.lastName,
              email: result.data.email,
              photoURL: result.data.imageUrl,
              gender: result.data.gender,
              itaCode: this.itaCode,
              countryOperation: result.data.countryOperationDefault.countryCode,
              countryOperationName:
                result.data.countryOperationDefault.countryName,
              country: result.data.countryId,
              countryName: result.data.countryName,
              countryOperationEntry: result.data.countryOperationEntry,
              countryOperationNameEntry: result.data.countryOperationNameEntry,
              state: result.data.stateId,
              city: result.data.city,
              address: result.data.address != null ? result.data.address : "",
              birthDate: result.data.birthDate,
              phone: result.data.phone,
              documentNumber: result.data.document,
              stateName: result.data.stateName,
              cityName: result.data.cityName,
              zipCode:
                result.data.zipCode == undefined
                  ? "00000"
                  : result.data.zipCode,
              renewalDate: result.data.renewalDate,
              language: result.data.language.toLowerCase(),
            };
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    async validate() {
      this.$vs.loading();
      let _totalPay = 0;
      let _payments = 0;
      let products = this.billingData.filter(
        (x) => x.orderType == this.other.billingOrder.product
      );

      for (let index = 0; index < products.length; index++) {
        _totalPay += products[index].productValue + products[index].productTax;
      }

      let _wayToPayTotal = this.billingData.filter(
        (x) => x.orderType == this.other.billingOrder.wayToPay
      );

      if (_wayToPayTotal.length > 0) {
        _payments = _wayToPayTotal.reduce(
          (sum, current) => sum + parseFloat(current.productValue),
          0
        );
      }

      if (this.paymentConfirmed >= _totalPay) {
        await this.generate();
      } else if (_payments >= _totalPay) {
        await this.changeStage(this.status.genealogy.verify);
      }

      this.$vs.loading.close();
    },

    async generate() {
      this.$vs.loading();

      let detail = [];
      let products = this.billingData.filter(
        (x) => x.orderType == this.other.billingOrder.product
      );

      for (let x = 0; x < products.length; x++) {
        detail.push({
          productCode: products[x].productCode,
          priceLevel:  products[x].productValue < 40 && this.contactDto.contactType == 1 ? 3 : products[x].priceLevel
        });
      }

      let _data = {
        enroller: this.itaCode,
        contactType: this.contactDto.contactType,
        name: this.contactDto.name,
        lastName: this.contactDto.lastName,
        address: this.contactDto.address,
        countryName: this.contactDto.countryOperationNameEntry,
        stateName: this.contactDto.stateName,
        cityName: this.contactDto.cityName,
        phone: this.contactDto.phone,
        zipCode: this.contactDto.zipCode,
        countryCode: this.contactDto.countryOperationEntry,
        purchaseId: this.purchaseId,
        payment: [{ transactionNumber: this.authCode }],
        detail: detail,
        createdBy: this.emailSession,
      };

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_MONTHLYPAY}monthlyPayment/generate`,
        data: _data,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale.toUpperCase(),
        },
      }).then(
        (result) => {
          if (result.data.success) {
            this.orderNumber = result.data.orderNumber;
            this.popup = true;
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgPurchaseVerify"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          }
          this.$vs.loading.close();
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
          this.$vs.loading.close();
        }
      );
    },

    async changeStage(newStageId) {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_OVAPI}Tracking/createByPurchase`,
        data: {
          identifier: this.purchaseId,
          entityId: this.other.entity.purchase,
          previousStageId: this.status.genealogy.pending,
          newStageId: newStageId,
          createdBy: this.emailSession,
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("loggedIn")}`,
        },
      }).then(
        (result) => {
          if (result.data.status == 200) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    acceptDialog() {
      this.$router.push({ path: "/purchases/index" });
    },

    validPayment() {
      this.$validator.validateAll().then((result) => {
        if (
          this.wayToPayOptionId == this.database.merchantType.transfer &&
          (this.transferPayment.file == null || this.transferPayment.file == "")
        ) {
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: this.$i18n.t("MsgNotAttachment"),
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        } else if (!result) {
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: this.$i18n.t("MsgRequiredField"),
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        } else {
          this.createPayment();
        }
      });
    },

    async getPayments() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_MONTHLYPAY}payment/getByPurchase/${this.purchaseId}`,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale.toUpperCase(),
        },
      }).then(
        (result) => {
          if (result.data != null) {
            this.paymentList = result.data.data;
            let _total = 0;
            for (let index = 0; index < this.paymentList.length; index++) {
              if (this.paymentList[index].stageId == this.status.payment.paid) {
                _total =
                  parseFloat(this.paymentConfirmed) +
                  parseFloat(this.paymentList[index].value);
              }
            }
            this.paymentConfirmed = _total;
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    async paymentEwallet(itaCode, referenceId) {
      this.$vs.loading();
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_MEMBERSHIP}payout/validStatus/${itaCode}/${referenceId}`,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale.toUpperCase(),
        },
      }).then(
        async (result) => {
          this.$vs.loading.close();
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            await this.getById();
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },

    async paymentTransfer(stageId, referenceId) {
      this.$vs.loading();
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_MEMBERSHIP}payment/updateStage/${stageId}/${referenceId}`,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale.toUpperCase(),
        },
      }).then(
        async (result) => {
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            await this.getById();
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },

    async getStagePayment() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_OVAPI}Stage/${this.$i18n.locale}/2`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.stagePaymentList = [];
            this.stageList =[];
          } else {
            this.stagePaymentList = result.data;
            this.stageList = result.data;
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    async updateIdentifierStage() {
      this.$vs.loading();
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_MEMBERSHIP}payment/updateIdentifierStage`,
        data: {
          id: this.paymentId,
          stageId: this.stagePayment,
          identifier: this.identifierPurchase,
        },
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale.toUpperCase(),
        },
      }).then(
        async (result) => {
          this.$vs.loading.close();
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.popupEdit = false;
            await this.getById();
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },

    async updateOrderAndStage() {
      this.$vs.loading();
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_MEMBERSHIP}purchase/updateOrderAndStage`,
        data: {
          stageId: this.stageId,
          purchaseId: this.purchaseId,
          orderNumber: this.orderNumber,
        },
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale.toUpperCase(),
        },
      }).then(
        async (result) => {
          this.$vs.loading.close();
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.popupEditOrder = false;
            await this.getById();
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },

    openDocument(url) {
      window.open(url, this.$i18n.t("Transfer"));
    },

    openResponseMerchant(text) {
      this.responseMerchant = text;
      this.popupResMer = true;
    },

    openEditPay(stageId, id) {
      this.identifierPurchase = this.purchaseId;
      this.stagePayment = stageId;
      this.paymentId = id;
      this.popupEdit = true;
    },
    openAdd() {
      this.popUpTitle = this.$i18n.t("Create");
      this.wayToPayOptionId = "";
      this.merchantId = "";
      this.creditCard = {};
      this.transfer = {};
      this.walletId = "";
      this.accountWalletId = "";
      this.paymentId = 0;
      this.referenceCodeOffline = "";
      this.value = 0;
      this.stageId = "";
      this.expirationYear = this.expirationYearOption[0];
      this.expirationMonth = this.expirationMonthOption[0];
      this.maxCvv = 0;
      this.merchantResponse = "";
      this.createdBy = JSON.parse(localStorage.getItem("userInfo")).email;
      this.paymentDate = new Date();
      this.popupAddPayment = true;      
    },
    async getMerchantsApplication() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }ApplicationMerchant/GetByApplicationId/${this.$i18n.locale.toUpperCase()}/${
          this.database.application.FOC
        }`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (result.data) this.merchantList = result.data.data;
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },
    async getPayment() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Payment/ByBooking/${this.$i18n.locale.toUpperCase()}/${
          this.bookingId
        }`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (result.data.success) {
            this.payments = result.data.data;
            this.totalPayment = this.payments.filter(x => (x.state == "Pagado" || x.state == "Paid") 
                              && x.paymentTypeId != this.database.merchantType.offline).reduce((sum, current) => sum + parseFloat(current.value),0);

            this.totalPayment = this.totalPayment.toFixed(2);
            
            this.cardsOption = result.data.data.map((x) => ({
              value: x.cardId,
              text: `${x.cardType} - ***${x.cardNumber}`,
              type: x.cardTypeId
            }));
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },
    setAccountWallet(item) {
      let _accounts = this.walletOption.find((x) => x.walletId == item)
        .accounts;
      this.accountWalletOption = _accounts;
    },
    async valid() {
      if (this.paymentId != 0 && this.paymentId != null) 
        await this.updatePayment();
       else 
        await this.createPaymentOffline();
    },
    async updatePayment() {
      this.$vs.loading();
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Payment/Update`,
        data: {
          id: this.paymentId,
          stageId: this.stageId,
          value: this.value,
          transactionNumber: this.referenceCodeOffline,
          paymentDate: this.paymentDate,
          language: this.$i18n.locale.toUpperCase(),
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        async (result) => {
          if (result.data.id != undefined && result.data.id != null) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.popupAddPayment = false;
            await this.getPayment();
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },
    async createPaymentOffline() {
      this.$vs.loading();
      let payDate = moment(this.paymentDate).format("YYYY-MM-DD HH:mm");
      let _payment = {
        id: 0,
        contactId: this.contactId,
        identifier: this.purchaseId,
        entityId: this.other.entity.purchase,
        currencyCode: this.billingData[0].currencyCode,
        countryCode: this.countryCode,
        value: this.value,
        allocationNumber: this.referenceCodeOffline,
        payDay: payDate,
        stateId: this.stageId,
        merchantResponse: this.merchantResponse,
        referenceText: this.merchantResponse,
        merchantId: this.merchantId,
        createdBy: this.emailSession,
        productId: this.productId,
        applicationId: this.database.application.FOC,
        expirationMonth: this.expirationMonth,
        expirationYear: this.expirationYear,
        installmentNumber: 1,
        merchantTypeId: this.wayToPayOptionId,
        accertifyId: "0",
        accertifyPoints: 0,
        accertify: "NOPROCESS",
        isSecured: false,
        cityId: 0,
        language: this.$i18n.locale.toUpperCase(),
        CardTypeId: 0
      };
      
      if (this.wayToPayOptionId == this.database.merchantType.creditCard && this.haveCardInfo) {
        _payment.creditCardId = 0;
        _payment.cardTypeId = this.creditCard.creditCardTypeId;
        _payment.contactId = this.contactId;
        _payment.cardNumber = this.creditCard.cardNumber;
        _payment.cardSecurityCode = this.creditCard.cardSecurityCode;
        _payment.expirationMonth = this.expirationMonth;
        _payment.expirationYear = this.expirationYear;
        _payment.cardholderDocument = this.creditCard.documentNumber;
        _payment.cardHolderName = this.creditCard.cardHolderName;
        _payment.cardholderLastName = this.creditCard.cardholderLastName;
        _payment.address = this.creditCard.address;
        _payment.zipCode = this.creditCard.zipCode;
        _payment.email = this.creditCard.email;
        _payment.installmentNumber = this.creditCard.installmentNumber;
      } 
      else if (this.wayToPayOptionId == this.database.merchantType.creditCard && !this.haveCardInfo) {
        _payment.creditCardId = this.creditCardId;
        _payment.cardTypeId = this.cardsOption.find(x => x.value == this.creditCardId).type;
      }
      else if (this.wayToPayOptionId == this.database.merchantType.transfer) {
        _payment.bankId = this.transfer.bankTypeId;
        _payment.bankAccountId = this.transfer.bankAccountId;
      }

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Payment/Register`,
        data: _payment,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        async (result) => {
          if (result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.popupAddPayment = false;
            await this.getPayment();
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },
  },
  async created() {
    this.$vs.loading();

    if (
      this.$route.params.purchaseId != undefined &&
      this.$route.params.purchaseId != null &&
      this.$route.params.purchaseId != 0
    ) {
      this.purchaseId = this.$route.params.purchaseId;
      await this.getById();
    }

    await this.setContact();
    await this.getYearsCrediCard();
    await this.getStagePayment();
    await this.getMerchantsApplication();

    this.$vs.loading.close();
  },
};
</script>
<style lang="scss">
.x-hellosign-embedded {
  z-index: 53000;
}
</style>

