<template>
    <div>
        <!-- BILLINGA DATA -->
        <vx-card>
            <div>
                <div class="vx-row">
                    <div class="vx-col md:w-1/4 w-full mb-6">
                        <div class="vx-col w-full">
                            <vs-input class="w-full" icon-pack="feather" icon="icon-user" icon-no-border
                                :label="$t(resources.Name.i18n) || resources.Name.name" v-model="billingData.name" />
                        </div>
                    </div>
                    <div class="vx-col md:w-1/4 w-full mb-6">
                        <div class="vx-col w-full">
                            <vs-input class="w-full" icon-pack="feather" icon="icon-user" icon-no-border
                                :label="$t(resources.LastName.i18n) || resources.LastName.name"
                                v-model="billingData.lastName" />
                        </div>
                    </div>
                    <div class="vx-col md:w-1/4 w-full mb-6">
                        <div class="vx-col w-full">
                            <vs-input class="w-full" icon-pack="feather" icon="icon-mail" icon-no-border type="email"
                                :label="$t(resources.Email.i18n) || resources.Email.name" v-model="billingData.email" />
                        </div>
                    </div>
                    <div class="vx-col md:w-1/4 w-full">
                        <div class="vx-col w-full">
                            <vs-input class="w-full" icon-pack="feather" icon="icon-smartphone" icon-no-border
                                :label="$t(resources.Mobile.i18n) || resources.Mobile.name"
                                v-model="billingData.phone" />
                        </div>
                    </div>
                </div>

                <div class="vx-row">
                    <div class="vx-col md:w-1/4 w-full">
                        <label class="vs-input--label">{{
                            $t(resources.Country.i18n) || resources.Country.name
                        }}</label>
                        <v-select v-model="billingData.countryId" :clearable="false" :options="countryList"
                            :reduce="country => country.id" label="name"
                            v-on:input="getStateByCountry(`${billingData.countryId}`)"
                            :hint="`${billingData.countryId}`" />
                    </div>

                    <div class="vx-col md:w-1/4 w-full mb-6">
                        <label class="vs-input--label">{{
                            $t(resources.State.i18n) || resources.State.name
                        }}</label>
                        <v-select v-model="billingData.stateId" :clearable="false" :options="stateCountry"
                            :reduce="state => state.id" label="name"
                            v-on:input="getCityByState(`${billingData.stateId}`)" :hint="`${billingData.stateId}`" />
                    </div>
                    <div class="vx-col md:w-1/4 w-full mb-6">
                        <label class="vs-input--label">{{
                            $t(resources.Destination.i18n) || resources.Destination.name
                        }}</label>
                        <v-select v-model="billingData.cityId" :clearable="false" :options="destinationList"
                            :reduce="city => city.id" label="name" />
                    </div>
                    <div class="vx-col md:w-1/4 w-full mb-6">
                        <div class="vx-col w-full">
                            <vs-input class="w-full" icon-pack="feather" icon="icon-map" icon-no-border
                                :label="$t(resources.Address.i18n) || resources.Address.name"
                                v-model="billingData.address" />
                        </div>
                    </div>
                </div>

                <div class="vx-row">
                    <div class="vx-col md:w-1/4 w-full mb-6">
                        <div class="vx-col w-full">
                            <vs-input class="w-full" icon-pack="feather" icon="icon-hash" icon-no-border
                                :label="$t(resources.ZipCode.i18n) || resources.ZipCode.name"
                                v-model="billingData.zipCode" />
                        </div>
                    </div>
                    <div class="vx-col md:w-1/4 w-full mb-6">
                        <label class="vs-input--label">{{
                            $t(resources.DocumentType.i18n) || resources.DocumentType.name
                        }}</label>
                        <v-select v-model="billingData.documentTypeId" :clearable="false"
                            :options="identifiacionTypeList" :reduce="item => item.id" label="name" />
                    </div>
                    <div class="vx-col md:w-1/4 w-full mb-6">
                        <div class="vx-col w-full">
                            <vs-input class="w-full" icon-pack="feather" icon="icon-hash" icon-no-border :label="
                                $t(resources.DocumentNumber.i18n) ||
                                resources.DocumentNumber.name
                            " v-model="billingData.documentNumber" />
                        </div>
                    </div>
                </div>
                <vs-row vs-type="flex" vs-justify="flex-end">
                    <vs-button v-if="isCreateBillingData" class="mr-0 mb-2" @click="createBillingData(billingData)">{{
                    $t(resources.Save.i18n) || resources.Save.name }}</vs-button>
                    <vs-button v-if="allowEdit || (emailSession == 'a.holguin@visiontravelmail.net' || emailSession == 'l.palacio@visiontravelmail.net')" class="mr-0 mb-2" @click="updateBillingData(billingData)">{{
                    $t(resources.Save.i18n) || resources.Save.name }}</vs-button>
                </vs-row>
            </div>
        </vx-card>
    </div>
</template>
<script>
import utilities from "@/assets/resources/utilities.json";
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import database from "@/assets/resources/enums/database.json";
import other from "@/assets/resources/enums/other.json";
import status from "@/assets/resources/enums/status.json";
import vSelect from "vue-select";
import axios from "axios";
import moment from "moment";

export default {
    components: {
        vSelect
    },
    name: "payment",
    props: {
        bookingId: String,
        allowEdit: Boolean
    },
    data() {
        return {
            urlVision: process.env.VUE_APP_VISION,
            database: database,
            other: other,
            status: status,
            alerts: alerts,
            resources: resources,
            utilities: utilities,
            language: this.$i18n.locale.toUpperCase(),
            billingData: {},
            countryList: [],
            stateCountry: [],
            destinationList: [],
            identifiacionTypeList: [],
            isCreateBillingData: false,
            isUpdateBillingData: false,
            emailSession: JSON.parse(localStorage.getItem("userInfo")).email,
            contactIdSession: JSON.parse(localStorage.getItem("userInfo")).id,
            configdateTimePicker: {
                enableTime: true,
                dateFormat: "Y-m-d H:i"
            },

        };
    },
    computed: {
        currentDate() {
            var date = moment(new Date()).format("YYYY-MM-DD");
            return date;
        }
    },
    methods: {
        async getBillingData() {
            await axios({
                method: "GET",
                url: `${process.env.VUE_APP_APIVT
                    }BillingData/${this.$i18n.locale.toUpperCase()}/${this.bookingId}/1`,
                headers: {
                    "content-type": "application/json",
                    Authorization: `bearer ${localStorage.getItem("tokenVT")}`
                }
            }).then(
                async result => {
                    if (result.data.success) {
                        this.billingData = result.data.data;
                        this.isCreateBillingData = false;
                        await this.getStateByCountry(`${this.billingData.countryId}`);
                        await this.getCityByState(`${this.billingData.stateId}`);
                    } else {
                        this.isCreateBillingData = true;
                        this.isUpdateBillingData = false;
                    }
                },
                error => {
                    this.$vs.notify({
                        title: this.$i18n.t("Error"),
                        text: this.$i18n.t("MsgError") + "<br/>" + error.message,
                        color: "danger",
                        iconPack: "feather",
                        position: "top-right",
                        icon: "icon-x-circle"
                    });
                }
            );
        },

        async getStateByCountry(item) {
            await axios({
                method: "GET",
                url: `${process.env.VUE_APP_APIVT
                    }State/GetByCountry/${this.$i18n.locale.toUpperCase()}/${item}`,
                headers: {
                    "content-type": "application/json",
                    Authorization: `bearer ${localStorage.getItem("tokenVT")}`
                }
            }).then(
                result => {
                    if (result.data) this.stateCountry = result.data;
                },
                error => {
                    this.$vs.notify({
                        title: this.$i18n.t("Error"),
                        text: this.$i18n.t("MsgError") + "<br/>" + error.message,
                        color: "danger",
                        iconPack: "feather",
                        position: "top-right",
                        icon: "icon-x-circle"
                    });
                }
            );
        },

        async getCityByState(item) {
            await axios({
                method: "GET",
                url: `${process.env.VUE_APP_APIVT
                    }City/GetByState/${this.$i18n.locale.toUpperCase()}/${item}`,
                headers: {
                    "content-type": "application/json",
                    Authorization: `bearer ${localStorage.getItem("tokenVT")}`
                }
            }).then(
                result => {
                    if (result.data) this.destinationList = result.data;
                },
                error => {
                    this.$vs.notify({
                        title: this.$i18n.t("Error"),
                        text: this.$i18n.t("MsgError") + "<br/>" + error.message,
                        color: "danger",
                        iconPack: "feather",
                        position: "top-right",
                        icon: "icon-x-circle"
                    });
                }
            );
        },

        async updateBillingData(data) {
            data.language = this.$i18n.locale.toUpperCase();
            await axios({
                method: "POST",
                url: `${process.env.VUE_APP_APIVT}BillingData/Update`,
                data: data,
                headers: {
                    "content-type": "application/json",
                    Authorization: `bearer ${localStorage.getItem("tokenVT")}`
                }
            }).then(
                result => {
                    if (result.data.success) {
                        this.billingData = result.data.data;
                        this.getStateByCountry(`${this.billingData.countryId}`);
                        this.getCityByState(`${this.billingData.stateId}`);
                        this.$vs.notify({
                            title: this.$i18n.t("Success"),
                            text: this.$i18n.t("MsgSaved") + "<br/>" + result.data.message,
                            color: "success",
                            iconPack: "feather",
                            position: "top-right",
                            icon: "icon-x-circle"
                        });
                    }
                },
                error => {
                    this.$vs.notify({
                        title: this.$i18n.t("Error"),
                        text: this.$i18n.t("MsgError") + "<br/>" + error.message,
                        color: "danger",
                        iconPack: "feather",
                        position: "top-right",
                        icon: "icon-x-circle"
                    });
                }
            );
        },

        async createBillingData(data) {
            let contactInfo = JSON.parse(localStorage.getItem("userInfo"));
            data.language = this.$i18n.locale.toUpperCase();
            data.id = this.bookingId;
            data.entity = this.utilities.entity.booking;
            data.createBy = contactInfo.email;
            await axios({
                method: "POST",
                url: `${process.env.VUE_APP_APIVT}BillingData/Billing`,
                data: data,
                headers: {
                    "content-type": "application/json",
                    Authorization: `bearer ${localStorage.getItem("tokenVT")}`
                }
            }).then(
                result => {
                    if (result.data) {
                        this.getBillingData();
                        this.$vs.notify({
                            title: this.$i18n.t("Success"),
                            text: this.$i18n.t("MsgSaved") + "<br/>" + result.data.message,
                            color: "success",
                            iconPack: "feather",
                            position: "top-right",
                            icon: "icon-x-circle"
                        });
                    }
                },
                error => {
                    this.$vs.notify({
                        title: this.$i18n.t("Error"),
                        text: this.$i18n.t("MsgError") + "<br/>" + error.message,
                        color: "danger",
                        iconPack: "feather",
                        position: "top-right",
                        icon: "icon-x-circle"
                    });
                }
            );
        },

        async getIdentifiacionType() {
            await axios({
                method: "GET",
                url: `${process.env.VUE_APP_APIVT
                    }IdentificationType/${this.$i18n.locale.toUpperCase()}`,
                headers: {
                    "content-type": "application/json",
                    Authorization: `bearer ${localStorage.getItem("tokenVT")}`
                }
            }).then(
                result => {
                    if (result.data) this.identifiacionTypeList = result.data;
                },
                error => {
                    this.$vs.notify({
                        title: this.$i18n.t("Error"),
                        text: this.$i18n.t("MsgError") + "<br/>" + error.message,
                        color: "danger",
                        iconPack: "feather",
                        position: "top-right",
                        icon: "icon-x-circle"
                    });
                }
            );
        },

        async getCountry() {
            await axios({
                method: "GET",
                url: `${process.env.VUE_APP_APIVT
                    }Country/${this.$i18n.locale.toUpperCase()}`,
                headers: {
                    "content-type": "application/json",
                    Authorization: `bearer ${localStorage.getItem("tokenVT")}`
                }
            }).then(
                result => {
                    if (result.data) {
                        this.countryList = result.data;
                    }
                },
                error => {
                    this.$vs.notify({
                        title: this.$i18n.t("Error"),
                        text: this.$i18n.t("MsgError") + "<br/>" + error.message,
                        color: "danger",
                        iconPack: "feather",
                        position: "top-right",
                        icon: "icon-x-circle"
                    });
                }
            );
        },

    },

    async created() {
        this.$vs.loading();
        await this.getCountry();
        await this.getIdentifiacionType();

        if (this.bookingId != 0) {
            await this.getBillingData();
        }
        this.$vs.loading.close();
    }
};
</script>