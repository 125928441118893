var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vx-card",
    {
      staticClass: "email-compose",
      attrs: {
        title:
          _vm.$t(_vm.resources.NewMessage.i18n) || _vm.resources.NewMessage.name
      }
    },
    [
      _c(
        "form",
        [
          _c("div", { staticClass: "vx-row mb-2" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.From.i18n) || _vm.resources.From.name
                    )
                  )
                ]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  staticClass: "w-full",
                  attrs: {
                    type: "text",
                    "icon-pack": "feather",
                    icon: "icon-arrow-down",
                    "icon-no-border": "",
                    clearable: false,
                    options: _vm.fromOption,
                    reduce: function(mail) {
                      return mail.text
                    },
                    label: "text",
                    name: "De"
                  },
                  model: {
                    value: _vm.mailFrom,
                    callback: function($$v) {
                      _vm.mailFrom = $$v
                    },
                    expression: "mailFrom"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.To.i18n) || _vm.resources.To.name
                    )
                  )
                ]),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  staticClass: "w-full",
                  attrs: { name: "mailTo", maxlength: 250 },
                  model: {
                    value: _vm.mailTo,
                    callback: function($$v) {
                      _vm.mailTo = $$v
                    },
                    expression: "mailTo"
                  }
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("mailTo"),
                        expression: "errors.has('mailTo')"
                      }
                    ],
                    staticClass: "text-danger text-sm mt-0"
                  },
                  [_vm._v(_vm._s(_vm.errors.first("mailTo")))]
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "vx-row mb-2" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Subject.i18n) ||
                        _vm.resources.Subject.name
                    )
                  )
                ]),
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  staticClass: "w-full",
                  attrs: { name: "mailSubject", maxlength: 50 },
                  model: {
                    value: _vm.mailSubject,
                    callback: function($$v) {
                      _vm.mailSubject = $$v
                    },
                    expression: "mailSubject"
                  }
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("mailSubject"),
                        expression: "errors.has('mailSubject')"
                      }
                    ],
                    staticClass: "text-danger text-sm"
                  },
                  [_vm._v(_vm._s(_vm.errors.first("mailSubject")))]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.SelectTemplate.i18n) ||
                        _vm.resources.SelectTemplate.name
                    )
                  )
                ]),
                _c("v-select", {
                  staticClass: "w-full",
                  attrs: {
                    type: "text",
                    "icon-pack": "feather",
                    icon: "icon-arrow-down",
                    "icon-no-border": "",
                    clearable: true,
                    options: _vm.templateOption,
                    reduce: function(template) {
                      return template.id
                    },
                    label: "name",
                    hint: "" + _vm.template
                  },
                  on: {
                    input: function($event) {
                      return _vm.templateBody("" + _vm.template)
                    }
                  },
                  model: {
                    value: _vm.template,
                    callback: function($$v) {
                      _vm.template = $$v
                    },
                    expression: "template"
                  }
                })
              ],
              1
            )
          ]),
          _c("quill-editor", {
            attrs: { options: _vm.editorOption },
            model: {
              value: _vm.mailMessage,
              callback: function($$v) {
                _vm.mailMessage = $$v
              },
              expression: "mailMessage"
            }
          }),
          _c("div", { staticClass: "vx-row mb-2 mt-2" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full" },
              [
                _c(
                  "label",
                  {
                    staticClass: "btn btn-primary",
                    staticStyle: { "margin-right": "80px" }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Attachment.i18n) ||
                          _vm.t(_vm.resources.Attachment.name)
                      )
                    )
                  ]
                ),
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { type: "file" },
                  on: { change: _vm.onFileSelected }
                })
              ],
              1
            )
          ]),
          _c(
            "vs-row",
            { attrs: { "vs-type": "flex", "vs-justify": "center" } },
            [
              _c(
                "vs-button",
                {
                  staticClass: "mt-5 block text-left",
                  attrs: { type: "filled" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.submitForm($event)
                    }
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Send.i18n) || _vm.resources.Send.name
                    )
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }