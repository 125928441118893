<template>
  <div id="page-user-list">
    <vx-card
      :title="$t(resources.Filters.i18n) || t(resources.Filters.name)"
      class="mb-4"
    >
      <div class="vx-row">
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <vs-input
            class="w-full"
            icon-pack="feather"
            icon="icon-mail"
            icon-no-border
            :label="$t(resources.Email.i18n) || resources.Email.name"
            v-model="email"
          />
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2">
          <vs-input
            type="number"
            class="w-full"
            icon-pack="feather"
            icon="icon-user"
            icon-no-border
            :label="
              $t(resources.DistributorID.i18n) || resources.DistributorID.name
            "
            v-model="itaCode"
          />
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.Type.i18n) || resources.Type.name
            }}</label>
            <v-select
              v-model="type"
              :clearable="true"
              :options="typeOption"
              :reduce="(item) => item.id"
              label="name"
            />
          </div>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2">
          <vs-select
            class="vx-col w-full"
            :label="`IBO - ${
              $t(resources.Status.i18n) || resources.Status.name
            }`"
            v-model="iboStatus"
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="$t(item.i18n) || item.text"
              v-for="(item, index) in iboStatusList"
            />
          </vs-select>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2">
          <vs-select
            class="vx-col w-full"
            :label="`IBO - ${
              $t(resources.IsRealtor.i18n) || resources.IsRealtor.name
            }`"
            v-model="isRealtor"
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="$t(item.i18n) || item.text"
              v-for="(item, index) in isRealtorList"
            />
          </vs-select>
        </div>
        <div class="vx-col mb-2 mt-5">
          <vs-button color="primary" type="filled" @click="get()">
            {{ $t(resources.Search.i18n) || resources.Search.name }}
          </vs-button>
        </div>
      </div>
    </vx-card>

    <div class="vx-card p-6">
      <div class="flex flex-wrap items-center">
        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{
                  currentPage * paginationPageSize - (paginationPageSize - 1)
                }}
                -
                {{
                  usersData.length - currentPage * paginationPageSize > 0
                    ? currentPage * paginationPageSize
                    : usersData.length
                }}
                of {{ usersData.length }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(25)">
                <span>25</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <div
          class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
        >
          <vs-input
            class="mb-4 md:mb-0 mr-4"
            v-model="searchQuery"
            @input="updateSearchQuery"
            :placeholder="$t(resources.Search.i18n) || resources.Search.name"
          />
          <vs-button
            class="mb-4 md:mb-0 mr-1"
            @click="gridApi.exportDataAsCsv()"
          >
            {{ $t(resources.ExportAs.i18n) || resources.ExportAs.name }}
          </vs-button>
        </div>
      </div>

      <!-- AgGrid Table -->
      <ag-grid-vue
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="usersData"
        rowSelection="single"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        @row-selected="onRowSelected"
      ></ag-grid-vue>

      <vs-pagination :total="totalPages" :max="7" v-model="currentPage" />
    </div>
  </div>
</template>

<script>
import resources from "@/i18n/resources.js";
import database from "@/assets/resources/enums/database.json";
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import axios from "axios";
import vSelect from "vue-select";

export default {
  components: {
    AgGridVue,
    vSelect,
  },
  data() {
    return {
      resources: resources,
      database: database,
      email: "",
      itaCode: "",
      type: 2,
      typeOption: [],
      iboStatus: 1,
      iboStatusList: [
        {
          text: "All",
          value: "",
          i18n: "All",
        },
        {
          text: "Active",
          value: "1",
          i18n: "Active",
        },
        {
          text: "Inactive",
          value: "2",
          i18n: "Inactive",
        },
      ],
      isRealtor: "",
      isRealtorList: [
        {
          text: "All",
          value: "",
          i18n: "All",
        },
        {
          text: "Yes",
          value: "1",
          i18n: "Yes",
        },
        {
          text: "No",
          value: "0",
          i18n: "No",
        },
      ],
      usersData: [],
      searchQuery: "",
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: "#",
          field: "contactId",
          hide: true,
        },
        {
          headerName: `IBO - ${this.$i18n.t("Status")}`,
          field: "itaStatus",
          filter: true,
          width: 130,
          cellRenderer: function (event) {
            let object = "";
            switch (event.node.data.itaStatus) {
              case 0:
                object = "";
                break;
              case 1:
                object =
                  '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-success), 1);">check_circle</i>';
                break;
              case 2:
                object =
                  '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-danger), 1);">highlight_off</i>';
                break;
            }
            return object;
          },
        },
        {
          headerName: this.$i18n.t("IsRealtor"),
          field: "isRealtor",
          filter: true,
          width: 130,
          cellRenderer: function (event) {
            let object = "";
            switch (event.node.data.isRealtor) {
              case 0:
                object =
                  '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-danger), 1);">highlight_off</i>';
                break;
              case 1:
                object =
                  '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-success), 1);">check_circle</i>';
                break;
            }
            return object;
          },
        },
        {
          headerName: this.$i18n.t("Type"),
          field: "contactTypeName",
          filter: true,
          width: 150,
        },
        {
          headerName: this.$i18n.t("DistributorID"),
          field: "itaCode",
          filter: true,
          width: 150,
        },
        {
          headerName: this.$i18n.t("Name"),
          field: "name",
          filter: true,
        },
        {
          headerName: this.$i18n.t("LastName"),
          field: "lastName",
          filter: true,
          width: 200,
        },
        {
          headerName: this.$i18n.t("Email"),
          field: "email",
          filter: true,
          width: 280,
        },
        {
          headerName: this.$i18n.t("CreateDate"),
          field: "creationDate",
          filter: true,
        },
        {
          headerName: this.$i18n.t("Password"),
          field: "hasKey",
          filter: true,
          width: 110,
          cellRenderer: function (event) {
            let object = "";
            switch (event.node.data.hasKey) {
              case 0:
                object =
                  '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-danger), 1);">highlight_off</i>';
                break;
              case 1:
                object =
                  '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-success), 1);">check_circle</i>';
                break;
            }
            return object;
          },
        },
        {
          headerName: this.$i18n.t("PasswordUpdatedDate"),
          field: "passwordUpdatedDate",
          filter: true,
        },
      ],
    };
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 20;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  async created() {
    await this.get();
    await this.getContactType();
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    async get() {
      this.$vs.loading();

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_MEMBERSHIP}contact/get`,
        data: {
          status: this.status,
          type: this.type,
          itaCode: this.itaCode,
          email: this.email.toLowerCase(),
          iboStatus: this.iboStatus,
          isRealtor: this.isRealtor,
        },
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale,
        },
      }).then(
        (result) => {
          if (result.data.code == 204) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.usersData = [];
          } else {
            this.usersData = result.data.data;
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },

    async getContactType() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_MEMBERSHIP}contactType/get`,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale,
        },
      }).then(
        (result) => {
          if (result.data.code == 204) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.typeOption = [
              {
                id: "0",
                name: this.$i18n.t("All"),
              },
            ];
          } else {
            result.data.data.push({
              id: "0",
              name: this.$i18n.t("All"),
            });
            this.typeOption = result.data.data;
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    onRowSelected(event) {
      if (
        event.node.data.contactTypeId == this.database.contactType.Ita ||
        event.node.data.contactTypeId == this.database.contactType.Employed ||
        event.node.data.contactTypeId == this.database.contactType.Customer ||
        event.node.data.contactTypeId == this.database.contactType.ClientIta
      ) {
        this.$router.push({
          name: "users-detail",
          params: {
            contactId: event.node.data.id,
            contactTypeId: event.node.data.contactTypeId,
          },
        });
      }
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
};
</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
