var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.tracking.length == 0
        ? _c(
            "div",
            { staticClass: "vx-col md:w-1/4 w-full mb-base mt-4" },
            [
              _c(
                "vs-button",
                {
                  attrs: { color: "primary", type: "filled" },
                  on: {
                    click: function($event) {
                      return _vm.StartManagement()
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(_vm.resources.StartManagement.i18n) ||
                          _vm.resources.StartManagement.name
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.tracking.length != 0
        ? _c("div", { staticClass: "mb-base mt-4" }, [
            _c(
              "div",
              { staticClass: "vx-row" },
              [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/3 w-full" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.Stage.i18n) ||
                            _vm.resources.Stage.name
                        )
                      )
                    ]),
                    _c("v-select", {
                      attrs: {
                        clearable: false,
                        options: _vm.stageList,
                        reduce: function(x) {
                          return x.id
                        },
                        label: "name",
                        scrollable: ""
                      },
                      model: {
                        value: _vm.stageId,
                        callback: function($$v) {
                          _vm.stageId = $$v
                        },
                        expression: "stageId"
                      }
                    })
                  ],
                  1
                ),
                _c("vs-input", {
                  staticClass: "vx-col w-full md:w-1/3 mb-2 inputx",
                  attrs: {
                    label:
                      _vm.$t(_vm.resources.Subject.i18n) ||
                      _vm.t(_vm.resources.Subject.name)
                  },
                  model: {
                    value: _vm.subject,
                    callback: function($$v) {
                      _vm.subject = $$v
                    },
                    expression: "subject"
                  }
                }),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/3 w-full mb-2" },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "btn btn-primary",
                        staticStyle: { "margin-right": "80px" }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.Attachment.i18n) ||
                              _vm.resources.Attachment.name
                          )
                        )
                      ]
                    ),
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { type: "file" },
                      on: { change: _vm.onFileSelected }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col md:w-1/3 w-full" },
                [
                  _c("label", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Remark.i18n) ||
                          _vm.resources.Remark.name
                      )
                    )
                  ]),
                  _c("vs-textarea", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      "icon-no-border": "",
                      icon: "icon-clipboard",
                      maxlength: "9000"
                    },
                    model: {
                      value: _vm.remark,
                      callback: function($$v) {
                        _vm.remark = $$v
                      },
                      expression: "remark"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "vx-col md:w-1/6 w-full mb-4" }, [
                _c(
                  "ul",
                  {
                    staticClass: "vx-col w-full",
                    staticStyle: { "margin-top": "20px" }
                  },
                  [
                    _c(
                      "li",
                      [
                        _c(
                          "vs-checkbox",
                          {
                            model: {
                              value: _vm.publish,
                              callback: function($$v) {
                                _vm.publish = $$v
                              },
                              expression: "publish"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.RemarkPublish.i18n) ||
                                  _vm.resources.RemarkPublish.name
                              )
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                )
              ]),
              _c(
                "div",
                { staticClass: "vx-col mb-2 mt-5" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "mb-4",
                      attrs: { color: "primary", type: "filled" },
                      on: {
                        click: function($event) {
                          return _vm.changeStage()
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(_vm.resources.Save.i18n) ||
                              _vm.resources.Save.name
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _c("vx-timeline", { attrs: { data: _vm.comments } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }