<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center"
    id="page-login"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col hidden lg:block lg:w-1/2">
              <img src="@/assets/images/pages/login.png" alt="login" class="mx-auto" />
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="p-8">
                <div class="text-right">
                  <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
                    <span class="cursor-pointer flex i18n-locale">
                      <img
                        class="h-5 w-7"
                        :src="
                          require(`@/assets/images/flags/${$i18n.locale}.png`)
                        "
                        :alt="$i18n.locale"
                      />
                    </span>
                    <vs-dropdown-menu class="w-48 i18n-dropdown vx-navbar-dropdown">
                      <vs-dropdown-item @click="updateLocale('en')">
                        <img class="h-4 w-5 mr-1" src="@/assets/images/flags/en.png" alt="en" />
                        &nbsp;English
                      </vs-dropdown-item>
                      <vs-dropdown-item @click="updateLocale('es')">
                        <img class="h-4 w-5 mr-1" src="@/assets/images/flags/es.png" alt="es" />
                        &nbsp;Español
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </div>

                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">{{ $t(resources.Login.i18n) || resources.Login.name }}</h4>
                  <p>{{ $t(alerts.MsgWelcome.i18n) || alerts.MsgWelcome.name }}.</p>
                </div>
                <vs-input
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  icon="icon icon-user"
                  icon-pack="feather"
                  :label-placeholder="
                    $t(resources.MyDistributor.i18n) ||
                      resources.MyDistributor.name
                  "
                  v-model="itaCodeRegistration"
                  class="w-full no-icon-border"
                />
                <vs-input
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  icon="icon icon-user"
                  icon-pack="feather"
                  :label-placeholder="
                    $t(resources.DistributorID.i18n) ||
                      resources.DistributorID.name
                  "
                  v-model="itaCode"
                  class="w-full no-icon-border"
                />
                <!-- <span class="text-danger text-sm" v-show="errors.has('ita')">{{
                  errors.first("ita")
                }}</span>-->
                <vs-input
                  v-validate="'required|email|min:3'"
                  data-vv-validate-on="blur"
                  name="email"
                  icon="icon icon-user"
                  icon-pack="feather"
                  :label-placeholder="
                    $t(resources.Email.i18n) || resources.Email.name
                  "
                  v-model="email"
                  class="w-full no-icon-border"
                />
                <!-- <span class="text-danger text-sm">{{
                  errors.first("email")
                }}</span>-->

                <vs-input
                  data-vv-validate-on="blur"
                  v-validate="'required|min:4|max:30'"
                  type="password"
                  name="password"
                  icon="icon icon-lock"
                  icon-pack="feather"
                  :label-placeholder="
                    $t(resources.Password.i18n) || resources.Password.name
                  "
                  v-model="password"
                  class="w-full mt-6 no-icon-border"
                />
                <!-- <span class="text-danger text-sm">{{
                  errors.first("password")
                }}</span>-->
                <div class="flex flex-wrap justify-between my-5">
                  <router-link to="/pages/forgot-password">
                    {{
                    $t(resources.ForgotPassword.i18n) ||
                    resources.ForgotPassword.name
                    }}
                  </router-link>
                </div>
                <div class="flex flex-wrap float-right my-5">
                  <vs-button
                    :disabled="!validateForm"
                    @click="login"
                  >{{ $t(resources.Login.i18n) || resources.Login.name }}</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import auth from "@/assets/resources/auth.json";
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import axios from "axios";
import VueJwtDecode from "vue-jwt-decode";
import utilities from "@/assets/resources/utilities.json";

export default {
  data() {
    return {
      email: "",
      password: "",
      itaCode: 1,
      itaCodeRegistration: 1,

      resources: resources,
      alerts: alerts,
      auth: auth,
      utilities: utilities
    };
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.email != "" &&
        this.password != "" &&
        this.itaCode != ""
      );
    }
  },
  methods: {
    updateLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem("language", locale);
    },

    async login() {
      // Loading
      this.$vs.loading();
      await axios({
        method: "POST",
        url: process.env.VUE_APP_OVAPI + "users/authenticate",
        data: {
          email: this.email,
          password: this.password,
          language: this.$i18n.locale.toUpperCase(),
          itaCode: this.itaCodeRegistration,
          ApplicationId: this.utilities.application.OV,
          deviceId: localStorage.getItem('__mmapiwsid')
        },
        headers: {
          "content-type": "application/json"
        }
      })
        .then(result => {
          if (result.data.contactType === 2) {
            let decoded = VueJwtDecode.decode(result.data.token);
            let decodedVT = VueJwtDecode.decode(result.data.tokenVT);

            localStorage.setItem("tokenExpiry", decoded.exp);
            localStorage.setItem("tokenExpiryVT", decodedVT.exp);
            localStorage.setItem("loggedIn", result.data.token);
            localStorage.setItem("tokenVT", result.data.tokenVT);
            localStorage.setItem("tokenInfo", result.data.infotoken);

            localStorage.setItem(
              "userInfo",
              JSON.stringify({
                id: result.data.id,
                name: result.data.name,
                lastName: result.data.lastName,
                email: result.data.email,
                photoURL: result.data.imageUrl,
                gender: result.data.gender,
                itaCode: this.itaCode,
                countryCode: result.data.countryOperation,
                country: result.data.countryOperationName,
                address: result.data.address != null ? result.data.address : "",
                birthDate: result.data.birthDate,
                phone: result.data.phone,
                nationalityName: result.data.nationalityName,
                countryName: result.data.countryName,
                documentNumber: result.data.documentNumber,
                stateName: result.data.stateName,
                cityName: result.data.cityName,
                language: result.data.language.toLowerCase()
              })
            );
            this.$router.push("/");
          } else {
            this.$vs.notify({
              time: 6000,
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgEmployedNotExist"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-alert-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        });
      this.$vs.loading.close();
    }
  }
};
</script>
<style>
  .vs-input--input.hasValue + .vs-placeholder-label, .vs-input--input:focus + .vs-placeholder-label {
    transform: translate(-3px,-75%) !important;
  }
</style>
