<template>
  <div>
    <div v-if="canSearch">
      <vx-card class="mb-4">
        <vs-row vs-type="flex" vs-justify="center" v-if="canUpdate">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <vs-button
              radius
              color="success"
              icon-pack="feather"
              icon="icon-plus"
              size="large"
              @click="cleanFields(true)"
            ></vs-button>
          </vs-col>
        </vs-row>
        <h4 class="mb-4">
          {{ $t(resources.Filters.i18n) || resources.Filters.name }}
        </h4>
        <div class="vx-row">
          <div class="vx-col w-full md:w-1/5 mb-2">
            <label class="vs-input--label">
              {{ $t(resources.StartDate.i18n) || resources.StartDate.name }}
            </label>
            <datepicker
              :language="languages[language]"
              format="d MMMM yyyy"
              v-model="start_date"
            ></datepicker>
          </div>
          <div class="vx-col w-full md:w-1/5 mb-2">
            <label class="vs-input--label">{{
              $t(resources.EndDate.i18n) || resources.EndDate.name
            }}</label>
            <datepicker
              :language="languages[language]"
              format="d MMMM yyyy"
              v-model="end_date"
            ></datepicker>
          </div>
          <vs-button
            color="primary"
            type="filled"
            icon-pack="feather"
            icon="icon-refresh-ccw"
            class="mt-5"
            @click="cleanDate()"
          ></vs-button>
          <div class="vx-col md:w-1/6 w-full mb-2">
            <label class="vs-input--label">{{
              $t(resources.Currency.i18n) || resources.Currency.name
            }}</label>
            <v-select
              :placeholder="$t(resources.Select.i18n) || resources.Select.name"
              v-model="currencyId"
              v-validate="'required'"
              :clearable="false"
              :options="currencyList"
              :reduce="item => item.id"
              label="text"
            />
          </div>
          <div class="vx-col w-full md:w-1/6 mb-2">
            <label class="vs-input--label">{{
              $t(resources.Stage.i18n) || resources.Stage.name
            }}</label>
            <v-select
              v-model="stageId"
              :clearable="false"
              :options="stageList"
              :reduce="x => x.id"
              label="name"
              scrollable
              v-validate="'required'"
              name="stage"
            />
            <span class="text-danger text-sm" v-show="errors.has('stage')">{{
              errors.first("stage")
            }}</span>
          </div>
          <div class="vx-col w-full md:w-1/6 mb-2">
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                $t(resources.AccountType.i18n) || resources.AccountType.name
              }}</label>
              <v-select
                v-model="accountType"
                :clearable="true"
                :options="accountTypeOption"
                :reduce="item => item.id"
                label="name"
              />
            </div>
          </div>
          <div class="vx-col md:w-1/6 w-full mb-2">
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                $t(resources.AccountNumber.i18n) || resources.AccountNumber.name
              }}</label>
              <v-select
                id="accountId"
                @keyup.native="getByText('accountId')"
                v-model="accountId"
                :clearable="true"
                :options="accountList"
                :reduce="item => item.id"
                label="nameWithNumber"
                name="destination"
              />
            </div>
          </div>
          <div class="vx-col w-full md:w-1/6 mb-2">
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                $t(resources.Type.i18n) || resources.Type.name
              }}</label>
              <v-select
                v-model="type"
                :clearable="true"
                :options="typeOption"
                :reduce="item => item.id"
                label="name"
              />
            </div>
          </div>
          <div class="vx-col w-full md:w-1/6 mb-2">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-hash"
              icon-no-border
              :label="
                $t(resources.TransactionNumber.i18n) ||
                  resources.TransactionNumber.name
              "
              v-model="transactionNumber"
            />
          </div>
          <div class="vx-col w-full md:w-1/6 mb-2">
            <vs-input
              type="number"
              class="w-full"
              icon-pack="feather"
              icon="icon-user"
              icon-no-border
              :label="
                $t(resources.DistributorID.i18n) || resources.DistributorID.name
              "
              v-model="itaCodeForm"
            />
          </div>
          <div class="vx-col w-full md:w-1/6 mb-2">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-hash"
              icon-no-border
              :label="
                $t(resources.ReferenceCode.i18n) || resources.ReferenceCode.name
              "
              v-model="referenceCode"
            />
          </div>

          <div class="vx-col mb-2 mt-5">
            <vs-button color="primary" type="filled" @click="get()">{{
              $t(resources.Search.i18n) || resources.Search.name
            }}</vs-button>
          </div>
        </div>
      </vx-card>
      <vx-card class="mb-4">
        <vs-collapse>
          <vs-collapse-item>
            <div slot="header">
              {{ $t(resources.Summary.i18n) || resources.Summary.i18n }}
            </div>
            <h2
              class="text-center text-primary"
              v-if="summaryByAccount.length > 0"
            >
              <strong>{{
                $t(resources.AccountType.i18n) || resources.AccountType.name
              }}</strong>
            </h2>
            <vs-row vs-type="flex" vs-justify="center" class="mb-4">
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="2"
                vs-sm="3"
                vs-xs="12"
                class="mr-2"
                :key="indextr"
                v-for="(account, indextr) in summaryByAccount"
              >
                <vx-card card-background="success" content-color="#fff">
                  <h5 class="text-center text-white">
                    {{ account.accountType }}
                  </h5>
                  <h1 class="text-center text-white">
                    <strong>${{ account.value.toFixed(2) }}</strong>
                  </h1>
                </vx-card>
              </vs-col>
            </vs-row>
            <h2
              class="text-center text-primary"
              v-if="summaryByActivity.length > 0"
            >
              <strong>{{
                $t(resources.Type.i18n) || resources.Type.name
              }}</strong>
            </h2>
            <vs-row vs-type="flex" vs-justify="center" class="mb-4">
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="2"
                vs-sm="3"
                vs-xs="12"
                class="mr-2 mb-2"
                :key="indextr"
                v-for="(account, indextr) in summaryByActivity"
              >
                <vx-card card-background="success" content-color="#fff">
                  <h5 class="text-center text-white">
                    {{ account.accountType }}
                  </h5>
                  <h1 class="text-center text-white">
                    <strong>${{ account.value.toFixed(2) }}</strong>
                  </h1>
                </vx-card>
              </vs-col>
            </vs-row>
          </vs-collapse-item>
        </vs-collapse>
      </vx-card>
      <div id="ag-grid-demo">
        <vx-card>
          <!-- TABLE ACTION ROW -->
          <div class="flex flex-wrap justify-between items-center">
            <!-- ITEMS PER PAGE -->
            <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
              <vs-dropdown vs-trigger-click class="cursor-pointer">
                <div
                  class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                  <span class="mr-2"
                    >{{
                      currentPage * paginationPageSize -
                        (paginationPageSize - 1)
                    }}
                    -
                    {{
                      dataList.length - currentPage * paginationPageSize > 0
                        ? currentPage * paginationPageSize
                        : dataList.length
                    }}
                    of {{ dataList.length }}</span
                  >
                  <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
                <vs-dropdown-menu>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                    <span>20</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                    <span>50</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                    <span>100</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                    <span>150</span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>

            <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
            <div
              class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
            >
              <vs-input
                class="mb-4 md:mb-0 mr-4"
                v-model="searchQuery"
                @input="updateSearchQuery"
                :placeholder="
                  $t(resources.Search.i18n) || resources.Search.name
                "
              />
              <vs-button
                class="mb-4 md:mb-0"
                @click="gridApi.exportDataAsCsv()"
              >
                {{ $t(resources.ExportAs.i18n) || resources.ExportAs.name }}
              </vs-button>
            </div>
          </div>
          <ag-grid-vue
            :gridOptions="gridOptions"
            class="ag-theme-material w-100 my-4 ag-grid-table"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :rowData="dataList"
            rowSelection="single"
            colResizeDefault="shift"
            :animateRows="true"
            :floatingFilter="true"
            :pagination="true"
            :paginationPageSize="paginationPageSize"
            :suppressPaginationPanel="true"
            @row-selected="onRowSelected"
          ></ag-grid-vue>
          <vs-pagination
            :total="totalPages"
            :max="maxPageNumbers"
            v-model="currentPage"
          />
        </vx-card>
      </div>
      <vs-popup
        :title="$t(resources.Description.i18n) || resources.Description.i18n"
        :active.sync="popupDetail"
      >
        <h1 class="text-center text-primary font-bold mb-base">
          {{ $t(resources.Answer.i18n) || resources.Answer.i18n }}:
        </h1>
        <p class="text-center mb-base">{{ description }}</p>
      </vs-popup>
      <vs-popup
        :title="$t(resources.Create.i18n) || resources.Create.i18n"
        :active.sync="popupCreate"
      >
        <div class="vx-row">
          <div class="vx-col w-full md:w-1/2 mb-2">
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                $t(resources.Type.i18n) || resources.Type.name
              }}</label>
              <v-select
                v-model="typeId"
                :clearable="true"
                :options="typeOption"
                :reduce="item => item.id"
                label="name"
              />
            </div>
          </div>
          <div class="vx-col md:w-1/2 w-full mb-2">
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                $t(resources.AccountNumber.i18n) || resources.AccountNumber.name
              }}</label>
              <v-select
                id="account"
                @keyup.native="getByText('account')"
                v-model="account"
                :clearable="true"
                :options="accountList"
                :reduce="item => item.id"
                label="nameWithNumber"
                name="account"
                v-validate="'required'"
                v-on:input="setAccountInfo(`${account}`)"
              />
            </div>
            <span class="text-danger text-sm" v-show="errors.has('account')">{{
              errors.first("account")
            }}</span>
          </div>
          <div class="vx-col w-full md:w-1/2 mb-2">
            <vs-input
              readonly
              class="w-full"
              icon-pack="feather"
              icon="icon-user"
              icon-no-border
              :label="$t(resources.Name.i18n) || resources.Name.name"
              v-model="contactName"
              name="contactName"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('contactName')"
              >{{ errors.first("contactName") }}</span
            >
          </div>
          <div class="vx-col w-full md:w-1/2 mb-2">
            <vs-input
              readonly
              class="w-full"
              icon-pack="feather"
              icon="icon-mail"
              icon-no-border
              :label="$t(resources.Email.i18n) || resources.Email.name"
              v-model="contactEmail"
              name="contactEmail"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('contactEmail')"
              >{{ errors.first("contactEmail") }}</span
            >
          </div>
          <div class="vx-col w-full md:w-1/2 mb-2">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-hash"
              icon-no-border
              :label="
                $t(resources.ReferenceCode.i18n) || resources.ReferenceCode.name
              "
              v-model="referenceId"
              name="referenceCode"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('referenceCode')"
              >{{ errors.first("referenceCode") }}</span
            >
          </div>

          <div class="vx-col md:w-1/2 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-dollar-sign"
                type="number"
                :label="$t(resources.Value.i18n) || resources.Value.name"
                v-model="value"
                v-validate="'required|decimal:2'"
                name="value"
              />
            </div>
            <span class="text-danger text-sm" v-show="errors.has('value')">{{
              errors.first("value")
            }}</span>
          </div>
          <div class="vx-col w-full md:w-1/2 mb-2">
            <label class="vs-input--label">{{
              $t(resources.Stage.i18n) || resources.Stage.name
            }}</label>
            <v-select
              v-model="stageId"
              :clearable="false"
              :options="stageList"
              :reduce="x => x.id"
              label="name"
              scrollable
              v-validate="'required'"
              name="stage"
            />
            <span class="text-danger text-sm" v-show="errors.has('stage')">{{
              errors.first("stage")
            }}</span>
          </div>
          <div class="vx-col md:w-1/2 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-user"
                :label="
                  $t(resources.CreatedBy.i18n) || resources.CreatedBy.name
                "
                v-model="emailSession"
                readonly
              />
            </div>
          </div>
          <div class="vx-col w-full">
            <label>{{
              $t(resources.Remark.i18n) || resources.Remark.name
            }}</label>
            <vs-textarea v-model="observation" />
          </div>
          <div class="vx-col mb-2 mt-5" style="text-align: right">
            <vs-button color="primary" type="filled" @click.prevent="valid()">
              {{ $t(resources.Send.i18n) || resources.Send.name }}
            </vs-button>
          </div>
        </div>
      </vs-popup>
    </div>
    <div v-else>
      <h2 class="text-center text-danger">
        Usted no tiene los permisos suficientes para ver este reporte.
      </h2>
    </div>
  </div>
</template>

<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import vSelect from "vue-select";
import moment from "moment";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
    Datepicker,
    vSelect
  },
  data() {
    return {
      resources: resources,
      alerts: alerts,
      language: this.$i18n.locale,
      languages: lang,
      emailSession: JSON.parse(localStorage.getItem("userInfo")).email,
      popupDetail: false,
      popupCreate: false,
      start_date: new Date(),
      end_date: new Date(),
      accountType: "",
      accountTypeOption: [],
      type: "",
      typeOption: [],
      accountId: "",
      accountList: [],
      transactionNumber: "",
      referenceCode: "",
      paid: "",
      itaCodeForm: "",
      description: "",
      typeId: "",
      account: "",
      observation: "",
      referenceId: "",
      stageId: 13,
      value: "",
      contactEmail: "",
      contactName: "",
      stageList: [],
      searchQuery: "",
      summaryByAccount: [],
      summaryByActivity: [],
      gridOptions: {},
      maxPageNumbers: 10,
      gridApi: null,
      rows: 20,
      NumberPage: 0,
      dataList: [],
      currencyId: 24,
      currencyList: [
        { text: "USD", id: 24 },
        { text: "COP", id: 40 }
      ],
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: this.$i18n.t("Status"),
          field: "stageName",
          width: 150,
          filter: true
        },
        {
          headerName: this.$i18n.t("Type"),
          field: "activityType",
          width: 170,
          filter: true
        },
        {
          headerName: this.$i18n.t("Value"),
          field: "value",
          width: 160,
          cellRenderer: function(event) {
            let object = "";
            if (event.node.data.value < 0) {
              object = `<span class='text-danger'>${event.node.data.value}</span>`;
            } else object = object = `<span>${event.node.data.value}</span>`;

            return object;
          },
          filter: true
        },
        {
          headerName: this.$i18n.t("TransactionNumber"),
          field: "transactionCode",
          width: 180,
          filter: true
        },
        {
          headerName: this.$i18n.t("AccountType"),
          field: "accountType",
          width: 180,
          filter: true
        },
        {
          headerName: this.$i18n.t("AccountNumber"),
          field: "account",
          width: 180,
          filter: true
        },
        {
          headerName: this.$i18n.t("Description"),
          field: "observation",
          width: 450,
          filter: true
        },
        {
          headerName: this.$i18n.t("ReferenceCode"),
          field: "referenceId",
          filter: true
        },
        {
          headerName: this.$i18n.t("DistributorID"),
          field: "itaCode",
          width: 180,
          filter: true
        },
        {
          headerName: this.$i18n.t("Name"),
          field: "contactName",
          width: 280,
          filter: true
        },
        {
          headerName: this.$i18n.t("CreationDate"),
          field: "creationDate",
          filter: true
        },
        {
          headerName: this.$i18n.t("CreatedBy"),
          field: "createdBy",
          width: 300,
          filter: true
        },
        {
          headerName: this.$i18n.t("PaymentDate"),
          field: "paymentDate",
          filter: true
        }
      ]
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("level", null);
      } else this.gridOptions.columnApi.setColumnPinned("level", "left");
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 50;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    },
    canUpdate() {
      let listEmails = [
        "a.gomez@visiontravel.net",
        "odalis@visiontravel.net",
        "a.ramirez@visiontravel.net",
        "felipe@visiontravel.net",
        "v.hernandez@visiontravelmail.net"
      ];
      let isAllow = listEmails.filter(
        x => x == this.emailSession.toLowerCase()
      );
      if (isAllow.length > 0) return true;

      return false;
    },
    canSearch() {
      let listEmails = [
        "a.gomez@visiontravel.net",
        "odalis@visiontravel.net",
        "a.ramirez@visiontravel.net",
        "felipe@visiontravel.net",
        "v.hernandez@visiontravelmail.net",
        "l.palacio@visiontravelmail.net",
        "y.villa@visiontravelmail.net",
        "clara.chavarriaga@visiontravel.net",
        "j.villegas@visiontravel.net",
        "d.lopez@visiontravelmail.net",
        "j.builes@visiontravelmail.net",
        "d.moreno@visiontravelmail.net"
      ];
      let isAllow = listEmails.filter(
        x => x == this.emailSession.toLowerCase()
      );
      if (isAllow.length > 0) return true;

      return false;
    }
  },
  async created() {
    await this.getActivityType();
    await this.getAccountType();
    await this.get();
    await this.getStagePayment(2);
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    async cleanFields(openPopUp) {
      this.typeId = "";
      this.account = "";
      this.observation = "";
      this.referenceId = "";
      this.stageId = "";
      this.value = "";
      if (openPopUp) {
        this.popupCreate = true;
        await this.getStagePayment();
      } else this.popupCreate = false;
    },

    setAccountInfo(item) {
      let _data = this.accountList.find(x => x.id == item);
      this.contactName = _data.contactName;
      this.contactEmail = _data.contactEmail;
    },

    async getActivityType() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_ACCOUNTING}activityType/get`,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      }).then(
        result => {
          if (result.data.success && result.data.code == 204) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.typeOption = [
              {
                id: "",
                name: this.$i18n.t("All")
              }
            ];
          } else {
            result.data.data.push({
              id: "",
              name: this.$i18n.t("All")
            });
            this.typeOption = result.data.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getAccountType() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_ACCOUNTING}accountType/get`,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      }).then(
        result => {
          if (result.data.success && result.data.code == 204) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.accountTypeOption = [
              {
                id: "",
                name: this.$i18n.t("All")
              }
            ];
          } else {
            result.data.data.push({
              id: "",
              name: this.$i18n.t("All")
            });
            this.accountTypeOption = result.data.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async get() {
      this.$vs.loading();
      let startDate = "";
      let endDate = "";

      if (this.start_date != null && this.end_date != null) {
        startDate = moment(this.start_date).format("YYYY-MM-DD");
        endDate = moment(this.end_date).format("YYYY-MM-DD");
      }

      const _data = {
        status: this.stageId,
        start_date: startDate,
        end_date: endDate,
        accountType: this.accountType,
        accountId: this.accountId,
        type: this.type,
        transactionNumber: this.transactionNumber,
        itaCode: this.itaCodeForm,
        referenceId: this.referenceCode,
        currencyId: this.currencyId,
        group: false
      };

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_ACCOUNTING}blockPay/get`,
        data: _data,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      }).then(
        async result => {
          if (result.data.success && result.data.code == 204) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.dataList = [];
          } else {
            const _emails = [
            "l.palacio@visiontravelmail.net",
            "y.villa@visiontravelmail.net",
            "clara.chavarriaga@visiontravel.net",
            "j.villegas@visiontravel.net"
            ];

            if (_emails.includes(this.emailSession)) {
              const _accountsId = [
                2774,
                102636,
                102650,
                102651,
                102699,
                102663,
                102673,
                102677,
                102679,
                102680,
                485726,
                485727
              ];
              this.dataList = result.data.data.filter(
                x => !_accountsId.includes(x.accountId)
              );
            } else {
              this.dataList = result.data.data;
            }
          }
          await this.getSummary(_data);
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async getSummary(data) {
      data.group = true;

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_ACCOUNTING}blockPay/get`,
        data: data,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      }).then(
        result => {
          if (result.data.success && result.data.code == 204) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.dataList = [];
          } else {
            this.summaryByAccount = result.data.data.filter(x => x.type == 1);
            this.summaryByActivity = result.data.data.filter(x => x.type == 2);
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getByText(destination) {
      let getElementId;
      if (destination == "accountId")
        getElementId = document.getElementById("accountId");
      if (destination == "account")
        getElementId = document.getElementById("account");

      let text = getElementId.__vue__.search;

      if (text.length >= 3) {
        await axios({
          method: "GET",
          url: `${
            process.env.VUE_APP_APIVT
          }Account/GetByText/${this.$i18n.locale.toUpperCase()}/${text}`,
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tokenVT")}`
          }
        }).then(
          result => {
            if (!result.data.success) {
              this.$vs.notify({
                title: this.$i18n.t("Alert"),
                text: this.$i18n.t("MsgNotFoundInfo"),
                color: "warning",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle"
              });
              this.accountList = [];
            } else {
              this.accountList = result.data.data;
            }
          },
          error => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError") + "<br/>" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        );
      }
    },

    async getStagePayment(type) {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_OVAPI}Stage/${this.$i18n.locale}/${type}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.stageList = [];
          } else {
            this.stageList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    valid() {
      this.$validator.validateAll().then(async result => {
        if (result) {
          await this.create();
        } else {
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: this.$i18n.t("MsgRequiredField"),
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      });
    },

    async create() {
      this.$vs.loading();

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Activity/Create`,
        data: {
          typeActivityId: this.typeId,
          accountId: this.account,
          stageId: this.stageId,
          transactionCode: "",
          value: this.value,
          referenceId: this.referenceId,
          observation: this.observation,
          createdBy: this.emailSession,
          language: this.$i18n.locale.toUpperCase()
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            await this.cleanFields(false);
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    onRowSelected(event) {
      this.description = event.node.data.information;
      this.popupDetail = true;
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  }
};
</script>
<style>
.includeIconOnly.large {
  width: 60px !important;
  height: 60px !important;
  font-size: 0.7em;
}

.vs-button.large {
  font-size: 3.25rem;
}

.vs-button.large .vs-button--icon {
  font-size: 3.25rem;
}
</style>
