<!-- =========================================================================================
    File Name: ForgotPassword.vue
    Description: FOrgot Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="h-screen flex w-full bg-img">
    <div class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img src="@/assets/images/pages/forgot-password.png" alt="login" class="mx-auto" />
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
              <div class="p-8">
                <div class="text-right">
                  <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
                    <span class="cursor-pointer flex i18n-locale">
                      <img
                        class="h-5 w-7"
                        :src="
                          require(`@/assets/images/flags/${$i18n.locale}.png`)
                        "
                        :alt="$i18n.locale"
                      />
                    </span>
                    <vs-dropdown-menu class="w-48 i18n-dropdown vx-navbar-dropdown">
                      <vs-dropdown-item @click="updateLocale('en')">
                        <img class="h-4 w-5 mr-1" src="@/assets/images/flags/en.png" alt="en" />
                        &nbsp;English
                      </vs-dropdown-item>
                      <vs-dropdown-item @click="updateLocale('es')">
                        <img class="h-4 w-5 mr-1" src="@/assets/images/flags/es.png" alt="es" />
                        &nbsp;Español
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </div>
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">
                    {{
                    $t(resources.RecoverPassword.i18n) ||
                    resources.RecoverPassword.name
                    }}
                  </h4>
                  <p>
                    {{
                    $t(alerts.MsgRecoverPassword.i18n) ||
                    alerts.MsgRecoverPassword.name
                    }}
                  </p>
                </div>

                <vs-input
                  v-validate="'required|email|min:3'"
                  data-vv-validate-on="blur"
                  type="email"
                  :label-placeholder="
                    $t(resources.Email.i18n) || resources.Email.name
                  "
                  v-model="email"
                  class="w-full mb-8"
                />
                <span class="text-danger text-sm">
                  {{
                  errors.first("email")
                  }}
                </span>
                <vs-button type="border" to="/pages/login" class="px-4 w-full md:w-auto">
                  {{
                  $t(resources.Return.i18n) || resources.Return.name
                  }}
                </vs-button>
                <vs-button
                  :disabled="!validateForm"
                  class="float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-0"
                  @click="recover"
                >
                  {{
                  $t(resources.Recover.i18n) || resources.Recover.name
                  }}
                </vs-button>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import axios from "axios";

export default {
  data() {
    return {
      email: "",

      resources: resources,
      alerts: alerts
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email != "";
    }
  },
  methods: {
    updateLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem("language", locale);
    },

    async recover() {
      // Loading
      this.$vs.loading();

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_OVAPI}users/forgotPassword`,
        data: { email: this.email, language: this.$i18n.locale.toUpperCase() },
        headers: { "content-type": "application/json" }
      })
        .then(result => {
          this.$vs.loading.close();
          if (result.data.success == true) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-check"
            });
            this.$router.push({ path: "/pages/reset-password" });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-alert-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          if (error.response == undefined)
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError"),
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
        });
    }
  }
};
</script>
