/*=========================================================================================
  File Name: resources.js
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  Description: {
    name: "Description",
    slug: "description",
    i18n: "Description"
  },
  CarryInLastPeriod: {
    name: "Carry in from last period",
    slug: "CarryInLastPeriod",
    i18n: "CarryInLastPeriod"
  },
  CurrentPeriod: {
    name: "Current period",
    slug: "CurrentPeriod",
    i18n: "CurrentPeriod"
  },
  CarryNextPeriod: {
    name: "Carry forward to next period",
    slug: "CarryNextPeriod",
    i18n: "CarryNextPeriod"
  },
  BusinessCenters: {
    name: "Businesses centers",
    slug: "BusinessCenters",
    i18n: "BusinessCenters"
  },
  BusinessCenter: {
    name: "Business center",
    slug: "BusinessCenter",
    i18n: "BusinessCenter"
  },
  Center: {
    name: "Center",
    slug: "Center",
    i18n: "Center"
  },
  NewCustomers90: {
    name: "New customers the last 90 days",
    slug: "NewCustomers90",
    i18n: "NewCustomers90"
  },
  TourismSales90: {
    name: "Tourism sales the last 90 days",
    slug: "TourismSales90",
    i18n: "TourismSales90"
  },
  TourismSales365: {
    name: "Tourism sales the last 365 days",
    slug: "TourismSales365",
    i18n: "TourismSales365"
  },
  Last5Periods: {
    name: "Last five periods",
    slug: "Last5Periods",
    i18n: "Last5Periods"
  },
  Number: {
    name: "Número",
    slug: "Number",
    i18n: "Number"
  },
  PVAmount: {
    name: "PV Amount",
    slug: "PVAmount",
    i18n: "PVAmount"
  },
  Period: {
    name: "Period",
    slug: "Period",
    i18n: "Period"
  },
  DistCountryCode: {
    name: "Dist. country code",
    slug: "DistCountryCode",
    i18n: "DistCountryCode"
  },
  ShipToCountry: {
    name: "Ship to country",
    slug: "ShipToCountry",
    i18n: "ShipToCountry"
  },
  ShipToName: {
    name: "Ship to name",
    slug: "ShipToName",
    i18n: "ShipToName"
  },
  Date: {
    name: "Date",
    slug: "Date",
    i18n: "Date"
  },
  Options: {
    name: "Options",
    slug: "Options",
    i18n: "Options"
  },
  Next: {
    name: "Next",
    slug: "Next",
    i18n: "Next"
  },
  Back: {
    name: "Back",
    slug: "Back",
    i18n: "Back"
  },
  Finish: {
    name: "Finish",
    slug: "Finish",
    i18n: "Finish"
  },
  LastWeek: {
    name: "Last week",
    slug: "LastWeek",
    i18n: "LastWeek"
  },
  Currently: {
    name: "Currently",
    slug: "Currently",
    i18n: "Currently"
  },
  Total: {
    name: "Total",
    slug: "Total",
    i18n: "Total"
  },
  Volumen: {
    name: "Volumen",
    slug: "Volumen",
    i18n: "Volumen"
  },
  Available: {
    name: "Available",
    slug: "Available",
    i18n: "Available"
  },
  Profile: {
    name: "Profile",
    slug: "Profile",
    i18n: "Profile"
  },
  View: {
    name: "View",
    slug: "View",
    i18n: "View"
  },
  Download: {
    name: "Download",
    slug: "Download",
    i18n: "Download"
  },
  OrderHistory: {
    name: "Orders history",
    slug: "OrderHistory",
    i18n: "OrderHistory"
  },
  BasicInformation: {
    name: "Basic information",
    slug: "BasicInformation",
    i18n: "BasicInformation"
  },
  DocumentNumber: {
    name: "Document number",
    slug: "DocumentNumber",
    i18n: "DocumentNumber"
  },
  BirthDate: {
    name: "Birth date",
    slug: "BirthDate",
    i18n: "BirthDate"
  },
  Email: {
    name: "Email",
    slug: "Email",
    i18n: "Email"
  },
  Mobile: {
    name: "Mobile",
    slug: "Mobile",
    i18n: "Mobile"
  },
  Gender: {
    name: "Gender",
    slug: "Gender",
    i18n: "Gender"
  },
  CountryOperation: {
    name: "Country operation",
    slug: "CountryOperation",
    i18n: "CountryOperation"
  },
  Nationality: {
    name: "Nationality",
    slug: "Nationality",
    i18n: "Nationality"
  },
  ResidenceCity: {
    name: "Residence city",
    slug: "ResidenceCity",
    i18n: "ResidenceCity"
  },
  Address: {
    name: "Address",
    slug: "Address",
    i18n: "Address"
  },
  Return: {
    name: "Return",
    slug: "Return",
    i18n: "Return"
  },
  AllRightsReserved: {
    name: "All rights reserved",
    slug: "AllRightsReserved",
    i18n: "AllRightsReserved"
  },
  HandCraftedMMade: {
    name: "Hand-craft and made with",
    slug: "HandCraftedMMade",
    i18n: "HandCraftedMMade"
  },
  Invoice: {
    name: "Factura",
    slug: "Invoice",
    i18n: "Invoice"
  },
  OrderNumber: {
    name: "Order number",
    slug: "OrderNumber",
    i18n: "OrderNumber"
  },
  OrderDate: {
    name: "Order date",
    slug: "OrderDate",
    i18n: "OrderDate"
  },
  ShipTo: {
    name: "Ship to",
    slug: "ShipTo",
    i18n: "ShipTo"
  },
  BillTo: {
    name: "Bill to",
    slug: "BillTo",
    i18n: "BillTo"
  },
  Item: {
    name: "Item",
    slug: "Item",
    i18n: "Item"
  },
  Code: {
    name: "Code",
    slug: "Code",
    i18n: "Code"
  },
  QtyShip: {
    name: "Quantity ship",
    slug: "QtyShip",
    i18n: "QtyShip"
  },
  QtyOrder: {
    name: "Quantity order",
    slug: "QtyOrder",
    i18n: "QtyOrder"
  },
  Price: {
    name: "Price",
    slug: "Price",
    i18n: "Price"
  },
  Tax: {
    name: "Tax",
    slug: "Tax",
    i18n: "Tax"
  },
  Taxable: {
    name: "Taxable",
    slug: "Taxable",
    i18n: "Taxable"
  },
  Subtotal: {
    name: "Subtotal",
    slug: "Subtotal",
    i18n: "Subtotal"
  },
  Comments: {
    name: "Comments",
    slug: "Comments",
    i18n: "Comments"
  },
  ShippingAndHandling: {
    name: "Shipping and handling",
    slug: "ShippingAndHandling",
    i18n: "ShippingAndHandling"
  },
  TotalAmountDue: {
    name: "Total amount due",
    slug: "TotalAmountDue",
    i18n: "TotalAmountDue"
  },
  PaymentsReceived: {
    name: "Payments received",
    slug: "PaymentsReceived",
    i18n: "PaymentsReceived"
  },
  ReceiptAmount: {
    name: "Receipt amount",
    slug: "ReceiptAmount",
    i18n: "ReceiptAmount"
  },
  TotalCashReceipts: {
    name: "Total cash receipts",
    slug: "TotalCashReceipts",
    i18n: "TotalCashReceipts"
  },
  InvoceBalance: {
    name: "Invoice balance",
    slug: "InvoceBalance",
    i18n: "InvoceBalance"
  },
  Close: {
    name: "Close",
    slug: "Close",
    i18n: "Close"
  },
  NoAvailableData: {
    name: "No available data",
    slug: "NoAvailableData",
    i18n: "NoAvailableData"
  },
  LeftVolumen: {
    name: "Left volumen",
    slug: "LeftVolumen",
    i18n: "LeftVolumen"
  },
  RightVolumen: {
    name: "Left volumen",
    slug: "RightVolumen",
    i18n: "RightVolumen"
  },
  ExportAs: {
    name: "Export as",
    slug: "ExportAs",
    i18n: "ExportAs"
  },
  Logout: {
    name: "Logout",
    slug: "Logout",
    i18n: "Logout"
  },
  LeftBvTravelPoint: {
    name: "Left - Travel points (BV)",
    slug: "LeftBvTravelPoint",
    i18n: "LeftBvTravelPoint"
  },
  RightBvTravelPoint: {
    name: "Right - Travel points (BV)",
    slug: "RightBvTravelPoint",
    i18n: "RightBvTravelPoint"
  },
  Qualifications: {
    name: "Qualifications",
    slug: "Qualifications",
    i18n: "Qualifications"
  },
  Rank: {
    name: "Rank",
    slug: "Rank",
    i18n: "Rank"
  },
  VolumenPersonal: {
    name: "Volumen personal",
    slug: "VolumenPersonal",
    i18n: "VolumenPersonal"
  },
  TotalVolumen: {
    name: "Volumen total",
    slug: "TotalVolumen",
    i18n: "TotalVolumen"
  },
  ViewEnrolledsTotal: {
    name: "View enrollees total",
    slug: "ViewEnrolledsTotal",
    i18n: "ViewEnrolledsTotal"
  },
  CyclesRequired: {
    name: "Cycles required",
    slug: "CyclesRequired",
    i18n: "CyclesRequired"
  },
  CyclesTotal: {
    name: "Cycles total made",
    slug: "CyclesTotal",
    i18n: "CyclesTotal"
  },
  PersonalEnrollees: {
    name: "Personal enrollees",
    slug: "PersonalEnrollees",
    i18n: "PersonalEnrollees"
  },
  Left: {
    name: "Left",
    slug: "Left",
    i18n: "Left"
  },
  Right: {
    name: "Right",
    slug: "Right",
    i18n: "Right"
  },
  SinceWeek: {
    name: "Since week",
    slug: "SinceWeek",
    i18n: "SinceWeek"
  },
  UntilWeek: {
    name: "Until week",
    slug: "UntilWeek",
    i18n: "UntilWeek"
  },
  Select: {
    name: "Select",
    slug: "Select",
    i18n: "Select"
  },
  Search: {
    name: "Search",
    slug: "Search",
    i18n: "Search"
  },
  Summary: {
    name: "Summary",
    slug: "Summary",
    i18n: "Summary"
  },
  EndDate: {
    name: "End date",
    slug: "EndDate",
    i18n: "EndDate"
  },
  CheckDate: {
    name: "Check date",
    slug: "CheckDate",
    i18n: "CheckDate"
  },
  CheckAmount: {
    name: "Check amount",
    slug: "CheckAmount",
    i18n: "CheckAmount"
  },
  EarningsAmount: {
    name: "Earnings amount",
    slug: "EarningsAmount",
    i18n: "EarningsAmount"
  },
  CurrencyCode: {
    name: "Currency code",
    slug: "CurrencyCode",
    i18n: "CurrencyCode"
  },
  TransactionNumber: {
    name: "Transaction number",
    slug: "TransactionNumber",
    i18n: "TransactionNumber"
  },
  EarningsSummaryByPeriod: {
    name: "Earnings summary by period",
    slug: "EarningsSummaryByPeriod",
    i18n: "EarningsSummaryByPeriod"
  },
  Commissions: {
    name: "Commissions",
    slug: "Commissions",
    i18n: "Commissions"
  },
  RetailCommission: {
    name: "Retail commission",
    slug: "RetailCommission",
    i18n: "RetailCommission"
  },
  FastStart: {
    name: "Fast start",
    slug: "FastStart",
    i18n: "FastStart"
  },
  CycleCommission: {
    name: "Cycle commission",
    slug: "CycleCommission",
    i18n: "CycleCommission"
  },
  MatchingBonus: {
    name: "Matching bonus",
    slug: "MatchingBonus",
    i18n: "MatchingBonus"
  },
  BaseBonus: {
    name: "Base bonus",
    slug: "BaseBonus",
    i18n: "BaseBonus"
  },
  LifestyleBonus: {
    name: "Lifestyle bonus",
    slug: "LifestyleBonus",
    i18n: "LifestyleBonus"
  },
  EarningsByCommissionType: {
    name: "Earnings by commission type",
    slug: "EarningsByCommissionType",
    i18n: "EarningsByCommissionType"
  },
  PeriodDetails: {
    name: "PeriodDetails",
    slug: "PeriodDetails",
    i18n: "PeriodDetails"
  },
  DownloadPDF: {
    name: "Download PDF",
    slug: "DownloadPDF",
    i18n: "DownloadPDF"
  },
  Detail: {
    name: "Detail",
    slug: "Detail",
    i18n: "Detail"
  },
  DistributorID: {
    name: "Distributor ID",
    slug: "DistributorID",
    i18n: "DistributorID"
  },
  Name: {
    name: "Name",
    slug: "Name",
    i18n: "Name"
  },
  PaidRank: {
    name: "Paid rank",
    slug: "PaidRank",
    i18n: "PaidRank"
  },
  PinRank: {
    name: "Pin rank",
    slug: "PinRank",
    i18n: "PinRank"
  },
  PercentContributed: {
    name: "Percent contributed",
    slug: "PercentContributed",
    i18n: "PercentContributed"
  },
  AmountContributed: {
    name: "Amount contributed",
    slug: "AmountContributed",
    i18n: "AmountContributed"
  },
  Amount: {
    name: "Amount",
    slug: "Amount",
    i18n: "Amount"
  },
  BinaryTree: {
    name: "Binary tree",
    slug: "BinaryTree",
    i18n: "BinaryTree"
  },
  RequiredEnrolleds: {
    name: "Required enrollees",
    slug: "RequiredEnrollees",
    i18n: "RequiredEnrolleds"
  },
  Level: {
    name: "Level",
    slug: "Level",
    i18n: "Level"
  },
  EndRank: {
    name: "End rank",
    slug: "EndRank",
    i18n: "EndRank"
  },
  Side: {
    name: "Side",
    slug: "Side",
    i18n: "Side"
  },
  Sponsor: {
    name: "Sponsor",
    slug: "Sponsor",
    i18n: "Sponsor"
  },
  FromLevel: {
    name: "From level",
    slug: "FromLevel",
    i18n: "FromLevel"
  },
  UntilLevel: {
    name: "Until level",
    slug: "UntilLevel",
    i18n: "UntilLevel"
  },
  All: {
    name: "All",
    slug: "All",
    i18n: "All"
  },
  Customer: {
    name: "Customer",
    slug: "Customer",
    i18n: "Customer"
  },
  Terminated: {
    name: "Terminated",
    slug: "Terminated",
    i18n: "Terminated"
  },
  Distributor: {
    name: "Distributor",
    slug: "Distributor",
    i18n: "Distributor"
  },
  MemberType: {
    name: "Member type",
    slug: "MemberType",
    i18n: "MemberType"
  },
  Suspended: {
    name: "Suspended",
    slug: "Suspended",
    i18n: "Suspended"
  },
  AmountRows: {
    name: "Amount rows",
    slug: "AmountRows",
    i18n: "AmountRows"
  },
  Status: {
    name: "Status",
    slug: "Status",
    i18n: "Status"
  },
  Associated: {
    name: "Associated",
    slug: "Associated",
    i18n: "Associated"
  },
  Silver: {
    name: "Silver",
    slug: "Silver",
    i18n: "Silver"
  },
  Gold: {
    name: "Gold",
    slug: "Gold",
    i18n: "Gold"
  },
  Platinum: {
    name: "Platinum",
    slug: "Platinum",
    i18n: "Platinum"
  },
  Diamond: {
    name: "Diamond",
    slug: "Diamond",
    i18n: "Diamond"
  },
  DoubleDiamond: {
    name: "Double Diamond",
    slug: "DoubleDiamond",
    i18n: "DoubleDiamond"
  },
  TripleDiamond: {
    name: "Triple Diamond",
    slug: "TripleDiamond",
    i18n: "TripleDiamond"
  },
  Crown: {
    name: "Crown",
    slug: "Crown",
    i18n: "Crown"
  },
  CrownAmbassador: {
    name: "Crown Ambassador",
    slug: "CrownAmbassador",
    i18n: "CrownAmbassador"
  },
  Start: {
    name: "Start",
    slug: "Start",
    i18n: "Start"
  },
  Unclassified: {
    name: "Unclassified",
    slug: "Unclassified",
    i18n: "Unclassified"
  },
  Login: {
    name: "Login",
    slug: "Login",
    i18n: "Login"
  },
  ForgotPassword: {
    name: "Forgot your password?",
    slug: "ForgotPassword",
    i18n: "ForgotPassword"
  },
  Password: {
    name: "Password",
    slug: "Password",
    i18n: "Password"
  },
  RecoverPassword: {
    name: "Recover your password",
    slug: "RecoverPassword",
    i18n: "RecoverPassword"
  },
  Recover: {
    name: "Recover",
    slug: "Recover",
    i18n: "Recover"
  },
  Recapture: {
    name: "Recapture",
    slug: "Recapture",
    i18n: "Recapture"
  },
  ConfirmPassword: {
    name: "Confirm password",
    slug: "ConfirmPassword",
    i18n: "ConfirmPassword"
  },
  VerificationCode: {
    name: "Verification code",
    slug: "VerificationCode",
    i18n: "VerificationCode"
  },
  ResetPassword: {
    name: "Reset password",
    slug: "ResetPassword",
    i18n: "ResetPassword"
  },
  Reset: {
    name: "Reset",
    slug: "Reset",
    i18n: "Reset"
  },
  StatusByPeriod: {
    name: "Status by period",
    slug: "StatusByPeriod",
    i18n: "StatusByPeriod"
  },
  CustomizedCommissions: {
    name: "Customized commissions",
    slug: "CustomizedCommissions",
    i18n: "CustomizedCommissions"
  },
  TipAndCustomizedFee: {
    name: "Tips and customized Fee",
    slug: "TipAndCustomizedFee",
    i18n: "TipAndCustomizedFee"
  },
  FeeTotal: {
    name: "Fee total",
    slug: "FeeTotal",
    i18n: "FeeTotal"
  },
  TipsTotal: {
    name: "Tips total",
    slug: "TipsTotal",
    i18n: "TipsTotal"
  },
  Pay: {
    name: "Pay",
    slug: "Pay",
    i18n: "Pay"
  },
  BookingCode: {
    name: "Booking code",
    slug: "BookingCode",
    i18n: "BookingCode"
  },
  Type: {
    name: "Type",
    slug: "Type",
    i18n: "Type"
  },
  Transfer: {
    name: "Transfer",
    slug: "Transfer",
    i18n: "Transfer"
  },
  Congratulations: {
    name: "Congratulations",
    slug: "Congratulations",
    i18n: "Congratulations"
  },
  Paid: {
    name: "Paid",
    slug: "Paid",
    i18n: "Paid"
  },
  WithoutPay: {
    name: "Without pay",
    slug: "WithoutPay",
    i18n: "WithoutPay"
  },
  TotalTransactions: {
    name: "Total transactions",
    slug: "TotalTransactions",
    i18n: "TotalTransactions"
  },
  Tip: {
    name: "Tip",
    slug: "Tip",
    i18n: "Tip"
  },
  CustomizedFee: {
    name: "Customized fee",
    slug: "CustomizedFee",
    i18n: "CustomizedFee"
  },
  AdjustmentTip: {
    name: "Tip adjustment",
    slug: "AdjustmentTip",
    i18n: "AdjustmentTip"
  },
  AdjustmentCustomizedFee: {
    name: "Customized fee adjustment",
    slug: "AdjustmentCustomizedFee",
    i18n: "AdjustmentCustomizedFee"
  },
  Payment: {
    name: "Payment",
    slug: "Payment",
    i18n: "Payment"
  },
  Settings: {
    name: "Settings",
    slug: "Settings",
    i18n: "Settings"
  },
  Access: {
    name: "Access",
    slug: "Access",
    i18n: "Access"
  },
  Active: {
    name: "Active",
    slug: "Active",
    i18n: "Active"
  },
  Inactive: {
    name: "Inactive",
    slug: "Inactive",
    i18n: "Inactive"
  },
  ExpirationDate: {
    name: "Expiration date",
    slug: "ExpirationDate",
    i18n: "ExpirationDate"
  },
  CreatedBy: {
    name: "Created by",
    slug: "CreatedBy",
    i18n: "CreatedBy"
  },
  Create: {
    name: "Create",
    slug: "Create",
    i18n: "Create"
  },
  TotalPendingCharges: {
    name: "Total pending charges",
    slug: "TotalPendingCharges",
    i18n: "TotalPendingCharges"
  },
  LastName: {
    name: "Last name",
    slug: "LastName",
    i18n: "LastName"
  },
  Historical: {
    name: "Historical",
    slug: "Historical",
    i18n: "Historical"
  },
  CreateDate: {
    name: "Create date",
    slug: "CreateDate",
    i18n: "CreateDate"
  },
  Percent: {
    name: "Percent",
    slug: "Percent",
    i18n: "Percent"
  },
  NewValue: {
    name: "New value",
    slug: "NewValue",
    i18n: "NewValue"
  },
  OldValue: {
    name: "Old value",
    slug: "OldValue",
    i18n: "OldValue"
  },
  PaymentDate: {
    name: "Payment date",
    slug: "PaymentDate",
    i18n: "PaymentDate"
  },
  UpdateEmail: {
    name: "Update email",
    slug: "UpdateEmail",
    i18n: "UpdateEmail"
  },
  Save: {
    name: "Save",
    slug: "Save",
    i18n: "Save"
  },
  ThemeCustomized: {
    name: "Theme customized",
    slug: "ThemeCustomized",
    i18n: "ThemeCustomized"
  },
  LayoutType: {
    name: "Layout type",
    slug: "LayoutType",
    i18n: "LayoutType"
  },
  Vertical: {
    name: "Vertical",
    slug: "Vertical",
    i18n: "Vertical"
  },
  Horizontal: {
    name: "Horizontal",
    slug: "Horizontal",
    i18n: "Horizontal"
  },
  Color: {
    name: "Color",
    slug: "Color",
    i18n: "Color"
  },
  Mode: {
    name: "Mode",
    slug: "Mode",
    i18n: "Mode"
  },
  Light: {
    name: "Light",
    slug: "Light",
    i18n: "Light"
  },
  Dark: {
    name: "Dark",
    slug: "Dark",
    i18n: "Dark"
  },
  SemiDark: {
    name: "Semi-dark",
    slug: "SemiDark",
    i18n: "SemiDark"
  },
  CollapseSidebar: {
    name: "Collapse Sidebar",
    slug: "CollapseSidebar",
    i18n: "CollapseSidebar"
  },
  NavbarColor: {
    name: "Navbar color",
    slug: "NavbarColor",
    i18n: "NavbarColor"
  },
  NavbarType: {
    name: "Navbar type",
    slug: "NavbarType",
    i18n: "NavbarType"
  },
  FooterType: {
    name: "Footer type",
    slug: "FooterType",
    i18n: "FooterType"
  },
  RightToLeft: {
    name: "Derecha a la izquierda",
    slug: "RightToLeft",
    i18n: "RightToLeft"
  },
  Animation: {
    name: "Animation",
    slug: "Animation",
    i18n: "Animation"
  },
  Hidden: {
    name: "Hidden",
    slug: "Hidden",
    i18n: "Hidden"
  },
  Static: {
    name: "Static",
    slug: "Static",
    i18n: "Static"
  },
  Sticky: {
    name: "Sticky",
    slug: "Sticky",
    i18n: "Sticky"
  },
  Floating: {
    name: "Floating",
    slug: "Floating",
    i18n: "Floating"
  },
  Bookings: {
    name: "Bookings",
    slug: "Bookings",
    i18n: "Bookings"
  },
  BookingDetail: {
    name: "BookingDetail",
    slug: "BookingDetail",
    i18n: "BookingDetail"
  },
  AccountNumber: {
    name: "AccountNumber",
    slug: "AccountNumber",
    i18n: "AccountNumber"
  },
  ContactName: {
    name: "ContactName",
    slug: "ContactName",
    i18n: "ContactName"
  },
  Filters: {
    name: "Filters",
    slug: "Filters",
    i18n: "Filters"
  },
  Gds: {
    name: "Gds",
    slug: "Gds",
    i18n: "Gds"
  },
  Managed: {
    name: "Managed",
    slug: "Managed",
    i18n: "Managed"
  },
  TicketNumber: {
    name: "Ticket number",
    slug: "TicketNumber",
    i18n: "TicketNumber"
  },
  Source: {
    name: "Source",
    slug: "Source",
    i18n: "Source"
  },
  WayToPay: {
    name: "WayToPay",
    slug: "WayToPay",
    i18n: "WayToPay"
  },
  NetValue: {
    name: "NetValue",
    slug: "NetValue",
    i18n: "NetValue"
  },
  TaxesValue: {
    name: "TaxesValue",
    slug: "TaxesValue",
    i18n: "TaxesValue"
  },
  FeeValue: {
    name: "FeeValue",
    slug: "FeeValue",
    i18n: "FeeValue"
  },
  FeeCustomized: {
    name: "FeeCustomized",
    slug: "FeeCustomized",
    i18n: "FeeCustomized"
  },
  MerchantValue: {
    name: "MerchantValue",
    slug: "MerchantValue",
    i18n: "MerchantValue"
  },
  DiscountValue: {
    name: "DiscountValue",
    slug: "DiscountValue",
    i18n: "DiscountValue"
  },
  TotalValue: {
    name: "TotalValue",
    slug: "TotalValue",
    i18n: "TotalValue"
  },
  OtherValue: {
    name: "OtherValue",
    slug: "OtherValue",
    i18n: "OtherValue"
  },
  ProductType: {
    name: "ProductType",
    slug: "ProductType",
    i18n: "ProductType"
  },
  Origin: {
    name: "Origin",
    slug: "Origin",
    i18n: "Origin"
  },
  Destination: {
    name: "Destination",
    slug: "Destination",
    i18n: "Destination"
  },
  SupplierName: {
    name: "SupplierName",
    slug: "SupplierName",
    i18n: "SupplierName"
  },
  Itinerary: {
    name: "Itinerary",
    slug: "Itinerary",
    i18n: "Itinerary"
  },
  Passengers: {
    name: "Passengers",
    slug: "Passengers",
    i18n: "Passengers"
  },
  PassengerType: {
    name: "PassengerType",
    slug: "PassengerType",
    i18n: "PassengerType"
  },
  DocumentType: {
    name: "DocumentType",
    slug: "DocumentType",
    i18n: "DocumentType"
  },
  Telephone: {
    name: "Telephone",
    slug: "Telephone",
    i18n: "Telephone"
  },
  FareValue: {
    name: "FareValue",
    slug: "FareValue",
    i18n: "FareValue"
  },
  Merchant: {
    name: "Merchant",
    slug: "Merchant",
    i18n: "Merchant"
  },
  Value: {
    name: "Value",
    slug: "Value",
    i18n: "Value"
  },
  Country: {
    name: "Country",
    slug: "Country",
    i18n: "Country"
  },
  PaymentMethod: {
    name: "Payment method",
    slug: "PaymentMethod",
    i18n: "PaymentMethod"
  },
  Stage: {
    name: "Stage",
    slug: "Stage",
    i18n: "Stage"
  },
  Yes: {
    name: "Yes",
    slug: "Yes",
    i18n: "Yes"
  },
  No: {
    name: "No",
    slug: "No",
    i18n: "No"
  },
  StartDate: {
    name: "Start date",
    slug: "StartDate",
    i18n: "StartDate"
  },
  Commercial: {
    name: "Commercial",
    slug: "Commercial",
    i18n: "Commercial"
  },
  RiskAnalysis: {
    name: "Risk analysis",
    slug: "RiskAnalysis",
    i18n: "RiskAnalysis"
  },
  PaymentStage: {
    name: "Payment stage",
    slug: "PaymentStage",
    i18n: "PaymentStage"
  },
  Issue: {
    name: "Issue",
    slug: "Issue",
    i18n: "Issue"
  },
  Billing: {
    name: "Billing",
    slug: "Billing",
    i18n: "Billing"
  },
  Commission: {
    name: "Commission",
    slug: "Commission",
    i18n: "Commission"
  },
  Returns: {
    name: "Returns",
    slug: "Returns",
    i18n: "Returns"
  },
  Cancellations: {
    name: "Cancellations",
    slug: "Cancellations",
    i18n: "Cancellations"
  },
  Changes: {
    name: "Changes",
    slug: "Changes",
    i18n: "Changes"
  },
  ChargeSuppliers: {
    name: "ChargeSuppliers",
    slug: "ChargeSuppliers",
    i18n: "ChargeSuppliers"
  },
  Fraud: {
    name: "Fraud",
    slug: "Fraud",
    i18n: "Fraud"
  },
  Clean: {
    name: "Clean",
    slug: "Clean",
    i18n: "Clean"
  },
  Documents: {
    name: "Documents",
    slug: "Documents",
    i18n: "Documents"
  },
  Traking: {
    name: "Traking",
    slug: "Traking",
    i18n: "Traking"
  },
  Users: {
    name: "Users",
    slug: "Users",
    i18n: "Users"
  },
  Delete: {
    name: "Delete",
    slug: "Delete",
    i18n: "Delete"
  },
  Edit: {
    name: "Edit",
    slug: "Edit",
    i18n: "Edit"
  },
  Print: {
    name: "Print",
    slug: "Print",
    i18n: "Print"
  },
  Actions: {
    name: "Actions",
    slug: "Actions",
    i18n: "Actions"
  },
  Subject: {
    name: "Subject",
    slug: "Subject",
    i18n: "Subject"
  },
  GeneratePayments: {
    name: "Generate payments",
    slug: "GeneratePayments",
    i18n: "GeneratePayments"
  },
  PaymentInformation: {
    name: "Payment information",
    slug: "PaymentInformation",
    i18n: "PaymentInformation"
  },
  MethodOfPayment: {
    name: "Method of payment",
    slug: "MethodOfPayment",
    i18n: "MethodOfPayment"
  },
  CardType: {
    name: "Card type",
    slug: "CardType",
    i18n: "CardType"
  },
  CardOwner: {
    name: "Card owner",
    slug: "CardOwner",
    i18n: "CardOwner"
  },
  CardNumber: {
    name: "Card number",
    slug: "CardNumber",
    i18n: "CardNumber"
  },
  ExpirationMonth: {
    name: "Expiration mounth",
    slug: "ExpirationMonth",
    i18n: "ExpirationMonth"
  },
  ExpirationYear: {
    name: "Expiration year",
    slug: "ExpirationYear",
    i18n: "ExpirationYear"
  },
  SecurityCode: {
    name: "Security code",
    slug: "SecurityCode",
    i18n: "SecurityCode"
  },
  ZipCode: {
    name: "Zip code",
    slug: "ZipCode",
    i18n: "ZipCode"
  },
  BillingData: {
    name: "Billing data",
    slug: "BillingData",
    i18n: "BillingData"
  },
  Recovery: {
    name: "Recovery",
    slug: "Recovery",
    i18n: "Recovery"
  },
  CreditCard: {
    name: "Credit card",
    slug: "CreditCard",
    i18n: "CreditCard"
  },
  TotalToPay: {
    name: "Total to pay",
    slug: "TotalToPay",
    i18n: "TotalToPay"
  },
  Cards: {
    name: "Cards",
    slug: "Cards",
    i18n: "Cards"
  },
  Charge: {
    name: "Charge",
    slug: "Charge",
    i18n: "Charge"
  },
  Send: {
    name: "Send",
    slug: "Send",
    i18n: "Send"
  },
  PayYourReservation: {
    name: "Pay your reservation",
    slug: "PayYourReservation",
    i18n: "PayYourReservation"
  },
  CountryCode: {
    name: "Country code",
    slug: "CountryCode",
    i18n: "CountryCode"
  },
  Currency: {
    name: "Currency",
    slug: "Currency",
    i18n: "Currency"
  },
  AddPassenger: {
    name: "AddPassenger",
    slug: "AddPassenger",
    i18n: "AddPassenger"
  },
  ExpirationDatePassport: {
    name: "ExpirationDatePassport",
    slug: "ExpirationDatePassport",
    i18n: "ExpirationDatePassport"
  },
  SpecificationName: {
    name: "SpecificationName",
    slug: "SpecificationName",
    i18n: "SpecificationName"
  },
  SpecificationValue: {
    name: "SpecificationValue",
    slug: "SpecificationValue",
    i18n: "SpecificationValue"
  },
  AddItinerary: {
    name: "AddItinerary",
    slug: "AddItinerary",
    i18n: "AddItinerary"
  },
  MyDistributor: {
    name: "My distributor",
    slug: "MyDistributor",
    i18n: "MyDistributor"
  },
  Previousstage: {
    name: "Previousstage",
    slug: "Previousstage",
    i18n: "Previousstage"
  },
  Currentstage: {
    name: "Currentstage",
    slug: "Currentstage",
    i18n: "Currentstage"
  },
  Responsable: {
    name: "Responsable",
    slug: "Responsable",
    i18n: "Responsable"
  },
  State: {
    name: "State",
    slug: "State",
    i18n: "State"
  },
  FirstName: {
    name: "First name",
    slug: "FirstName",
    i18n: "FirstName"
  },
  Body: {
    name: "Body",
    slug: "Body",
    i18n: "Body"
  },
  Sent: {
    name: "Sent",
    slug: "Sent",
    i18n: "Sent"
  },
  NewMessage: {
    name: "New message",
    slug: "NewMessage",
    i18n: "NewMessage"
  },
  To: {
    name: "To",
    slug: "To",
    i18n: "To"
  },
  From: {
    name: "From",
    slug: "From",
    i18n: "From"
  },
  Attachments: {
    name: "Attachments",
    slug: "Attachments",
    i18n: "Attachments"
  },
  SelectTemplate: {
    name: "Select template",
    slug: "SelectTemplate",
    i18n: "SelectTemplate"
  },
  Tracking: {
    name: "Tracking",
    slug: "Tracking",
    i18n: "Tracking"
  },
  Ita: {
    name: "Ita",
    slug: "Ita",
    i18n: "Ita"
  },
  Remark: {
    name: "Remark",
    slug: "Remark",
    i18n: "Remark"
  },
  Results: {
    name: "Results",
    slug: "Results",
    i18n: "Results"
  },
  GeneralInformation: {
    name: "General information",
    slug: "GeneralInformation",
    i18n: "GeneralInformation"
  },
  Contact: {
    name: "Contact",
    slug: "Contact",
    i18n: "Contact"
  },
  ReservationType: {
    name: "Type of reservation",
    slug: "ReservationType",
    i18n: "ReservationType"
  },
  TotalTaxes: {
    name: "TotalTaxes",
    slug: "TotalTaxes",
    i18n: "TotalTaxes"
  },
  NetTotal: {
    name: "Net total",
    slug: "NetTotal",
    i18n: "NetTotal"
  },
  BookingByReference: {
    name: "Booking by reference",
    slug: "BookingByReference",
    i18n: "BookingByReference"
  },
  Upgrade: {
    name: "Upgrade",
    slug: "Upgrade",
    i18n: "Upgrade"
  },
  Membership: {
    name: "Membership",
    slug: "Membership",
    i18n: "Membership"
  },
  MonthlyPayment: {
    name: "Monthly payment",
    slug: "MonthlyPayment",
    i18n: "MonthlyPayment"
  },
  AutomaticMonthlyPayment: {
    name: "Automatic monthly payment",
    slug: "AutomaticMonthlyPayment",
    i18n: "AutomaticMonthlyPayment"
  },
  Buy: {
    name: "Buy",
    slug: "Buy",
    i18n: "Buy"
  },
  Purchases: {
    name: "Purchases",
    slug: "Purchases",
    i18n: "Purchases"
  },
  DepartureDate: {
    name: "Departure date",
    slug: "DepartureDate",
    i18n: "DepartureDate"
  },
  ArrivalDate: {
    name: "Arrival date",
    slug: "ArrivalDate",
    i18n: "ArrivalDate"
  },
  Class: {
    name: "Class",
    slug: "Class",
    i18n: "Class"
  },
  Airline: {
    name: "Airline",
    slug: "Airline",
    i18n: "Airline"
  },
  Airlines: {
    name: "Airlines",
    slug: "Airlines",
    i18n: "Airlines"
  },
  FlightNumber: {
    name: "Flight number",
    slug: "FlightNumber",
    i18n: "FlightNumber"
  },
  Passenger: {
    name: "Passenger",
    slug: "Passenger",
    i18n: "Passenger"
  },
  IsPayed: {
    name: "IsPayed",
    slug: "IsPayed",
    i18n: "IsPayed"
  },
  AddPayment: {
    name: "AddPayment",
    slug: "AddPayment",
    i18n: "AddPayment"
  },
  NumberTransaction: {
    name: "Number Transaction",
    slug: "NumberTransaction",
    i18n: "NumberTransaction"
  },
  BankName: {
    name: "Bank Name",
    slug: "BankName",
    i18n: "BankName"
  },
  AuthorizationNumber: {
    name: "Authorization Number",
    slug: "AuthorizationNumber",
    i18n: "AuthorizationNumber"
  },
  CreditCardNumber: {
    name: "CreditCard Number",
    slug: "CreditCardNumber",
    i18n: "CreditCardNumber"
  },
  CreditCardType: {
    name: "CreditCard Type",
    slug: "CreditCardType",
    i18n: "CreditCardType"
  },
  CreditCardOwner: {
    name: "CreditCard Owner",
    slug: "CreditCardOwner",
    i18n: "CreditCardOwner"
  },
  DuesNumber: {
    name: "Dues Number",
    slug: "DuesNumber",
    i18n: "DuesNumber"
  },
  CardholderLastName: {
    name: "Cardholder LastName",
    slug: "CardholderLastName",
    i18n: "CardholderLastName"
  },
  WriteOrigin: {
    name: "Write a origin",
    slug: "WriteOrigin",
    i18n: "WriteOrigin"
  },
  WriteDestination: {
    name: "Write a destination",
    slug: "WriteDestination",
    i18n: "WriteDestination"
  },
  LenderServices: {
    name: "Lender services",
    slug: "LenderServices",
    i18n: "LenderServices"
  },
  Supplier: {
    name: "Supplier",
    slug: "Supplier",
    i18n: "Supplier"
  },
  FlightClass: {
    name: "Flight class",
    slug: "FlightClass",
    i18n: "FlightClass"
  },
  Update: {
    name: "Update",
    slug: "Update",
    i18n: "Update"
  },
  RemarkPublish: {
    name: "Remark Publish",
    slug: "RemarkPublish",
    i18n: "RemarkPublish"
  },
  Cancel: {
    name: "Cancel",
    slug: "Cancel",
    i18n: "Cancel"
  },
  Booking: {
    name: "Booking",
    slug: "Booking",
    i18n: "Booking"
  },
  OpenDocument: {
    name: "Open document",
    slug: "OpenDocument",
    i18n: "OpenDocument"
  },
  Generate: {
    name: "Generate",
    slug: "Generate",
    i18n: "Generate"
  },
  StartManagement: {
    name: "Start Management",
    slug: "StartManagement",
    i18n: "StartManagement"
  },
  Notifications: {
    name: "Notifications",
    slug: "Notifications",
    i18n: "Notifications"
  },
  Blog: {
    name: "Blog",
    slug: "Blog",
    i18n: "Blog"
  },
  Language: {
    name: "Language",
    slug: "Language",
    i18n: "Language"
  },
  Title: {
    name: "Title",
    slug: "Title",
    i18n: "Title"
  },
  CreationDate: {
    name: "Creation date",
    slug: "CreationDate",
    i18n: "CreationDate"
  },
  List: {
    name: "List",
    slug: "List",
    i18n: "List"
  },
  Preview: {
    name: "Preview",
    slug: "Preview",
    i18n: "Preview"
  },
  HasAttachment: {
    name: "Has attachment",
    slug: "HasAttachment",
    i18n: "HasAttachment"
  },
  Transactions: {
    name: "Transactions",
    slug: "Transactions",
    i18n: "Transactions"
  },
  GroupedByTransaction: {
    name: "Grouped by transaction",
    slug: "GroupedByTransaction",
    i18n: "GroupedByTransaction"
  },
  Product: {
    name: "Product",
    slug: "Product",
    i18n: "Product"
  },
  IsFinanced: {
    name: "Is financed",
    slug: "IsFinanced",
    i18n: "IsFinanced"
  },
  Attachment: {
    name: "Attachment",
    slug: "Attachment",
    i18n: "Attachment"
  },
  Installments: {
    name: "Installments",
    slug: "Installments",
    i18n: "Installments"
  },
  FinanceProduct: {
    name: "Finance product",
    slug: "FinanceProduct",
    i18n: "FinanceProduct"
  },
  VerifyDate: {
    name: "Verify date",
    slug: "VerifyDate",
    i18n: "VerifyDate"
  },
  IncludeNeo: {
    name: "Do you want to buy Neo application?",
    slug: "IncludeNeo",
    i18n: "IncludeNeo"
  },
  ResidenceCountry: {
    name: "Residence country",
    slug: "ResidenceCountry",
    i18n: "ResidenceCountry"
  },
  ResidenceState: {
    name: "Residence state",
    slug: "ResidenceState",
    i18n: "ResidenceState"
  },
  Enroller: {
    name: "Enroller",
    slug: "Enroller",
    i18n: "Enroller"
  },
  Presenter: {
    name: "Presenter",
    slug: "Presenter",
    i18n: "Presenter"
  },
  AllCloser: {
    name: "All closer",
    slug: "AllCloser",
    i18n: "AllCloser"
  },
  InstallmentPay: {
    name: "Your installment is by only",
    slug: "InstallmentPay",
    i18n: "InstallmentPay"
  },
  BeingMember: {
    name: "Being member!",
    slug: "BeingMember",
    i18n: "BeingMember"
  },
  Closer: {
    name: "Closer",
    slug: "Closer",
    i18n: "Closer"
  },
  BeneficiaryName: {
    name: "Beneficiary Name",
    slug: "BeneficiaryName",
    i18n: "BeneficiaryName"
  },
  BeneficiaryLastName: {
    name: "Beneficiary last name",
    slug: "BeneficiaryLastName",
    i18n: "BeneficiaryLastName"
  },
  MembershipType: {
    name: "Membership Type",
    slug: "MembershipType",
    i18n: "MembershipType"
  },
  Bill: {
    name: "Bill",
    slug: "Bill",
    i18n: "Bill"
  },

  InstallmentNumber: {
    name: "installment Number",
    slug: "InstallmentNumber",
    i18n: "InstallmentNumber"
  },
  Resources: {
    name: "Resources",
    slug: "Resources",
    i18n: "Resources"
  },
  Downloads: {
    name: "Downloads",
    slug: "Downloads",
    i18n: "Downloads"
  },
  EWallet: {
    name: "EWallet",
    slug: "EWallet",
    i18n: "EWallet"
  },
  News: {
    name: "News",
    slug: "News",
    i18n: "News"
  },
  ReferenceCode: {
    name: "Reference code",
    slug: "ReferenceCode",
    i18n: "ReferenceCode"
  },

  FinancialCharge: {
    name: "Financial charge",
    slug: "FinancialCharge",
    i18n: "FinancialCharge"
  },
  BVPoints: {
    name: "BV Points",
    slug: "BVPoints",
    i18n: "BVPoints"
  },
  WeekNumber: {
    name: "Week number",
    slug: "WeekNumber",
    i18n: "WeekNumber"
  },
  PreviousStage: {
    name: "Previous stage",
    slug: "PreviousStage",
    i18n: "PreviousStage"
  },
  CurrentStage: {
    name: "Current stage",
    slug: "CurrentStage",
    i18n: "CurrentStage"
  },
  Customers: {
    name: "Customers",
    slug: "Customers",
    i18n: "Customers"
  },
  TopTravelAgents: {
    name: "Top travel agents",
    slug: "TopTravelAgents",
    i18n: "TopTravelAgents"
  },
  LatestBookings: {
    name: "Latest bookings",
    slug: "LatestBookings",
    i18n: "LatestBookings"
  },
  Sales: {
    name: "Sales",
    slug: "Sales",
    i18n: "Sales"
  },
  Year: {
    name: "Year",
    slug: "Year",
    i18n: "Year"
  },
  Month: {
    name: "Month",
    slug: "Month",
    i18n: "Month"
  },
  MoreInformation: {
    name: "More information",
    slug: "MoreInformation",
    i18n: "MoreInformation"
  },
  FeeNote: {
    name: "FeeNote",
    slug: "FeeNote",
    i18n: "FeeNote"
  },
  CardRegistered: {
    name: "CardRegistered",
    slug: "CardRegistered",
    i18n: "CardRegistered"
  },
  CardNote: {
    name: "CardNote",
    slug: "CardNote",
    i18n: "CardNote"
  },
  NewCard: {
    name: "NewCard",
    slug: "NewCard",
    i18n: "NewCard"
  },
  EmailIncorrect: {
    name: "EmailIncorrect",
    slug: "EmailIncorrect",
    i18n: "EmailIncorrect"
  },
  Coupons: {
    name: "Coupons",
    slug: "Coupons",
    i18n: "Coupons"
  },
  UserName: {
    name: "User name",
    slug: "UserName",
    i18n: "UserName"
  },
  TermsConditionsTitle: {
    name: "TermsConditionsTitle",
    slug: "TermsConditionsTitle",
    i18n: "TermsConditionsTitle"
  },
  TermsConditionsContent1: {
    name: "TermsConditionsContent1",
    slug: "TermsConditionsContent1",
    i18n: "TermsConditionsContent1"
  },
  TermsConditionsContent2: {
    name: "TermsConditionsContent2",
    slug: "TermsConditionsContent2",
    i18n: "TermsConditionsContent2"
  },
  TermsConditionsContent3: {
    name: "TermsConditionsContent3",
    slug: "TermsConditionsContent3",
    i18n: "TermsConditionsContent3"
  },
  TermsConditionsContent4: {
    name: "TermsConditionsContent4",
    slug: "TermsConditionsContent4",
    i18n: "TermsConditionsContent4"
  },
  TermsConditionsContent5: {
    name: "TermsConditionsContent5",
    slug: "TermsConditionsContent5",
    i18n: "TermsConditionsContent5"
  },
  TermsConditionsContent6: {
    name: "TermsConditionsContent6",
    slug: "TermsConditionsContent6",
    i18n: "TermsConditionsContent6"
  },
  TermsConditionsContent7: {
    name: "TermsConditionsContent7",
    slug: "TermsConditionsContent7",
    i18n: "TermsConditionsContent7"
  },
  TermsConditionsContent8: {
    name: "TermsConditionsContent8",
    slug: "TermsConditionsContent8",
    i18n: "TermsConditionsContent8"
  },
  TermsConditionsContent9: {
    name: "TermsConditionsContent9",
    slug: "TermsConditionsContent9",
    i18n: "TermsConditionsContent9"
  },
  TermsConditionsContent10: {
    name: "TermsConditionsContent10",
    slug: "TermsConditionsContent10",
    i18n: "TermsConditionsContent10"
  },
  TermsConditionsContent11: {
    name: "TermsConditionsContent11",
    slug: "TermsConditionsContent11",
    i18n: "TermsConditionsContent11"
  },
  TermsConditionsContent12: {
    name: "TermsConditionsContent12",
    slug: "TermsConditionsContent12",
    i18n: "TermsConditionsContent12"
  },
  TermsConditionsContent13: {
    name: "TermsConditionsContent13",
    slug: "TermsConditionsContent13",
    i18n: "TermsConditionsContent13"
  },
  TermsConditionsContent14: {
    name: "TermsConditionsContent14",
    slug: "TermsConditionsContent14",
    i18n: "TermsConditionsContent14"
  },
  TermsConditionsContent15: {
    name: "TermsConditionsContent15",
    slug: "TermsConditionsContent15",
    i18n: "TermsConditionsContent15"
  },
  Accept: {
    name: "Accept",
    slug: "Accept",
    i18n: "Accept"
  },
  Welcome: {
    name: "Welcome",
    slug: "Welcome",
    i18n: "Welcome"
  },
  Genealogy: {
    name: "Genealogy",
    slug: "Genealogy",
    i18n: "Genealogy"
  },
  SentPresentations: {
    name: "Sent presentations",
    slug: "SentPresentations",
    i18n: "SentPresentations"
  },
  OpenedPresentations: {
    name: "Opened presentations",
    slug: "OpenedPresentations",
    i18n: "OpenedPresentations"
  },
  NewMembers: {
    name: "New members",
    slug: "NewMembers",
    i18n: "NewMembers"
  },
  Phone: {
    name: "Phone",
    slug: "Phone",
    i18n: "Phone"
  },
  Indicative: {
    name: "Indicative",
    slug: "Indicative",
    i18n: "Indicative"
  },
  Management: {
    name: "Management",
    slug: "Management",
    i18n: "Management"
  },
  SendContract: {
    name: "Send contract",
    slug: "SendContract",
    i18n: "SendContract"
  },
  Contracts: {
    name: "Contracts",
    slug: "Contracts",
    i18n: "Contracts"
  },
  SendIOU: {
    name: "Send IOU",
    slug: "SendIOU",
    i18n: "SendIOU"
  },
  SendWelcome: {
    name: "Send welcome",
    slug: "SendWelcome",
    i18n: "SendWelcome"
  },
  NewMember: {
    name: "New member",
    slug: "NewMember",
    i18n: "NewMember"
  },
  CreateEwallet: {
    name: "Create E-wallet",
    slug: "CreateEwallet",
    i18n: "CreateEwallet"
  },
  BirthDay: {
    name: "Birth day",
    slug: "BirthDay",
    i18n: "BirthDay"
  },
  BirthMonth: {
    name: "Birth month",
    slug: "BirthMonth",
    i18n: "BirthMonth"
  },
  BirthYear: {
    name: "Birth year",
    slug: "BirthYear",
    i18n: "BirthYear"
  },
  Hello: {
    name: "Hello",
    slug: "Hello",
    i18n: "Hello"
  },
  MembershipJoin: {
    name:
      "You have registrated a new member to our community. His/her distributor ID is:",
    slug: "MembershipJoin",
    i18n: "MembershipJoin"
  },
  OrderGenerated: {
    name: "Also, we were generated an invoce with order number:",
    slug: "OrderGenerated",
    i18n: "OrderGenerated"
  },
  GenerateMembership: {
    name: "Generate membership",
    slug: "GenerateMembership",
    i18n: "GenerateMembership"
  },
  BookingHandmade: {
    name: "Booking Handmade",
    slug: "BookingHandmade",
    i18n: "BookingHandmade"
  },
  ItaCode: {
    name: "ItaCode",
    slug: "ItaCode",
    i18n: "ItaCode"
  },
  ContactFormTitle: {
    name: "ContactFormTitle",
    slug: "ContactFormTitle",
    i18n: "ContactFormTitle"
  },
  TelephoneType: {
    name: "TelephoneType",
    slug: "TelephoneType",
    i18n: "TelephoneType"
  },
  TelephoneNumber: {
    name: "Telephone Number",
    slug: "TelephoneNumber",
    i18n: "TelephoneNumber"
  },
  Specifications: {
    name: "Specifications",
    slug: "Specifications",
    i18n: "Specifications"
  },
  Specification: {
    name: "Specification",
    slug: "Specification",
    i18n: "Specification"
  },
  Add: {
    name: "Add",
    slug: "Add",
    i18n: "Add"
  },
  AdditionalInformation: {
    name: "Additional information",
    slug: "AdditionalInformation",
    i18n: "AdditionalInformation"
  },
  InsuranceName: {
    name: "Insurance name",
    slug: "InsuranceName",
    i18n: "InsuranceName"
  },
  ActivityName: {
    name: "Activity name",
    slug: "ActivityName",
    i18n: "ActivityName"
  },
  RentalName: {
    name: "Rental name",
    slug: "RentalName",
    i18n: "RentalName"
  },
  SupplierRentalCode: {
    name: "Supplier rental code",
    slug: "SupplierRentalCode",
    i18n: "SupplierRentalCode"
  },
  Affiliations: {
    name: "Affiliations",
    slug: "Affiliations",
    i18n: "Affiliations"
  },
  Accounting: {
    name: "Accounting",
    slug: "Accounting",
    i18n: "Accounting"
  },
  CashRegister: {
    name: "Cash register",
    slug: "CashRegister",
    i18n: "CashRegister"
  },
  Nickname: {
    name: "Nickname",
    slug: "Nickname",
    i18n: "Nickname"
  },
  IsFavorite: {
    name: "Is favorite",
    slug: "IsFavorite",
    i18n: "IsFavorite"
  },
  ItsRegistred: {
    name: "It's registred",
    slug: "ItsRegistred",
    i18n: "ItsRegistred"
  },
  ChangeDate: {
    name: "Change date",
    slug: "ChangeDate",
    i18n: "ChangeDate"
  },
  UpdatedBy: {
    name: "Updated by",
    slug: "UpdatedBy",
    i18n: "UpdatedBy"
  },
  MembershipCountry: {
    name: "Membership country",
    slug: "MembershipCountry",
    i18n: "MembershipCountry"
  },
  Generated: {
    name: "Generated",
    slug: "Generated",
    i18n: "Generated"
  },
  Help: {
    name: "Help",
    slug: "Help",
    i18n: "Help"
  },
  UpdateSignatures: {
    name: "Update signatures",
    slug: "UpdateSignatures",
    i18n: "UpdateSignatures"
  },
  Signature: {
    name: "Signature",
    slug: "Signature",
    i18n: "Signature"
  },
  Answer: {
    name: "Answer",
    slug: "Answer",
    i18n: "Answer"
  },
  AccountType: {
    name: "Account type",
    slug: "AccountType",
    i18n: "AccountType"
  },
  TinaCode: {
    name: "Tina Code",
    slug: "TinaCode",
    i18n: "TinaCode"
  },
  OtherProducts: {
    name: "Other products",
    slug: "OtherProducts",
    i18n: "OtherProducts"
  },
  Tourism: {
    name: "Tourism",
    slug: "Tourism",
    i18n: "Tourism"
  },
  PackagesHelp: {
    name: "Packages help",
    slug: "PackagesHelp",
    i18n: "PackagesHelp"
  },
  Get: {
    name: "Get",
    slug: "Get",
    i18n: "Get"
  },
  ExchangeRate: {
    name: "Exchange rate",
    slug: "ExchangeRate",
    i18n: "ExchangeRate"
  },
  MailSetting: {
    name: "Mail Setting",
    slug: "MailSetting",
    i18n: "MailSetting"
  },
  Identifier: {
    name: "Identifier",
    slug: "Identifier",
    i18n: "Identifier"
  },
  Parameters: {
    name: "Parameters",
    slug: "Parameters",
    i18n: "Parameters"
  },
  Products: {
    name: "Products",
    slug: "Products",
    i18n: "Products"
  },
  ContactTypeName: {
    name: "ContactTypeName",
    slug: "ContactTypeName",
    i18n: "ContactTypeName"
  },
  Benefit: {
    name: "Benefit",
    slug: "Benefit",
    i18n: "Benefit"
  },
  PriceLevel: {
    name: "Price Level",
    slug: "PriceLevel",
    i18n: "PriceLevel"
  },
  IsCompany: {
    name: "It's a company",
    slug: "IsCompany",
    i18n: "IsCompany"
  },
  Promotion: {
    name: "Promotion",
    slug: "Promotion",
    i18n: "Promotion"
  },
  IsRealtor: {
    name: "It's rRealtor",
    slug: "IsRealtor",
    i18n: "IsRealtor"
  },
  AccertifyId: {
    name: "Accertify ID",
    slug: "AccertifyId",
    i18n: "AccertifyId"
  },
  Accertify: {
    name: "Accertify",
    slug: "Accertify",
    i18n: "Accertify"
  },
  AccertifyPoints: {
    name: "Accertify points",
    slug: "AccertifyPoints",
    i18n: "AccertifyPoints"
  },
  DetailPrices: {
    name: "Detail precies",
    slug: "DetailPrices",
    i18n: "DetailPrices"
  },
  MsgCustomizedFee: {
    name: "IBO has configured {0}{1} of fee customized to this product.",
    slug: "MsgCustomizedFee",
    i18n: "MsgCustomizedFee"
  },
  SupplierDestination: {
    name: "Supplier Destination",
    slug: "SupplierDestination",
    i18n: "SupplierDestination"
  },
  TinaHelp: {
    name: "Tina Help",
    slug: "TinaHelp",
    i18n: "TinaHelp"
  },
  Packages: {
    name: "Packages",
    slug: "Packages",
    i18n: "Packages"
  },
  City: {
    name: "City",
    slug: "City",
    i18n: "City"
  },
  BookingValue: {
    name: "Booking value",
    slug: "BookingValue",
    i18n: "BookingValue"
  },
  AirlineCommission:
  {
    name: "Airline commission",
    slug: "AirlineCommission",
    i18n: "AirlineCommission"
  },
  Hotels: {
    name: "Hotels",
    slug: "Hotels",
    i18n: "Hotels"
  },
  IBO:
  {
    name: "Independent Business Owner (IBO)",
    slug: "IBO",
    i18n: "IBO"
  },
  Responsible: {
    name: "Responsible",
    slug: "Responsible",
    i18n: "Responsible"
  },
  ChangeResponsible: {
    name: "Would you like to change the booking responsible?",
    slug: "ChangeResponsible",
    i18n: "ChangeResponsible"
  },
  Category: {
    name: "Category",
    slug: "Category",
    i18n: "Category"
  },
  Accommodation: {
    name: "Accommodation",
    slug: "Accommodation",
    i18n: "Accommodation"
  },
  WriteName: {
    name: "Write a name",
    slug: "WriteName",
    i18n: "WriteName"
  },
  Longitude: {
    name: "Longitude",
    slug: "Longitude",
    i18n: "Longitude"
  },
  Latitude: {
    name: "Latitude",
    slug: "Latitude",
    i18n: "Latitude"
  },
  StarNumber: {
    name: "Star number",
    slug: "StarNumber",
    i18n: "StarNumber"
  },
  Outstanding: {
    name: "Outstanding",
    slug: "Outstanding",
    i18n: "Outstanding"
  },
  Disabled: {
    name: "Disabled",
    slug: "Disabled",
    i18n: "Disabled"
  },
  DescriptionES: {
    name: "DescriptionES",
    slug: "DescriptionES",
    i18n: "DescriptionES"
  },
  DescriptionEN: {
    name: "DescriptionEN",
    slug: "DescriptionEN",
    i18n: "DescriptionEN"
  },
  ShortName: {
    name: "Short name",
    slug: "ShortName",
    i18n: "ShortName"
  },
  CustomizedAVV: {
    name: "Customized AVV",
    slug: "CustomizedAVV",
    i18n: "CustomizedAVV"
  },
  CustomizedO2T: {
    name: "Customized One2Trip",
    slug: "CustomizedO2T",
    i18n: "CustomizedO2T"
  },
  RelationshipItaCode: {
    name: "Relationship Ita Code",
    slug: "RelationshipItaCode",
    i18n: "RelationshipItaCode"
  },
  ManagementPackage: {
    name: "Management Package",
    slug: "ManagementPackage",
    i18n: "ManagementPackage"
  },
  Service: {
    name: "Service",
    slug: "Service",
    i18n: "Service"
  },
  NameEs: {
    name: "Name Es",
    slug: "NameEs",
    i18n: "NameEs"
  },
  NameEn: {
    name: "Name En",
    slug: "NameEn",
    i18n: "NameEn"
  },
  CategoryType: {
    name: "Category Type",
    slug: "CategoryType",
    i18n: "CategoryType"
  },
  ChildAmount: {
    name: "Child Amount",
    slug: "ChildAmount",
    i18n: "ChildAmount"
  },
  AdultAmount: {
    name: "Adult Amount",
    slug: "AdultAmount",
    i18n: "AdultAmount"
  },
  InfantAmount: {
    name: "Infant Amount",
    slug: "InfantAmount",
    i18n: "InfantAmount"
  },
  Nights: {
    name: "Nights",
    slug: "Nights",
    i18n: "Nights"
  },
  Days: {
    name: "Days",
    slug: "Days",
    i18n: "Days"
  },
  Popular: {
    name: "Popular",
    slug: "Popular",
    i18n: "Popular"
  },
  MinimumPrice: {
    name: "Minimal Price",
    slug: "MinimumPrice",
    i18n: "MinimumPrice"
  },
  IncludeEs: {
    name: "IncludeEs",
    slug: "IncludeEs",
    i18n: "IncludeEs"
  },
  IncludeEn: {
    name: "IncludeEn",
    slug: "IncludeEn",
    i18n: "IncludeEn"
  },
  NoIncludeEs: {
    name: "No IncludeEs",
    slug: "NoIncludeEs",
    i18n: "NoIncludeEs"
  },
  NoIncludeEn: {
    name: "No IncludeEn",
    slug: "NoIncludeEn",
    i18n: "NoIncludeEn"
  },
  ImportantInformationEn: {
    name: "Important InformationEn",
    slug: "ImportantInformationEn",
    i18n: "ImportantInformationEn"
  },
  ImportantInformationEs: {
    name: "Important InformationEs",
    slug: "ImportantInformationEs",
    i18n: "ImportantInformationEs"
  },
  Configuration: {
    name: "Configuration",
    slug: "Configuration",
    i18n: "Configuration"
  },
  Application: {
    name: "Application",
    slug: "Application",
    i18n: "Application"
  },
  CancellationPolicy: {
    name: "Cancellation Policy",
    slug: "CancellationPolicy",
    i18n: "CancellationPolicy"
  },
  Images: {
    name: "Images",
    slug: "Images",
    i18n: "Images"
  },
  Main: {
    name: "Main",
    slug: "Main",
    i18n: "Main"
  },
  Age: {
    name: "Age",
    slug: "Age",
    i18n: "Age"
  },
  Hotel: {
    name: "Hotel",
    slug: "Hotel",
    i18n: "Hotel"
  },
  AgentCommissionValue: {
    name: "Agent commission value",
    slug: "AgentCommissionValue",
    i18n: "AgentCommissionValue"
  },
  MainBooking: {
    name: "Main booking",
    slug: "MainBooking",
    i18n: "MainBooking"
  },
  Blocks: {
    name: "Blocks",
    slug: "Blocks",
    i18n: "Blocks"
  },
  AppliesBothWays: {
    name: "Applies both ways",
    slug: "AppliesBothWays",
    i18n: "AppliesBothWays"
  },
  End: {
    name: "End",
    slug: "End",
    i18n: "End"
  },
  ChargeIn: {
    name: "Charge in",
    slug: "ChargeIn",
    i18n: "ChargeIn"
  },
  Discount: {
    name: "Discount",
    slug: "Discount",
    i18n: "Discount"
  },
  Benefits:{
    name: "Benefits",
    slug: "Benefits",
    i18n: "Benefits"
  },
  Points:{
    name: "Points",
    slug: "Points",
    i18n: "Points"
  },
  Plan:{
    name: "Plan",
    slug: "Plan",
    i18n: "Plan"
  },
  ContentType: {
    name: "Content type",
    slug: "ContentType",
    i18n: "ContentType"
  },
  IsFeatured: {
    name: "Is featured",
    slug: "IsFeatured",
    i18n: "IsFeatured"
  },
  DiscountIn: {
    name: "Discount in",
    slug: "DiscountIn",
    i18n: "DiscountIn"
  },
  TicketsIssued:{
    name: "Tickets issued",
    slug: "TicketsIssued",
    i18n: "TicketsIssued"
  },
  Copy: {
    name: "Copy",
    slug: "Copy",
    i18n: "Copy"
  },
  MyCommissions: {
    name: "MyCommissions",
    slug: "MyCommissions",
    i18n: "MyCommissions"
  },
  Incentives: {
    name: "Copy",
    slug: "Copy",
    i18n: "Copy"
  },
  IssueDate: {
    name: "IssueDate",
    slug: "IssueDate",
    i18n: "IssueDate"
  },
  InitialAgent: {
    name: "InitialAgent",
    slug: "InitialAgent",
    i18n: "InitialAgent"
  },
  FinalAgent: {
    name: "FinalAgent",
    slug: "FinalAgent",
    i18n: "FinalAgent"
  },
  Administration: {
    name: "Administration",
    slug: "Administration",
    i18n: "Administration"
  },
  AgentEmail: {
    name: "AgentEmail",
    slug: "AgentEmail",
    i18n: "AgentEmail"
  },
  IsChange: {
    name: "Is a change?",
    slug: "IsChange",
    i18n: "IsChange"
  },
  AmountDays:{
    name: "Amount days",
    slug: "AmountDays",
    i18n: "AmountDays"
  },
  Area:{
    name: "Area",
    slug: "Area",
    i18n: "Area"
  },
  SupportTeam:{
    name: "Support team",
    slug: "SupportTeam",
    i18n: "SupportTeam"
  },
  Rol:{
    name: "Rol",
    slug: "Rol",
    i18n: "Rol"
  },
  SendMoney: {
    name: "Send money",
    slug: "SendMoney",
    i18n: "SendMoney"
  },
  SendMoneyEwallet: {
    name: "Would you like to send money to an E-wallet account?",
    slug: "SendMoneyEwallet",
    i18n: "SendMoneyEwallet"
  },
  SendMoneyMasspay: {
    name: "Would you like to send money to an Masspay account?",
    slug: "SendMoneyMasspay",
    i18n: "SendMoneyMasspay"
  },
  Activities: {
    name: "Activities",
    slug: "Activities",
    i18n: "Activities"
  },
  Difference:{
    name: "Difference",
    slug: "Difference",
    i18n: "Difference"
  },
  Receipts:{
    name: "Receipts",
    slug: "Receipts",
    i18n: "Receipts"
  },
  Audit:{
    name: "Audit",
    slug: "Audit",
    i18n: "Audit"
  }
};
