/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default [
  {
    url: "/",
    name: "Home",
    slug: "home",
    icon: "HomeIcon",
    i18n: "Home"
  },
  {
    url: null,
    name: "Tourism",
    icon: "SendIcon",
    i18n: "Tourism",
    submenu: [
      {
        url: "/Booking/create",
        name: "Create",
        slug: "Create",
        i18n: "Create"
      },
      {
        url: "/Booking/byReference",
        name: "Get",
        slug: "Get",
        i18n: "Get",
      },
      {
        url: "/Booking/audit",
        name: "Audit",
        slug: "Audit",
        i18n: "Audit",
      },
      {
        url: null,
        name: "Configuration",
        i18n: "Configuration",
        submenu:[
          {
            url: "/management/Airlines",
            name: "Airlines",
            i18n: "Airlines",
            slug:"Airlines"
          },
          {
            url: "/management/blocks",
            name: "Blocks",
            i18n: "Blocks",
            slug:"Blocks"
          },
          {
            url: "/management/Category",
            name: "Category",
            i18n: "Category",
            slug: "Category"
          },
          {
            url: "/management/Service",
            name: "Service",
            i18n: "Service",
            slug:"Service"
          },
          {
            url: "/settings/SupplierDestination",
            name: "SupplierDestination",
            slug: "SupplierDestination",
            i18n: "SupplierDestination"
          },
        ]
      },
      {
        url: null,
        name: "Settings",
        i18n: "Settings",
        submenu:[
          {
            url: "/management/Hotels",
            name: "Hotels",
            slug: "Hotels",
            i18n: "Hotels"
          },
          {
            url: "/management/Package",
            name: "Packages",
            i18n: "Packages",
            slug: "Packages"
          }
        ]
      },
    ]
  },
  {
    url: null,
    name: "Purchases",
    icon: "ShoppingCartIcon",
    i18n: "Purchases",
    submenu: [
      {
        url: "/purchases/index",
        name: "List",
        slug: "List",
        i18n: "List"
      },
      {
        url: "/purchases/affiliations",
        name: "Affiliations",
        slug: "Affiliations",
        i18n: "Affiliations"
      }
    ]
  },
  {
    url: null,
    name: "Accounting",
    icon: "TrendingUpIcon",
    i18n: "Accounting",
    submenu: [
      {
        url: "/accounting/payments",
        name: "Payments",
        slug: "Payments",
        i18n: "Payments"
      },
      // {
      //   url: "/accounting/cashRegister",
      //   name: "Cash register",
      //   slug: "CashRegister",
      //   i18n: "CashRegister"
      // },
      {
        url: "/accounting/Sales",
        name: "Sales",
        slug: "Sales",
        i18n: "Sales"
      },
      {
        url: "/accounting/flightSales",
        name: "Tickets issued",
        slug: "TicketsIssued",
        i18n: "TicketsIssued"
      },
      {
        url: "/accounting/index-incentive",
        name: "Incentives",
        slug: "Incentives",
        i18n: "Incentives"
      },
      {
        url: "/payment/settings",
        name: "Configuration",
        slug: "Configuration",
        i18n: "Configuration"
      },
    ]
  },
  {
    url: null,
    name: "BlockPay",
    icon: "DollarSignIcon",
    submenu: [
      {
        url: "/blockPay/index",
        name: "List",
        slug: "List",
        i18n: "List"
      }
    ]
  },
  // {
  //   url: null,
  //   name: "Genealogy",
  //   icon: "GitPullRequestIcon",
  //   i18n: "Genealogy",
  //   submenu: [
  //     {
  //       url: "/genealogy/index",
  //       name: "Binary tree",
  //       slug: "binaryTree",
  //       i18n: "BinaryTree"
  //     },
  //     {
  //       url: "/genealogy/detail",
  //       name: "Detail",
  //       slug: "detail",
  //       i18n: "Detail"
  //     },
  //     {
  //       url: "/genealogy/statusByPeriod",
  //       name: "Status by period",
  //       slug: "statusByPeriod",
  //       i18n: "StatusByPeriod"
  //     },
  //     {
  //       url: "/orders/index",
  //       name: "Orders",
  //       slug: "Orders",
  //       i18n: "Orders"
  //     }
  //   ]
  // },
  // {
  //   url: null,
  //   name: "Commissions",
  //   icon: "ActivityIcon",
  //   i18n: "Commissions",
  //   submenu: [
  //     {
  //       url: "/commissions/Index",
  //       name: "Total",
  //       slug: "Total",
  //       i18n: "Total"
  //     },
  //     {
  //       url: "/commissions/NewCustomized",
  //       name: "Customized",
  //       slug: "Customized",
  //       i18n: "Customized",
  //     },
  //     {
  //       url: "/booking/commission",
  //       name: "Tourism",
  //       slug: "Tourism",
  //       i18n: "Tourism"
  //     }
  //   ]
  // },
  {
    url: null,
    name: "Settings",
    icon: "SettingsIcon",
    i18n: "Settings",
    submenu: [
      // {
      //   url: "/settings/access",
      //   name: "Access",
      //   slug: "Access",
      //   i18n: "Access"
      // },
      {
        url: "/settings/exchangeRate",
        name: "Exchange Rate",
        slug: "ExchangeRate",
        i18n: "ExchangeRate"
      },
      {
        url: "/settings/products",
        name: "Products",
        slug: "Products",
        i18n: "Products"
      },
      {
        url: "/settings/Promotion",
        name: "Promotion",
        slug: "Promotion",
        i18n: "Promotion"
      },
      {
        url: "/settings/Parameters",
        name: "Parameters",
        slug: "Parameters",
        i18n: "Parameters"
      },
      {
        url: "/settings/Mail",
        name: "MailSetting",
        slug: "MailSetting",
        i18n: "MailSetting"
      },
      {
        url: "/settings/blog",
        name: "Blog",
        slug: "Blog",
        i18n: "Blog"
      },
      {
        url: "/settings/Supplier",
        name: "Supplier",
        slug: "Supplier",
        i18n: "Supplier"
      }
    ]
  },
  {
    url: null,
    name: "Users",
    icon: "UserIcon",
    i18n: "Users",
    submenu: [
      {
        url: "/users/index",
        name: "List",
        slug: "List",
        i18n: "List"
      }
    ]
  },
  {
    url: null,
    name: "Benefits",
    icon: "AwardIcon",
    i18n: "Benefits",
    submenu: [
      {
        url: "/benefit/index",
        name: "List",
        slug: "List",
        i18n: "List"
      },
      {
        url: "/benefit/point",
        name: "Points",
        slug: "Points",
        i18n: "Points"
      }
    ]
  }
];
