var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "div",
    { staticClass: "vx-breadcrumb", class: _vm.data.staticClass },
    [
      _c(
        "ul",
        { staticClass: "flex flex-wrap items-center" },
        [
          _c(
            "li",
            { staticClass: "inline-flex items-end" },
            [
              _c(
                "router-link",
                { attrs: { to: "/" } },
                [
                  _c("feather-icon", {
                    attrs: {
                      icon: "HomeIcon",
                      svgClasses: "h-5 w-5 mb-1 stroke-current text-primary"
                    }
                  })
                ],
                1
              ),
              _c(
                "span",
                { staticClass: "breadcrumb-separator mx-2" },
                [
                  _c("feather-icon", {
                    attrs: {
                      icon: _vm.props.isRTL
                        ? "ChevronsLeftIcon"
                        : "ChevronsRightIcon",
                      svgClasses: "w-4 h-4"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._l(_vm.props.route.meta.breadcrumb.slice(1, -1), function(
            link,
            index
          ) {
            return _c(
              "li",
              { key: index, staticClass: "inline-flex items-center" },
              [
                link.url
                  ? _c("router-link", { attrs: { to: link.url } }, [
                      _vm._v(_vm._s(link.title))
                    ])
                  : _c("span", { staticClass: "text-primary cursor-default" }, [
                      _vm._v(_vm._s(link.title))
                    ]),
                _c(
                  "span",
                  { staticClass: "breadcrumb-separator mx-2 flex items-start" },
                  [
                    _c("feather-icon", {
                      attrs: {
                        icon: _vm.props.isRTL
                          ? "ChevronsLeftIcon"
                          : "ChevronsRightIcon",
                        svgClasses: "w-4 h-4"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          }),
          _c("li", { staticClass: "inline-flex" }, [
            _vm.props.route.meta.breadcrumb.slice(-1)[0].active
              ? _c("span", { staticClass: "cursor-default" }, [
                  _vm._v(
                    _vm._s(_vm.props.route.meta.breadcrumb.slice(-1)[0].title)
                  )
                ])
              : _vm._e()
          ])
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }