<template>
  <div>
    <div v-if="canSearch">
      <vx-card class="mb-4">
        <h4 class="mb-4">
          {{ $t(resources.Filters.i18n) || resources.Filters.name }}
        </h4>
        <div class="vx-row">
          <div class="vx-col w-full md:w-1/5 mb-2">
            <label class="vs-input--label">
              {{ $t(resources.StartDate.i18n) || resources.StartDate.name }}
            </label>
            <datepicker
              :language="languages[language]"
              format="d MMMM yyyy"
              v-model="start_date"
            ></datepicker>
          </div>
          <div class="vx-col w-full md:w-1/5 mb-2">
            <label class="vs-input--label">{{
              $t(resources.EndDate.i18n) || resources.EndDate.name
            }}</label>
            <datepicker
              :language="languages[language]"
              format="d MMMM yyyy"
              v-model="end_date"
            ></datepicker>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-6">
            <ul class="vx-col w-full">
              <li>
                <vs-checkbox v-model="isCreationDate">{{
                  $t(resources.CreationDate.i18n) || resources.CreationDate.name
                }}</vs-checkbox>
              </li>
            </ul>
          </div>
          <vs-button
            color="primary"
            type="filled"
            icon-pack="feather"
            icon="icon-refresh-ccw"
            class="mt-5"
            @click="cleanDate()"
          ></vs-button>
          <div class="vx-col w-full md:w-1/6 mb-2">
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                $t(resources.Supplier.i18n) || resources.Supplier.name
              }}</label>
              <v-select
                v-model="supplierId"
                :clearable="true"
                :options="supplierOption"
                :reduce="gds => gds.id"
                label="name"
              />
            </div>
          </div>
          <div class="vx-col w-full md:w-1/6 mb-2">
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                $t(resources.Country.i18n) || resources.Country.name
              }}</label>
              <v-select
                v-model="country"
                :clearable="true"
                :options="countryOption"
                :reduce="item => item.countryCode"
                label="countryName"
              />
            </div>
          </div>
          <div class="vx-col w-full md:w-1/6 mb-2">
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                $t(resources.Source.i18n) || resources.Source.name
              }}</label>
              <v-select
                v-model="source"
                :clearable="true"
                :options="sourceOption"
                :reduce="item => item.id"
                label="name"
              />
            </div>
          </div>
          <div class="vx-col w-full md:w-1/5 mb-2">
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                $t(resources.Type.i18n) || resources.Type.name
              }}</label>
              <v-select
                v-model="type"
                :clearable="true"
                :options="typeOption"
                :reduce="item => item.id"
                label="name"
              />
            </div>
          </div>
          <div class="vx-col w-full md:w-1/5 mb-2">
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                $t(resources.SupportTeam.i18n) || resources.SupportTeam.name
              }}</label>
              <v-select
                v-model="areaId"
                :clearable="true"
                :options="areaOption"
                :reduce="item => item.id"
                label="name"
              />
            </div>
          </div>
          <div class="vx-col md:w-1/5 w-full mb-4">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-mail"
                :label="
                  $t(resources.AgentEmail.i18n) || resources.AgentEmail.name
                "
                v-model="email"
              />
            </div>
          </div>
          <div class="vx-col w-full md:w-1/6 mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-hash"
                :label="
                  $t(resources.BookingCode.i18n) || resources.BookingCode.name
                "
                v-validate="'alpha_dash'"
                v-model="bookingReference"
                name="bookingReference"
                maxlength="20"
              />
            </div>
          </div>
          <div class="vx-col mt-2">
            <vs-button color="primary" type="filled" @click="get()">{{
              $t(resources.Search.i18n) || resources.Search.name
            }}</vs-button>
          </div>
        </div>
      </vx-card>
      <vx-card class="mb-4">
        <vs-collapse>
          <vs-collapse-item>
            <div slot="header">
              {{ $t(resources.Summary.i18n) || resources.Summary.i18n }}
            </div>
            <vs-row vs-type="flex" vs-justify="center" class="mb-4">
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="2"
                vs-sm="3"
                vs-xs="12"
                class="mr-2"
              >
                <vx-card card-background="success" content-color="#fff">
                  <h5 class="text-center text-white">
                    {{
                      $t(resources.InitialAgent.i18n) ||
                        resources.InitialAgent.i18n
                    }}
                  </h5>
                  <h1 class="text-center text-white">
                    <strong>${{ initialAgent }}</strong>
                  </h1>
                </vx-card>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="2"
                vs-sm="3"
                vs-xs="12"
                class="mr-2"
              >
                <vx-card card-background="success" content-color="#fff">
                  <h5 class="text-center text-white">
                    {{
                      $t(resources.FinalAgent.i18n) || resources.FinalAgent.i18n
                    }}
                  </h5>
                  <h1 class="text-center text-white">
                    <strong>${{ finalAgent }}</strong>
                  </h1>
                </vx-card>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="2"
                vs-sm="3"
                vs-xs="12"
                class="mr-2"
              >
                <vx-card card-background="success" content-color="#fff">
                  <h5 class="text-center text-white">
                    {{
                      $t(resources.Administration.i18n) ||
                        resources.Administration.i18n
                    }}
                  </h5>
                  <h1 class="text-center text-white">
                    <strong>${{ adminCommission }}</strong>
                  </h1>
                </vx-card>
              </vs-col>
            </vs-row>
          </vs-collapse-item>
          <vs-collapse-item>
            <div slot="header">
              {{ $t(resources.SupportTeam.i18n) || resources.SupportTeam.i18n }}
            </div>
            <div class="vx-row mb-base">
              <div
                class="vx-col w-full md:w-1/2 mb-base"
                v-for="item in summaryTeams"
                :key="item.idx"
              >
                <h5 class="text-center">{{ item.area }}</h5>
                <div>
                  <vs-table :data="item.children">
                    <template slot="thead">
                      <vs-th align="center">{{
                        $t(resources.InitialAgent.i18n) ||
                          t(resources.InitialAgent.name)
                      }}</vs-th>
                      <vs-th>{{
                        $t(resources.Value.i18n) || t(resources.Value.name)
                      }}</vs-th>
                    </template>
                    <template slot-scope="{ data }">
                      <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td :data="data[indextr].initialAgent">{{
                          data[indextr].initialAgent
                        }}</vs-td>
                        <vs-td :data="data[indextr].initialAgentCommission">{{
                          data[indextr].initialAgentCommission
                        }}</vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                </div>
                <h5 class="text-center text-success font-bold">
                  $
                  {{
                    item.children
                      .map(x => x.initialAgentCommission)
                      .reduce((y, amount) => y + amount)
                      .toLocaleString("es-MX")
                  }}
                </h5>
              </div>
            </div>
          </vs-collapse-item>
        </vs-collapse>
      </vx-card>
      <div id="ag-grid-demo">
        <vx-card>
          <!-- TABLE ACTION ROW -->
          <div class="flex flex-wrap justify-between items-center">
            <!-- ITEMS PER PAGE -->
            <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
              <vs-dropdown vs-trigger-click class="cursor-pointer">
                <div
                  class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                  <span class="mr-2"
                    >{{
                      currentPage * paginationPageSize -
                        (paginationPageSize - 1)
                    }}
                    -
                    {{
                      dataList.length - currentPage * paginationPageSize > 0
                        ? currentPage * paginationPageSize
                        : dataList.length
                    }}
                    of {{ dataList.length }}</span
                  >
                  <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
                <vs-dropdown-menu>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                    <span>20</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                    <span>50</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                    <span>100</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                    <span>150</span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>

            <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
            <div
              class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
            >
              <vs-input
                class="mb-4 md:mb-0 mr-4"
                v-model="searchQuery"
                @input="updateSearchQuery"
                :placeholder="
                  $t(resources.Search.i18n) || resources.Search.name
                "
              />
              <vs-button
                class="mb-4 md:mb-0"
                @click="gridApi.exportDataAsCsv()"
              >
                {{ $t(resources.ExportAs.i18n) || resources.ExportAs.name }}
              </vs-button>
            </div>
          </div>
          <ag-grid-vue
            :gridOptions="gridOptions"
            class="ag-theme-material w-100 my-4 ag-grid-table"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :rowData="dataList"
            rowSelection="single"
            colResizeDefault="shift"
            :animateRows="true"
            :floatingFilter="true"
            :pagination="true"
            :paginationPageSize="paginationPageSize"
            :suppressPaginationPanel="true"
            @row-selected="onRowSelected"
          ></ag-grid-vue>
          <vs-pagination
            :total="totalPages"
            :max="maxPageNumbers"
            v-model="currentPage"
          />
        </vx-card>
      </div>
    </div>
    <div v-else>
      <h2 class="text-center text-danger">
        Usted no tiene los permisos suficientes para ver este reporte.
      </h2>
    </div>
  </div>
</template>

<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import vSelect from "vue-select";
import moment from "moment";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
    Datepicker,
    vSelect
  },
  data() {
    return {
      resources: resources,
      alerts: alerts,
      language: this.$i18n.locale,
      languages: lang,
      emailSession: JSON.parse(localStorage.getItem("userInfo")).email,
      email: "",
      start_date: new Date(),
      end_date: new Date(),
      country: "",
      countryOption: [],
      type: "",
      typeOption: [],
      supplierId: "",
      supplierOption: [],
      source: "",
      sourceOption: [],
      isCreationDate: false,
      areaId: "",
      bookingReference: "",
      areaOption: [
        { id: 8, name: "Duque" },
        { id: 9, name: "Lara" },
        { id: 10, name: "Carico" },
        { id: 11, name: "Urdaneta" }
      ],
      summaryTeams: [],
      searchQuery: "",
      initialAgent: 0,
      finalAgent: 0,
      adminCommission: 0,
      gridOptions: {},
      maxPageNumbers: 10,
      gridApi: null,
      rows: 20,
      NumberPage: 0,
      dataList: [],
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: this.$i18n.t("International"),
          field: "isInternational",
          width: 110,
          cellRenderer: function(event) {
            let object = "";
            switch (event.node.data.isInternational) {
              case 0:
                object =
                  '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-danger), 1);">highlight_off</i>';
                break;
              case 1:
                object =
                  '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-success), 1);">check_circle</i>';
                break;
              default:
                object =
                  '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-danger), 1);">highlight_off</i>';
                break;
            }
            return object;
          }
        },
        {
          headerName: this.$i18n.t("Code"),
          field: "bookingReference",
          width: 150,
          filter: true
        },
        {
          headerName: this.$i18n.t("Type"),
          field: "product",
          width: 170,
          filter: true
        },
        {
          headerName: this.$i18n.t("AgentCommissionValue"),
          field: "agentCommissionValue",
          width: 160,
          filter: true
        },
        {
          headerName: this.$i18n.t("InitialAgent"),
          field: "initialAgent",
          width: 180,
          filter: true
        },
        {
          headerName: this.$i18n.t("Value"),
          field: "initialAgentCommission",
          width: 180,
          filter: true
        },
        {
          headerName: this.$i18n.t("FinalAgent"),
          field: "finalAgent",
          width: 180,
          filter: true
        },
        {
          headerName: this.$i18n.t("Value"),
          field: "finalAgentCommission",
          width: 180,
          filter: true
        },
        {
          headerName: this.$i18n.t("Administration"),
          field: "adminCommission",
          width: 180,
          filter: true
        },
        {
          headerName: this.$i18n.t("Area"),
          field: "initialAgentArea",
          filter: true
        },
        {
          headerName: this.$i18n.t("IssueDate"),
          field: "issuedDate",
          filter: true
        },
        {
          headerName: this.$i18n.t("Passenger"),
          field: "paxQuantity",
          filter: true
        },
        {
          headerName: this.$i18n.t("Country"),
          field: "countryCode",
          filter: true,
          width: 120
        },
        {
          headerName: this.$i18n.t("Source"),
          field: "source",
          filter: true
        },
        {
          headerName: this.$i18n.t("Supplier"),
          field: "supplier",
          width: 170,
          filter: true
        },
        {
          headerName: this.$i18n.t("CreationDate"),
          field: "creationDate",
          filter: true
        }
      ]
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("level", null);
      } else this.gridOptions.columnApi.setColumnPinned("level", "left");
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 20;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    },
    canSearch() {
      let listEmails = [
        "a.gomez@visiontravel.net",
        "odalis@visiontravel.net",
        "a.ramirez@visiontravel.net",
        "felipe@visiontravel.net",
        "v.hernandez@visiontravelmail.net",
        "l.palacio@visiontravelmail.net",
        "y.villa@visiontravelmail.net",
        "clara.chavarriaga@visiontravel.net",
        "j.villegas@visiontravel.net",
        "j.builes@visiontravelmail.net"
      ];
      let isAllow = listEmails.filter(
        x => x == this.emailSession.toLowerCase()
      );
      if (isAllow.length > 0) return true;

      return false;
    }
  },
  async created() {
    this.start_date = new Date(`${moment().format("YYYY-MM")}-02`);

    await this.getCountries();
    await this.getProducts();
    await this.getBookingSource();
    await this.getSupplier();
    await this.get();
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    async get() {
      this.$vs.loading();
      let startDate = "";
      let endDate = "";

      if (this.start_date != null && this.end_date != null) {
        startDate = moment(this.start_date).format("YYYY-MM-DD");
        endDate = moment(this.end_date).format("YYYY-MM-DD");
      }

      const _data = {
        startDate: startDate,
        endDate: endDate,
        productId: this.type,
        countryCode: this.country,
        supplierId: this.supplierId,
        bookingSourceId: this.source,
        initialAgentAreaId: this.areaId,
        email: this.email,
        bookingReference: this.bookingReference,
        issuedDate: this.isCreationDate ? false : true,
        isAdmin: true
      };

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_REPORT}booking/getIncentive`,
        data: _data,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      }).then(
        async result => {
          if (result.data.success) {
            this.dataList = result.data.data;
            await this.getSummary(_data);
            await this.getSummaryTeams(_data);
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async getSummary(data) {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_REPORT}booking/summaryIncentive`,
        data: data,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      }).then(
        result => {
          if (result.data.success) {
            this.initialAgent = result.data.data.initialAgent;
            this.finalAgent = result.data.data.finalAgent;
            this.adminCommission = result.data.data.adminCommission;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getSummaryTeams(data) {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_REPORT}booking/getSummaryTeams`,
        data: data,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      }).then(
        result => {
          if (result.data.success) {
            let _response = result.data.data.reduce(
              (r, { areaInitialAgent: area, ...object }) => {
                var temp = r.find(o => o.area === area);
                if (!temp) r.push((temp = { area, children: [] }));
                temp.children.push(object);
                return r;
              },
              []
            );
            this.summaryTeams = _response;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCountries() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }CountryOperation/GetPccCountry/${this.$i18n.locale.toUpperCase()}/1`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.countryOption = [];
          } else {
            this.countryOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getProducts() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Product/bytype/${this.$i18n.locale.toUpperCase()}/1`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.typeOption = [];
          } else {
            this.typeOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getSupplier() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Supplier`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.supplierOption = [];
          } else {
            this.supplierOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getBookingSource() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }BookingSource/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.sourceOption = [];
          } else {
            this.sourceOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    onRowSelected(event) {
      let _bookingId = event.node.data.bookingId;
      this.$router.push({
        name: "booking-bookingdetail",
        params: {
          bookingId: _bookingId
        }
      });
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  }
};
</script>
