<template>
  <div>
    <vx-card class="mb-4" :title="$t(resources.Packages.i18n) || resources.Packages.name">
      <div class="vx-row">
        <div class="vx-col md:w-1/3 w-full mb-2">
          <div class="vx-col w-full">
            <label>{{
                $t(resources.NameEs.i18n) || resources.NameEs.name
            }}</label>
            <vs-input class="w-full" icon-pack="feather" icon="icon-clipboard" icon-no-border
              v-model="packageDto.nameEs" v-validate="'required|min:3|max:49'" name="nameEs" />
            <span class="text-danger text-sm" v-show="errors.has('nameEs')">{{
                errors.first("nameEs")
            }}</span>
          </div>
        </div>
        <div class="vx-col md:w-1/3 w-full mb-2">
          <label>{{
              $t(resources.NameEn.i18n) || resources.NameEn.name
          }}</label>
          <div class="vx-col w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-clipboard" icon-no-border
              v-model="packageDto.nameEn" v-validate="'required|min:3|max:49'" name="nameEn" />
            <span class="text-danger text-sm" v-show="errors.has('nameEs')">{{
                errors.first("nameEn")
            }}</span>
          </div>
        </div>
        <div class="vx-col md:w-1/3 w-full mb-2">
          <label>{{
              $t(resources.ExpirationDate.i18n) || resources.ExpirationDate.name
          }}</label>
          <datepicker :language="languages[language]" format="d MMMM yyyy" v-model="packageDto.outOfDate"
            v-validate="'required'" name="expirationDate"></datepicker>
          <span class="text-danger text-sm" v-show="errors.has('expirationDate')">{{ errors.first("expirationDate")
          }}</span>
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label class="vs-input--label">{{
              $t(resources.Country.i18n) || resources.Country.name
          }}</label>
          <v-select v-model="packageDto.countryId" :clearable="false" :options="countryList"
            :reduce="country => country.id" label="name" v-on:input="getStateByCountry(`${packageDto.countryId}`)"
            :hint="`${packageDto.countryId}`" v-validate="'required'" name="country" />
          <span class="text-danger text-sm" v-show="errors.has('country')">{{
              errors.first("country")
          }}</span>
        </div>
        <div class="vx-col md:w-1/3 w-full mb-2">
          <label class="vs-input--label">{{
              $t(resources.State.i18n) || resources.State.name
          }}</label>
          <v-select v-model="packageDto.stateId" :clearable="false" :options="stateCountry" :reduce="state => state.id"
            label="name" v-on:input="getCityByState(`${packageDto.stateId}`)" :hint="`${packageDto.stateId}`"
            v-validate="'required'" name="state" />
          <span class="text-danger text-sm" v-show="errors.has('state')">{{
              errors.first("state")
          }}</span>
        </div>
        <div class="vx-col md:w-1/3 w-full mb-2">
          <label class="vs-input--label">{{
              $t(resources.Destination.i18n) || resources.Destination.name
          }}</label>
          <v-select v-model="packageDto.destinationId" :clearable="false" :options="destinationList"
            :reduce="city => city.id" label="name" v-validate="'required'" name="destination" />
          <span class="text-danger text-sm" v-show="errors.has('destination')">{{ errors.first("destination") }}</span>
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label>{{
              $t(resources.DescriptionES.i18n) || resources.DescriptionES.name
          }}</label>
          <vs-textarea rows="5" v-model="packageDto.descriptionEs" v-validate="'required|min:30|max:10000'"
            name="descriptionEs" />
          <span class="text-danger text-sm" v-show="errors.has('descriptionEs')">{{ errors.first("descriptionEs")
          }}</span>
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label>{{
              $t(resources.DescriptionEN.i18n) || resources.DescriptionEN.name
          }}</label>
          <vs-textarea rows="5" v-model="packageDto.descriptionEn" v-validate="'required|min:30|max:10000'"
            name="descriptionEn" />
          <span class="text-danger text-sm" v-show="errors.has('descriptionEn')">{{ errors.first("descriptionEn")
          }}</span>
        </div>

        <div class="vx-col md:w-1/3 w-full">
          <label>{{
              $t(resources.IncludeEs.i18n) || resources.IncludeEs.name
          }}</label>
          <vs-textarea rows="5" v-model="packageDto.includeEs" v-validate="'required|min:30|max:10000'"
            name="includeEs" />
          <span class="text-danger text-sm" v-show="errors.has('includeEs')">{{
              errors.first("includeEs")
          }}</span>
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label>{{
              $t(resources.IncludeEn.i18n) || resources.IncludeEn.name
          }}</label>
          <vs-textarea rows="5" v-model="packageDto.includeEn" v-validate="'required|min:30|max:10000'"
            name="includeEn" />
          <span class="text-danger text-sm" v-show="errors.has('includeEn')">{{
              errors.first("includeEn")
          }}</span>
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label>{{
              $t(resources.NoIncludeEs.i18n) || resources.NoIncludeEs.name
          }}</label>
          <vs-textarea rows="5" v-model="packageDto.noIncludeEs" v-validate="'required|min:30|max:10000'"
            name="noIncludeEs" />
          <span class="text-danger text-sm" v-show="errors.has('noIncludeEs')">{{ errors.first("noIncludeEs") }}</span>
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label>{{
              $t(resources.NoIncludeEn.i18n) || resources.NoIncludeEn.name
          }}</label>
          <vs-textarea rows="5" v-model="packageDto.noIncludeEn" v-validate="'required|min:30|max:10000'"
            name="NoIncludeEn" />
          <span class="text-danger text-sm" v-show="errors.has('NoIncludeEn')">{{ errors.first("NoIncludeEn") }}</span>
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label>{{
              $t(resources.ImportantInformationEs.i18n) ||
              resources.ImportantInformationEs.name
          }}</label>
          <vs-textarea rows="5" v-model="packageDto.importantInformationEs" v-validate="'required|min:30|max:10000'"
            name="importantInformationEs" />
          <span class="text-danger text-sm" v-show="errors.has('importantInformationEs')">{{
              errors.first("importantInformationEs")
          }}</span>
        </div>
        <div class="vx-col md:w-1/3 w-full">
          <label>{{
              $t(resources.ImportantInformationEn.i18n) ||
              resources.ImportantInformationEn.name
          }}</label>
          <vs-textarea rows="5" v-model="packageDto.importantInformationEn" v-validate="'required|min:30|max:10000'"
            name="importantInformationEn" />
          <span class="text-danger text-sm" v-show="errors.has('importantInformationEn')">{{
              errors.first("importantInformationEn")
          }}</span>
        </div>
        <div class="vx-col md:w-1/6 w-full mb-2">
          <ul class="vx-col w-full mt-5">
            <li>
              <vs-checkbox v-model="packageDto.isPopular">{{
                  $t(resources.Popular.i18n) || resources.Popular.name
              }}</vs-checkbox>
            </li>
          </ul>
        </div>
        <div class="vx-col md:w-1/6 w-full mb-2">
          <ul class="vx-col w-full mt-5">
            <li>
              <vs-checkbox v-model="packageDto.active">{{
                  $t(resources.Active.i18n) || resources.Active.name
              }}</vs-checkbox>
            </li>
          </ul>
        </div>
        <div class="vx-col mb-2 mt-5" style="text-align: right">
          <vs-button color="primary" type="filled" @click.prevent="validInformation">
            {{ $t(resources.Save.i18n) || resources.Save.name }}
          </vs-button>
        </div>
      </div>
    </vx-card>

    <div class="vx-row mb-4" v-if="packageId != 0">
      <vx-card class="mb-4" :title="$t(resources.Detail.i18n) || resources.Detail.name">
        <vs-row vs-type="flex" vs-justify="center">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <vs-button type="border" color="primary" icon-pack="feather" icon="icon-plus" size="large"
              @click="openCreateDetail()"></vs-button>
          </vs-col>
        </vs-row>
        <div class="vx-col w-full">
          <vs-table max-items="10" pagination search @search="handleSearch" :data="details">
            <template slot="thead">
              <vs-th>{{
                  $t(resources.Hotel.i18n) || resources.Hotel.name
              }}</vs-th>
              <vs-th>{{
                  $t(resources.Accommodation.i18n) || resources.Accommodation.name
              }}</vs-th>
              <vs-th>{{
                  $t(resources.StartDate.i18n) || resources.StartDate.name
              }}</vs-th>
              <vs-th>{{
                  $t(resources.EndDate.i18n) || resources.EndDate.name
              }}</vs-th>
              <vs-th>{{
                  $t(resources.Value.i18n) || resources.Value.name
              }}</vs-th>
              <vs-th>{{
                  $t(resources.AdultAmount.i18n) || resources.AdultAmount.name
              }}</vs-th>
              <vs-th>{{
                  $t(resources.ChildAmount.i18n) || resources.ChildAmount.name
              }}</vs-th>
              <vs-th>{{
                  $t(resources.InfantAmount.i18n) || resources.InfantAmount.name
              }}</vs-th>
              <vs-th>{{
                  $t(resources.Options.i18n) || resources.Options.name
              }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].hotelName">{{
                    data[indextr].hotelName
                }}</vs-td>
                <vs-td :data="data[indextr].accommodationName">{{
                    data[indextr].accommodationName
                }}</vs-td>
                <vs-td :data="data[indextr].startDate">{{
                    moment(data[indextr].startDate[0]).format("YYYY-MM-DD")
                }}</vs-td>
                <vs-td :data="data[indextr].endDate">{{
                    moment(data[indextr].endDate[0]).format("YYYY-MM-DD")
                }}</vs-td>
                <vs-td :data="data[indextr].value">{{
                    data[indextr].value
                }}</vs-td>
                <vs-td :data="data[indextr].adultAmount">{{
                    data[indextr].adultAmount
                }}</vs-td>
                <vs-td :data="data[indextr].childAmount">{{
                    data[indextr].childAmount
                }}</vs-td>
                <vs-td :data="data[indextr].infantAmount">{{
                    data[indextr].infantAmount
                }}</vs-td>
                <vs-td>
                  <vx-tooltip :text="$t(resources.View.i18n) || resources.View.name">
                    <a @click="showPopUpDetail(data[indextr])">
                      <feather-icon icon="EyeIcon" class="w-5 h-5 mr-1"></feather-icon>
                    </a>
                  </vx-tooltip>
                  <vx-tooltip :text="$t(resources.Delete.i18n) || resources.Delete.name">
                    <a @click="deleteDetailPackage(data[indextr])">
                      <feather-icon icon="XIcon" svgClasses="fill-current text-danger" class="w-5 h-5 mr-1">
                      </feather-icon>
                    </a>
                  </vx-tooltip>
                  <vx-tooltip :text="$t(resources.Service.i18n) || resources.Service.name">
                    <a @click="showPopUpService(data[indextr])">
                      <feather-icon icon="SettingsIcon" class="w-5 h-5 mr-1"></feather-icon>
                    </a>
                  </vx-tooltip>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </vx-card>

      <!-- Applications, settings, categories y suppliers -->
      <div class="vx-row mb-4">
        <div class="vx-col md:w-1/3 w-full mb-4">
          <vx-card class="mb-2" :title="
            $t(resources.Configuration.i18n) || resources.Configuration.name
          ">
            <vs-row vs-type="flex" vs-justify="center" class="mb-2">
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
                <vs-button type="border" color="primary" icon-pack="feather" icon="icon-plus" size="large"
                  @click="popUpConfigurationPackage = true"></vs-button>
              </vs-col>
            </vs-row>
            <div class="vx-col w-full">
              <vs-table max-items="10" pagination :data="packageConfigurationOption">
                <template slot="thead">
                  <vs-th>{{
                      $t(resources.CountryOperation.i18n) ||
                      resources.CountryOperation.name
                  }}</vs-th>
                  <vs-th>{{
                      $t(resources.CurrencyCode.i18n) ||
                      resources.CurrencyCode.name
                  }}</vs-th>
                  <vs-th>{{
                      $t(resources.Main.i18n) || resources.Main.name
                  }}</vs-th>
                  <vs-th>{{
                      $t(resources.Options.i18n) || resources.Options.name
                  }}</vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].countryOperationName">{{
                        data[indextr].countryOperationName
                    }}</vs-td>
                    <vs-td :data="data[indextr].currencyCode">{{
                        data[indextr].currencyCode
                    }}</vs-td>
                    <vs-td :data="data[indextr].isMain">
                      <feather-icon v-if="data[indextr].isMain" icon="CheckIcon" svgClasses="fill-current text-success"
                        class="w-5 h-5 mr-1"></feather-icon>
                      <feather-icon v-else icon="XIcon" svgClasses="fill-current text-danger" class="w-5 h-5 mr-1">
                      </feather-icon>
                    </vs-td>
                    <vs-td>
                      <vx-tooltip :text="
                        $t(resources.Delete.i18n) || resources.Delete.name
                      ">
                        <a @click="deletePackageConfiguration(data[indextr])">
                          <feather-icon icon="XIcon" svgClasses="fill-current text-danger" class="w-5 h-5 mr-1">
                          </feather-icon>
                        </a>
                      </vx-tooltip>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </vx-card>
        </div>
        <div class="vx-col md:w-1/3 w-full mb-4">
          <vx-card class="mb-2" :title="$t(resources.Category.i18n) || resources.Category.name">
            <vs-row vs-type="flex" vs-justify="center" class="mb-2">
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
                <vs-button type="border" color="primary" icon-pack="feather" icon="icon-plus" size="large"
                  @click="popUpCategory = true"></vs-button>
              </vs-col>
            </vs-row>
            <div class="vx-col w-full">
              <vs-table max-items="10" pagination :data="packageCategoryOption">
                <template slot="thead">
                  <vs-th>{{
                      $t(resources.Name.i18n) || resources.Name.name
                  }}</vs-th>
                  <vs-th>{{
                      $t(resources.Options.i18n) || resources.Options.name
                  }}</vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].categoryName">{{
                        data[indextr].categoryName
                    }}</vs-td>
                    <vs-td>
                      <vx-tooltip :text="
                        $t(resources.Delete.i18n) || resources.Delete.name
                      ">
                        <a @click="deletePackageCategory(data[indextr])">
                          <feather-icon icon="XIcon" svgClasses="fill-current text-danger" class="w-5 h-5 mr-1">
                          </feather-icon>
                        </a>
                      </vx-tooltip>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </vx-card>
        </div>
        <div class="vx-col md:w-1/3 w-full mb-4">
          <vx-card class="mb-2" :title="
            $t(resources.Application.i18n) || resources.Application.name
          ">
            <vs-row vs-type="flex" vs-justify="center" class="mb-2">
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
                <vs-button type="border" color="primary" icon-pack="feather" icon="icon-plus" size="large"
                  @click="popUpApplication = true"></vs-button>
              </vs-col>
            </vs-row>
            <div class="vx-col w-full">
              <vs-table max-items="10" pagination :data="packageApplication">
                <template slot="thead">
                  <vs-th>{{
                      $t(resources.Name.i18n) || resources.Name.name
                  }}</vs-th>
                  <vs-th>{{
                      $t(resources.Options.i18n) || resources.Options.name
                  }}</vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].applicationName">{{
                        data[indextr].applicationName
                    }}</vs-td>
                    <vs-td>
                      <vx-tooltip :text="
                        $t(resources.Delete.i18n) || resources.Delete.name
                      ">
                        <a @click="deletePackageApplication(data[indextr])">
                          <feather-icon icon="XIcon" svgClasses="fill-current text-danger" class="w-5 h-5 mr-1">
                          </feather-icon>
                        </a>
                      </vx-tooltip>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </vx-card>
        </div>
        <div class="vx-col w-full">
          <vx-card class="mb-2" :title="$t(resources.Supplier.i18n) || resources.Supplier.name">
            <vs-row vs-type="flex" vs-justify="center" class="mb-2">
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
                <vs-button type="border" color="primary" icon-pack="feather" icon="icon-plus" size="large"
                  @click="popUpSupplier = true"></vs-button>
              </vs-col>
            </vs-row>
            <div class="vx-col w-full">
              <vs-table max-items="10" pagination :data="supplierPackage">
                <template slot="thead">
                  <vs-th>{{
                      $t(resources.Name.i18n) || resources.Name.name
                  }}</vs-th>
                  <vs-th>{{
                      $t(resources.Address.i18n) || resources.Address.name
                  }}</vs-th>
                  <vs-th>{{
                      $t(resources.Email.i18n) || resources.Email.name
                  }}</vs-th>
                  <vs-th>{{
                      $t(resources.Telephone.i18n) || resources.Telephone.name
                  }}</vs-th>
                  <vs-th>{{
                      $t(resources.Options.i18n) || resources.Options.name
                  }}</vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].supplierName">{{
                        data[indextr].supplierName
                    }}</vs-td>
                    <vs-td :data="data[indextr].supplierAddress">{{
                        data[indextr].supplierAddress
                    }}</vs-td>
                    <vs-td :data="data[indextr].supplierEmail">{{
                        data[indextr].supplierEmail
                    }}</vs-td>
                    <vs-td :data="data[indextr].supplierPhone">{{
                        data[indextr].supplierPhone
                    }}</vs-td>
                    <vs-td>
                      <vx-tooltip :text="
                        $t(resources.Delete.i18n) || resources.Delete.name
                      ">
                        <a @click="deleteSupplierPackage(data[indextr])">
                          <feather-icon icon="XIcon" svgClasses="fill-current text-danger" class="w-5 h-5 mr-1">
                          </feather-icon>
                        </a>
                      </vx-tooltip>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </vx-card>
        </div>
      </div>

      <vx-card class="mb-2" :title="$t(resources.Images.i18n) || resources.Images.name">
        <div class="vx-row mb-4">
          <div class="vx-col md:w-1/2 w-full mb-2">
            <label class="btn btn-primary">{{
                $t(resources.Attachment.i18n) || t(resources.Attachment.name)
            }}</label>
            <vs-input type="file" class="w-full" @change="onFileSelected" multiple />
          </div>
          <vs-button color="primary" class="mt-4" @click="getuploadForm()">{{
              $t(resources.Save.i18n) || t(resources.Save.name)
          }}</vs-button>
        </div>
        <div class="carousel-example">
          <swiper :options="swiperOption">
            <swiper-slide v-for="item in imageList" :key="item.id">
              <img class="responsive" :src="item.pathImage" :alt="item.packageName" />
              <a @click="deleteImage(item.id)">
                <feather-icon icon="TrashIcon" svgClasses="text-danger" class="w-9 h-9 mr-1"></feather-icon>
              </a>
            </swiper-slide>
            <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
            <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
          </swiper>
        </div>
      </vx-card>
    </div>

    <!-- detalle paquete crear -->
    <vs-popup fullscreen :title="$t(resources.Detail.i18n) || resources.Detail.name" :active.sync="popUpProductDetail">
      <div class="vx-row mb-base">
        <div class="vx-col w-full md:w-1/4">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
                $t(resources.Hotels.i18n) || resources.Hotels.name
            }}</label>
            <v-select v-model="packageDetailDto.hotelId" :clearable="true" :options="hotelOption"
              :reduce="data => data.hotelId" label="hotelName"
              v-on:input="getAccommodation(`${packageDetailDto.hotelId}`)" :hint="`${packageDetailDto.hotelId}`" />
          </div>
        </div>
        <div class="vx-col w-full md:w-1/4">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
                $t(resources.Accommodation.i18n) || resources.Accommodation.name
            }}</label>
            <v-select v-model="packageDetailDto.accommodationId" :clearable="true" :options="accommodationOption"
              :reduce="data => data.id" label="nameEs" v-on:input="
                getAccommodationDetail(`${packageDetailDto.accommodationId}`)
              " :hint="`${packageDetailDto.accommodationId}`" />
          </div>
        </div>
        <div class="vx-col w-full md:w-1/2 mb-4">
          <vs-divider color="success" class="mt-4 mb-2">
            <h5 class="text-success">
              {{
                  $t(resources.AdditionalInformation.i18n) ||
                  resources.AdditionalInformation.name
              }}
            </h5>
          </vs-divider>
          <div class="vx-col w-full">
            <p class="mb-2">
              {{
                  $t(alerts.MsgAccommodationInfo.i18n) ||
                  alerts.MsgAccommodationInfo.i18n
              }}&nbsp;{{
    $t(resources.AdultAmount.i18n) || resources.AdultAmount.name
}}: <strong>{{ accommodationDetail.adultAmount }}</strong>,
              {{
                  $t(resources.ChildAmount.i18n) || resources.ChildAmount.name
              }}: <strong>{{ accommodationDetail.childAmount }}</strong>,
              {{
                  $t(resources.InfantAmount.i18n) || resources.InfantAmount.name
              }}: <strong>{{ accommodationDetail.infantAmount }}</strong>.
            </p>
          </div>
        </div>
        <div class="vx-col w-full md:w-1/4">
          <vs-input class="w-full" icon-pack="feather" icon="icon-dollar-sign" icon-no-border type="number"
            :label="$t(resources.Price.i18n) || resources.Price.name" v-model="packageDetailDto.value" />
        </div>
        <div class="vx-col w-full mb-2 md:w-1/3">
          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full">
              <ul class="vx-col w-full mt-5">
                <li>
                  <vs-checkbox v-model="packageDetailDto.isCommissionRate">{{
                      $t(resources.Percent.i18n) || resources.Percent.name
                  }}</vs-checkbox>
                </li>
              </ul>
            </div>
            <div class="vx-col w-full md:w-1/2">
              <vs-input class="w-full" icon-pack="feather" icon="icon-dollar-sign" icon-no-border type="number" :label="
                $t(resources.Commission.i18n) || resources.Commission.name
              " v-model="packageDetailDto.commissionValue" />
            </div>
          </div>
        </div>
        <div class="vx-col w-full mb-2 md:w-1/3">
          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full">
              <ul class="vx-col w-full mt-5">
                <li>
                  <vs-checkbox v-model="packageDetailDto.isIboRate">{{
                      $t(resources.Percent.i18n) || resources.Percent.name
                  }}
                    - IBO</vs-checkbox>
                </li>
              </ul>
            </div>
            <div class="vx-col w-full md:w-1/2">
              <vs-input class="w-full" icon-pack="feather" icon="icon-dollar-sign" icon-no-border type="number" :label="
                `${$t(resources.Commission.i18n) ||
                resources.Commission.name} - IBO`
              " v-model="packageDetailDto.iboValue" />
            </div>
          </div>
        </div>
      </div>
      <div class="vx-row mb-base">
        <div class="vx-col w-full md:w-3/4">
          <div class="vx-row">
            <div class="vx-col w-full md:w-1/4">
              <vs-input class="w-full" icon-pack="feather" icon="icon-hash" icon-no-border type="number" :label="
                $t(resources.AdultAmount.i18n) || resources.AdultAmount.name
              " v-model="packageDetailDto.adultAmount" />
            </div>
            <div class="vx-col w-full md:w-3/4">
              <label class="vs-input--label">{{
                  $t(resources.Age.i18n) || resources.Age.name
              }}</label>
              <vs-slider color="success" v-model="adultAge" />
            </div>
          </div>s
          <div class="vx-row">
            <div class="vx-col w-full md:w-1/4">
              <vs-input class="w-full" icon-pack="feather" icon="icon-hash" icon-no-border type="number" :label="
                $t(resources.ChildAmount.i18n) || resources.ChildAmount.name
              " v-model="packageDetailDto.childAmount" />
            </div>
            <div class="vx-col w-full md:w-3/4">
              <vs-slider color="success" v-model="childAge" />
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full md:w-1/4">
              <vs-input class="w-full" icon-pack="feather" icon="icon-hash" icon-no-border type="number" :label="
                $t(resources.InfantAmount.i18n) || resources.InfantAmount.name
              " v-model="packageDetailDto.infantAmount" />
            </div>
            <div class="vx-col w-full md:w-3/4">
              <vs-slider color="success" v-model="infantAge" />
            </div>
          </div>
        </div>
        <div class="vx-col w-full md:w-1/4">
          <label class="vs-input--label">{{
              $t(resources.CancellationPolicy.i18n) ||
              resources.CancellationPolicy.name
          }}</label>
          <vs-textarea rows="4" v-model="packageDetailDto.cancellationPolicy" />
        </div>
      </div>

      <vs-divider position="center"><strong>{{
          $t(resources.Date.i18n) || resources.Date.name
      }}</strong></vs-divider>
      <div class="vx-row">
        <div class="vx-col md:w-1/6 w-full mb-4 mt-5">
          <ul class="centerx">
            <li>
              <vs-checkbox v-model="isRangeDate">¿Es un rango de fechas?</vs-checkbox>
            </li>
          </ul>
        </div>
        <div class="vx-col md:w-1/6 w-full mb-4" v-if="isRangeDate">
          <div class="vx-col w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-hash" number :label="
              $t(resources.AmountDays.i18n) || resources.AmountDays.name
            " v-model="amountDays" />
          </div>
        </div>
      </div>

      <div class="vx-row mb-base">
        <div class="vx-col w-full md:w-1/6">
          <label class="vs-input--label">{{
              $t(resources.StartDate.i18n) || resources.StartDate.name
          }}</label>
          <datepicker :language="languages[language]" format="d MMMM yyyy" v-model="startDate"></datepicker>
        </div>
        <div class="vx-col w-full md:w-1/6">
          <label class="vs-input--label">{{
              $t(resources.EndDate.i18n) || resources.EndDate.name
          }}</label>
          <datepicker :language="languages[language]" format="d MMMM yyyy" v-model="endDate"></datepicker>
        </div>
        <div class="vx-col w-full md:w-1/6 mt-4">
          <vs-button color="primary" type="filled" @click="addDates()">
            {{ $t(resources.Add.i18n) || resources.Add.name }}
          </vs-button>
        </div>
      </div>

      <div class="vx-row mb-base">
        <div class="vx-col w-full md:w-1/2 mb-2">
          <vs-table :data="datesList">
            <template slot="thead">
              <vs-th>{{
                  $t(resources.StartDate.i18n) || resources.StartDate.name
              }}</vs-th>
              <vs-th>{{
                  $t(resources.EndDate.i18n) || resources.EndDate.name
              }}</vs-th>
              <vs-th>{{
                  $t(resources.Options.i18n) || resources.Options.name
              }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].startDate">{{
                    data[indextr].startDate
                }}</vs-td>
                <vs-td :data="data[indextr].endDate">{{
                    data[indextr].endDate
                }}</vs-td>
                <vs-td>
                  <vx-tooltip :text="
                    $t(resources.Disabled.i18n) || resources.Disabled.name
                  " class="w-5 h-5 mr-1">
                    <a @click="deleteDateList(data[indextr])">
                      <feather-icon icon="XIcon" svgClasses="fill-current text-danger" class="w-5 h-5 mr-1">
                      </feather-icon>
                    </a>
                  </vx-tooltip>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>

      <div class="vx-col w-full md:w-1/6 mb-2">
        <vs-button color="primary" type="filled" @click="createDetailPackage()">
          {{ $t(resources.Save.i18n) || resources.Save.name }}
        </vs-button>
      </div>
    </vs-popup>
    <!-- detalle paquete update -->
    <vs-popup :title="$t(resources.Detail.i18n) || resources.Detail.name" :active.sync="popUpProductDetailUpdate">
      <div class="vx-row mb-base">
        <div class="vx-col w-full">
          <label class="vs-input--label">{{
              $t(resources.StartDate.i18n) || resources.StartDate.name
          }}</label>
          <datepicker :language="languages[language]" format="d MMMM yyyy" v-model="packageDetailDto.startDate"
            readonly></datepicker>
        </div>
        <div class="vx-col w-full">
          <label class="vs-input--label">{{
              $t(resources.EndDate.i18n) || resources.EndDate.name
          }}</label>
          <datepicker readonly :language="languages[language]" format="d MMMM yyyy" v-model="packageDetailDto.endDate">
          </datepicker>
        </div>
        <div class="vx-col w-full">
          <vs-input readonly class="w-full" icon-pack="feather" icon="icon-hash" icon-no-border type="number" :label="
            $t(resources.AdultAmount.i18n) || resources.AdultAmount.name
          " v-model="packageDetailDto.adultAmount" />
        </div>
        <div class="vx-col w-full">
          <vs-input readonly class="w-full" icon-pack="feather" icon="icon-hash" icon-no-border type="number" :label="
            $t(resources.ChildAmount.i18n) || resources.ChildAmount.name
          " v-model="packageDetailDto.childAmount" />
        </div>
        <div class="vx-col w-full">
          <vs-input readonly class="w-full" icon-pack="feather" icon="icon-hash" icon-no-border type="number" :label="
            $t(resources.InfantAmount.i18n) || resources.InfantAmount.name
          " v-model="packageDetailDto.infantAmount" />
        </div>
        <div class="vx-col w-full mb-2">
          <vs-input readonly class="w-full" icon-pack="feather" icon="icon-dollar-sign" icon-no-border type="number"
            :label="$t(resources.Price.i18n) || resources.Price.name" v-model="packageDetailDto.value" />
        </div>

        <div class="vx-col w-full">
          <div class="vx-row">
            <div class="vx-col md:w-1/4 w-full">
              <ul class="vx-col w-full mt-5">
                <li>
                  <vs-checkbox v-model="packageDetailDto.isCommissionRate">{{
                      $t(resources.Percent.i18n) || resources.Percent.name
                  }}</vs-checkbox>
                </li>
              </ul>
            </div>
            <div class="vx-col w-full md:w-3/4">
              <vs-input readonly class="w-full" icon-pack="feather" icon="icon-dollar-sign" icon-no-border type="number"
                :label="
                  $t(resources.Commission.i18n) || resources.Commission.name
                " v-model="packageDetailDto.commissionValue" />
            </div>
          </div>
        </div>
        <div class="vx-col w-full mb-2">
          <div class="vx-row">
            <div class="vx-col md:w-1/4 w-full">
              <ul class="vx-col w-full mt-5">
                <li>
                  <vs-checkbox v-model="packageDetailDto.isIboRate">{{
                      $t(resources.Percent.i18n) || resources.Percent.name
                  }}
                    - IBO</vs-checkbox>
                </li>
              </ul>
            </div>
            <div class="vx-col w-full md:w-3/4">
              <vs-input readonly class="w-full" icon-pack="feather" icon="icon-dollar-sign" icon-no-border type="number"
                :label="
                  `${$t(resources.Commission.i18n) ||
                  resources.Commission.name} - IBO`
                " v-model="packageDetailDto.iboValue" />
            </div>
          </div>
        </div>
        <div class="vx-col w-full">
          <div class="vx-col w-full">
            <vs-input readonly class="w-full" icon-pack="feather" icon="icon-dollar-sign" icon-no-border
              :label="$t(resources.Hotels.i18n) || resources.Hotels.name" v-model="packageDetailDto.hotelName" />
          </div>
        </div>
        <div class="vx-col w-full">
          <div class="vx-col w-full">
            <vs-input readonly class="w-full" icon-pack="feather" icon="icon-dollar-sign" icon-no-border :label="
              $t(resources.Accommodation.i18n) || resources.Accommodation.name
            " v-model="packageDetailDto.accommodationName" />
          </div>
        </div>
        <div class="vx-col w-full">
          <label>{{
              $t(resources.CancellationPolicy.i18n) ||
              resources.CancellationPolicy.name
          }}</label>
          <vs-textarea rows="5" readonly v-model="packageDetailDto.cancellationPolicy" />
        </div>
      </div>
    </vs-popup>

    <!-- producto servicio -->
    <vs-popup :title="$t(resources.Service.i18n) || resources.Service.name" :active.sync="popUpProductService">
      <div class="vx-row mb-base">
        <div class="vx-col w-full">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
                $t(resources.Service.i18n) || resources.Service.name
            }}</label>
            <v-select v-model="productServiceDto.serviceId" :clearable="true" :options="serviceOption"
              :reduce="data => data.id" label="nameEs" />
          </div>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2 mt-5">
          <vs-button color="primary" type="filled" @click="createProductService()">
            {{ $t(resources.Create.i18n) || resources.Create.name }}
          </vs-button>
        </div>
      </div>
      <div class="vx-col w-full">
        <vs-table :data="serviceProductOption">
          <template slot="thead">
            <vs-th>{{ $t(resources.Name.i18n) || resources.Name.name }}</vs-th>
            <vs-th>{{
                $t(resources.Options.i18n) || resources.Options.name
            }}</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].serviceName">{{
                  data[indextr].serviceName
              }}</vs-td>
              <vx-tooltip :text="$t(resources.View.i18n) || resources.View.name">
                <a @click="deleteProductService(data[indextr])">
                  <feather-icon icon="XIcon" svgClasses="fill-current text-danger" class="w-5 h-5 mr-1"></feather-icon>
                </a>
              </vx-tooltip>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </vs-popup>

    <!-- Settings -->
    <vs-popup :title="$t(resources.Detail.i18n) || resources.Detail.name" :active.sync="popUpConfigurationPackage">
      <div class="vx-row mb-base">
        <div class="vx-col w-full">
          <label class="vs-input--label">{{
              $t(resources.CurrencyCode.i18n) || resources.CurrencyCode.name
          }}</label>
          <v-select v-model="packageConfigurationDto.currencyId" :clearable="true" :options="currencyType"
            :reduce="info => info.id" label="display" />
        </div>
        <div class="vx-col w-full">
          <label class="vs-input--label">{{
              $t(resources.CountryOperation.i18n) ||
              resources.CountryOperation.name
          }}</label>
          <v-select v-model="packageConfigurationDto.countryOperationId" :clearable="true" :options="countryOption"
            :reduce="item => item.countryId" label="countryName" />
        </div>
        <div class="vx-col w-full">
          <ul class="vx-col w-full" style="margin-top: 20px">
            <li>
              <vs-checkbox v-model="packageConfigurationDto.isMain">{{
                  $t(resources.Main.i18n) || resources.Main.name
              }}</vs-checkbox>
            </li>
          </ul>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2 mt-5">
          <vs-button color="primary" type="filled" @click="createPackageConfiguration()">
            {{ $t(resources.Save.i18n) || resources.Save.name }}
          </vs-button>
        </div>
      </div>
    </vs-popup>

    <!-- Categories -->
    <vs-popup :title="$t(resources.Category.i18n) || resources.Category.name" :active.sync="popUpCategory">
      <div class="vx-row mb-base">
        <div class="vx-col w-full">
          <label class="vs-input--label">{{
              $t(resources.Name.i18n) || resources.Name.name
          }}</label>
          <v-select v-model="category.categoryId" :clearable="true" :options="categoryOption" :reduce="item => item.id"
            label="nameEs" />
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2 mt-5">
          <vs-button color="primary" type="filled" @click="createPackageCategory()">
            {{ $t(resources.Save.i18n) || resources.Save.name }}
          </vs-button>
        </div>
      </div>
    </vs-popup>

    <!-- Application -->
    <vs-popup :title="$t(resources.Application.i18n) || resources.Application.name" :active.sync="popUpApplication">
      <div class="vx-row mb-base">
        <div class="vx-col w-full">
          <label class="vs-input--label">{{
              $t(resources.Name.i18n) || resources.Name.name
          }}</label>
          <v-select v-model="application.applicationId" :clearable="true" :options="applicationOption"
            :reduce="item => item.id" label="name" />
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2 mt-5">
          <vs-button color="primary" type="filled" @click="createApplication()">
            {{ $t(resources.Save.i18n) || resources.Save.name }}
          </vs-button>
        </div>
      </div>
    </vs-popup>

    <!-- Supplier -->
    <vs-popup :title="$t(resources.Supplier.i18n) || resources.Supplier.name" :active.sync="popUpSupplier">
      <div class="vx-row mb-base">
        <div class="vx-col w-full">
          <label class="vs-input--label">{{
              $t(resources.Name.i18n) || resources.Name.name
          }}</label>
          <v-select v-model="supplierId" :clearable="true" :options="supplierOption" :reduce="item => item.id"
            label="name" />
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2 mt-5">
          <vs-button color="primary" type="filled" @click="createSupplierPackage()">
            {{ $t(resources.Save.i18n) || resources.Save.name }}
          </vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import axios from "axios";
import alerts from "@/i18n/alerts.js";
import resources from "@/i18n/resources.js";
import database from "@/assets/resources/enums/database.json";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import moment from "moment";
import "swiper/dist/css/swiper.min.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";

export default {
  components: {
    vSelect,
    Datepicker,
    swiper,
    swiperSlide
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 30,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      },
      alerts: alerts,
      resources: resources,
      database: database,
      languages: lang,
      userSession: JSON.parse(localStorage.getItem("userInfo")).email,
      packageId: 0,
      packageDetailDto: {
        cancellationPolicy: "Sujeto a verificación"
      },
      packageDto: {},
      productServiceDto: {},
      serviceOption: [],
      serviceProductOption: [],
      popUpProductDetail: false,
      popUpProductService: false,
      popUpCategory: false,
      popUpApplication: false,
      popUpProductDetailUpdate: false,
      application: {},
      applicationOption: [],
      hotelOption: [],
      accommodationOption: [],
      destinationList: [],
      stateCountry: [],
      countryList: [],
      details: [],
      packageConfigurationOption: [],
      packageCategoryOption: [],
      packageApplication: [],
      categoryOption: [],
      supplierPackage: [],
      category: {},
      currencyType: [],
      accommodationDetail: { adultAmount: 0, childAmount: 0, infantAmount: 0 },
      countryOption: [],
      popUpConfigurationPackage: false,
      packageConfigurationDto: {},
      language: this.$i18n.locale,
      packageImage: [],
      imageList: [],
      supplierId: "",
      supplierOption: [],
      popUpSupplier: false,
      datesList: [],
      startDate: "",
      endDate: "",
      adultAge: [13, 50],
      childAge: [3, 12],
      infantAge: [0, 2],
      isRangeDate: false,
      amountDays: 0
    };
  },
  async created() {
    this.$vs.loading();

    this.packageId = this.$route.params.id;

    await this.getCountry();
    await this.getHotel();
    await this.getCategory();
    await this.getApplication();
    await this.getCurrency();
    await this.getCountryOperation();
    await this.getSupplier();
    if (this.packageId != 0) {
      await this.getService();
      await this.get();
      await this.getDetail();
      await this.getImages();
      await this.getStateByCountry(this.packageDto.countryId);
      await this.getCityByState(this.packageDto.stateId);
      await this.getPackageConfiguration();
      await this.getPackageCategory();
      await this.getPackageApplication();
      await this.getSupplierPackage();
    }

    this.$vs.loading.close();
  },
  methods: {
    handleSearch(searching) {
      if (searching.length >= 3) {
        this.details = this.details.filter(
          x =>
            x.accommodationName
              .toLowerCase()
              .includes(searching.toLowerCase()) ||
            x.hotelName.toLowerCase().includes(searching.toLowerCase())
        );
      }
    },

    addDates() {
      if (
        this.startDate != null &&
        this.startDate != null &&
        this.endDate != null &&
        this.endDate != null && moment(this.startDate)
      ) {
        let _startDate = moment(this.startDate);
        let _endDate = moment(this.endDate);
        let _dateCompare = moment().add(-1, 'd');

        if (_startDate.isBefore(_dateCompare) || _endDate.isBefore(_dateCompare)) {
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: this.$i18n.t("MsgItineraryBeforeNow"),
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
          return;
        }

        if (_startDate.isAfter(_endDate)) {
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: this.$i18n.t("MsgStartDateAfterEndDate"),
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
          return;
        }

        let _data = this.datesList.filter(
          x =>
            x.startDate == moment(this.startDate).format("YYYY-MM-DD") &&
            x.endDate == moment(this.endDate).format("YYYY-MM-DD")
        );

        if (_data.length == 0) {
          this.datesList.push({
            startDate: moment(this.startDate).format("YYYY-MM-DD"),
            endDate: moment(this.endDate).format("YYYY-MM-DD")
          });
        }
        this.startDate = "";
        this.endDate = "";
      }
    },

    deleteDateList(item) {
      let _data = this.datesList.filter(
        x =>
          x.startDate == moment(item.startDate).format("YYYY-MM-DD") &&
          x.endDate == moment(item.endDate).format("YYYY-MM-DD")
      );

      if (_data != undefined && _data != null) {
        let _index = this.datesList.findIndex(
          x =>
            x.startDate == moment(item.startDate).format("YYYY-MM-DD") &&
            x.endDate == moment(item.endDate).format("YYYY-MM-DD")
        );
        if (_index > -1) this.datesList.splice(_index, 1);
      }
    },

    openCreateDetail() {
      this.packageDetailDto = { cancellationPolicy: "Sujeto a verificación"};
      this.popUpProductDetail = true;
    },

    async get() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Package/GetbyId/${this.packageId}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.packageDto = {};
          } else {
            result.data.outOfDate = moment(result.data.outOfDate).format("LL");
            this.packageDto = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getDetail() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}PackageDetail/GetbyId/${this.language}/${this.packageId}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.details = [];
          } else this.details = result.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getService() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Service`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.serviceOption = [];
          } else {
            this.serviceOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getImages() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}PackageImage/GetByPackage/${this.packageId}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.imageList = [];
          } else {
            this.imageList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    showPopUpDetail(data) {
      this.popUpProductDetailUpdate = true;
      this.packageDetailDto = data;
    },

    async showPopUpService(data) {
      this.popUpProductService = true;
      this.productServiceDto.entityId = 5;
      this.productServiceDto.identifier = data.packageDetailId;
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}ProductService/Get/${this.productServiceDto.identifier}/5`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.serviceProductOption = [];
          } else {
            this.serviceProductOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getStateByCountry(item) {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT
          }State/GetByCountry/${this.$i18n.locale.toUpperCase()}/${item}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) this.stateCountry = result.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCityByState(item) {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT
          }City/GetByState/${this.$i18n.locale.toUpperCase()}/${item}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) this.destinationList = result.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCountry() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT
          }Country/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.countryList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    validInformation() {
      this.$validator.validateAll().then(async result => {
        if (result) {
          await this.createPackage();
        } else {
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: this.$i18n.t("MsgRequiredField"),
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      });
    },

    async createPackage() {
      let _requestName = "";
      this.packageDto.language = this.$i18n.locale.toUpperCase();
      if (
        this.packageDto.outOfDate != null &&
        this.packageDto.outOfDate != ""
      ) {
        this.packageDto.outOfDate = moment(this.packageDto.outOfDate).format(
          "YYYY-MM-DD"
        );
      }

      this.packageDto.createdBy = this.userSession;
      this.packageDto.type = 1;

      if (this.packageId != null && this.packageId != 0 && this.packageId != "")
        _requestName = "/Update";

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Package${_requestName}`,
        data: this.packageDto,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotSaved"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });

            if (_requestName != "/Update") {
              this.packageId = result.data.data.packageId;

              this.$router.push(
                `/management/package/detail/${result.data.data.packageId}`
              );
            }
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getHotel() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Accommodation/GetHotel`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.hotelOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async createDetailPackage() {
      this.packageDetailDto.packageId = this.packageId;
      this.packageDetailDto.language = this.$i18n.locale.toUpperCase();
      this.packageDetailDto.createdBy = this.userSession;
      this.packageDetailDto.adultAge = this.adultAge;
      this.packageDetailDto.infantAge = this.infantAge;
      this.packageDetailDto.childAge = this.childAge;
      this.packageDetailDto.isRangeDate = this.isRangeDate;
      this.packageDetailDto.amountDays = this.amountDays;

      this.packageDetailDto.startDate = this.datesList.map(x => {
        return x.startDate;
      });

      this.packageDetailDto.endDate = this.datesList.map(x => {
        return x.endDate;
      });

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}PackageDetail`,
        data: this.packageDetailDto,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            await this.getDetail();
          }
          this.popUpProductDetail = false;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async updateDetailPackage() {
      this.packageDetailDto.language = this.$i18n.locale.toUpperCase();
      this.packageDetailDto.createdBy = this.userSession;
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}packageDetail/Update`,
        data: this.packageDetailDto,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
          this.popUpProductDetail = false;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async createProductService() {
      this.productServiceDto.language = this.$i18n.locale.toUpperCase();
      this.productServiceDto.createdBy = this.userSession;
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}ProductService`,
        data: this.productServiceDto,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.serviceProductOption.push(result.data);
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
          this.popUpProductDetail = false;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getAccommodation(data) {
      this.$vs.loading();
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Accommodation/ByHotelId/${data}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.accommodationOption = result.data;
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.accommodationOption = [];
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async getAccommodationDetail(data) {
      this.accommodationDetail = this.accommodationOption.find(
        x => x.id == data
      );
    },

    async getPackageConfiguration() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}PackageConfiguration/GetbyPackageId/${this.packageId}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.packageConfigurationOption = [];
          } else {
            this.packageConfigurationOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getPackageCategory() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}PackageCategory/GetbyId/${this.packageId}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.packageCategoryOption = [];
          } else {
            this.packageCategoryOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCategory() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Category`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.categoryOption = [];
          } else {
            this.categoryOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async createPackageConfiguration() {
      this.packageConfigurationDto.language = this.$i18n.locale.toUpperCase();
      this.packageConfigurationDto.createdBy = this.userSession;
      this.packageConfigurationDto.PackageId = this.packageId;
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}PackageConfiguration`,
        data: this.packageConfigurationDto,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.packageConfigurationOption.push(result.data);
          }
          this.popUpConfigurationPackage = false;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async deletePackageConfiguration(_data) {
      if (this.packageConfigurationOption.find(x => x.id == _data.id)) {
        let _index = this.packageConfigurationOption.findIndex(
          x => x.id == _data.id
        );
        if (_index > -1) this.packageConfigurationOption.splice(_index, 1);
      }

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}PackageConfiguration/Disabled`,
        data: _data,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async deletePackageCategory(_data) {
      if (this.packageCategoryOption.find(x => x.id == _data.id)) {
        let _index = this.packageCategoryOption.findIndex(
          x => x.id == _data.id
        );
        if (_index > -1) this.packageCategoryOption.splice(_index, 1);
      }
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}PackageCategory/Disabled`,
        data: _data,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async deletePackageApplication(_data) {
      if (this.packageApplication.find(x => x.id == _data.id)) {
        let _index = this.packageApplication.findIndex(x => x.id == _data.id);
        if (_index > -1) this.packageApplication.splice(_index, 1);
      }
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}PackageApplication/Disabled`,
        data: _data,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async deleteSupplierPackage(_data) {
      if (this.supplierPackage.find(x => x.id == _data.id)) {
        let _index = this.supplierPackage.findIndex(x => x.id == _data.id);
        if (_index > -1) this.supplierPackage.splice(_index, 1);
      }
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}SupplierPackage/Disabled`,
        data: _data,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async deleteImage(id) {
      this.$vs.loading();

      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT
          }PackageImage/UpdateStatus/${this.$i18n.locale.toUpperCase()}/${id}/false`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            await this.getImages();
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async createPackageCategory() {
      this.category.language = this.$i18n.locale.toUpperCase();
      this.category.createdBy = this.userSession;
      this.category.PackageId = this.packageId;
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}PackageCategory`,
        data: this.category,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.packageCategoryOption.push(result.data);
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
          this.popUpCategory = false;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async createSupplierPackage() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}SupplierPackage`,
        data: {
          supplierId: this.supplierId,
          language: this.$i18n.locale.toUpperCase(),
          createdBy: this.userSession,
          PackageId: this.packageId
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (result.data) {
            await this.getSupplierPackage();
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
          this.popUpCategory = false;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCurrency() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT
          }Currency/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) this.currencyType = result.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCountryOperation() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT
          }CountryOperation/GetPccCountry/${this.$i18n.locale.toUpperCase()}/1`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.warningNotify();
            this.countryOption = [];
          } else {
            this.countryOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    warningNotify() {
      this.$vs.notify({
        title: this.$i18n.t("Alert"),
        text: this.$i18n.t("MsgNotFoundInfo"),
        color: "warning",
        iconPack: "feather",
        position: "top-right",
        icon: "icon-x-circle"
      });
    },

    async getApplication() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Application`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.applicationOption = [];
          } else {
            this.applicationOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getSupplier() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Supplier`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data.length > 0) {
            this.supplierOption = result.data;
          } else {
            this.supplierOption = [];
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async createApplication() {
      this.application.language = this.$i18n.locale.toUpperCase();
      this.application.createdBy = this.userSession;
      this.application.packageId = this.packageId;
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}PackageApplication`,
        data: this.application,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.packageApplication.push(result.data);
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
          this.popUpApplication = false;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getPackageApplication() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}PackageApplication/GetbyPackageId/${this.packageId}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.packageApplication = [];
          } else {
            this.packageApplication = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getSupplierPackage() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}SupplierPackage/GetByPackageId/${this.packageId}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.supplierPackage = [];
          } else {
            this.supplierPackage = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async deleteDetailPackage(_data) {
      if (this.details.find(x => x.id == _data.id)) {
        let _index = this.details.findIndex(x => x.id == _data.id);
        if (_index > -1) this.details.splice(_index, 1);
      }
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}PackageDetail/Disabled`,
        data: _data,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async deleteProductService(_data) {
      if (this.serviceProductOption.find(x => x.id == _data.id)) {
        let _index = this.serviceProductOption.findIndex(x => x.id == _data.id);
        if (_index > -1) this.serviceProductOption.splice(_index, 1);
      }
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}ProductService/Disabled`,
        data: _data,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    onFileSelected(event) {
      event.target.files.forEach(element => {
        this.packageImage.push(element);
      });
    },

    async getuploadForm() {
      this.$vs.loading();

      this.comments = [];

      let formData = new FormData();
      formData.set("PackageId", this.packageId);
      formData.set("CreatedBy", this.userSession);
      formData.set("Language", this.$i18n.locale.toUpperCase());
      if (this.packageImage != null) {
        this.packageImage.forEach(element => {
          formData.append(`Files`, element, element.name);
        });
      }

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}PackageImage`,
        data: formData,
        headers: {
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
          "Content-Type": "multipart/form-data"
        }
      }).then(
        async result => {
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.packageImage = [];
            await this.getImages();
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    }
  }
};
</script>
