<template>
  <div>
    <div class="vx-row">
      <vs-row vs-type="flex" vs-justify="center" class="mb-4">
        <img
          src="../../assets/images/logo/BlockPay.png"
          alt
          class="imag-blur"
          style="height: 80px"
        />
      </vs-row>
      <div class="vx-col w-full">
        <div class="vx-row">
          <div
            class="vx-col w-full md:w-1/5 mb-4"
            :key="indextr"
            v-for="(account, indextr) in accountOption"
          >
            <vx-card class="text-center cursor-pointer">
              <vs-avatar
                v-if="account.typeAccountId == database.typeAccount.MainAccount"
                icon="account_balance"
                svgClasses="h-10 w-10"
                size="large"
                color="rgb(23, 137, 186)"
              />
              <vs-avatar
                v-else-if="
                  account.typeAccountId == database.typeAccount.PositiveBalance
                "
                icon="beenhere"
                size="large"
                color="rgb(23, 137, 186)"
              />
              <vs-avatar
                v-else-if="account.typeAccountId == database.typeAccount.Saving"
                icon="flight_takeoff"
                size="large"
                color="rgb(23, 137, 186)"
              />
              <vs-avatar
                v-else
                icon="account_balance_wallet"
                size="large"
                color="rgb(23, 137, 186)"
              />
              <h4 class="mb-2 text-primary">
                <strong>{{ account.name }}</strong>
              </h4>
              <h1 class="text-success">
                <strong
                  >{{ account.currencyCode }} {{ account.balance }}</strong
                >
              </h1>
              <p class="mb-2">
                {{ $t(resources.Type.i18n) || resources.Type.name }}:
                {{ account.typeAccountName }}
              </p>
            </vx-card>
          </div>
        </div>
      </div>
      <div class="vx-col w-full">
        <vx-card class="mb-2">
          <vs-row vs-type="flex" vs-justify="center">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="2"
            >
              <vs-button
                radius
                color="primary"
                icon-pack="material-icons"
                icon="send"
                size="large"
                @click="popupSend = true"
              ></vs-button>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="2"
            >
              <vs-button
                radius
                color="warning"
                icon-pack="material-icons"
                icon="edit"
                size="large"
                @click="popupEdit = true"
              ></vs-button>
            </vs-col>
          </vs-row>
          <vs-row vs-type="flex" vs-justify="center" class="mb-4">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="2"
            >
              <p>
                {{
                  $t(resources.SendMoney.i18n) || $t(resources.SendMoney.name)
                }}
              </p>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="2"
            >
              <p>
                {{ $t(resources.Update.i18n) || $t(resources.Update.name) }}
              </p>
            </vs-col>
          </vs-row>
          <h4 class="mb-4">
            {{ $t(resources.Filters.i18n) || resources.Filters.name }}
          </h4>
          <div class="vx-row">
            <div class="vx-col md:w-1/4 w-full mb-2">
              <div class="vx-col w-full">
                <label class="vs-input--label">{{
                  $t(resources.AccountNumber.i18n) || resources.Type.name
                }}</label>
                <v-select
                  v-model="accountId"
                  :clearable="true"
                  :options="accountOption"
                  :reduce="item => item.id"
                  label="nameWithNumber"
                  v-on:input="getActivities(`${accountId}`)"
                  :hint="`${accountId}`"
                />
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
    <div id="ag-grid-demo">
      <vx-card
        :title="$t(resources.Activities.i18n) || resources.Activities.name"
      >
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center">
          <!-- ITEMS PER PAGE -->
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{
                    currentPage * paginationPageSize - (paginationPageSize - 1)
                  }}
                  -
                  {{
                    activitiesList.length - currentPage * paginationPageSize > 0
                      ? currentPage * paginationPageSize
                      : activitiesList.length
                  }}
                  of {{ activitiesList.length }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
              <vs-dropdown-menu>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <div
            class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
          >
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              :placeholder="$t(resources.Search.i18n) || resources.Search.name"
            />
            <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">
              {{ $t(resources.ExportAs.i18n) || resources.ExportAs.name }}
              CVS
            </vs-button>
          </div>
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="activitiesList"
          rowSelection="single"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          @row-selected="onRowSelected"
        ></ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
    <vs-popup
      :title="$t(resources.Update.i18n) || resources.Update.i18n"
      :active.sync="popupEdit"
    >
      <div class="vx-row">
        <div class="vx-col w-full md:w-1/2 mb-2">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.AccountNumber.i18n) || resources.Type.name
            }}</label>
            <v-select
              v-model="accountIdEdit"
              :clearable="false"
              :options="accountOption"
              :reduce="item => item.id"
              label="nameWithNumber"
              v-on:input="getAccountName(`${accountIdEdit}`)"
              :hint="`${accountIdEdit}`"
              name="accountName"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('accountName')"
              >{{ errors.first("accountName") }}</span
            >
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-user"
              :label="$t(resources.Name.i18n) || resources.Name.name"
              v-model="accountName"
              maxlength="20"
              v-validate="{ required: false, regex: /^([a-zA-Z0-9 ]+)$/ }"
              name="name"
            />
          </div>
          <span class="text-danger text-sm" v-show="errors.has('name')">{{
            errors.first("name")
          }}</span>
        </div>
        <div class="vx-col mb-base" style="text-align: right">
          <vs-button color="primary" type="filled" @click="updateAccount()">
            {{ $t(resources.Save.i18n) || resources.Save.name }}
          </vs-button>
        </div>
      </div>
    </vs-popup>
    <vs-popup
      :title="$t(resources.Description.i18n) || resources.Description.i18n"
      :active.sync="popupDetail"
    >
      <h1 class="text-center text-primary font-bold mb-base">
        {{ $t(resources.Answer.i18n) || resources.Answer.i18n }}:
      </h1>
      <p class="text-center mb-base">{{ rowSelect.observation }}</p>
      <vs-button
        v-if="rowSelect.typeActivityId == 16"
        type="filled"
        class="mt-2"
        icon-pack="feather"
        icon="icon-download"
        @click="downloadPDF(rowSelect)"
      >
        {{ $t(resources.DownloadPDF.i18n) || resources.DownloadPDF.name }}
      </vs-button>
    </vs-popup>
    <vs-popup
      :title="$t(resources.SendMoney.i18n) || resources.SendMoney.i18n"
      :active.sync="popupSend"
    >
      <vs-row vs-type="flex" vs-justify="center" class="mb-base">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center">
          <img
            src="../../assets/images/logo/BlockpayLogo.png"
            alt
            class="imag-blur"
          />
        </vs-col>
      </vs-row>
      <vs-divider class="mb-base">
        <h4>
          <strong>{{
            $t(resources.Transfer.i18n) || resources.Transfer.name
          }}</strong>
        </h4>
      </vs-divider>
      <div
        class="vx-row"
        v-if="currentDate.getHours() >= 14 && currentDate.getDay() == 3"
      >
        <h4 class="font-semibold mt-2 mb-base ml-3 text-danger text-center">
          {{
            $t(alerts.MsgNotTransferEwallet.i18n) ||
              alerts.MsgNotTransferEwallet.name
          }}.
        </h4>
      </div>
      <div class="vx-row" v-else>
        <div class="vx-col w-full mt-2 mb-2">
          <ul class="centerx">
            <li>
              <vs-checkbox v-model="isPayout">{{
                $t(resources.SendMoneyEwallet.i18n) ||
                  resources.SendMoneyEwallet.name
              }}</vs-checkbox>
            </li>
          </ul>
        </div>
        <div class="vx-col w-full mt-2 mb-4">
          <ul class="centerx">
            <li>
              <vs-checkbox v-model="isMasspay">{{
                $t(resources.SendMoneyMasspay.i18n) || resources.SendMoneyMasspay.name
              }}</vs-checkbox>
            </li>
          </ul>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full md:w-1/2 mb-2">
          <div class="vx-col w-full">
            <label class="vs-input--label"
              >{{ $t(resources.Origin.i18n) || resources.Origin.name }} -
              {{
                $t(resources.AccountNumber.i18n) || resources.AccountNumber.name
              }}</label
            >
            <v-select
              v-model="accountId"
              :clearable="true"
              :options="accountOption"
              :reduce="item => item.id"
              label="nameWithNumber"
            />
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full mb-2" v-if="!isPayout && !isMasspay">
          <div class="vx-col w-full">
            <label class="vs-input--label"
              >{{
                $t(resources.Destination.i18n) || resources.Destination.name
              }}
              -
              {{
                $t(resources.AccountNumber.i18n) || resources.AccountNumber.name
              }}</label
            >
            <v-select
              id="accountDestination"
              @keyup.native="getByText('accountDestination')"
              v-model="accountDestination"
              :clearable="true"
              :options="accountDestinationList"
              :reduce="item => item.id"
              label="nameWithNumber"
              name="destination"
              v-validate="'required'"
              v-on:input="getContactInfo(`${accountId}`)"
              :hint="`${accountId}`"
            />
            <span
              class="text-danger text-sm"
              v-show="errors.has('destination')"
              >{{ errors.first("destination") }}</span
            >
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full mb-2" v-if="!isPayout && !isMasspay">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-user"
              :label="$t(resources.Name.i18n) || resources.Name.name"
              v-model="contactName"
              disabled
            />
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full mb-2" v-if="!isPayout && !isMasspay">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-user"
              :label="$t(resources.Email.i18n) || resources.Email.name"
              v-model="contactEmail"
              disabled
            />
          </div>
        </div>
        <div v-if="isPayout && !isMasspay" class="vx-col md:w-1/2 w-full mb-2">
          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-dollar-sign"
                  type="number"
                  :label="
                    `
                    ${$t(resources.UserName.i18n) ||
                      resources.UserName.name} Ewallet`
                  "
                  v-model="accountDestination"
                  v-validate="'required'"
                  name="amount"
                />
              </div>
              <span class="text-danger text-sm" v-show="errors.has('amount')">{{
                errors.first("amount")
              }}</span>
            </div>
          </div>
        </div>
        <div v-if="isMasspay && !isPayout" class="vx-col md:w-1/2 w-full mb-2">
          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-dollar-sign"
                  :label="
                    `
                    ${$t(resources.UserName.i18n) ||
                      resources.UserName.name} Masspay`
                  "
                  v-model="accountNumber"
                  v-validate="'required'"
                  name="userName"
                  disabled
                />
              </div>
              <span
                class="text-danger text-sm"
                v-show="errors.has('userName')"
                >{{ errors.first("userName") }}</span
              >
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-dollar-sign"
              type="number"
              :label="$t(resources.Value.i18n) || resources.Value.name"
              v-model="amount"
              v-validate="'required|decimal:2'"
              name="amount"
            />
          </div>
          <span class="text-danger text-sm" v-show="errors.has('amount')">{{
            errors.first("amount")
          }}</span>
        </div>
        <div class="vx-col mb-2 mt-5" style="text-align: right">
          <vs-button color="primary" type="filled" @click.prevent="valid()">
            {{ $t(resources.Send.i18n) || resources.Send.name }}
          </vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import database from "@/assets/resources/enums/database.json";
import status from "@/assets/resources/enums/status.json";
import axios from "axios";
import vSelect from "vue-select";
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import logo_base64 from "@/assets/images/logo/logo_base64.js";
import jspdf from "jspdf";
import "jspdf-autotable";
import moment from "moment";

export default {
  components: {
    vSelect,
    AgGridVue
  },
  data() {
    return {
      resources: resources,
      alerts: alerts,
      database: database,
      status: status,
      language: this.$i18n.locale,
      popupEdit: false,
      popupDetail: false,
      popupSend: false,
      itaStatus: localStorage.getItem("status"),
      contactId: JSON.parse(localStorage.getItem("userInfo")).id,
      emailSession: JSON.parse(localStorage.getItem("userInfo")).email,
      accountNumber: "",
      accountIdEdit: "",
      accountName: "",
      accountId: "",
      accountOption: [],
      stageId: 23,
      stageList: [],
      popupActive: false,
      activitiesList: [],
      accountDestination: "0",
      accountDestinationList: [],
      rowSelect: {},
      contactName: "",
      contactEmail: "",
      amount: "",
      isPayout: false,
      isMasspay: false,
      imgLogo: logo_base64.image,
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 10,
      gridApi: null,
      rows: 20,
      NumberPage: 0,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: this.$i18n.t("Status"),
          field: "stageName",
          width: 150
        },
        {
          headerName: this.$i18n.t("Type"),
          field: "typeActivityName",
          width: 220
        },
        {
          headerName: this.$i18n.t("Value"),
          field: "value",
          width: 160,
          cellRenderer: function(event) {
            let object = "";
            if (event.node.data.value < 0) {
              object = `<span class='text-danger'>${event.node.data.value}</span>`;
            } else object = object = `<span>${event.node.data.value}</span>`;

            return object;
          }
        },
        {
          headerName: this.$i18n.t("TransactionNumber"),
          field: "transactionCode",
          width: 180
        },
        {
          headerName: this.$i18n.t("Description"),
          field: "observation",
          width: 450
        },
        {
          headerName: this.$i18n.t("ReferenceCode"),
          field: "referenceId"
        },
        {
          headerName: this.$i18n.t("CreationDate"),
          field: "creationDate"
        },
        {
          headerName: this.$i18n.t("PaymentDate"),
          field: "paymentDate"
        }
      ]
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("level", null);
      } else this.gridOptions.columnApi.setColumnPinned("level", "left");
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 50;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    },

    currentDate() {
      var date = new Date().toLocaleString("en-US", {
        timeZone: "America/New_York"
      });
      var datetime = new Date(date);
      return datetime;
    }
  },
  async created() {
    this.$vs.loading();

    await this.getAccounts();

    this.$vs.loading.close();
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    handleSearch(searching) {
      if (searching.length >= 3) {
        this.activitiesList = this.activitiesList.filter(
          x =>
            x.observation.toLowerCase().includes(searching.toLowerCase()) ||
            x.transactionCode.toLowerCase().includes(searching.toLowerCase()) ||
            x.typeActivityName.toLowerCase().includes(searching.toLowerCase())
        );
      }
    },

    downloadPDF(row) {
      var doc = new jspdf();
      const _pdfName = `Invoice_${row.transactionCode}.pdf`;
      moment.locale("es-us");
      const _user = JSON.parse(localStorage.getItem("userInfo"));
      var img = this.imgLogo;

      doc.addImage(img, "png", 7, 5, 80, 20, undefined, "none");

      doc.setFontSize(12);
      doc.setFontType("bold");
      doc.text(130, 15, "GLOBAL VISION NETWORK, LLC");
      doc.setFontSize(9);
      doc.setFontType("normal");
      doc.text(130, 18, "8725 NW 18th Terrace ste 206");
      doc.text(130, 21, "Doral, Florida, Estados Unidos");
      doc.text(130, 24, "Código postal: 33178");
      doc.text(130, 27, "Teléfono: 305 513 8760");

      doc.setFontSize(13);
      doc.setFontType("bold");
      doc.text(105, 40, `FACTURA POR PAGO POR HONORARIOS`);
      doc.text(162, 45, `NRO. ${row.transactionCode}`);

      doc.setFontSize(10);
      doc.setFontType("bold");
      doc.text(15, 50, "Pagado a:");
      doc.text(15, 55, "Documento:");
      doc.text(15, 60, "Dirección:");
      doc.text(15, 65, "Ciudad:");
      doc.text(15, 70, "Teléfono:");

      doc.setFontSize(10);
      doc.setFontType("normal");
      doc.text(34, 50, `${_user.name} ${_user.lastName}`);
      doc.text(36, 55, _user.documentNumber);
      doc.text(33, 60, _user.address);
      doc.text(30, 65, `${_user.cityName}, ${_user.countryName}`);
      doc.text(32, 70, _user.phone);

      doc.autoTable({
        margin: { left: 152 },
        startY: 55,
        headStyles: { fillColor: [195, 195, 195] },
        head: [[this.$i18n.t("PaymentDate")]],
        body: [[row.creationDate]]
      });

      doc.autoTable({
        margin: { left: 15 },
        startY: 80,
        headStyles: { fillColor: [195, 195, 195] },
        head: [["Concepto", "Valor"]],
        body: [[row.observation, row.value]]
      });

      doc.setFontSize(10);
      doc.setFontType("bold");
      doc.text(110, 100, "TOTAL PAGADO EN USD:");
      doc.text(168, 100, `${row.value.toLocaleString("es-MX")}`);

      doc.setDrawColor(29, 47, 83);
      doc.setLineWidth(1);
      doc.line(30, 190, 185, 190);
      doc.setFontSize(8);
      doc.setFontType("italic");
      doc.text(80, 200, `Creado por: ${row.createdBy}`);
      doc.text(70, 204, `Fecha generación: ${moment().format("LLLL")}`);

      doc.save(_pdfName);
    },

    getContactInfo() {
      let _data = this.accountDestinationList.find(
        x => x.id == this.accountDestination
      );
      this.contactName = _data.contactName;
      this.contactEmail = _data.contactEmail;
    },

    async getAccounts() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Account/GetByContact/${this.$i18n.locale.toUpperCase()}/${
          this.contactId
        }`,
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("tokenVT")
        }
      }).then(
        async result => {
          if (result.data.success && result.data.resultCode == 200) {
            this.accountOption = result.data.data;
            this.accountNumber = result.data.data[0].accountNumber;
            this.accountId = result.data.data.filter(
              x => x.typeAccountId == this.database.typeAccount.MainAccount
            )[0].id;
            await this.getActivities(this.accountId);
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.accountOption = [];
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getActivities(account) {
      this.$vs.loading();
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Activity/GetByAccountId/${this.$i18n.locale.toUpperCase()}/${account}`,
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("tokenVT")
        }
      }).then(
        result => {
          if (result.data.success && result.data.resultCode == 200) {
            this.activitiesList = result.data.data;
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.activitiesList = [];
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async updateAccount() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_MEMBERSHIP}contact/updateAccount`,
        data: {
          id: this.accountIdEdit,
          name: this.accountName
        },
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      }).then(
        async result => {
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.popupEdit = false;
            await this.getAccounts();
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getByText(destination) {
      let getElementId;
      if (destination == "accountDestination")
        getElementId = document.getElementById("accountDestination");

      let text = getElementId.__vue__.search;

      if (text.length >= 3) {
        await axios({
          method: "GET",
          url: `${
            process.env.VUE_APP_APIVT
          }Account/GetByText/${this.$i18n.locale.toUpperCase()}/${text}`,
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("tokenVT")}`
          }
        }).then(
          result => {
            if (!result.data.success) {
              this.$vs.notify({
                title: this.$i18n.t("Alert"),
                text: this.$i18n.t("MsgNotFoundInfo"),
                color: "warning",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle"
              });
              this.accountDestinationList = [];
            } else {
              if (destination == "accountDestination")
                this.accountDestinationList = result.data.data;
            }
          },
          error => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError") + "<br/>" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        );
      }
    },

    valid() {
      this.$validator.validateAll().then(async result => {
        let _origin = this.accountOption.find(x => x.id == this.accountId);
        let _destination = this.accountDestinationList.find(
          x => x.id == this.accountDestination
        );

        if (
          !this.isPayout &&
          !this.isMasspay &&
          _origin.typeAccountId == this.database.typeAccount.PositiveBalance &&
          _destination.typeAccountId !=
            this.database.typeAccount.PositiveBalance
        ) {
          this.$vs.dialog({
            color: "warning",
            title: this.$i18n.t("Alert"),
            text:
              "Sólo puedes pasar dinero entre cuentas de tipo saldos a favor (Convención)."
          });
        } else if (
          !this.isPayout &&
          !this.isMasspay &&
          _origin.typeAccountId == this.database.typeAccount.MainAccount &&
          _destination.typeAccountId ==
            this.database.typeAccount.PositiveBalance
        ) {
          this.$vs.dialog({
            color: "warning",
            title: this.$i18n.t("Alert"),
            text:
              "No se permite pasar dinero de una principal (Disponible) a una tipo saldos a favor (Convención)."
          });
        } else if (
          (this.isPayout || this.isMasspay) &&
          _origin.typeAccountId == this.database.typeAccount.PositiveBalance
        ) {
          this.$vs.dialog({
            color: "warning",
            title: this.$i18n.t("Alert"),
            text: `No se permite enviar dinero de una cuenta tipo "Saldos a Favor" a E-wallet.`
          });
        } else if (result) {
          await this.send();
        } else {
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: this.$i18n.t("MsgRequiredField"),
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      });
    },

    async send() {
      this.$vs.loading();
      let _merchantDestination = this.database.merchants.BlockPay;

      if (this.isPayout && !this.isMasspay)
        _merchantDestination = this.database.merchants.Payout;
      if (this.isMasspay && !this.isPayout)
        _merchantDestination = this.database.merchants.Masspay;

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}BlockPay/SendMoney`,
        data: {
          originId: this.accountId,
          destinationId: this.accountDestination,
          amount: this.amount,
          createdBy: this.emailSession,
          language: this.$i18n.locale.toUpperCase(),
          merchantOrigin: this.database.merchants.BlockPay,
          merchantDestination: _merchantDestination
        },
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          this.popupSend = false;
          if (result.data.success) {
            await this.sendEmail(result.data.response);
            await this.getActivities(this.accountId);
            this.clear();
            this.$vs.dialog({
              color: "success",
              title: this.$i18n.t("Success"),
              text: result.data.response
            });
          } else {
            this.$vs.dialog({
              color: "warning",
              title: this.$i18n.t("Alert"),
              text: result.data.response
            });
          }
          this.$vs.loading.close();
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
          this.$vs.loading.close();
        }
      );
    },

    async sendEmail(message) {
      let emails = [];
      let _message = message;
      emails.push(this.emailSession);

      let _subject = "";
      if (this.isPayout) {
        _subject = `Transferecia a E-wallet`;
        _message = `${message} <br/><br/><strong>NOTA IMPORTANTE:</strong> recuerde que su transacción pasa a estar en estado PENDIENTE y será enviada a i-Payout el día miércoles luego de las 4:00pm (Hora Miami).`;
      } else if (this.isMasspay) {
        _subject = `Transferecia a Masspay`;
        _message = `${message} <br/><br/><strong>NOTA IMPORTANTE:</strong> recuerde que su transacción pasa a estar en estado PENDIENTE y será enviada a Masspay - North Lane el día miércoles luego de las 4:00pm (Hora Miami). VISIONBIZ no es responsable de los tiempos que tome el proveedor en depositar el dinero en su cuenta de banco previamente configurada, además, debe tener en cuenta que estos tiempos cambian según el País.`;
      } else {
        _subject = `${this.contactName} te envío dinero.`;
        emails.push(this.contactEmail);
      }

      let _data = {
        subject: _subject,
        message: _message,
        language: this.$i18n.locale.toUpperCase(),
        createdBy: this.emailSession,
        productType: 3,
        identifier: 0,
        addressesList: emails,
        entityId: 0
      };

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Emails/Template`,
        data: _data,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tokenVT")}`
        }
      });
    },

    clear() {
      this.accountId = "";
      this.accountDestination = "0";
      this.contactName = "";
      this.contactEmail = "";
      this.amount = "";
    },

    getAccountName() {
      let _data = this.accountOption.find(x => x.id == this.accountIdEdit);
      this.accountName = _data.name;
    },

    onRowSelected(event) {
      this.rowSelect = event.node.data;
      this.popupDetail = true;
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  }
};
</script>
