<template>
  <div class="vx-col w-full">
    <div id="extra-component-form-wizard-demo">
      <h5 class="text-center">
        <strong>
          {{
          $t(resources.BusinessCenters.i18n) || resources.BusinessCenters.name
          }}
        </strong>
      </h5>
      <form-wizard
        color="rgba(var(--vs-primary), 1)"
        :title="null"
        :subtitle="null"
        :next-button-text="$t(resources.Next.i18n) || resources.Next.name"
        :back-button-text="$t(resources.Back.i18n) || resources.Back.name"
        :finish-button-text="$t(resources.Finish.i18n) || resources.Finish.name"
      >
        <tab-content>
          <!-- tab 1 content -->
          <div class="row">
            <vx-card>
              <div slot="no-body">
                <vs-table :data="businessCenter1">
                  <template slot="thead">
                    <vs-th>
                      {{
                      $t(resources.Description.i18n) ||
                      resources.Description.name
                      }}
                    </vs-th>
                    <vs-th class="text-right">
                      {{
                      $t(resources.CarryInLastPeriod.i18n) ||
                      resources.CarryInLastPeriod.name
                      }}
                    </vs-th>
                    <vs-th class="text-right">
                      {{
                      $t(resources.CurrentPeriod.i18n) ||
                      resources.CurrentPeriod.name
                      }}
                    </vs-th>
                    <vs-th class="text-right">
                      {{
                      $t(resources.CarryNextPeriod.i18n) ||
                      resources.CarryNextPeriod.name
                      }}
                    </vs-th>
                  </template>
                  <template slot-scope="{ data }">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                      <vs-td :data="data[indextr].description">
                        <span>{{ data[indextr].description }}</span>
                      </vs-td>
                      <vs-td :data="data[indextr].lastWeek" class="text-right">
                        <span>{{ data[indextr].lastWeek }}</span>
                      </vs-td>
                      <vs-td :data="data[indextr].currently" class="text-right">
                        <span>{{ data[indextr].currently }}</span>
                      </vs-td>
                      <vs-td :data="data[indextr].acumulated" class="text-right">
                        <span>{{ data[indextr].acumulated }}</span>
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </div>
            </vx-card>
          </div>
        </tab-content>

        <!-- tab 2 content -->
        <tab-content v-if="this.nroCenters > 1">
          <div class="row">
            <vx-card>
              <div slot="no-body" class>
                <vs-table :data="businessCenter2">
                  <template slot="thead">
                    <vs-th>
                      {{
                      $t(resources.Description.i18n) ||
                      resources.Description.name
                      }}
                    </vs-th>
                    <vs-th class="text-right">
                      {{
                      $t(resources.CarryInLastPeriod.i18n) ||
                      resources.CarryInLastPeriod.name
                      }}
                    </vs-th>
                    <vs-th class="text-right">
                      {{
                      $t(resources.CurrentPeriod.i18n) ||
                      resources.CurrentPeriod.name
                      }}
                    </vs-th>
                    <vs-th class="text-right">
                      {{
                      $t(resources.CarryNextPeriod.i18n) ||
                      resources.CarryNextPeriod.name
                      }}
                    </vs-th>
                  </template>
                  <template slot-scope="{ data }">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                      <vs-td :data="data[indextr].description">
                        <span>{{ data[indextr].description }}</span>
                      </vs-td>
                      <vs-td :data="data[indextr].lastWeek" class="text-right">
                        <span>{{ data[indextr].lastWeek }}</span>
                      </vs-td>
                      <vs-td :data="data[indextr].currently" class="text-right">
                        <span>{{ data[indextr].currently }}</span>
                      </vs-td>
                      <vs-td :data="data[indextr].acumulated" class="text-right">
                        <span>{{ data[indextr].acumulated }}</span>
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </div>
            </vx-card>
          </div>
        </tab-content>

        <!-- tab 3 content -->
        <tab-content v-if="this.nroCenters > 2">
          <div class="row">
            <vx-card>
              <div slot="no-body" class>
                <vs-table :data="businessCenter3">
                  <template slot="thead">
                    <vs-th>
                      {{
                      $t(resources.Description.i18n) ||
                      resources.Description.name
                      }}
                    </vs-th>
                    <vs-th class="text-right">
                      {{
                      $t(resources.CarryInLastPeriod.i18n) ||
                      resources.CarryInLastPeriod.name
                      }}
                    </vs-th>
                    <vs-th class="text-right">
                      {{
                      $t(resources.CurrentPeriod.i18n) ||
                      resources.CurrentPeriod.name
                      }}
                    </vs-th>
                    <vs-th class="text-right">
                      {{
                      $t(resources.CarryNextPeriod.i18n) ||
                      resources.CarryNextPeriod.name
                      }}
                    </vs-th>
                  </template>
                  <template slot-scope="{ data }">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                      <vs-td :data="data[indextr].description">
                        <span>{{ data[indextr].description }}</span>
                      </vs-td>
                      <vs-td :data="data[indextr].lastWeek" class="text-right">
                        <span>{{ data[indextr].lastWeek }}</span>
                      </vs-td>
                      <vs-td :data="data[indextr].currently" class="text-right">
                        <span>{{ data[indextr].currently }}</span>
                      </vs-td>
                      <vs-td :data="data[indextr].acumulated" class="text-right">
                        <span>{{ data[indextr].acumulated }}</span>
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </div>
            </vx-card>
          </div>
        </tab-content>
      </form-wizard>
    </div>
  </div>
</template>
<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import axios from "axios";
import resources from "@/i18n/resources.js";

export default {
  props: {
    itaCode: String,
    period: String
  },
  data() {
    return {
      resources: resources,

      nroCenters: 0,
      businessCenter1: "",
      businessCenter2: "",
      businessCenter3: ""
    };
  },
  components: {
    FormWizard,
    TabContent
  },
  created: function() {
    this.getBusinessCenter();
  },
  methods: {
    //get businesses centers
    async getBusinessCenter() {
      await axios({
        method: "POST",
        url: process.env.VUE_APP_OVAPI + "genealogyDetailBySavedQuery",
        data: {
          infotoken: localStorage.getItem("tokenInfo"),
          distid: this.itaCode,
          group: "MyStatus",
          qryid: "MS1",
          filters: "",
          sortFields: ""
        },
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("loggedIn")
        }
      }).then(
        result => {
          let numberCenters = result.data.DATA.BUS_CTR.length - 1;
          this.nroCenters = numberCenters;
          this.setCenterOne(result.data.DATA);
          if (numberCenters > 1) this.setCenterTwo(result.data.DATA);
          if (numberCenters > 2) this.setCenterThree(result.data.DATA);
        },
        error => {
          this.$vs.notify({
            title: "Error",
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    //Set in the business center one
    setCenterOne(json) {
      let center = '[{ "description":"' + this.$i18n.t("LeftVolumen");

      // ----- Left last period ------
      center = center.concat('","lastWeek": ' + json.OVOL4_1[1]);
      // ----- Left current period ------
      center = center.concat(',"currently": ' + json.VOL4[1]);
      // ----- Left acumulated ------
      center = center.concat(',"acumulated": ' + json.OVOL4[1] + "},");

      // ----- Right last period ------
      center = center.concat(
        '{ "description":"' +
          this.$i18n.t("RightVolumen") +
          '","lastWeek": ' +
          json.OVOL5_1[1]
      );
      // ----- Right current period ------
      center = center.concat(',"currently": ' + json.VOL5[1]);
      // ----- Right acumulated ------
      center = center.concat(',"acumulated": ' + json.OVOL5[1] + "}]");

      var obj1 = JSON.parse(center);
      this.businessCenter1 = obj1;
    },

    //Set in the business center 2
    setCenterTwo(json) {
      let center = '[{ "description":"' + this.$i18n.t("LeftVolumen");

      // ----- Left last period ------
      center = center.concat('","lastWeek": ' + json.OVOL4_1[2]);
      // ----- Left current period ------
      center = center.concat(',"currently": ' + json.VOL4[2]);
      // ----- Left acumulated ------
      center = center.concat(',"acumulated": ' + json.OVOL4[2] + "},");

      // ----- Right last period ------
      center = center.concat(
        '{ "description":"' +
          this.$i18n.t("RightVolumen") +
          '","lastWeek": ' +
          json.OVOL5_1[2]
      );
      // ----- Right current period ------
      center = center.concat(',"currently": ' + json.VOL5[2]);
      // ----- Right acumulated ------
      center = center.concat(',"acumulated": ' + json.OVOL5[2] + "}]");

      var obj1 = JSON.parse(center);
      this.businessCenter2 = obj1;
    },

    //Set Business center 3
    setCenterThree(json) {
      let center = '[{ "description":"' + this.$i18n.t("LeftVolumen");

      // ----- Left last period ------
      center = center.concat('","lastWeek": ' + json.OVOL4_1[3]);
      // ----- Left current period ------
      center = center.concat(',"currently": ' + json.VOL4[3]);
      // ----- Left acumulated ------
      center = center.concat(',"acumulated": ' + json.OVOL4[3] + "},");

      // ----- Right last period ------
      center = center.concat(
        '{ "description":"' +
          this.$i18n.t("RightVolumen") +
          '","lastWeek": ' +
          json.OVOL5_1[3]
      );
      // ----- Right current period ------
      center = center.concat(',"currently": ' + json.VOL5[3]);
      // ----- Right acumulated ------
      center = center.concat(',"acumulated": ' + json.OVOL5[3] + "}]");

      var obj1 = JSON.parse(center);
      this.businessCenter3 = obj1;
    }
  }
};
</script>
