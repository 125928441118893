var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-row",
        { attrs: { "vs-type": "flex", "vs-justify": "flex-end" } },
        [
          _c(
            "vx-tooltip",
            {
              attrs: {
                text:
                  _vm.$t(_vm.resources.AddPassenger.i18n) ||
                  _vm.resources.AddPassenger.name
              }
            },
            [
              _c("vs-button", {
                staticClass: "mr-0 mb-2",
                attrs: {
                  radius: "",
                  color: "primary",
                  "icon-pack": "feather",
                  type: "border",
                  icon: "icon-plus"
                },
                on: {
                  click: function($event) {
                    return _vm.showPopUpPassenger(0, false)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "vs-table",
        {
          attrs: { data: _vm.getFilter() },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var data = ref.data
                return _vm._l(data, function(tr, indextr) {
                  return _c(
                    "vs-tr",
                    { key: indextr },
                    [
                      _c(
                        "vs-td",
                        { attrs: { data: data[indextr].passengerTypeName } },
                        [_vm._v(_vm._s(data[indextr].passengerTypeName))]
                      ),
                      _c(
                        "vs-td",
                        { attrs: { data: data[indextr].firstName } },
                        [_vm._v(_vm._s(data[indextr].firstName))]
                      ),
                      _c("vs-td", { attrs: { data: data[indextr].lastName } }, [
                        _vm._v(_vm._s(data[indextr].lastName))
                      ]),
                      _c(
                        "vs-td",
                        { attrs: { data: data[indextr].documentNumber } },
                        [_vm._v(_vm._s(data[indextr].documentNumber))]
                      ),
                      _c(
                        "vs-td",
                        { attrs: { data: data[indextr].birthDate } },
                        [_vm._v(_vm._s(data[indextr].birthDate))]
                      ),
                      _vm.productId != _vm.database.products.HOT &&
                      _vm.productId != _vm.database.products.PAQ
                        ? _c(
                            "vs-td",
                            {
                              attrs: { data: data[indextr].passportExpiration }
                            },
                            [_vm._v(_vm._s(data[indextr].passportExpiration))]
                          )
                        : _vm._e(),
                      _c(
                        "vs-td",
                        [
                          _c(
                            "vx-tooltip",
                            {
                              attrs: {
                                text:
                                  _vm.$t(_vm.resources.Edit.i18n) ||
                                  _vm.resources.Edit.name
                              }
                            },
                            [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.showPopUpPassenger(
                                        data[indextr],
                                        true
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    staticClass: "w-5 h-5 mr-1",
                                    attrs: { icon: "EditIcon" }
                                  })
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "vx-tooltip",
                            {
                              attrs: {
                                text:
                                  _vm.$t(_vm.resources.Delete.i18n) ||
                                  _vm.resources.Delete.name
                              }
                            },
                            [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.remove(data[indextr])
                                    }
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    staticClass: "w-5 h-5 mr-1",
                                    attrs: {
                                      icon: "XIcon",
                                      svgClasses: "fill-current text-danger"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                })
              }
            }
          ])
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.Type.i18n) || _vm.resources.Type.name
                  )
                )
              ]),
              _c("vs-th", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.Name.i18n) || _vm.resources.Name.name
                  )
                )
              ]),
              _c("vs-th", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.LastName.i18n) ||
                      _vm.resources.LastName.name
                  )
                )
              ]),
              _c("vs-th", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.DocumentNumber.i18n) ||
                      _vm.resources.DocumentNumber.name
                  )
                )
              ]),
              _c("vs-th", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.BirthDate.i18n) ||
                      _vm.resources.BirthDate.name
                  )
                )
              ]),
              _vm.productId != _vm.database.products.HOT &&
              _vm.productId != _vm.database.products.PAQ
                ? _c("vs-th", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.ExpirationDatePassport.i18n) ||
                          _vm.resources.ExpirationDatePassport.name
                      )
                    )
                  ])
                : _vm._e(),
              _c("vs-th", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.Options.i18n) ||
                      _vm.resources.Options.name
                  )
                )
              ])
            ],
            1
          )
        ],
        2
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title:
              _vm.$t(_vm.resources.Passengers.i18n) ||
              _vm.resources.Passengers.name,
            active: _vm.popupActivePassenger
          },
          on: {
            "update:active": function($event) {
              _vm.popupActivePassenger = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vx-row mb-4" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full" },
              [
                _c(
                  "vs-select",
                  {
                    staticClass: "w-full",
                    attrs: {
                      label:
                        _vm.$t(_vm.resources.PassengerType.i18n) ||
                        _vm.resources.PassengerType.name
                    },
                    model: {
                      value: _vm.passengerTypeId,
                      callback: function($$v) {
                        _vm.passengerTypeId = $$v
                      },
                      expression: "passengerTypeId"
                    }
                  },
                  _vm._l(_vm.passengerType, function(item, index) {
                    return _c("vs-select-item", {
                      key: index,
                      attrs: { value: item.value, text: item.text }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full" },
              [
                _c(
                  "vs-select",
                  {
                    staticClass: "w-full",
                    attrs: {
                      label:
                        _vm.$t(_vm.resources.DocumentType.i18n) ||
                        _vm.resources.DocumentType.name
                    },
                    model: {
                      value: _vm.documentType,
                      callback: function($$v) {
                        _vm.documentType = $$v
                      },
                      expression: "documentType"
                    }
                  },
                  _vm._l(_vm.identifiacionTypeList, function(item, index) {
                    return _c("vs-select-item", {
                      key: index,
                      attrs: { value: item.id, text: item.name }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "vx-col md:w-1/2 w-full" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-hash",
                      "icon-no-border": "",
                      label:
                        _vm.$t(_vm.resources.DocumentNumber.i18n) ||
                        _vm.resources.DocumentNumber.name
                    },
                    model: {
                      value: _vm.documentNumber,
                      callback: function($$v) {
                        _vm.documentNumber = $$v
                      },
                      expression: "documentNumber"
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.BirthDate.i18n) ||
                        _vm.resources.BirthDate.name
                    )
                  )
                ]),
                _c("datepicker", {
                  attrs: {
                    language: _vm.languages[_vm.language],
                    format: "d MMMM yyyy"
                  },
                  model: {
                    value: _vm.birthDate,
                    callback: function($$v) {
                      _vm.birthDate = $$v
                    },
                    expression: "birthDate"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    "icon-pack": "feather",
                    "icon-no-border": "",
                    icon: "icon-user",
                    label:
                      _vm.$t(_vm.resources.Name.i18n) || _vm.resources.Name.name
                  },
                  model: {
                    value: _vm.firstName,
                    callback: function($$v) {
                      _vm.firstName = $$v
                    },
                    expression: "firstName"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    "icon-pack": "feather",
                    "icon-no-border": "",
                    icon: "icon-user",
                    label:
                      _vm.$t(_vm.resources.LastName.i18n) ||
                      _vm.resources.LastName.name
                  },
                  model: {
                    value: _vm.lastName,
                    callback: function($$v) {
                      _vm.lastName = $$v
                    },
                    expression: "lastName"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full" },
              [
                _c(
                  "vs-select",
                  {
                    staticClass: "w-full",
                    attrs: {
                      label:
                        _vm.$t(_vm.resources.Gender.i18n) ||
                        _vm.resources.Gender.name
                    },
                    model: {
                      value: _vm.genderId,
                      callback: function($$v) {
                        _vm.genderId = $$v
                      },
                      expression: "genderId"
                    }
                  },
                  _vm._l(_vm.genderType, function(item, index) {
                    return _c("vs-select-item", {
                      key: index,
                      attrs: { value: item.value, text: item.text }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Country.i18n) ||
                        _vm.resources.Country.name
                    )
                  )
                ]),
                _c("v-select", {
                  attrs: {
                    clearable: false,
                    options: _vm.countryList,
                    reduce: function(country) {
                      return country.id
                    },
                    label: "name"
                  },
                  model: {
                    value: _vm.nationalityId,
                    callback: function($$v) {
                      _vm.nationalityId = $$v
                    },
                    expression: "nationalityId"
                  }
                })
              ],
              1
            ),
            _vm.productId != _vm.database.products.HOT &&
            _vm.productId != _vm.database.products.PAQ
              ? _c(
                  "div",
                  { staticClass: "vx-col md:w-1/2 w-full" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.ExpirationDatePassport.i18n) ||
                            _vm.resources.ExpirationDatePassport.name
                        )
                      )
                    ]),
                    _c("datepicker", {
                      attrs: {
                        language: _vm.languages[_vm.language],
                        format: "d MMMM yyyy"
                      },
                      model: {
                        value: _vm.passportExpiration,
                        callback: function($$v) {
                          _vm.passportExpiration = $$v
                        },
                        expression: "passportExpiration"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "vx-col md:w-1/2 w-full" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      "icon-no-border": "",
                      icon: "icon-phone",
                      type: "number",
                      label:
                        _vm.$t(_vm.resources.Telephone.i18n) ||
                        _vm.resources.Telephone.name
                    },
                    model: {
                      value: _vm.Telephone,
                      callback: function($$v) {
                        _vm.Telephone = $$v
                      },
                      expression: "Telephone"
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    "icon-pack": "feather",
                    "icon-no-border": "",
                    icon: "icon-hash",
                    label:
                      _vm.$t(_vm.resources.TicketNumber.i18n) ||
                      _vm.resources.TicketNumber.name
                  },
                  model: {
                    value: _vm.ticketNumber,
                    callback: function($$v) {
                      _vm.ticketNumber = $$v
                    },
                    expression: "ticketNumber"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "email",
                      expression: "'email'"
                    }
                  ],
                  staticClass: "w-full",
                  attrs: {
                    name: "passengerEmail",
                    label:
                      _vm.$t(_vm.resources.Email.i18n) ||
                      _vm.resources.Email.name,
                    "icon-pack": "feather",
                    icon: "icon-mail",
                    maxlength: "150",
                    "icon-no-border": ""
                  },
                  model: {
                    value: _vm.email,
                    callback: function($$v) {
                      _vm.email = $$v
                    },
                    expression: "email"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    readonly: "",
                    "icon-pack": "feather",
                    "icon-no-border": "",
                    icon: "icon-map",
                    label:
                      _vm.$t(_vm.resources.ResidenceCity.i18n) ||
                      _vm.resources.ResidenceCity.name
                  },
                  model: {
                    value: _vm.residence,
                    callback: function($$v) {
                      _vm.residence = $$v
                    },
                    expression: "residence"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    readonly: "",
                    "icon-pack": "feather",
                    "icon-no-border": "",
                    icon: "icon-map-pin",
                    label:
                      _vm.$t(_vm.resources.Address.i18n) ||
                      _vm.resources.Address.name
                  },
                  model: {
                    value: _vm.address,
                    callback: function($$v) {
                      _vm.address = $$v
                    },
                    expression: "address"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    readonly: "",
                    "icon-pack": "feather",
                    "icon-no-border": "",
                    icon: "icon-hash",
                    label:
                      _vm.$t(_vm.resources.ZipCode.i18n) ||
                      _vm.resources.ZipCode.name
                  },
                  model: {
                    value: _vm.postalCode,
                    callback: function($$v) {
                      _vm.postalCode = $$v
                    },
                    expression: "postalCode"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/4 mb-2 mt-5" },
              [
                _c(
                  "vs-button",
                  {
                    attrs: { color: "primary", type: "filled" },
                    on: {
                      click: function($event) {
                        return _vm.savePassenger()
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(_vm.resources.Save.i18n) ||
                            _vm.resources.Save.name
                        ) +
                        " "
                    )
                  ]
                )
              ],
              1
            )
          ]),
          _c("vs-divider", { staticClass: "mb-2" }, [
            _c("h4", { staticClass: "font-semibold mb-base" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(_vm.resources.Specifications.i18n) ||
                      _vm.resources.Specifications.name
                  ) +
                  " "
              )
            ])
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    "icon-pack": "feather",
                    "icon-no-border": "",
                    icon: "icon-edit",
                    label:
                      _vm.$t(_vm.resources.Specification.i18n) ||
                      _vm.resources.Specification.name
                  },
                  model: {
                    value: _vm.specificationName,
                    callback: function($$v) {
                      _vm.specificationName = $$v
                    },
                    expression: "specificationName"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/2 mb-2 mt-5" },
              [
                _c(
                  "vs-button",
                  {
                    attrs: { color: "primary", type: "filled" },
                    on: {
                      click: function($event) {
                        return _vm.addSpecification()
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(_vm.resources.Save.i18n) ||
                            _vm.resources.Save.name
                        ) +
                        " "
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c(
                  "vs-table",
                  {
                    attrs: { data: _vm.specifications },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var data = ref.data
                          return _vm._l(data, function(tr, indextr) {
                            return _c(
                              "vs-tr",
                              { key: indextr },
                              [
                                _c(
                                  "vs-td",
                                  {
                                    attrs: {
                                      data: data[indextr].specificationValue
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(data[indextr].specificationValue)
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          })
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "template",
                      { slot: "thead" },
                      [
                        _c("vs-th", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.Specification.i18n) ||
                                _vm.resources.Specification.name
                            )
                          )
                        ]),
                        _c("vs-th", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.Options.i18n) ||
                                _vm.resources.Options.name
                            )
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  2
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }