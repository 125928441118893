var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { attrs: { id: "ag-grid-demo" } },
      [
        _c(
          "vx-card",
          [
            _c("h4", { staticClass: "font-semibold mb-base" }, [
              _vm._v(
                _vm._s(
                  _vm.$t(_vm.resources.Search.i18n) || _vm.resources.Search.name
                )
              )
            ]),
            _c("div", { staticClass: "vx-row mb-base" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/4 mb-2" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      type: "number",
                      "icon-pack": "feather",
                      icon: "icon-user",
                      "icon-no-border": "",
                      label:
                        _vm.$t(_vm.resources.DistributorID.i18n) ||
                        _vm.resources.DistributorID.name
                    },
                    model: {
                      value: _vm.itaCodeForm,
                      callback: function($$v) {
                        _vm.itaCodeForm = $$v
                      },
                      expression: "itaCodeForm"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/6 mb-2 mt-5" },
                [
                  _c(
                    "vs-button",
                    {
                      attrs: { color: "primary", type: "filled" },
                      on: {
                        click: function($event) {
                          return _vm.validateAccess()
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(_vm.resources.Search.i18n) ||
                              _vm.resources.Search.name
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "vx-row" },
              [
                _c(
                  "vs-table",
                  {
                    staticClass: "vx-col w-full",
                    attrs: { data: _vm.accessData },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var data = ref.data
                          return _vm._l(data, function(tr, indextr) {
                            return _c(
                              "vs-tr",
                              { key: indextr },
                              [
                                data[indextr].statusId == "1"
                                  ? _c(
                                      "vs-td",
                                      {
                                        staticClass: "text-center",
                                        attrs: { data: data[indextr].statusId }
                                      },
                                      [
                                        _c("vs-icon", {
                                          attrs: {
                                            "icon-pack": "feather",
                                            icon: "icon-check-circle",
                                            color: "success"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _c(
                                      "vs-td",
                                      {
                                        staticClass: "text-center",
                                        attrs: { data: data[indextr].statusId }
                                      },
                                      [
                                        _c("vs-icon", {
                                          attrs: {
                                            "icon-pack": "feather",
                                            icon: "icon-x-circle",
                                            color: "danger"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                _c(
                                  "vs-td",
                                  { attrs: { data: data[indextr].code } },
                                  [
                                    _vm._v(
                                      " " + _vm._s(data[indextr].code) + " "
                                    )
                                  ]
                                ),
                                _c(
                                  "vs-td",
                                  { attrs: { data: data[indextr].name } },
                                  [
                                    _vm._v(
                                      _vm._s(data[indextr].name) +
                                        " " +
                                        _vm._s(data[indextr].lastName)
                                    )
                                  ]
                                ),
                                _c(
                                  "vs-td",
                                  { attrs: { data: data[indextr].email } },
                                  [
                                    _vm._v(
                                      " " + _vm._s(data[indextr].email) + " "
                                    )
                                  ]
                                ),
                                _c(
                                  "vs-td",
                                  {
                                    attrs: {
                                      data:
                                        data[indextr].countryOperationDefault
                                          .countryCode
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          data[indextr].countryOperationDefault
                                            .countryCode
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c("vs-td", [
                                  _vm._v(
                                    " " + _vm._s(data[indextr].cityName) + " "
                                  )
                                ]),
                                _c("vs-td", [
                                  _vm._v(
                                    " " + _vm._s(data[indextr].address) + " "
                                  )
                                ]),
                                _c("vs-td", [
                                  _vm._v(
                                    " " + _vm._s(data[indextr].phone) + " "
                                  )
                                ])
                              ],
                              1
                            )
                          })
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "template",
                      { slot: "thead" },
                      [
                        _c("vs-th", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(_vm.resources.Status.i18n) ||
                                  _vm.resources.Status.name
                              ) +
                              " "
                          )
                        ]),
                        _c("vs-th", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(_vm.resources.DistributorID.i18n) ||
                                  _vm.resources.DistributorID.name
                              ) +
                              " "
                          )
                        ]),
                        _c("vs-th", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(_vm.resources.Name.i18n) ||
                                  _vm.resources.Name.name
                              ) +
                              " "
                          )
                        ]),
                        _c("vs-th", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(_vm.resources.Email.i18n) ||
                                  _vm.resources.Email.name
                              ) +
                              " "
                          )
                        ]),
                        _c("vs-th", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(_vm.resources.CountryOperation.i18n) ||
                                  _vm.resources.CountryOperation.name
                              ) +
                              " "
                          )
                        ]),
                        _c("vs-th", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(_vm.resources.ResidenceCity.i18n) ||
                                  _vm.resources.ResidenceCity.name
                              ) +
                              " "
                          )
                        ]),
                        _c("vs-th", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(_vm.resources.Address.i18n) ||
                                  _vm.resources.Address.name
                              ) +
                              " "
                          )
                        ]),
                        _c("vs-th", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(_vm.resources.Mobile.i18n) ||
                                  _vm.resources.Mobile.name
                              ) +
                              " "
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  2
                )
              ],
              1
            ),
            _c("br"),
            _c("vs-divider", { staticClass: "mb-2" }, [
              _c("h4", { staticClass: "font-semibold mb-base" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(_vm.resources.CustomizedFee.i18n) ||
                        _vm.resources.CustomizedFee.name
                    ) +
                    " "
                )
              ])
            ]),
            _c(
              "div",
              { staticClass: "vx-row" },
              [
                _c(
                  "vs-table",
                  {
                    staticClass: "vx-col w-full",
                    attrs: { data: _vm.feeList },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var data = ref.data
                          return _vm._l(data, function(tr, indextr) {
                            return _c(
                              "vs-tr",
                              { key: indextr },
                              [
                                _c(
                                  "vs-td",
                                  { attrs: { data: data[indextr].CodProd } },
                                  [
                                    _vm._v(
                                      " " + _vm._s(data[indextr].CodProd) + " "
                                    )
                                  ]
                                ),
                                data[indextr].EsPorcentaje
                                  ? _c(
                                      "vs-td",
                                      {
                                        attrs: {
                                          data: data[indextr].EsPorcentaje
                                        }
                                      },
                                      [
                                        _c("vs-icon", {
                                          attrs: {
                                            "icon-pack": "material-icons",
                                            icon: "check_circle",
                                            size: "small",
                                            color: "rgba(var(--vs-success), 1)"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _c(
                                      "vs-td",
                                      {
                                        attrs: {
                                          data: data[indextr].EsPorcentaje
                                        }
                                      },
                                      [
                                        _c("vs-icon", {
                                          attrs: {
                                            "icon-pack": "material-icons",
                                            icon: "highlight_off",
                                            size: "small",
                                            color: "rgba(var(--vs-danger), 1)"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                _c(
                                  "vs-td",
                                  { attrs: { data: data[indextr].Valor } },
                                  [
                                    _vm._v(
                                      " " + _vm._s(data[indextr].Valor) + " "
                                    )
                                  ]
                                ),
                                _c(
                                  "vs-td",
                                  {
                                    attrs: { data: data[indextr].FechaCreacion }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(data[indextr].FechaCreacion) +
                                        " "
                                    )
                                  ]
                                ),
                                data[indextr].Activo
                                  ? _c(
                                      "vs-td",
                                      { attrs: { data: data[indextr].Activo } },
                                      [
                                        _c("vs-icon", {
                                          attrs: {
                                            "icon-pack": "material-icons",
                                            icon: "check_circle",
                                            size: "small",
                                            color: "rgba(var(--vs-success), 1)"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _c(
                                      "vs-td",
                                      { attrs: { data: data[indextr].Activo } },
                                      [
                                        _c("vs-icon", {
                                          attrs: {
                                            "icon-pack": "material-icons",
                                            icon: "highlight_off",
                                            size: "small",
                                            color: "rgba(var(--vs-danger), 1)"
                                          }
                                        })
                                      ],
                                      1
                                    )
                              ],
                              1
                            )
                          })
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "template",
                      { slot: "thead" },
                      [
                        _c("vs-th", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(_vm.resources.Type.i18n) ||
                                  _vm.resources.Type.name
                              ) +
                              " "
                          )
                        ]),
                        _c("vs-th", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(_vm.resources.Percent.i18n) ||
                                  _vm.resources.Percent.name
                              ) +
                              " "
                          )
                        ]),
                        _c("vs-th", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(_vm.resources.Amount.i18n) ||
                                  _vm.resources.Amount.name
                              ) +
                              " "
                          )
                        ]),
                        _c("vs-th", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(_vm.resources.CreateDate.i18n) ||
                                  _vm.resources.CreateDate.name
                              ) +
                              " "
                          )
                        ]),
                        _c("vs-th", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(_vm.resources.Status.i18n) ||
                                  _vm.resources.Status.name
                              ) +
                              " "
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  2
                )
              ],
              1
            ),
            _c("br"),
            _c("vs-divider", [
              _c("h4", { staticClass: "font-semibold mb-base" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.Historical.i18n) ||
                      _vm.resources.Historical.name
                  )
                )
              ])
            ]),
            _c(
              "div",
              { staticClass: "flex flex-wrap justify-between items-center" },
              [
                _c(
                  "div",
                  {
                    staticClass: "mb-4 md:mb-0 mr-4 ag-grid-table-actions-left"
                  },
                  [
                    _c(
                      "vs-dropdown",
                      {
                        staticClass: "cursor-pointer",
                        attrs: { "vs-trigger-click": "" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                          },
                          [
                            _c("span", { staticClass: "mr-2" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.currentPage * _vm.paginationPageSize -
                                      (_vm.paginationPageSize - 1)
                                  ) +
                                  " - " +
                                  _vm._s(
                                    _vm.accessData.length -
                                      _vm.currentPage * _vm.paginationPageSize >
                                      0
                                      ? _vm.currentPage * _vm.paginationPageSize
                                      : _vm.accessData.length
                                  ) +
                                  " of " +
                                  _vm._s(_vm.accessData.length) +
                                  " "
                              )
                            ]),
                            _c("feather-icon", {
                              attrs: {
                                icon: "ChevronDownIcon",
                                svgClasses: "h-4 w-4"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "vs-dropdown-menu",
                          [
                            _c(
                              "vs-dropdown-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.gridApi.paginationSetPageSize(20)
                                  }
                                }
                              },
                              [_c("span", [_vm._v("20")])]
                            ),
                            _c(
                              "vs-dropdown-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.gridApi.paginationSetPageSize(50)
                                  }
                                }
                              },
                              [_c("span", [_vm._v("50")])]
                            ),
                            _c(
                              "vs-dropdown-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.gridApi.paginationSetPageSize(
                                      100
                                    )
                                  }
                                }
                              },
                              [_c("span", [_vm._v("100")])]
                            ),
                            _c(
                              "vs-dropdown-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.gridApi.paginationSetPageSize(
                                      150
                                    )
                                  }
                                }
                              },
                              [_c("span", [_vm._v("150")])]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-wrap items-center justify-between ag-grid-table-actions-right"
                  },
                  [
                    _c("vs-input", {
                      staticClass: "mb-4 md:mb-0 mr-4",
                      attrs: {
                        placeholder:
                          _vm.$t(_vm.resources.Search.i18n) ||
                          _vm.resources.Search.name
                      },
                      on: { input: _vm.updateSearchQuery },
                      model: {
                        value: _vm.searchQuery,
                        callback: function($$v) {
                          _vm.searchQuery = $$v
                        },
                        expression: "searchQuery"
                      }
                    }),
                    _c(
                      "vs-button",
                      {
                        staticClass: "mb-4 md:mb-0 mr-1",
                        on: {
                          click: function($event) {
                            return _vm.gridApi.exportDataAsCsv()
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(_vm.resources.ExportAs.i18n) ||
                                _vm.resources.ExportAs.name
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            ),
            _c("ag-grid-vue", {
              staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
              attrs: {
                gridOptions: _vm.gridOptions,
                columnDefs: _vm.columnDefs,
                defaultColDef: _vm.defaultColDef,
                rowData: _vm.historicoFee,
                rowSelection: "single",
                colResizeDefault: "shift",
                animateRows: true,
                floatingFilter: true,
                pagination: true,
                paginationPageSize: _vm.paginationPageSize,
                suppressPaginationPanel: true
              }
            }),
            _c("vs-pagination", {
              attrs: { total: _vm.totalPages, max: _vm.maxPageNumbers },
              model: {
                value: _vm.currentPage,
                callback: function($$v) {
                  _vm.currentPage = $$v
                },
                expression: "currentPage"
              }
            })
          ],
          1
        ),
        _c(
          "vs-popup",
          {
            attrs: {
              title:
                _vm.$t(_vm.resources.Update.i18n) || _vm.resources.Update.name,
              active: _vm.popupActive
            },
            on: {
              "update:active": function($event) {
                _vm.popupActive = $event
              }
            }
          },
          [
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/2 mb-2" },
                [
                  _c("label", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(_vm.resources.BirthDate.i18n) ||
                            _vm.resources.BirthDate.name
                        ) +
                        " "
                    )
                  ]),
                  _c("datepicker", {
                    attrs: {
                      language: _vm.languages[_vm.language],
                      format: "d MMMM yyyy"
                    },
                    model: {
                      value: _vm.birthDate,
                      callback: function($$v) {
                        _vm.birthDate = $$v
                      },
                      expression: "birthDate"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/2 mb-2" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-file",
                      "icon-no-border": "",
                      label:
                        _vm.$t(_vm.resources.DocumentNumber.i18n) ||
                        _vm.resources.DocumentNumber.name
                    },
                    model: {
                      value: _vm.document,
                      callback: function($$v) {
                        _vm.document = $$v
                      },
                      expression: "document"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full mb-2" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      type: "email",
                      "icon-pack": "feather",
                      icon: "icon-user",
                      "icon-no-border": "",
                      label:
                        _vm.$t(_vm.resources.Email.i18n) ||
                        _vm.resources.Email.name
                    },
                    model: {
                      value: _vm.emailForm,
                      callback: function($$v) {
                        _vm.emailForm = $$v
                      },
                      expression: "emailForm"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/2 mb-2" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.CountryOperation.i18n) ||
                          _vm.resources.CountryOperation.name
                      )
                    )
                  ]),
                  _c("v-select", {
                    staticClass: "w-full",
                    attrs: {
                      type: "text",
                      "icon-pack": "feather",
                      icon: "icon-arrow-down",
                      "icon-no-border": "",
                      clearable: true,
                      options: _vm.countryOperationList,
                      reduce: function(country) {
                        return country.countryCode
                      },
                      label: "display"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "option",
                        fn: function(option) {
                          return [
                            _c("img", {
                              staticStyle: { with: "9px" },
                              attrs: { src: option.iconUrl }
                            }),
                            _vm._v(" " + _vm._s(option.display) + " ")
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.countryOperation,
                      callback: function($$v) {
                        _vm.countryOperation = $$v
                      },
                      expression: "countryOperation"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/2 mb-2" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Country.i18n) ||
                          _vm.resources.Country.name
                      )
                    )
                  ]),
                  _c("v-select", {
                    staticClass: "w-full",
                    attrs: {
                      type: "text",
                      "icon-pack": "feather",
                      icon: "icon-arrow-down",
                      "icon-no-border": "",
                      clearable: true,
                      options: _vm.countryList,
                      reduce: function(country) {
                        return country.id
                      },
                      label: "name",
                      hint: "" + _vm.country
                    },
                    on: {
                      input: function($event) {
                        return _vm.getStateByCountry("" + _vm.country)
                      }
                    },
                    model: {
                      value: _vm.country,
                      callback: function($$v) {
                        _vm.country = $$v
                      },
                      expression: "country"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/2 mb-2" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.State.i18n) ||
                          _vm.resources.State.name
                      )
                    )
                  ]),
                  _c("v-select", {
                    staticClass: "w-full",
                    attrs: {
                      type: "text",
                      "icon-pack": "feather",
                      icon: "icon-arrow-down",
                      "icon-no-border": "",
                      clearable: false,
                      options: _vm.stateCountry,
                      reduce: function(state) {
                        return state.id
                      },
                      label: "name",
                      hint: "" + _vm.state
                    },
                    on: {
                      input: function($event) {
                        return _vm.getCityByState("" + _vm.state)
                      }
                    },
                    model: {
                      value: _vm.state,
                      callback: function($$v) {
                        _vm.state = $$v
                      },
                      expression: "state"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/2 mb-2" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.ResidenceCity.i18n) ||
                          _vm.resources.ResidenceCity.name
                      )
                    )
                  ]),
                  _c("v-select", {
                    staticClass: "w-full",
                    attrs: {
                      type: "text",
                      "icon-pack": "feather",
                      icon: "icon-arrow-down",
                      "icon-no-border": "",
                      clearable: false,
                      options: _vm.destinationList,
                      reduce: function(city) {
                        return city.id
                      },
                      label: "name"
                    },
                    model: {
                      value: _vm.residenceCity,
                      callback: function($$v) {
                        _vm.residenceCity = $$v
                      },
                      expression: "residenceCity"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full mb-2" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      type: "text",
                      "icon-pack": "feather",
                      icon: "icon-home",
                      "icon-no-border": "",
                      label:
                        _vm.$t(_vm.resources.Address.i18n) ||
                        _vm.resources.Address.name
                    },
                    model: {
                      value: _vm.address,
                      callback: function($$v) {
                        _vm.address = $$v
                      },
                      expression: "address"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-full mb-2" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      type: "text",
                      "icon-pack": "feather",
                      icon: "icon-phone",
                      "icon-no-border": "",
                      label:
                        _vm.$t(_vm.resources.Phone.i18n) ||
                        _vm.resources.Phone.name
                    },
                    model: {
                      value: _vm.phone,
                      callback: function($$v) {
                        _vm.phone = $$v
                      },
                      expression: "phone"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/6 mb-2 mt-5" },
                [
                  _c(
                    "vs-button",
                    {
                      attrs: { color: "primary", type: "filled" },
                      on: {
                        click: function($event) {
                          return _vm.update()
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(_vm.resources.Save.i18n) ||
                              _vm.resources.Save.name
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }