<!-- =========================================================================================
    File Name: bookingDetail.vue
    Description: bookingDetail Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="vx-row mb-4">
      <vx-card :title="$t(resources.Booking.i18n) || resources.Booking.name">
        <h4 class="text-center text-danger mb-4" v-if="managedSot">
          <strong
            >{{
              $t(alerts.MsgManagedBySOT.i18n) || alerts.MsgManagedBySOT.name
            }}.</strong
          >
        </h4>
        <h4 class="text-center text-success mb-4" v-if="isManaged">
          <strong
            >{{ $t(alerts.MsgManaged.i18n) || alerts.MsgManaged.name }}.</strong
          >
        </h4>
        <div class="vx-row">
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-mail"
                icon-no-border
                readonly
                label="Voucher"
                v-model="voucherEmail"
              />
            </div>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-hash"
                icon-no-border
                :label="
                  $t(resources.AccountNumber.i18n) ||
                    resources.AccountNumber.name
                "
                v-model="accountNumber"
                readonly
              />
            </div>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-mail"
                icon-no-border
                :label="
                  `IBO - ${$t(resources.Email.i18n) || resources.Email.name}`
                "
                v-model="iboEmail"
                readonly
              />
            </div>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-user"
                icon-no-border
                readonly
                :label="
                  $t(resources.ContactName.i18n) || resources.ContactName.name
                "
                v-model="contactName"
              />
            </div>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon-no-border
                icon="icon-mail"
                readonly
                :label="
                  `${$t(resources.Contact.i18n) ||
                    resources.Contact.name} - ${$t(resources.Email.i18n) ||
                    resources.Email.name}`
                "
                v-model="email"
              />
            </div>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-map"
                icon-no-border
                readonly
                :label="
                  $t(resources.CountryOperation.i18n) ||
                    resources.CountryOperation.name
                "
                v-model="countryOperation"
              />
            </div>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-hash"
                icon-no-border
                readonly
                :label="$t(resources.Code.i18n) || resources.Code.name"
                v-model="bookingCode"
              />
            </div>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-dollar-sign"
                icon-no-border
                readonly
                :label="$t(resources.NetValue.i18n) || resources.NetValue.name"
                :value="netValue.toLocaleString('es-MX')"
              />
            </div>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon-no-border
                readonly
                icon="icon-dollar-sign"
                :label="$t(resources.TaxesValue.i18n) || resources.Source.name"
                :value="taxValue.toLocaleString('es-MX')"
              />
            </div>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-dollar-sign"
                icon-no-border
                readonly
                :label="$t(resources.FeeValue.i18n) || resources.FeeValue.name"
                :value="feeValue.toLocaleString('es-MX')"
              />
            </div>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-dollar-sign"
                icon-no-border
                readonly
                :label="
                  $t(resources.MerchantValue.i18n) ||
                    resources.MerchantValue.name
                "
                :value="merchantValue.toLocaleString('es-MX')"
              />
            </div>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-dollar-sign"
                icon-no-border
                readonly
                :label="
                  $t(resources.AgentCommissionValue.i18n) ||
                    resources.AgentCommissionValue.name
                "
                :value="agentCommissionValue.toLocaleString('es-MX')"
              />
            </div>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-dollar-sign"
                icon-no-border
                readonly
                :label="
                  $t(resources.BookingValue.i18n) || resources.BookingValue.name
                "
                :value="totalValue.toLocaleString('es-MX')"
              />
            </div>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon-no-border
                readonly
                icon="icon-dollar-sign"
                :label="
                  $t(resources.DiscountValue.i18n) ||
                    resources.DiscountValue.name
                "
                :value="discountValue.toLocaleString('es-MX')"
              />
            </div>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon-no-border
                icon="icon-dollar-sign"
                readonly
                :label="
                  $t(resources.FeeCustomized.i18n) ||
                    resources.FeeCustomized.name
                "
                :value="feeCustomized.toLocaleString('es-MX')"
              />
            </div>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon-no-border
                icon="icon-dollar-sign"
                readonly
                :label="
                  $t(resources.TotalValue.i18n) || resources.TotalValue.name
                "
                :value="
                  (totalValue + feeCustomized + discountValue).toLocaleString(
                    'es-MX'
                  )
                "
              />
            </div>
          </div>
          <div
            class="vx-col md:w-1/6 w-full mb-2"
            v-if="countryOperation == 'COL'"
          >
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-clipboard"
                icon-no-border
                readonly
                :label="$t(resources.Invoice.i18n) || resources.Invoice.name"
                v-model="invoice"
              />
            </div>
          </div>
          <div
            class="vx-col md:w-1/6 w-full mt-6"
            v-if="
                countryOperation == 'COL' &&
                bookingDetail[0].currencyCode == 'COP'
            "
          >
            <vs-button
              color="success"
              type="filled"
              @click="validateInvoice()"
              >{{
                $t(resources.Generate.i18n) || resources.Generate.name
              }}</vs-button
            >
          </div>
        </div>
      </vx-card>

      <vs-popup
        classContent="popup overFlow"
        :title="
          `${$t(resources.FeeCustomized.i18n) ||
            resources.FeeCustomized.name} - ${$t(resources.Remark.i18n) ||
            resources.Remark.name}`
        "
        :active.sync="popupObservation"
      >
        <vs-textarea
          class="w-full"
          icon-pack="feather"
          icon-no-border
          icon="icon-clipboard"
          v-model="remark"
        />

        <div class="vx-col mb-2 mt-5" style="text-align: right">
          <vs-button
            color="primary"
            type="filled"
            @click="popupObservation = false"
          >
            {{ $t(resources.Save.i18n) || resources.Save.name }}
          </vs-button>
        </div>
      </vs-popup>
    </div>

    <div class="vx-row mb-4">
      <vs-tabs>
        <vs-tab
          :label="$t(resources.Detail.i18n) || resources.Detail.name"
          icon-pack="feather"
          icon="icon-file-text"
        >
          <vs-row vs-type="flex" vs-justify="center" class="mb-base mt-2">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="3"
            >
              <vs-button
                color="primary"
                type="filled"
                icon="add"
                @click="popupAddDetail = true"
                >{{ $t(resources.Add.i18n) || resources.Add.name }}</vs-button
              >
            </vs-col>
          </vs-row>
          <vx-card
            class="mb-4"
            v-for="(detail, index) in bookingDetail"
            :key="index"
          >
            <h3 class="font-weight-bold">
              {{ detail.productName }} - #{{ detail.bookingReference }} <vs-button v-if="(emailSession == 'a.gomez@visiontravel.net' || emailSession == 'y.villa@visiontravelmail.net') && (detail.stageId == 31 || detail.stageId == 33)" class="small" color="success" type="filled" @click="setAudited(detail.bookingId, detail.bookingDetailId, detail.bookingReference)">Auditada</vs-button>
            </h3>
            <p>
              <small
                >ID: {{ detail.bookingDetailId }}|
                {{
                  $t(resources.CreationDate.i18n) ||
                    resources.CreationDate.name
                }}: {{ detail.createdDate }} |
                {{ $t(resources.CreatedBy.i18n) || resources.CreatedBy.name }}:
                {{ detail.createdBy }} | {{ detail.bookingSourceName }} | {{ $t(resources.Stage.i18n) || resources.Stage.name }}: {{ detail.stageName }}
              </small>
            </p>

            <vs-collapse type="border" class="mt-4">
              <vs-collapse-item>
                <div slot="header" class="font-weight-bold">
                  {{
                    $t(resources.DetailPrices.i18n) ||
                      resources.DetailPrices.name
                  }}
                </div>
                <div class="text-center text-primary font-semibold">
                  {{ detail.msgCustomizedFee }}
                </div>

                <div class="vx-row mt-4">
                  <div class="vx-col w-full md:w-1/4 mb-2">
                    <label class="vs-input--label">{{
                      $t(resources.CountryOperation.i18n) ||
                        resources.CountryOperation.name
                    }}</label>
                    <v-select
                      v-model="countryOperation"
                      :clearable="true"
                      :options="countryOption"
                      :reduce="item => item.countryCode"
                      label="countryName"
                      :disabled="detail.bookingSourceId == 2 || detail.bookingSourceId == 21 ? false : true"
                    />
                  </div>
                  <div class="vx-col md:w-1/4 w-full mb-2">
                    <label class="vs-input--label">{{
                      $t(resources.Currency.i18n) || resources.Currency.name
                    }}</label>
                    <v-select
                      v-model="detail.currencyId"
                      :clearable="false"
                      :options="currencyList"
                      :reduce="data => data.id"
                      label="name"
                      :disabled="detail.bookingSourceId == 2 || detail.bookingSourceId == 21 ? false : true"
                    >
                    </v-select>
                  </div>
                  <div class="vx-col md:w-1/4 w-full mb-6">
                    <label class="vs-input--label">{{
                      $t(resources.ProductType.i18n) ||
                        resources.ProductType.name
                    }}</label>
                    <v-select
                      v-model="detail.productId"
                      :clearable="true"
                      :options="productType"
                      :reduce="product => product.id"
                      label="name"
                      :disabled="detail.bookingSourceId == 2 || detail.bookingSourceId == 21 ? false : true"
                    />
                  </div>
                  <div class="vx-col md:w-1/4 w-full mb-4">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-dollar-sign"
                        icon-no-border
                        :label="
                          $t(resources.BookingCode.i18n) ||
                            resources.BookingCode.name
                        "
                        v-model="detail.bookingReference"
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/4 w-full mb-4">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-dollar-sign"
                        icon-no-border
                        type="number"
                        :label="
                          $t(resources.NetValue.i18n) || resources.NetValue.name
                        "
                        v-model="detail.value"
                        v-on:change="sumValues(index)"
                        :disabled="detail.bookingSourceId == 2 || detail.bookingSourceId == 21 ? false : true"
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/4 w-full mb-4">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon-no-border
                        icon="icon-dollar-sign"
                        type="number"
                        :label="
                          $t(resources.TaxesValue.i18n) ||
                            resources.TaxesValue.name
                        "
                        v-model="detail.tax"
                        v-on:change="sumValues(index)"
                        :disabled="detail.bookingSourceId == 2 || detail.bookingSourceId == 21 ? false : true"
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/4 w-full mb-4">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-dollar-sign"
                        icon-no-border
                        type="number"
                        :label="
                          $t(resources.FeeValue.i18n) || resources.FeeValue.name
                        "
                        v-model="detail.fee"
                        v-on:change="sumValues(index)"
                        :disabled="detail.bookingSourceId == 2 || detail.bookingSourceId == 21 ? false : true"
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/4 w-full mb-4">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon-no-border
                        type="number"
                        icon="icon-dollar-sign"
                        :label="
                          $t(resources.MerchantValue.i18n) ||
                            resources.MerchantValue.name
                        "
                        v-model="detail.merchant"
                        v-on:change="sumValues(index)"
                      />
                    </div>
                  </div>
                  <div
                    class="vx-col md:w-1/4 w-full mb-4"
                    v-if="
                      detail.bookingSourceId != 2 &&
                        detail.bookingSourceId != 21 &&
                        emailSession != 'a.gomez@visiontravel.net' &&
                        emailSession != 'felipe@visiontravel.net' &&
                        emailSession != 'v.hernandez@visiontravelmail.net'
                    "
                  >
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-dollar-sign"
                        icon-no-border
                        type="number"
                        :label="
                          $t(resources.AgentCommissionValue.i18n) ||
                            resources.AgentCommissionValue.name
                        "
                        v-model="detail.agentCommissionValue"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/4 w-full mb-4" v-else>
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-dollar-sign"
                        icon-no-border
                        type="number"
                        :label="
                          $t(resources.AgentCommissionValue.i18n) ||
                            resources.AgentCommissionValue.name
                        "
                        v-model="detail.agentCommissionValue"
                        v-on:change="sumValues(index)"
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/4 w-full mb-4">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-dollar-sign"
                        icon-no-border
                        type="number"
                        readonly
                        :label="
                          $t(resources.BookingValue.i18n) ||
                            resources.BookingValue.name
                        "
                        v-model="detail.total"
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/4 w-full mb-4">
                    <div class="vx-col w-full">
                      <vx-tooltip
                        :text="detail.descriptionDiscountIn"
                        position="top"
                      >
                        <label for="" class="vs-input--label"
                          >{{
                            $t(resources.DiscountValue.i18n) ||
                              resources.DiscountValue.name
                          }}

                          <feather-icon icon="InfoIcon" svgClasses="h-4 w-4" />
                        </label>
                      </vx-tooltip>
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-dollar-sign"
                        icon-no-border
                        type="number"
                        v-model="detail.discount"
                        v-on:change="sumValues(index)"
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/4 w-full mb-4">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon-no-border
                        icon="icon-dollar-sign"
                        type="number"
                        :label="
                          $t(resources.FeeCustomized.i18n) ||
                            resources.FeeCustomized.name
                        "
                        v-model="detail.feeCustomized"
                        v-on:change="sumValues(index, false, true)"
                        :disabled="detail.bookingSourceId == 2 || detail.bookingSourceId == 21 ? false : true"
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/4 w-full mb-4">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon-no-border
                        icon="icon-dollar-sign"
                        type="number"
                        readonly
                        :label="
                          $t(resources.TotalValue.i18n) ||
                            resources.TotalValue.name
                        "
                        v-model="detail.totalComplete"
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/4 w-full mb-4">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon-no-border
                        icon="icon-dollar-sign"
                        type="number"
                        :readonly="
                          detail.stageId == database.stage.Issue ||
                          detail.stageId == database.stage.Risk
                            ? false
                            : true
                        "
                        :label="
                          $t(resources.AirlineCommission.i18n) ||
                            resources.AirlineCommission.name
                        "
                        v-model="detail.airLineCommissionValue"
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/4 w-full mb-4">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon-no-border
                        icon="icon-dollar-sign"
                        type="number"
                        :label="
                          $t(resources.OtherValue.i18n) ||
                            resources.OtherValue.name
                        "
                        v-model="detail.otherValue"
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/4 w-full">
                    <ul class="centerx" style="margin-top: 20px">
                      <li>
                        <vs-checkbox v-model="detail.isChange"
                          >¿Es un cambio?</vs-checkbox
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <vs-divider color="success" class="mt-4 mb-2">
                  <h5 class="text-success">
                    {{
                      $t(resources.Commissions.i18n) ||
                        resources.Commissions.name
                    }}
                  </h5>
                </vs-divider>

                <div class="vx-row mb-4">
                  <div class="vx-col md:w-1/5 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-hash"
                        icon-no-border
                        :readonly="
                          detail.stageId == database.stage.Billing
                            ? false
                            : true
                        "
                        :label="
                          $t(resources.OrderNumber.i18n) ||
                            resources.OrderNumber.name
                        "
                        v-model="detail.infotraxOrder"
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/5 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon-no-border
                        icon="icon-calendar"
                        readonly
                        :label="
                          $t(resources.Period.i18n) || resources.Period.name
                        "
                        v-model="detail.infotraxWeek"
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/5 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon-no-border
                        icon="icon-calendar"
                        :readonly="
                          detail.stageId == database.stage.Billing
                            ? false
                            : true
                        "
                        :label="
                          $t(resources.CreationDate.i18n) ||
                            resources.CreationDate.name
                        "
                        v-model="detail.creationDateOrder"
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/5 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-dollar-sign"
                        icon-no-border
                        :readonly="
                          detail.stageId == database.stage.Billing
                            ? false
                            : true
                        "
                        :label="
                          $t(resources.Value.i18n) || resources.Value.name
                        "
                        v-model="detail.itacommission"
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/5 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-package"
                        icon-no-border
                        :readonly="
                          detail.stageId == database.stage.Billing
                            ? false
                            : true
                        "
                        :label="
                          $t(resources.BVPoints.i18n) || resources.BVPoints.name
                        "
                        v-model="detail.bvPoints"
                      />
                    </div>
                  </div>
                </div>

                <vs-row
                  vs-type="flex"
                  vs-justify="flex-end"
                  v-if="detail.allowEdit ||
                  (emailSession == 'a.gomez@visiontravel.net'
                  || emailSession == 'v.hernandez@visiontravelmail.net'
                  || emailSession == 'm.agudelo@visiontravelmail.net')
                  "
                >
                  <vs-button class="mr-0 mb-2" @click="postDetail(detail)">{{
                    $t(resources.Save.i18n) || resources.Save.name
                  }}</vs-button>
                </vs-row>
              </vs-collapse-item>
            </vs-collapse>
            <vs-tabs style="width: auto; height: auto; overflow: auto">
              <vs-tab
                :label="
                  $t(resources.Passengers.i18n) || resources.Passengers.name
                "
              >
                <passenger
                  :bookingId="`${bookingId}`"
                  :productId="`${detail.productId}`"
                  :bookingDetailId="`${detail.bookingDetailId}`"
                ></passenger>
              </vs-tab>
              <vs-tab
                :label="
                  $t(resources.Itinerary.i18n) || resources.Passengers.name
                "
              >
                <itinerary
                  :bookingId="`${bookingId}`"
                  :productId="detail.productId"
                  :bookingDetailId="detail.bookingDetailId"
                ></itinerary>
              </vs-tab>
              <vs-tab
                :label="$t(resources.Tracking.i18n) || resources.Tracking.name"
              >
                <tracking
                  :bookingId="`${bookingId}`"
                  :bookingDetailId="`${detail.bookingDetailId}`"
                  :stage="detail.stageId"
                  :allowEdit="true"
                ></tracking>
              </vs-tab>
            </vs-tabs>
            <vs-row vs-type="flex" vs-justify="center" class="mb-base mt-2">
            <!-- <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="3"
              v-if="(allowToCancelList.filter(x => x == detail.supplierId)).length > 0"
            >
              <vs-button
                color="danger"
                type="filled"
                @click="popupCancel = true"
                >{{ $t(resources.Cancel.i18n) || resources.Cancel.name }}</vs-button
              >
            </vs-col> -->
          </vs-row>
          </vx-card>
        </vs-tab>
        <vs-tab
          :label="$t(resources.Payment.i18n) || resources.Payment.name"
          icon-pack="feather"
          icon="icon-credit-card"
        >
          <payment
            v-if="bookingDetail.length > 0"
            :bookingId="`${bookingId}`"
            :countryOperationCode="`${countryOperation}`"
            :productId="productId"
            :supplierId="supplierId"
            :currencyCode="`${bookingDetail[0].currencyCode}`"
            :contactId="bookingDetail[0].contactId"
            :itaContactId="bookingDetail[0].itaContactId"
            :bookingList="detailsList"
            :iboCode="itaCode"
          ></payment>
        </vs-tab>
        <vs-tab
          :label="$t(resources.Comments.i18n) || t(resources.Comments.name)"
          icon-pack="feather"
          icon="icon-message-circle"
          @click="getComments"
        >
          <div class="vx-row">
            <div class="vx-col w-full mb-4">
              <vx-card>
                <div>
                  <h4 class="mb-2">
                    {{
                      $t(resources.Comments.i18n) || t(resources.Comments.name)
                    }}
                  </h4>
                  <div class="vx-row">
                    <vs-input
                      class="vx-col w-full md:w-1/2 mb-2 inputx"
                      :label="
                        $t(resources.Subject.i18n) || t(resources.Subject.name)
                      "
                      v-model="remarkDto.subject"
                    />
                    <div class="vx-col md:w-1/2 w-full">
                      <label class="btn btn-primary">{{
                        $t(resources.Attachment.i18n) ||
                          t(resources.Attachment.name)
                      }}</label>
                      <vs-input
                        type="file"
                        class="w-full"
                        @change="onFileSelected"
                      />
                    </div>
                  </div>
                  <vs-textarea
                    :label="
                      $t(resources.Comments.i18n) || t(resources.Comments.name)
                    "
                    class="vx-col w-full md:w-1/2 mb-4"
                    v-model="remarkDto.remark"
                    maxlength="9000"
                  />
                  <div class="vx-col md:w-1/4 w-full mb-2">
                    <ul class="vx-col w-full" style="margin-top: 20px">
                      <li>
                        <vs-checkbox v-model="remarkDto.publish">{{
                          $t(resources.RemarkPublish.i18n) ||
                            resources.RemarkPublish.name
                        }}</vs-checkbox>
                      </li>
                    </ul>
                  </div>
                  <vs-button
                    color="primary"
                    type="filled"
                    class="mb-4"
                    @click="getuploadForm()"
                    >{{
                      $t(resources.Save.i18n) || t(resources.Save.name)
                    }}</vs-button
                  >
                </div>
                <vx-timeline :data="comments" />
              </vx-card>
            </div>
          </div>
        </vs-tab>

        <vs-tab
          :label="$t(resources.Documents.i18n) || t(resources.Documents.name)"
          icon-pack="feather"
          icon="icon-file"
          @click="getDocuments"
        >
          <div class="vx-row">
            <div class="vx-col w-full mb-4">
              <vx-card>
                <ul class="vx-timeline">
                  <li v-for="item in documents" :key="item.id" class="mb-4">
                    <div class="timeline-icon" :class="`bg-${item.color}`">
                      <feather-icon
                        icon="FileIcon"
                        svgClasses="text-white stroke-current w-5 h-5"
                      />
                    </div>
                    <small class="activity-e-time">{{ item.time }}</small>
                    <p class="font-semibold">
                      {{
                        $t(resources.CreatedBy.i18n) ||
                          resources.CreatedBy.name
                      }}: {{ item.user }}
                    </p>
                    <div class="timeline-info mt-1">
                      <p class="font-semibold mb-2">
                        {{
                          $t(resources.Subject.i18n) || resources.Subject.name
                        }}: {{ item.title }}
                      </p>
                      <a @click="openDocument(item.url)">{{
                        $t(resources.OpenDocument.i18n) ||
                          resources.OpenDocument.name
                      }}</a>
                    </div>
                  </li>
                </ul>
              </vx-card>
            </div>
          </div>
        </vs-tab>
        <vs-tab
          style="align-text: right"
          :label="$t(resources.Email.i18n) || t(resources.Email.name)"
          icon-pack="feather"
          icon="icon-mail"
        >
          <newMessage
            :identifier="bookingId"
            :entityId="other.entity.booking"
            :type="1"
            :emailTo="`${voucherEmail};${iboEmail};${email}`"
          />
        </vs-tab>
        <vs-tab
          :label="$t(resources.BillingData.i18n) || resources.BillingData.name"
          icon-pack="feather"
          icon="icon-clipboard"
        >
          <billing-data
            :bookingId="bookingId"
            :allowEdit="bookingDetail[0].allowEdit"
          ></billing-data>
        </vs-tab>
      </vs-tabs>
    </div>

    <vs-popup
      :title="$t(resources.Create.i18n) || resources.Create.name"
      :active.sync="popupAddDetail"
    >
      <div class="vx-row mb-base">
        <div class="vx-col md:w-1/2 w-full">
          <label class="vs-input--label">{{
            $t(resources.Currency.i18n) || resources.Currency.name
          }}</label>
          <v-select
            v-model="createDetail.currencyId"
            :clearable="false"
            :options="currencyList"
            :reduce="data => data.id"
            label="name"
          >
          </v-select>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <label class="vs-input--label">{{
            $t(resources.ProductType.i18n) || resources.ProductType.name
          }}</label>
          <v-select
            v-model="createDetail.productId"
            :clearable="true"
            :options="productType"
            :reduce="product => product.id"
            label="name"
          />
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-dollar-sign"
              icon-no-border
              :label="
                $t(resources.BookingCode.i18n) || resources.BookingCode.name
              "
              v-model="createDetail.bookingReference"
              @blur="
                createDetail.bookingReference = createDetail.bookingReference.replace(
                  /\s+/g,
                  ''
                )
              "
            />
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-dollar-sign"
              icon-no-border
              type="number"
              :label="$t(resources.NetValue.i18n) || resources.NetValue.name"
              v-model="createDetail.value"
              v-on:change="sumValues(0, true)"
            />
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon-no-border
              icon="icon-dollar-sign"
              type="number"
              :label="
                $t(resources.TaxesValue.i18n) || resources.TaxesValue.name
              "
              v-model="createDetail.tax"
              v-on:change="sumValues(0, true)"
            />
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-dollar-sign"
              icon-no-border
              type="number"
              :label="$t(resources.FeeValue.i18n) || resources.FeeValue.name"
              v-model="createDetail.fee"
              v-on:change="sumValues(0, true)"
            />
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon-no-border
              type="number"
              icon="icon-dollar-sign"
              :label="
                $t(resources.MerchantValue.i18n) || resources.MerchantValue.name
              "
              v-model="createDetail.merchant"
              v-on:change="sumValues(0, true)"
            />
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-dollar-sign"
              icon-no-border
              type="number"
              :label="
                $t(resources.DiscountValue.i18n) || resources.DiscountValue.name
              "
              v-model="createDetail.discount"
              v-on:change="sumValues(0, true)"
            />
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-dollar-sign"
              icon-no-border
              type="number"
              :label="
                $t(resources.AgentCommissionValue.i18n) ||
                  resources.AgentCommissionValue.name
              "
              v-model="createDetail.agentCommission"
              v-on:change="sumValues(0, true)"
            />
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-dollar-sign"
              icon-no-border
              type="number"
              readonly
              :label="
                $t(resources.BookingValue.i18n) || resources.BookingValue.name
              "
              v-model="createDetail.total"
            />
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon-no-border
              icon="icon-dollar-sign"
              type="number"
              :label="
                $t(resources.FeeCustomized.i18n) || resources.FeeCustomized.name
              "
              v-model="createDetail.feeCustomized"
              v-on:change="sumValues(0, true)"
            />
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon-no-border
              icon="icon-dollar-sign"
              type="number"
              readonly
              :label="
                $t(resources.TotalValue.i18n) || resources.TotalValue.name
              "
              v-model="createDetail.totalValue"
            />
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-user"
              :label="$t(resources.CreatedBy.i18n) || resources.CreatedBy.name"
              v-model="createDetail.createdBy"
              readonly
            />
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <label class="vs-input--label">{{
            $t(resources.ReferenceCode.i18n) || resources.ReferenceCode.name
          }}</label>
          <v-select
            v-model="createDetail.bookingDetailId"
            :clearable="false"
            :options="bookingDetail"
            :reduce="data => data.bookingDetailId"
            label="bookingReference"
          >
          </v-select>
        </div>
        <div
          class="vx-col md:w-1/2 mb-2 w-full"
        >
          <label class="vs-input--label">{{
            $t(resources.Supplier.i18n) || resources.Supplier.name
          }}</label>
          <v-select
            v-model="createDetail.supplierId"
            :clearable="true"
            :options="supplierOption"
            :placeholder="$t(resources.Select.i18n) || resources.Select.name"
            :reduce="item => item.id"
            label="name"
          />
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <ul class="centerx" style="margin-top: 20px">
            <li>
              <vs-checkbox v-model="createDetail.isChange"
                >¿Es un cambio?</vs-checkbox
              >
            </li>
          </ul>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <ul class="centerx" style="margin-top: 20px">
            <li>
              <vs-checkbox v-model="createDetail.copyItinerary"
                >Copiar itinerarios</vs-checkbox
              >
            </li>
          </ul>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <ul class="centerx" style="margin-top: 20px">
            <li>
              <vs-checkbox v-model="createDetail.copyPassenger"
                >Copiar Pasajeros</vs-checkbox
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="vx-col mb-2 mt-5">
        <vs-button color="primary" type="filled" @click="saveDetail()">
          {{ $t(resources.Save.i18n) || resources.Save.name }}
        </vs-button>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import axios from "axios";
import resources from "@/i18n/resources.js";
import VxTimeline from "@/components/timeline/VxTimeline";
import tracking from "../booking/tracking.vue";
import itinerary from "../booking/itinerary.vue";
import payment from "../booking/payment.vue";
import utilities from "@/assets/resources/utilities.json";
import newMessage from "../email/newMessage.vue";
import passenger from "../booking/passenger.vue";
import other from "@/assets/resources/enums/other.json";
import database from "@/assets/resources/enums/database.json";
import alerts from "@/i18n/alerts.js";
import vSelect from "vue-select";
import BillingData from './billingData.vue';

export default {
  components: {
    VxTimeline,
    tracking,
    itinerary,
    payment,
    newMessage,
    passenger,
    vSelect,
    BillingData
  },
  data() {
    return {
      emailSession: JSON.parse(localStorage.getItem("userInfo")).email,
      alerts: alerts,
      resources: resources,
      database: database,
      other: other,
      isNavOpen: false,
      document: "",
      name: "",
      lastName: "",
      comments: [],
      utilities: utilities,
      timelineDocuments: [],
      currencyList: [],
      countryOption: [],
      trace: [],
      expirateDate: null,
      bookingId: 0,
      isManaged: false,
      managedSot: false,
      accountNumber: 0,
      iboEmail: "",
      contactName: "",
      email: "",
      countryOperation: "",
      bookingCode: "",
      source: "",
      netValue: 0,
      taxValue: 0,
      feeValue: 0,
      feeCustomized: 0,
      merchantValue: 0,
      agentCommissionValue: 0,
      discountValue: 0,
      totalValue: 0,
      otherValue: 0,
      bookingDetail: [],
      detailsList: [],
      documents: [],
      options2: [
        { text: "Cedula", value: 1 },
        { text: "ID", value: 7 },
        { text: "Pasaporte", value: 3 }
      ],
      loading: false,
      log: [],
      remarkDto: {},
      itaCode: "",
      listFee: [],
      remark: "",
      popupObservation: false,
      voucherEmail: "",
      productType: [],
      supplierOption: [],
      popupAddDetail: false,
      invoice: "",
      createDetail: {
        bookingId: 0,
        bookingDetailId: 0,
        bookingReference: "",
        productId: 0,
        currencyId: 0,
        bookingSourceId: 2,
        fee: 0,
        tax: 0,
        merchant: 0,
        feeCustomized: 0,
        total: 0,
        value: 0,
        discount: 0,
        discountIn: 0,
        createdBy: JSON.parse(localStorage.getItem("userInfo")).email,
        airLineCommissionValue: 0,
        rateValue: 0,
        agentCommission: 0,
        language: this.$i18n.locale.toUpperCase(),
        supplierId: 0,
        isChange: false,
        copyItinerary: true,
        copyPassenger: true,
        totalValue: 0
      },
      popupCancel: false,
      allowToCancelList: [],
      productId: 0,
      supplierId: 0
    };
  },
  methods: {
    async getProductType() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Product/ByType/${this.$i18n.locale.toUpperCase()}/1`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) this.productType = result.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCountryOperation() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }CountryOperation/GetPccCountry/${this.$i18n.locale.toUpperCase()}/1`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.warningNotify();
            this.countryOption = [];
          } else {
            this.countryOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCurrency() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Currency/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.currencyList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getSupplier() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Supplier`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data.length == 0) {
            this.supplierOption = [];
          } else {
            this.supplierOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    getFeeConfiguration(item) {
      let _res = this.listFee.find(x => x.productId == item);
      let _message = `${this.$i18n
        .t("MsgCustomizedFee")
        .replace("{0}", "0")
        .replace("{1}", "%")}`;

      if (_res != undefined && _res != null) {
        let _isPercent = _res.isPercentage ? "%" : "";
        _message = `${this.$i18n
          .t("MsgCustomizedFee")
          .replace("{0}", _res.value)
          .replace("{1}", _isPercent)}`;
      }
      return _message;
    },

    async getDetail() {
      this.netValue = 0;
      this.taxValue = 0;
      this.feeValue = 0;
      this.feeCustomized = 0;
      this.merchantValue = 0;
      this.discountValue = 0;
      this.totalValue = 0;
      this.otherValue = 0;
      this.agentCommissionValue = 0;

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Booking/ListByFilters`,
        data: {
          searchKeys: {
            bookingid: this.bookingId,
            onlyDetail: true
          },
          page: 1,
          rows: 10,
          language: this.$i18n.locale.toUpperCase()
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (result.data.success) {
            this.itaCode = `${result.data.data[0].itaCode}`;
            this.isManaged = result.data.data[0].isManaged;
            this.managedSot = result.data.data[0].managedSot;
            await this.getFeeProduct();

            this.accountNumber = `${result.data.data[0].itaCode} - ${result.data.data[0].itaName} ${result.data.data[0].itaLastName}`;
            this.iboEmail = result.data.data[0].itaEmail;
            this.contactName = `${result.data.data[0].contactName} ${result.data.data[0].contactLastName}`;
            this.email = `${result.data.data[0].contactEmail}`;
            this.countryOperation = `${result.data.data[0].countryCode}`;
            this.bookingCode = result.data.data[0].bookingCode;
            this.source = result.data.data[0].bookingSourceName;
            this.voucherEmail = result.data.data[0].voucherEmail;
            this.bookingDetail = result.data.data;

            let _isMain = this.bookingDetail.filter(x => x.isMain).length > 0
            this.supplierId = _isMain ? this.bookingDetail.find(x =>  x.isMain).supplierId : this.bookingDetail.find(x => x.supplierId != 0).supplierId
            this.productId = _isMain ? this.bookingDetail.find(x =>  x.isMain).productId : this.bookingDetail.find(x => x.supplierId != 0).productId
            this.invoice = result.data.data[0].invoice;
            this.bookingDetail
              .filter(x => x.stageId != this.database.stage.Cancellations)
              .forEach(item => {
                this.netValue += item.value;
                this.taxValue += item.tax;
                this.feeValue += item.fee;
                this.feeCustomized += item.feeCustomized;
                this.merchantValue += item.merchant;
                this.discountValue += item.discount;
                this.agentCommissionValue += item.agentCommissionValue;
                this.otherValue += item.otherValue;
                this.totalValue += item.total;
                item["msgCustomizedFee"] = this.getFeeConfiguration(
                  item.productId
                );

                // if(!item.allowEdit){
                //   if(this.emailSession == 'a.gomez@visiontravel.net' || this.emailSession == 'v.hernandez@visiontravelmail.net')
                //     item.allowEdit = true
                //   else  if((item.stageId == 33 || item.stageId == 7 || item.stageId == 5) && (this.emailSession == 'a.holguin@visiontravelmail.net' || this.emailSession == "l.palacio@visiontravelmail.net" || this.emailSession == 'g.sepulveda@visiontravelmail.net'))
                //     item.allowEdit = true;
                //   else
                //     item.allowEdit = false;
                // }

                this.allowEdit = true;

                this.detailsList.push({
                  id: item.bookingDetailId,
                  value: item.bookingReference,
                  productId: item.productId,
                  supplierId: item.supplierId,
                  totalComplete: item.totalComplete,
                  isMain: item.isMain
                });
              });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async postDetail(item) {
      this.$vs.loading();

      item.airLineCommissionValue = parseFloat(item.airLineCommissionValue);
      item.feeCustomized = parseFloat(item.feeCustomized);
      item.language = this.$i18n.locale.toUpperCase();
      item.remark = this.remark;
      item.updatedBy = this.emailSession;
      item.countryOperationId = this.countryOption.find(
        x => x.countryCode === this.countryOperation
      ).countryId;

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}BookingDetail/Update`,
        data: item,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved") + "<br/>" + result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            await this.getDetail();
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async getComments() {
      this.comments = [];
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Remarks/Search`,
        data: {
          searchKeys: {
            identifier: this.bookingId,
            entityid: this.utilities.entity.booking
          },
          page: 1,
          rows: 100,
          language: this.$i18n.locale.toUpperCase()
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.comments = [];
          } else {
            let response = result.data.data;
            for (var i = 0; i < response.length; i++) {
              var color = "primary";
              var icon = "MessageSquareIcon";

              if (response[i].type == 0 && response[i].publish)
                color = "success";
              else if (response[i].type == 0 && !response[i].publish)
                color = "danger";
              else if (response[i].type == 2 && response[i].publish) {
                color = "warning";
                icon = "AlertTriangleIcon";
              }

              this.comments.push({
                idx: i,
                id: response[i].id,
                color: color,
                icon: response[i].type == 1 ? "MailIcon" : icon,
                title: response[i].subject,
                desc: response[i].remark,
                time: response[i].creationDate,
                user: response[i].createdBy,
                type: response[i].type,
                url: response[i].pathUrl
              });
            }
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getDocuments() {
      this.documents = [];
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Remarks/Search`,
        data: {
          searchKeys: {
            identifier: this.bookingId,
            entityid: this.utilities.entity.booking,
            hasattachment: true
          },
          page: 1,
          rows: 20,
          language: this.$i18n.locale.toUpperCase()
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.documents = [];
          } else {
            let response = result.data.data;
            for (var i = 0; i < response.length; i++) {
              this.documents.push({
                id: i,
                color: "success",
                title: response[i].subject,
                time: response[i].creationDate,
                user: response[i].createdBy,
                url: response[i].pathUrl
              });
            }
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getuploadForm() {
      this.$vs.loading();

      let _isPublish = false;
      if (this.remarkDto.publish) _isPublish = this.remarkDto.publish;

      this.comments = [];
      let contactInfo = JSON.parse(localStorage.getItem("userInfo"));
      let formData = new FormData();
      formData.set("identifier", this.bookingId);
      formData.set("entityId", this.utilities.entity.booking);
      formData.set("remark", this.remarkDto.remark);
      formData.set("subject", this.remarkDto.subject);
      formData.set("type", 0);
      formData.set("createdBy", contactInfo.email);
      formData.set("sender", contactInfo.email);
      formData.set("language", this.$i18n.locale.toUpperCase());
      formData.set("publish", _isPublish);
      if (this.remarkDto.file != null)
        formData.append("file", this.remarkDto.file, this.remarkDto.file.Name);
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Remarks`,
        data: formData,
        headers: {
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
          "Content-Type": "multipart/form-data"
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.remarkDto = {};
            this.getComments();
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async getFeeProduct() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }ChargeSetting/ByIta/${this.$i18n.locale.toUpperCase()}/${
          this.itaCode
        }/1`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.listFee = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async saveDetail() {
      this.createDetail.bookingId = this.bookingId;

      if (
        this.createDetail.supplierId == null ||
        this.createDetail.supplierId == "" ||
        this.createDetail.supplierId == 0
      ) {
        this.$vs.notify({
          title: this.$i18n.t("Alert"),
          text: "El campo PROVEEDOR es requerido",
          color: "warning",
          position: "top-right",
        });
        return;
      }

      this.$vs.loading();

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}BookingDetail/Create`,
        data: this.createDetail,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.popupAddDetail = false;
            this.createDetail = {
              bookingId: this.bookingId,
              bookingDetailId: 0,
              bookingReference: "",
              productId: 0,
              currencyId: 0,
              bookingSourceId: 2,
              fee: 0,
              tax: 0,
              merchant: 0,
              feeCustomized: 0,
              total: 0,
              value: 0,
              discount: 0,
              discountIn: 0,
              createdBy: JSON.parse(localStorage.getItem("userInfo")).email,
              airLineCommissionValue: 0,
              rateValue: 0,
              agentCommission: 0,
              supplierId: 0,
              language: this.$i18n.locale.toUpperCase()
            };
            await this.getDetail();
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async validateInvoice() {
      if (this.bookingDetail[0].currencyCode == "COP") {
        this.$vs.dialog({
          type: "confirm",
          color: "warning",
          title: this.$i18n.t("Success"),
          text: `¿Está seguro que desea generar la factura de la reserva #${this.bookingCode}?`,
          accept: this.generateInvoice
        });
      }
    },

    async generateInvoice() {
      this.$vs.loading();
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_SIIGO}invoices/create`,
        data: {
          bookingId: this.bookingId,
          language: this.$i18n.locale.toUpperCase()
        },
        headers: {
          "content-type": "application/json",
          "accept-language": "es"
        }
      }).then(
        async result => {
          if (
            result.data != undefined &&
            result.data != null &&
            result.data[0].success
          ) {
            this.$vs.dialog({
              color: "success",
              title: this.$i18n.t("Success"),
              text: `Se ha generado la factura con Nro. ${result.data[0].message.idNameInvoice}`
            });
            await this.getDetail();
          } else {
            this.$vs.dialog({
              color: "warning",
              title: this.$i18n.t("Alert"),
              text: `${result.data[0].error} -
                    ${result.data[0].statusText}`
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async allowSupplier() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }ParameterAplication/AllowToCancel/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("tokenVT")
        }
      }).then(
        async result => {
            this.allowToCancelList = result.data.value.split(",");
            //let _allow = _list.filter(x => x == _user.email);
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async setAudited(bookingId,bookingDetailId,bookingCode) {
      await axios({
        method: "POST",
        url: `${
          process.env.VUE_APP_APIVT
        }BookingDetail/UpdateStage/${this.$i18n.locale.toUpperCase()}`,
        data: {
          bookingId: bookingId,
          bookingDetailId: bookingDetailId,
          stageId: 39,
          remark: `Se marca como auditada el detalle #${bookingCode}`,
          createdBy: this.emailSession,
          isManaged: false,
          managedSot: false,
          responsibleId: 0
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved") + "<br/>" + result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.stage = this.stageId;
            await this.getTracking();
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },

    onlyText($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (
        (keyCode < 65 || keyCode > 122 || keyCode == 0) &&
        keyCode !== 241 &&
        keyCode !== 32
      ) {
        $event.preventDefault();
      }
    },

    onFileSelected(event) {
      this.remarkDto.file = event.target.files[0];
    },

    sumValues(index, isCreate, openPopup) {
      if (isCreate) {
        parseFloat(
          (this.createDetail.total =
            parseFloat(this.createDetail.value) +
            parseFloat(this.createDetail.tax) +
            parseFloat(this.createDetail.fee) +
            parseFloat(this.createDetail.merchant) +
            parseFloat(this.createDetail.agentCommission))
        ).toFixed(2);

        this.createDetail.totalValue = parseFloat(this.createDetail.total + parseFloat(this.createDetail.feeCustomized)).toFixed(2);
      } else {
        parseFloat(
          (this.bookingDetail[index].total =
            parseFloat(this.bookingDetail[index].value) +
            parseFloat(this.bookingDetail[index].tax) +
            parseFloat(this.bookingDetail[index].fee) +
            parseFloat(this.bookingDetail[index].merchant) +
            parseFloat(this.bookingDetail[index].agentCommissionValue))
        ).toFixed(2);

        parseFloat(
          (this.bookingDetail[index].totalComplete =
            parseFloat(this.bookingDetail[index].total) +
            parseFloat(this.bookingDetail[index].feeCustomized) +
            parseFloat(this.bookingDetail[index].discount))
        ).toFixed(2);

        if (openPopup) this.popupObservation = true;
      }
    },

    openDocument(url) {
      window.open(url);
    },
  },
  async created() {
    this.$vs.loading();

    this.bookingId = this.$route.params.bookingId;
    await this.getSupplier();
    await this.getCountryOperation();
    await this.getCurrency();
    await this.getProductType();
    await this.getDetail();
    await this.allowSupplier();

    this.$emit("init");
    this.$vs.loading.close();
  }
};
</script>
<style lang="stylus">
.overFlow {
  overflow-x: hidden;
}

.popup-example {
  .vs-input {
    float: left;
    width: 50%;
    margin: 10px;
    margin-top: 5px;
  }

  .con-select {
    margin-left: 10px;
    width: 50%;
    margin-bottom: 10px;
  }
}
</style>
