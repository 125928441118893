<template>
  <div>
    <vx-card class="mb-4" :title="$t(resources.List.i18n) || resources.List.name">
      <div class="vx-row">
        <div class="vx-col w-full md:w-1/4 mb-2">
          <label class="vs-input--label">{{$t(resources.Status.i18n) || resources.Status.name}}</label>
          <v-select
            v-model="statusId"
            :clearable="false"
            :options="statusList"
            :reduce="status=> status.value"
            label="text"
          />
        </div>
        <div class="vx-col w-full md:w-1/4 mb-2" v-if="statusId == '3' || statusId == '0'">
          <label>
            {{
            $t(resources.StartDate.i18n) || resources.StartDate.name
            }}
          </label>
          <datepicker :language="languages[language]" format="d MMMM yyyy" v-model="start_date"></datepicker>
        </div>
        <div class="vx-col w-full md:w-1/4 mb-2" v-if="statusId == '3' || statusId == '0'">
          <label>{{ $t(resources.EndDate.i18n) || resources.EndDate.name }}</label>
          <datepicker :language="languages[language]" format="d MMMM yyyy" v-model="end_date"></datepicker>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2 mt-5">
          <vs-button color="primary" type="filled" @click="getTransactions()">
            {{
            $t(resources.Search.i18n) || resources.Search.name
            }}
          </vs-button>
        </div>
      </div>
    </vx-card>

    <div id="ag-grid-demo">
      <vx-card>
        <div class="flex flex-wrap justify-between items-center">
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2">
                  {{
                  currentPage * paginationPageSize - (paginationPageSize - 1)
                  }}
                  -
                  {{
                  dataList.length - currentPage * paginationPageSize > 0
                  ? currentPage * paginationPageSize
                  : dataList.length
                  }}
                  of {{ dataList.length }}
                </span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <!-- <div class="mb-4"> -->

          <!-- </div> -->

          <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              :placeholder="$t(resources.Search.i18n) || resources.Search.name"
            />
            <vs-button class="mb-4 md:mb-0 mr-1" @click="gridApi.exportDataAsCsv()">
              {{
              $t(resources.ExportAs.i18n) || resources.ExportAs.name
              }}
            </vs-button>
          </div>
        </div>

        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="dataList"
          rowSelection="single"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          @row-selected="openConfirm"
        ></ag-grid-vue>
        <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" />
      </vx-card>
    </div>
  </div>
</template>
<script>
import resources from "@/i18n/resources.js";
import axios from "axios";
import vSelect from "vue-select";
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";

export default {
  components: {
    vSelect,
    AgGridVue,
    Datepicker
  },
  data() {
    return {
      resources: resources,
      language: this.$i18n.locale,
      languages: lang,
      start_date: new Date(),
      end_date: new Date(),
      statusId: "2",
      statusList: [
        {
          text: "Todos",
          value: "0"
        },
        {
          text: "Verificación",
          value: "2"
        },
        {
          text: "Aprobada",
          value: "3"
        }
      ],
      transactionId: "",
      email: JSON.parse(localStorage.getItem("userInfo")).email,
      nodeItaCode: "",
      dataList: [],
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: this.$i18n.t("Status"),
          pinned: "left",
          field: "Activo",
          width: 160,
          cellRenderer: function(event) {
            let object = "";
            if (
              parseFloat(event.node.data.Precio) >
              parseFloat(event.node.data.TotalPagos)
            ) {
              object =
                '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-danger), 1);">highlight_off</i>';
            } else {
              object =
                '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-success), 1);">check_circle</i>';
            }

            return `${object} ${event.node.data.NombreEstado}`;
          }
        },
        {
          headerName: "#",
          field: "Id",
          filter: true,
          width: 120
        },
        {
          headerName: this.$i18n.t("DistributorID"),
          field: "CodigoIta",
          filter: true,
          width: 130
        },

        {
          headerName: this.$i18n.t("Name"),
          field: "NombreIta",
          filter: true
        },
        {
          headerName: this.$i18n.t("Code"),
          field: "CodigoProducto",
          filter: true,
          width: 150
        },
        {
          headerName: this.$i18n.t("Country"),
          field: "PaisOperacion",
          filter: true,
          width: 100
        },
        {
          headerName: this.$i18n.t("Price"),
          field: "Precio",
          filter: true,
          width: 100
        },
        {
          headerName: this.$i18n.t("PaymentsReceived"),
          field: "TotalPagos",
          filter: true,
          width: 120
        },
        {
          headerName: this.$i18n.t("Period"),
          field: "SemanaInfotrax",
          filter: true,
          width: 120
        },
        {
          headerName: this.$i18n.t("OrderNumber"),
          field: "OrdenIfx",
          filter: true,
          width: 120
        },
        {
          headerName: this.$i18n.t("Responsable"),
          field: "Responsable",
          filter: true
        },
        {
          headerName: this.$i18n.t("ChangeDate"),
          field: "FechaEstadoAprobada",
          filter: true
        }
      ]
    };
  },
  created() {
    this.getTransactions();
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("level", null);
      } else this.gridOptions.columnApi.setColumnPinned("level", "left");
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 20;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    }
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    async getTransactions() {
      this.$vs.loading();
      let startDate = "";
      let endDate = "";
      let data = {
        statusId: this.statusId
      };

      if (this.start_date != null && this.end_date != null) {
        startDate = new Date(this.start_date).toLocaleDateString("en-US");
        endDate = new Date(this.end_date).toLocaleDateString("en-US");
      }

      if (this.statusId == "3" || this.statusId == "0") {
        data = {
          statusId: this.statusId,
          start_date: startDate,
          end_date: endDate
        };
      }

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_OVAPI}order/getTransactions`,
        data: data,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("loggedIn")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.dataList = [];
          } else {
            this.dataList = result.data[0];
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    openConfirm(event) {
      this.transactionId = event.node.data.Id;
      this.nodeItaCode = event.node.data.CodigoIta;

      if (
        event.node.data.TotalPagos >= event.node.data.Precio &&
        event.node.data.IdEstado == "2"
      ) {
        this.$vs.dialog({
          type: "confirm",
          color: "warning",
          title: `${this.$i18n.t("TransactionNumber")}: ${this.transactionId}`,
          text: this.$i18n
            .t("MsgCreateTransaction")
            .replace("{0}", this.nodeItaCode),
          accept: this.createTransaction
        });
      } else {
        this.$vs.notify({
          title: this.$i18n.t("Error"),
          text: this.$i18n.t("MsgPaymentNotComplete"),
          color: "warning",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-x-circle"
        });
      }
    },

    async createTransaction() {
      this.$vs.loading();

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_OVAPI}order/createTransactions`,
        data: {
          transactionId: this.transactionId,
          createdBy: this.email
        },
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("loggedIn")
        }
      }).then(
        result => {
          if (result.data.code == 500) {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError"),
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-check"
            });
            setTimeout(() => {
              this.$vs.loading.close();
              window.location.reload();
            }, 1000);
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  }
};
</script>