<template>
  <form>
    <div class="vx-row mb-2">
      <div class="vx-col md:w-1/6 w-full mb-base">
        <div class="vx-col w-full">
          <label class="vs-input--label">{{
            $t(resources.Type.i18n) || resources.Type.name
          }}</label>
          <v-select
            type="text"
            class="w-full"
            icon-pack="feather"
            icon="icon-arrow-down"
            icon-no-border
            :clearable="true"
            :options="typeOption"
            :reduce="x => x.id"
            label="name"
            v-model="type"
          />
        </div>
      </div>
      <div class="vx-col md:w-1/6 w-full mb-base">
        <div class="vx-col w-full">
          <label class="vs-input--label">{{
            $t(resources.ContentType.i18n) || resources.ContentType.name
          }}</label>
          <v-select
            type="text"
            class="w-full"
            icon-pack="feather"
            icon="icon-arrow-down"
            icon-no-border
            :clearable="true"
            :options="contentTypeOption"
            :reduce="x => x.id"
            label="name"
            v-model="contentType"
          />
        </div>
      </div>
      <div class="vx-col md:w-1/6 w-full">
        <label class="vs-input--label">{{
          $t(resources.Language.i18n) || resources.Language.name
        }}</label>
        <v-select
          type="text"
          class="w-full"
          icon-pack="feather"
          icon="icon-arrow-down"
          icon-no-border
          :clearable="true"
          :options="languageOption"
          :reduce="language => language.internationalStandard"
          label="name"
          v-model="language"
        />
      </div>
      <div class="vx-col md:w-1/4 w-full">
        <label class="vs-input--label">{{
          $t(resources.Title.i18n) || resources.Title.name
        }}</label>
        <vs-input
          name="mailSubject"
          v-model="title"
          class="w-full"
          :maxlength="50"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('description')">{{
          errors.first("description")
        }}</span>
      </div>
      <div class="vx-col md:w-1/6 w-full mb-2">
        <ul class="vx-col w-full mt-5">
          <li>
            <vs-checkbox v-model="isFeatured">{{
              $t(resources.IsFeatured.i18n) || resources.IsFeatured.name
            }}</vs-checkbox>
          </li>
        </ul>
      </div>
    </div>
    <quill-editor v-model="description" :options="editorOption" />
    <div class="vx-col md:w-1/2 w-full mb-2 mt-2">
      <label class="btn btn-primary" style="margin-right: 80px">Adjunto</label>
      <vs-input type="file" class="w-full" @change="onFileSelected" />
    </div>
    <vs-row vs-type="flex" vs-justify="center">
      <vs-button
        type="filled"
        class="mt-5 block text-left"
        @click.prevent="submitForm"
        >{{ $t(resources.Save.i18n) || resources.Save.name }}</vs-button
      >
    </vs-row>
  </form>
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";

import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import axios from "axios";
import vSelect from "vue-select";
import utilities from "@/assets/resources/utilities.json";

export default {
  components: {
    quillEditor,
    vSelect
  },
  name: "create-blog",
  data() {
    return {
      resources: resources,
      utilities: utilities,
      alerts: alerts,
      activePrompt: false,
      email: JSON.parse(localStorage.getItem("userInfo")).email,
      title: "",
      description: "",
      attachment: null,
      type: "",
      typeOption: [],
      contentType: "",
      contentTypeOption: [],
      isFeatured: false,
      editorOption: {
        modules: {
          toolbar: [
            [
              "bold",
              "italic",
              "underline",
              "strike",
              "link",
              "blockquote",
              "code-block"
            ],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ font: [] }]
          ]
        },
        placeholder: "Message"
      },
      language: "ES",
      languageOption: []
    };
  },
  async created() {
    await this.getLanguage();
    await this.getContentType();
    await this.getType();
  },
  methods: {
    async getLanguage() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Language`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.languageOption = [];
          } else {
            this.languageOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async submitForm() {
      this.$vs.loading();
      this.$validator.validateAll().then(async result => {
        if (result) {
          await this.save();
        } else {
          // form have errors
        }
        this.$vs.loading.close();
      });
    },

    async save() {
      this.$vs.loading();

      let formData = new FormData();
      formData.set("BlogTypeId", this.type);
      formData.set("ContentTypeId", this.contentType);
      formData.set("Language", this.language);
      formData.set("Title", this.title);
      formData.set("Body", this.description);
      formData.set("CreatedBy", this.email);
      formData.set("IsFeatured", this.isFeatured);
      if (this.attachment != null)
        formData.append("File", this.attachment, this.attachment.name);

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Blog`,
        data: formData,
        headers: {
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
          "Content-Type": "multipart/form-data"
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
      // setTimeout(() => {
      //   window.location.reload();
      // }, 2000);
    },

    async getType() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }BlogType/${this.language.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.typeOption = [];
          } else {
            this.typeOption = result.data.filter(x => x.id !== 0);
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getContentType() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }ContentType/${this.language.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.contentTypeOption = [];
          } else {
            this.contentTypeOption = result.data.filter(x => x.id !== 0);
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    onFileSelected(event) {
      this.attachment = event.target.files[0];
    }
  }
};
</script>
