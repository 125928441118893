<template>
  <div>
    <vx-card class="mb-4">
      <vs-row vs-type="flex" vs-justify="center" class="mb-base mt-2">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
          <vs-button
            color="primary"
            type="filled"
            icon="add"
            @click="openAdd(false)"
            >{{ $t(resources.Add.i18n) || resources.Add.name }}</vs-button
          >
          <vs-button
            class="ml-2"
            color="rgb(27, 48, 83)"
            type="filled"
            icon="add"
            @click="openAdd(true)"
            >TC empresarial</vs-button
          >
          <vs-button
            class="ml-2"
            color="success"
            type="filled"
            icon="credit_card"
            @click="popupRecapture = true"
            >{{
              $t(resources.Recapture.i18n) || resources.Recapture.name
            }}</vs-button
          >
          <vs-button
            class="ml-2"
            color="warning"
            type="filled"
            icon="link"
            @click="popupGenerate = true"
            >{{
              $t(resources.Generate.i18n) || resources.Generate.name
            }}</vs-button
          >
          <vs-button
            class="ml-2"
            color="success"
            type="filled"
            icon="credit_card"
            @click="setAccountBlockpay()"
            >{{
              $t(resources.Recapture.i18n) || resources.Recapture.name
            }}
            Blockpay</vs-button
          >
        </vs-col>
      </vs-row>
      <div class="mt-base">
        <h3 class="text-center">
          <strong>
            {{ $t(resources.Receipts.i18n) || resources.Receipts.name }} |
            {{ $t(resources.Total.i18n) || resources.Total.name }}:</strong
          >
          {{ currencyCode }} {{ totalReceipts }}
        </h3>
        <h5
          class="text-center mt-2 font-semibold text-danger"
          v-if="totalBooking > totalReceipts"
        >
          El pago debe ser completado para emitir los servicios. Diferencia por:
          ${{
            parseFloat(totalBooking - totalReceipts)
              .toFixed(2)
              .toLocaleString("es-MX")
          }}
        </h5>
        <vs-table :data="receipts" class="mt-base">
          <template slot="thead">
            <vs-th>#</vs-th>
            <vs-th>{{
              $t(resources.Merchant.i18n) || resources.Merchant.name
            }}</vs-th>
            <vs-th>Id Merchant</vs-th>
            <vs-th>{{
              $t(resources.Currency.i18n) || resources.Currency.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.Value.i18n) || resources.Value.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.TransactionNumber.i18n) ||
                resources.TransactionNumber.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.CreditCardType.i18n) || resources.CreditCardType.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.CreditCardNumber.i18n) ||
                resources.CreditCardNumber.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.Status.i18n) || resources.Status.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.PaymentDate.i18n) || resources.PaymentDate.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.CreationDate.i18n) || resources.CreationDate.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.CreatedBy.i18n) || resources.CreatedBy.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.Options.i18n) || resources.Options.name
            }}</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].id">{{ data[indextr].id }}</vs-td>
              <vs-td :data="data[indextr].merchantName">{{
                data[indextr].merchantName
              }}</vs-td>
              <vs-td :data="data[indextr].transactionIdMerchant">{{
                data[indextr].transactionIdMerchant
              }}</vs-td>
              <vs-td :data="data[indextr].currencyCode">{{
                data[indextr].currencyCode
              }}</vs-td>
              <vs-td :data="data[indextr].value">{{
                data[indextr].value
              }}</vs-td>
              <vs-td :data="data[indextr].transactionNumber">{{
                data[indextr].transactionNumber
              }}</vs-td>
              <vs-td :data="data[indextr].cardType">{{
                data[indextr].cardType
              }}</vs-td>
              <vs-td
                :data="data[indextr].cardNumber"
                v-if="
                  data[indextr].cardNumber != '' &&
                    data[indextr].cardNumber != null
                "
                >****{{ data[indextr].cardNumber }}</vs-td
              >
              <vs-td :data="data[indextr].cardNumber" v-else></vs-td>
              <vs-td
                v-if="
                  data[indextr].paymentTypeId ==
                    database.merchantType.EWallet &&
                    data[indextr].statusId == status.payment.pending
                "
                :data="data[indextr].merchantType"
              >
                <vs-button
                  color="success"
                  type="line"
                  icon-pack="feather"
                  icon="icon-refresh-ccw"
                  @click="
                    paymentEwallet(
                      `${data[indextr].description}`,
                      `${data[indextr].referenceId}`
                    )
                  "
                  >{{ data[indextr].stageName }}</vs-button
                >
              </vs-td>
              <vs-td
                v-else-if="
                  data[indextr].paymentTypeId ==
                    database.merchantType.transfer &&
                    data[indextr].statusId == status.payment.pending
                "
                :data="data[indextr].paymentTypeId"
              >
                {{ data[indextr].state }}
                <vs-button
                  color="success"
                  icon-pack="feather"
                  icon="icon-check"
                  @click="
                    updateStage(`${status.payment.paid}`, `${data[indextr].id}`)
                  "
                ></vs-button>
                <vs-button
                  color="danger"
                  icon-pack="feather"
                  icon="icon-x"
                  @click="
                    updateStage(
                      `${status.payment.declined}`,
                      `${data[indextr].id}`
                    )
                  "
                ></vs-button>
              </vs-td>
              <vs-td v-else :data="data[indextr].state">{{
                data[indextr].state
              }}</vs-td>
              <vs-td :data="data[indextr].paymentDate">{{
                data[indextr].paymentDatetime
              }}</vs-td>
              <vs-td :data="data[indextr].createdDate">{{
                data[indextr].creationDate
              }}</vs-td>
              <vs-td :data="data[indextr].createdBy">{{
                data[indextr].createdBy
              }}</vs-td>
              <vs-td>
                <vx-tooltip
                  :text="$t(resources.Edit.i18n) || resources.Edit.name"
                >
                  <a @click="getRecord(data[indextr].id)">
                    <feather-icon
                      icon="EditIcon"
                      class="w-5 h-5 mr-1"
                    ></feather-icon>
                  </a>
                </vx-tooltip>
                <vx-tooltip
                  text="Devolución cobro"
                  v-if="data[indextr].value > 0"
                >
                  <vs-button
                    color="danger"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-x-circle"
                    @click="
                      openPopupReturn(
                        data[indextr].id,
                        data[indextr].transactionNumber,
                        data[indextr].value
                      )
                    "
                    >Devolución</vs-button
                  >
                </vx-tooltip>
                <!-- <vx-tooltip
                  text="VOID"
                  v-if="
                    data[indextr].paymentTypeId == 3 &&
                      currentDate == data[indextr].paymentDate
                  "
                >
                  <a @click="generateVoid(data[indextr].id)">
                    <feather-icon
                      icon="RepeatIcon"
                      class="w-8 h-8 mr-1"
                      svgClasses="text-warning"
                    ></feather-icon>
                  </a>
                </vx-tooltip> -->
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </vx-card>

    <vs-tabs>
      <vs-tab label="Registro de tarjetas">
        <vx-card class="mb-4 mt-2">
          <vs-table :data="cardsRegistry" class="mt-base">
            <template slot="thead">
              <vs-th>#</vs-th>
              <vs-th>{{
                $t(resources.Merchant.i18n) || resources.Merchant.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.Currency.i18n) || resources.Currency.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.TransactionNumber.i18n) ||
                  resources.TransactionNumber.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.CreditCardType.i18n) ||
                  resources.CreditCardType.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.CreditCardNumber.i18n) ||
                  resources.CreditCardNumber.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.CreationDate.i18n) || resources.CreationDate.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.CreatedBy.i18n) || resources.CreatedBy.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.Options.i18n) || resources.Options.name
              }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].id">{{ data[indextr].id }}</vs-td>
                <vs-td :data="data[indextr].merchantName">{{
                  data[indextr].merchantName
                }}</vs-td>
                <vs-td :data="data[indextr].currencyCode">{{
                  data[indextr].currencyCode
                }}</vs-td>
                <vs-td :data="data[indextr].transactionNumber">{{
                  data[indextr].transactionNumber
                }}</vs-td>
                <vs-td :data="data[indextr].cardType">{{
                  data[indextr].cardType
                }}</vs-td>
                <vs-td
                  :data="data[indextr].cardNumber"
                  v-if="
                    data[indextr].cardNumber != '' &&
                      data[indextr].cardNumber != null
                  "
                  >****{{ data[indextr].cardNumber }}</vs-td
                >
                <vs-td :data="data[indextr].cardNumber" v-else></vs-td>
                <vs-td :data="data[indextr].createdDate">{{
                  data[indextr].creationDate
                }}</vs-td>
                <vs-td :data="data[indextr].createdBy">{{
                  data[indextr].createdBy
                }}</vs-td>
                <vs-td>
                  <vx-tooltip
                    :text="$t(resources.Edit.i18n) || resources.Edit.name"
                  >
                    <a @click="getRecord(data[indextr].id)">
                      <feather-icon
                        icon="EditIcon"
                        class="w-5 h-5 mr-1"
                      ></feather-icon>
                    </a>
                  </vx-tooltip>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </vs-tab>
      <vs-tab label="Cobros a crédito">
        <h3 class="text-center">
          <strong>
            {{ $t(resources.Receipts.i18n) || resources.Receipts.name }}:</strong
          >
          {{ currencyCode }} {{ totalCredit.toLocaleString("es-MX") }}
        </h3>
        <h5
          class="text-center mt-2 font-semibold text-danger"
          v-if="totalBooking > totalCredit"
        >
          El pago debe ser completado para emitir los servicios. Diferencia por:
          ${{
            parseFloat(totalBooking - totalCredit)
              .toFixed(2)
              .toLocaleString("es-MX")
          }}
        </h5>

        <vs-table :data="chargeOnCreditList" class="mt-base">
          <template slot="thead">
            <vs-th>#</vs-th>
            <vs-th>{{
              $t(resources.Merchant.i18n) || resources.Merchant.name
            }}</vs-th>
            <vs-th>Id Merchant</vs-th>
            <vs-th>{{
              $t(resources.Currency.i18n) || resources.Currency.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.Value.i18n) || resources.Value.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.TransactionNumber.i18n) ||
                resources.TransactionNumber.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.CreditCardType.i18n) || resources.CreditCardType.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.CreditCardNumber.i18n) ||
                resources.CreditCardNumber.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.Status.i18n) || resources.Status.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.PaymentDate.i18n) || resources.PaymentDate.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.CreationDate.i18n) || resources.CreationDate.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.CreatedBy.i18n) || resources.CreatedBy.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.Options.i18n) || resources.Options.name
            }}</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].id">{{ data[indextr].id }}</vs-td>
              <vs-td :data="data[indextr].merchantName">{{
                data[indextr].merchantName
              }}</vs-td>
              <vs-td :data="data[indextr].transactionIdMerchant">{{
                data[indextr].transactionIdMerchant
              }}</vs-td>
              <vs-td :data="data[indextr].currencyCode">{{
                data[indextr].currencyCode
              }}</vs-td>
              <vs-td :data="data[indextr].value">{{
                data[indextr].value
              }}</vs-td>
              <vs-td :data="data[indextr].transactionNumber">{{
                data[indextr].transactionNumber
              }}</vs-td>
              <vs-td :data="data[indextr].cardType">{{
                data[indextr].cardType
              }}</vs-td>
              <vs-td
                :data="data[indextr].cardNumber"
                v-if="
                  data[indextr].cardNumber != '' &&
                    data[indextr].cardNumber != null
                "
                >****{{ data[indextr].cardNumber }}</vs-td
              >
              <vs-td :data="data[indextr].cardNumber" v-else></vs-td>
              <vs-td
                v-if="
                  data[indextr].paymentTypeId ==
                    database.merchantType.EWallet &&
                    data[indextr].statusId == status.payment.pending
                "
                :data="data[indextr].merchantType"
              >
                <vs-button
                  color="success"
                  type="line"
                  icon-pack="feather"
                  icon="icon-refresh-ccw"
                  @click="
                    paymentEwallet(
                      `${data[indextr].description}`,
                      `${data[indextr].referenceId}`
                    )
                  "
                  >{{ data[indextr].stageName }}</vs-button
                >
              </vs-td>
              <vs-td
                v-else-if="
                  data[indextr].paymentTypeId ==
                    database.merchantType.transfer &&
                    data[indextr].statusId == status.payment.pending
                "
                :data="data[indextr].paymentTypeId"
              >
                {{ data[indextr].state }}
                <vs-button
                  color="success"
                  icon-pack="feather"
                  icon="icon-check"
                  @click="
                    updateStage(`${status.payment.paid}`, `${data[indextr].id}`)
                  "
                ></vs-button>
                <vs-button
                  color="danger"
                  icon-pack="feather"
                  icon="icon-x"
                  @click="
                    updateStage(
                      `${status.payment.declined}`,
                      `${data[indextr].id}`
                    )
                  "
                ></vs-button>
              </vs-td>
              <vs-td v-else :data="data[indextr].state">{{
                data[indextr].state
              }}</vs-td>
              <vs-td :data="data[indextr].paymentDate">{{
                data[indextr].paymentDatetime
              }}</vs-td>
              <vs-td :data="data[indextr].createdDate">{{
                data[indextr].creationDate
              }}</vs-td>
              <vs-td :data="data[indextr].createdBy">{{
                data[indextr].createdBy
              }}</vs-td>
              <vs-td>
                <vx-tooltip
                  :text="$t(resources.Edit.i18n) || resources.Edit.name"
                >
                  <a @click="getRecord(data[indextr].id)">
                    <feather-icon
                      icon="EditIcon"
                      class="w-5 h-5 mr-1"
                    ></feather-icon>
                  </a>
                </vx-tooltip>
                <vx-tooltip
                  text="Devolución cobro"
                  v-if="data[indextr].value > 0"
                >
                  <vs-button
                    color="danger"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-x-circle"
                    @click="
                      openPopupReturn(
                        data[indextr].id,
                        data[indextr].transactionNumber,
                        data[indextr].value
                      )
                    "
                    >Devolución</vs-button
                  >
                </vx-tooltip>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-tab>
      <vs-tab label="Pagos con tarjetas empresariales">
        <vx-card class="mb-4 mt-2">
          <h4 class="mb-base text-center"></h4>
          <vs-table :data="businessCards" class="mt-base">
            <template slot="thead">
              <vs-th>#</vs-th>
              <vs-th>{{
                $t(resources.Merchant.i18n) || resources.Merchant.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.Currency.i18n) || resources.Currency.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.Value.i18n) || resources.Value.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.TransactionNumber.i18n) ||
                  resources.TransactionNumber.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.CreditCardType.i18n) ||
                  resources.CreditCardType.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.CreditCardNumber.i18n) ||
                  resources.CreditCardNumber.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.CreationDate.i18n) || resources.CreationDate.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.CreatedBy.i18n) || resources.CreatedBy.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.Options.i18n) || resources.Options.name
              }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].id">{{ data[indextr].id }}</vs-td>
                <vs-td :data="data[indextr].merchantName">{{
                  data[indextr].merchantName
                }}</vs-td>
                <vs-td :data="data[indextr].currencyCode">{{
                  data[indextr].currencyCode
                }}</vs-td>
                <vs-td :data="data[indextr].value">{{
                  data[indextr].value
                }}</vs-td>
                <vs-td :data="data[indextr].transactionNumber">{{
                  data[indextr].transactionNumber
                }}</vs-td>
                <vs-td :data="data[indextr].cardType">{{
                  data[indextr].cardType
                }}</vs-td>
                <vs-td
                  :data="data[indextr].cardNumber"
                  v-if="
                    data[indextr].cardNumber != '' &&
                      data[indextr].cardNumber != null
                  "
                  >****{{ data[indextr].cardNumber }}</vs-td
                >
                <vs-td :data="data[indextr].cardNumber" v-else></vs-td>
                <vs-td :data="data[indextr].createdDate">{{
                  data[indextr].creationDate
                }}</vs-td>
                <vs-td :data="data[indextr].createdBy">{{
                  data[indextr].createdBy
                }}</vs-td>
                <vs-td>
                  <vx-tooltip
                    :text="$t(resources.Edit.i18n) || resources.Edit.name"
                  >
                    <a @click="getRecord(data[indextr].id)">
                      <feather-icon
                        icon="EditIcon"
                        class="w-5 h-5 mr-1"
                      ></feather-icon>
                    </a>
                  </vx-tooltip>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </vs-tab>
      <vs-tab label="Tarjetas virtuales">
        <vx-card class="mb-4 mt-2">
          <h4 class="mb-base text-center"></h4>
          <vs-table :data="otherList" class="mt-base">
            <template slot="thead">
              <vs-th>#</vs-th>
              <vs-th>{{
                $t(resources.Merchant.i18n) || resources.Merchant.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.Currency.i18n) || resources.Currency.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.Value.i18n) || resources.Value.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.TransactionNumber.i18n) ||
                  resources.TransactionNumber.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.CreditCardType.i18n) ||
                  resources.CreditCardType.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.CreditCardNumber.i18n) ||
                  resources.CreditCardNumber.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.CreationDate.i18n) || resources.CreationDate.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.CreatedBy.i18n) || resources.CreatedBy.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.Options.i18n) || resources.Options.name
              }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].id">{{ data[indextr].id }}</vs-td>
                <vs-td :data="data[indextr].merchantName">{{
                  data[indextr].merchantName
                }}</vs-td>
                <vs-td :data="data[indextr].currencyCode">{{
                  data[indextr].currencyCode
                }}</vs-td>
                <vs-td :data="data[indextr].value">{{
                  data[indextr].value
                }}</vs-td>
                <vs-td :data="data[indextr].transactionNumber">{{
                  data[indextr].transactionNumber
                }}</vs-td>
                <vs-td :data="data[indextr].cardType">{{
                  data[indextr].cardType
                }}</vs-td>
                <vs-td
                  :data="data[indextr].cardNumber"
                  v-if="
                    data[indextr].cardNumber != '' &&
                      data[indextr].cardNumber != null
                  "
                  >****{{ data[indextr].cardNumber }}</vs-td
                >
                <vs-td :data="data[indextr].cardNumber" v-else></vs-td>
                <vs-td :data="data[indextr].createdDate">{{
                  data[indextr].creationDate
                }}</vs-td>
                <vs-td :data="data[indextr].createdBy">{{
                  data[indextr].createdBy
                }}</vs-td>
                <vs-td>
                  <vx-tooltip
                    :text="$t(resources.Edit.i18n) || resources.Edit.name"
                  >
                    <a @click="getRecord(data[indextr].id)">
                      <feather-icon
                        icon="EditIcon"
                        class="w-5 h-5 mr-1"
                      ></feather-icon>
                    </a>
                  </vx-tooltip>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </vs-tab>
    </vs-tabs>

    <vx-card class="mb-4 mt-2">
      <h4 class="mb-base text-center">Asociación de cobros</h4>
      <div class="mt-4" v-for="item in payments" :key="item.idx">
        <vs-table :data="item">
          <template slot="header">
            <h4 class="mb-4 mt-4 text-center font-bold">
              {{ item[0].bookingInfo }}
            </h4>
            <vx-tooltip
              text="Añadir FOP en SABRE"
              v-if="
                (item[0].paymentTypeId == 3 ||
                  item[0].paymentTypeId == 6 ||
                  item[0].paymentTypeId == 7) &&
                  item[0].productId == 2 &&
                  item[0].supplierId == 1
              "
            >
              <vs-button
                color="success"
                type="filled"
                icon-pack="feather"
                icon="icon-credit-card"
                @click="openpopupFOPSabre()"
                >SABRE</vs-button
              >
            </vx-tooltip>
          </template>

          <template slot="thead">
            <vs-th>{{ $t(resources.Main.i18n) || resources.Main.name }}</vs-th>
            <vs-th>{{ $t(resources.Type.i18n) || resources.Type.name }}</vs-th>
            <vs-th>{{
              $t(resources.ReferenceCode.i18n) || resources.ReferenceCode.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.Merchant.i18n) || resources.Merchant.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.Currency.i18n) || resources.Currency.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.Value.i18n) || resources.Value.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.TransactionNumber.i18n) ||
                resources.TransactionNumber.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.CreditCardType.i18n) || resources.CreditCardType.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.CreditCardNumber.i18n) ||
                resources.CreditCardNumber.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.PaymentDate.i18n) || resources.PaymentDate.name
            }}</vs-th>
            <!-- <vs-th>{{
              $t(resources.CreationDate.i18n) || resources.CreationDate.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.CreatedBy.i18n) || resources.CreatedBy.name
            }}</vs-th> -->
          </template>

          <template slot-scope="{ data }">
            <vs-tr
              :key="indextr"
              v-for="(tr, indextr) in data"
              :class="
                data[indextr].typeId == 4 ? 'text-success font-semibold' : ''
              "
            >
              <vs-td :data="data[indextr].fatherId">{{
                data[indextr].fatherId
              }}</vs-td>
              <vs-td :data="data[indextr].type">{{ data[indextr].type }}</vs-td>
              <vs-td :data="data[indextr].referenceId">{{
                data[indextr].referenceId
              }}</vs-td>
              <vs-td :data="data[indextr].merchantName">{{
                data[indextr].merchantName
              }}</vs-td>
              <vs-td :data="data[indextr].currencyCode">{{
                data[indextr].currencyCode
              }}</vs-td>
              <vs-td :data="data[indextr].value">{{
                data[indextr].value
              }}</vs-td>
              <vs-td :data="data[indextr].transactionNumber">{{
                data[indextr].transactionNumber
              }}</vs-td>
              <vs-td :data="data[indextr].cardType">{{
                data[indextr].cardType
              }}</vs-td>
              <vs-td
                :data="data[indextr].cardNumber"
                v-if="
                  data[indextr].cardNumber != '' &&
                    data[indextr].cardNumber != null
                "
                >****{{ data[indextr].cardNumber }}</vs-td
              >
              <vs-td :data="data[indextr].cardNumber" v-else></vs-td>
              <vs-td :data="data[indextr].paymentDatetime">{{
                data[indextr].paymentDatetime
              }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </vx-card>

    <!--Popup Recover-->
    <vs-popup
      :title="$t(resources.Recapture.i18n) || resources.Recapture.name"
      :active.sync="popupRecapture"
    >
      <div class="vx-row">
        <div
          class="vx-col w-full mb-2"
          v-if="iboCode == 11670 || iboCode == 19144"
        >
          <ul class="centerx">
            <li>
              <vs-checkbox v-model="useAngelCard"
                >¿Cobro a crédito de Ángel Duque?</vs-checkbox
              >
            </li>
          </ul>
        </div>
        <div class="vx-col w-full" v-if="useAngelCard">
          <label class="vs-input--label">{{
            $t(resources.CreditCard.i18n) || resources.CreditCard.name
          }}</label>
          <vs-select
            class="w-full"
            :placeholder="$t(resources.Select.i18n) || resources.Select.name"
            v-model="angelCarId"
          >
            <vs-select-item
              :key="index"
              :value="item.id"
              :text="`${item.creditCardTypeName} - **${item.cardNumber}`"
              v-for="(item, index) in angelCardsOption"
            />
          </vs-select>
          <!-- <vs-input class="w-full" icon-pack="feather" icon="icon-credit-card" type="text" value="Visa - **4674" readonly /> -->
        </div>
        <div v-else class="vx-col w-full mb-2">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.CreditCard.i18n) || resources.CreditCard.name
            }}</label>
            <vs-select
              class="w-full"
              :placeholder="$t(resources.Select.i18n) || resources.Select.name"
              v-model="recaptureCardId"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item, index) in cardsOption"
              />
            </vs-select>
          </div>
        </div>
        <!-- <div class="vx-col w-full">
          <label class="vs-input--label">{{
            $t(resources.ReferenceCode.i18n) || resources.ReferenceCode.name
          }}</label>
          <v-select
            v-model="identifier"
            :clearable="false"
            :options="bookingList"
            :reduce="data => data.id"
            label="value"
          >
          </v-select>
        </div> -->
        <div class="vx-col w-full mb-2">
          <vs-input
            class="w-full"
            placeholder="0.00"
            icon-pack="feather"
            icon="icon-dollar-sign"
            :label="$t(resources.Total.i18n) || resources.Total.name"
            :maxlength="10"
            type="number"
            v-model="recaptureValue"
          />
        </div>
      </div>

      <div class="vx-col mb-2 mt-5" style="text-align: right">
        <vs-button color="primary" type="filled" @click="Recapture()">
          {{ $t(resources.Pay.i18n) || resources.Pay.name }}
        </vs-button>
      </div>
    </vs-popup>

    <vs-popup
      :title="
        `${$t(resources.Recapture.i18n) || resources.Recapture.name} Blockpay`
      "
      :active.sync="popupRecaptureBP"
    >
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full mb-2">
          <label class="vs-input--label">{{
            $t(resources.AccountNumber.i18n) || resources.AccountNumber.name
          }}</label>
          <v-select
            v-model="accountWalletId"
            :clearable="false"
            :options="accountWalletOption"
            :reduce="x => x.id"
            label="name"
            :hint="`${accountWalletId}`"
          ></v-select>
        </div>
        <div class="vx-col md:w-1/2 w-full mb-2">
          <vs-input
            class="w-full"
            placeholder="0.00"
            icon-pack="feather"
            icon="icon-dollar-sign"
            :label="$t(resources.Total.i18n) || resources.Total.name"
            :maxlength="10"
            type="number"
            v-model="recaptureValueBlockpay"
          />
        </div>
      </div>

      <div class="vx-col mb-2 mt-5" style="text-align: right">
        <vs-button color="primary" type="filled" @click="RecaptureBlockpay()">
          {{ $t(resources.Pay.i18n) || resources.Pay.name }}
        </vs-button>
      </div>
    </vs-popup>

    <!--Popup CreditCard-->
    <vs-popup
      classContent="popup overFlow"
      :title="$t(resources.Generate.i18n) || resources.Generate.name"
      :active.sync="popupGenerate"
    >
      <vs-input
        type="email"
        class="w-full mb-2"
        icon-pack="feather"
        icon="icon-mail"
        placeholder
        :label="$t(resources.Email.i18n) || resources.Email.name"
        :maxlength="100"
        v-model="creditCardEmail"
      />
      <!-- <div class="vx-col w-full">
        <label class="vs-input--label">{{
          $t(resources.ReferenceCode.i18n) || resources.ReferenceCode.name
        }}</label>
        <v-select
          v-model="identifier"
          :clearable="true"
          :options="bookingList"
          :reduce="data => data.id"
          label="value"
        >
        </v-select>
      </div> -->

      <ul class="centerx" style="margin-top: 20px">
        <li>
          <vs-checkbox v-model="onlyRegister">{{
            $t(alerts.MsgOnlySaveCard.i18n) || alerts.MsgOnlySaveCard.name
          }}</vs-checkbox>
        </li>
      </ul>

      <div class="vx-col mb-2 mt-5" style="text-align: right">
        <vs-button color="primary" type="filled" @click="sendMail()">
          {{ $t(resources.Send.i18n) || resources.Send.name }}
        </vs-button>
      </div>

      <div class="vx-row" v-if="paymentLink !== null && paymentLink !== ''">
        <div class="vx-col w-full md:w-3/4 mb-2 mt-base">
          <vs-input
            v-model="paymentLink"
            class="w-full"
            icon-pack="feather"
            icon="icon-link"
            icon-no-border
            readonly
          />
        </div>
        <div class="vx-col w-full md:w-1/6 mb-4 mt-base">
          <vs-button
            v-clipboard:copy="paymentLink"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            >{{ $t(resources.Copy.i18n) || resources.Copy.name }}</vs-button
          >
        </div>
      </div>
    </vs-popup>

    <!--Popup Virtual card-->
    <vs-popup
      :title="$t(resources.Issue.i18n) || resources.Issue.name"
      :active.sync="popupVirtualCard"
    >
      <h4
        class="mb-4"
        v-if="bookingFlight != undefined && bookingFlight != null"
      >
        <strong
          >{{ $t(resources.BookingCode.i18n) || resources.BookingCode.name }}:
          #{{ bookingFlight.value }}</strong
        >
      </h4>
      <p class="mb-4">
        Una vez aprobada la transferencia, la reserva de vuelo puede ser
        emitida, por favor, selecciona con cuál forma de pago quieres emitir la
        reserva, de lo contrario, hacer clic en el botón "Cancelar".
      </p>
      <div class="vx-row">
        <div class="vx-col w-full">
          <label>{{
            $t(resources.WayToPay.i18n) || resources.WayToPay.name
          }}</label>
          <ul class="leftx">
            <li>
              <vs-radio v-model="wayToPayIssue" vs-value="7"
                >Tarjeta de crédito virtual de Amadeus</vs-radio
              >
            </li>
            <li>
              <vs-radio v-model="wayToPayIssue" vs-value="2">Cash</vs-radio>
            </li>
          </ul>
        </div>
      </div>

      <div class="vx-col mb-2 mt-5" style="text-align: right">
        <vs-button color="success" type="filled" @click="ApproveIssue()">
          {{ $t(resources.Issue.i18n) || resources.Issue.name }} </vs-button
        >&nbsp;|&nbsp;
        <vs-button
          color="danger"
          type="filled"
          @click="popupVirtualCard = false"
        >
          {{ $t(resources.Cancel.i18n) || resources.Cancel.name }}
        </vs-button>
      </div>
    </vs-popup>

    <!--Popup FOP Sabre-->
    <vs-popup title="Setear tarjeta en SABRE" :active.sync="popupFOPSabre">
      <div class="vx-row">
        <div class="vx-col w-full mb-2">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.CreditCard.i18n) || resources.CreditCard.name
            }}</label>
            <vs-select
              class="w-full"
              :placeholder="$t(resources.Select.i18n) || resources.Select.name"
              v-model="cardFopId"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item, index) in cardsOption"
              />
            </vs-select>
          </div>
        </div>
      </div>

      <div class="vx-col mb-2 mt-5" style="text-align: right">
        <vs-button color="primary" type="filled" @click="setFop()">
          {{ $t(resources.Pay.i18n) || resources.Pay.name }}
        </vs-button>
      </div>
    </vs-popup>

    <!--Popup Duplicate-->
    <vs-popup title="Devolución cobro" :active.sync="popupReturn">
      <p>
        Usted está haciendo la devolución del cobro con número transacción:
        <strong>{{ returns.referenceId }}</strong>
      </p>
      <div class="vx-row mt-4">
        <div class="vx-col md:w-1/2 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-dollar-sign"
              type="number"
              :label="$t(resources.Value.i18n) || resources.Value.name"
              v-model="returns.value"
              name="value"
            />
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-hash"
              :label="
                $t(resources.AuthorizationNumber.i18n) ||
                  resources.AuthorizationNumber.name
              "
              v-model="returns.allocationNumber"
              name="referenceCode"
            />
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full mb-2">
          <label class="vs-input--label">{{
            $t(resources.PaymentDate.i18n) || resources.PaymentDate.name
          }}</label>
          <flat-pickr
            class="vx-col w-full"
            :config="configdateTimePicker"
            v-model="returns.paymentDate"
          />
        </div>
      </div>

      <div class="vx-col mb-2 mt-5" style="text-align: right">
        <vs-button color="primary" type="filled" @click="duplicate()">
          {{ $t(resources.Save.i18n) || resources.Save.name }}
        </vs-button>
      </div>
    </vs-popup>

    <!--Popup add payment-->
    <vs-popup fullscreen :title="popUpTitle" :active.sync="popupAddPayment">
      <div class="vx-row">
        <div class="vx-col md:w-1/4 w-full">
          <label class="vs-input--label">{{
            $t(resources.WayToPay.i18n) || resources.WayToPay.name
          }}</label>
          <v-select
            v-model="wayToPayOptionId"
            :clearable="false"
            :options="wayToPayOption"
            :reduce="data => data.wayToPayId"
            label="name"
            v-on:input="setWayToPay(`${wayToPayOptionId}`)"
            :hint="`${wayToPayOptionId}`"
            :disabled="canUpdate"
            v-validate="'required'"
            name="wayToPay"
          />
          <span class="text-danger text-sm" v-show="errors.has('wayToPay')">{{
            errors.first("wayToPay")
          }}</span>
        </div>
        <div class="vx-col md:w-1/4 w-full">
          <label class="vs-input--label">{{
            $t(resources.Merchant.i18n) || resources.Merchant.name
          }}</label>
          <v-select
            v-model="merchantId"
            :clearable="false"
            :options="merchantList"
            :reduce="data => data.merchantId"
            label="merchantName"
            :disabled="canUpdate"
            v-validate="'required'"
            name="merchant"
          />
          <span class="text-danger text-sm" v-show="errors.has('merchant')">{{
            errors.first("merchant")
          }}</span>
        </div>
        <div class="vx-col md:w-1/4 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-dollar-sign"
              type="number"
              :label="$t(resources.Value.i18n) || resources.Value.name"
              v-model="value"
              v-validate="'required|decimal:2'"
              name="value"
            />
          </div>
          <span class="text-danger text-sm" v-show="errors.has('value')">{{
            errors.first("value")
          }}</span>
        </div>
        <div class="vx-col md:w-1/4 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-hash"
              :label="
                $t(resources.AuthorizationNumber.i18n) ||
                  resources.AuthorizationNumber.name
              "
              v-model="referenceCode"
              name="referenceCode"
              v-validate="'max:20|alpha_num'"
            />
          </div>
          <span
            class="text-danger text-sm"
            v-show="errors.has('referenceCode')"
            >{{ errors.first("referenceCode") }}</span
          >
        </div>
        <div class="vx-col md:w-1/4 w-full mb-2">
          <label class="vs-input--label">{{
            $t(resources.PaymentDate.i18n) || resources.PaymentDate.name
          }}</label>
          <flat-pickr
            class="vx-col w-full"
            :config="configdateTimePicker"
            v-model="paymentDate"
          />
        </div>
        <div class="vx-col w-full md:w-1/4 mb-2">
          <label class="vs-input--label">{{
            $t(resources.Stage.i18n) || resources.Stage.name
          }}</label>
          <v-select
            v-model="stageId"
            :clearable="false"
            :options="stageList"
            :reduce="x => x.id"
            label="name"
            scrollable
            v-validate="'required'"
            name="stage"
          />
          <span class="text-danger text-sm" v-show="errors.has('stage')">{{
            errors.first("stage")
          }}</span>
        </div>
        <div class="vx-col md:w-1/4 w-full" v-if="!canUpdate">
          <label>{{
            $t(resources.Remark.i18n) || resources.Remark.name
          }}</label>
          <vs-textarea v-model="merchantResponse" />
        </div>
        <div class="vx-col md:w-1/4 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-hash"
              :label="$t(resources.CreatedBy.i18n) || resources.CreatedBy.name"
              v-model="createdBy"
              name="createdBy"
              disabled
            />
          </div>
          <span class="text-danger text-sm" v-show="errors.has('createdBy')">{{
            errors.first("createdBy")
          }}</span>
        </div>
        <!-- <div
          class="vx-col md:w-1/4 w-full mb-2"
          v-if="paymentId != 0 && paymentId != null"
        >
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-hash"
              type="number"
              :label="$t(resources.Booking.i18n) || resources.Booking.name"
              v-model="identifier"
              v-validate="'required|decimal:0'"
              name="identifier"
            />
          </div>
          <span class="text-danger text-sm" v-show="errors.has('identifier')">{{
            errors.first("identifier")
          }}</span>
        </div> -->
        <div
          class="vx-col md:w-1/4 w-full"
          v-if="
            paymentId != 0 && paymentId != null && oldIdentifier != identifier
          "
        >
          <label>{{
            $t(resources.Comments.i18n) || resources.Comments.name
          }}</label>
          <vs-textarea
            v-model="comments"
            v-validate="'required|min:20'"
            name="comments"
          />
          <span class="text-danger text-sm" v-show="errors.has('comments')">{{
            errors.first("comments")
          }}</span>
        </div>
      </div>

      <h4 class="mb-4 mt-4">
        {{
          $t(resources.AdditionalInformation.i18n) ||
            resources.AdditionalInformation.name
        }}
      </h4>
      <div
        class="mb-2"
        v-if="
          wayToPayOptionId == database.merchantType.virtualCard &&
            merchantId == database.merchants.Tp
        "
      >
        <div class="vx-col md:w-1/6 w-full mb-2">
          <vs-input
            class="w-full"
            icon-pack="feather"
            icon="icon-credit-card"
            :label="$t(resources.CreditCard.i18n) || resources.CreditCard.name"
            value="TP - **4793"
            readonly
          />
        </div>
      </div>
      <div
        class="mb-2"
        v-else-if="
          wayToPayOptionId == database.merchantType.creditCard ||
            wayToPayOptionId == database.merchantType.offline ||
            wayToPayOptionId == database.merchantType.virtualCard
        "
      >
        <div class="vx-row">
          <div class="vx-col md:w-1/4 w-full mb-2" v-if="!isBusinessCard">
            <ul class="centerx mb-4">
              <li>
                <vs-checkbox v-model="haveCardInfo">{{
                  $t(alerts.MsgHaveCardInfo.i18n) || alerts.MsgHaveCardInfo.name
                }}</vs-checkbox>
              </li>
            </ul>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2" v-if="!isBusinessCard">
            <ul class="centerx mb-4">
              <li>
                <vs-checkbox v-model="isDispersion"
                  >¿Es dispersión de fondos?</vs-checkbox
                >
              </li>
            </ul>
          </div>
          <div class="vx-col md:w-1/2 w-full mb-2">
            <ul class="centerx mb-4">
              <li>
                <vs-checkbox v-model="isBusinessCard"
                  >¿Es tarjeta empresarial?</vs-checkbox
                >
              </li>
            </ul>
            <ul class="leftx" v-if="isBusinessCard">
              <li v-for="item in cardsList" :key="item.idx">
                <vs-radio v-model="creditCardId1" :vs-value="item.id"
                  >{{ item.creditCardTypeName }} - ***{{
                    item.cardNumber
                  }}</vs-radio
                >
              </li>
            </ul>
          </div>
        </div>

        <div class="vx-row" v-if="haveCardInfo && !isBusinessCard">
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                $t(resources.Type.i18n) || resources.Type.name
              }}</label>
              <v-select
                v-model="creditCard.creditCardTypeId"
                :clearable="false"
                :options="creditcardOption"
                :reduce="data => data.cardId"
                label="name"
                v-on:input="
                  setInstallmentNumber(`${creditCard.creditCardTypeId}`)
                "
                :hint="`${creditCard.creditCardTypeId}`"
                :disabled="canUpdate"
                v-validate="'required'"
                name="cardType"
              />
            </div>
            <span class="text-danger text-sm" v-show="errors.has('cardType')">{{
              errors.first("cardType")
            }}</span>
          </div>
          <div
            class="vx-col md:w-1/4 w-full mb-2"
            v-if="paymentId != 0 && paymentId != null"
          >
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-credit-card"
                :label="
                  $t(resources.CreditCardNumber.i18n) ||
                    resources.CreditCardNumber.name
                "
                v-model="creditCard.cardNumber"
                v-validate="'required'"
                disabled
              />
            </div>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2" v-else>
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-credit-card"
                type="number"
                :label="
                  $t(resources.CreditCardNumber.i18n) ||
                    resources.CreditCardNumber.name
                "
                v-model="creditCard.cardNumber"
                v-validate="'required|credit_card'"
                name="cardNumber"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('cardNumber')"
                >{{ errors.first("cardNumber") }}</span
              >
            </div>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-lock"
                :maxlength="maxCvv"
                :label="
                  $t(resources.SecurityCode.i18n) || resources.SecurityCode.name
                "
                v-model="creditCard.cardSecurityCode"
                name="SecurityCode"
                :disabled="canUpdate"
              />
            </div>
            <span
              class="text-danger text-sm"
              v-show="errors.has('SecurityCode')"
              >{{ errors.first("SecurityCode") }}</span
            >
          </div>
          <div class="vx-col w-full md:w-1/4 mb-4">
            <label class="vs-input--label">{{
              $t(resources.ExpirationYear.i18n) || resources.ExpirationYear.name
            }}</label>
            <vs-select
              class="vx-col w-full"
              icon-no-border
              v-model="expirationYear"
              v-on:change="expirationYearChange($event)"
              :disabled="canUpdate"
              v-validate="'required'"
              name="expirationYear"
            >
              <vs-select-item
                :key="index"
                :value="item"
                :text="item"
                v-for="(item, index) in expirationYearOption"
              />
            </vs-select>
            <span class="text-danger text-sm" v-show="errors.has('stage')">{{
              errors.first("expirationYear")
            }}</span>
          </div>
          <div class="vx-col w-full md:w-1/4 mb-4">
            <label class="vs-input--label">{{
              $t(resources.ExpirationMonth.i18n) ||
                resources.ExpirationMonth.name
            }}</label>
            <vs-select
              class="vx-col w-full"
              icon-no-border
              v-model="expirationMonth"
              :disabled="canUpdate"
              v-validate="'required'"
              name="expirationMonth"
            >
              <vs-select-item
                :key="index"
                :value="item"
                :text="item"
                v-for="(item, index) in expirationMonthOption"
              />
            </vs-select>
            <span
              class="text-danger text-sm"
              v-show="errors.has('expirationMonth')"
              >{{ errors.first("expirationMonth") }}</span
            >
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-user"
                :label="$t(resources.Name.i18n) || resources.Name.name"
                v-model="creditCard.cardHolderName"
                v-validate="{ required: true, regex: /^([a-zA-Z ]+)$/ }"
                name="cardHolderName"
                :disabled="canUpdate"
              />
            </div>
            <span
              class="text-danger text-sm"
              v-show="errors.has('cardHolderName')"
              >{{ errors.first("cardHolderName") }}</span
            >
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-user"
                :label="$t(resources.LastName.i18n) || resources.LastName.name"
                v-model="creditCard.cardholderLastName"
                v-validate="{ required: true, regex: /^([a-zA-Z ]+)$/ }"
                name="cardholderLastName"
                :disabled="canUpdate"
              />
            </div>
            <span
              class="text-danger text-sm"
              v-show="errors.has('cardholderLastName')"
              >{{ errors.first("cardholderLastName") }}</span
            >
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-hash"
                icon-no-border
                :label="
                  $t(resources.DocumentNumber.i18n) ||
                    resources.DocumentNumber.name
                "
                v-model="creditCard.documentNumber"
                v-validate="'required|max:20|alpha_num'"
                name="documentCard"
                :disabled="canUpdate"
              />
            </div>
            <span
              class="text-danger text-sm"
              v-show="errors.has('documentCard')"
              >{{ errors.first("documentCard") }}</span
            >
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-mail"
                type="email"
                :label="$t(resources.Email.i18n) || resources.Email.name"
                v-model="creditCard.email"
                v-validate="'required|max:100|email'"
                name="emailCard"
                :disabled="canUpdate"
              />
            </div>
            <span
              class="text-danger text-sm"
              v-show="errors.has('emailCard')"
              >{{ errors.first("emailCard") }}</span
            >
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-map-pin"
                icon-no-border
                :label="$t(resources.Address.i18n) || resources.Address.name"
                v-model="creditCard.address"
                v-validate="{ required: true, regex: /^[a-zA-Z0-9 ]{5,50}$/ }"
                name="addressCard"
                :disabled="canUpdate"
              />
            </div>
            <span
              class="text-danger text-sm"
              v-show="errors.has('addressCard')"
              >{{ errors.first("addressCard") }}</span
            >
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-hash"
                type="number"
                :label="$t(resources.ZipCode.i18n) || resources.ZipCode.name"
                v-model="creditCard.zipCode"
                v-validate="{ required: true, regex: /^\d{5}$/ }"
                name="zipCodeCard"
                :disabled="canUpdate"
              />
            </div>
            <span
              class="text-danger text-sm"
              v-show="errors.has('zipCodeCard')"
              >{{ errors.first("zipCodeCard") }}</span
            >
          </div>
          <div
            class="vx-col md:w-1/4 w-full mb-2"
            v-if="paymentId != 0 && paymentId != ''"
          >
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-map"
                icon-no-border
                :label="
                  $t(resources.ResidenceCity.i18n) ||
                    resources.ResidenceCity.name
                "
                v-model="creditCard.cityName"
                disabled
              />
            </div>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                $t(resources.InstallmentNumber.i18n) ||
                  resources.InstallmentNumber.name
              }}</label>
              <v-select
                v-model="creditCard.installmentNumber"
                :clearable="false"
                :options="installmentNumberOption"
                :reduce="data => data.cardId"
                label="name"
                :disabled="canUpdate"
              />
            </div>
          </div>
        </div>
        <div class="vx-row" v-else-if="!isBusinessCard">
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                $t(resources.CreditCard.i18n) || resources.CreditCard.name
              }}</label>
              <vs-select
                class="w-full"
                :placeholder="
                  $t(resources.Select.i18n) || resources.Select.name
                "
                v-model="creditCardId"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in cardsOption"
                />
              </vs-select>
            </div>
          </div>
        </div>
      </div>

      <div
        class="mb-2"
        v-if="wayToPayOptionId == database.merchantType.EWallet"
      >
        <div class="vx-row">
          <div class="vx-col md:w-1/4 w-full mb-2">
            <label class="vs-input--label">{{
              $t(resources.Select.i18n) || resources.Select.name
            }}</label>
            <v-select
              v-model="walletId"
              :clearable="false"
              :options="walletOption"
              :reduce="data => data.walletId"
              label="name"
              v-on:input="setAccountWallet(`${walletId}`)"
              :hint="`${walletId}`"
              :disabled="canUpdate"
            >
            </v-select>
          </div>
          <div
            class="vx-col md:w-1/4 w-full mb-2"
            v-if="
              walletId == database.merchants.BlockPay &&
                (paymentId == 0 || paymentId == null)
            "
          >
            <label class="vs-input--label">{{
              $t(resources.AccountNumber.i18n) || resources.AccountNumber.name
            }}</label>
            <v-select
              v-model="accountWalletId"
              :clearable="false"
              :options="accountWalletOption"
              :reduce="x => x.id"
              label="name"
              :hint="`${accountWalletId}`"
              name="accountWalletId"
              v-validate="'required'"
              :disabled="canUpdate"
            ></v-select>
            <span
              class="text-danger text-sm"
              v-show="errors.has('accountWalletId')"
              >{{ errors.first("accountWalletId") }}</span
            >
          </div>
          <div
            class="vx-col md:w-1/4 w-full mb-2"
            v-if="
              walletId == database.merchants.Payout &&
                (paymentId == 0 || paymentId == null)
            "
          >
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-user"
                type="number"
                :label="
                  $t(resources.DistributorID.i18n) ||
                    resources.DistributorID.name
                "
                v-model="itaCodeWallet"
                v-validate="'required'"
                name="itaCodeWallet"
                :disabled="canUpdate"
              />
            </div>
            <span
              class="text-danger text-sm"
              v-show="errors.has('itaCodeWallet')"
              >{{ errors.first("itaCodeWallet") }}</span
            >
          </div>
        </div>
      </div>

      <div
        class="mb-2"
        v-if="wayToPayOptionId == database.merchantType.transfer"
      >
        <div class="vx-row">
          <div class="vx-col md:w-1/4 w-full mb-2">
            <label class="vs-input--label">{{
              $t(resources.BankName.i18n) || resources.BankName.name
            }}</label>
            <v-select
              v-model="transfer.bankTypeId"
              :clearable="false"
              :options="bankType"
              :reduce="data => data.id"
              label="name"
              v-on:input="setAccountNumber(transfer.bankTypeId)"
              :hint="transfer.bankTypeId"
              :disabled="canUpdate"
            />
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <label class="vs-input--label">{{
              $t(resources.AccountNumber.i18n) || resources.AccountNumber.name
            }}</label>
            <v-select
              v-model="transfer.bankAccountId"
              :clearable="false"
              :options="accountNumberType"
              :reduce="data => data.id"
              label="account"
              :disabled="canUpdate"
            />
          </div>
        </div>
      </div>

      <div class="vx-col mb-2 mt-5" style="text-align: right">
        <vs-button color="primary" type="filled" @click.prevent="valid">
          {{ $t(resources.Save.i18n) || resources.Save.name }}
        </vs-button>
      </div>

      <div class="vx-row" v-if="paymentId != 0 && paymentId != null">
        <div class="vx-col md:w-1/4 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-calendar"
              :label="
                $t(resources.CreationDate.i18n) || resources.CreationDate.name
              "
              v-model="creationDate"
              disabled
            />
          </div>
        </div>
        <div class="vx-col md:w-1/4 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-file-text"
              :label="$t(resources.Invoice.i18n) || resources.Invoice.name"
              v-model="invoiceId"
              readonly
            />
          </div>
        </div>
        <div class="vx-col md:w-1/4 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-alert-triangle"
              :label="$t(resources.Accertify.i18n) || resources.Accertify.name"
              v-model="accertify"
              disabled
            />
          </div>
        </div>
        <div class="vx-col md:w-1/4 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-hash"
              :label="
                $t(resources.AccertifyPoints.i18n) ||
                  resources.AccertifyPoints.name
              "
              v-model="accertifyPoints"
              disabled
            />
          </div>
        </div>
        <div class="vx-col md:w-1/4 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-file-text"
              :label="
                $t(resources.AccertifyId.i18n) || resources.AccertifyId.name
              "
              v-model="accertifyId"
              readonly
            />
          </div>
        </div>
        <div class="vx-col md:w-1/4 w-full">
          <label>{{
            $t(resources.Answer.i18n) || resources.Answer.name
          }}</label>
          <vs-textarea v-model="merchantResponse" disabled />
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import utilities from "@/assets/resources/utilities.json";
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import database from "@/assets/resources/enums/database.json";
import other from "@/assets/resources/enums/other.json";
import status from "@/assets/resources/enums/status.json";
import * as lang from "vuejs-datepicker/src/locale";
import vSelect from "vue-select";
import axios from "axios";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";

export default {
  components: {
    vSelect,
    flatPickr
  },
  name: "payment",
  props: {
    bookingId: String,
    countryOperationCode: String,
    currencyCode: String,
    productId: Number,
    supplierId: Number,
    contactId: Number,
    itaContactId: Number,
    bookingList: Array,
    iboCode: String
  },
  data() {
    return {
      urlVision: process.env.VUE_APP_VISION,
      database: database,
      other: other,
      status: status,
      alerts: alerts,
      resources: resources,
      utilities: utilities,
      language: this.$i18n.locale.toUpperCase(),
      languages: lang,
      popupAddPayment: false,
      popupRecapture: false,
      popupGenerate: false,
      wayToPayOption: [],
      wayToPayOptionId: "",
      onlyRegister: false,
      creditCard: {},
      transfer: {},
      stageList: [],
      creditcardOption: [],
      expirationYearOption: [],
      expirationMonthOption: [],
      installmentNumberOption: [],
      accountNumberType: [],
      editPayment: false,
      paymentId: 0,
      referenceCode: "",
      value: 0,
      stageId: "",
      expirationYear: 0,
      expirationMonth: 0,
      maxCvv: 0,
      emailSession: JSON.parse(localStorage.getItem("userInfo")).email,
      contactIdSession: JSON.parse(localStorage.getItem("userInfo")).id,
      paymentDate: new Date(),
      configdateTimePicker: {
        enableTime: true,
        dateFormat: "Y-m-d H:i"
      },
      merchantList: [],
      merchantId: "",
      walletOption: [],
      accountWalletId: "",
      accountWalletOption: [],
      itaCodeWallet: "",
      createdBy: "",
      creationDate: "",
      merchantResponse: "",
      invoiceId: "",
      accertify: "",
      accertifyId: "",
      accertifyPoints: "",
      popUpTitle: this.$i18n.t("Create"),
      cardsOption: [],
      creditCardId: 0,
      haveCardInfo: false,
      totalReceipts: 0,
      receipts: [],
      payments: [],
      recaptureCardId: 0,
      recaptureValue: "",
      creditCardEmail: "",
      identifier: 0,
      oldIdentifier: 0,
      comments: "",
      popupVirtualCard: false,
      wayToPayIssue: 7,
      bookingFlight: null,
      paymentLink: "",
      isDispersion: false,
      isBusinessCard: false,
      creditCardId1: 0,
      cardsList: [],
      totalBooking: 0,
      popupReturn: false,
      popupFOPSabre: false,
      cardFopId: 0,
      returns: {},
      cardsRegistry: [],
      businessCards: [],
      otherList: [],
      useAngelCard: false,
      chargeOnCreditList: [],
      angelCardsOption: [],
      angelCarId: "",
      popupRecaptureBP: false,
      recaptureValueBlockpay: "",
      totalCredit: 0,
      bookingPrice: 0
    };
  },
  computed: {
    canUpdate() {
      if (this.paymentId != 0 && this.paymentId != null) return true;
      else if (this.isBusinessCard) return true;
      else return false;
    },
    currentDate() {
      var date = moment(new Date()).format("YYYY-MM-DD");
      return date;
    }
  },
  methods: {
    getYearsCrediCard() {
      var currentDate = new Date()
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "/");
      this.currentYear = parseInt(currentDate.split("/")[0]);
      this.currentMonth = parseInt(currentDate.split("/")[1]);

      this.expirationYearOption = [];
      this.expirationMonthOption = [];
      var iYear = 0;
      for (let item = this.currentYear; iYear <= 20; item++) {
        iYear++;
        this.expirationYearOption.push(item);
      }

      for (let item = this.currentMonth; item <= 12; item++) {
        this.expirationMonthOption.push(item);
      }
    },

    expirationYearChange(year) {
      var yearSelect = year;
      this.expirationMonthOption = [];

      if (this.currentYear === yearSelect) {
        for (let item = this.currentMonth; item <= 12; item++) {
          this.expirationMonthOption.push(item);
        }
      } else {
        for (let item = 1; item <= 12; item++) {
          this.expirationMonthOption.push(item);
        }
      }
    },

    setWayToPay(item) {
      let _merchantType = this.wayToPayOption.filter(x => x.wayToPayId == item);
      if (item == 1)
        this.walletOption = _merchantType[0].options[0].wallets.map(x => x);
      if (item == 2)
        this.bankType = _merchantType[0].options[0].banks.map(x => x);
      if (item == 3 || item == 6)
        this.creditcardOption = _merchantType[0].options.map(x => x);
    },

    setInstallmentNumber(item) {
      let _creditcard = this.creditcardOption.filter(x => x.cardId == item);
      this.maxCvv = _creditcard[0].maxCvv;
      this.installmentNumberOption = _creditcard[0].installmentNumber;
    },

    setAccountNumber(item) {
      let _account = this.bankType
        .filter(x => x.id == item)
        .map(x => x.accounts);
      this.accountNumberType = _account[0].map(x => x);
    },

    setAccountWallet(item) {
      let _accounts = this.walletOption.find(x => x.walletId == item).accounts;
      this.accountWalletOption = _accounts;
    },

    setAccountBlockpay() {
      const _ways = this.wayToPayOption.filter(
        x => x.wayToPayId == database.merchantType.EWallet
      );

      const _walletOption = _ways[0].options[0].wallets.map(x => x);
      let _accounts = _walletOption.find(
        x => x.walletId == database.merchants.BlockPay
      ).accounts;
      this.accountWalletOption = _accounts;
      this.popupRecaptureBP = true;
    },

    openAdd(isBusinessCard) {
      if (this.totalBooking == this.totalReceipts && !isBusinessCard) {
        this.$vs.dialog({
          color: "warning",
          title: this.$i18n.t("Alert"),
          text:
            "Los cobros realizados equivalen al valor total de la reserva, por ende, no se permite asociar más. Para añadir un nuevo registro debe ser creado como anticipo."
        });
        return;
      }

      this.popUpTitle = this.$i18n.t("Create");
      if (isBusinessCard) {
        this.wayToPayOptionId = 6;
        this.merchantId = 10;
        this.creditCard = {};
        this.value = 0;
        this.stageId = 13;
        this.isBusinessCard = true;
      } else {
        this.wayToPayOptionId = "";
        this.merchantId = "";
        this.creditCard = {};
        this.transfer = {};
        this.walletId = "";
        this.accountWalletId = "";
        this.value = 0;
        this.stageId = "";
        this.maxCvv = 0;
      }
      this.paymentId = 0;
      this.referenceCode = "";
      this.merchantResponse = "";
      this.expirationYear = this.expirationYearOption[0];
      this.expirationMonth = this.expirationMonthOption[0];
      this.createdBy = JSON.parse(localStorage.getItem("userInfo")).email;
      this.paymentDate = new Date();
      this.popupAddPayment = true;
    },

    valid() {
      this.$validator.validateAll().then(async result => {
        if (result) {
          if (this.paymentId != 0 && this.paymentId != null) {
            await this.updatePayment();
          } else {
            await this.createPayment();
          }
        } else if (
          this.oldIdentifier != this.identifier &&
          this.comments != null &&
          this.comments != ""
        ) {
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: `Usted ha cambiado el localizador asociado al pago, por favor escriba en el campo "Comentarios", el motivo para continuar.`,
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
            time: 8000
          });
        } else {
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: this.$i18n.t("MsgRequiredField"),
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      });
    },

    async getRecord(item) {
      this.$vs.loading();
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Payment/${this.$i18n.locale.toUpperCase()}/${item}/${
          this.contactIdSession
        }`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            var _res = result.data;
            this.haveCardInfo = _res.isBusinessCard ? false : true;
            this.isBusinessCard = _res.isBusinessCard;
            this.paymentId = _res.id;
            this.identifier = _res.identifier;
            this.oldIdentifier = _res.identifier;
            this.popUpTitle = this.$i18n.t("Update");
            this.setWayToPay(_res.merchantTypeId);
            this.wayToPayOptionId = _res.merchantTypeId;
            this.merchantId = _res.merchantId;
            this.value = _res.value;
            this.referenceCode = _res.allocationNumber;
            this.paymentDate = _res.payDay;
            this.stageId = _res.stateId;
            this.createdBy = _res.createdBy;

            this.amount = _res.amount;
            this.expirationYear = _res.expirationYear;
            this.expirationYearChange(_res.expirationYear);
            this.expirationMonth = _res.expirationMonth;
            this.creditCard.cardHolderName = _res.cardHolderName;
            this.creditCard.cardholderLastName = _res.cardholderLastName;
            this.creditCard.cardNumber = _res.cardNumber;
            this.creditCard.cardSecurityCode = _res.cardSecurityCode;
            this.creditCard.documentNumber = _res.cardholderDocument;
            this.creditCard.email = _res.email;
            this.creditCard.address = _res.address;
            this.creditCard.zipCode = _res.zipCode;
            this.creditCard.creditCardTypeId = _res.cardTypeId;
            this.creditCard.cityName = _res.cityName;
            this.creditCard.installmentNumber = _res.installmentNumber;
            this.creationDate = _res.creationDate;
            this.invoiceId = _res.referenceId;
            this.accertify = _res.accertify;
            this.accertifyPoints = _res.accertifyPoints;
            this.accertifyId = _res.accertifyId;
            this.merchantResponse = _res.referenceText;
            this.isDispersion = _res.isDispersion;
            this.popupAddPayment = true;

            if (_res.merchantTypeId == this.database.merchantType.transfer) {
              this.setAccountNumber(_res.bankId);
              this.transfer.bankTypeId = _res.bankId;
              this.transfer.bankAccountId = _res.bankAccountId;
            }

            if (_res.merchantTypeId == this.database.merchantType.EWallet) {
              this.setAccountWallet(_res.merchantId);
              this.walletId = _res.merchantId;
            }
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async generateVoid(item) {
      this.$vs.loading();
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Booking/Void`,
        data: {
          paymentId: item,
          createdBy: this.emailSession,
          language: this.$i18n.locale.toUpperCase()
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (result.status == 204) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: "No fue posible realizar el VOID",
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else if (result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgPurchaseCompleted"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            await this.get();
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async setFop() {
      this.$vs.loading();
      let _payment =
        this.receipts.filter(x => x.cardId == this.cardFopId)[0] ||
        this.cardsRegistry.filter(x => x.cardId == this.cardFopId)[0] ||
        this.chargeOnCreditList.filter(x => x.cardId == this.cardFopId)[0];

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Payment/AddPaymentBookingFlight`,
        data: {
          paymentId: _payment.id,
          createdBy: this.emailSession,
          language: this.$i18n.locale.toUpperCase()
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (result.status == 204) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text:
                "No fue posible realizar el seteo de la forma de pago en Sabre",
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else if (result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async sendMail() {
      let _allowSend = true;

      if (
        this.onlyRegister &&
        this.wayToPayOption.find(x => x.code == "OF") == undefined
      ) {
        _allowSend = false;
        this.$vs.notify({
          color: "warning",
          title: this.$i18n.t("Alert"),
          position: "top-right",
          icon: "icon-x-circle",
          text:
            "Debe configurar las formas de pago para enviar el LINK DE REGISTRO DE TARJETA."
        });
      } else if (
        !this.onlyRegister &&
        this.wayToPayOption.find(x => x.code == "TC") == undefined
      ) {
        _allowSend = false;
        this.$vs.notify({
          color: "warning",
          title: this.$i18n.t("Alert"),
          position: "top-right",
          icon: "icon-x-circle",
          text:
            "Debe configurar las formas de pago para enviar el LINK DE PAGOS."
        });
      }

      if (_allowSend) {
        if (this.identifier === undefined || this.identifier === null)
          this.identifier = 0;

        this.$vs.loading();
        await axios({
          method: "POST",
          url: `${process.env.VUE_APP_APIVT}Emails/PaymentLink`,
          data: {
            bookingId: this.bookingId,
            bookingDetailId: 0,
            language: "ES",
            email: this.creditCardEmail,
            isCreditCard: this.onlyRegister,
            createdBy: this.emailSession
          },
          headers: {
            "content-type": "application/json",
            Authorization: `bearer ${localStorage.getItem("tokenVT")}`
          }
        }).then(
          result => {
            if (!result.status) {
              this.$vs.notify({
                title: this.$i18n.t("Error"),
                text: this.$i18n.t("MsgEmailNotSent"),
                color: "danger",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle"
              });
              this.popupTarjetaCreditoActive = false;
              this.$vs.loading.close();
            } else {
              this.$vs.notify({
                title: this.$i18n.t("Success"),
                text: this.$i18n.t("MsgEmailSent"),
                color: "success",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle"
              });
              this.paymentLink = `https://foca.visiontravel.net/payment/online/ES/${
                this.onlyRegister ? 1 : 0
              }/${this.bookingId}/0`;
              this.$vs.loading.close();
            }
          },
          error => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgEmailNotSent") + "<br />" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.popupTarjetaCreditoActive = false;
            this.$vs.loading.close();
          }
        );
      }
    },
    async get() {
      await this.getByBooking(2); //Receipts
      await this.getByBooking(5); //Registry
      await this.getByBooking(6); //Business card
      await this.getByBooking(7); //Other
      await this.getByBooking(8); //Charge on credit
      await this.getDetailGroup();
      await this.getAngelCards();
    },

    async getByBooking(type) {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Payment/ByBooking/${this.$i18n.locale.toUpperCase()}/${
          this.bookingId
        }/${type}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data.success) {
            if (type == 2) {
              this.receipts = result.data.data;

              this.totalReceipts = this.receipts
                .filter(
                  x =>
                    x.statusId == 13 &&
                    (x.paymentTypeId == this.database.merchantType.EWallet ||
                      x.paymentTypeId == this.database.merchantType.transfer ||
                      x.paymentTypeId ==
                        this.database.merchantType.creditCard ||
                      x.paymentTypeId == this.database.merchantType.cash)
                )
                .reduce((sum, current) => sum + parseFloat(current.value), 0);

              this.totalReceipts = this.totalReceipts.toLocaleString("es-MX");
            } else if (type == 5) {
              this.cardsRegistry = result.data.data;
            } else if (type == 6) {
              this.businessCards = result.data.data;
            } else if (type == 7) {
              this.otherList = result.data.data;
            } else if (type == 8) {
              this.chargeOnCreditList = result.data.data;
              this.totalCredit = result.data.data.filter(
                  x =>x.statusId == 13)
                .reduce((sum, current) => sum + parseFloat(current.value), 0);
            }
            let _cards = result.data.data.filter(x => x.cardId != null);

            _cards.map(x =>
              this.cardsOption.push({
                value: x.cardId,
                text: `${x.cardType} - ***${x.cardNumber}`,
                type: x.cardTypeId
              })
            );
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getDetailGroup() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Payment/GetDetailGroup/${this.$i18n.locale.toUpperCase()}/${
          this.bookingId
        }/3`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data.success) {
            this.payments = result.data.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getWayToPay() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}WayToPay/GetAvailable`,
        data: {
          applicationId: this.database.application.FOC,
          countryCode: this.countryOperationCode,
          productId: this.productId,
          supplierId: this.supplierId,
          language: this.$i18n.locale.toUpperCase(),
          currencyCode: this.currencyCode,
          contactId: this.itaContactId
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.wayToPayOption = [];
          } else {
            this.wayToPayOption = result.data.wayToPay;
            this.setWayToPay(this.wayToPayOptionId);
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
          this.merchantOption = [];
        }
      );
    },

    async getMerchantsApplication() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }ApplicationMerchant/GetByApplicationId/${this.$i18n.locale.toUpperCase()}/${
          this.database.application.FOC
        }`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) this.merchantList = result.data.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getBusinessCard() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}CreditCard/GetBusiness`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) this.cardsList = result.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getAngelCards() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}CreditCard/866`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) this.angelCardsOption = result.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async createPayment() {
      this.$vs.loading();
      let payDate = moment(this.paymentDate).format("YYYY-MM-DD HH:mm");

      let _payment = {
        id: 0,
        contactId: this.contactId,
        paymentTypeId: 2,
        identifier: this.bookingId,
        entityId: this.database.entity.booking,
        currencyCode: this.currencyCode,
        countryCode: this.countryOperationCode,
        value: this.value,
        allocationNumber: this.referenceCode,
        payDay: payDate,
        stateId: this.stageId,
        merchantResponse: this.merchantResponse,
        referenceText: this.merchantResponse,
        merchantId: this.merchantId,
        createdBy: this.emailSession,
        productId: this.productId,
        applicationId: this.database.application.FOC,
        expirationMonth: this.expirationMonth,
        expirationYear: this.expirationYear,
        installmentNumber: 1,
        merchantTypeId: this.wayToPayOptionId,
        accertifyId: "0",
        accertifyPoints: 0,
        accertify: "NOPROCESS",
        isSecured: false,
        cityId: 0,
        language: this.$i18n.locale.toUpperCase(),
        CardTypeId: 0,
        isDispersion: this.isDispersion,
        supplierId: this.supplierId
      };

      if (
        this.wayToPayOptionId == this.database.merchantType.virtualCard &&
        this.merchantId == this.database.merchants.Tp
      ) {
        _payment.creditCardId = "35187";
        _payment.cardTypeId = 7;
      } else if (
        (this.wayToPayOptionId == this.database.merchantType.creditCard ||
          this.wayToPayOptionId == this.database.merchantType.offline) &&
        this.haveCardInfo
      ) {
        _payment.creditCardId = 0;
        _payment.cardTypeId = this.creditCard.creditCardTypeId;
        _payment.contactId = this.contactId;
        _payment.cardNumber = this.creditCard.cardNumber;
        _payment.cardSecurityCode = this.creditCard.cardSecurityCode;
        _payment.expirationMonth = this.expirationMonth;
        _payment.expirationYear = this.expirationYear;
        _payment.cardholderDocument = this.creditCard.documentNumber;
        _payment.cardHolderName = this.creditCard.cardHolderName;
        _payment.cardholderLastName = this.creditCard.cardholderLastName;
        _payment.address = this.creditCard.address;
        _payment.zipCode = this.creditCard.zipCode;
        _payment.email = this.creditCard.email;
        _payment.installmentNumber = this.creditCard.installmentNumber;
      } else if (
        (this.wayToPayOptionId == this.database.merchantType.creditCard ||
          this.wayToPayOptionId == this.database.merchantType.offline) &&
        !this.haveCardInfo
      ) {
        if (this.isBusinessCard) {
          (_payment.paymentTypeId = 6),
            (_payment.creditCardId = this.creditCardId1);
          _payment.cardTypeId = this.cardsList.find(
            x => x.id == this.creditCardId1
          ).creditCardTypeId;
        } else {
          _payment.creditCardId = this.creditCardId;
          _payment.cardTypeId = this.cardsOption.find(
            x => x.value == this.creditCardId
          ).type;
        }
      } else if (this.wayToPayOptionId == this.database.merchantType.transfer) {
        _payment.bankId = this.transfer.bankTypeId;
        _payment.bankAccountId = this.transfer.bankAccountId;
      }

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Payment/Register`,
        data: _payment,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.popupAddPayment = false;
            await this.get();
            if (
              this.wayToPayOptionId == this.database.merchantType.transfer &&
              this.stageId == status.payment.paid
            )
              this.popupVirtualCard = true;
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async updatePayment() {
      this.$vs.loading();
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Payment/Update`,
        data: {
          id: this.paymentId,
          stageId: this.stageId,
          value: this.value,
          transactionNumber: this.referenceCode,
          paymentDate: this.paymentDate,
          identifier:
            this.identifier != "" || this.identifier != 0
              ? this.identifier
              : this.paymentId,
          comments: this.comments,
          updatedBy: this.emailSession,
          createdBy: this.emailSession,
          isDispersion: this.isDispersion,
          supplierId: this.supplierId,
          language: this.$i18n.locale.toUpperCase()
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (result.data.id != undefined && result.data.id != null) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.popupAddPayment = false;
            await this.get();
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async getStagePayment() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_OVAPI}Stage/${this.$i18n.locale}/2`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.stageList = [];
          } else {
            this.stageList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async Recapture() {
      this.$vs.loading();
      if (this.useAngelCard) {
        this.recaptureCardId = this.angelCarId;
      }

      const _data = {
        applicationId: this.database.application.FOC,
        contactId: this.contactId,
        createdBy: this.emailSession,
        currencyCode: this.currencyCode,
        countryCode: this.countryOperationCode,
        identifier: this.bookingId,
        language: this.language.toUpperCase(),
        productId: this.productId,
        supplierId: this.supplierId,
        amount: this.recaptureValue,
        paymentType: this.utilities.entity.booking,
        wayToPayId: this.database.merchantType.creditCard,
        creditCardId: this.recaptureCardId,
        chargeOnCredit: this.useAngelCard
      };

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Payment/Recapture`,
        data: _data,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (!result.data.data.success) {
            this.$vs.dialog({
              color: "warning",
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgPaymentNotMade")
            });
            this.$vs.loading.close();
          } else {
            this.$vs.dialog({
              color: "success",
              title: this.$i18n.t("Success"),
              text: `${this.$i18n.t(
                "MsgPaymentMade"
              )}. Ahora puedes cerrar esta página, nuestros asesores continuarán con el tráamite de tu reserva.`
            });
            this.$vs.loading.close();
          }
          this.popupRecapture = false;
          await this.get();
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
          this.$vs.loading.close();
        }
      );
    },

    async RecaptureBlockpay() {
      this.$vs.loading();

      let _data = {
        applicationId: this.database.application.FOC,
        contactId: this.itaContactId,
        createdBy: this.emailSession,
        currencyCode: this.currencyCode,
        countryCode: this.countryOperationCode,
        identifier: this.bookingId,
        language: this.language.toUpperCase(),
        productId: this.productId,
        supplierId: this.supplierId,
        amount: this.recaptureValueBlockpay,
        paymentType: this.utilities.entity.booking,
        wayToPayId: database.merchantType.EWallet,
        payment: {
          walletId: database.merchants.BlockPay,
          charge: true,
          userName: this.accountWalletId,
          accountTypeId: this.accountWalletOption.find(
            x => x.id == this.accountWalletId
          ).typeAccountId,
          amount: this.recaptureValueBlockpay,
          language: this.$i18n.locale
        }
      };

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Payment/PayCreditCard`,
        data: _data,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (!result.data.data.success) {
            this.$vs.dialog({
              color: "warning",
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgPaymentNotMade")
            });
            this.payed = false;
            this.$vs.loading.close();
          } else {
            this.payed = true;
            this.$vs.dialog({
              color: "success",
              title: this.$i18n.t("Success"),
              text: `${this.$i18n.t("MsgPaymentMade")}`
            });
            this.$vs.loading.close();
          }

          this.recaptureValueBlockpay = "";
          this.popupRecaptureBP = false;
          await this.get();
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
          this.$vs.loading.close();
        }
      );
    },

    async updateStage(stageId, paymentId) {
      this.$vs.loading();
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Payment/UpdateStage`,
        data: {
          id: paymentId,
          stageId: stageId,
          paymentDate: "2022-01-01",
          transactionNumber: "123",
          updatedBy: this.emailSession,
          supplierId: this.supplierId,
          language: this.$i18n.locale.toUpperCase()
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (result.data.id != undefined && result.data.id != null) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            if (
              this.bookingFlight != undefined &&
              this.bookingFlight != null &&
              stageId == status.payment.paid
            )
              this.popupVirtualCard = true;

            await this.get();
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async ApproveIssue() {
      this.$vs.loading();

      if (this.bookingFlight != undefined && this.bookingFlight != null) {
        await axios({
          method: "POST",
          url: `${process.env.VUE_APP_APIVT}Booking/Approve`,
          data: {
            bookingDetailId: this.bookingFlight.id,
            MerchantTypeId: this.wayToPayIssue,
            createdBy: this.emailSession,
            language: this.$i18n.locale.toUpperCase()
          },
          headers: {
            "content-type": "application/json",
            Authorization: `bearer ${localStorage.getItem("tokenVT")}`
          }
        }).then(
          result => {
            if (result.data != undefined && result.data != null) {
              this.popupVirtualCard = false;
              this.$vs.notify({
                title: this.$i18n.t("Success"),
                text: this.$i18n.t("MsgSaved"),
                color: "success",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle"
              });
            } else {
              this.$vs.notify({
                title: this.$i18n.t("Alert"),
                text: result.data.message,
                color: "warning",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle"
              });
            }
          },
          error => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError") + "<br/>" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        );
      }
      this.$vs.loading.close();
    },

    async paymentEwallet(itaCode, referenceId) {
      this.$vs.loading();
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_MEMBERSHIP}payout/validStatus/${itaCode}/${referenceId}`,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale.toUpperCase()
        }
      }).then(
        async result => {
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            await this.get();
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async duplicate() {
      this.$vs.loading();
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Payment/Duplicate`,
        data: this.returns,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (result.data.data != undefined && result.data.data != null) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.popupReturn = false;
            await this.get();
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    openPopupReturn(id, code, value) {
      this.returns = {};
      this.returns = {
        referenceId: code,
        paymentId: id,
        createdBy: this.emailSession,
        value: value * -1,
        paymentDate: new Date(),
        allocationNumber: "",
        language: this.$i18n.locale.toUpperCase()
      };
      this.popupReturn = true;
    },

    openpopupFOPSabre() {
      this.cardFopId = 0;
      this.popupFOPSabre = true;
    },
    onCopy() {
      this.$vs.notify({
        title: this.$i18n.t("Success"),
        text: this.$i18n.t("MsgCopy"),
        color: "success",
        iconPack: "feather",
        position: "top-center",
        icon: "icon-check-circle"
      });
    },

    onError() {
      this.$vs.notify({
        title: this.$i18n.t("Error"),
        text: this.$i18n.t("MsgError"),
        color: "danger",
        iconPack: "feather",
        position: "top-center",
        icon: "icon-alert-circle"
      });
    }
  },

  async created() {
    this.$vs.loading();
    this.getYearsCrediCard();
    await this.getStagePayment();
    await this.getMerchantsApplication();
    await this.getBusinessCard();

    if (this.bookingId != 0) {
      await this.get();
      this.bookingFlight = this.bookingList.find(
        x => x.productId == 2 && x.supplierId == 1
      );

      this.totalBooking = this.bookingList
        .filter(x => x.stageId != 8)
        .reduce((sum, current) => sum + parseFloat(current.totalComplete), 0);

      //this.totalBooking = this.totalBooking.toLocaleString("es-MX");
    }
    await this.getWayToPay();
    this.$vs.loading.close();
  }
};
</script>
