<template>
  <div>
    <vs-row vs-type="flex" vs-justify="center" class="mb-4">
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <vs-avatar class="user-profile-img" :src="imageUrl" size="200px" />
      </vs-col>
    </vs-row>
    <div class="vx-row">
      <div class="vx-col w-full mb-base">
        <vx-card>
          <h3
            class="text-center text-success mb-4"
            v-if="
              contactTypeId == this.database.contactType.Ita ||
                contactTypeId == this.database.contactType.ClientIta
            "
          >
            <strong
              >{{
                $t(resources.DistributorID.i18n) ||
                  resources.DistributorID.name
              }}:</strong
            >
            {{ itaCode }}&nbsp;|&nbsp;
            <strong
              >{{
                $t(resources.ExpirationDate.i18n) ||
                  resources.ExpirationDate.name
              }}:</strong
            >
            {{ renewalDate }}&nbsp;
          </h3>
          <p
            class="font-semibold text-center text-warning mb-base"
            v-if="contactTypeId == this.database.contactType.Ita"
          ></p>

          <div class="vx-row">
            <div class="vx-col md:w-1/4 w-full mb-2">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-hash"
                icon-no-border
                :label="
                  $t(resources.DocumentType.i18n) || resources.DocumentType.name
                "
                :value="documentType"
                disabled
              />
            </div>
            <div class="vx-col md:w-1/4 w-full mb-4">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-hash"
                  icon-no-border
                  :label="
                    $t(resources.DocumentNumber.i18n) ||
                      resources.DocumentNumber.name
                  "
                  :value="documentNumber"
                  disabled
                />
              </div>
            </div>
            <div class="vx-col md:w-1/4 w-full mb-2">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-user"
                  :label="$t(resources.Name.i18n) || resources.Name.name"
                  v-model="name"
                  v-validate="{ required: true, regex: /^([a-zA-Z ]+)$/ }"
                  name="name"
                />
                <span class="text-danger text-sm" v-show="errors.has('name')">{{
                  errors.first("name")
                }}</span>
              </div>
            </div>
            <div class="vx-col md:w-1/4 w-full mb-2">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-user"
                  :label="
                    $t(resources.LastName.i18n) || resources.LastName.name
                  "
                  v-model="lastName"
                  v-validate="{ required: true, regex: /^([a-zA-Z ]+)$/ }"
                  name="lastName"
                />
              </div>
              <span
                class="text-danger text-sm"
                v-show="errors.has('lastName')"
                >{{ errors.first("lastName") }}</span
              >
            </div>

            <div class="vx-col md:w-1/4 w-full mb-4">
              <label class="vs-input--label">{{
                $t(resources.MembershipCountry.i18n) ||
                  resources.MembershipCountry.name
              }}</label>
              <v-select
                v-model="countryOperationEntry"
                :clearable="false"
                :options="countryOperationOption"
                :reduce="data => data.countryCode"
                label="display"
              >
                <template slot="option" slot-scope="option">
                  <img :src="option.iconUrl" class="h-6 w-6" />
                  {{ option.display }}
                </template>
              </v-select>
              <!-- <div class="vx-col w-full">
                <vs-input
                  v-model="countryOperationEntry"
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-map"
                  icon-no-border
                  disabled
                  :label="
                    $t(resources.MembershipCountry.i18n) ||
                    resources.MembershipCountry.name
                  "
                />
              </div> -->
            </div>

            <div class="vx-col md:w-1/4 w-full mb-2">
              <label class="vs-input--label">{{
                $t(resources.BirthDay.i18n) || resources.BirthDay.name
              }}</label>
              <v-select
                v-model="birthDay"
                :clearable="false"
                :options="dayList"
                :reduce="data => data.value"
                label="text"
                v-validate="'required'"
                name="birthDay"
              ></v-select>
              <span
                class="text-danger text-sm"
                v-show="errors.has('birthDay')"
                >{{ errors.first("birthDay") }}</span
              >
            </div>
            <div class="vx-col md:w-1/4 w-full mb-2">
              <label class="vs-input--label">{{
                $t(resources.BirthMonth.i18n) || resources.BirthMonth.name
              }}</label>
              <v-select
                v-model="birthMonth"
                :clearable="false"
                :options="monthList"
                :reduce="data => data.value"
                label="text"
                v-validate="'required'"
                name="birthMonth"
              ></v-select>
              <span
                class="text-danger text-sm"
                v-show="errors.has('birthMonth')"
                >{{ errors.first("birthMonth") }}</span
              >
            </div>
            <div class="vx-col md:w-1/4 w-full mb-2">
              <label class="vs-input--label">{{
                $t(resources.BirthYear.i18n) || resources.BirthYear.name
              }}</label>
              <v-select
                v-model="birthYear"
                :clearable="false"
                :options="yearList"
                :reduce="data => data.value"
                label="text"
                v-validate="'required'"
                name="birthYear"
              ></v-select>
              <span
                class="text-danger text-sm"
                v-show="errors.has('birthYear')"
                >{{ errors.first("birthYear") }}</span
              >
            </div>
            <div class="vx-col md:w-1/4 w-full mb-2">
              <div class="vx-col w-full">
                <vs-input
                  type="email"
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-mail"
                  icon-no-border
                  :label="$t(resources.Email.i18n) || resources.Email.name"
                  v-model="email"
                />
              </div>
            </div>
            <div class="vx-col md:w-1/4 w-full mb-2">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-smartphone"
                  icon-no-border
                  :label="$t(resources.Mobile.i18n) || resources.Mobile.name"
                  v-model="phone"
                />
              </div>
            </div>
            <div class="vx-col md:w-1/4 w-full mb-2">
              <vs-select
                class="w-full"
                icon-no-border
                :label="$t(resources.Gender.i18n) || resources.Gender.name"
                v-model="gender"
                v-validate="'required'"
                name="gender"
              >
                <vs-select-item
                  :key="index"
                  :value="item.value"
                  :text="$t(item.i18n) || item.text"
                  v-for="(item, index) in genderOption"
                />
              </vs-select>
              <span class="text-danger text-sm" v-show="errors.has('gender')">{{
                errors.first("gender")
              }}</span>
            </div>
            <div class="vx-col md:w-1/4 w-full mb-2">
              <label class="vs-input--label">{{
                $t(resources.ResidenceCountry.i18n) ||
                  resources.ResidenceCountry.name
              }}</label>
              <v-select
                v-model="countryId"
                :clearable="false"
                :options="countryList"
                :reduce="country => country.id"
                label="name"
                v-on:input="getStateByCountry(`${countryId}`)"
                :hint="`${countryId}`"
                name="countryId"
                v-validate="'required'"
              ></v-select>
              <span
                class="text-danger text-sm"
                v-show="errors.has('countryId')"
                >{{ errors.first("countryId") }}</span
              >
            </div>
            <div class="vx-col md:w-1/4 w-full mb-2">
              <label class="vs-input--label">{{
                $t(resources.ResidenceState.i18n) ||
                  resources.ResidenceState.name
              }}</label>
              <v-select
                v-model="stateId"
                :clearable="false"
                :options="stateCountry"
                :reduce="state => state.id"
                label="name"
                v-on:input="getCityByState(`${stateId}`)"
                :hint="`${stateId}`"
                v-validate="'required'"
                name="state"
              />
              <span class="text-danger text-sm" v-show="errors.has('state')">{{
                errors.first("state")
              }}</span>
            </div>
            <div class="vx-col md:w-1/4 w-full mb-2">
              <label class="vs-input--label">{{
                $t(resources.ResidenceCity.i18n) || resources.ResidenceCity.name
              }}</label>
              <v-select
                v-model="cityId"
                :clearable="false"
                :options="destinationList"
                :reduce="city => city.id"
                label="name"
                v-validate="'required'"
                name="city"
              />
              <span class="text-danger text-sm" v-show="errors.has('city')">{{
                errors.first("city")
              }}</span>
            </div>
            <div class="vx-col md:w-1/4 w-full mb-2">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-map-pin"
                  :label="$t(resources.Address.i18n) || resources.Address.name"
                  v-model="address"
                  maxlength="99"
                  v-validate="{ required: true, regex: /^([a-zA-Z0-9- ]+)$/ }"
                  name="address"
                />
              </div>
              <span
                class="text-danger text-sm"
                v-show="errors.has('address')"
                >{{ errors.first("address") }}</span
              >
            </div>
            <div class="vx-col md:w-1/4 w-full mb-2">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-hash"
                  type="number"
                  :label="$t(resources.ZipCode.i18n) || resources.ZipCode.name"
                  v-model="zipCode"
                  v-validate="'required|min:5|numeric'"
                  name="zipCode"
                />
              </div>
              <span
                class="text-danger text-sm"
                v-show="errors.has('zipCode')"
                >{{ errors.first("zipCode") }}</span
              >
            </div>
            <div class="vx-col md:w-1/4 w-full mb-2">
              <label class="vs-input--label">{{
                $t(resources.Nationality.i18n) || resources.Nationality.name
              }}</label>
              <v-select
                v-model="nationalityId"
                :clearable="false"
                :options="countryList"
                :reduce="country => country.id"
                label="name"
                name="nationalityId"
                v-validate="'required'"
              ></v-select>
              <span
                class="text-danger text-sm"
                v-show="errors.has('nationalityId')"
                >{{ errors.first("nationalityId") }}</span
              >
            </div>
            <div
              class="vx-col md:w-1/4 w-full mb-2"
              v-if="contactTypeId == this.database.contactType.Ita"
            >
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-user"
                  :label="
                    `${$t(resources.Nickname.i18n) ||
                      resources.Nickname.name} - One2trip`
                  "
                  v-model="nickname"
                  maxlength="50"
                  v-validate="{ required: true, regex: /^([a-zA-Z0-9- ]+)$/ }"
                  name="nickname"
                />
              </div>
              <span
                class="text-danger text-sm"
                v-show="errors.has('nickname')"
                >{{ errors.first("nickname") }}</span
              >
            </div>

            <div class="vx-col md:w-1/4 w-full">
              <label class="vs-input--label"
                >{{
                  $t(resources.CountryOperation.i18n) ||
                    resources.CountryOperation.name
                }}
                - One2trip</label
              >
              <v-select
                v-model="countryOperationId"
                :clearable="false"
                :options="countryOperationOption"
                :reduce="data => data.countryId"
                label="display"
              >
                <template slot="option" slot-scope="option">
                  <img :src="option.iconUrl" class="h-6 w-6" />
                  {{ option.display }}
                </template>
              </v-select>
            </div>

            <div class="vx-col md:w-1/4 w-full mb-2">
              <div class="vx-col w-full">
                <vs-input
                  class="w-full"
                  icon-pack="feather"
                  icon="icon-hash"
                  type="number"
                  :label="
                    $t(resources.TinaCode.i18n) || resources.TinaCode.name
                  "
                  v-model="tinaCode"
                  v-validate="'required|numeric'"
                  name="tinaCode"
                />
              </div>
              <span
                class="text-danger text-sm"
                v-show="errors.has('tinaCode')"
                >{{ errors.first("tinaCode") }}</span
              >
            </div>
            <div class="vx-col md:w-1/4 w-full mt-4 mb-2">
              <ul class="centerx">
                <li>
                  <vs-checkbox v-model="isRealtor">{{
                    $t(resources.IsRealtor.i18n) || resources.IsRealtor.name
                  }}</vs-checkbox>
                </li>
              </ul>
            </div>
          </div>

          <div
            class="vx-col mb-2 mt-2"
            style="text-align: right"
            v-if="canUpdate"
          >
            <vs-button color="primary" type="filled" @click="update()">
              {{ $t(resources.Save.i18n) || resources.Save.name }}
            </vs-button>
          </div>
        </vx-card>
      </div>
    </div>
    <vs-tabs>
      <vs-tab
        :label="$t(resources.Benefit.i18n) || resources.Benefit.name"
        icon-pack="feather"
        icon="icon-award"
      >
        <div class="vx-row">
          <div
            class="vx-col w-full lg:w-1/4 mb-base mt-6"
            v-for="item in benefitsList"
            :key="item.id"
          >
            <vx-card :card-background="item.color" content-color="#fff">
              <h4 class="text-center text-white">{{ item.name }}</h4>
              <h1 class="text-center text-white"><strong>{{ item.remaining }}</strong></h1>
            </vx-card>
          </div>
        </div>
      </vs-tab>
      <vs-tab
        v-if="contactTypeId == this.database.contactType.Ita"
        :label="
          $t(resources.CustomizedFee.i18n) || resources.CustomizedFee.name
        "
        icon-pack="feather"
        icon="icon-settings"
      >
        <vs-table
          @search="chargesSearch"
          search
          pagination
          max-items="10"
          :data="chargesList"
        >
          <template slot="thead">
            <vs-th>{{
              $t(resources.Status.i18n) || resources.Status.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.Product.i18n) || resources.Product.name
            }}</vs-th>
            <vs-th>{{ $t(resources.Type.i18n) || resources.Type.name }}</vs-th>
            <vs-th>{{
              $t(resources.Value.i18n) || resources.Value.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.CreationDate.i18n) || resources.CreationDate.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.ChangeDate.i18n) || resources.ChangeDate.name
            }}</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].active" v-if="data[indextr].active">
                <vs-icon
                  icon-pack="material-icons"
                  icon="check_circle"
                  size="small"
                  color="rgba(var(--vs-success), 1)"
                ></vs-icon>
              </vs-td>
              <vs-td :data="data[indextr].active" v-else>
                <vs-icon
                  icon-pack="material-icons"
                  icon="highlight_off"
                  size="small"
                  color="rgba(var(--vs-danger), 1)"
                ></vs-icon>
              </vs-td>
              <vs-td :data="data[indextr].productName">{{
                data[indextr].productName
              }}</vs-td>
              <vs-td :data="data[indextr].chargeTypeName">{{
                data[indextr].chargeTypeName
              }}</vs-td>
              <vs-td :data="data[indextr].value"
                >% {{ data[indextr].value }}</vs-td
              >
              <vs-td :data="data[indextr].createdDate">{{
                data[indextr].createdDate
              }}</vs-td>
              <vs-td :data="data[indextr].modifiedDate">{{
                data[indextr].modifiedDate
              }}</vs-td>
              <vs-td :data="data[indextr].creationDate">{{
                data[indextr].creationDate
              }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-tab>
      <vs-tab
        v-if="contactTypeId != this.database.contactType.Employed"
        :label="$t(resources.Access.i18n) || resources.Access.name"
        icon-pack="feather"
        icon="icon-users"
      >
        <vs-table
          @search="accessSearch"
          search
          pagination
          max-items="10"
          :data="accessList"
        >
          <template slot="thead">
            <vs-th>{{
              $t(resources.Status.i18n) || resources.Status.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.ExpirationDate.i18n) || resources.ExpirationDate.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.Product.i18n) || resources.Product.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.IsFinanced.i18n) || resources.IsFinanced.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.CreationDate.i18n) || resources.CreationDate.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.CreatedBy.i18n) || resources.CreatedBy.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.ChangeDate.i18n) || resources.CreationDate.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.UpdatedBy.i18n) || resources.CreatedBy.name
            }}</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].Activo" v-if="data[indextr].Activo">
                <vs-icon
                  icon-pack="material-icons"
                  icon="check_circle"
                  size="small"
                  color="rgba(var(--vs-success), 1)"
                ></vs-icon>
              </vs-td>
              <vs-td :data="data[indextr].Activo" v-else>
                <vs-icon
                  icon-pack="material-icons"
                  icon="highlight_off"
                  size="small"
                  color="rgba(var(--vs-danger), 1)"
                ></vs-icon>
              </vs-td>
              <vs-td :data="data[indextr].FechaRenovacion">{{
                data[indextr].FechaRenovacion
              }}</vs-td>
              <vs-td :data="data[indextr].Producto">{{
                data[indextr].Producto
              }}</vs-td>
              <vs-td
                :data="data[indextr].EsFinanciado"
                v-if="data[indextr].isFavorite"
              >
                <vs-icon
                  icon-pack="material-icons"
                  icon="check_circle"
                  size="small"
                  color="rgba(var(--vs-success), 1)"
                ></vs-icon>
              </vs-td>
              <vs-td :data="data[indextr].EsFinanciado" v-else>
                <vs-icon
                  icon-pack="material-icons"
                  icon="highlight_off"
                  size="small"
                  color="rgba(var(--vs-danger), 1)"
                ></vs-icon>
              </vs-td>
              <vs-td :data="data[indextr].FechaCreacion">{{
                data[indextr].FechaCreacion
              }}</vs-td>
              <vs-td :data="data[indextr].CreadoPor">{{
                data[indextr].CreadoPor
              }}</vs-td>
              <vs-td :data="data[indextr].FechaModificacion">{{
                data[indextr].FechaModificacion
              }}</vs-td>
              <vs-td :data="data[indextr].ModificadoPor">{{
                data[indextr].ModificadoPor
              }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-tab>
      <vs-tab
        :label="$t(resources.CreditCard.i18n) || resources.CreditCard.name"
        icon-pack="feather"
        icon="icon-credit-card"
      >
        <vs-table
          @search="cardsSearch"
          search
          pagination
          max-items="10"
          :data="cardsList"
        >
          <template slot="thead">
            <vs-th>{{
              $t(resources.ItsRegistred.i18n) || resources.ItsRegistred.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.IsFavorite.i18n) || resources.IsFavorite.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.CardNumber.i18n) || resources.CardNumber.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.ExpirationDate.i18n) || resources.ExpirationDate.name
            }}</vs-th>
            <!-- <vs-th>{{$t(resources.SecurityCode.i18n) || resources.SecurityCode.name}}</vs-th> -->
            <vs-th>{{ $t(resources.Name.i18n) || resources.Name.name }}</vs-th>
            <vs-th>{{
              $t(resources.LastName.i18n) || resources.LastName.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.Email.i18n) || resources.Email.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.Address.i18n) || resources.Address.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.ZipCode.i18n) || resources.ZipCode.name
            }}</vs-th>
            <vs-th>{{
              $t(resources.CreationDate.i18n) || resources.CreationDate.name
            }}</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td
                :data="data[indextr].isRegistered"
                v-if="data[indextr].isRegistered"
              >
                <vs-icon
                  icon-pack="material-icons"
                  icon="check_circle"
                  size="small"
                  color="rgba(var(--vs-success), 1)"
                ></vs-icon>
              </vs-td>
              <vs-td :data="data[indextr].isRegistered" v-else>
                <vs-icon
                  icon-pack="material-icons"
                  icon="highlight_off"
                  size="small"
                  color="rgba(var(--vs-danger), 1)"
                ></vs-icon>
              </vs-td>
              <vs-td
                :data="data[indextr].isFavorite"
                v-if="data[indextr].isFavorite"
              >
                <vs-icon
                  icon-pack="material-icons"
                  icon="check_circle"
                  size="small"
                  color="rgba(var(--vs-success), 1)"
                ></vs-icon>
              </vs-td>
              <vs-td :data="data[indextr].isFavorite" v-else>
                <vs-icon
                  icon-pack="material-icons"
                  icon="highlight_off"
                  size="small"
                  color="rgba(var(--vs-danger), 1)"
                ></vs-icon>
              </vs-td>
              <vs-td :data="data[indextr].cardNumber">{{
                data[indextr].cardNumber
              }}</vs-td>
              <vs-td :data="data[indextr].expirationMonth"
                >{{ data[indextr].expirationYear }} -
                {{ data[indextr].expirationMonth }}</vs-td
              >
              <!-- <vs-td :data="data[indextr].securityCode">{{ data[indextr].securityCode }}</vs-td> -->
              <vs-td :data="data[indextr].name">{{ data[indextr].name }}</vs-td>
              <vs-td :data="data[indextr].lastName">{{
                data[indextr].lastName
              }}</vs-td>
              <vs-td :data="data[indextr].email">{{
                data[indextr].email
              }}</vs-td>
              <vs-td :data="data[indextr].address">{{
                data[indextr].address
              }}</vs-td>
              <vs-td :data="data[indextr].zipCode">{{
                data[indextr].zipCode
              }}</vs-td>
              <vs-td :data="data[indextr].creationDate">{{
                data[indextr].creationDate
              }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-tab>

      <!-- <vs-tab label="Mail" icon-pack="feather" icon="icon-mail"></vs-tab>
      <vs-tab label="Heart" icon-pack="feather" icon="icon-heart"></vs-tab>-->
    </vs-tabs>
    <div class="vx-row mt-base">
      <div class="vx-col w-full text-right">
        <vs-button
          color="rgb(174,174,174)"
          type="filled"
          @click="$router.push('/users/index')"
          >{{ $t(resources.Return.i18n) || resources.Return.name }}</vs-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import resources from "@/i18n/resources.js";
import database from "@/assets/resources/enums/database.json";
import * as lang from "vuejs-datepicker/src/locale";
import vSelect from "vue-select";

export default {
  components: {
    vSelect
  },
  data() {
    return {
      resources: resources,
      database: database,
      lang: lang,
      isNavOpen: false,
      userSession: JSON.parse(localStorage.getItem("userInfo")).email,
      itaStatus: localStorage.getItem("status"),
      renewalDate: localStorage.getItem("renewalDate"),
      contactId: 0,
      contactTypeId: 0,
      genderOption: [
        { value: 1, text: this.$i18n.t("Female") },
        { value: 2, text: this.$i18n.t("Male") },
        { value: 3, text: this.$i18n.t("Other") }
      ],
      dayList: [],
      monthList: [],
      yearList: [],
      stateCountry: [],
      countryList: [],
      destinationList: [],
      name: "",
      lastName: "",
      documentType: 0,
      documentNumber: "",
      gender: 3,
      birthDay: "",
      birthMonth: "",
      birthYear: "",
      email: "",
      lastEmail: "",
      phone: "",
      countryOperationEntry: "",
      countryOperationEntryName: "",
      nationalityId: "",
      countryId: "",
      stateId: "",
      cityId: "",
      zipCode: "",
      address: "",
      imageUrl: "",
      itaCode: "",
      nickname: "",
      tinaCode: "",
      countryOperationId: 0,
      countryOperationOption: [],
      countryOperation: "",
      cardsList: [],
      chargesList: [],
      accessList: [],
      isRealtor: false,
      benefitsList: []
    };
  },
  async created() {
    this.$vs.loading();

    this.contactId = this.$route.params.contactId;
    this.contactTypeId = this.$route.params.contactTypeId;
    this.getBirtDate();
    await this.getCountryOperation();
    await this.getCountry();
    await this.setInformation();
    if (this.contactTypeId != this.database.contactType.Employed) {
      await this.getCreditCard();
      await this.getListAccess();
      await this.getBenefits();
    }

    if (this.contactTypeId == this.database.contactType.Ita)
      await this.getCharges();

    this.$vs.loading.close();
  },
  computed: {
    canUpdate() {
      let listEmails = [
        "a.gomez@visiontravel.net",
        "odalis@visiontravel.net",
        "j.castrillon@visiontravelmail.net",
        "a.holguin@visiontravelmail.net",
        "j.suarez@visiontravelmail.net",
        "j.villegas@visiontravel.net",
        "v.hernandez@visiontravelmail.net",
        "d.lopez@visiontravelmail.net"
      ];
      let isAllow = listEmails.filter(x => x == this.userSession.toLowerCase());
      if (isAllow.length > 0) return true;

      return false;
    }
  },
  methods: {
    getBirtDate() {
      let dates = lang[this.$i18n.locale];
      let year = new Date().getFullYear() - 18;

      for (let index = 1; index < 32; index++) {
        this.dayList.push({
          value: index < 10 ? `0${index}` : index,
          text: index < 10 ? `0${index}` : index
        });
      }

      for (let index = 0; index < dates._months.length; index++) {
        let value = index + 1 < 10 ? `0${index + 1}` : index + 1;
        this.monthList.push({ value: value, text: dates._months[index] });
      }

      for (let index = 0; index < 81; index++) {
        this.yearList.push({ value: year - index, text: year - index });
      }
    },

    async update() {
      this.$vs.loading();
      let cityName = this.destinationList.find(x => x.id == this.cityId).name;
      let stateName = this.stateCountry.find(x => x.id == this.stateId).name;
      let countryName = this.countryList.find(x => x.id == this.countryId).name;
      let couOpe = this.countryOperationOption.find(
        x => x.countryId == this.countryOperationId
      );

      let countryEntry = this.countryOperationOption.find(
        x => x.countryCode == this.countryOperationEntry
      );
      let _data = {};

      _data.id = this.contactId;
      _data.itaCode = this.itaCode;
      _data.name = this.name;
      _data.lastName = this.lastName;
      _data.email = this.email;
      _data.lastEmail = this.lastEmail;
      _data.phone = this.phone;
      _data.gender = this.gender;
      _data.nationalityId = this.nationalityId;
      _data.cityId = this.cityId;
      _data.address = this.address;
      _data.stateName = stateName;
      _data.countryName = countryName;
      _data.cityName = cityName;
      _data.zipCode = this.zipCode;
      _data.countryOperationId = this.countryOperationId;
      _data.countryIso2 = countryEntry.countryCodeIso2;
      _data.countryOperation = couOpe.countryCode;
      _data.countryOperationEntry = this.countryOperationEntry;
      _data.birthDay = this.birthDay;
      _data.birthMonth = this.birthMonth;
      _data.birthYear = this.birthYear;
      _data.nickname = this.nickname;
      _data.tinaCode = this.tinaCode;
      _data.isRealtor = this.isRealtor;

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_MEMBERSHIP}contact/update`,
        data: _data,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      }).then(
        result => {
          if (result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
          this.$vs.loading.close();
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
          this.$vs.loading.close();
        }
      );
    },

    async setInformation() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Contact/${this.$i18n.locale.toUpperCase()}/${this.contactId}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (result.data) {
            let userInfo = result.data;
            let birthDate = userInfo.birthDate.split("-");

            this.countryId = userInfo.country;
            await this.getStateByCountry(this.countryId);

            this.stateId = userInfo.state;
            await this.getCityByState(this.stateId);

            this.cityId = userInfo.city;

            let document = userInfo.documentNumber;
            if (
              userInfo.documentType == 11 &&
              userInfo.contactType != this.database.contactType.Employed
            )
              document = this.$i18n.t("Private");

            this.itaCode = userInfo.itaCode;
            this.name = userInfo.name;
            this.lastName = userInfo.lastName;
            this.renewalDate = userInfo.renewalDate;
            this.documentType = userInfo.documentTypeName;
            this.documentNumber = document;
            this.countryOperationEntryName = `${userInfo.countryOperationEntry} - ${userInfo.countryOperationEntryName}`;
            this.countryOperationEntry = userInfo.countryOperationEntry;
            this.birthDay = birthDate[2];
            this.birthMonth = birthDate[1];
            this.birthYear = birthDate[0];
            this.email = userInfo.email;
            this.lastEmail = userInfo.email;
            this.phone = userInfo.phone;
            this.gender = userInfo.gender;
            this.imageUrl = userInfo.imageUrl;
            this.address = userInfo.address;
            this.zipCode = userInfo.zipCode;
            this.nationalityId = userInfo.nationalityId;
            this.nickname = userInfo.nickname;
            this.tinaCode = userInfo.tinaCode;
            this.countryOperationId = userInfo.countryOperationId;
            this.countryOperation = userInfo.countryOperation;
            this.isRealtor =
              userInfo.isRealtor == undefined ? false : userInfo.isRealtor;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCountryOperation() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }CountryOperation/GetPccCountry/${this.$i18n.locale.toUpperCase()}/${
          this.database.application.OV
        }`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.countryOperationOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCountry() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Country/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.countryList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getStateByCountry(item) {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }State/GetByCountry/${this.$i18n.locale.toUpperCase()}/${item}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) this.stateCountry = result.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCityByState(item) {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }City/GetByState/${this.$i18n.locale.toUpperCase()}/${item}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) this.destinationList = result.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCreditCard() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}CreditCard/${this.contactId}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data != undefined && result.data != "") {
            this.cardsList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: "Error",
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    cardsSearch(searching) {
      if (searching.length >= 3) {
        this.cardsList = this.cardsList.filter(x =>
          x.cardNumber.toLowerCase().includes(searching.toLowerCase())
        );
      }
    },

    async getBenefits() {
      await axios({
        method: "POST",
        url: `${
          process.env.VUE_APP_APIVT
        }Benefit/Available`,
        data: {
          contactId: this.contactId,
          itaCode: this.itaCode,
          language: this.$i18n.locale.toUpperCase(),
          products: [1,2,4,5,0]
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data.length > 0) {
            this.benefitsList = result.data;
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.benefitsList = [];
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCharges() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }ChargeSetting/ByIta/${this.$i18n.locale.toUpperCase()}/${
          this.itaCode
        }`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data.length > 0) {
            this.chargesList = result.data.filter(x => x.active);
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.chargesList = [];
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    chargesSearch(searching) {
      if (searching.length >= 3) {
        this.chargesList = this.chargesList.filter(
          x =>
            x.productName.toLowerCase().includes(searching.toLowerCase()) ||
            x.createdDate.toLowerCase().includes(searching.toLowerCase())
        );
      }
    },

    async getListAccess() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_OVAPI}getListAccess`,
        data: {
          distid: this.itaCode,
          state: "0,1",
          start_date: "",
          end_date: ""
        },
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("loggedIn")
        }
      }).then(
        result => {
          if (result.data.rowsAffected[0] == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.accessList = [];
          } else {
            this.accessList = result.data.recordsets[0];
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    accessSearch(searching) {
      if (searching.length >= 3) {
        this.accessList = this.accessList.filter(
          x =>
            x.Producto.toLowerCase().includes(searching.toLowerCase()) ||
            x.FechaRenovacion.toLowerCase().includes(searching.toLowerCase())
        );
      }
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/pages/profile.scss";
</style>
