var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.stage !== _vm.database.stage.Billing || _vm.allowChange
        ? _c("div", { staticClass: "vx-row" }, [
            _vm.tracking.length == 0
              ? _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full" },
                  [
                    _c(
                      "vs-button",
                      {
                        attrs: { color: "primary", type: "filled" },
                        on: {
                          click: function($event) {
                            return _vm.StartManagement()
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(_vm.resources.StartManagement.i18n) ||
                                _vm.resources.StartManagement.name
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm.tracking.length !== 0
        ? // (stage !== database.stage.Billing || allowChange)
          _c("div", [
            _c("div", { staticClass: "vx-col md:w-1/2 w-full mt-2 mb-3" }, [
              _c("ul", { staticClass: "centerx" }, [
                _c(
                  "li",
                  [
                    _c(
                      "vs-checkbox",
                      {
                        on: {
                          change: function($event) {
                            return _vm.refreshForm()
                          }
                        },
                        model: {
                          value: _vm.changeResponsible,
                          callback: function($$v) {
                            _vm.changeResponsible = $$v
                          },
                          expression: "changeResponsible"
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.ChangeResponsible.i18n) ||
                              _vm.resources.ChangeResponsible.name
                          )
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ]),
            _vm.changeResponsible
              ? _c(
                  "div",
                  { staticClass: "vx-col md:w-1/3 w-full mb-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.Responsible.i18n) ||
                            _vm.resources.Responsible.name
                        )
                      )
                    ]),
                    _c("v-select", {
                      attrs: {
                        clearable: false,
                        options: _vm.contactList,
                        reduce: function(x) {
                          return x.id
                        },
                        label: "name",
                        scrollable: ""
                      },
                      model: {
                        value: _vm.responsible,
                        callback: function($$v) {
                          _vm.responsible = $$v
                        },
                        expression: "responsible"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full mb-3" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Stage.i18n) ||
                        _vm.resources.Stage.name
                    )
                  )
                ]),
                _c("v-select", {
                  attrs: {
                    clearable: false,
                    options: _vm.stageOption,
                    reduce: function(x) {
                      return x.id
                    },
                    label: "name",
                    disabled: _vm.disabledStage,
                    scrollable: ""
                  },
                  model: {
                    value: _vm.stageId,
                    callback: function($$v) {
                      _vm.stageId = $$v
                    },
                    expression: "stageId"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/3 w-full mb-2" },
              [
                _c("vs-textarea", {
                  staticClass: "w-full",
                  attrs: {
                    "icon-pack": "feather",
                    "icon-no-border": "",
                    icon: "icon-clipboard",
                    size: "small",
                    label:
                      _vm.$t(_vm.resources.Remark.i18n) ||
                      _vm.resources.Remark.name
                  },
                  model: {
                    value: _vm.remark,
                    callback: function($$v) {
                      _vm.remark = $$v
                    },
                    expression: "remark"
                  }
                })
              ],
              1
            ),
            _vm.allowEdit
              ? _c(
                  "div",
                  { staticClass: "vx-col mb-4" },
                  [
                    _c(
                      "vs-button",
                      {
                        attrs: { color: "primary", type: "filled" },
                        on: {
                          click: function($event) {
                            return _vm.updateBookingStage()
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(_vm.resources.Save.i18n) ||
                                _vm.resources.Save.name
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm.tracking.length != 0
        ? _c(
            "vs-table",
            {
              attrs: { "max-items": "6", pagination: "", data: _vm.tracking },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(ref) {
                      var data = ref.data
                      return _vm._l(data, function(tr, indextr) {
                        return _c(
                          "vs-tr",
                          { key: indextr },
                          [
                            _c(
                              "vs-td",
                              { attrs: { data: data[indextr].createdDate } },
                              [_vm._v(_vm._s(data[indextr].createdDate))]
                            ),
                            _c(
                              "vs-td",
                              {
                                attrs: { data: data[indextr].previousStageName }
                              },
                              [_vm._v(_vm._s(data[indextr].previousStageName))]
                            ),
                            _c(
                              "vs-td",
                              { attrs: { data: data[indextr].newStageName } },
                              [_vm._v(_vm._s(data[indextr].newStageName))]
                            ),
                            _c(
                              "vs-td",
                              { attrs: { data: data[indextr].contactName } },
                              [_vm._v(_vm._s(data[indextr].contactName))]
                            ),
                            _c(
                              "vs-td",
                              { attrs: { data: data[indextr].createdBy } },
                              [_vm._v(_vm._s(data[indextr].createdBy))]
                            )
                          ],
                          1
                        )
                      })
                    }
                  }
                ],
                null,
                false,
                3417611005
              )
            },
            [
              _c(
                "template",
                { slot: "thead" },
                [
                  _c("vs-th", { attrs: { align: "center" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Date.i18n) ||
                          _vm.t(_vm.resources.Date.name)
                      )
                    )
                  ]),
                  _c("vs-th", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Previousstage.i18n) ||
                          _vm.t(_vm.resources.Previousstage.name)
                      )
                    )
                  ]),
                  _c("vs-th", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Currentstage.i18n) ||
                          _vm.t(_vm.resources.Currentstage.name)
                      )
                    )
                  ]),
                  _c("vs-th", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Responsable.i18n) ||
                          _vm.t(_vm.resources.Responsable.name)
                      )
                    )
                  ]),
                  _c("vs-th", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.CreatedBy.i18n) ||
                          _vm.t(_vm.resources.CreatedBy.name)
                      )
                    )
                  ])
                ],
                1
              )
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }