var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticStyle: { "padding-top": "7px" }, attrs: { id: "ag-grid-demo" } },
      [
        _c(
          "vx-card",
          [
            _c(
              "div",
              { staticClass: "flex flex-wrap justify-between items-center" },
              [
                _c(
                  "div",
                  {
                    staticClass: "mb-4 md:mb-0 mr-4 ag-grid-table-actions-left"
                  },
                  [
                    _c(
                      "vs-dropdown",
                      {
                        staticClass: "cursor-pointer",
                        attrs: { "vs-trigger-click": "" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                          },
                          [
                            _c("span", { staticClass: "mr-2" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.currentPage * _vm.paginationPageSize -
                                    (_vm.paginationPageSize - 1)
                                ) +
                                  " - " +
                                  _vm._s(
                                    _vm.dataList.length -
                                      _vm.currentPage * _vm.paginationPageSize >
                                      0
                                      ? _vm.currentPage * _vm.paginationPageSize
                                      : _vm.dataList.length
                                  ) +
                                  " of " +
                                  _vm._s(_vm.dataList.length)
                              )
                            ]),
                            _c("feather-icon", {
                              attrs: {
                                icon: "ChevronDownIcon",
                                svgClasses: "h-4 w-4"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "vs-dropdown-menu",
                          [
                            _c(
                              "vs-dropdown-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.gridApi.paginationSetPageSize(20)
                                  }
                                }
                              },
                              [_c("span", [_vm._v("20")])]
                            ),
                            _c(
                              "vs-dropdown-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.gridApi.paginationSetPageSize(50)
                                  }
                                }
                              },
                              [_c("span", [_vm._v("50")])]
                            ),
                            _c(
                              "vs-dropdown-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.gridApi.paginationSetPageSize(
                                      100
                                    )
                                  }
                                }
                              },
                              [_c("span", [_vm._v("100")])]
                            ),
                            _c(
                              "vs-dropdown-item",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.gridApi.paginationSetPageSize(
                                      150
                                    )
                                  }
                                }
                              },
                              [_c("span", [_vm._v("150")])]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-wrap items-center justify-between ag-grid-table-actions-right"
                  },
                  [
                    _c("vs-input", {
                      staticClass: "mb-4 md:mb-0 mr-4",
                      attrs: {
                        placeholder:
                          _vm.$t(_vm.resources.Search.i18n) ||
                          _vm.resources.Search.name
                      },
                      on: { input: _vm.updateSearchQuery },
                      model: {
                        value: _vm.searchQuery,
                        callback: function($$v) {
                          _vm.searchQuery = $$v
                        },
                        expression: "searchQuery"
                      }
                    }),
                    _c(
                      "vs-button",
                      {
                        staticClass: "mb-4 md:mb-0",
                        on: {
                          click: function($event) {
                            return _vm.gridApi.exportDataAsCsv()
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(_vm.resources.ExportAs.i18n) ||
                                _vm.resources.ExportAs.name
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "vs-button",
                      {
                        staticClass: "ml-1",
                        attrs: { color: "success" },
                        on: {
                          click: function($event) {
                            return _vm.cleanFields(true)
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(_vm.resources.Create.i18n) ||
                                _vm.resources.Create.name
                            )
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            ),
            _c("ag-grid-vue", {
              staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
              attrs: {
                gridOptions: _vm.gridOptions,
                columnDefs: _vm.columnDefs,
                defaultColDef: _vm.defaultColDef,
                rowData: _vm.dataList,
                rowSelection: "single",
                colResizeDefault: "shift",
                animateRows: true,
                floatingFilter: true,
                pagination: true,
                paginationPageSize: _vm.paginationPageSize,
                suppressPaginationPanel: true
              }
            }),
            _c("vs-pagination", {
              attrs: { total: _vm.totalPages, max: _vm.maxPageNumbers },
              model: {
                value: _vm.currentPage,
                callback: function($$v) {
                  _vm.currentPage = $$v
                },
                expression: "currentPage"
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "centerx" },
      [
        _c(
          "vs-popup",
          {
            attrs: {
              classContent: "popup overFlow",
              title:
                _vm.$t(_vm.resources.Create.i18n) || _vm.resources.Create.name,
              active: _vm.popupCreate
            },
            on: {
              "update:active": function($event) {
                _vm.popupCreate = $event
              }
            }
          },
          [
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/2 mb-2" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      type: "text",
                      "icon-pack": "feather",
                      icon: "icon-user",
                      "icon-no-border": "",
                      label:
                        _vm.$t(_vm.resources.Name.i18n) ||
                        _vm.resources.Name.name
                    },
                    model: {
                      value: _vm.name,
                      callback: function($$v) {
                        _vm.name = $$v
                      },
                      expression: "name"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/2 mb-2" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      type: "text",
                      "icon-pack": "feather",
                      icon: "icon-map",
                      "icon-no-border": "",
                      label:
                        _vm.$t(_vm.resources.Address.i18n) ||
                        _vm.resources.Address.name
                    },
                    model: {
                      value: _vm.address,
                      callback: function($$v) {
                        _vm.address = $$v
                      },
                      expression: "address"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/2 mb-2" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      type: "text",
                      "icon-pack": "feather",
                      icon: "icon-mail",
                      "icon-no-border": "",
                      label:
                        _vm.$t(_vm.resources.Email.i18n) ||
                        _vm.resources.Email.name
                    },
                    model: {
                      value: _vm.email,
                      callback: function($$v) {
                        _vm.email = $$v
                      },
                      expression: "email"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/2 mb-2" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      type: "text",
                      "icon-pack": "feather",
                      icon: "icon-phone",
                      "icon-no-border": "",
                      label:
                        _vm.$t(_vm.resources.Telephone.i18n) ||
                        _vm.resources.Telephone.name
                    },
                    model: {
                      value: _vm.phone,
                      callback: function($$v) {
                        _vm.phone = $$v
                      },
                      expression: "phone"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "vx-col md:w-1/4 w-full" }, [
                _c("ul", { staticClass: "vx-col w-full mt-4" }, [
                  _c(
                    "li",
                    [
                      _c(
                        "vs-checkbox",
                        {
                          model: {
                            value: _vm.active,
                            callback: function($$v) {
                              _vm.active = $$v
                            },
                            expression: "active"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.Active.i18n) ||
                                _vm.resources.Active.name
                            )
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            ]),
            _c(
              "div",
              { staticClass: "vx-col mt-4" },
              [
                _c(
                  "vs-button",
                  {
                    attrs: { color: "primary", type: "filled" },
                    on: {
                      click: function($event) {
                        return _vm.create()
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(_vm.resources.Save.i18n) ||
                            _vm.resources.Save.name
                        ) +
                        " "
                    )
                  ]
                )
              ],
              1
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }