<template>
  <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo">
    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ activeUserInfo }}</p>
      <small>{{
        $t(resources.Available.i18n) || resources.Available.name
      }}</small>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
      <div class="con-img ml-3">
        <img
          v-if="activeUserImg"
          key="onlineImg"
          :src="activeUserImg"
          @error="imageUrlAlt"
          alt="user-img"
          width="40"
          height="40"
          class="rounded-full shadow-md cursor-pointer block"
        />
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">
          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/pages/profile').catch(() => {})"
          >
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">{{
              $t(resources.Profile.i18n) || resources.Profile.name
            }}</span>
          </li>
          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="
              $router.push('/pages/incentive-commissions').catch(() => {})
            "
          >
            <feather-icon icon="DollarSignIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">{{
              $t(resources.MyCommissions.i18n) || resources.MyCommissions.name
            }}</span>
          </li>
          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/blockpay/employees').catch(() => {})"
          >
            <feather-icon icon="CreditCardIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Blockpay</span>
          </li>

          <!-- <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/apps/email').catch(() => {})"
          >
            <feather-icon icon="MailIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Inbox</span>
          </li>

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/apps/todo').catch(() => {})"
          >
            <feather-icon icon="CheckSquareIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Tasks</span>
          </li> -->

          <vs-divider class="m-1" />

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout"
          >
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">{{
              $t(resources.Logout.i18n) || resources.Logout.name
            }}</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
import resources from "@/i18n/resources.js";

export default {
  data() {
    return { resources: resources };
  },
  computed: {
    activeUserInfo() {
      var contactInfo = JSON.parse(localStorage.getItem("userInfo"));
      return contactInfo.name;
    },

    activeUserImg() {
      return JSON.parse(localStorage.getItem("userInfo")).photoURL;
    }
  },
  methods: {
    imageUrlAlt(event) {
      event.target.src =
        "https://cdn1.visiontravel.net/Images/avatar/default.jpg";
    },

    logout() {
      localStorage.removeItem("tokenVT");
      localStorage.removeItem("tokenExpiry");
      localStorage.removeItem("loggedIn");
      localStorage.removeItem("userInfo");
      localStorage.removeItem("language");
      localStorage.removeItem("periods");
      this.$router.push("/pages/login");
    }
  }
};
</script>
