<template>
  <div>
    <vx-card :title="$t(resources.Create.i18n) || resources.Create.name">
      <div class="vx-row mb-4">
        <div class="vx-col w-full md:w-1/4 mb-2">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.Application.i18n) || resources.Application.name
            }}</label>
            <v-select
              v-model="createDto.applicationId"
              :options="applicationsList"
              :reduce="item => item.id"
              label="name"
              v-validate="'required'"
              name="type"
            />
            <span class="text-danger text-sm" v-show="errors.has('type')">{{
              errors.first("type")
            }}</span>
          </div>
        </div>
        <div class="vx-col w-full md:w-1/4 mb-2">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.Supplier.i18n) || resources.Supplier.name
            }}</label>
            <v-select
              v-model="createDto.supplierId"
              :options="suppliersList"
              :reduce="item => item.id"
              label="name"
              v-validate="'required'"
              name="supplier"
            />
            <span class="text-danger text-sm" v-show="errors.has('supplier')">{{
              errors.first("supplier")
            }}</span>
          </div>
        </div>
        <div class="vx-col w-full md:w-1/4 mb-2">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.Product.i18n) || resources.Product.name
            }}</label>
            <v-select
              v-model="createDto.productId"
              :options="productsList"
              :reduce="item => item.id"
              label="name"
              v-validate="'required'"
              name="product"
            />
            <span class="text-danger text-sm" v-show="errors.has('product')">{{
              errors.first("product")
            }}</span>
          </div>
        </div>
        <div class="vx-col w-full md:w-1/4 mb-2">
          <div class="vx-col w-full">
            <label class="vs-input--label"
              >{{ $t(resources.ChargeIn.i18n) || resources.ChargeIn.name }} (%
              Merchant)</label
            >
            <v-select
              v-model="createDto.chargeIn"
              :options="chargesList"
              :reduce="item => item.id"
              label="name"
              v-validate="'required'"
              name="charge"
            />
            <span class="text-danger text-sm" v-show="errors.has('charge')">{{
              errors.first("charge")
            }}</span>
          </div>
        </div>

        <div class="vx-col md:w-1/4 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-dollar-sign"
              size="small"
              type="number"
              :label="$t(resources.Value.i18n) || resources.Value.name"
              v-model="createDto.value"
              v-validate="'required|decimal:2'"
              name="value"
            />
          </div>
          <span class="text-danger text-sm" v-show="errors.has('value')">{{
            errors.first("value")
          }}</span>
        </div>
        <div class="vx-col md:w-1/6 w-full mb-2">
          <ul class="vx-col w-full mt-5">
            <li>
              <vs-checkbox v-model="createDto.isPercentage">{{
                $t(resources.Percent.i18n) || resources.Percent.name
              }}</vs-checkbox>
            </li>
          </ul>
        </div>
        <div class="vx-col md:w-1/6 w-full mb-2">
          <ul class="vx-col w-full mt-5">
            <li>
              <vs-checkbox v-model="createDto.requireTresD"
                >3D Secure</vs-checkbox
              >
            </li>
          </ul>
        </div>
      </div>
      <vs-divider position="center"
        ><strong>{{
          $t(resources.Settings.i18n) || resources.Settings.name
        }}</strong></vs-divider
      >
      <div class="vx-row">
        <div class="vx-col w-full md:w-1/4 mb-2">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.Country.i18n) || resources.Country.name
            }}</label>
            <v-select
              v-model="countryCode"
              :clearable="true"
              :options="countriesList"
              :reduce="item => item.countryCode"
              label="countryName"
            />
          </div>
        </div>
        <div class="vx-col w-full md:w-1/4 mb-2">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.Currency.i18n) || resources.Currency.name
            }}</label>
            <v-select
              v-model="currencyCode"
              :clearable="true"
              :options="currenciesList"
              :reduce="item => item.code"
              label="name"
            />
          </div>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2 mt-5">
          <vs-button
            color="primary"
            type="filled"
            @click="getWayToPayByCountry()"
          >
            {{ $t(resources.Search.i18n) || resources.Search.name }}
          </vs-button>
        </div>
      </div>

      <div class="vx-row mb-base">
        <div class="vx-col w-full mb-2">
          <vs-table :data="wayToPayList">
            <template slot="thead">
              <vs-th>{{
                $t(resources.Type.i18n) || resources.Type.name
              }}</vs-th>
              <vs-th>Merchant</vs-th>
              <vs-th>{{
                $t(resources.CreditCard.i18n) || resources.CreditCard.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.AccountType.i18n) || resources.AccountType.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.Options.i18n) || resources.Options.name
              }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].merchantTypeName">{{
                  data[indextr].merchantTypeName
                }}</vs-td>
                <vs-td :data="data[indextr].merchantName">{{
                  data[indextr].merchantName
                }}</vs-td>
                <vs-td :data="data[indextr].creditCardTypeName">{{
                  data[indextr].creditCardTypeName
                }}</vs-td>
                <vs-td :data="data[indextr].accountTypeName">{{
                  data[indextr].accountTypeName
                }}</vs-td>
                <vs-td>
                  <vx-tooltip
                    :text="
                      $t(resources.Disabled.i18n) || resources.Disabled.name
                    "
                    class="w-5 h-5 mr-1"
                  >
                    <a @click="deleteCountryList(data[indextr])">
                      <feather-icon
                        icon="XIcon"
                        svgClasses="fill-current text-danger"
                        class="w-5 h-5 mr-1"
                      ></feather-icon>
                    </a>
                  </vx-tooltip>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
      <div class="vx-col mb-2 mt-5" style="text-align: right">
        <vs-button color="primary" type="filled" @click.prevent="validCreate()">
          {{ $t(resources.Save.i18n) || resources.Save.name }}
        </vs-button>
      </div>
    </vx-card>
  </div>
</template>
<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import vSelect from "vue-select";
import axios from "axios";
import database from "@/assets/resources/enums/database.json";

export default {
  components: {
    vSelect
  },
  data() {
    return {
      resources: resources,
      alerts: alerts,
      language: this.$i18n.locale,
      database: database,
      emailSession: JSON.parse(localStorage.getItem("userInfo")).email,
      dataList: [],
      applicationsList: [],
      productsList: [],
      suppliersList: [],
      countriesList: [],
      currenciesList: [],
      chargesList: [],
      wayToPayList: [],
      createDto: { active: true },
      countryCode: "",
      currencyCode: ""
    };
  },
  async created() {
    this.$vs.loading();

    await this.getSupplier();
    await this.getProducts();
    await this.getApplications();
    await this.getCountries();
    await this.getCharges();
    await this.getCurrencies();

    this.$vs.loading.close();
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    async getSupplier() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Supplier`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.suppliersList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getProducts() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Product/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.productsList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getApplications() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Application`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.applicationsList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCountries() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }CountryOperation/GetPccCountry/${this.$i18n.locale.toUpperCase()}/1`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data.length > 0) {
            this.countriesList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCharges() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }ChargeIn/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.chargesList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCurrencies() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Currency/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data.length > 0) {
            this.currenciesList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getWayToPayByCountry() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }WayToPay/GetByCountry/${this.$i18n.locale.toUpperCase()}/${
          this.countryCode
        }/${this.currencyCode}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data.length > 0) {
            this.wayToPayList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    deleteCountryList(item) {
      let _data = this.wayToPayList.filter(x => x.id == item.id);

      if (_data != undefined && _data != null) {
        let _index = this.wayToPayList.findIndex(x => x.id == item.id);
        if (_index > -1) this.wayToPayList.splice(_index, 1);
      }
    },

    validCreate() {
      this.$validator.validateAll().then(async result => {
        if (result && this.wayToPayList.length > 0) {
          await this.create();
        } else {
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: this.$i18n.t("MsgRequiredField"),
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      });
    },

    async create() {
      this.$vs.loading();
      let _wayToPay = [];

      this.wayToPayList.forEach(element => {
          _wayToPay.push(element.id);
      });

      this.createDto.waysToPay = _wayToPay;
      this.createDto.createdBy = this.emailSession;
      this.createDto.language = this.$i18n.locale.toUpperCase();

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}FeaturePaymentType`,
        data: this.createDto,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.$router.push({ path: "/payment/settings" });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    }
  }
};
</script>
