var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { attrs: { id: "ag-grid-demo" } },
      [
        _c(
          "vx-card",
          {
            staticClass: "mb-2",
            attrs: {
              title:
                _vm.$t(_vm.resources.Products.i18n) ||
                _vm.resources.Products.name
            }
          },
          [
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c(
                  "vs-table",
                  {
                    attrs: {
                      "max-items": "10",
                      pagination: "",
                      data: _vm.products
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var data = ref.data
                          return _vm._l(data, function(tr, indextr) {
                            return _c(
                              "vs-tr",
                              { key: indextr },
                              [
                                _c(
                                  "vs-td",
                                  { attrs: { data: data[indextr].code } },
                                  [_vm._v(_vm._s(data[indextr].code))]
                                ),
                                _c(
                                  "vs-td",
                                  { attrs: { data: data[indextr].name } },
                                  [_vm._v(_vm._s(data[indextr].name))]
                                ),
                                data[indextr].active
                                  ? _c(
                                      "vs-td",
                                      { attrs: { data: data[indextr].active } },
                                      [
                                        _c("feather-icon", {
                                          staticClass: "w-5 h-5 mr-1",
                                          attrs: {
                                            icon: "CheckIcon",
                                            svgClasses:
                                              "fill-current text-success"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _c(
                                      "vs-td",
                                      { attrs: { data: data[indextr].active } },
                                      [
                                        _c("feather-icon", {
                                          staticClass: "w-5 h-5 mr-1",
                                          attrs: {
                                            icon: "XIcon",
                                            svgClasses:
                                              "fill-current text-danger"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                _c(
                                  "vs-td",
                                  [
                                    _c(
                                      "vx-tooltip",
                                      {
                                        attrs: {
                                          text:
                                            _vm.$t(_vm.resources.View.i18n) ||
                                            _vm.resources.View.name
                                        }
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.$router.push(
                                                  "/settings/productDetail/" +
                                                    data[indextr].id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("feather-icon", {
                                              staticClass: "w-5 h-5 mr-1",
                                              attrs: { icon: "EyeIcon" }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          })
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "template",
                      { slot: "thead" },
                      [
                        _c("vs-th", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.Code.i18n) ||
                                _vm.resources.Code.name
                            )
                          )
                        ]),
                        _c("vs-th", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.Name.i18n) ||
                                _vm.resources.Name.name
                            )
                          )
                        ]),
                        _c("vs-th", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.Active.i18n) ||
                                _vm.resources.Active.name
                            )
                          )
                        ]),
                        _c("vs-th", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.Options.i18n) ||
                                _vm.resources.Options.name
                            )
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  2
                )
              ],
              1
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }