var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", [
    _vm.node.status === "T"
      ? _c(
          "div",
          { staticClass: "tooltip" },
          [
            _c("vs-avatar", {
              staticClass: "m-0",
              staticStyle: {
                "border-style": "solid",
                "border-color": "rgba(var(--vs-dark),1)",
                "border-width": "medium"
              },
              attrs: {
                size: "50px",
                src: "https://cdn1.visiontravel.net/Images/avatar/default.jpg"
              }
            }),
            _c("div", { staticClass: "bottom" }, [
              _c("p", [
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.DistributorID.i18n) ||
                        _vm.resources.DistributorID.name
                    )
                  )
                ]),
                _vm._v(" : " + _vm._s(_vm.node.dist) + " ")
              ]),
              _c("p", [
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Status.i18n) ||
                        _vm.resources.Status.name
                    )
                  )
                ]),
                _vm._v(
                  " : " +
                    _vm._s(
                      _vm.$t(_vm.resources.Terminated.i18n) ||
                        _vm.resources.Terminated.name
                    ) +
                    " "
                )
              ])
            ])
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "tooltip" },
          [
            _vm.node.endRankId === "0"
              ? _c("vs-avatar", {
                  staticClass: "m-0",
                  staticStyle: {
                    "border-style": "solid",
                    "border-color": "rgba(var(--vs-dark),1)",
                    "border-width": "thin"
                  },
                  attrs: {
                    src:
                      "https://cdn1.visiontravel.net/Images/avatar/" +
                      _vm.node.dist +
                      ".jpg",
                    size: "50px"
                  }
                })
              : _vm.node.endRankId === "1"
              ? _c("vs-avatar", {
                  staticClass: "m-0",
                  staticStyle: {
                    "border-style": "solid",
                    "border-color": "rgba(var(--vs-success),1)",
                    "border-width": "medium"
                  },
                  attrs: {
                    src:
                      "https://cdn1.visiontravel.net/Images/avatar/" +
                      _vm.node.dist +
                      ".jpg",
                    size: "50px"
                  }
                })
              : _vm.node.endRankId === "2"
              ? _c("vs-avatar", {
                  staticClass: "m-0",
                  staticStyle: {
                    "border-style": "solid",
                    "border-color": "rgb(173, 170, 214)",
                    "border-width": "medium"
                  },
                  attrs: {
                    src:
                      "https://cdn1.visiontravel.net/Images/avatar/" +
                      _vm.node.dist +
                      ".jpg",
                    size: "50px"
                  }
                })
              : _vm.node.endRankId === "3"
              ? _c("vs-avatar", {
                  staticClass: "m-0",
                  staticStyle: {
                    "border-style": "solid",
                    "border-color": "rgb(57, 208, 253)",
                    "border-width": "medium"
                  },
                  attrs: {
                    src:
                      "https://cdn1.visiontravel.net/Images/avatar/" +
                      _vm.node.dist +
                      ".jpg",
                    size: "50px"
                  }
                })
              : _vm.node.endRankId === "4"
              ? _c("vs-avatar", {
                  staticClass: "m-0",
                  staticStyle: {
                    "border-style": "solid",
                    "border-color": "rgb(27, 0, 246)",
                    "border-width": "medium"
                  },
                  attrs: {
                    src:
                      "https://cdn1.visiontravel.net/Images/avatar/" +
                      _vm.node.dist +
                      ".jpg",
                    size: "50px"
                  }
                })
              : _vm.node.endRankId === "5"
              ? _c("vs-avatar", {
                  staticClass: "m-0",
                  staticStyle: {
                    "border-style": "solid",
                    "border-color": "rgb(255, 252, 35)",
                    "border-width": "medium"
                  },
                  attrs: {
                    src:
                      "https://cdn1.visiontravel.net/Images/avatar/" +
                      _vm.node.dist +
                      ".jpg",
                    size: "50px"
                  }
                })
              : _vm.node.endRankId === "6"
              ? _c("vs-avatar", {
                  staticClass: "m-0",
                  staticStyle: {
                    "border-style": "solid",
                    "border-color": "rgb(255, 155, 8)",
                    "border-width": "medium"
                  },
                  attrs: {
                    src:
                      "https://cdn1.visiontravel.net/Images/avatar/" +
                      _vm.node.dist +
                      ".jpg",
                    size: "50px"
                  }
                })
              : _vm.node.endRankId === "7"
              ? _c("vs-avatar", {
                  staticClass: "m-0",
                  staticStyle: {
                    "border-style": "solid",
                    "border-color": "rgb(238, 33, 236)",
                    "border-width": "medium"
                  },
                  attrs: {
                    src:
                      "https://cdn1.visiontravel.net/Images/avatar/" +
                      _vm.node.dist +
                      ".jpg",
                    size: "50px"
                  }
                })
              : _vm.node.endRankId === "8"
              ? _c("vs-avatar", {
                  staticClass: "m-0",
                  staticStyle: {
                    "border-style": "solid",
                    "border-color": "rgb(238, 33, 33)",
                    "border-width": "medium"
                  },
                  attrs: {
                    src:
                      "https://cdn1.visiontravel.net/Images/avatar/" +
                      _vm.node.dist +
                      ".jpg",
                    size: "50px"
                  }
                })
              : _vm.node.endRankId === "9"
              ? _c("vs-avatar", {
                  staticClass: "m-0",
                  staticStyle: {
                    "border-style": "solid",
                    "border-color": "rgb(0, 255, 46 )",
                    "border-width": "medium"
                  },
                  attrs: {
                    src:
                      "https://cdn1.visiontravel.net/Images/avatar/" +
                      _vm.node.dist +
                      ".jpg",
                    size: "50px"
                  }
                })
              : _vm._e(),
            _c("div", { staticClass: "bottom" }, [
              _c("p", { staticClass: "text-center" }, [
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.BasicInformation.i18n) ||
                        _vm.resources.BasicInformation.name
                    )
                  )
                ])
              ]),
              _c("br"),
              _c("p", [
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.DistributorID.i18n) ||
                        _vm.resources.DistributorID.name
                    )
                  )
                ]),
                _vm._v(" : " + _vm._s(_vm.node.dist) + " ")
              ]),
              _vm.node.status == "D"
                ? _c("p", [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.Status.i18n) ||
                            _vm.resources.Status.name
                        )
                      )
                    ]),
                    _vm._v(
                      " : " +
                        _vm._s(
                          _vm.$t(_vm.resources.Distributor.i18n) ||
                            _vm.resources.Distributor.name
                        ) +
                        " "
                    )
                  ])
                : _vm.node.status == "S"
                ? _c("p", [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.Status.i18n) ||
                            _vm.resources.Status.name
                        )
                      )
                    ]),
                    _vm._v(
                      " : " +
                        _vm._s(
                          _vm.$t(_vm.resources.Suspended.i18n) ||
                            _vm.resources.Suspended.name
                        ) +
                        " "
                    )
                  ])
                : _c("p", [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.Status.i18n) ||
                            _vm.resources.Status.name
                        )
                      )
                    ]),
                    _vm._v(
                      " : " +
                        _vm._s(
                          _vm.$t(_vm.resources.Terminated.i18n) ||
                            _vm.resources.Terminated.name
                        ) +
                        " "
                    )
                  ]),
              _c("p", [
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Name.i18n) || _vm.resources.Name.name
                    )
                  )
                ]),
                _vm._v(" : " + _vm._s(_vm.node.name) + " ")
              ]),
              _c("p", [
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Center.i18n) ||
                        _vm.resources.Center.name
                    )
                  )
                ]),
                _vm._v(" : 00 " + _vm._s(_vm.node.bus_ctr) + " ")
              ]),
              _vm.node.side == "L"
                ? _c("p", [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.Side.i18n) ||
                            _vm.resources.Side.name
                        )
                      )
                    ]),
                    _vm._v(
                      " : " +
                        _vm._s(
                          _vm.$t(_vm.resources.Left.i18n) ||
                            _vm.resources.Left.name
                        ) +
                        " "
                    )
                  ])
                : _c("p", [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.Side.i18n) ||
                            _vm.resources.Side.name
                        )
                      )
                    ]),
                    _vm._v(
                      " : " +
                        _vm._s(
                          _vm.$t(_vm.resources.Right.i18n) ||
                            _vm.resources.Right.name
                        ) +
                        " "
                    )
                  ]),
              _c("p", [
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.VolumenPersonal.i18n) ||
                        _vm.resources.VolumenPersonal.name
                    )
                  )
                ]),
                _vm._v(" : " + _vm._s(_vm.node.vol1) + " ")
              ]),
              _c("p", [
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Volumen.i18n) ||
                        _vm.resources.Volumen.name
                    )
                  )
                ]),
                _vm._v(" : " + _vm._s(_vm.node.vol3) + " ")
              ]),
              _c("p", [
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.EndRank.i18n) ||
                        _vm.resources.EndRank.name
                    )
                  )
                ]),
                _vm._v(" : " + _vm._s(_vm.node.endRank) + " ")
              ])
            ])
          ],
          1
        ),
    _vm.node.children && _vm.node.children.length
      ? _c(
          "ul",
          _vm._l(_vm.node.children, function(child) {
            return _c("node", { key: child.id, attrs: { node: child } })
          }),
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }