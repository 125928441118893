var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative" }, [
    _c(
      "div",
      { staticClass: "vx-navbar-wrapper nav-menu-wrapper" },
      [
        _c(
          "vs-navbar",
          {
            staticClass: "vx-navbar navbar-custom navbar-skelton",
            attrs: { color: _vm.navbarColor }
          },
          [
            _c(
              "ul",
              { staticClass: "menu-items flex flex-wrap w-full items-center" },
              _vm._l(_vm.navMenuItems, function(item, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    staticClass: "menu-item",
                    class: { "mr-2": !(_vm.navMenuItems.length === index + 1) }
                  },
                  [
                    item.header
                      ? [
                          _c("h-nav-menu-header", {
                            staticClass: "menu-header relative",
                            attrs: { header: item }
                          })
                        ]
                      : item.submenu
                      ? [
                          _c("h-nav-menu-group", {
                            key: "group-" + index,
                            staticClass: "menu-group relative py-4",
                            attrs: {
                              bottom: "",
                              group: item,
                              groupIndex: index,
                              open: _vm.checkGrpChildrenActive(item)
                            }
                          })
                        ]
                      : item.url
                      ? _c(
                          "div",
                          { staticClass: "menu-link" },
                          [
                            _c(
                              "h-nav-menu-item",
                              {
                                staticClass: "relative py-4 cursor-pointer",
                                attrs: {
                                  to:
                                    item.slug !== "external" ? item.url : null,
                                  href:
                                    item.slug === "external" ? item.url : null,
                                  icon: item.icon,
                                  target: item.target,
                                  isDisabled: item.isDisabled,
                                  slug: item.slug
                                }
                              },
                              [
                                _c("span", { staticClass: "truncate" }, [
                                  _vm._v(_vm._s(_vm.$t(item.i18n) || item.name))
                                ]),
                                item.tag
                                  ? _c(
                                      "vs-chip",
                                      { attrs: { color: item.tagColor } },
                                      [_vm._v(_vm._s(item.tag))]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  2
                )
              }),
              0
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }