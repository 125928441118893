<template>
  <vx-card>
    <div>
      <h4 class="mb-2">{{$t(resources.Comments.i18n) || t(resources.Comments.name)}}</h4>
      <div class="vx-row">
        <vs-input
          class="vx-col w-full md:w-1/2 mb-2 inputx"
          :label="$t(resources.Subject.i18n) || t(resources.Subject.name)"
        />
        <div class="vx-col md:w-1/2 w-full mb-2">
          <label class="btn btn-primary" style="margin-right: 80px;">Adjunto</label>
          <vs-input type="file" class="w-full" v-model="file" />
        </div>
      </div>
      <vs-textarea
        :label="$t(resources.Comments.i18n) || t(resources.Comments.name)"
        class="vx-col w-full md:w-1/2 mb-4"
      />
      <vs-button
        color="primary"
        type="filled"
        class="mb-base"
        @click="getuploadForm()"
      >{{$t(resources.Save.i18n) || t(resources.Save.name)}}</vs-button>
    </div>
    <vs-tabs>
      <vs-tab
        :label="$t(resources.Comments.i18n) || t(resources.Comments.name)"
        icon-pack="feather"
        icon="icon-message-circle"
      >
        <div class="vx-row">
          <div class="vx-col w-full mb-base">
            <vx-card>
              <vx-timeline :data="comments" />
            </vx-card>
          </div>
        </div>
      </vs-tab>

      <vs-tab
        :label="$t(resources.Documents.i18n) || t(resources.Documents.name)"
        icon-pack="feather"
        icon="icon-file"
      >
        <div class="vx-row">
          <div class="vx-col w-full mb-base">
            <vx-card title="Activity">
              <vx-timeline :data="timelineDocuments" />
            </vx-card>
          </div>
        </div>
      </vs-tab>

      <vs-tab
        :label="$t(resources.Trace.i18n) || t(resources.Trace.name)"
        icon-pack="feather"
        icon="icon-list"
      >
        <div>
          <vs-table :data="trace">
            <template slot="thead">
              <vs-th align="center">{{$t(resources.Date.i18n) || t(resources.Date.name)}}</vs-th>
              <vs-th>{{$t(resources.Previousstage.i18n) || t(resources.Previousstage.name)}}</vs-th>
              <vs-th>{{$t(resources.Currentstage.i18n) || t(resources.Currentstage.name)}}</vs-th>
              <vs-th>{{$t(resources.Responsable.i18n) || t(resources.Responsable.name)}}</vs-th>
            </template>
            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].createdDate">{{ data[indextr].createdDate }}</vs-td>

                <vs-td
                  :data="data[indextr].previousStageName"
                >{{ data[indextr].previousStageName == "" ? "-" : data[indextr].previousStageName == "" }}</vs-td>

                <vs-td :data="data[indextr].newStageName">{{ data[indextr].newStageName }}</vs-td>

                <vs-td :data="data[indextr].contactName">{{ data[indextr].contactName }}</vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>

<script>
export default {
  data() {
    return {
      model: null
    };
  },
  methods: {
  }
};
</script>