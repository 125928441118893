<template>
  <div>
    <vs-row vs-type="flex" vs-justify="flex-end">
      <vx-tooltip
        :text="$t(resources.AddPassenger.i18n) || resources.AddPassenger.name"
      >
        <vs-button
          radius
          class="mr-0 mb-2"
          color="primary"
          icon-pack="feather"
          @click="showPopUpPassenger(0, false)"
          type="border"
          icon="icon-plus"
        ></vs-button>
      </vx-tooltip>
    </vs-row>
    <vs-table :data="getFilter()">
      <template slot="thead">
        <vs-th>{{ $t(resources.Type.i18n) || resources.Type.name }}</vs-th>
        <vs-th>{{ $t(resources.Name.i18n) || resources.Name.name }}</vs-th>
        <vs-th>{{
          $t(resources.LastName.i18n) || resources.LastName.name
        }}</vs-th>
        <vs-th>{{
          $t(resources.DocumentNumber.i18n) || resources.DocumentNumber.name
        }}</vs-th>
        <vs-th>{{
          $t(resources.BirthDate.i18n) || resources.BirthDate.name
        }}</vs-th>
        <vs-th
          v-if="
            productId != database.products.HOT &&
              productId != database.products.PAQ
          "
          >{{
            $t(resources.ExpirationDatePassport.i18n) ||
              resources.ExpirationDatePassport.name
          }}</vs-th
        >
        <vs-th>{{
          $t(resources.Options.i18n) || resources.Options.name
        }}</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].passengerTypeName">{{
            data[indextr].passengerTypeName
          }}</vs-td>
          <vs-td :data="data[indextr].firstName">{{
            data[indextr].firstName
          }}</vs-td>
          <vs-td :data="data[indextr].lastName">{{
            data[indextr].lastName
          }}</vs-td>
          <vs-td :data="data[indextr].documentNumber">{{
            data[indextr].documentNumber
          }}</vs-td>
          <vs-td :data="data[indextr].birthDate">{{
            data[indextr].birthDate
          }}</vs-td>
          <vs-td
            v-if="
              productId != database.products.HOT &&
                productId != database.products.PAQ
            "
            :data="data[indextr].passportExpiration"
            >{{ data[indextr].passportExpiration }}</vs-td
          >
          <vs-td>
            <vx-tooltip :text="$t(resources.Edit.i18n) || resources.Edit.name">
              <a @click="showPopUpPassenger(data[indextr], true)">
                <feather-icon
                  icon="EditIcon"
                  class="w-5 h-5 mr-1"
                ></feather-icon>
              </a>
            </vx-tooltip>
            <vx-tooltip
              :text="$t(resources.Delete.i18n) || resources.Delete.name"
            >
              <a @click="remove(data[indextr])">
                <feather-icon
                  icon="XIcon"
                  svgClasses="fill-current text-danger"
                  class="w-5 h-5 mr-1"
                ></feather-icon>
              </a>
            </vx-tooltip>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-popup
      :title="$t(resources.Passengers.i18n) || resources.Passengers.name"
      :active.sync="popupActivePassenger"
    >
      <div class="vx-row mb-4">
        <div class="vx-col md:w-1/2 w-full">
          <vs-select
            :label="
              $t(resources.PassengerType.i18n) || resources.PassengerType.name
            "
            v-model="passengerTypeId"
            class="w-full"
          >
            <vs-select-item
              v-for="(item, index) in passengerType"
              :key="index"
              :value="item.value"
              :text="item.text"
            />
          </vs-select>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <vs-select
            :label="
              $t(resources.DocumentType.i18n) || resources.DocumentType.name
            "
            v-model="documentType"
            class="w-full"
          >
            <vs-select-item
              :key="index"
              :value="item.id"
              :text="item.name"
              v-for="(item, index) in identifiacionTypeList"
            />
          </vs-select>
        </div>

        <div class="vx-col md:w-1/2 w-full">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-hash"
              icon-no-border
              :label="
                $t(resources.DocumentNumber.i18n) ||
                  resources.DocumentNumber.name
              "
              v-model="documentNumber"
            />
          </div>
        </div>

        <div class="vx-col md:w-1/2 w-full">
          <label class="vs-input--label">{{
            $t(resources.BirthDate.i18n) || resources.BirthDate.name
          }}</label>
          <datepicker
            :language="languages[language]"
            format="d MMMM yyyy"
            v-model="birthDate"
          ></datepicker>
        </div>

        <div class="vx-col md:w-1/2 w-full">
          <vs-input
            class="w-full"
            icon-pack="feather"
            icon-no-border
            icon="icon-user"
            :label="$t(resources.Name.i18n) || resources.Name.name"
            v-model="firstName"
          />
        </div>

        <div class="vx-col md:w-1/2 w-full">
          <vs-input
            class="w-full"
            icon-pack="feather"
            icon-no-border
            icon="icon-user"
            :label="$t(resources.LastName.i18n) || resources.LastName.name"
            v-model="lastName"
          />
        </div>

        <div class="vx-col md:w-1/2 w-full">
          <vs-select
            :label="$t(resources.Gender.i18n) || resources.Gender.name"
            v-model="genderId"
            class="w-full"
          >
            <vs-select-item
              v-for="(item, index) in genderType"
              :key="index"
              :value="item.value"
              :text="item.text"
            />
          </vs-select>
        </div>

        <div class="vx-col md:w-1/2 w-full">
          <label class="vs-input--label">{{
            $t(resources.Country.i18n) || resources.Country.name
          }}</label>
          <v-select
            v-model="nationalityId"
            :clearable="false"
            :options="countryList"
            :reduce="country => country.id"
            label="name"
          />
        </div>

        <div
          class="vx-col md:w-1/2 w-full"
          v-if="
            productId != database.products.HOT &&
              productId != database.products.PAQ
          "
        >
          <label class="vs-input--label">{{
            $t(resources.ExpirationDatePassport.i18n) ||
              resources.ExpirationDatePassport.name
          }}</label>
          <datepicker
            :language="languages[language]"
            format="d MMMM yyyy"
            v-model="passportExpiration"
          ></datepicker>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon-no-border
              icon="icon-phone"
              type="number"
              :label="$t(resources.Telephone.i18n) || resources.Telephone.name"
              v-model="Telephone"
            />
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <vs-input
            class="w-full"
            icon-pack="feather"
            icon-no-border
            icon="icon-hash"
            :label="
              $t(resources.TicketNumber.i18n) || resources.TicketNumber.name
            "
            v-model="ticketNumber"
          />
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <vs-input
            name="passengerEmail"
            :label="$t(resources.Email.i18n) || resources.Email.name"
            v-model="email"
            v-validate="'email'"
            class="w-full"
            icon-pack="feather"
            icon="icon-mail"
            maxlength="150"
            icon-no-border
          />
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <vs-input
            readonly
            class="w-full"
            icon-pack="feather"
            icon-no-border
            icon="icon-map"
            :label="
              $t(resources.ResidenceCity.i18n) || resources.ResidenceCity.name
            "
            v-model="residence"
          />
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <vs-input
            readonly
            class="w-full"
            icon-pack="feather"
            icon-no-border
            icon="icon-map-pin"
            :label="$t(resources.Address.i18n) || resources.Address.name"
            v-model="address"
          />
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <vs-input
            readonly
            class="w-full"
            icon-pack="feather"
            icon-no-border
            icon="icon-hash"
            :label="$t(resources.ZipCode.i18n) || resources.ZipCode.name"
            v-model="postalCode"
          />
        </div>
        <div class="vx-col w-full md:w-1/4 mb-2 mt-5">
          <vs-button color="primary" type="filled" @click="savePassenger()">
            {{ $t(resources.Save.i18n) || resources.Save.name }}
          </vs-button>
        </div>
      </div>
      <vs-divider class="mb-2">
        <h4 class="font-semibold mb-base">
          {{
            $t(resources.Specifications.i18n) || resources.Specifications.name
          }}
        </h4>
      </vs-divider>
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full">
          <vs-input
            class="w-full"
            icon-pack="feather"
            icon-no-border
            icon="icon-edit"
            :label="
              $t(resources.Specification.i18n) || resources.Specification.name
            "
            v-model="specificationName"
          />
        </div>
        <div class="vx-col w-full md:w-1/2 mb-2 mt-5">
          <vs-button color="primary" type="filled" @click="addSpecification()">
            {{ $t(resources.Save.i18n) || resources.Save.name }}
          </vs-button>
        </div>

        <div class="vx-col w-full">
          <vs-table :data="specifications">
            <template slot="thead">
              <vs-th>{{
                $t(resources.Specification.i18n) || resources.Specification.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.Options.i18n) || resources.Options.name
              }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].specificationValue">{{
                  data[indextr].specificationValue
                }}</vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import utilities from "@/assets/resources/utilities.json";
import resources from "@/i18n/resources.js";
import vSelect from "vue-select";
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import moment from "moment";
import database from "@/assets/resources/enums/database.json";

export default {
  components: {
    vSelect,
    Datepicker
  },
  name: "passenger",
  props: {
    bookingId: String,
    productId: String,
    bookingDetailId: String,
    passengerListDto: Array
  },
  data() {
    return {
      resources: resources,
      utilities: utilities,
      database: database,
      popupActivePassenger: false,
      identifiacionTypeList: [],
      passengerList: [],
      language: this.$i18n.locale.toUpperCase(),
      languages: lang,
      passengerType: [
        { text: "Adulto", value: 1 },
        { text: "Niño", value: 7 },
        { text: "Niño Acompañado", value: 3 }
      ],
      genderType: [
        { text: "Seleccione", value: 0 },
        { text: "Femenino", value: 1 },
        { text: "Masculino", value: 2 },
        { text: "Otro", value: 3 }
      ],
      countryList: [],
      specificationName: "",
      specifications: [],
      documentNumber: "",
      documentType: "",
      Telephone: "",
      birthDate: "",
      genderId: 0,
      passengerTypeId: 0,
      firstName: "",
      lastName: "",
      passportExpiration: "",
      fare: 0,
      tax: 0,
      fee: 0,
      nationality: "",
      nationalityId: 0,
      passengerDto: null,
      ticketNumber: 0,
      email: "",
      passengerTypeName: "",
      phoneType: 0,
      title: "",
      areaCityCode: "",
      currencyCode: "",
      passengerUpdate: [],
      idPassenger: 0,
      isSaved: false,
      residence: "",
      address: "",
      postalCode: ""
    };
  },
  methods: {
    showPopUpPassenger(item, isEdit) {
      if (isEdit) {
        this.isSaved = true;
        this.idPassenger = item.id;
        this.ticketNumber = item.ticketNumber;
        this.passengerTypeId = item.passengerTypeId;
        this.passengerTypeName = item.passengerTypeName;
        this.phoneType = item.phoneType;
        this.nationality = item.nationality;
        this.nationalityId = item.nationalityId;
        this.Telephone = item.telephone;
        this.firstName = item.firstName;
        this.lastName = item.lastName;
        this.documentType = item.documentType;
        this.documentNumber = item.documentNumber;
        this.title = item.title;
        this.areaCityCode = item.areaCityCode;
        this.birthDate = moment(item.birthDate).format("LL");
        this.passportExpiration = moment(item.passportExpiration).format("LL");
        this.currencyCode = item.currencyCode;
        this.genderId = item.genderId;
        this.residence = item.destination;
        this.address = item.address;
        this.postalCode = item.postalCode;
        this.email = item.email;
        this.specifications = item.passengerSpecification;
      } else {
        this.isSaved = false;
        this.ticketNumber = "";
        this.passengerTypeId = 0;
        this.nationalityId = 0;
        this.Telephone = "";
        this.firstName = "";
        this.lastName = "";
        this.documentType = "";
        this.documentNumber = "";
        this.birthDate = "";
        this.passportExpiration = "";
        this.genderId = 0;
        this.specifications = [];
      }
      this.popupActivePassenger = true;
    },

    async getPassenger() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Passenger/${this.$i18n.locale.toUpperCase()}/${this.bookingId}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data.success) this.passengerList = result.data.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getIdentifiacionType() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }IdentificationType/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) this.identifiacionTypeList = result.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCountry() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Country/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.countryList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async savePassenger() {
      if (this.email == null || this.email.trim() == "") {
        this.$vs.notify({
          title: this.$i18n.t("Alert"),
          text: "El correo electrónico es un OBLIGATORIO en el pasajero.",
          color: "warning",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-x-circle"
        });
        return;
      }

      if (this.isSaved) {
        this.updatePassenger();
      } else {
        let contactInfo = JSON.parse(localStorage.getItem("userInfo"));
        let _data = {
          bookingDetailId: this.bookingDetailId,
          ticketNumber: this.ticketNumber,
          passengerTypeId: this.passengerTypeId,
          telephone: this.Telephone,
          firstName: this.firstName,
          lastName: this.lastName,
          documentType: this.documentType,
          documentNumber: this.documentNumber,
          birthDate: moment(this.birthDate).format("YYYY-MM-DD"),
          language: this.$i18n.locale.toUpperCase(),
          gender: this.genderId,
          countryId: this.nationalityId,
          productId: this.productId,
          createdBy: contactInfo.email,
          email: this.email,
          specification: this.specifications.map(x => x.specificationValue)
        };

        if (
          this.passportExpiration != null &&
          this.passportExpiration != "" &&
          this.passportExpiration != "Invalid date"
        ) {
          _data.passportExpiration = moment(this.passportExpiration).format(
            "YYYY-MM-DD"
          );
        } else {
          _data.passportExpiration = moment("1900-01-01").format("YYYY-MM-DD");
        }
        if (
          this.birthDate != null &&
          this.birthDate != "" &&
          this.birthDate != "Invalid date"
        ) {
          _data.birthDate = moment(this.birthDate).format("YYYY-MM-DD");
        } else {
          _data.birthDate = moment("1900-01-01").format("YYYY-MM-DD");
        }

        await axios({
          method: "POST",
          url: `${process.env.VUE_APP_APIVT}Passenger/create`,
          data: _data,
          headers: {
            "content-type": "application/json",
            Authorization: `bearer ${localStorage.getItem("tokenVT")}`
          }
        }).then(
          async result => {
            if (result.data) {
              this.popupActivePassenger = false;
              await this.getPassenger();
            }
          },
          error => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError") + "<br/>" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        );
      }
    },

    async updatePassenger() {
      if (
        this.passportExpiration != null &&
        this.passportExpiration != "" &&
        this.passportExpiration != "Invalid date"
      ) {
        this.passportExpiration = moment(this.passportExpiration).format(
          "YYYY-MM-DD"
        );
      } else {
        this.passportExpiration = moment("1900-01-01").format("YYYY-MM-DD");
      }
      await axios({
        method: "POST",
        url: `${
          process.env.VUE_APP_APIVT
        }Passenger/Update/${this.$i18n.locale.toUpperCase()}`,
        data: {
          id: this.idPassenger,
          bookingId: this.bookingId,
          bookingDetailId: this.bookingDetailId,
          ticketNumber: this.ticketNumber,
          passengerTypeId: this.passengerTypeId,
          passengerTypeName: this.passengerTypeName,
          phoneType: this.phoneType,
          nationality: this.nationality,
          nationalityId: this.nationalityId,
          telephone: this.Telephone,
          firstName: this.firstName,
          lastName: this.lastName,
          documentType: this.documentType,
          documentNumber: this.documentNumber,
          title: this.title,
          areaCityCode: this.areaCityCode,
          fare: this.fare,
          fee: this.fee,
          tax: this.tax,
          birthDate: this.birthDate,
          passportExpiration: this.passportExpiration,
          currencyCode: this.currencyCode,
          email: this.email,
          passengerSpecification: this.specifications
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (!result.data) {
            this.popupActivePassenger = false;
            this.passengerUpdate = [];
          } else {
            this.passengerUpdate = result.data;
            this.getPassenger();

            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
          this.merchantOption = [];
        }
      );
    },

    async remove(item) {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Passenger/Disabled/${this.$i18n.locale.toUpperCase()}/${item.id}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            await this.getPassenger();
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    getFilter() {
      return this.passengerList.filter(
        x => x.bookingDetailId == this.bookingDetailId
      );
    },

    addSpecification() {
      if (this.specificationName !== "") {
        this.specifications.push({
          specificationValue: this.specificationName
        });
        this.specificationName = "";
      } else this.warningNotify();
    },

    warningNotify() {
      this.$vs.notify({
        title: this.$i18n.t("Alert"),
        text: this.$i18n.t("MsgNotFoundInfo"),
        color: "warning",
        iconPack: "feather",
        position: "top-right",
        icon: "icon-x-circle"
      });
    }
  },
  async created() {
    await this.getIdentifiacionType();
    await this.getCountry();
    if (this.bookingId !== "0") this.getPassenger();
    else this.passengerList = this.passengerListDto;
  }
};
</script>
