<template>
  <div>
    <vx-card>
      <div class="vx-row">
        <div class="vx-col md:w-1/4 w-full mb-base">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.Type.i18n) || resources.Type.name
            }}</label>
            <v-select
              type="text"
              class="w-full"
              icon-pack="feather"
              icon="icon-arrow-down"
              icon-no-border
              :clearable="true"
              :options="typeOption"
              :reduce="x => x.name"
              label="name"
              v-model="type"
            />
          </div>
        </div>
        <div class="vx-col md:w-1/4 w-full mb-base">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.ContentType.i18n) || resources.ContentType.name
            }}</label>
            <v-select
              type="text"
              class="w-full"
              icon-pack="feather"
              icon="icon-arrow-down"
              icon-no-border
              :clearable="true"
              :options="contentTypeOption"
              :reduce="x => x.name"
              label="name"
              v-model="contentType"
            />
          </div>
        </div>
        <div class="vx-col md:w-1/4 w-full mb-base">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.Language.i18n) || resources.Language.name
            }}</label>
            <v-select
              type="text"
              class="w-full"
              icon-pack="feather"
              icon="icon-arrow-down"
              icon-no-border
              :clearable="true"
              :options="languageOption"
              :reduce="language => language.internationalStandard"
              label="name"
              v-model="language"
            />
          </div>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2 mt-5">
          <vs-button color="primary" type="filled" @click="get()">
            {{ $t(resources.Search.i18n) || resources.Search.name }}
          </vs-button>
        </div>
      </div>
      <vs-tabs>
        <vs-tab
          :label="$t(resources.List.i18n) || resources.List.name"
          icon-pack="feather"
          icon="icon-list"
        >
          <vs-table
            @search="filtered"
            search
            pagination
            max-items="10"
            :data="dataList"
          >
            <template slot="thead">
              <vs-th>{{
                $t(resources.Type.i18n) || resources.Type.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.ContentType.i18n) || resources.ContentType.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.Title.i18n) || resources.Title.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.HasAttachment.i18n) || resources.HasAttachment.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.Status.i18n) || resources.Status.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.IsFeatured.i18n) || resources.IsFeatured.name
              }}</vs-th>
              <vs-th>{{
                $t(resources.Options.i18n) || resources.Options.name
              }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].title">{{
                  data[indextr].blogTypeName
                }}</vs-td>
                <vs-td :data="data[indextr].title">{{
                  data[indextr].contentTypeName
                }}</vs-td>
                <vs-td :data="data[indextr].title">{{
                  data[indextr].title
                }}</vs-td>
                <vs-td
                  :data="data[indextr].hasAttachment"
                  v-if="data[indextr].hasAttachment"
                  class="text-center"
                >
                  <vs-icon
                    icon-pack="feather"
                    icon="icon-check-circle"
                    color="success"
                  ></vs-icon>
                </vs-td>
                <vs-td
                  :data="data[indextr].hasAttachment"
                  v-else
                  class="text-center"
                >
                  <vs-icon
                    icon-pack="feather"
                    icon="icon-x-circle"
                    color="danger"
                  ></vs-icon>
                </vs-td>
                <vs-td
                  :data="data[indextr].active"
                  v-if="data[indextr].active"
                  class="text-center"
                >
                  <vs-icon
                    icon-pack="feather"
                    icon="icon-check-circle"
                    color="success"
                  ></vs-icon>
                </vs-td>
                <vs-td :data="data[indextr].active" v-else class="text-center">
                  <vs-icon
                    icon-pack="feather"
                    icon="icon-x-circle"
                    color="danger"
                  ></vs-icon>
                </vs-td>
                <vs-td
                  :data="data[indextr].isFeatured"
                  v-if="data[indextr].isFeatured"
                  class="text-center"
                >
                  <vs-icon
                    icon-pack="feather"
                    icon="icon-check-circle"
                    color="success"
                  ></vs-icon>
                </vs-td>
                <vs-td :data="data[indextr].active" v-else class="text-center">
                  <vs-icon
                    icon-pack="feather"
                    icon="icon-x-circle"
                    color="danger"
                  ></vs-icon>
                </vs-td>
                <vs-td :data="data[indextr]">
                  <a @click="openUpdate(data[indextr])">
                    <vs-icon icon-pack="feather" icon="icon-edit"></vs-icon>
                  </a>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vs-tab>
        <vs-tab
          :label="$t(resources.Preview.i18n) || resources.Preview.name"
          icon-pack="feather"
          icon="icon-image"
        >
          <div id="knowledge-base-page">
            <!-- JUMBOTRON -->
            <div class="knowledge-base-jumbotron mb-4">
              <vs-input
                icon-no-border
                :placeholder="
                  $t(resources.Search.i18n) || resources.Search.name
                "
                v-model="searchQuery"
                icon-pack="feather"
                icon="icon-search"
                size="large"
                class="w-full mt-6"
                v-on:input="filtered(`${searchQuery}`)"
                :hint="`${searchQuery}`"
              />
            </div>

            <!-- KNOWLEDGE BASE CARDS  -->
            <div class="vx-row">
              <div
                class="vx-col w-full md:w-1/2 sm:w-full mb-4"
                v-for="item in dataList"
                :key="item.id"
              >
                <vx-card class="text-center cursor-pointer">
                  <iframe
                    v-if="item.attachmentType == 'MP4'"
                    width="350"
                    height="370"
                    :src="item.attachementUrl"
                    frameborder="0"
                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <embed
                    v-else-if="item.attachmentType == 'PDF'"
                    :src="item.attachementUrl"
                    width="700"
                    height="370"
                  />
                  <img
                    v-else
                    :src="item.attachementUrl"
                    alt="graphic"
                    width="350"
                    class="mx-auto mb-4"
                  />
                  <vs-button
                    color="success"
                    type="line"
                    icon-pack="feather"
                    icon="icon-download-cloud"
                    size="large"
                    @click="openDocument(`${item.attachementUrl}`)"
                  ></vs-button>
                  <h4 class="mb-2">
                    <strong>{{ item.title }}</strong>
                  </h4>
                  <div v-html="item.body"></div>
                </vx-card>
              </div>
            </div>
          </div>
        </vs-tab>
        <vs-tab
          :label="$t(resources.Create.i18n) || resources.Create.name"
          icon-pack="feather"
          icon="icon-plus"
        >
          <create-blog class="mt-base" />
        </vs-tab>
      </vs-tabs>
    </vx-card>
    <vs-popup
      :title="$t(resources.Update.i18n) || resources.Update.i18n"
      :active.sync="popupEdit"
    >
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full mb-2">
          <label class="vs-input--label">{{
            $t(resources.Type.i18n) || resources.Type.name
          }}</label>
          <v-select
            type="text"
            class="w-full"
            icon-pack="feather"
            icon="icon-arrow-down"
            icon-no-border
            :clearable="false"
            :options="typeOption"
            :reduce="x => x.id"
            label="name"
            v-model="updateDto.blogTypeId"
          />
        </div>
        <div class="vx-col md:w-1/2 w-full mb-2">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.ContentType.i18n) || resources.ContentType.name
            }}</label>
            <v-select
              type="text"
              class="w-full"
              icon-pack="feather"
              icon="icon-arrow-down"
              icon-no-border
              :clearable="false"
              :options="contentTypeOption"
              :reduce="x => x.id"
              label="name"
              v-model="updateDto.contentTypeId"
            />
          </div>
        </div>
        <div class="vx-col w-full mb-2">
          <label class="vs-input--label">{{
            $t(resources.Title.i18n) || resources.Title.name
          }}</label>
          <vs-input
            name="title"
            v-model="updateDto.title"
            class="w-full"
            :maxlength="50"
            v-validate="'required|max:50'"
          />
          <span class="text-danger text-sm" v-show="errors.has('title')">{{
            errors.first("title")
          }}</span>
        </div>
        <div class="vx-col w-full mb-2">
          <quill-editor v-model="updateDto.body" :options="editorOption" />
        </div>
        <div class="vx-col w-full mb-2">
          <ul class="vx-col w-full mt-5">
            <li>
              <vs-checkbox v-model="updateDto.isFeatured">{{
                $t(resources.IsFeatured.i18n) || resources.IsFeatured.name
              }}</vs-checkbox>
            </li>
          </ul>
        </div>
      </div>
      <div class="vx-col mb-base mt-base" style="text-align: right">
        <vs-button color="primary" type="filled" @click="update()">
          {{ $t(resources.Save.i18n) || resources.Save.name }}
        </vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import resources from "@/i18n/resources.js";
import createBlog from "./createBlog.vue";
import axios from "axios";
import vSelect from "vue-select";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    createBlog,
    vSelect,
    quillEditor
  },
  data() {
    return {
      resources: resources,
      searchQuery: "",
      dataList: [],
      language: "ES",
      languageOption: [],
      type: "",
      typeOption: [],
      contentType: "",
      contentTypeOption: [],
      popupEdit: false,
      updateDto: {
        id: 0,
        blogTypeId: "",
        contentTypeId: "",
        title: "",
        body: "",
        isFeatured: false,
        language: this.$i18n.locale.toUpperCase(),
        createdBy: JSON.parse(localStorage.getItem("userInfo")).email
      },
      editorOption: {
        modules: {
          toolbar: [
            [
              "bold",
              "italic",
              "underline",
              "strike",
              "link",
              "blockquote",
              "code-block"
            ],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ font: [] }]
          ]
        },
        placeholder: "Message"
      }
    };
  },
  async created() {
    await this.get();
    await this.getLanguage();
    await this.getContentType();
    await this.getType();
  },
  computed: {},
  methods: {
    filtered(searchQuery) {
      if (searchQuery.length >= 3) {
        this.dataList = this.dataList.filter(item =>
          item.title.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
    },

    openUpdate(item) {
      this.updateDto.id = item.id;
      this.updateDto.blogTypeId = item.blogTypeId;
      this.updateDto.contentTypeId = item.contentTypeId;
      this.updateDto.title = item.title;
      this.updateDto.body = item.body;
      this.updateDto.isFeatured = item.isFeatured;
      this.popupEdit = true;
    },

    async get() {
      this.$vs.loading();
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Blog/Search`,
        data: {
          searchKeys: {
            entityid: 19
          },
          page: 1,
          rows: 500,
          language: this.language
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.dataList = [];
          } else {
            this.dataList = result.data.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async getLanguage() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Language`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.languageOption = [];
          } else {
            this.languageOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getType() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }BlogType/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.typeOption = [];
          } else {
            this.typeOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getContentType() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }ContentType/${this.language.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.contentTypeOption = [];
          } else {
            this.contentTypeOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async update() {
      this.$vs.loading();

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Blog/Update`,
        data: this.updateDto,
        headers: {
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
          "content-type": "application/json"
        }
      }).then(
        async result => {
          if (result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.popupEdit = false;
            await this.get();
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    openDocument(url) {
      window.open(url);
    }
  }
};
</script>
