<template>
  <div>
    <vx-card class="mb-4">
      <h2 class="font-semibold">
        {{
        $t(resources.CustomizedCommissions.i18n) ||
        resources.CustomizedCommissions.name
        }}
      </h2>
      <h5 class="font-semibold mt-4 mb-2">
        {{
        $t(alerts.MsgConsiderInformation.i18n) ||
        alerts.MsgConsiderInformation.i18n
        }}
      </h5>
      <p>
        -
        {{
        $t(alerts.MsgPayTransaction.i18n) || alerts.MsgPayTransaction.i18n
        }}.
      </p>
      <p>- {{ $t(alerts.MsgDaysToPayment.i18n) || alerts.MsgDaysToPayment.i18n }}</p>
      <p>
        -
        {{ $t(alerts.MsgNotEnoughMoney.i18n) || alerts.MsgNotEnoughMoney.i18n }}
      </p>
      <p>- {{ $t(alerts.MsgStageBill.i18n) || alerts.MsgStageBill.i18n }}.</p>
      <br />
      <h4 v-if="pendingValue < -1" class="text-center text-danger">
        USD ${{ pendingValue }}
        {{
        $t(alerts.MsgTotalPendingCharges.i18n) ||
        alerts.MsgTotalPendingCharges.i18n
        }}.
      </h4>
      <p class="text-center font-semibold">
        {{
        $t(alerts.MsgWriteToCustomerService.i18n) ||
        alerts.MsgWriteToCustomerService.i18n
        }}.
      </p>
      <div class="vx-row mb-4 mt-4">
        <div class="vx-col w-full md:w-1/4 mb-2">
          <vs-input
            type="number"
            class="w-full"
            icon-pack="feather"
            icon="icon-user"
            icon-no-border
            :label="
              $t(resources.DistributorID.i18n) || resources.DistributorID.name
            "
            v-model="itaCodeForm"
          />
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2 mt-5">
          <vs-button
            color="primary"
            type="filled"
            @click="validateTransactions()"
          >{{ $t(resources.Search.i18n) || resources.Search.name }}</vs-button>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full lg:w-1/2 mb-base mt-6">
          <vx-card slot="no-body" class="text-center bg-primary-gradient greet-user">
            <img
              src="@/assets/images/elements/decore-left.png"
              class="decore-left"
              alt="Decore Left"
              width="150"
            />
            <feather-icon
              icon="AwardIcon"
              class="p-6 mb-8 bg-primary inline-flex rounded-full text-white shadow"
              svgClasses="h-8 w-8"
              v-if="total > 0"
            ></feather-icon>
            <vs-icon
              icon-pack="material-icons"
              icon="sentiment_very_dissatisfied"
              size="large"
              color="rgb(255,255,255)"
              v-else
            ></vs-icon>
            <h1 class="mb-4 text-white" v-if="total > 0">
              {{
              $t(resources.Congratulations.i18n) ||
              resources.Congratulations.name
              }}
              {{ itaCodeForm }},
            </h1>
            <h1 class="mb-6 text-white" v-else>{{ itaCodeForm }},</h1>
            <p class="xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto text-white">
              {{
              $t(alerts.MsgYouHaveEarned.i18n) || alerts.MsgYouHaveEarned.name
              }}
              <strong>USD ${{ total }}</strong>
              {{ $t(alerts.MsgTipAndFee.i18n) || alerts.MsgTipAndFee.name }}.
            </p>
            <p class="text-white" v-if="total < 2">
              {{
              $t(alerts.MsgNotEnoughMoney.i18n) ||
              alerts.MsgNotEnoughMoney.i18n
              }}
            </p>
          </vx-card>
          <div v-if="notTransactions">
            <h4 class="font-semibold text-center mt-6 text-danger">
              {{
              $t(alerts.MsgOneTransferAtDay.i18n) ||
              alerts.MsgOneTransferAtDay.name
              }}.
            </h4>
          </div>
          <!-- <div
            v-else-if="
              currentDate.getHours() >= 13 && currentDate.getDay() == 3
            "
          >
            <h4
              class="font-semibold text-center mt-6 text-danger"
            >{{ $t(alerts.MsgNotPayment.i18n) || alerts.MsgNotPayment.name }}.</h4>
          </div>-->
          <div v-else class="vx-col w-full mt-5 text-center">
            <vs-row v-if="country == 'COL'">
              <h6 class="mb-3 font-semibold text-center text-danger">
                {{
                $t(alerts.MsgAnotherCountry.i18n) ||
                alerts.MsgAnotherCountry.name
                }}.
              </h6>
              <div class="vx-col md:w-1/2 w-full mb-2 mb-6">
                <div class="vx-col w-full">
                  <vs-input
                    class="w-full"
                    icon-pack="feather"
                    icon="icon-hash"
                    icon-no-border
                    v-model="transactionNumber"
                    :placeholder="
                      $t(resources.TransactionNumber.i18n) ||
                        resources.TransactionNumber.name
                    "
                  />
                </div>
              </div>
              <!-- <div class="vx-col md:w-1/2 w-full mb-2 mb-6">
                <flat-pickr
                  :config="configdateTimePicker"
                  v-model="paymentDate"
                  :placeholder="
                    $t(resources.PaymentDate.i18n) || resources.PaymentDate.name
                  "
                />
              </div>-->
              <vs-button
                color="primary"
                size="large"
                type="filled"
                :disabled="!validateForm"
                @click="payColombia()"
              >
                {{
                $t(resources.Transfer.i18n) || resources.Transfer.name
                }}
              </vs-button>
            </vs-row>
            <vs-button
              v-else
              color="primary"
              size="large"
              type="filled"
              :disabled="!validateForm"
              @click="payPayout(3)"
            >
              {{
              $t(resources.Transfer.i18n) || resources.Transfer.name
              }}
            </vs-button>
          </div>
        </div>
        <div class="vx-col w-full lg:w-1/2 mb-base mt-6">
          <h4 class="font-semibold text-center mt-6">
            {{
            $t(resources.TipAndCustomizedFee.i18n) ||
            resources.TipAndCustomizedFee.name
            }}
          </h4>
          <vs-input
            type="number"
            class="w-full font-semibold mb-base"
            icon-pack="feather"
            icon="icon-dollar-sign"
            icon-no-border
            disabled
            :label="$t(resources.TipsTotal.i18n) || resources.TipsTotal.name"
            v-model="tipTotal"
          />
          <vs-input
            type="number"
            class="w-full font-semibold mb-base"
            icon-pack="feather"
            icon="icon-dollar-sign"
            icon-no-border
            disabled
            :label="$t(resources.FeeTotal.i18n) || resources.FeeTotal.name"
            v-model="feeTotal"
          />
          <vs-input
            type="number"
            class="w-full font-semibold mb-base"
            icon-pack="feather"
            icon="icon-dollar-sign"
            icon-no-border
            disabled
            :label="
              $t(resources.TotalPendingCharges.i18n) ||
                resources.TotalPendingCharges.name
            "
            v-model="pendingValue"
          />
        </div>
      </div>
    </vx-card>

    <vs-row vs-type="flex" vs-justify="flex-end">
      <!-- Descriptions -->
      <vs-button
        color="rgb(174,174,174)"
        type="filled"
        @click="$router.push('/commissions/DetailCustomized')"
        class="mt-2"
      >{{ $t(resources.Return.i18n) || resources.Return.name }}</vs-button>
    </vs-row>
  </div>
</template>
<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import axios from "axios";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";

export default {
  components: {},
  data() {
    return {
      // configdateTimePicker: {
      //   enableTime: true,
      //   dateFormat: "Y-m-d H:i"
      // },
      country: null,

      resources: resources,
      alerts: alerts,
      language: this.$i18n.locale,
      start_date: null,
      end_date: null,
      notTransactions: false,
      tipTotal: 0,
      feeTotal: 0,
      total: 0,
      pendingValue: 0,
      transactionNumber: null,
      paymentDate: new Date(),
      itaCodeForm: JSON.parse(localStorage.getItem("userInfo")).itaCode,
      username: JSON.parse(localStorage.getItem("userInfo")).name,
      email: JSON.parse(localStorage.getItem("userInfo")).email,
      tipFeeData: []
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.total >= 2;
    },
    currentDate() {
      var date = new Date().toLocaleString("en-US", {
        timeZone: "America/New_York"
      });
      var datetime = new Date(date);
      return datetime;
    }
  },

  methods: {
    async validateTransactions() {
      this.$vs.loading();

      await axios({
        method: "POST",
        url: process.env.VUE_APP_OVAPI + "getTipAndFee",
        data: {
          distid: this.itaCodeForm,
          state: "1",
          chargeType: "",
          start_date: new Date().toLocaleDateString("en-US"),
          end_date: new Date().toLocaleDateString("en-US")
        },
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("loggedIn")
        }
      }).then(
        result => {
          if (result.data.rowsAffected[0] != 0) {
            this.notTransactions = true;
            this.feeTotal = 0;
            this.tipTotal = 0;
            this.pendingValue = 0;
            this.total = 0;
          } else {
            this.notTransactions = false;
            this.getTipAndFee();
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async getTipAndFee() {
      this.$vs.loading();

      await axios({
        method: "POST",
        url: process.env.VUE_APP_OVAPI + "getTipAndFee",
        data: {
          distid: this.itaCodeForm,
          state: "0",
          chargeType: ""
        },
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("loggedIn")
        }
      }).then(
        result => {
          if (result.data.rowsAffected[0] != 0) {
            this.sumValues(result.data.recordsets[0]);
            this.tipFeeData = result.data.recordsets[0];
            this.country = result.data.recordsets[0][0].PaisOperacionIta;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    sumValues(data) {
      let fee = 0;
      let tip = 0;
      let pending = 0;

      if (this.country != "COL") {
        pending = -1;
      }

      for (var i = 0; i < data.length; i++) {
        //loop through the array
        if (data[i].TipoCargo === 1 && !data[i].EstaPago) tip += data[i].Valor;
        else if (data[i].TipoCargo === 2 && !data[i].EstaPago)
          fee += data[i].Valor;
        else if (data[i].TipoCargo === 3 && !data[i].EstaPago)
          tip += data[i].Valor;
        else if (data[i].TipoCargo === 4 && !data[i].EstaPago)
          fee += data[i].Valor;
        else if (data[i].TipoCargo === 5 && !data[i].EstaPago)
          pending += data[i].Valor;
      }
      this.feeTotal = fee.toFixed(2);
      this.tipTotal = tip.toFixed(2);
      this.pendingValue = pending.toFixed(2);
      this.total = (fee + tip + pending).toFixed(2);
    },

    async payPayout() {
      this.$vs.loading();

      let json = this.getJson();

      await axios({
        method: "POST",
        url: process.env.VUE_APP_OVAPI + "paymentTipAndFee",
        data: json,
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("loggedIn")
        }
      }).then(
        result => {
          if (result.data.m_Code == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text:
                this.$i18n.t("MsgPaymentMade") +
                ". <br>" +
                this.$i18n.t("MsgRefreshPage"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-check"
            });
            setTimeout(() => {
              this.$vs.loading.close();
              window.location.reload();
            }, 1000);
          } else if (result.data.m_Code == -2) {
            this.$vs.notify({
              time: 8000,
              title: this.$i18n.t("Alert"),
              text: this.$i18n
                .t("MsgWalletNotExist")
                .replace("{0}", this.itaCodeForm),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.$vs.loading.close();
          } else if (result.data.m_Code == -3) {
            this.$vs.notify({
              time: 8000,
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotPayment"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              time: 8000,
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgPaymentNotMade"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            setTimeout(() => {
              this.$vs.loading.close();
              window.location.reload();
            }, 1000);
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async payColombia() {
      var d = new Date(this.paymentDate);
      var date = d.toLocaleDateString("es-CO");
      var time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();

      if (
        this.transactionNumber == null ||
        this.transactionNumber == "" ||
        this.paymentDate == null ||
        this.paymentDate == ""
      ) {
        this.$vs.notify({
          title: this.$i18n.t("Alert"),
          text: this.$i18n.t("MsgRequiredField"),
          color: "warning",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-x-circle"
        });
      } else {
        this.$vs.loading();

        let idList = this.tipFeeData.map(function(obj) {
          return obj.Id;
        });

        await axios({
          method: "POST",
          url: process.env.VUE_APP_OVAPI + "paymentColombia",
          data: {
            TransactionNumber: this.transactionNumber,
            PaymentDate: date,
            PaymentTime: time,
            IdHistorico: idList,
            createdBy: this.email
          },
          headers: {
            "content-type": "application/json",
            Authorization: "bearer " + localStorage.getItem("loggedIn")
          }
        }).then(
          result => {
            if (result.status === 200) {
              this.$vs.notify({
                title: this.$i18n.t("Success"),
                text:
                  this.$i18n.t("MsgPaymentMade") +
                  ". <br>" +
                  this.$i18n.t("MsgRefreshPage"),
                color: "success",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-check"
              });
              this.$vs.loading.close();
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            } else {
              this.$vs.notify({
                time: 8000,
                title: this.$i18n.t("Alert"),
                text: this.$i18n.t("MsgPaymentNotMade"),
                color: "warning",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle"
              });
              this.$vs.loading.close();
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }
          },
          error => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError") + "<br/>" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        );
      }
    },

    getJson() {
      let array = [];
      let idList = this.tipFeeData.map(function(obj) {
        return obj.Id;
      });

      let bookingList1 = this.tipFeeData
        .filter(x => x.TipoCargo == 1 || x.TipoCargo == 3)
        .map(function(obj) {
          return obj.Localizador;
        });

      let bookingList2 = this.tipFeeData
        .filter(x => x.TipoCargo == 2 || x.TipoCargo == 4)
        .map(function(obj) {
          return obj.Localizador;
        });

      let transaction = {
        UserName: this.itaCodeForm,
        Amount: this.total,
        Comments:
          "Commissions type 1 and 3" +
          " - " +
          this.$i18n.t("BookingCode") +
          ": " +
          bookingList1.toString() +
          " ///// Commissions type 2 and 4 " +
          " - " +
          this.$i18n.t("BookingCode") +
          ": " +
          bookingList2.toString(),
        IdHistorico: idList
      };
      array.push(transaction);

      let json = { createdBy: this.email, data: array, applicationId: 1 };
      return json;
    }
  }
};
</script>

<style lang="scss">
#dashboard-analytics {
  .greet-user {
    position: relative;
    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: 576px) {
    .decore-left,
    .decore-right {
      width: 140px;
    }
  }
}
</style>
