<template>
  <div>
    <div id="ag-grid-demo" style="padding-top: 7px">
      <vx-card>
        <div class="flex flex-wrap justify-between items-center">
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{
                    currentPage * paginationPageSize - (paginationPageSize - 1)
                  }}
                  -
                  {{
                    dataList.length - currentPage * paginationPageSize > 0
                      ? currentPage * paginationPageSize
                      : dataList.length
                  }}
                  of {{ dataList.length }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <div
            class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
          >
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              :placeholder="$t(resources.Search.i18n) || resources.Search.name"
            />
            <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">
              {{ $t(resources.ExportAs.i18n) || resources.ExportAs.name }}
            </vs-button>
            <vs-button class="ml-1" color="success" @click="cleanFields(true)">
              {{
                $t(resources.Create.i18n) || resources.Create.name
              }}</vs-button
            >
          </div>
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="dataList"
          rowSelection="single"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
        ></ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>

    <!--Popup Create rate-->
    <div class="centerx">
      <vs-popup
        classContent="popup overFlow"
        :title="$t(resources.Create.i18n) || resources.Create.name"
        :active.sync="popupCreate"
      >
        <div class="vx-row">
          <div class="vx-col w-full md:w-1/2 mb-2">
            <vs-input
              type="text"
              class="w-full"
              icon-pack="feather"
              icon="icon-user"
              icon-no-border
              :label="$t(resources.Name.i18n) || resources.Name.name"
              v-model="name"
            />
          </div>
          <div class="vx-col w-full md:w-1/2 mb-2">
            <vs-input
              type="text"
              class="w-full"
              icon-pack="feather"
              icon="icon-map"
              icon-no-border
              :label="$t(resources.Address.i18n) || resources.Address.name"
              v-model="address"
            />
          </div>
          <div class="vx-col w-full md:w-1/2 mb-2">
            <vs-input
              type="text"
              class="w-full"
              icon-pack="feather"
              icon="icon-mail"
              icon-no-border
              :label="$t(resources.Email.i18n) || resources.Email.name"
              v-model="email"
            />
          </div>
          <div class="vx-col w-full md:w-1/2 mb-2">
            <vs-input
              type="text"
              class="w-full"
              icon-pack="feather"
              icon="icon-phone"
              icon-no-border
              :label="$t(resources.Telephone.i18n) || resources.Telephone.name"
              v-model="phone"
            />
          </div>
          <div class="vx-col md:w-1/4 w-full">
            <ul class="vx-col w-full mt-4">
              <li>
                <vs-checkbox v-model="active">{{
                  $t(resources.Active.i18n) || resources.Active.name
                }}</vs-checkbox>
              </li>
            </ul>
          </div>
        </div>

        <div class="vx-col mt-4">
          <vs-button color="primary" type="filled" @click="create()">
            {{ $t(resources.Save.i18n) || resources.Save.name }}
          </vs-button>
        </div>
      </vs-popup>
    </div>
  </div>
</template>
<script>
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import resources from "@/i18n/resources.js";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue
  },
  data() {
    return {
      resources: resources,
      dataList: [],
      name: "",
      address: "",
      email: "",
      phone: "",
      active: true,
      emailSession: JSON.parse(localStorage.getItem("userInfo")).email,
      NumberPage: 0,
      rows: 20,
      popupCreate: false,
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 10,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: this.$i18n.t("Name"),
          field: "name",
          filter: true,
          width: 220
        },
        {
          headerName: this.$i18n.t("Address"),
          field: "address",
          filter: true,
          width: 250
        },
        {
          headerName: this.$i18n.t("Email"),
          field: "email",
          filter: true,
          width: 250
        },
        {
          headerName: this.$i18n.t("Telephone"),
          field: "phone",
          filter: true,
          width: 180
        },
        {
          headerName: this.$i18n.t("Status"),
          field: "active",
          width: 110,
          cellRenderer: function(event) {
            let object = "";
            switch (event.node.data.active) {
              case false:
                object =
                  '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-danger), 1);">highlight_off</i>';
                break;
              case true:
                object =
                  '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-success), 1);">check_circle</i>';
                break;
              default:
                break;
            }
            return object;
          }
        }
      ]
    };
  },
  async created() {
    await this.get();
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("level", null);
      } else this.gridOptions.columnApi.setColumnPinned("level", "left");
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 20;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    }
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    cleanFields(openPopUp) {
      this.name = "";
      this.address = "";
      this.email = "";
      this.phone = "";
      this.active = true;
      if (openPopUp) this.popupCreate = true;
      else this.popupCreate = false;
    },

    async get() {
      this.$vs.loading();

      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Supplier`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data.length > 0) {
            this.dataList = result.data;
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.dataList = [];
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async create() {
      this.$vs.loading();

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Supplier`,
        data: {
          name: this.name,
          address: this.address,
          phone: this.phone,
          email: this.email,
          active: this.active,
          createdBy: this.emailSession,
          language: this.$i18n.locale.toUpperCase()
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (result.data.success) {
            this.cleanFields(false);
            await this.get();

            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotSaved"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.dataList = [];
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  }
};
</script>
