<template>
  <div>
    <vx-card
      :title="$t(resources.Filters.i18n) || resources.Filters.name"
      class="mb-4"
    >
      <!-- <vs-row vs-type="flex" vs-justify="center" class="mb-base mt-2">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
          <vs-button
            color="primary"
            type="filled"
            icon="add"
            @click="openAdd(false)"
            >{{ $t(resources.Add.i18n) || resources.Add.name }}</vs-button
          >
        </vs-col>
      </vs-row> -->
      <div class="vx-row">
        <div class="vx-col w-full md:w-1/6 mb-2">
          <label class="vs-input--label">
            {{ $t(resources.StartDate.i18n) || resources.StartDate.name }}
          </label>
          <datepicker
            :language="languages[language]"
            format="d MMMM yyyy"
            v-model="start_date"
          ></datepicker>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2">
          <label class="vs-input--label">{{
            $t(resources.EndDate.i18n) || resources.EndDate.name
          }}</label>
          <datepicker
            :language="languages[language]"
            format="d MMMM yyyy"
            v-model="end_date"
          ></datepicker>
        </div>
        <vs-button
          color="primary"
          type="filled"
          icon-pack="feather"
          icon="icon-refresh-ccw"
          class="mt-5"
          @click="cleanDate()"
        ></vs-button>
        <div class="vx-col w-full md:w-1/6 mb-2">
          <label class="vs-input--label">{{
            $t(resources.Type.i18n) || resources.Type.name
          }}</label>
          <v-select
            v-model="typeId"
            :clearable="false"
            :options="typesList"
            :reduce="x => x.id"
            label="name"
            scrollable
          />
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2">
          <label class="vs-input--label">{{
            $t(resources.Stage.i18n) || resources.Stage.name
          }}</label>
          <v-select
            v-model="stageId"
            :clearable="false"
            :options="stageList"
            :reduce="x => x.id"
            label="name"
            scrollable
          />
        </div>
        <!-- <div class="vx-col w-full md:w-1/6 mb-2">
          <vs-input
            class="w-full"
            icon-pack="feather"
            icon="icon-file-text"
            icon-no-border
            :label="$t(resources.Invoice.i18n) || resources.Invoice.name"
            v-model="invoice"
          />
        </div> -->
        <div class="vx-col w-full md:w-1/6 mb-2">
          <vs-input
            class="w-full"
            icon-pack="feather"
            icon="icon-hash"
            icon-no-border
            :label="
              $t(resources.TransactionNumber.i18n) ||
                resources.TransactionNumber.name
            "
            v-model="transactionNumber"
          />
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.Type.i18n) || resources.Type.name
            }}</label>
            <v-select
              v-model="creditCardTypeId"
              :clearable="true"
              :options="creditCardType"
              :reduce="item => item.id"
              label="name"
            />
          </div>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2">
          <vs-input
            type="number"
            class="w-full"
            icon-pack="feather"
            icon="icon-credit-card"
            icon-no-border
            :label="$t(resources.CardNumber.i18n) || resources.CardNumber.name"
            v-model="cardNumber"
          />
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2">
          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.BankName.i18n) || resources.BankName.name
            }}</label>
            <v-select
              v-model="bankId"
              :clearable="true"
              :options="banksList"
              :reduce="item => item.id"
              label="name"
            />
          </div>
        </div>
        <div class="vx-col md:w-1/6 w-full">
          <label class="vs-input--label">{{
            $t(resources.Merchant.i18n) || resources.Merchant.name
          }}</label>
          <v-select
            v-model="merchantId"
            :clearable="true"
            :options="merchantList"
            :reduce="data => data.merchantId"
            label="merchantName"
          />
        </div>
        <div class="vx-col w-full md:w-1/5 mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon-no-border
              label="Localizador u Orden Infotrax"
              v-validate="'alpha_dash'"
              v-model="code"
              name="bookingCode"
              maxlength="20"
            />
            <span class="text-danger text-sm">{{
              errors.first("bookingCode")
            }}</span>
          </div>
        </div>

        <div class="vx-col mb-2 mt-5">
          <vs-button color="primary" type="filled" @click="get()">{{
            $t(resources.Search.i18n) || resources.Search.name
          }}</vs-button>
        </div>
      </div>
    </vx-card>
    <div id="ag-grid-demo">
      <vx-card>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center">
          <!-- ITEMS PER PAGE -->
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{
                    currentPage * paginationPageSize - (paginationPageSize - 1)
                  }}
                  -
                  {{
                    affiliationsList.length - currentPage * paginationPageSize >
                    0
                      ? currentPage * paginationPageSize
                      : affiliationsList.length
                  }}
                  of {{ affiliationsList.length }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
              <vs-dropdown-menu>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <div
            class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
          >
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              :placeholder="$t(resources.Search.i18n) || resources.Search.name"
            />
            <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">
              {{ $t(resources.ExportAs.i18n) || resources.ExportAs.name }}
            </vs-button>
          </div>
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="affiliationsList"
          rowSelection="single"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          @row-selected="onRowSelected"
        ></ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
  </div>
</template>

<script>
import other from "@/assets/resources/enums/other.json";
import database from "@/assets/resources/enums/database.json";
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import vSelect from "vue-select";
import moment from "moment";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
    Datepicker,
    vSelect
  },
  data() {
    return {
      other: other,
      database: database,
      resources: resources,
      alerts: alerts,
      language: this.$i18n.locale,
      languages: lang,
      start_date: new Date(),
      end_date: new Date(),
      stageId: "",
      invoice: "",
      transactionNumber: "",
      cardNumber: "",
      stageList: [],
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 10,
      gridApi: null,
      rows: 20,
      NumberPage: 0,
      affiliationsList: [],
      bankId: "",
      banksList: [],
      merchantId: "",
      merchantList: [],
      code: "",
      typeId: 2,
      typesList: [],
      creditCardTypeId: "",
      creditCardType: [
        {"id": 1, "name": "Visa"},
        {"id": 2, "name": "MasterCard"},
        {"id": 3, "name": "Amex"},
        {"id": 4, "name": "Dinners"},
        {"id": 5, "name": "Discover"},
      ],
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: "Entity",
          field: "entityId",
          hide: true
        },
        {
          headerName: "Identifier",
          field: "identifier",
          hide: true
        },
        {
          headerName: "PurchaseType",
          field: "purchaseType",
          hide: true
        },
        {
          headerName: "IBO",
          field: "itaCode",
          hide: true
        },
        {
          headerName: "#",
          field: "id",
          filter: true,
          width: 110
        },
        {
          headerName: this.$i18n.t("Code"),
          field: "code",
          filter: true
        },
        {
          headerName: this.$i18n.t("Type"),
          field: "entityName",
          filter: true,
          width: 130
        },
        {
          headerName: this.$i18n.t("Status"),
          field: "stageName",
          filter: true,
          width: 130
        },
        {
          headerName: this.$i18n.t("Invoice"),
          field: "referenceId",
          filter: true
        },
        {
          headerName: this.$i18n.t("TransactionNumber"),
          field: "transaccionNumber",
          filter: true
        },
        {
          headerName: this.$i18n.t("Account"),
          field: "account",
          filter: true,
          width: 150
        },
        {
          headerName: this.$i18n.t("Value"),
          field: "value",
          filter: true,
          width: 150
        },
        {
          headerName: this.$i18n.t("Description"),
          field: "information",
          filter: true,
          width: 250
        },
        {
          headerName: this.$i18n.t("Name"),
          field: "creditCardName",
          filter: true
        },
        {
          headerName: this.$i18n.t("AccertifyPoints"),
          field: "points",
          filter: true
        },
        {
          headerName: this.$i18n.t("CreationDate"),
          field: "creationDate",
          filter: true
        },
        {
          headerName: this.$i18n.t("PaymentDate"),
          field: "paymentDate",
          filter: true
        },
                {
          headerName: "¿Es dispersión?",
          field: "isDispersion",
          filter: true
        },
        {
          headerName: "¿Cliente pagó?",
          field: "customerPaid",
          filter: true
        },
        {
          headerName: "Recibo caja",
          field: "cashReceipt",
          filter: true
        }
      ]
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("level", null);
      } else this.gridOptions.columnApi.setColumnPinned("level", "left");
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 50;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    }
  },
  async created() {
    await this.getStage();
    await this.getBanks();
    await this.getMerchantsApplication();
    await this.getPaymentType();
    await this.get();
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    cleanDate() {
      this.start_date = null;
      this.end_date = null;
    },

    async get() {
      let startDate = "";
      let endDate = "";

      if (this.start_date != null && this.end_date != null) {
        startDate = moment(this.start_date).format("YYYY-MM-DD");
        endDate = moment(this.end_date).format("YYYY-MM-DD");
      }

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_ACCOUNTING}payment/get`,
        data: {
          status: this.stageId,
          invoice: this.invoice,
          transactionNumber: this.transactionNumber,
          cardNumber: this.cardNumber,
          bankId: this.bankId,
          start_date: startDate,
          end_date: endDate,
          merchantId: this.merchantId,
          code: this.code,
          paymentTypeId: this.typeId
        },
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      }).then(
        result => {
          if (result.data.success && result.data.code == 204) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.affiliationsList = [];
          } else {
            this.affiliationsList = result.data.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getStage() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_OVAPI}Stage/${this.$i18n.locale}/2`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.stageList = [
              {
                id: "",
                name: this.$i18n.t("All")
              }
            ];
          } else {
            result.data.push({
              id: "",
              name: this.$i18n.t("All")
            });
            this.stageList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getBanks() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Bank/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.banksList = [];
          } else {
            this.banksList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getMerchantsApplication() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }ApplicationMerchant/GetByApplicationId/${this.$i18n.locale.toUpperCase()}/${
          this.database.application.FOC
        }`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) this.merchantList = result.data.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getPaymentType() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}PaymentType/${this.$i18n.locale}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data.success) {
            result.data.data.push({
              id: "",
              name: this.$i18n.t("All")
            });
            this.typesList = result.data.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    onRowSelected(event) {
      if (event.node.data.entityId == this.other.entity.booking) {
        this.$router.push({
          name: "booking-bookingdetail",
          params: {
            bookingId: event.node.data.identifier
          }
        });
      } else if (
        event.node.data.entityId == this.other.entity.purchase &&
        event.node.data.purchaseType == this.database.purchaseType.Membership
      ) {
        this.$router.push({
          name: "purchases-membership",
          params: {
            purchaseId: event.node.data.identifier
          }
        });
      } else if (
        event.node.data.entityId == this.other.entity.purchase &&
        (event.node.data.purchaseType ==
          this.database.purchaseType.MonthlyPayment ||
          event.node.data.purchaseType ==
            this.database.purchaseType.AutMonthlyPayment)
      ) {
        this.$router.push({
          name: "purchases-monthlyPayment",
          params: {
            purchaseId: event.node.data.identifier
          }
        });
      } else if (
        event.node.data.entityId == this.other.entity.purchase &&
        event.node.data.purchaseType == this.database.purchaseType.Upgrade
      ) {
        this.$router.push({
          name: "purchases-upgrade",
          params: {
            purchaseId: event.node.data.identifier
          }
        });
      } else if (
        event.node.data.entityId == this.other.entity.purchase &&
        event.node.data.purchaseType == this.database.purchaseType.Another
      ) {
        this.$router.push({
          name: "purchases-products",
          params: {
            purchaseId: event.node.data.identifier
          }
        });
      }
      else if (
        event.node.data.entityId == this.other.entity.lyra
      ) {
        window.open(`https://foca.visionbiz.net/order/detail/${event.node.data.identifier}/`);
      }
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  }
};
</script>
