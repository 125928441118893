var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { attrs: { id: "ag-grid-demo" } },
        [
          _c(
            "vx-card",
            {
              staticClass: "mb-2",
              attrs: {
                title:
                  _vm.$t(_vm.resources.Parameters.i18n) ||
                  _vm.resources.Parameters.name
              }
            },
            [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c(
                    "vs-table",
                    {
                      attrs: {
                        "max-items": "10",
                        pagination: "",
                        data: _vm.parameters,
                        search: ""
                      },
                      on: { search: _vm.handleSearch },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var data = ref.data
                            return _vm._l(data, function(tr, indextr) {
                              return _c(
                                "vs-tr",
                                { key: indextr },
                                [
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: {
                                        data: data[indextr].parameter.name
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(data[indextr].parameter.name)
                                      )
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: {
                                        data:
                                          data[indextr].parameter.description
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          data[indextr].parameter.description
                                        )
                                      )
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    { attrs: { data: data[indextr].value } },
                                    [_vm._v(_vm._s(data[indextr].value))]
                                  ),
                                  data[indextr].active
                                    ? _c(
                                        "vs-td",
                                        {
                                          attrs: { data: data[indextr].active }
                                        },
                                        [
                                          _c("feather-icon", {
                                            staticClass: "w-5 h-5 mr-1",
                                            attrs: {
                                              icon: "CheckIcon",
                                              svgClasses:
                                                "fill-current text-success"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _c(
                                        "vs-td",
                                        {
                                          attrs: { data: data[indextr].active }
                                        },
                                        [
                                          _c("feather-icon", {
                                            staticClass: "w-5 h-5 mr-1",
                                            attrs: {
                                              icon: "XIcon",
                                              svgClasses:
                                                "fill-current text-danger"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                  _c(
                                    "vs-td",
                                    [
                                      _c(
                                        "vx-tooltip",
                                        {
                                          attrs: {
                                            text:
                                              _vm.$t(_vm.resources.View.i18n) ||
                                              _vm.resources.View.name
                                          }
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.showPopUp(
                                                    data[indextr]
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("feather-icon", {
                                                staticClass: "w-5 h-5 mr-1",
                                                attrs: { icon: "EyeIcon" }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            })
                          }
                        }
                      ])
                    },
                    [
                      _c(
                        "template",
                        { slot: "thead" },
                        [
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Name.i18n) ||
                                  _vm.resources.Name.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Description.i18n) ||
                                  _vm.resources.Description.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Value.i18n) ||
                                  _vm.resources.Value.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Active.i18n) ||
                                  _vm.resources.Active.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Options.i18n) ||
                                  _vm.resources.Options.name
                              )
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title:
              _vm.$t(_vm.resources.Detail.i18n) || _vm.resources.Detail.name,
            active: _vm.popupActive
          },
          on: {
            "update:active": function($event) {
              _vm.popupActive = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vx-row mb-base" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full mb-2" },
              [
                _c("vs-textarea", {
                  staticClass: "vx-col w-full md:w-1/2 mb-4",
                  attrs: {
                    label:
                      _vm.$t(_vm.resources.Value.i18n) ||
                      _vm.t(_vm.resources.Value.name)
                  },
                  model: {
                    value: _vm.param.value,
                    callback: function($$v) {
                      _vm.$set(_vm.param, "value", $$v)
                    },
                    expression: "param.value"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full mb-2" },
              [
                _c(
                  "vs-switch",
                  {
                    attrs: { color: "success" },
                    model: {
                      value: _vm.paramActive,
                      callback: function($$v) {
                        _vm.paramActive = $$v
                      },
                      expression: "paramActive"
                    }
                  },
                  [
                    _c("span", { attrs: { slot: "on" }, slot: "on" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.Yes.i18n) ||
                            _vm.resources.Yes.name
                        )
                      )
                    ]),
                    _c("span", { attrs: { slot: "off" }, slot: "off" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.No.i18n) || _vm.resources.No.name
                        )
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/6 mb-2 mt-5" },
              [
                _c(
                  "vs-button",
                  {
                    attrs: { color: "primary", type: "filled" },
                    on: {
                      click: function($event) {
                        return _vm.update()
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(_vm.resources.Save.i18n) ||
                            _vm.resources.Save.name
                        ) +
                        " "
                    )
                  ]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }