<template>
  <div>
    <div id="ag-grid-demo">
      <vx-card>
        <h4 class="font-semibold mb-base">
          {{ $t(resources.Service.i18n) || resources.Service.name }}
        </h4>
        <div class="flex flex-wrap justify-between items-center">
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2">
                  {{
                    currentPage * paginationPageSize - (paginationPageSize - 1)
                  }}
                  -
                  {{
                    dataList.length - currentPage * paginationPageSize > 0
                      ? currentPage * paginationPageSize
                      : dataList.length
                  }}
                  of {{ dataList.length }}
                </span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
          <div
            class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
          >
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              :placeholder="$t(resources.Search.i18n) || resources.Search.name"
            />
            <vs-button
              class="mb-4 md:mb-0 mr-1"
              @click="gridApi.exportDataAsCsv()"
            >
              {{ $t(resources.ExportAs.i18n) || resources.ExportAs.name }}
            </vs-button>
            <vs-button
              class="mb-4 md:mb-0 mr-1"
              @click="popupCreate = true"
            >
              {{ $t(resources.Create.i18n) || resources.Create.name }}
            </vs-button>
          </div>
        </div>

        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="dataList"
          rowSelection="single"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          @row-clicked="onRowClicked"
        ></ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
    <!--Popup Update-->
    <div class="centerx">
      <vs-popup
        classContent="popup overFlow"
        :title="$t(resources.Update.i18n) || resources.Update.name"
        :active.sync="popupUpdate"
      >
        <div class="vx-row mb-base">
          <div class="vx-col w-full mb-2">
            <vs-input
              type="text"
              class="w-full"
              icon-pack="feather"
              icon="icon-user"
              :label="$t(resources.NameEn.i18n) || resources.NameEn.name"
              v-model="updateDto.nameEn"
            />
          </div>
          <div class="vx-col w-full mb-2">
            <vs-input
              type="text"
              class="w-full"
              icon-pack="feather"
              icon="icon-user"
              :label="$t(resources.NameEs.i18n) || resources.NameEs.name"
              v-model="updateDto.nameEs"
            />
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <ul class="vx-col w-full" style="margin-top: 20px">
              <li>
                <vs-checkbox v-model="updateDto.active">{{
                  $t(resources.Active.i18n) ||
                  resources.Active.name
                }}</vs-checkbox>
              </li>
            </ul>
          </div>
        </div>
        <div class="vx-col mb-2 mt-5" style="text-align: right">
          <vs-button color="primary" type="filled" @click="update()">
            {{ $t(resources.Save.i18n) || resources.Save.name }}
          </vs-button>
        </div>
      </vs-popup>
    </div>

    <!--Popup Create-->
    <div class="centerx">
      <vs-popup
        classContent="popup overFlow"
        :title="$t(resources.Create.i18n) || resources.Create.name"
        :active.sync="popupCreate"
      >
        <div class="vx-row mb-base">
          <div class="vx-col w-full mb-2">
            <vs-input
              type="text"
              class="w-full"
              icon-pack="feather"
              icon="icon-user"
              maxlength="100"
              :label="$t(resources.NameEn.i18n) || resources.NameEn.name"
              v-model="createDto.nameEn"
            />
          </div>
          <div class="vx-col w-full mb-2">
            <vs-input
              type="text"
              class="w-full"
              icon-pack="feather"
              icon="icon-user"
              maxlength="100"
              :label="$t(resources.NameEs.i18n) || resources.NameEs.name"
              v-model="createDto.nameEs"
            />
          </div>
        </div>
        <div class="vx-col mb-2 mt-5" style="text-align: right">
          <vs-button color="primary" type="filled" @click="create()">
            {{ $t(resources.Save.i18n) || resources.Save.name }}
          </vs-button>
        </div>
      </vs-popup>
    </div>
  </div>
</template>
<script>
import utilities from "@/assets/resources/utilities.json";
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import axios from "axios";
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
  },
  data() {
    return {
      alerts: alerts,
      resources: resources,
      utilities: utilities,
      popupUpdate: false,
      popupCreate: false,
      dataList: [],
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      updateDto: {},
      createDto:{},
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: "#",
          field: "id",
          filter: true,
        },
        {
          headerName: this.$i18n.t("NameEs"),
          field: "nameEs",
          filter: true,
          width: 300,
        },
        {
          headerName: this.$i18n.t("NameEn"),
          field: "nameEn",
          filter: true,
          width: 300,
        },
        {
          headerName: this.$i18n.t("Active"),
          field: "active",
          width: 110,
          cellRenderer: function (event) {
            let object = "";
            if (event.node.data.active) {
              object =
                '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-success), 1);">check_circle</i>';
            } else
              object =
                '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-danger), 1);">highlight_off</i>';

            return object;
          },
        },
      ],
    };
  },
  methods: {

    async get() {
      this.$vs.loading();
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Service`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (result.data) {
            this.dataList = result.data;
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.dataList = [];
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    async onRowClicked(event) {
      this.updateDto = event.node.data;
      this.popupUpdate = true;
    },
    async update() {
      this.updateDto.language = this.$i18n.locale.toUpperCase();
      this.$vs.loading();
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Service/Update`,
        data: this.updateDto,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        async (result) => {
          if (result.data.success) {
            await this.get();
            this.popupUpdate = false;

            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },
    async create() {
      this.$vs.loading();
      let contactInfo = JSON.parse(localStorage.getItem("userInfo"))
      this.createDto.language = this.$i18n.locale.toUpperCase();
      this.createDto.createdBy = contactInfo.email;
      this.createDto.active = true;

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Service`,
        data: this.createDto,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        async (result) => {
          if (result.data) {
            this.popupCreate = false;
            await this.get();

            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 20;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("level", null);
      } else this.gridOptions.columnApi.setColumnPinned("level", "left");
    },
  },
  async created() {
    await this.get();
  },
};
</script>
