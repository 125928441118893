export default {
  MsgError: {
    name: "Please,contact with the system administrator ",
    slug: "Error",
    i18n: "Error"
  },
  DontHaveCycles: {
    name: "You haven't made cycles",
    slug: "DontHaveCycles",
    i18n: "DontHaveCycles"
  },
  InformationEarnings: {
    name:
      "If you need to view more information about your earnings by period, please, select the table row",
    slug: "InformationEarnings",
    i18n: "InformationEarnings"
  },
  ColorsTableGenealogy: {
    name: 'The "Level" column has its values with colors, the meaning is:',
    slug: "ColorsTableGenealogy",
    i18n: "ColorsTableGenealogy"
  },
  MsgNotAllow10Rows: {
    name: "It only allows maximum 9 levels",
    slug: "MsgNotAllow10Rows",
    i18n: "MsgNotAllow10Rows"
  },
  MsgDistNotExist: {
    name: "The Distributor ID doesn't exist in your binary tree",
    slug: "MsgDistNotExist",
    i18n: "MsgDistNotExist"
  },
  MsgWelcome: {
    name: "Welcome back, please login to your account",
    slug: "MsgWelcome",
    i18n: "MsgWelcome"
  },
  MsgRecoverPassword: {
    name:
      "Please enter your email address and we'll send you a verification code to reset or update your password.",
    slug: "MsgRecoverPassword",
    i18n: "MsgRecoverPassword"
  },
  MsgNewPassword: {
    name: "Please enter your new password",
    slug: "MsgNewPassword",
    i18n: "MsgNewPassword"
  },
  MsgInvalidPassword: {
    name:
      "The password must have minimum 9 characters with uppercase, lowercase, numbers and special characters, else the button will not active",
    slug: "MsgInvalidPassword",
    i18n: "MsgInvalidPassword"
  },
  MsgNotBinaryTree: {
    name:
      "It has occurred a fail to recover the binary tree. Information isn't correct. Please, check with the administrator",
    slug: "MsgNotBinaryTree",
    i18n: "MsgNotBinaryTree"
  },
  MsgFromLevelLess: {
    name: 'The "{0}" field must be less than "{1}"',
    slug: "MsgFromLevelLess",
    i18n: "MsgFromLevelLess"
  },
  MsgStatusByPeriod: {
    name:
      '"You can search your qualification or another distributor of your downline in different periods"',
    slug: "MsgStatusByPeriod",
    i18n: "MsgStatusByPeriod"
  },
  MsgConsiderInformation: {
    name: "Please you consider for the next information:",
    slug: "MsgConsiderInformation",
    i18n: "MsgConsiderInformation"
  },
  MsgPayTransaction: {
    name: "USD $1 will be charged for each transfer requested.",
    slug: "MsgPayTransaction",
    i18n: "MsgPayTransaction"
  },
  MsgDaysToPayment: {
    name:
      "Transfers will be available on your ewallet the next Wednesday after transfer was requested at 16:00 ET or later.",
    slug: "MsgDaysToPayment",
    i18n: "MsgDaysToPayment"
  },
  MsgNotEnoughMoney: {
    name: "You must have least USD $2 to do transferr",
    slug: "MsgNotEnoughMoney",
    i18n: "MsgNotEnoughMoney"
  },
  MsgNotPayment: {
    name: "We are not processing transfers request at this time",
    slug: "MsgNotPayment",
    i18n: "MsgNotPayment"
  },
  MsgWalletNotExist: {
    name:
      "The user {0} doesn't exista in E-wallet. Please, you need to create an account to receive payments.",
    slug: "MsgWalletNotExist",
    i18n: "MsgWalletNotExist"
  },
  MsgYouHaveEarned: {
    name: "You have earned",
    slug: "MsgYouHaveEarned",
    i18n: "MsgYouHaveEarned"
  },
  MsgTipAndFee: {
    name: "at tips and customized fee",
    slug: "MsgTipAndFee",
    i18n: "MsgTipAndFee"
  },
  MsgPaymentMade: {
    name: "Payment have been made correctly",
    slug: "MsgTipAndFee",
    i18n: "MsgTipAndFee"
  },
  MsgPaymentNotMade: {
    name: "Payment haven't been made correctly",
    slug: "MsgTipAndFee",
    i18n: "MsgTipAndFee"
  },
  MsgStageBill: {
    name: "Only bookings are on billing will be paid",
    slug: "MsgStageBill",
    i18n: "MsgStageBill"
  },
  MsgTax: {
    name:
      'Taxes will be charged for each booking. View "Tax" column in the detail',
    slug: "MsgTax",
    i18n: "MsgTax"
  },
  MsgTotalPendingCharges: {
    name:
      "Will be charged to your transfer. You could there is received double payments or we didn't do the discount USD $1 por other transfers made",
    slug: "MsgTotalPendingCharges",
    i18n: "MsgTotalPendingCharges"
  },
  MsgWriteToCustomerService: {
    name:
      "If you have something question, you must write to servicioalcliente@visiontravel.net with your issue",
    slug: "MsgWriteToCustomerService",
    i18n: "MsgWriteToCustomerService"
  },
  MsgOneTransferAtDay: {
    name: "You only can make one transfer at day",
    slug: "MsgOneTransferAtDay",
    i18n: "MsgOneTransferAtDay"
  },
  MsgNotHistorical: {
    name: "Doesn't exist historical information",
    slug: "MsgNotHistorical",
    i18n: "MsgNotHistorical"
  },
  MsgAnotherCountry: {
    name:
      "You must write the transaction number and payment date because operation country of the ITA is Colombia",
    slug: "MsgAnotherCountry",
    i18n: "MsgAnotherCountry"
  },
  MsgCustomizeTheme: {
    name: "Customize and preview in real time",
    slug: "MsgCustomizeTheme",
    i18n: "MsgCustomizeTheme"
  },
  MsgAllRequiredField: {
    name: "All fields are required",
    slug: "MsgAllRequiredField",
    i18n: "MsgAllRequiredField"
  },
  MsgAcquireMembership: {
    name: "You want to acquire a {0} membership for {1} {2}",
    slug: "MsgAcquireMembership",
    i18n: "MsgAcquireMembership"
  },
  MsgAcquireMembership1: {
    name: "MsgAcquireMembership1",
    slug: "MsgAcquireMembership1",
    i18n: "MsgAcquireMembership1"
  },
  MsgAcquireMembership2: {
    name: "MsgAcquireMembership1",
    slug: "MsgAcquireMembership2",
    i18n: "MsgAcquireMembership2"
  },
  MsgPendingMembership: {
    name: "You owe {0} membership years",
    slug: "MsgPendingMembership",
    i18n: "MsgPendingMembership"
  },
  NewMember: {
    name: "New member",
    slug: "NewMember",
    i18n: "NewMeNewMembermbers"
  },
  MsgHaveCardInfo: {
    name: "Do you have the credit card information?",
    slug: "MsgHaveCardInfo",
    i18n: "MsgHaveCardInfo"
  },
  MsgFinishBooking: {
    name: "Would you like to finish the booking?",
    slug: "MsgFinishBooking",
    i18n: "MsgFinishBooking"
  },
  MsgManaged: {
    name: "This booking has been managed and finished",
    slug: "MsgManaged",
    i18n: "MsgManaged"
  },
  MsgOnlySaveCard: {
    name: "Would you only like to save the credit Card?",
    slug: "MsgOnlySaveCard",
    i18n: "MsgOnlySaveCard"
  },
  MsgManagedSOT: {
    name: "Will you manage booking by SOT?",
    slug: "MsgManagedSOT",
    i18n: "MsgManagedSOT"
  },
  MsgManagedBySOT: {
    name: "This booking has been managed by SOT",
    slug: "MsgManagedBySOT",
    i18n: "MsgManagedBySOT"
  },
  MsgInvest: {
    name:
      "Congratulations! You've done an investment on Block Invest. GOOD LUCK WITH THE EARNINGS!",
    slug: "MsgInvest",
    i18n: "MsgInvest"
  },
  MsgAccommodationInfo: {
    name:
    "It's very important to check the accommodation's capacibility that you've choosen with the configuration you'll save. Please, you compare with the following information:",
    slug: "MsgAccommodationInfo",
    i18n: "MsgAccommodationInfo"
  },
  MsgStartDateAfterEndDate: {
    name: "Start date must less than end date",
    slug: "MsgStartDateAfterEndDate",
    i18n: "MsgStartDateAfterEndDate"
  },
  MsgItineraryBeforeNow: {
    name: "Itinerary's dates must greater than now",
    slug: "MsgItineraryBeforeNow",
    i18n: "MsgItineraryBeforeNow"
  },
  MsgItineraryAfterArrival: {
    name: "Departure date must less than arrival date",
    slug: "MsgItineraryAfterArrival",
    i18n: "MsgItineraryAfterArrival"
  },
  MsgNotTransferEwallet: {
    name: "We are not processing transfers request to Ewallet at this time",
    slug: "MsgNotTransferEwallet",
    i18n: "MsgNotTransferEwallet"
  }
};
