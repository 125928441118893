var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "page-user-list" } },
    [
      _c(
        "vx-card",
        {
          staticClass: "mb-4",
          attrs: {
            title:
              _vm.$t(_vm.resources.Filters.i18n) ||
              _vm.t(_vm.resources.Filters.name)
          }
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 sm:w-1/2 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-mail",
                    "icon-no-border": "",
                    label:
                      _vm.$t(_vm.resources.Email.i18n) ||
                      _vm.resources.Email.name
                  },
                  model: {
                    value: _vm.email,
                    callback: function($$v) {
                      _vm.email = $$v
                    },
                    expression: "email"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/6 mb-2" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    type: "number",
                    "icon-pack": "feather",
                    icon: "icon-user",
                    "icon-no-border": "",
                    label:
                      _vm.$t(_vm.resources.DistributorID.i18n) ||
                      _vm.resources.DistributorID.name
                  },
                  model: {
                    value: _vm.itaCode,
                    callback: function($$v) {
                      _vm.itaCode = $$v
                    },
                    expression: "itaCode"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "vx-col w-full md:w-1/6 mb-2" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Type.i18n) ||
                          _vm.resources.Type.name
                      )
                    )
                  ]),
                  _c("v-select", {
                    attrs: {
                      clearable: true,
                      options: _vm.typeOption,
                      reduce: function(item) {
                        return item.id
                      },
                      label: "name"
                    },
                    model: {
                      value: _vm.type,
                      callback: function($$v) {
                        _vm.type = $$v
                      },
                      expression: "type"
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/6 mb-2" },
              [
                _c(
                  "vs-select",
                  {
                    staticClass: "vx-col w-full",
                    attrs: {
                      label:
                        "IBO - " +
                        (_vm.$t(_vm.resources.Status.i18n) ||
                          _vm.resources.Status.name)
                    },
                    model: {
                      value: _vm.iboStatus,
                      callback: function($$v) {
                        _vm.iboStatus = $$v
                      },
                      expression: "iboStatus"
                    }
                  },
                  _vm._l(_vm.iboStatusList, function(item, index) {
                    return _c("vs-select-item", {
                      key: index,
                      attrs: {
                        value: item.value,
                        text: _vm.$t(item.i18n) || item.text
                      }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/6 mb-2" },
              [
                _c(
                  "vs-select",
                  {
                    staticClass: "vx-col w-full",
                    attrs: {
                      label:
                        "IBO - " +
                        (_vm.$t(_vm.resources.IsRealtor.i18n) ||
                          _vm.resources.IsRealtor.name)
                    },
                    model: {
                      value: _vm.isRealtor,
                      callback: function($$v) {
                        _vm.isRealtor = $$v
                      },
                      expression: "isRealtor"
                    }
                  },
                  _vm._l(_vm.isRealtorList, function(item, index) {
                    return _c("vs-select-item", {
                      key: index,
                      attrs: {
                        value: item.value,
                        text: _vm.$t(item.i18n) || item.text
                      }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col mb-2 mt-5" },
              [
                _c(
                  "vs-button",
                  {
                    attrs: { color: "primary", type: "filled" },
                    on: {
                      click: function($event) {
                        return _vm.get()
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(_vm.resources.Search.i18n) ||
                            _vm.resources.Search.name
                        ) +
                        " "
                    )
                  ]
                )
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "div",
        { staticClass: "vx-card p-6" },
        [
          _c("div", { staticClass: "flex flex-wrap items-center" }, [
            _c(
              "div",
              { staticClass: "flex-grow" },
              [
                _c(
                  "vs-dropdown",
                  {
                    staticClass: "cursor-pointer",
                    attrs: { "vs-trigger-click": "" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                      },
                      [
                        _c("span", { staticClass: "mr-2" }, [
                          _vm._v(
                            _vm._s(
                              _vm.currentPage * _vm.paginationPageSize -
                                (_vm.paginationPageSize - 1)
                            ) +
                              " - " +
                              _vm._s(
                                _vm.usersData.length -
                                  _vm.currentPage * _vm.paginationPageSize >
                                  0
                                  ? _vm.currentPage * _vm.paginationPageSize
                                  : _vm.usersData.length
                              ) +
                              " of " +
                              _vm._s(_vm.usersData.length)
                          )
                        ]),
                        _c("feather-icon", {
                          attrs: {
                            icon: "ChevronDownIcon",
                            svgClasses: "h-4 w-4"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "vs-dropdown-menu",
                      [
                        _c(
                          "vs-dropdown-item",
                          {
                            on: {
                              click: function($event) {
                                return _vm.gridApi.paginationSetPageSize(10)
                              }
                            }
                          },
                          [_c("span", [_vm._v("10")])]
                        ),
                        _c(
                          "vs-dropdown-item",
                          {
                            on: {
                              click: function($event) {
                                return _vm.gridApi.paginationSetPageSize(20)
                              }
                            }
                          },
                          [_c("span", [_vm._v("20")])]
                        ),
                        _c(
                          "vs-dropdown-item",
                          {
                            on: {
                              click: function($event) {
                                return _vm.gridApi.paginationSetPageSize(25)
                              }
                            }
                          },
                          [_c("span", [_vm._v("25")])]
                        ),
                        _c(
                          "vs-dropdown-item",
                          {
                            on: {
                              click: function($event) {
                                return _vm.gridApi.paginationSetPageSize(30)
                              }
                            }
                          },
                          [_c("span", [_vm._v("30")])]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "flex flex-wrap items-center justify-between ag-grid-table-actions-right"
              },
              [
                _c("vs-input", {
                  staticClass: "mb-4 md:mb-0 mr-4",
                  attrs: {
                    placeholder:
                      _vm.$t(_vm.resources.Search.i18n) ||
                      _vm.resources.Search.name
                  },
                  on: { input: _vm.updateSearchQuery },
                  model: {
                    value: _vm.searchQuery,
                    callback: function($$v) {
                      _vm.searchQuery = $$v
                    },
                    expression: "searchQuery"
                  }
                }),
                _c(
                  "vs-button",
                  {
                    staticClass: "mb-4 md:mb-0 mr-1",
                    on: {
                      click: function($event) {
                        return _vm.gridApi.exportDataAsCsv()
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(_vm.resources.ExportAs.i18n) ||
                            _vm.resources.ExportAs.name
                        ) +
                        " "
                    )
                  ]
                )
              ],
              1
            )
          ]),
          _c("ag-grid-vue", {
            staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
            attrs: {
              gridOptions: _vm.gridOptions,
              columnDefs: _vm.columnDefs,
              defaultColDef: _vm.defaultColDef,
              rowData: _vm.usersData,
              rowSelection: "single",
              colResizeDefault: "shift",
              animateRows: true,
              floatingFilter: true,
              pagination: true,
              paginationPageSize: _vm.paginationPageSize,
              suppressPaginationPanel: true
            },
            on: { "row-selected": _vm.onRowSelected }
          }),
          _c("vs-pagination", {
            attrs: { total: _vm.totalPages, max: 7 },
            model: {
              value: _vm.currentPage,
              callback: function($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }