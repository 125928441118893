var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h-screen flex w-full bg-img" }, [
    _c(
      "div",
      {
        staticClass:
          "vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center"
      },
      [
        _c("vx-card", [
          _c(
            "div",
            {
              staticClass: "full-page-bg-color",
              attrs: { slot: "no-body" },
              slot: "no-body"
            },
            [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center"
                  },
                  [
                    _c("img", {
                      staticClass: "mx-auto",
                      attrs: {
                        src: require("@/assets/images/pages/forgot-password.png"),
                        alt: "login"
                      }
                    })
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "p-8" },
                      [
                        _c(
                          "div",
                          { staticClass: "text-right" },
                          [
                            _c(
                              "vs-dropdown",
                              {
                                staticClass: "cursor-pointer",
                                attrs: {
                                  "vs-custom-content": "",
                                  "vs-trigger-click": ""
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "cursor-pointer flex i18n-locale"
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "h-5 w-7",
                                      attrs: {
                                        src: require("@/assets/images/flags/" +
                                          _vm.$i18n.locale +
                                          ".png"),
                                        alt: _vm.$i18n.locale
                                      }
                                    })
                                  ]
                                ),
                                _c(
                                  "vs-dropdown-menu",
                                  {
                                    staticClass:
                                      "w-48 i18n-dropdown vx-navbar-dropdown"
                                  },
                                  [
                                    _c(
                                      "vs-dropdown-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.updateLocale("en")
                                          }
                                        }
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "h-4 w-5 mr-1",
                                          attrs: {
                                            src: require("@/assets/images/flags/en.png"),
                                            alt: "en"
                                          }
                                        }),
                                        _vm._v(" English ")
                                      ]
                                    ),
                                    _c(
                                      "vs-dropdown-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.updateLocale("es")
                                          }
                                        }
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "h-4 w-5 mr-1",
                                          attrs: {
                                            src: require("@/assets/images/flags/es.png"),
                                            alt: "es"
                                          }
                                        }),
                                        _vm._v(" Español ")
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("div", { staticClass: "vx-card__title mb-8" }, [
                          _c("h4", { staticClass: "mb-4" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(_vm.resources.RecoverPassword.i18n) ||
                                    _vm.resources.RecoverPassword.name
                                ) +
                                " "
                            )
                          ]),
                          _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(_vm.alerts.MsgRecoverPassword.i18n) ||
                                    _vm.alerts.MsgRecoverPassword.name
                                ) +
                                " "
                            )
                          ])
                        ]),
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|email|min:3",
                              expression: "'required|email|min:3'"
                            }
                          ],
                          staticClass: "w-full mb-8",
                          attrs: {
                            "data-vv-validate-on": "blur",
                            type: "email",
                            "label-placeholder":
                              _vm.$t(_vm.resources.Email.i18n) ||
                              _vm.resources.Email.name
                          },
                          model: {
                            value: _vm.email,
                            callback: function($$v) {
                              _vm.email = $$v
                            },
                            expression: "email"
                          }
                        }),
                        _c("span", { staticClass: "text-danger text-sm" }, [
                          _vm._v(" " + _vm._s(_vm.errors.first("email")) + " ")
                        ]),
                        _c(
                          "vs-button",
                          {
                            staticClass: "px-4 w-full md:w-auto",
                            attrs: { type: "border", to: "/pages/login" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(_vm.resources.Return.i18n) ||
                                    _vm.resources.Return.name
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "vs-button",
                          {
                            staticClass:
                              "float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-0",
                            attrs: { disabled: !_vm.validateForm },
                            on: { click: _vm.recover }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(_vm.resources.Recover.i18n) ||
                                    _vm.resources.Recover.name
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                )
              ])
            ]
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }