<template>
  <div>
    <vx-card :title="$t(resources.Filters.i18n) || resources.Filters.name" class="mb-4">
      <div class="vx-row">
        <div class="vx-col w-full md:w-1/5 mb-2">
          <label class="vs-input--label">
            {{
            $t(resources.StartDate.i18n) || resources.StartDate.name
            }}
          </label>
          <datepicker :language="languages[language]" format="d MMMM yyyy" v-model="start_date"></datepicker>
        </div>
        <div class="vx-col w-full md:w-1/5 mb-2">
          <label class="vs-input--label">{{ $t(resources.EndDate.i18n) || resources.EndDate.name }}</label>
          <datepicker :language="languages[language]" format="d MMMM yyyy" v-model="end_date"></datepicker>
        </div>
        <vs-button
          color="primary"
          type="filled"
          icon-pack="feather"
          icon="icon-refresh-ccw"
          class="mt-5"
          @click="cleanDate()"
        ></vs-button>
        <div class="vx-col w-full md:w-1/5 mb-2">
          <label class="vs-input--label">{{$t(resources.Type.i18n) || resources.Type.name}}</label>
          <v-select
            v-model="merchantTypeId"
            :clearable="false"
            :options="merchantTypeList"
            :reduce="x=> x.id"
            label="name"
            scrollable
          />
        </div>

        <div class="vx-col mb-2 mt-5">
          <vs-button
            color="primary"
            type="filled"
            @click="get()"
          >{{ $t(resources.Search.i18n) || resources.Search.name }}</vs-button>
        </div>
      </div>
    </vx-card>
    <div id="ag-grid-demo">
      <vx-card>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center">
          <!-- ITEMS PER PAGE -->
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span
                  class="mr-2"
                >{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ dataList.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : dataList.length }} of {{ dataList.length }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
              <vs-dropdown-menu>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              :placeholder="$t(resources.Search.i18n) || resources.Search.name"
            />
            <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">
              {{ $t(resources.ExportAs.i18n) || resources.ExportAs.name }}
            </vs-button>
          </div>
        </div>
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="dataList"
          rowSelection="single"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
        ></ag-grid-vue>
        <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" />
      </vx-card>
    </div>
  </div>
</template>

<script>
import other from "@/assets/resources/enums/other.json";
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import vSelect from "vue-select";
import moment from "moment";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
    Datepicker,
    vSelect,
  },
  data() {
    return {
      other: other,
      resources: resources,
      alerts: alerts,
      language: this.$i18n.locale,
      languages: lang,
      start_date: new Date(),
      end_date: new Date(),
      merchantTypeId: "",
      merchantTypeList: [],
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 10,
      gridApi: null,
      rows: 20,
      NumberPage: 0,
      dataList: [],
      bankId: "",
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: "#",
          field: "id",
          filter: true,
          width: 110,
        },
        {
          headerName: this.$i18n.t("Type"),
          field: "paymentType",
          filter: true,
          width: 120,
        },
        {
          headerName: this.$i18n.t("Code"),
          field: "productCode",
          filter: true,
        },
        {
          headerName: this.$i18n.t("Name"),
          field: "productName",
          filter: true,
        },
        {
          headerName: this.$i18n.t("Value"),
          field: "paidValue",
          filter: true,
          width: 150,
        },
        {
          headerName: this.$i18n.t("TransactionNumber"),
          field: "transaccionNumber",
          filter: true,
        },

        {
          headerName: this.$i18n.t("Account"),
          field: "account",
          filter: true,
          width: 150,
        },
        {
          headerName: this.$i18n.t("Description"),
          field: "information",
          filter: true,
          width: 250,
        },
        {
          headerName: this.$i18n.t("Invoice"),
          field: "referenceId",
          filter: true,
        },
        {
          headerName: this.$i18n.t("PaymentDate"),
          field: "paymentDate",
          filter: true,
        },
        {
          headerName: this.$i18n.t("CreationDate"),
          field: "creationDate",
          filter: true,
        },
      ],
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("level", null);
      } else this.gridOptions.columnApi.setColumnPinned("level", "left");
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 50;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  created: function () {
    this.getMerchantType();
    this.get();
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    cleanDate() {
      this.start_date = null;
      this.end_date = null;
    },

    async get() {
      let startDate = "";
      let endDate = "";

      if (this.start_date != null && this.end_date != null) {
        startDate = moment(this.start_date).format("YYYY-MM-DD");
        endDate = moment(this.end_date).format("YYYY-MM-DD");
      }

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_ACCOUNTING}payment/getCashRegister`,
        data: {
          merchantTypeId: this.merchantTypeId,
          start_date: startDate,
          end_date: endDate,
        },
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale,
        },
      }).then(
        (result) => {
          if (result.data.success && result.data.code == 204) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.dataList = [];
          } else {
            this.dataList = result.data.data;
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    async getMerchantType() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_ACCOUNTING}merchantType/get`,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale,
        },
      }).then(
        (result) => {
          if (result.data.success && result.data.code == 204) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.merchantTypeList = [
              {
                id: "",
                name: this.$i18n.t("All"),
              },
            ];
          } else {
            result.data.data.push({
              id: "",
              name: this.$i18n.t("All"),
            });
            this.merchantTypeList = result.data.data;
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
};
</script>
