var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "form",
        { attrs: { "data-vv-scope": "form-1" } },
        [
          _c(
            "vx-card",
            {
              staticClass: "mb-4",
              attrs: {
                title:
                  _vm.$t(_vm.resources.Create.i18n) || _vm.resources.Create.name
              }
            },
            [
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col md:w-1/2 w-full mb-4" }, [
                  _c("ul", { staticClass: "centerx" }, [
                    _c(
                      "li",
                      [
                        _c(
                          "vs-checkbox",
                          {
                            on: {
                              change: function($event) {
                                return _vm.getCustomers(1)
                              }
                            },
                            model: {
                              value: _vm.isBusiness,
                              callback: function($$v) {
                                _vm.isBusiness = $$v
                              },
                              expression: "isBusiness"
                            }
                          },
                          [_vm._v("¿Es Corporativo?")]
                        )
                      ],
                      1
                    )
                  ])
                ])
              ]),
              _c("div", { staticClass: "vx-row" }, [
                !_vm.isBusiness
                  ? _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col w-full" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.DistributorID.i18n) ||
                                  _vm.resources.DistributorID.name
                              )
                            )
                          ]),
                          _c("v-select", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            attrs: {
                              id: "iboCode",
                              clearable: true,
                              options: _vm.iboList,
                              reduce: function(item) {
                                return item.itaCode
                              },
                              label: "fullName",
                              name: "iboCode"
                            },
                            on: {
                              input: function($event) {
                                return _vm.getCustomers("" + _vm.iboCode)
                              }
                            },
                            nativeOn: {
                              keyup: function($event) {
                                return _vm.getIboByText()
                              }
                            },
                            model: {
                              value: _vm.iboCode,
                              callback: function($$v) {
                                _vm.iboCode = $$v
                              },
                              expression: "iboCode"
                            }
                          })
                        ],
                        1
                      ),
                      _c("span", { staticClass: "text-danger text-sm" }, [
                        _vm._v(_vm._s(_vm.errors.first("form-1.iboCode")))
                      ])
                    ])
                  : _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-4" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col w-full" },
                        [
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: {
                              "icon-pack": "feather",
                              icon: "icon-user",
                              disabled: "",
                              label:
                                _vm.$t(_vm.resources.DistributorID.i18n) ||
                                _vm.resources.DistributorID.name,
                              value: "0001 - Corporativo GLOBAL VISION"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full" },
                  [
                    _c(
                      "vx-tooltip",
                      {
                        attrs: {
                          text:
                            _vm.$t(_vm.resources.Create.i18n) ||
                            _vm.resources.Create.name,
                          position: "top"
                        }
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "vs-input--label" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Customer.i18n) ||
                                  _vm.resources.Customer.name
                              ) + " | "
                            ),
                            _c("feather-icon", {
                              staticClass: "success",
                              attrs: {
                                icon: "UserPlusIcon",
                                svgClasses: "h-6 w-6"
                              },
                              on: {
                                click: function($event) {
                                  _vm.popupContact = true
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c("v-select", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      attrs: {
                        clearable: false,
                        options: _vm.customers,
                        reduce: function(customer) {
                          return customer.id
                        },
                        label: "fullName",
                        hint: "" + _vm.booking.contactId,
                        name: "customer"
                      },
                      on: {
                        input: function($event) {
                          return _vm.getContact("" + _vm.booking.contactId)
                        }
                      },
                      model: {
                        value: _vm.booking.contactId,
                        callback: function($$v) {
                          _vm.$set(_vm.booking, "contactId", $$v)
                        },
                        expression: "booking.contactId"
                      }
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(_vm._s(_vm.errors.first("form-1.customer")))
                    ])
                  ],
                  1
                ),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-4" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-mail",
                          disabled: "",
                          label:
                            (_vm.$t(_vm.resources.Email.i18n) ||
                              _vm.resources.Email.name) +
                            " - " +
                            (_vm.$t(_vm.resources.Contact.i18n) ||
                              _vm.resources.Contact.name)
                        },
                        model: {
                          value: _vm.emailUser,
                          callback: function($$v) {
                            _vm.emailUser = $$v
                          },
                          expression: "emailUser"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "vx-col w-full md:w-1/4 mb-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.CountryOperation.i18n) ||
                            _vm.resources.CountryOperation.name
                        )
                      )
                    ]),
                    _c("v-select", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      attrs: {
                        clearable: false,
                        options: _vm.countryOption,
                        reduce: function(data) {
                          return data.countryCode
                        },
                        label: "display",
                        scrollable: "",
                        name: "country"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "option",
                          fn: function(option) {
                            return [
                              _c("img", {
                                staticClass: "h-6 w-6",
                                attrs: { src: option.iconUrl }
                              }),
                              _vm._v(" " + _vm._s(option.display) + " ")
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.countryOperation,
                        callback: function($$v) {
                          _vm.countryOperation = $$v
                        },
                        expression: "countryOperation"
                      }
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(_vm._s(_vm.errors.first("form-1.country")))
                    ])
                  ],
                  1
                )
              ])
            ]
          ),
          _c(
            "vx-card",
            {
              staticClass: "mb-4",
              attrs: {
                title:
                  _vm.$t(_vm.resources.ContactFormTitle.i18n) ||
                  _vm.resources.ContactFormTitle.name
              }
            },
            [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|min:3|alpha_spaces",
                          expression: "'required|min:3|alpha_spaces'"
                        }
                      ],
                      staticClass: "w-full mb-1",
                      attrs: {
                        label:
                          _vm.$t(_vm.resources.ContactName.i18n) ||
                          _vm.resources.ContactName.name,
                        name: "emergencyName",
                        "icon-pack": "feather",
                        icon: "icon-user",
                        maxlength: "100"
                      },
                      model: {
                        value: _vm.booking.emergencyName,
                        callback: function($$v) {
                          _vm.$set(_vm.booking, "emergencyName", $$v)
                        },
                        expression: "booking.emergencyName"
                      }
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(_vm._s(_vm.errors.first("form-1.emergencyName")))
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|email",
                          expression: "'required|email'"
                        }
                      ],
                      staticClass: "w-full mb-1",
                      attrs: {
                        label:
                          _vm.$t(_vm.resources.Email.i18n) ||
                          _vm.resources.Email.name,
                        name: "emailVoucher",
                        "icon-pack": "feather",
                        icon: "icon-mail",
                        maxlength: "150"
                      },
                      model: {
                        value: _vm.booking.emailVoucher,
                        callback: function($$v) {
                          _vm.$set(_vm.booking, "emailVoucher", $$v)
                        },
                        expression: "booking.emailVoucher"
                      }
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(_vm._s(_vm.errors.first("form-1.emailVoucher")))
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/6 w-full" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.TelephoneType.i18n) ||
                            _vm.resources.TelephoneType.name
                        )
                      )
                    ]),
                    _c("v-select", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      attrs: {
                        clearable: false,
                        options: _vm.phoneTypes,
                        reduce: function(x) {
                          return x.id
                        },
                        label: "type",
                        name: "phoneType"
                      },
                      model: {
                        value: _vm.booking.emergencyPhoneType,
                        callback: function($$v) {
                          _vm.$set(_vm.booking, "emergencyPhoneType", $$v)
                        },
                        expression: "booking.emergencyPhoneType"
                      }
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(_vm._s(_vm.errors.first("form-1.phoneTypes")))
                    ]),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(_vm._s(_vm.errors.first("form-1.phoneType")))
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/6 w-full" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.Indicative.i18n) ||
                            _vm.resources.Indicative.name
                        )
                      )
                    ]),
                    _c("v-select", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      attrs: {
                        clearable: false,
                        options: _vm.countryList,
                        reduce: function(country) {
                          return country.id
                        },
                        label: "country",
                        name: "emergencyCountryCode"
                      },
                      model: {
                        value: _vm.booking.emergencyCountryCode,
                        callback: function($$v) {
                          _vm.$set(_vm.booking, "emergencyCountryCode", $$v)
                        },
                        expression: "booking.emergencyCountryCode"
                      }
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(
                        _vm._s(_vm.errors.first("form-1.emergencyCountryCode"))
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/6 w-full" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|min:8",
                          expression: "'required|min:8'"
                        }
                      ],
                      staticClass: "w-full",
                      attrs: {
                        label:
                          _vm.$t(_vm.resources.Number.i18n) ||
                          _vm.resources.Number.name,
                        name: "emergencyPhone",
                        "icon-pack": "feather",
                        icon: "icon-hash",
                        maxlength: "20"
                      },
                      model: {
                        value: _vm.booking.emergencyPhone,
                        callback: function($$v) {
                          _vm.$set(_vm.booking, "emergencyPhone", $$v)
                        },
                        expression: "booking.emergencyPhone"
                      }
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(_vm._s(_vm.errors.first("form-1.emergencyPhone")))
                    ])
                  ],
                  1
                )
              ])
            ]
          ),
          _c(
            "vx-card",
            {
              staticClass: "mb-4",
              attrs: {
                title:
                  _vm.$t(_vm.resources.BookingDetail.i18n) ||
                  _vm.resources.BookingDetail.name
              }
            },
            [
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col md:w-1/2 w-full mb-4" }, [
                  _c("ul", { staticClass: "centerx" }, [
                    _c(
                      "li",
                      [
                        _c(
                          "vs-checkbox",
                          {
                            on: {
                              change: function($event) {
                                return _vm.getSupportTeam()
                              }
                            },
                            model: {
                              value: _vm.isSupportTeam,
                              callback: function($$v) {
                                _vm.isSupportTeam = $$v
                              },
                              expression: "isSupportTeam"
                            }
                          },
                          [_vm._v("¿El incentivo es para Team de apoyo?")]
                        )
                      ],
                      1
                    )
                  ])
                ])
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _vm.isSupportTeam
                  ? _c(
                      "div",
                      { staticClass: "vx-col w-full md:w-1/4 mb-2" },
                      [
                        _c("label", { staticClass: "vs-input--label" }, [
                          _vm._v("Asesor inicial")
                        ]),
                        _c("v-select", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            }
                          ],
                          attrs: {
                            clearable: false,
                            options: _vm.teamList,
                            reduce: function(data) {
                              return data.email
                            },
                            label: "fullName",
                            scrollable: "",
                            name: "supportTeam"
                          },
                          model: {
                            value: _vm.emailTeam,
                            callback: function($$v) {
                              _vm.emailTeam = $$v
                            },
                            expression: "emailTeam"
                          }
                        }),
                        _c("span", { staticClass: "text-danger text-sm" }, [
                          _vm._v(_vm._s(_vm.errors.first("form-1.supportTeam")))
                        ])
                      ],
                      1
                    )
                  : _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-4" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col w-full" },
                        [
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: {
                              "icon-pack": "feather",
                              icon: "icon-mail",
                              readonly: "",
                              label:
                                _vm.$t(_vm.resources.CreatedBy.i18n) ||
                                _vm.resources.CreatedBy.name
                            },
                            model: {
                              value: _vm.emailSession,
                              callback: function($$v) {
                                _vm.emailSession = $$v
                              },
                              expression: "emailSession"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.ProductType.i18n) ||
                            _vm.resources.ProductType.name
                        )
                      )
                    ]),
                    _c("v-select", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      attrs: {
                        clearable: true,
                        options: _vm.productType,
                        reduce: function(product) {
                          return product.id
                        },
                        label: "name",
                        hint: "" + _vm.bookingDetail.productId,
                        name: "product"
                      },
                      on: {
                        input: function($event) {
                          return _vm.getItineraryByProduct(
                            "" + _vm.bookingDetail.productId
                          )
                        }
                      },
                      model: {
                        value: _vm.bookingDetail.productId,
                        callback: function($$v) {
                          _vm.$set(_vm.bookingDetail, "productId", $$v)
                        },
                        expression: "bookingDetail.productId"
                      }
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(_vm._s(_vm.errors.first("form-1.product")))
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.Currency.i18n) ||
                            _vm.resources.Currency.name
                        )
                      )
                    ]),
                    _c("v-select", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      attrs: {
                        clearable: true,
                        options: _vm.currencyType,
                        reduce: function(x) {
                          return x.code
                        },
                        label: "display",
                        name: "currency"
                      },
                      on: {
                        input: function($event) {
                          return _vm.setRules()
                        }
                      },
                      model: {
                        value: _vm.bookingDetail.currencyCode,
                        callback: function($$v) {
                          _vm.$set(_vm.bookingDetail, "currencyCode", $$v)
                        },
                        expression: "bookingDetail.currencyCode"
                      }
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(_vm._s(_vm.errors.first("form-1.currency")))
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.Source.i18n) ||
                            _vm.resources.Source.name
                        )
                      )
                    ]),
                    _c("v-select", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      attrs: {
                        clearable: true,
                        options: _vm.sourceOption,
                        reduce: function(item) {
                          return item.id
                        },
                        label: "name",
                        name: "source"
                      },
                      model: {
                        value: _vm.bookingDetail.bookingSourceId,
                        callback: function($$v) {
                          _vm.$set(_vm.bookingDetail, "bookingSourceId", $$v)
                        },
                        expression: "bookingDetail.bookingSourceId"
                      }
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(_vm._s(_vm.errors.first("form-1.source")))
                    ])
                  ],
                  1
                ),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-4" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|min:3|max:20",
                            expression: "'required|min:3|max:20'"
                          }
                        ],
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-hash",
                          label:
                            _vm.$t(_vm.resources.BookingCode.i18n) ||
                            _vm.resources.BookingCode.name,
                          name: "code"
                        },
                        on: {
                          blur: function($event) {
                            _vm.bookingDetail.bookingReference = _vm.bookingDetail.bookingReference.replace(
                              /\s+/g,
                              ""
                            )
                          }
                        },
                        model: {
                          value: _vm.bookingDetail.bookingReference,
                          callback: function($$v) {
                            _vm.$set(_vm.bookingDetail, "bookingReference", $$v)
                          },
                          expression: "bookingDetail.bookingReference"
                        }
                      })
                    ],
                    1
                  ),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(_vm._s(_vm.errors.first("form-1.code")))
                  ])
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-4" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "" + _vm.rules,
                            expression: "`${rules}`"
                          }
                        ],
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-dollar-sign",
                          type: "number",
                          label:
                            _vm.$t(_vm.resources.NetValue.i18n) ||
                            _vm.resources.NetValue.name,
                          name: "netValue"
                        },
                        on: {
                          keyup: function($event) {
                            return _vm.sumTotal()
                          }
                        },
                        model: {
                          value: _vm.bookingDetail.value,
                          callback: function($$v) {
                            _vm.$set(_vm.bookingDetail, "value", $$v)
                          },
                          expression: "bookingDetail.value"
                        }
                      })
                    ],
                    1
                  ),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(_vm._s(_vm.errors.first("form-1.netValue")))
                  ])
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-4" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "" + _vm.rules,
                            expression: "`${rules}`"
                          }
                        ],
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-dollar-sign",
                          type: "number",
                          label:
                            _vm.$t(_vm.resources.TaxesValue.i18n) ||
                            _vm.resources.TaxesValue.name,
                          name: "taxValue"
                        },
                        on: {
                          keyup: function($event) {
                            return _vm.sumTotal()
                          }
                        },
                        model: {
                          value: _vm.bookingDetail.tax,
                          callback: function($$v) {
                            _vm.$set(_vm.bookingDetail, "tax", $$v)
                          },
                          expression: "bookingDetail.tax"
                        }
                      })
                    ],
                    1
                  ),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(_vm._s(_vm.errors.first("form-1.taxValue")))
                  ])
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-4" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "" + _vm.rules,
                            expression: "`${rules}`"
                          }
                        ],
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-dollar-sign",
                          type: "number",
                          label:
                            _vm.$t(_vm.resources.FeeValue.i18n) ||
                            _vm.resources.FeeValue.name,
                          name: "feeValue"
                        },
                        on: {
                          keyup: function($event) {
                            return _vm.sumTotal()
                          }
                        },
                        model: {
                          value: _vm.bookingDetail.fee,
                          callback: function($$v) {
                            _vm.$set(_vm.bookingDetail, "fee", $$v)
                          },
                          expression: "bookingDetail.fee"
                        }
                      })
                    ],
                    1
                  ),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(_vm._s(_vm.errors.first("form-1.feeValue")))
                  ])
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-4" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "" + _vm.rules,
                            expression: "`${rules}`"
                          }
                        ],
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          type: "number",
                          icon: "icon-dollar-sign",
                          label:
                            _vm.$t(_vm.resources.MerchantValue.i18n) ||
                            _vm.resources.MerchantValue.name,
                          name: "merchantValue"
                        },
                        on: {
                          keyup: function($event) {
                            return _vm.sumTotal()
                          }
                        },
                        model: {
                          value: _vm.bookingDetail.merchant,
                          callback: function($$v) {
                            _vm.$set(_vm.bookingDetail, "merchant", $$v)
                          },
                          expression: "bookingDetail.merchant"
                        }
                      })
                    ],
                    1
                  ),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(_vm._s(_vm.errors.first("form-1.merchantValue")))
                  ])
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-4" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-dollar-sign",
                          "icon-no-border": "",
                          type: "number",
                          label:
                            _vm.$t(_vm.resources.AgentCommissionValue.i18n) ||
                            _vm.resources.AgentCommissionValue.name
                        },
                        on: {
                          change: function($event) {
                            return _vm.sumTotal()
                          }
                        },
                        model: {
                          value: _vm.bookingDetail.agentCommission,
                          callback: function($$v) {
                            _vm.$set(_vm.bookingDetail, "agentCommission", $$v)
                          },
                          expression: "bookingDetail.agentCommission"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-4" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-dollar-sign",
                          disabled: "",
                          label:
                            _vm.$t(_vm.resources.BookingValue.i18n) ||
                            _vm.resources.BookingValue.name
                        },
                        model: {
                          value: _vm.bookingDetail.total,
                          callback: function($$v) {
                            _vm.$set(_vm.bookingDetail, "total", $$v)
                          },
                          expression: "bookingDetail.total"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.DiscountIn.i18n) ||
                            _vm.resources.DiscountIn.name
                        )
                      )
                    ]),
                    _c("v-select", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      attrs: {
                        clearable: false,
                        options: _vm.discountInOption,
                        reduce: function(item) {
                          return item.value
                        },
                        label: "text",
                        name: "discountIn"
                      },
                      model: {
                        value: _vm.bookingDetail.discountIn,
                        callback: function($$v) {
                          _vm.$set(_vm.bookingDetail, "discountIn", $$v)
                        },
                        expression: "bookingDetail.discountIn"
                      }
                    }),
                    _c("span", { staticClass: "text-danger text-sm" }, [
                      _vm._v(_vm._s(_vm.errors.first("form-1.discountIn")))
                    ])
                  ],
                  1
                ),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-4" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c(
                        "label",
                        { staticClass: "vs-input--label", attrs: { for: "" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.DiscountValue.i18n) ||
                                _vm.resources.DiscountValue.name
                            ) + " "
                          )
                        ]
                      ),
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "" + _vm.rules,
                            expression: "`${rules}`"
                          }
                        ],
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-dollar-sign",
                          "icon-no-border": "",
                          type: "number",
                          name: "discount"
                        },
                        on: {
                          change: function($event) {
                            return _vm.sumTotal()
                          }
                        },
                        model: {
                          value: _vm.bookingDetail.discount,
                          callback: function($$v) {
                            _vm.$set(_vm.bookingDetail, "discount", $$v)
                          },
                          expression: "bookingDetail.discount"
                        }
                      })
                    ],
                    1
                  ),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(_vm._s(_vm.errors.first("form-1.discount")))
                  ])
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-4" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "" + _vm.rules,
                            expression: "`${rules}`"
                          }
                        ],
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-dollar-sign",
                          label:
                            _vm.$t(_vm.resources.FeeCustomized.i18n) ||
                            _vm.resources.FeeCustomized.name,
                          name: "feeCustomized"
                        },
                        on: {
                          change: function($event) {
                            return _vm.sumTotal()
                          }
                        },
                        model: {
                          value: _vm.bookingDetail.feeCustomized,
                          callback: function($$v) {
                            _vm.$set(_vm.bookingDetail, "feeCustomized", $$v)
                          },
                          expression: "bookingDetail.feeCustomized"
                        }
                      })
                    ],
                    1
                  ),
                  _c("span", { staticClass: "text-danger text-sm" }, [
                    _vm._v(_vm._s(_vm.errors.first("form-1.feeCustomized")))
                  ])
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-4" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-dollar-sign",
                          disabled: "",
                          label:
                            _vm.$t(_vm.resources.TotalValue.i18n) ||
                            _vm.resources.TotalValue.name,
                          value: _vm.bookingDetail.totalComplete
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "vx-card",
        { staticClass: "mb-4" },
        [
          _c(
            "vs-tabs",
            [
              _c(
                "vs-tab",
                {
                  attrs: {
                    label:
                      _vm.$t(_vm.resources.Passengers.i18n) ||
                      _vm.resources.Passengers.name
                  }
                },
                [
                  _c(
                    "form",
                    {
                      staticClass: "vx-row",
                      attrs: { "data-vv-scope": "form-2" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.DocumentType.i18n) ||
                                  _vm.resources.DocumentType.name
                              )
                            )
                          ]),
                          _c("v-select", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            attrs: {
                              name: "passengerDocumentType",
                              label: "name",
                              clearable: false,
                              options: _vm.identifiacionTypeList,
                              reduce: function(item) {
                                return item.id
                              }
                            },
                            model: {
                              value: _vm.passengerModel.documentType,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.passengerModel,
                                  "documentType",
                                  $$v
                                )
                              },
                              expression: "passengerModel.documentType"
                            }
                          }),
                          _c("span", { staticClass: "text-danger text-sm" }, [
                            _vm._v(
                              _vm._s(
                                _vm.errors.first("form-2.passengerDocumentType")
                              )
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full" },
                        [
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|alpha_num",
                                expression: "'required|alpha_num'"
                              }
                            ],
                            staticClass: "w-full",
                            attrs: {
                              name: "passengerDocumentNumber",
                              label:
                                _vm.$t(_vm.resources.DocumentNumber.i18n) ||
                                _vm.resources.DocumentNumber.name,
                              "icon-pack": "feather",
                              icon: "icon-hash",
                              maxlength: "30"
                            },
                            model: {
                              value: _vm.passengerModel.document,
                              callback: function($$v) {
                                _vm.$set(_vm.passengerModel, "document", $$v)
                              },
                              expression: "passengerModel.document"
                            }
                          }),
                          _c("span", { staticClass: "text-danger text-sm" }, [
                            _vm._v(
                              _vm._s(
                                _vm.errors.first(
                                  "form-2.passengerDocumentNumber"
                                )
                              )
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full" },
                        [
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|min:3|alpha_spaces",
                                expression: "'required|min:3|alpha_spaces'"
                              }
                            ],
                            staticClass: "w-full",
                            attrs: {
                              name: "passengerName",
                              label:
                                _vm.$t(_vm.resources.Name.i18n) ||
                                _vm.resources.Name.name,
                              "icon-pack": "feather",
                              icon: "icon-user",
                              maxlength: "100"
                            },
                            model: {
                              value: _vm.passengerModel.name,
                              callback: function($$v) {
                                _vm.$set(_vm.passengerModel, "name", $$v)
                              },
                              expression: "passengerModel.name"
                            }
                          }),
                          _c("span", { staticClass: "text-danger text-sm" }, [
                            _vm._v(
                              _vm._s(_vm.errors.first("form-2.passengerName"))
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full" },
                        [
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|min:3|alpha_spaces",
                                expression: "'required|min:3|alpha_spaces'"
                              }
                            ],
                            staticClass: "w-full",
                            attrs: {
                              name: "passengerLastname",
                              label:
                                _vm.$t(_vm.resources.LastName.i18n) ||
                                _vm.resources.LastName.name,
                              "icon-pack": "feather",
                              icon: "icon-user",
                              maxlength: "100"
                            },
                            model: {
                              value: _vm.passengerModel.lastName,
                              callback: function($$v) {
                                _vm.$set(_vm.passengerModel, "lastName", $$v)
                              },
                              expression: "passengerModel.lastName"
                            }
                          }),
                          _c("span", { staticClass: "text-danger text-sm" }, [
                            _vm._v(
                              _vm._s(
                                _vm.errors.first("form-2.passengerLastname")
                              )
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.PassengerType.i18n) ||
                                  _vm.resources.PassengerType.name
                              )
                            )
                          ]),
                          _c("v-select", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            attrs: {
                              name: "passengerType",
                              label: "text",
                              clearable: false,
                              options: _vm.passengerType,
                              reduce: function(item) {
                                return item.value
                              }
                            },
                            model: {
                              value: _vm.passengerModel.passengerTypeId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.passengerModel,
                                  "passengerTypeId",
                                  $$v
                                )
                              },
                              expression: "passengerModel.passengerTypeId"
                            }
                          }),
                          _c("span", { staticClass: "text-danger text-sm" }, [
                            _vm._v(
                              _vm._s(_vm.errors.first("form-2.passengerType"))
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.BirthDate.i18n) ||
                                  _vm.resources.BirthDate.name
                              )
                            )
                          ]),
                          _c("datepicker", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            attrs: {
                              name: "passengerBirthDate",
                              format: "d MMMM yyyy",
                              language: _vm.languages[_vm.language]
                            },
                            model: {
                              value: _vm.passengerModel.birthDate,
                              callback: function($$v) {
                                _vm.$set(_vm.passengerModel, "birthDate", $$v)
                              },
                              expression: "passengerModel.birthDate"
                            }
                          }),
                          _c("span", { staticClass: "text-danger text-sm" }, [
                            _vm._v(
                              _vm._s(
                                _vm.errors.first("form-2.passengerBirthDate")
                              )
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  _vm.resources.ExpirationDatePassport.i18n
                                ) || _vm.resources.ExpirationDatePassport.name
                              )
                            )
                          ]),
                          _c("datepicker", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            attrs: {
                              name: "passengerPassport",
                              format: "d MMMM yyyy",
                              language: _vm.languages[_vm.language]
                            },
                            model: {
                              value: _vm.passengerModel.passportExpiration,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.passengerModel,
                                  "passportExpiration",
                                  $$v
                                )
                              },
                              expression: "passengerModel.passportExpiration"
                            }
                          }),
                          _c("span", { staticClass: "text-danger text-sm" }, [
                            _vm._v(
                              _vm._s(
                                _vm.errors.first("form-2.passengerPassport")
                              )
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full" },
                        [
                          _c("label", { staticClass: "vs-input--label" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Gender.i18n) ||
                                  _vm.resources.Gender.name
                              )
                            )
                          ]),
                          _c("v-select", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            attrs: {
                              name: "passengerGender",
                              label: "text",
                              clearable: false,
                              options: _vm.genderType,
                              reduce: function(item) {
                                return item.value
                              }
                            },
                            model: {
                              value: _vm.passengerModel.genderId,
                              callback: function($$v) {
                                _vm.$set(_vm.passengerModel, "genderId", $$v)
                              },
                              expression: "passengerModel.genderId"
                            }
                          }),
                          _c("span", { staticClass: "text-danger text-sm" }, [
                            _vm._v(
                              _vm._s(_vm.errors.first("form-2.passengerGender"))
                            )
                          ])
                        ],
                        1
                      ),
                      _vm.bookingDetail.productId ==
                        _vm.database.products.AIR &&
                      _vm.countryOperation == "USA"
                        ? _c(
                            "div",
                            { staticClass: "vx-col md:w-1/4 w-full" },
                            [
                              _c("label", { staticClass: "vs-input--label" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(_vm.resources.Country.i18n) ||
                                      _vm.resources.Country.name
                                  )
                                )
                              ]),
                              _c("v-select", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'"
                                  }
                                ],
                                attrs: {
                                  name: "passengerNationality",
                                  label: "name",
                                  clearable: false,
                                  options: _vm.countryPassenger,
                                  reduce: function(country) {
                                    return country.id
                                  }
                                },
                                model: {
                                  value: _vm.passengerModel.nationalityId,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.passengerModel,
                                      "nationalityId",
                                      $$v
                                    )
                                  },
                                  expression: "passengerModel.nationalityId"
                                }
                              }),
                              _c(
                                "span",
                                { staticClass: "text-danger text-sm" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors.first(
                                        "form-2.passengerNationality"
                                      )
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full" },
                        [
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|email",
                                expression: "'required|email'"
                              }
                            ],
                            staticClass: "w-full",
                            attrs: {
                              name: "passengerEmail",
                              label:
                                _vm.$t(_vm.resources.Email.i18n) ||
                                _vm.resources.Email.name,
                              "icon-pack": "feather",
                              icon: "icon-mail",
                              maxlength: "150"
                            },
                            model: {
                              value: _vm.passengerModel.email,
                              callback: function($$v) {
                                _vm.$set(_vm.passengerModel, "email", $$v)
                              },
                              expression: "passengerModel.email"
                            }
                          }),
                          _c("span", { staticClass: "text-danger text-sm" }, [
                            _vm._v(
                              _vm._s(_vm.errors.first("form-2.passengerEmail"))
                            )
                          ])
                        ],
                        1
                      ),
                      _vm.bookingDetail.productId ==
                        _vm.database.products.AIR &&
                      _vm.countryOperation == "USA"
                        ? _c(
                            "div",
                            { staticClass: "vx-col md:w-1/4 w-full" },
                            [
                              _c("label", { staticClass: "vs-input--label" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(_vm.resources.Indicative.i18n) ||
                                      _vm.resources.Indicative.name
                                  )
                                )
                              ]),
                              _c("v-select", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'"
                                  }
                                ],
                                attrs: {
                                  name: "passengerIndicative",
                                  label: "country",
                                  clearable: false,
                                  options: _vm.countryList,
                                  reduce: function(country) {
                                    return country.id
                                  }
                                },
                                model: {
                                  value: _vm.passengerModel.indicative,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.passengerModel,
                                      "indicative",
                                      $$v
                                    )
                                  },
                                  expression: "passengerModel.indicative"
                                }
                              }),
                              _c(
                                "span",
                                { staticClass: "text-danger text-sm" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors.first(
                                        "form-2.passengerIndicative"
                                      )
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.bookingDetail.productId ==
                        _vm.database.products.AIR &&
                      _vm.countryOperation == "USA"
                        ? _c(
                            "div",
                            { staticClass: "vx-col md:w-1/4 w-full" },
                            [
                              _c("vs-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required|min:8",
                                    expression: "'required|min:8'"
                                  }
                                ],
                                staticClass: "w-full",
                                attrs: {
                                  name: "passengerPhone",
                                  label:
                                    _vm.$t(
                                      _vm.resources.TelephoneNumber.i18n
                                    ) || _vm.resources.TelephoneNumber.name,
                                  "icon-pack": "feather",
                                  icon: "icon-hash",
                                  maxlength: "20"
                                },
                                model: {
                                  value: _vm.passengerModel.phone,
                                  callback: function($$v) {
                                    _vm.$set(_vm.passengerModel, "phone", $$v)
                                  },
                                  expression: "passengerModel.phone"
                                }
                              }),
                              _c(
                                "span",
                                { staticClass: "text-danger text-sm" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.errors.first("form-2.passengerPhone")
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "vx-col w-full md:w-1/4 mb-2 mt-5" },
                        [
                          _c(
                            "vs-button",
                            {
                              attrs: { color: "primary", type: "filled" },
                              on: { click: _vm.savePassenger }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(_vm.resources.Add.i18n) ||
                                      _vm.resources.Add.name
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c("vs-divider", { staticClass: "mb-2" }, [
                    _c("h4", { staticClass: "font-semibold mb-base" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(_vm.resources.Passengers.i18n) ||
                              _vm.resources.Passengers.name
                          ) +
                          " "
                      )
                    ])
                  ]),
                  _c(
                    "vs-table",
                    {
                      attrs: { data: _vm.passengerDto },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var data = ref.data
                            return _vm._l(data, function(tr, indextr) {
                              return _c(
                                "vs-tr",
                                { key: indextr },
                                [
                                  _c(
                                    "vs-td",
                                    { attrs: { data: data[indextr].name } },
                                    [_vm._v(_vm._s(data[indextr].name))]
                                  ),
                                  _c(
                                    "vs-td",
                                    { attrs: { data: data[indextr].lastName } },
                                    [_vm._v(_vm._s(data[indextr].lastName))]
                                  ),
                                  _c(
                                    "vs-td",
                                    { attrs: { data: data[indextr].document } },
                                    [_vm._v(_vm._s(data[indextr].document))]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: { data: data[indextr].birthDate }
                                    },
                                    [_vm._v(_vm._s(data[indextr].birthDate))]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: {
                                        data: data[indextr].passportExpiration
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(data[indextr].passportExpiration)
                                      )
                                    ]
                                  ),
                                  _c("vs-td", [
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.deletePassenger(
                                              data[indextr]
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("feather-icon", {
                                          staticClass: "w-5 h-5 mr-1",
                                          attrs: {
                                            icon: "XIcon",
                                            svgClasses:
                                              "fill-current text-danger"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ],
                                1
                              )
                            })
                          }
                        }
                      ])
                    },
                    [
                      _c(
                        "template",
                        { slot: "thead" },
                        [
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Name.i18n) ||
                                  _vm.resources.Name.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.LastName.i18n) ||
                                  _vm.resources.LastName.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.DocumentNumber.i18n) ||
                                  _vm.resources.DocumentNumber.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.BirthDate.i18n) ||
                                  _vm.resources.BirthDate.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  _vm.resources.ExpirationDatePassport.i18n
                                ) || _vm.resources.ExpirationDatePassport.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Options.i18n) ||
                                  _vm.resources.Options.name
                              )
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "vs-tab",
                {
                  attrs: {
                    label:
                      _vm.$t(_vm.resources.Itinerary.i18n) ||
                      _vm.resources.Itinerary.name
                  }
                },
                [
                  _c(
                    "form",
                    {
                      staticClass: "vx-row mb-4",
                      attrs: { "data-vv-scope": "form-3" }
                    },
                    [
                      _vm.bookingDetail.productId == 1 ||
                      _vm.bookingDetail.productId == 9
                        ? _c(
                            "div",
                            { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                            [
                              _c("label", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(_vm.resources.Hotels.i18n) ||
                                      _vm.resources.Hotels.name
                                  )
                                )
                              ]),
                              _c("v-select", {
                                attrs: {
                                  id: "searchOrigin",
                                  clearable: true,
                                  options: _vm.hotelList,
                                  placeholder:
                                    _vm.$t(_vm.resources.Select.i18n) ||
                                    _vm.resources.Select.name,
                                  reduce: function(x) {
                                    return x.id
                                  },
                                  label: "name"
                                },
                                nativeOn: {
                                  keyup: function($event) {
                                    return _vm.getHotelByName("Origin")
                                  }
                                },
                                model: {
                                  value: _vm.hotelId,
                                  callback: function($$v) {
                                    _vm.hotelId = $$v
                                  },
                                  expression: "hotelId"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.bookingDetail.productId == _vm.database.products.PAQ
                        ? _c(
                            "div",
                            { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                            [
                              _c("div", { staticClass: "vx-col w-full mb-4" }, [
                                _c("ul", { staticClass: "centerx" }, [
                                  _c(
                                    "li",
                                    [
                                      _c(
                                        "vs-checkbox",
                                        {
                                          model: {
                                            value: _vm.havePackage,
                                            callback: function($$v) {
                                              _vm.havePackage = $$v
                                            },
                                            expression: "havePackage"
                                          }
                                        },
                                        [_vm._v("¿El paquete existe en Foca?")]
                                      )
                                    ],
                                    1
                                  )
                                ])
                              ]),
                              _vm.havePackage
                                ? _c(
                                    "div",
                                    { staticClass: "vx-col  w-full mb-2" },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          clearable: true,
                                          options: _vm.packageList,
                                          placeholder:
                                            _vm.$t(_vm.resources.Select.i18n) ||
                                            _vm.resources.Select.name,
                                          reduce: function(x) {
                                            return x.packageId
                                          },
                                          label: "nameEs"
                                        },
                                        model: {
                                          value: _vm.packageId,
                                          callback: function($$v) {
                                            _vm.packageId = $$v
                                          },
                                          expression: "packageId"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "vx-col w-full mb-2" },
                                    [
                                      _c("vs-input", {
                                        staticClass: "w-full",
                                        attrs: {
                                          type: "number",
                                          name: "packageId"
                                        },
                                        model: {
                                          value: _vm.packageId,
                                          callback: function($$v) {
                                            _vm.packageId = $$v
                                          },
                                          expression: "packageId"
                                        }
                                      })
                                    ],
                                    1
                                  )
                            ]
                          )
                        : _vm._e(),
                      _vm.bookingDetail.productId == 5
                        ? _c(
                            "div",
                            { staticClass: "vx-col md:w-1/2 w-full mb-2" },
                            [
                              _c("div", { staticClass: "vx-row mb-4" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-col md:w-1/2 w-full mb-2"
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "vs-input--label" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(_vm.resources.Origin.i18n) ||
                                              _vm.resources.Origin.name
                                          )
                                        )
                                      ]
                                    ),
                                    _c("v-select", {
                                      attrs: {
                                        clearable: false,
                                        options: _vm.originOption,
                                        reduce: function(x) {
                                          return x.id
                                        },
                                        label: "name"
                                      },
                                      model: {
                                        value: _vm.origin,
                                        callback: function($$v) {
                                          _vm.origin = $$v
                                        },
                                        expression: "origin"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-col md:w-1/2 w-full mb-2"
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "vs-input--label" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              _vm.resources.Destination.i18n
                                            ) || _vm.resources.Destination.name
                                          )
                                        )
                                      ]
                                    ),
                                    _c("v-select", {
                                      attrs: {
                                        clearable: false,
                                        options: _vm.destinationOption,
                                        reduce: function(x) {
                                          return x.id
                                        },
                                        label: "name"
                                      },
                                      model: {
                                        value: _vm.destination,
                                        callback: function($$v) {
                                          _vm.destination = $$v
                                        },
                                        expression: "destination"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm.bookingDetail.productId !== 1 &&
                      _vm.bookingDetail.productId !== 4 &&
                      _vm.bookingDetail.productId !== 5 &&
                      _vm.bookingDetail.productId !== 9
                        ? _c(
                            "div",
                            { staticClass: "vx-col md:w-1/2 w-full mb-2" },
                            [
                              _c("div", { staticClass: "vx-row mb-4" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-col md:w-1/2 w-full mb-2"
                                  },
                                  [
                                    _c("label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(_vm.resources.Origin.i18n) ||
                                            _vm.resources.Origin.name
                                        )
                                      )
                                    ]),
                                    _c("v-select", {
                                      attrs: {
                                        id: "searchOrigin",
                                        clearable: true,
                                        options: _vm.originOption,
                                        placeholder:
                                          _vm.$t(
                                            _vm.resources.WriteOrigin.i18n
                                          ) || _vm.resources.WriteOrigin.name,
                                        reduce: function(origin) {
                                          return origin.id
                                        },
                                        label: "name"
                                      },
                                      nativeOn: {
                                        keyup: function($event) {
                                          return _vm.getCityByName("Origin")
                                        }
                                      },
                                      model: {
                                        value: _vm.origin,
                                        callback: function($$v) {
                                          _vm.origin = $$v
                                        },
                                        expression: "origin"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-col md:w-1/2 w-full mb-2"
                                  },
                                  [
                                    _c("label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            _vm.resources.Destination.i18n
                                          ) || _vm.resources.Destination.name
                                        )
                                      )
                                    ]),
                                    _c("v-select", {
                                      attrs: {
                                        id: "searchDestination",
                                        clearable: true,
                                        options: _vm.destinationOption,
                                        placeholder:
                                          _vm.$t(
                                            _vm.resources.WriteDestination.i18n
                                          ) ||
                                          _vm.resources.WriteDestination.name,
                                        reduce: function(destination) {
                                          return destination.id
                                        },
                                        label: "name"
                                      },
                                      nativeOn: {
                                        keyup: function($event) {
                                          return _vm.getCityByName(
                                            "Destination"
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.destination,
                                        callback: function($$v) {
                                          _vm.destination = $$v
                                        },
                                        expression: "destination"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                        [
                          _c("label", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Supplier.i18n) ||
                                  _vm.resources.Supplier.name
                              )
                            )
                          ]),
                          _c("v-select", {
                            attrs: {
                              clearable: true,
                              options: _vm.gdsOption,
                              placeholder:
                                _vm.$t(_vm.resources.Select.i18n) ||
                                _vm.resources.Select.name,
                              reduce: function(gds) {
                                return gds.id
                              },
                              label: "name"
                            },
                            model: {
                              value: _vm.gds,
                              callback: function($$v) {
                                _vm.gds = $$v
                              },
                              expression: "gds"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                        [
                          _c("label", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.DepartureDate.i18n) ||
                                  _vm.resources.DepartureDate.name
                              )
                            )
                          ]),
                          _c("flat-pickr", {
                            staticClass: "vx-col w-full",
                            attrs: {
                              config: _vm.configdateTimePicker,
                              placeholder: "yyyy-MM-dd Hh:mm"
                            },
                            model: {
                              value: _vm.departureDate,
                              callback: function($$v) {
                                _vm.departureDate = $$v
                              },
                              expression: "departureDate"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/4 w-full mb-2" },
                        [
                          _c("label", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.ArrivalDate.i18n) ||
                                  _vm.resources.ArrivalDate.name
                              )
                            )
                          ]),
                          _c("flat-pickr", {
                            staticClass: "vx-col w-full",
                            attrs: {
                              config: _vm.configdateTimePicker,
                              placeholder: "yyyy-MM-dd Hh:mm"
                            },
                            model: {
                              value: _vm.arrivalDate,
                              callback: function($$v) {
                                _vm.arrivalDate = $$v
                              },
                              expression: "arrivalDate"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.bookingDetail.productId == 2
                        ? _c(
                            "div",
                            {
                              staticClass: "vx-col w-full md:w-3/4 w-full mb-2"
                            },
                            [
                              _c("div", { staticClass: "vx-row mb-4" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vx-col w-full md:w-1/3 w-full mb-2"
                                  },
                                  [
                                    _c("label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            _vm.resources.LenderServices.i18n
                                          ) || _vm.resources.LenderServices.name
                                        )
                                      )
                                    ]),
                                    _c("v-select", {
                                      attrs: {
                                        id: "searchLender",
                                        clearable: true,
                                        options: _vm.lenderOption,
                                        placeholder:
                                          _vm.$t(_vm.resources.Select.i18n) ||
                                          _vm.resources.Select.name,
                                        reduce: function(lender) {
                                          return lender.id
                                        },
                                        label: "name"
                                      },
                                      nativeOn: {
                                        keyup: function($event) {
                                          return _vm.getAirlineByText()
                                        }
                                      },
                                      model: {
                                        value: _vm.lender,
                                        callback: function($$v) {
                                          _vm.lender = $$v
                                        },
                                        expression: "lender"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vx-col w-full md:w-1/3 w-full mb-2"
                                  },
                                  [
                                    _c("label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            _vm.resources.FlightClass.i18n
                                          ) || _vm.resources.FlightClass.name
                                        )
                                      )
                                    ]),
                                    _c("vs-input", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "alpha",
                                          expression: "'alpha'"
                                        }
                                      ],
                                      staticClass: "w-full",
                                      attrs: {
                                        "icon-pack": "feather",
                                        maxlength: "2"
                                      },
                                      model: {
                                        value: _vm.flightClass,
                                        callback: function($$v) {
                                          _vm.flightClass = $$v
                                        },
                                        expression: "flightClass"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vx-col w-full md:w-1/3 w-full mb-2"
                                  },
                                  [
                                    _c("label", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            _vm.resources.FlightNumber.i18n
                                          ) || _vm.resources.FlightNumber.name
                                        )
                                      )
                                    ]),
                                    _c("vs-input", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "numeric",
                                          expression: "'numeric'"
                                        }
                                      ],
                                      staticClass: "w-full",
                                      attrs: {
                                        "icon-pack": "feather",
                                        maxlength: "10"
                                      },
                                      model: {
                                        value: _vm.flightNumber,
                                        callback: function($$v) {
                                          _vm.flightNumber = $$v
                                        },
                                        expression: "flightNumber"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "vx-col w-full mt-4" },
                        [
                          _c(
                            "vs-button",
                            {
                              attrs: { color: "primary", type: "filled" },
                              on: { click: _vm.saveItinerary }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(_vm.resources.Add.i18n) ||
                                      _vm.resources.Add.name
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "vs-table",
                    {
                      attrs: { data: _vm.itineraryDto },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var data = ref.data
                            return _vm._l(data, function(tr, indextr) {
                              return _c(
                                "vs-tr",
                                { key: indextr },
                                [
                                  _c(
                                    "vs-td",
                                    { attrs: { data: data[indextr].origin } },
                                    [_vm._v(_vm._s(data[indextr].origin))]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: { data: data[indextr].destination }
                                    },
                                    [_vm._v(_vm._s(data[indextr].destination))]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: {
                                        data: data[indextr].departureDate
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(data[indextr].departureDate)
                                      )
                                    ]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: { data: data[indextr].arrivalDate }
                                    },
                                    [_vm._v(_vm._s(data[indextr].arrivalDate))]
                                  ),
                                  _c(
                                    "vs-td",
                                    {
                                      attrs: {
                                        data: data[indextr].supplierName
                                      }
                                    },
                                    [_vm._v(_vm._s(data[indextr].supplierName))]
                                  ),
                                  _c("td", [
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteItinerary(
                                              data[indextr]
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("feather-icon", {
                                          staticClass: "w-5 h-5 mr-1",
                                          attrs: {
                                            icon: "XIcon",
                                            svgClasses:
                                              "fill-current text-danger"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ],
                                1
                              )
                            })
                          }
                        }
                      ])
                    },
                    [
                      _c(
                        "template",
                        { slot: "thead" },
                        [
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Origin.i18n) ||
                                  _vm.resources.Origin.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Destination.i18n) ||
                                  _vm.resources.Destination.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.StartDate.i18n) ||
                                  _vm.resources.StartDate.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.EndDate.i18n) ||
                                  _vm.resources.EndDate.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.SupplierName.i18n) ||
                                  _vm.resources.SupplierName.name
                              )
                            )
                          ]),
                          _c("vs-th", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(_vm.resources.Options.i18n) ||
                                  _vm.resources.Options.name
                              )
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "vs-row",
            {
              staticClass: "mb-2",
              attrs: { "vs-type": "flex", "vs-justify": "center" }
            },
            [
              _c(
                "div",
                { staticClass: "vx-col w-full md:w-1/4 mb-2 mt-5 text-center" },
                [
                  _c(
                    "vs-button",
                    {
                      attrs: {
                        color: "success",
                        type: "filled",
                        size: "large"
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.validForm($event)
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(_vm.resources.Create.i18n) ||
                              _vm.resources.Create.name
                          ) +
                          " " +
                          _vm._s(
                            _vm.$t(_vm.resources.Booking.i18n) ||
                              _vm.resources.Booking.name
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title:
              _vm.$t(_vm.resources.Create.i18n) || _vm.resources.Create.name,
            active: _vm.popupContact
          },
          on: {
            "update:active": function($event) {
              _vm.popupContact = $event
            }
          }
        },
        [
          _c("p", [
            _vm._v(
              " Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor. "
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }