<!-- =========================================================================================
    File Name: Profile.vue
    Description: Profile Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="vx-col w-full mb-2">
      <vs-row vs-type="flex" vs-justify="center" class="mb-2">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center">
          <h2 class="mb-1 text-primary">
            {{
              $t(alerts.MsgAcquireMembership1.i18n) ||
              alerts.MsgAcquireMembership1.name
            }}
          </h2>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center">
          <h2 class="mb-1 text-primary">
            {{
              $t(alerts.MsgAcquireMembership2.i18n) ||
              alerts.MsgAcquireMembership2.name
            }}
          </h2>
        </vs-col>
      </vs-row>
      <form-wizard
        ref="checkoutWizard"
        color="rgba(var(--vs-primary), 1)"
        :title="null"
        :subtitle="null"
        :hide-buttons="true"
      >
        <tab-content
          :title="$t(resources.Product.i18n) || resources.Product.name"
          icon="feather icon-shopping-bag"
          class="mb-5"
        >
          <div class="vx-row">
            <div class="vx-col lg:w-3/4 w-full">
              <vx-card class="mb-base">
                <div class="vx-row mb-2">
                  <div class="vx-col sm:w-1/4 w-full">
                    <label>{{
                      $t(resources.Type.i18n) || resources.Type.name
                    }}</label>
                    <ul class="leftx">
                      <li>
                        <vs-radio
                          v-model="radioMembershipType"
                          vs-value="1"
                          v-on:change="deleteBilling()"
                          >{{
                            $t(resources.Distributor.i18n) ||
                            resources.Distributor.name
                          }}</vs-radio
                        >
                      </li>
                      <li>
                        <vs-radio
                          v-model="radioMembershipType"
                          vs-value="2"
                          v-on:change="deleteBilling()"
                          >{{
                            $t(resources.Customer.i18n) ||
                            resources.Customer.name
                          }}</vs-radio
                        >
                      </li>
                    </ul>
                  </div>
                  <div class="vx-col md:w-1/3 w-full">
                    <label class="vs-input--label">{{
                      $t(resources.CountryOperation.i18n) ||
                      resources.CountryOperation.name
                    }}</label>
                    <v-select
                      v-model="countryOperationId"
                      :clearable="false"
                      :options="countryOperationType"
                      :reduce="(data) => data.countryId"
                      label="display"
                      scrollable
                    >
                      <template slot="option" slot-scope="option">
                        <img :src="option.iconUrl" class="h-6 w-6" />
                        {{ option.display }}
                      </template>
                    </v-select>
                  </div>
                  <div class="vx-col md:w-1/3 w-full">
                    <label class="vs-input--label">{{
                      $t(resources.Currency.i18n) || resources.Currency.name
                    }}</label>
                    <v-select
                      v-model="currencyId"
                      :clearable="false"
                      :options="currencyList"
                      :reduce="(data) => data.id"
                      label="name"
                      scrollable
                      v-on:input="getProductByType(`${currencyId}`)"
                      :hint="`${currencyId}`"
                    >
                    </v-select>
                  </div>
                  <div
                    class="vx-col md:w-1/2 w-full mt-4 mb-base"
                    v-if="countryOperationId"
                  >
                    <label class="vs-input--label">{{
                      $t(resources.Product.i18n) || resources.Product.name
                    }}</label>
                    <v-select
                      v-model="productSelectId"
                      :clearable="false"
                      :options="productSelect"
                      :reduce="(data) => data.id"
                      label="name"
             
                    >
                      <template slot="option" slot-scope="option">
                        <img
                          :src="option.imageUrl"
                          @error="imageUrlAlt"
                          class="h-8 w-9"
                        />
                        {{ option.name }}
                      </template>
                    </v-select>
                  </div>
                  <!-- <div
                    class="vx-col md:w-1/2 w-full mt-base"
                    v-if="radioMembershipType == '1'"
                  >
                    <ul class="centerx">
                      <li>
                        <vs-checkbox
                          v-model="includeNeo"
                          v-on:change="deleteNeo()"
                          >{{
                            $t(resources.IncludeNeo.i18n) ||
                            resources.IncludeNeo.name
                          }}</vs-checkbox
                        >
                      </li>
                    </ul>
                  </div> -->
                  <div
                    class="vx-col md:w-1/2 w-full mt-base"
                    v-if="
                      radioMembershipType == '1' &&
                        currencyId == 24 &&
                        productSelectId == 16
                    "
                  >
                    <label class="">¿Adquirir plan con paquete de viaje?</label>
                    <v-select
                      v-model="groupId"
                      :clearable="false"
                      :options="groups"
                      :reduce="data => data.id"
                      label="name"
                      v-on:input="selectInstallment(`${groupId}`)"
                      :hint="`${groupId}`"
                    >
                    </v-select>
                  </div>
                </div>

                <div
                  class="vx-row"
                  v-if="isInstallmentsAllowed && radioMembershipType == '1' && groupId != '' && groupId != null"
                >
                  <vs-divider />
                  <div class="vx-col md:w-1/1 w-full">
                    <ul class="centerx">
                      <li>
                        <vs-checkbox v-model="checkBox1">{{
                          $t(resources.FinanceProduct.i18n) ||
                            resources.FinanceProduct.name
                        }}</vs-checkbox>
                      </li>
                    </ul>
                  </div>
                  <div class="vx-col md:w-1/1 w-full" v-if="checkBox1">
                    <label class="vs-input--label">{{
                      $t(resources.Installments.i18n) ||
                        resources.Installments.name
                    }}</label>
                    <v-select
                      v-model="installmentProductOptionId"
                      :clearable="false"
                      :options="installmentProductOption"
                      v-on:input="
                        setInstallment(`${installmentProductOptionId}`)
                      "
                      :hint="`${installmentProductOptionId}`"
                    ></v-select>
                  </div>
                </div>

                <vs-button
                  v-if="productSelectId || membershipId != 0"
                  class="mt-6 ml-auto flex"
                  @click="$refs.checkoutWizard.nextTab()"
                  >{{
                    $t(resources.Next.i18n) || resources.Next.name
                  }}</vs-button
                >
              </vx-card>
            </div>

            <div class="vx-col lg:w-1/4 w-full">
              <billing
                :data="billingData"
                :installment="installmentProductOptionId"
              ></billing>
              <vs-row
                vs-type="flex"
                vs-justify="center"
                class="mb-base"
                v-if="enabledButton"
              >
                <vs-col vs-type="flex" vs-justify="center" vs-align="center">
                  <vs-button
                    color="primary"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-heart"
                    size="large"
                    class="mt-4"
                    @click="generate()"
                    >{{
                      $t(resources.BeingMember.i18n) ||
                      resources.BeingMember.name
                    }}</vs-button
                  >
                </vs-col>
              </vs-row>
            </div>
          </div>
        </tab-content>
        <tab-content
          :title="
            $t(resources.BasicInformation.i18n) ||
            resources.BasicInformation.name
          "
          icon="feather icon-user"
          class="mb-5"
        >
          <div class="vx-row">
            <div class="vx-col lg:w-3/4 w-full">
              <vx-card>
                <div class="vx-row">
                  <div class="vx-col md:w-1/2 w-full mb-4">
                    <ul class="centerx">
                      <li>
                        <vs-checkbox v-model="isBusiness">{{
                          $t(resources.IsCompany.i18n) ||
                          resources.IsCompany.name
                        }}</vs-checkbox>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="vx-row">
                  <div class="vx-col md:w-2/3 w-full mb-2" v-if="isBusiness">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-user"
                        :label="$t(resources.Name.i18n) || resources.Name.name"
                        v-model="name"
                        maxlength="32"
                        v-validate="{ required: true, regex: /^([a-zA-Z ]+)$/ }"
                        name="name"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('name')"
                        >{{ errors.first("name") }}</span
                      >
                    </div>
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2" v-if="!isBusiness">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-user"
                        :label="$t(resources.Name.i18n) || resources.Name.name"
                        v-model="name"
                        maxlength="32"
                        v-validate="{
                          required: true,
                          regex: /^([a-zA-Z ]+)$/,
                        }"
                        name="name"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('name')"
                        >{{ errors.first("name") }}</span
                      >
                    </div>
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2" v-if="!isBusiness">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-user"
                        :label="
                          $t(resources.LastName.i18n) || resources.LastName.name
                        "
                        v-model="lastName"
                        maxlength="32"
                        v-validate="{
                          required: true,
                          regex: /^([a-zA-Z ]+)$/,
                        }"
                        name="lastName"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('lastName')"
                      >{{ errors.first("lastName") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        type="email"
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-mail"
                        :label="
                          $t(resources.Email.i18n) || resources.Email.name
                        "
                        v-model="email"
                        v-validate="'required|max:100|email'"
                        name="email"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('email')"
                        >{{ errors.first("email") }}</span
                      >
                    </div>
                  </div>

                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <label class="vs-input--label">{{
                      $t(resources.DocumentType.i18n) ||
                      resources.DocumentType.name
                    }}</label>
                    <v-select
                      v-model="documentTypeId"
                      :clearable="false"
                      :options="identifiacionTypeList"
                      :reduce="(data) => data.id"
                      label="name"
                      v-validate="'required'"
                      name="documentType"
                    ></v-select>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('documentType')"
                      >{{ errors.first("documentType") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        v-if="documentTypeId == 11"
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-hash"
                        :label="
                          $t(resources.DocumentNumber.i18n) ||
                          resources.DocumentNumber.name
                        "
                        v-model="document"
                        name="document"
                        type="number"
                        v-validate="'required|min:8|max:9'"
                      />
                      <vs-input
                        v-else
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-hash"
                        :label="
                          $t(resources.DocumentNumber.i18n) ||
                          resources.DocumentNumber.name
                        "
                        v-model="document"
                        name="document"
                        v-validate="'required|max:20|alpha_num'"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('document')"
                      >{{ errors.first("document") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <vs-select
                      class="w-full"
                      icon-no-border
                      :label="
                        $t(resources.Gender.i18n) || resources.Gender.name
                      "
                      v-model="gender"
                      v-validate="'required'"
                      name="gender"
                    >
                      <vs-select-item
                        :key="index"
                        :value="item.value"
                        :text="$t(item.i18n) || item.text"
                        v-for="(item, index) in genderOption"
                      />
                    </vs-select>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('gender')"
                      >{{ errors.first("gender") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <label class="vs-input--label">{{
                      $t(resources.BirthDay.i18n) || resources.BirthDay.name
                    }}</label>
                    <v-select
                      v-model="birthDay"
                      :clearable="false"
                      :options="dayList"
                      :reduce="(data) => data.value"
                      label="text"
                      v-validate="'required'"
                      name="birthDay"
                    ></v-select>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('birthDay')"
                      >{{ errors.first("birthDay") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <label class="vs-input--label">{{
                      $t(resources.BirthMonth.i18n) || resources.BirthMonth.name
                    }}</label>
                    <v-select
                      v-model="birthMonth"
                      :clearable="false"
                      :options="monthList"
                      :reduce="(data) => data.value"
                      label="text"
                      v-validate="'required'"
                      name="birthMonth"
                    ></v-select>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('birthMonth')"
                      >{{ errors.first("birthMonth") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <label class="vs-input--label">{{
                      $t(resources.BirthYear.i18n) || resources.BirthYear.name
                    }}</label>
                    <v-select
                      v-model="birthYear"
                      :clearable="false"
                      :options="yearList"
                      :reduce="(data) => data.value"
                      label="text"
                      v-validate="'required'"
                      name="birthYear"
                    ></v-select>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('birthYear')"
                      >{{ errors.first("birthYear") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <label class="vs-input--label">{{
                      $t(resources.Indicative.i18n) || resources.Indicative.name
                    }}</label>
                    <v-select
                      v-model="indicativeId"
                      :clearable="false"
                      :options="countryList"
                      :reduce="(country) => country.id"
                      label="name"
                      name="indicativeId"
                      v-validate="'required'"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('indicativeId')"
                      >{{ errors.first("indicativeId") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-smartphone"
                        :label="
                          $t(resources.Mobile.i18n) || resources.Mobile.name
                        "
                        v-model="phone"
                        name="phone"
                        v-validate="'required|numeric'"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('phone')"
                      >{{ errors.first("phone") }}</span
                    >
                  </div>

                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <label class="vs-input--label">{{
                      $t(resources.ResidenceCountry.i18n) ||
                      resources.ResidenceCountry.name
                    }}</label>
                    <v-select
                      v-model="countryId"
                      :clearable="false"
                      :options="countryList"
                      :reduce="(country) => country.id"
                      label="name"
                      v-on:input="getStateByCountry(`${countryId}`)"
                      :hint="`${countryId}`"
                      name="country"
                      v-validate="'required'"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('country')"
                      >{{ errors.first("country") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <label class="vs-input--label">{{
                      $t(resources.ResidenceState.i18n) ||
                      resources.ResidenceState.name
                    }}</label>
                    <v-select
                      v-model="stateId"
                      :clearable="false"
                      :options="stateCountry"
                      :reduce="(state) => state.id"
                      label="name"
                      v-on:input="getCityByState(`${stateId}`)"
                      :hint="`${stateId}`"
                      v-validate="'required'"
                      name="state"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('state')"
                      >{{ errors.first("state") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <label class="vs-input--label">{{
                      $t(resources.ResidenceCity.i18n) ||
                      resources.ResidenceCity.name
                    }}</label>
                    <v-select
                      v-model="cityId"
                      :clearable="false"
                      :options="destinationList"
                      :reduce="(city) => city.id"
                      label="name"
                      v-validate="'required'"
                      name="city"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('city')"
                      >{{ errors.first("city") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-map-pin"
                        :label="
                          $t(resources.Address.i18n) || resources.Address.name
                        "
                        v-model="address"
                        v-validate="{
                          required: true,
                          regex: /^([a-zA-Z0-9- ]+)$/,
                        }"
                        name="address"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('address')"
                      >{{ errors.first("address") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-hash"
                        type="number"
                        :label="
                          $t(resources.ZipCode.i18n) || resources.ZipCode.name
                        "
                        v-model="zipCode"
                        v-validate="'required|min:5|max:10|numeric'"
                        name="zipCode"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('zipCode')"
                      >{{ errors.first("zipCode") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-user"
                        :label="
                          $t(resources.BeneficiaryName.i18n) ||
                          resources.BeneficiaryName.name
                        "
                        v-model="beneficiaryName"
                        v-validate="{
                          required: false,
                          regex: /^([a-zA-Z ]+)$/,
                        }"
                        name="beneficiaryName"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('beneficiaryName')"
                      >{{ errors.first("beneficiaryName") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-user"
                        :label="
                          $t(resources.BeneficiaryLastName.i18n) ||
                          resources.BeneficiaryLastName.name
                        "
                        v-model="beneficiaryLastName"
                        v-validate="{
                          required: false,
                          regex: /^([a-zA-Z ]+)$/,
                        }"
                        name="beneficiaryLastName"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('beneficiaryLastName')"
                      >{{ errors.first("beneficiaryLastName") }}</span
                    >
                  </div>
                </div>

                <vs-divider>
                  <h5>
                    {{
                      $t(resources.BinaryTree.i18n) || resources.BinaryTree.name
                    }}
                  </h5>
                </vs-divider>
                <div class="vx-row">
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <label class="vs-input--label">{{
                        $t(resources.Enroller.i18n) || resources.Enroller.name
                      }}</label>
                      <v-select
                        id="enroller"
                        @keyup.native="getItaByText('enroller')"
                        v-model="enroller"
                        :clearable="true"
                        :options="enrollerList"
                        :reduce="(item) => item.itaCode"
                        label="fullName"
                        name="enroller"
                        v-validate="'required'"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('enroller')"
                        >{{ errors.first("enroller") }}</span
                      >
                    </div>
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <label class="vs-input--label">{{
                        $t(resources.Closer.i18n) || resources.Closer.name
                      }}</label>
                      <v-select
                        id="presenter"
                        @keyup.native="getItaByText('presenter')"
                        v-model="presenter"
                        :clearable="true"
                        :options="presenterList"
                        :reduce="(item) => item.itaCode"
                        label="fullName"
                        name="presenter"
                        v-validate="'required'"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('presenter')"
                        >{{ errors.first("presenter") }}</span
                      >
                    </div>
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2 mt-5">
                    <div class="vx-col w-full">
                      <vs-checkbox v-model="isAllpresenter">{{
                        $t(resources.AllCloser.i18n) || resources.AllCloser.name
                      }}</vs-checkbox>
                    </div>
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <label class="vs-input--label">{{
                        $t(resources.Sponsor.i18n) || resources.Sponsor.name
                      }}</label>
                      <v-select
                        id="sponsor"
                        @keyup.native="getItaByText('sponsor')"
                        v-model="sponsor"
                        :clearable="true"
                        :options="sponsorList"
                        :reduce="(item) => item.itaCode"
                        label="fullName"
                        name="sponsor"
                        v-validate="'required'"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('sponsor')"
                        >{{ errors.first("sponsor") }}</span
                      >
                    </div>
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <label>{{
                        $t(resources.BusinessCenter.i18n) ||
                        resources.BusinessCenter.name
                      }}</label>
                      <v-select
                        v-model="businessCenterId"
                        :clearable="false"
                        :options="businessCenterOption"
                        :reduce="(data) => data.value"
                        label="text"
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <label>{{
                        $t(resources.Side.i18n) || resources.Side.name
                      }}</label>
                      <v-select
                        v-model="sideId"
                        :clearable="false"
                        :options="sideOption"
                        :reduce="(data) => data.value"
                        label="text"
                      />
                    </div>
                  </div>
                </div>

                <div class="flex justify-between">
                  <vs-button
                    class="mt-6 ml-auto flex"
                    @click="$refs.checkoutWizard.prevTab()"
                    >{{
                      $t(resources.Back.i18n) || resources.Back.name
                    }}</vs-button
                  >
                  <vs-button
                    class="mt-6 ml-2 flex"
                    @click.prevent="validBasicInformation"
                    >{{
                      $t(resources.Next.i18n) || resources.Next.name
                    }}</vs-button
                  >
                </div>
              </vx-card>
            </div>

            <div class="vx-col lg:w-1/4 w-full">
              <billing
                :data="billingData"
                :installment="installmentProductOptionId"
              ></billing>
              <vs-row
                vs-type="flex"
                vs-justify="center"
                class="mb-base"
                v-if="enabledButton"
              >
                <vs-col vs-type="flex" vs-justify="center" vs-align="center">
                  <vs-button
                    color="primary"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-heart"
                    size="large"
                    class="mt-4"
                    @click="generate()"
                    >{{
                      $t(resources.BeingMember.i18n) ||
                      resources.BeingMember.name
                    }}</vs-button
                  >
                </vs-col>
              </vs-row>
            </div>
          </div>
        </tab-content>
        <tab-content
          :title="
            $t(resources.PaymentMethod.i18n) || resources.PaymentMethod.name
          "
          icon="feather icon-credit-card"
          class="mb-5"
        >
          <div class="vx-row">
            <div class="vx-col lg:w-3/4 w-full relative">
              <vx-card
                class="mb-2"
                :title="$t(resources.Select.i18n) || resources.Select.name"
              >
                <div class="vx-col md:w-1/2 w-full">
                  <label class="vs-input--label">{{
                    $t(resources.WayToPay.i18n) || resources.WayToPay.name
                  }}</label>
                  <v-select
                    v-model="wayToPayOptionId"
                    :clearable="true"
                    :options="wayToPayOption"
                    :reduce="(data) => data.wayToPayId"
                    label="name"
                    v-on:input="setWayToPay(`${wayToPayOptionId}`)"
                    :hint="`${wayToPayOptionId}`"
                  />
                </div>
              </vx-card>

              <vx-card
                class="mb-2"
                v-if="wayToPayOptionId == database.merchantType.creditCard"
              >
                <h4 class="mb-4">
                  {{
                    $t(resources.AddPayment.i18n) || resources.AddPayment.name
                  }}
                  -
                  {{
                    $t(resources.CreditCard.i18n) || resources.CreditCard.name
                  }}
                </h4>
                <div class="vx-row">
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <label class="vs-input--label">{{
                        $t(resources.Type.i18n) || resources.Type.name
                      }}</label>
                      <v-select
                        v-model="creditcardPayment.creditCardTypeId"
                        :clearable="false"
                        :options="creditcardOption"
                        :reduce="(data) => data.cardId"
                        label="name"
                        v-on:input="
                          setInstallmentNumber(
                            `${creditcardPayment.creditCardTypeId}`
                          )
                        "
                        :hint="`${creditcardPayment.creditCardTypeId}`"
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-credit-card"
                        size="small"
                        type="number"
                        :label="
                          $t(resources.CreditCardNumber.i18n) ||
                          resources.CreditCardNumber.name
                        "
                        v-model="creditcardPayment.cardNumber"
                        v-validate="'required|credit_card'"
                        name="cardNumber"
                      />
                      <span
                        class="text-danger text-sm"
                        v-show="errors.has('cardNumber')"
                        >{{ errors.first("cardNumber") }}</span
                      >
                    </div>
                  </div>
                  <div
                    class="vx-col md:w-1/3 w-full mb-2"
                    v-if="
                      installmentNumberOption &&
                      installmentNumberOption.length > 0
                    "
                  >
                    <div class="vx-col w-full">
                      <label class="vs-input--label">{{
                        $t(resources.InstallmentNumber.i18n) ||
                        resources.InstallmentNumber.name
                      }}</label>
                      <v-select
                        v-model="creditcardPayment.installmentNumber"
                        :clearable="false"
                        :options="installmentNumberOption"
                        :reduce="(data) => data.cardId"
                        label="name"
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-hash"
                        size="small"
                        type="password"
                        :maxlength="maxCvv"
                        :label="
                          $t(resources.SecurityCode.i18n) ||
                          resources.SecurityCode.name
                        "
                        v-model="creditcardPayment.cardSecurityCode"
                        name="SecurityCode"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('SecurityCode')"
                      >{{ errors.first("SecurityCode") }}</span
                    >
                  </div>
                  <div class="vx-col w-full md:w-1/3 mb-4">
                    <label>{{
                      $t(resources.ExpirationYear.i18n) ||
                      resources.ExpirationYear.name
                    }}</label>
                    <vs-select
                      icon-no-border
                      :placeholder="
                        $t(resources.ExpirationYear.i18n) ||
                        resources.ExpirationYear.name
                      "
                      v-model="expirationYear"
                      v-on:change="expirationYearChange($event)"
                    >
                      <vs-select-item
                        :key="index"
                        :value="item"
                        :text="item"
                        v-for="(item, index) in expirationYearOption"
                      />
                    </vs-select>
                  </div>
                  <div class="vx-col w-full md:w-1/3 mb-4">
                    <label>{{
                      $t(resources.ExpirationMonth.i18n) ||
                      resources.ExpirationMonth.name
                    }}</label>
                    <vs-select
                      icon-no-border
                      :placeholder="
                        $t(resources.ExpirationMonth.i18n) ||
                        resources.ExpirationMonth.name
                      "
                      v-model="expirationMonth"
                    >
                      <vs-select-item
                        :key="index"
                        :value="item"
                        :text="item"
                        v-for="(item, index) in expirationMonthOption"
                      />
                    </vs-select>
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-user"
                        size="small"
                        :label="$t(resources.Name.i18n) || resources.Name.name"
                        v-model="creditcardPayment.cardHolderName"
                        v-validate="{ required: true, regex: /^([a-zA-Z ]+)$/ }"
                        name="cardHolderName"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('cardHolderName')"
                      >{{ errors.first("cardHolderName") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-user"
                        size="small"
                        :label="
                          $t(resources.LastName.i18n) || resources.LastName.name
                        "
                        v-model="creditcardPayment.cardholderLastName"
                        v-validate="{ required: true, regex: /^([a-zA-Z ]+)$/ }"
                        name="cardholderLastName"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('cardholderLastName')"
                      >{{ errors.first("cardholderLastName") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-hash"
                        size="small"
                        type="number"
                        :label="
                          $t(resources.ZipCode.i18n) || resources.ZipCode.name
                        "
                        v-model="creditcardPayment.zipCode"
                        v-validate="'required|min:5|max:10|numeric'"
                        name="zipCodeCard"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('zipCodeCard')"
                      >{{ errors.first("zipCodeCard") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-mail"
                        size="small"
                        type="email"
                        :label="
                          $t(resources.Email.i18n) || resources.Email.name
                        "
                        v-model="creditcardPayment.email"
                        v-validate="'required|max:100|email'"
                        name="emailCard"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('emailCard')"
                      >{{ errors.first("emailCard") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-map-pin"
                        icon-no-border
                        size="small"
                        :label="
                          $t(resources.Address.i18n) || resources.Address.name
                        "
                        v-model="creditcardPayment.address"
                        v-validate="{
                          required: true,
                          regex: /^([a-zA-Z0-9 ]+)$/,
                        }"
                        name="addressCard"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('addressCard')"
                      >{{ errors.first("addressCard") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-hash"
                        icon-no-border
                        size="small"
                        :label="
                          $t(resources.DocumentNumber.i18n) ||
                          resources.DocumentNumber.name
                        "
                        v-model="creditcardPayment.documentNumber"
                        v-validate="'required|max:20|alpha_num'"
                        name="documentCard"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('documentCard')"
                      >{{ errors.first("documentCard") }}</span
                    >
                  </div>

                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-dollar-sign"
                        size="small"
                        type="number"
                        :label="
                          $t(resources.Value.i18n) || resources.Value.name
                        "
                        v-model="creditcardPayment.amount"
                        v-validate="'required|decimal:2'"
                        name="amountCard"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('amountCard')"
                      >{{ errors.first("amountCard") }}</span
                    >
                  </div>
                </div>
                <div class="vx-col mb-2 mt-5" style="text-align: right">
                  <vs-button
                    color="primary"
                    type="filled"
                    @click="createPayment()"
                  >
                    {{ $t(resources.Save.i18n) || resources.Save.name }}
                  </vs-button>
                </div>
              </vx-card>

              <vx-card
                class="mb-2"
                v-if="wayToPayOptionId == database.merchantType.EWallet"
              >
                <h4 class="mb-4">
                  {{
                    $t(resources.AddPayment.i18n) || resources.AddPayment.name
                  }}
                  - {{ $t(resources.EWallet.i18n) || resources.EWallet.name }}
                </h4>
                <div class="vx-row">
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-user"
                        size="small"
                        type="number"
                        :label="
                          $t(resources.DistributorID.i18n) ||
                          resources.DistributorID.name
                        "
                        v-model="eWalletPayment.itaCodeWallet"
                        v-validate="'required|max:10|numeric'"
                        name="itaCodeWallet"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('itaCodeWallet')"
                      >{{ errors.first("itaCodeWallet") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/3 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-dollar-sign"
                        size="small"
                        type="number"
                        :label="
                          $t(resources.Value.i18n) || resources.Value.name
                        "
                        v-model="eWalletPayment.amount"
                        v-validate="'required|decimal:2'"
                        name="amountCard"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('amountCard')"
                      >{{ errors.first("amountCard") }}</span
                    >
                  </div>
                  <div class="vx-col mb-2 mt-5" style="text-align: right">
                    <vs-button
                      color="primary"
                      type="filled"
                      @click="createPayment()"
                    >
                      {{ $t(resources.Save.i18n) || resources.Save.name }}
                    </vs-button>
                  </div>
                </div>
              </vx-card>

              <vx-card
                class="mb-2"
                v-if="wayToPayOptionId == database.merchantType.transfer"
              >
                <h4 class="mb-4">
                  {{
                    $t(resources.AddPayment.i18n) || resources.AddPayment.name
                  }}
                  - {{ $t(resources.Transfer.i18n) || resources.Transfer.name }}
                </h4>
                <div class="vx-row">
                  <div class="vx-col md:w-1/2 w-full mb-2">
                    <label class="vs-input--label">{{
                      $t(resources.BankName.i18n) || resources.BankName.name
                    }}</label>
                    <v-select
                      v-model="transferPayment.bankTypeId"
                      :clearable="false"
                      :options="bankType"
                      :reduce="(data) => data.id"
                      label="name"
                      v-on:input="setAccountNumber(transferPayment.bankTypeId)"
                      :hint="transferPayment.bankTypeId"
                    />
                  </div>
                  <div class="vx-col md:w-1/2 w-full mb-2">
                    <label class="vs-input--label">{{
                      $t(resources.AccountNumber.i18n) ||
                      resources.AccountNumber.name
                    }}</label>
                    <v-select
                      v-model="transferPayment.bankAccountId"
                      :clearable="false"
                      :options="accountNumberType"
                      :reduce="(data) => data.id"
                      label="account"
                    />
                  </div>
                  <div class="vx-col md:w-1/2 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-hash"
                        size="small"
                        type="number"
                        :label="
                          $t(resources.DocumentNumber.i18n) ||
                          resources.DocumentNumber.name
                        "
                        v-model="transferPayment.documentId"
                        name="documentCard"
                        v-validate="'required|max:20|alpha_num'"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('documentCard')"
                      >{{ errors.first("documentCard") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/2 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-hash"
                        size="small"
                        :label="
                          $t(resources.ReferenceCode.i18n) ||
                          resources.ReferenceCode.name
                        "
                        v-model="transferPayment.referenceCode"
                        name="referenceCode"
                        v-validate="'required|max:20|alpha_num'"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('referenceCode')"
                      >{{ errors.first("referenceCode") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/2 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-dollar-sign"
                        size="small"
                        type="number"
                        :label="
                          $t(resources.Value.i18n) || resources.Value.name
                        "
                        v-model="transferPayment.amount"
                        name="amountCard"
                        v-validate="'required|decimal:2'"
                      />
                    </div>
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('amountCard')"
                      >{{ errors.first("amountCard") }}</span
                    >
                  </div>
                  <div class="vx-col md:w-1/2 w-full mb-2">
                    <label class="btn btn-primary" style="margin-right: 80px">{{
                      $t(resources.Attachment.i18n) || resources.Attachment.name
                    }}</label>
                    <vs-input
                      type="file"
                      class="w-full"
                      @change="onFileSelected"
                    />
                  </div>
                </div>
                <div class="vx-col mb-2 mt-5" style="text-align: right">
                  <vs-button
                    color="primary"
                    type="filled"
                    @click.prevent="validPayment()"
                  >
                    {{ $t(resources.Save.i18n) || resources.Save.name }}
                  </vs-button>
                </div>
              </vx-card>

              <vx-card
                v-if="paymentList.length > 0"
                :title="
                  $t(resources.PaymentsReceived.i18n) ||
                  resources.PaymentsReceived.name
                "
              >
                <vs-table pagination max-items="10" :data="paymentList">
                  <template slot="thead">
                    <vs-th>{{
                      $t(resources.WayToPay.i18n) || resources.WayToPay.name
                    }}</vs-th>
                    <vs-th>{{
                      $t(resources.Description.i18n) ||
                      resources.Description.name
                    }}</vs-th>
                    <vs-th>{{
                      $t(resources.Value.i18n) || resources.Value.name
                    }}</vs-th>
                    <vs-th>{{
                      $t(resources.TransactionNumber.i18n) ||
                      resources.TransactionNumber.name
                    }}</vs-th>
                    <vs-th>{{
                      $t(resources.CreationDate.i18n) ||
                      resources.CreationDate.name
                    }}</vs-th>
                    <vs-th>{{
                      $t(resources.Status.i18n) || resources.Status.name
                    }}</vs-th>
                    <vs-th>{{
                      $t(resources.Options.i18n) || resources.Options.name
                    }}</vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                      <vs-td
                        v-if="
                          data[indextr].merchantTypeId ==
                          database.merchantType.transfer
                        "
                        :data="data[indextr].merchantType"
                      >
                        <vs-button
                          color="success"
                          type="line"
                          icon-pack="feather"
                          icon="icon-download-cloud"
                          @click="
                            openDocument(
                              `${urlVision}Payments/purchases/${data[indextr].attachmentId}.${data[indextr].attachmentExtension}`
                            )
                          "
                          >{{ data[indextr].merchantType }}</vs-button
                        >
                      </vs-td>
                      <vs-td v-else :data="data[indextr].merchantType">{{
                        data[indextr].merchantType
                      }}</vs-td>
                      <vs-td :data="data[indextr].information">{{
                        data[indextr].information
                      }}</vs-td>
                      <vs-td :data="data[indextr].value">{{
                        data[indextr].value
                      }}</vs-td>
                      <vs-td :data="data[indextr].transactionNumber">{{
                        data[indextr].transactionNumber
                      }}</vs-td>
                      <vs-td :data="data[indextr].creationDate">{{
                        data[indextr].creationDate
                      }}</vs-td>
                      <vs-td
                        v-if="
                          data[indextr].merchantTypeId ==
                            database.merchantType.EWallet &&
                          data[indextr].stageId == status.payment.pending
                        "
                        :data="data[indextr].merchantType"
                      >
                        <vs-button
                          color="success"
                          type="line"
                          icon-pack="feather"
                          icon="icon-refresh-ccw"
                          @click="
                            paymentEwallet(
                              `${data[indextr].information}`,
                              `${data[indextr].referenceId}`
                            )
                          "
                          >{{ data[indextr].stageName }}</vs-button
                        >
                      </vs-td>
                      <vs-td
                        v-else-if="
                          data[indextr].merchantTypeId ==
                            database.merchantType.transfer &&
                          data[indextr].stageId == status.payment.pending
                        "
                        :data="data[indextr].merchantType"
                      >
                        {{ data[indextr].stageName }}
                        <vs-button
                          color="success"
                          icon-pack="feather"
                          icon="icon-check"
                          @click="
                            paymentTransfer(
                              `${status.payment.paid}`,
                              `${data[indextr].referenceId}`
                            )
                          "
                        ></vs-button>
                        <vs-button
                          color="danger"
                          icon-pack="feather"
                          icon="icon-x"
                          @click="
                            paymentTransfer(
                              `${status.payment.declined}`,
                              `${data[indextr].referenceId}`
                            )
                          "
                        ></vs-button>
                      </vs-td>
                      <vs-td v-else :data="data[indextr].stageName">{{
                        data[indextr].stageName
                      }}</vs-td>
                      <vs-td>
                        <vs-button
                          color="primary"
                          type="line"
                          icon-pack="feather"
                          icon="icon-eye"
                          @click="
                            openResponseMerchant(
                              `${data[indextr].merchantResponse}`
                            )
                          "
                        ></vs-button>
                        <vs-button
                          color="primary"
                          type="line"
                          icon-pack="feather"
                          icon="icon-edit"
                          @click="
                            openEditPay(
                              data[indextr].stageId,
                              `${data[indextr].id}`
                            )
                          "
                        ></vs-button>
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </vx-card>
            </div>

            <div class="vx-col lg:w-1/4 w-full">
              <billing
                :data="billingData"
                :installment="installmentProductOptionId"
              ></billing>
              <vs-row
                vs-type="flex"
                vs-justify="center"
                class="mb-base"
                v-if="enabledButton"
              >
                <vs-col vs-type="flex" vs-justify="center" vs-align="center">
                  <vs-button
                    color="primary"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-heart"
                    size="large"
                    class="mt-4"
                    @click="generate()"
                    >{{
                      $t(resources.BeingMember.i18n) ||
                      resources.BeingMember.name
                    }}</vs-button
                  >
                </vs-col>
              </vs-row>
            </div>
          </div>
        </tab-content>
      </form-wizard>
      <vx-card
        :title="$t(resources.Management.i18n) || resources.Management.name"
      >
      <p class="mb-2"><strong>{{$t(resources.CreationDate.i18n) || resources.CreationDate.name}}:</strong> {{creationDate}}</p>
        <h3 class="text-center text-primary mb-4">
          <strong
            >{{
              $t(resources.Enroller.i18n) || resources.Enroller.name
            }}:</strong
          >
          {{ itaCode }}&nbsp;|&nbsp;
          <strong
            >{{
              $t(resources.NewMember.i18n) || resources.NewMember.name
            }}:</strong
          >
          {{ newMemberCode }}&nbsp;|&nbsp;
          <strong
            >{{
              $t(resources.OrderNumber.i18n) || resources.OrderNumber.name
            }}:</strong
          >
          {{ orderNumber }}
        </h3>

        <vs-row vs-type="flex" vs-justify="center" class="mb-2">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center">
            <vs-button
              color="warning"
              type="filled"
              icon-pack="feather"
              icon="icon-edit"
              @click="popupEditOrder = true"
              >{{ $t(resources.Update.i18n) || resources.Update.name }} -
              {{
                $t(resources.OrderNumber.i18n) || resources.OrderNumber.name
              }}</vs-button
            >
          </vs-col>
        </vs-row>

        <h3 class="text-center text-success">
          <strong
            >{{ $t(resources.Stage.i18n) || resources.Stage.name }}:</strong
          >
          {{ stageName }}
        </h3>
        <vs-row vs-type="flex" vs-justify="center" class="mb-base">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center">
            <vs-button
              v-if="canUpdate"
              color="primary"
              type="filled"
              icon-pack="feather"
              icon="icon-file-text"
              class="mt-4"
              @click="sendHelloSign()"
              >{{
                $t(resources.SendContract.i18n) || resources.SendContract.name
              }}</vs-button
            >
            <vs-button
              v-if="canUpdate"
              color="primary"
              type="filled"
              icon-pack="feather"
              icon="icon-dollar-sign"
              class="mt-4 ml-2"
              @click="SendIOU()"
              >{{
                $t(resources.SendIOU.i18n) || resources.SendIOU.name
              }}</vs-button
            >
            <vs-button
              v-if="canUpdate && newMemberCode != '' && newMemberCode != 0"
              color="primary"
              type="filled"
              icon-pack="feather"
              icon="icon-edit"
              class="mt-4 ml-2"
              @click="generateOnlyIfx()"
              >{{
                $t(resources.Generate.i18n) || resources.Generate.name
              }}</vs-button
            >
            <vs-button
              color="primary"
              type="filled"
              icon-pack="feather"
              icon="icon-mail"
              class="mt-4 ml-2"
              @click="sendWelcome()"
              >{{
                $t(resources.SendWelcome.i18n) || resources.SendWelcome.name
              }}</vs-button
            >
            <vs-button
              v-if="canUpdate"
              color="primary"
              type="filled"
              icon-pack="feather"
              icon="icon-credit-card"
              class="mt-4 ml-2"
              @click="createEwallet()"
              >{{
                $t(resources.CreateEwallet.i18n) || resources.CreateEwallet.name
              }}</vs-button
            >
          </vs-col>
        </vs-row>
        <vs-tabs>
          <vs-tab
            :label="$t(resources.Comments.i18n) || t(resources.Comments.name)"
            icon-pack="feather"
            icon="icon-message-circle"
          >
            <comments
              v-if="purchaseId != 0 && purchaseId != undefined"
              :purchaseId="`${purchaseId}`"
              :currentStage="stageId"
            ></comments>
          </vs-tab>
          <vs-tab
            :label="$t(resources.Tracking.i18n) || t(resources.Tracking.name)"
            icon-pack="feather"
            icon="icon-list"
          >
            <tracking
              v-if="purchaseId != 0 && purchaseId != undefined"
              :purchaseId="`${purchaseId}`"
              :currentStage="stageId"
            ></tracking>
          </vs-tab>
          <vs-tab
            style="align-text: right"
            :label="$t(resources.Email.i18n) || t(resources.Email.name)"
            icon-pack="feather"
            icon="icon-mail"
          >
            <newMessage
              :identifier="purchaseId"
              :entityId="other.entity.purchase"
              :type="2"
              :emailTo="emailIta"
            />
          </vs-tab>
        </vs-tabs>
      </vx-card>
    </div>
    <vs-row vs-type="flex" vs-justify="flex-end">
      <!-- Descriptions -->
      <vs-button
        color="rgb(174,174,174)"
        type="filled"
        @click="$router.push('/purchases/index')"
        class="mt-2"
        >{{ $t(resources.Return.i18n) || resources.Return.name }}</vs-button
      >
    </vs-row>
    <vs-popup
      :title="$t(resources.Detail.i18n) || resources.Detail.i18n"
      :active.sync="popupResMer"
    >
      <h1 class="text-center text-primary font-bold mb-base">
        {{ $t(resources.Answer.i18n) || resources.Answer.i18n }}:
      </h1>
      <p class="text-center mb-base">{{ responseMerchant }}</p>
    </vs-popup>
    <vs-popup
      :title="$t(resources.Update.i18n) || resources.Update.i18n"
      :active.sync="popupEdit"
    >
      <div class="vx-row">
        <div class="vx-col w-full md:w-1/2 mb-2">
          <label class="vs-input--label">{{
            $t(resources.Stage.i18n) || resources.Stage.name
          }}</label>
          <v-select
            v-model="stagePayment"
            :clearable="false"
            :options="stagePaymentList"
            :reduce="(x) => x.id"
            label="name"
            scrollable
          />
        </div>
        <div class="vx-col md:w-1/2 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-hash"
              label="ID"
              v-model="identifierPurchase"
              v-validate="'required|numeric'"
            />
          </div>
        </div>
        <div class="vx-col mb-base mt-5" style="text-align: right">
          <vs-button
            color="primary"
            type="filled"
            @click="updateIdentifierStage()"
          >
            {{ $t(resources.Save.i18n) || resources.Save.name }}
          </vs-button>
        </div>
      </div>
    </vs-popup>
    <vs-popup
      :title="$t(resources.Update.i18n) || resources.Update.i18n"
      :active.sync="popupEditOrder"
    >
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full mb-2">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-hash"
              :label="
                $t(resources.OrderNumber.i18n) || resources.OrderNumber.name
              "
              v-model="orderNumber"
              v-validate="'required|numeric'"
            />
          </div>
        </div>
        <div class="vx-col mb-base mt-5" style="text-align: right">
          <vs-button
            color="primary"
            type="filled"
            @click="updateOrderAndStage()"
          >
            {{ $t(resources.Save.i18n) || resources.Save.name }}
          </vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import axios from "axios";
import resources from "@/i18n/resources.js";
import database from "@/assets/resources/enums/database.json";
import other from "@/assets/resources/enums/other.json";
import status from "@/assets/resources/enums/status.json";
import vSelect from "vue-select";
import * as lang from "vuejs-datepicker/src/locale";
import alerts from "@/i18n/alerts.js";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import billing from "@/views/purchases/billing.vue";
import tracking from "../purchases/tracking.vue";
import comments from "../purchases/comments.vue";
import newMessage from "../email/newMessage.vue";

export default {
  components: {
    vSelect,
    FormWizard,
    TabContent,
    billing,
    tracking,
    comments,
    newMessage,
  },
  data() {
    return {
      urlVision: process.env.VUE_APP_VISION,
      database: database,
      other: other,
      status: status,
      resources: resources,
      alerts: alerts,
      isNavOpen: false,
      document: "",
      name: "",
      lastName: "",
      address: "",
      email: "",
      phone: "",
      enroller: "",
      sponsor: "",
      sponsorIsDisabled: true,
      presenter: "",
      isAllpresenter: true,
      sponsorList: [],
      enrollerList: [],
      presenterList: [],
      sideId: 1,
      sideOption: [
        { value: 1, text: `${this.$i18n.t("Left")}` },
        { value: 2, text: `${this.$i18n.t("Right")}` },
      ],
      businessCenterOption: [
        { value: 1, text: `${this.$i18n.t("Center")} 1` },
        { value: 2, text: `${this.$i18n.t("Center")} 2` },
        { value: 3, text: `${this.$i18n.t("Center")} 3` },
      ],
      genderOption: [
        { value: 1, text: this.$i18n.t("Female") },
        { value: 2, text: this.$i18n.t("Male") },
        { value: 3, text: this.$i18n.t("Other") },
      ],
      businessCenterId: 1,
      lado: "",
      zip: "31178",
      countryList: [],
      indicativeId: 0,
      identifiacionTypeList: [],
      documentTypeId: 0,
      countryId: 0,
      stateCountry: [],
      stateId: 0,
      destinationList: [],
      cityId: 0,
      zipCode: "",
      beneficiaryName: "",
      beneficiaryLastName: "",
      language: this.$i18n.locale.toUpperCase(),
      languages: lang,
      birthDate: "",
      productList: [],
      countryOperationType: [],
      countryOperationId: 0,
      productSelect: [],
      productSelectId: 0,
      product: {},
      radioMembershipType: "",
      contactDto: {},
      msgAcquireMembership: "",
      wayToPayOption: [],
      wayToPayOptionId: 0,
      countryDto: {},
      creditcardPayment: {},
      creditcardOption: [],
      installmentNumberType: [],
      bankType: [],
      installmentNumberOption: [],
      purchaseId: 0,
      expirationYearOption: [],
      expirationMonthOption: [],
      currentYear: 0,
      currentMonth: 0,
      expirationYear: 0,
      expirationMonth: 0,
      billingData: [],
      transferPayment: {},
      eWalletPayment: {},
      accountNumberType: [],
      maxCvv: 0,
      isInstallmentsAllowed: false,
      installmentProductOption: [],
      installmentProductOptionId: 1,
      checkBox1: false,
      includeNeo: true,
      productPrice: {},
      products: [],
      membershipId: 0,
      authCode: 0,
      paymentList: [],
      isGenerated: 0,
      itaCode: 0,
      stageId: 0,
      stageName: "",
      birthDay: "",
      birthMonth: "",
      birthYear: "",
      dayList: [],
      monthList: [],
      yearList: [],
      paymentConfirmed: 0,
      newMemberCode: 0,
      newMemberId: 0,
      orderNumber: 0,
      msgSendContract: "",
      gender: "",
      emailSession: JSON.parse(localStorage.getItem("userInfo")).email,
      emailIta: "",
      popupResMer: false,
      popupEdit: false,
      responseMerchant: "",
      paymentId: "",
      stagePayment: "",
      stagePaymentList: [],
      identifierPurchase: 0,
      popupEditOrder: false,
      contactTypeId: 1,
      currencyId: 0,
      currencyCode: "",
      currencyList: [],
      currencyDto: {},
      isBusiness: false,
      creationDate: "",
      groups: [
        {
          id: 1,
          value: 3,
          name: "Paquete Madrid x2",
          price: 968,
          productId: 67
        },
        {
          id: 2,
          value: 3,
          name: "Paquete Madrid x1",
          price: 734,
          productId: 67
        },
        {
          id: 3,
          value: 6,
          name: "Paquete Las Vegas x2",
          price: 2298.72,
          productId: 67
        },
        {
          id: 4,
          value: 6,
          name: "Paquete Las Vegas x1",
          price: 1324.36,
          productId: 67
        }
      ],
      groupId: ""
    };
  },
  computed: {
    enabledButton() {
      let totalPay = 0;
      let products = this.billingData.filter(
        (x) => x.orderType == this.other.billingOrder.product
      );

      let _wayToPayTotal = this.billingData.filter(
        (x) => x.orderType == this.other.billingOrder.wayToPay
      );

      for (let index = 0; index < products.length; index++) {
        totalPay +=
          (products[index].productValue + products[index].productTax) /
          this.installmentProductOptionId;
      }

      if (_wayToPayTotal.length > 0) {
        let _payments = _wayToPayTotal.reduce(
          (sum, current) => sum + parseFloat(current.productValue),
          0
        );
        if (totalPay <= _payments && this.isGenerated == 0) return true;
        else return true;
        // return totalPay == _payments && this.isGenerated == 0 ? true : false;
      } else return false;
    },
    canUpdate() {
      let listEmails = [
        "a.gomez@visiontravel.net",
        "odalis@visiontravel.net",
        "d.lopez@visiontravelmail.net",
        "v.hernandez@visiontravelmail.net",
      ];
      let isAllow = listEmails.filter(
        (x) => x == this.emailSession.toLowerCase()
      );
      if (isAllow.length > 0) return true;

      return false;
    },
  },
  methods: {
    imageUrlAlt(event) {
      event.target.src =
        "https://cdn1.visiontravel.net/Images/products/VIVE001.png";
    },

    async getCountry() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Country/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (result.data) {
            this.countryList = result.data;
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    async getIdentifiacionType() {
      let country = this.countryOperationType.find(
        (x) => x.countryId == this.countryOperationId
      );
      let countryCode = country.countryCode;

      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }IdentificationType/GetByCountry/${this.$i18n.locale.toUpperCase()}/${countryCode}/${countryCode}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (result.data) {
            if (country.countryId == this.database.countryOperation.USA) {
              this.identifiacionTypeList = result.data.filter(
                (x) => x.id == this.database.identificationType.SSN
              );
            } else {
              this.identifiacionTypeList = result.data;
            }
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    async getStateByCountry(item) {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }State/GetByCountry/${this.$i18n.locale.toUpperCase()}/${item}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (result.data) this.stateCountry = result.data;
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    async getCityByState(item) {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }City/GetByState/${this.$i18n.locale.toUpperCase()}/${item}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (result.data) this.destinationList = result.data;
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    async getCountryOperation() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }CountryOperation/GetPccCountry/${this.$i18n.locale.toUpperCase()}/${
          this.database.application.OV
        }`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (result.data) {
            this.countryOperationType = result.data;
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    async getCurrency() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Currency/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (result.data) {
            this.currencyList = result.data;
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    async getProductByType(item) {
      this.productSelectId = "";
      this.installmentProductOptionId = 1;
      this.billingData = [];
      this.contactTypeId =
        this.radioMembershipType == "1"
          ? this.database.contactType.Ita
          : this.database.contactType.ClientIta;

      this.countryDto = this.countryOperationType.filter(
        (x) => x.countryId == this.countryOperationId
      )[0];

      this.currencyDto = this.currencyList.filter((x) => x.id == item)[0];

      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Product/Prices/${this.$i18n.locale.toUpperCase()}/${
          this.countryDto.countryCode
        }/${this.currencyDto.code}/${this.contactTypeId}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (result.data) {
            this.products = result.data;
            this.productSelect = result.data.filter(
              (x) =>
                x.code != "NEO001" &&
                x.code != "PRM001" &&
                x.code != "PRM003" &&
                x.code != "UPVIVE03" &&
                x.name.indexOf("Autoship")
            );
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      await this.getIdentifiacionType();
    },

    setInstallment(item) {
      let _installment = this.product.installments.find(
        (x) => x.number == item
      );

      this.productPrice = _installment;
      this.billingData = [
        {
          productId: this.product.id,
          productName: this.product.name,
          currencyCode: this.product.currencyCode,
          orderType: this.other.billingOrder.product,
          productValue: _installment.price,
          productCode: this.product.code,
          productTax: _installment.other,
          imageUrl: this.product.imageUrl,
        },
      ];
      if (this.radioMembershipType == "1") {
        var membership = this.products.find((x) => x.code == "PRM001");
       // var neo = this.products.find((x) => x.code == "NEO001");
        this.billingData.push({
          productId: membership.id,
          productName: membership.name,
          currencyCode: membership.currencyCode,
          orderType: this.other.billingOrder.product,
          productValue: membership.installments.find((x) => x.number == item)
            .price,
          productTax: membership.installments.find((x) => x.number == item)
            .other,
          productCode: membership.code,
        });
        
        if (
          this.checkBox1 &&
          (this.purchaseId == null || this.purchaseId == 0)
        ) {
          let _group = this.groups.find(x => x.id == this.groupId);

          this.billingData.push({
            productId: _group.productId,
            productName: _group.name,
            currencyCode: "USD",
            orderType: this.other.billingOrder.product,
            productValue: _group.price,
            productTax: 0,
            productCode: "GRP"
          });
        }

        this.productPrice = _installment;

        if (this.product.installmentsAllowed.length > 1) {
          this.isInstallmentsAllowed = true;
          this.installmentProductOption = this.product.installmentsAllowed;
        } else {
          this.isInstallmentsAllowed = false;
          this.installmentProductOption = [];
        }

        // if (this.includeNeo) {
        //   this.billingData.push({
        //     productId: neo.id,
        //     productName: neo.name,
        //     currencyCode: neo.currencyCode,
        //     orderType: this.other.billingOrder.product,
        //     productValue: neo.installments.find((x) => x.number == item).price,
        //     productTax: neo.installments.find((x) => x.number == item).other,
        //     productCode: neo.code,
        //   });
        // }
      }
    },

    selectInstallment(groupId) {
      let _installment = this.groups.find(x => x.id == groupId).value;
      this.installmentProductOption = this.installmentProductOption.filter(
        x => x.number == _installment
      );
      this.installmentProductOptionId = _installment;
      this.checkBox1 = true;
      //this.setInstallment(_installment);
    },

    setProductMembership(item) {
      this.installmentProductOptionId = 1;
      let _product = this.productSelect.filter((x) => x.id == item);
      this.product = _product[0];
      // if (_product != null) {
      //   let _installment = this.product.installments[0];

        // this.billingData = [
        //   {
        //     productId: this.product.id,
        //     productName: this.product.name,
        //     currencyCode: this.product.currencyCode,
        //     orderType: this.other.billingOrder.product,
        //     productValue: _installment.price,
        //     productTax: _installment.other,
        //     productCode: this.product.code,
        //     imageUrl: this.product.imageUrl,
        //   },
        // ];

        // if (this.radioMembershipType == "1") {
        //   var membership = this.products.find((x) => x.code == "PRM001");
        //   //var neo = this.products.find((x) => x.code == "NEO001");
        //   this.billingData.push({
        //     productId: membership.id,
        //     productName: membership.name,
        //     currencyCode: membership.currencyCode,
        //     orderType: this.other.billingOrder.product,
        //     productValue: membership.installments.find(
        //       (x) => x.number == this.installmentProductOptionId
        //     ).price,
        //     productTax: membership.installments.find(
        //       (x) => x.number == this.installmentProductOptionId
        //     ).other,
        //     productCode: membership.code,
        //   });
        //   // if (this.includeNeo) {
        //   //   this.billingData.push({
        //   //     productId: neo.id,
        //   //     productName: neo.name,
        //   //     currencyCode: neo.currencyCode,
        //   //     orderType: this.other.billingOrder.product,
        //   //     productValue: neo.installments.find(
        //   //       (x) => x.number == this.installmentProductOptionId
        //   //     ).price,
        //   //     productTax: neo.installments.find(
        //   //       (x) => x.number == this.installmentProductOptionId
        //   //     ).other,
        //   //     productCode: neo.code,
        //   //   });
        //   // }
        // }
        // this.productPrice = _installment;

        // if (this.product.installmentsAllowed.length > 1) {
        //   this.isInstallmentsAllowed = true;
        //   this.installmentProductOption = this.product.installmentsAllowed;
        // } else {
        //   this.isInstallmentsAllowed = false;
        //   this.installmentProductOption = [];
        // }
    },

    async setContact() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Ita/${this.$i18n.locale.toUpperCase().toUpperCase()}/${this.itaCode}`,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (result.data.id != null) {
            this.contactDto = {
              id: result.data.id,
              name: result.data.name,
              lastName: result.data.lastName,
              email: result.data.email,
              photoURL: result.data.imageUrl,
              gender: result.data.gender,
              itaCode: this.itaCode,
              countryCode: result.data.countryOperationDefault.countryCode,
              address: result.data.address != null ? result.data.address : "",
              birthDate: result.data.birthDate,
              phone: result.data.phone,
              nationalityName: result.data.nationalityName,
              countryName: result.data.countryName,
              documentNumber: result.data.documentNumber,
              stateName: result.data.stateName,
              cityName: result.data.cityName,
              language: result.data.language.toLowerCase(),
            };
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    async getWayToPay() {
      let data = {
        applicationId: this.database.application.OV,
        countryCode: this.countryDto.countryCode,
        productId: this.product.id,
        supplierId: this.database.supplier.visionTravel,
        language: this.$i18n.locale.toUpperCase().toUpperCase(),
        currencyCode: this.product.currencyCode,
      };

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}WayToPay/GetAvailable`,
        data: data,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.wayToPayOption = [];
          } else {
            this.wayToPayOption = result.data.wayToPay;
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
          this.merchantOption = [];
        }
      );
    },

    setWayToPay(item) {
      let _merchantType = this.wayToPayOption.filter(
        (x) => x.wayToPayId == item
      );
      if (item == 2)
        this.bankType = _merchantType[0].options[0].banks.map((x) => x);
      if (item == 3)
        this.creditcardOption = _merchantType[0].options.map((x) => x);
    },

    setInstallmentNumber(item) {
      let _creditcard = this.creditcardOption.filter((x) => x.cardId == item);
      this.maxCvv = _creditcard[0].maxCvv;
      this.installmentNumberOption = _creditcard[0].installmentNumber;
    },

    async createPayment() {
      this.$vs.loading();
      let _wayToPayTotal = 0;
      let _productTotal = 0;
      let _otherValue = 0;
      let _taxesValue = 0;
      if (this.billingData.length > 0) {
        if (
          this.billingData.filter(
            (x) => x.orderType == this.other.billingOrder.wayToPay
          ).length > 0
        ) {
          _wayToPayTotal = this.billingData
            .filter((x) => x.orderType == this.other.billingOrder.wayToPay)
            .reduce(
              (sum, current) => sum + parseFloat(current.productValue),
              0
            );
        }

        if (
          this.billingData.filter(
            (x) => x.orderType == this.other.billingOrder.product
          ).length > 0
        ) {
          _productTotal = this.billingData
            .filter((x) => x.orderType == this.other.billingOrder.product)
            .reduce(
              (sum, current) => sum + parseFloat(current.productValue),
              0
            );
        }

        _otherValue = this.productPrice.other;
        _taxesValue = this.productPrice.taxes;
      }

      let _data = {};
      let _payment = {};
      if (this.purchaseId) _data.purchaseId = this.purchaseId;
      else _data.purchaseId = 0;

      _data.language = this.$i18n.locale.toUpperCase();
      _data.currencyCode = this.product.currencyCode;
      _data.countryCode = this.countryDto.countryCode;
      _data.contactId = this.contactDto.id;
      _data.itaCode = this.itaCode;
      _data.applicationId = this.database.application.OV;
      _data.supplierId = this.database.supplier.visionTravel;
      _data.createdBy = this.emailSession;
      _data.purchaseTypeId = 1;
      _data.products = [];
      _data.wayToPayId = this.wayToPayOptionId;
      _data.rateValue = this.product.rateValue;
      _data.taxesValue = _taxesValue;
      _data.otherValue = _otherValue;
      _data.installment = 1;

      let productList = this.billingData.filter(
        (x) => x.orderType == this.other.billingOrder.product
      );
      for (let x = 0; x < productList.length; x++) {
        _data.products.push(productList[x].productId);
      }

      if (this.wayToPayOptionId == this.database.merchantType.creditCard) {
        _payment.creditCardTypeId = this.creditcardPayment.creditCardTypeId;
        _payment.cardNumber = this.creditcardPayment.cardNumber;
        _payment.securityCode = this.creditcardPayment.cardSecurityCode;
        _payment.documentNumber = this.creditcardPayment.documentNumber;
        _payment.name = this.creditcardPayment.cardHolderName;
        _payment.lastName = this.creditcardPayment.cardholderLastName;
        _payment.address = this.creditcardPayment.address;
        _payment.zipCode = this.creditcardPayment.zipCode;
        _payment.email = this.creditcardPayment.email;
        _payment.amount = this.creditcardPayment.amount;
        _payment.currencyCode = this.product.currencyCode;
        _payment.expirationMonth = this.expirationMonth;
        _payment.expirationYear = this.expirationYear;
        _payment.transactionId = 0;
        _wayToPayTotal += this.creditcardPayment.amount;
        _data.isPurchase = _productTotal == _wayToPayTotal;
        if (
          this.creditcardPayment.installmentNumber &&
          this.creditcardPayment.installmentNumber != 0
        )
          this.installmentNumber = this.creditcardPayment.installmentNumber;
        else this.installmentNumber = 1;
        _payment.installmentNumber = this.installmentNumber;
      } else if (this.wayToPayOptionId == this.database.merchantType.transfer) {
        _payment.accountId = this.transferPayment.bankAccountId;
        _payment.documentId = this.transferPayment.documentId;
        _payment.referenceCode = this.transferPayment.referenceCode;
        _payment.amount = this.transferPayment.amount;
        _payment.currencyCode = this.product.currencyCode;
        _payment.language = this.$i18n.locale.toUpperCase();
        _wayToPayTotal += this.transferPayment.amount;
        _data.isPurchase = _productTotal == _wayToPayTotal;
      } else if (this.wayToPayOptionId == this.database.merchantType.EWallet) {
        _payment.WalletId = 6;
        _payment.userName = this.eWalletPayment.itaCodeWallet;
        _payment.amount = this.eWalletPayment.amount;
        _payment.language = this.$i18n.locale.toUpperCase();
        _payment.charge = false;
      }

      _data.payment = _payment;

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Membership/BuyProduct`,
        data: _data,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        async (result) => {
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgPaymentMade"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.sendEmail(result.data.data.purchaseId);
            if (
              this.purchaseId == 0 ||
              this.purchaseId == undefined ||
              this.purchaseId == null
            ) {
              await this.createRecord(result.data.data.purchaseId);
            }
            this.purchaseId = result.data.data.purchaseId;
            this.authCode = result.data.data.payResult.allocationNumber;

            this.addPay(_data);
            this.getPayments();
            if (this.wayToPayOptionId == this.database.merchantType.transfer)
              this.getuploadForm(
                result.data.data.purchaseId,
                result.data.data.paymentId
              );
            this.$vs.loading.close();
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
          this.$vs.loading.close();
        }
      );
      this.$vs.loading.close();
    },

    async getuploadForm(purchaseId, paymentId) {
      this.$vs.loading();
      let formData = new FormData();
      formData.set("purchaseId", purchaseId);
      formData.set("paymentId", paymentId);
      formData.set("language", this.$i18n.locale.toUpperCase());
      formData.append(
        "file",
        this.transferPayment.file,
        this.transferPayment.file.Name
      );

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Membership/AddAttachment`,
        data: formData,
        headers: {
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
          "Content-Type": "multipart/form-data",
        },
      }).then(
        (result) => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },

    async generate() {
      this.$vs.loading();

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_MEMBERSHIP}affiliation/generate`,
        data: {
          purchaseId: this.purchaseId,
          createdBy: this.emailSession,
          email: this.email,
          membershipId: this.membershipId,
        },
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale.toUpperCase(),
        },
      }).then(
        async (result) => {
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          } else if (result.data.success && result.data.code == 200) {
            this.$vs.dialog({
              color: "warning",
              title: this.$i18n.t("Alert"),
              text: result.data.message,
            });
          } else {
            await this.getStage();
            let response = result.data.data;
            this.stageId = response.stageId;
            this.stageName = this.stageList.find(
              (x) => x.id == response.stageId
            ).name;
            this.newMemberCode = response.newMemberCode;
            this.newMemberId = response.newMemberId;
            this.orderNumber = response.orderNumber;
            this.isGenerated = 1;

            this.msgSendContract = this.$i18n
              .t("MsgSendContract")
              .replace("{0}", this.email)
              .replace("{1}", `${this.name} ${this.lastName}`);
            this.popupWelcome = true;
            await this.sendWelcome();
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );

      this.$vs.loading.close();
    },

    async generateOnlyIfx() {
      this.$vs.loading();

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_MEMBERSHIP}affiliation/generateOnlyIfx`,
        data: {
          purchaseId: this.purchaseId,
          createdBy: this.emailSession,
        },
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale.toUpperCase(),
        },
      }).then(
        async (result) => {
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          } else {
            let response = result.data.data;
            this.newMemberCode = response.newMemberCode;
            this.orderNumber = response.orderNumber;
            this.isGenerated = 1;
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );

      this.$vs.loading.close();
    },

    async getStage() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_OVAPI
        }Stage/${this.$i18n.locale.toUpperCase()}/4`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.stageList = [];
          } else {
            this.stageList = result.data;
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    addPay(item) {
      let _merchantType = this.wayToPayOption.filter(
        (x) => x.wayToPayId == item.wayToPayId
      );
      this.billingData.push({
        productId: this.product.id,
        productName: _merchantType[0].name,
        currencyCode: this.product.currencyCode,
        orderType: this.other.billingOrder.wayToPay,
        productValue: item.payment.amount,
        productCode: this.product.code,
      });
    },

    async getYearsCrediCard() {
      var currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, "/");
      this.currentYear = parseInt(currentDate.split("/")[0]);
      this.currentMonth = parseInt(currentDate.split("/")[1]);

      this.expirationYearOption = [];
      this.expirationMonthOption = [];
      var iYear = 0;
      for (let item = this.currentYear; iYear <= 20; item++) {
        iYear++;
        this.expirationYearOption.push(item);
      }

      for (let item = this.currentMonth; item <= 12; item++) {
        this.expirationMonthOption.push(item);
      }
    },

    async expirationYearChange(year) {
      var yearSelect = year;
      this.expirationMonthOption = [];

      if (this.currentYear === yearSelect) {
        for (let item = this.currentMonth; item <= 12; item++) {
          this.expirationMonthOption.push(item);
        }
      } else {
        for (let item = 1; item <= 12; item++) {
          this.expirationMonthOption.push(item);
        }
      }
    },

    validBasicInformation() {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          if (
            this.membershipId != 0 &&
            this.membershipId != null &&
            this.membershipId != "" &&
            this.stageId != this.status.genealogy.Terminated
          ) {
            await this.updateRecord();
          } else if (
            (this.membershipId == 0 ||
              this.membershipId == null ||
              this.membershipId == "") &&
            this.purchaseId != 0 &&
            this.purchaseId != null &&
            this.purchaseId != "" &&
            this.stageId != this.status.genealogy.Terminated
          ) {
            await this.createRecord(this.purchaseId);
          }
          await this.validateSponsor();
          this.$refs.checkoutWizard.nextTab();
        } else {
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: this.$i18n.t("MsgRequiredField"),
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      });
    },

    setAccountNumber(item) {
      let _account = this.bankType
        .filter((x) => x.id == item)
        .map((x) => x.accounts);
      this.accountNumberType = _account[0].map((x) => x);
    },

    onFileSelected(event) {
      this.transferPayment.file = event.target.files[0];
    },

    deleteBilling() {
      this.installmentProductOptionId = 1;
      this.billingData = [];
      this.productSelectId = "";
      this.countryOperationId = "";
    },

    deleteNeo() {
      if (
        this.includeNeo &&
        this.billingData.filter((x) => x.productCode == "NEO001").length == 0
      ) {
        var neo = this.products.find((x) => x.code == "NEO001");
        this.billingData.push({
          productId: neo.id,
          productName: neo.name,
          currencyCode: neo.currencyCode,
          orderType: this.other.billingOrder.product,
          productValue: neo.installments.find(
            (x) => x.number == this.installmentProductOptionId
          ).price,
          productTax: neo.installments.find(
            (x) => x.number == this.installmentProductOptionId
          ).other,
          productCode: neo.code,
        });
      } else if (!this.includeNeo) {
        this.billingData.splice(2, 1);
      }
    },

    async createRecord(purchaseId) {
      let _data = {};
      _data.isBu;
      _data.type = this.radioMembershipType;
      _data.purchaseId = purchaseId;
      _data.itaCode = this.contactDto.itaCode;
      _data.countryOperationId = this.countryOperationId;
      _data.cityId = this.cityId;
      _data.indicativeId = this.indicativeId;
      _data.documentType = this.documentTypeId;
      _data.document = this.document;
      _data.name = this.name;
      _data.lastName = this.lastName;
      _data.birthDate = `${this.birthYear}-${this.birthMonth}-${this.birthDay}`;
      _data.email = this.email;
      _data.address = this.address;
      _data.zipCode = this.zipCode;
      _data.phone = this.phone;
      _data.beneficiaryName = this.beneficiaryName;
      _data.beneficiaryLastName = this.beneficiaryLastName;
      _data.sponsor = this.sponsor;
      _data.center = this.businessCenterId;
      _data.sideId = this.sideId;
      _data.enroller = this.enroller;
      _data.presenter = this.presenter;
      _data.isAllpresenter = this.isAllpresenter ? 1 : 0;
      _data.installmentNumber = this.installmentProductOptionId;
      _data.createdBy = this.emailSession;

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_MEMBERSHIP}Membership/createRecord`,
        data: _data,
        headers: {
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          } else {
            this.membershipId = result.data.insertId;
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    async getMembership() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_MEMBERSHIP}affiliation/getByPurchase/${this.purchaseId}`,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale.toUpperCase(),
        },
      }).then(
        async (result) => {
          if (result.data.data.length == 0) {
            this.$vs.dialog({
              color: "warning",
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
            });
          } else {
            let response = result.data.data;
            // let detail = response.detail.filter(
            //   (x) => x.productId == this.database.products.NEO001
            // );

            // this.includeNeo = detail.length > 0 ? true : false;
            this.radioMembershipType = response.type;
            this.isBusiness = response.isBusiness;
            this.countryId = response.countryId;
            this.countryOperationId = response.countryOperationId;
            this.currencyId = response.currencyId;
            await this.getProductByType(this.currencyId);

            await this.getStateByCountry(this.countryId);
            this.stateId = response.stateId;

            await this.getCityByState(this.stateId);

            this.productSelectId = response.productId;
            await this.setProductMembership(this.productSelectId);

            let _group = response.detail.filter(x => x.productCode == "GRP");
            if (_group.length > 0) {
              this.groupId = this.groups.find(x => x.price == _group[0].value);
            }
            response.detail.forEach((element) => {
              this.billingData.push({
                productId: element.productId,
                productName: element.productName,
                currencyCode: response.currencyCode,
                orderType: this.other.billingOrder.product,
                productValue: element.value * element.amount,
                productTax: 0,
                productCode: element.productCode,
                priceLevel: response.priceLevel,
                imageUrl: `https://cdn1.visiontravel.net/Images/products/VIVE001.png`,
              });
            });
            this.billingData[0].productTax = response.additionalValue;

            this.installmentProductOptionId = response.installmentNumber;
            if (response.installmentNumber > 1) {
              this.checkBox1 = true;
              //this.setInstallment(this.installmentProductOptionId);
            }

            this.itaCode = response.itaCode;
            this.membershipId = response.id;
            this.documentTypeId = response.identificationTypeId;
            this.document = response.identification;
            this.name = response.name;
            this.lastName = response.lastName;
            this.birthDay = response.birthDay;
            this.birthMonth = response.birthMonth;
            this.birthYear = response.birthYear;
            this.gender = response.gender;
            this.address = response.address;
            this.zipCode = response.zipCode;
            this.cityId = response.cityId;
            this.email = response.email;
            this.indicativeId = response.countryIndicativeId;
            this.phone = response.phone;
            this.beneficiaryName = response.beneficiaryName;
            this.beneficiaryLastName = response.beneficiaryLastName;
            this.enroller = response.enroller;
            this.sponsor = response.sponsorCode;
            this.businessCenterId = response.businessCenter;
            this.sideId = response.side;
            this.presenter = response.presenterCode;
            this.isAllpresenter = response.allPresenter == 1 ? true : false;
            this.paymentList = response.payment;
            this.isGenerated = response.isGenerated;
            this.stageId = response.stageId;
            this.stageName = response.stageName;
            this.newMemberCode = response.newMemberCode;
            this.newMemberId = response.newMemberId;
            this.orderNumber = response.orderNumber;
            this.emailIta = response.emailIta;
            this.creationDate = response.creationDate;
            await this.validateSponsor();

            this.billingData.push({
              productId: 0,
              productName: this.$i18n.t("Payments"),
              currencyCode: response.currencyCode,
              orderType: this.other.billingOrder.wayToPay,
              productValue: response.totalPayment,
              productCode: this.product.code,
            });
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    validPayment() {
      this.$validator.validateAll().then((result) => {
        if (
          this.wayToPayOptionId == this.database.merchantType.transfer &&
          (this.transferPayment.file == null || this.transferPayment.file == "")
        ) {
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: this.$i18n.t("MsgNotAttachment"),
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        } else if (!result) {
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: this.$i18n.t("MsgRequiredField"),
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        } else {
          this.createPayment();
        }
      });
    },

    async sendEmail(purchaseId) {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}NeoTool/Invitation`,
        data: {
          itaCode: this.contactDto.itaCode,
          url: "",
          urltext: this.$i18n.t("VisitUs"),
          subject: `[${this.$i18n.t("Membership")}] ITA ${
            this.contactDto.itaCode
          }`,
          message: `IdCompra: ${purchaseId} - ${this.$i18n.t("Customer")}: ${
            this.name
          } ${this.lastName} `,
          language: this.$i18n.locale.toUpperCase(),
          guestList: ["a.gomez@visiontravel.net", "odalis@visiontravelmail.net"],
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      });
    },

    async validateSponsor() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_OVAPI}validate`,
        data: {
          infotoken: localStorage.getItem("tokenInfo"),
          distid: this.sponsor,
        },
        headers: {
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          if (result.data) {
            this.sponsorIsDisabled =
              result.data.Dist_Status != "D" ? true : false;
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    async getPayments() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_MEMBERSHIP}payment/getByPurchase/${this.purchaseId}`,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale.toUpperCase(),
        },
      }).then(
        (result) => {
          if (result.data != null) {
            this.paymentList = result.data.data;
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    acceptDialog() {
      this.$router.push({ path: "/purchases/index" });
    },

    async sendHelloSign() {
      this.$vs.loading();
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_HELLOSIGN
        }membership/sendForm/${this.$i18n.locale.toUpperCase()}/${
          this.purchaseId
        }`,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale,
        },
      }).then(
        (result) => {
          if (result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgEmailSent"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },

    async SendIOU() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_HELLOSIGN}membership/sendIOU/${this.purchaseId}`,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale,
        },
      }).then(
        (result) => {
          if (result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgEmailSent"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    openDocument(url) {
      window.open(url);
    },

    async sendWelcome() {
      this.$vs.loading();
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Membership/Welcome`,
        data: {
          language: this.$i18n.locale.toUpperCase().toUpperCase(),
          purchaseId: this.purchaseId,
          itaCode: this.newMemberCode,
          contactId: this.newMemberId,
          isIta: true,
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgEmailSent"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          }
          this.$vs.loading.close();
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
          this.$vs.loading.close();
        }
      );
    },

    async createEwallet() {
      this.$vs.loading();
      let cityName = this.destinationList.find((x) => x.id == this.cityId).name;
      let stateName = this.stateCountry.find((x) => x.id == this.stateId).name;
      let countryName = this.countryList.find((x) => x.id == this.countryId)
        .name;
      let _data = {};

      _data.newMemberCode = this.newMemberCode;
      _data.name = this.name;
      _data.lastName = this.lastName;
      _data.identification = this.document;
      _data.email = this.email;
      _data.address = this.address;
      _data.stageName = stateName;
      _data.countryName = countryName;
      _data.cityName = cityName;
      _data.zipCode = this.zipCode.length < 5 ? "00000" : this.zipCode;
      _data.countryOperationISO = this.countryDto.countryCodeIso2;
      _data.phone = this.phone;
      _data.birthDay = this.birthDay;
      _data.birthMonth = this.birthMonth;
      _data.birthYear = this.birthYear;

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_MEMBERSHIP}payout/createAccount`,
        data: _data,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale.toUpperCase(),
        },
      }).then(
        (result) => {
          if (result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          }
          this.$vs.loading.close();
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
          this.$vs.loading.close();
        }
      );
    },

    getBirtDate() {
      let dates = lang[this.$i18n.locale];
      let year = new Date().getFullYear() - 18;

      for (let index = 1; index < 32; index++) {
        this.dayList.push({
          value: index < 10 ? `0${index}` : index,
          text: index < 10 ? `0${index}` : index,
        });
      }

      for (let index = 0; index < dates._months.length; index++) {
        let value = index + 1 < 10 ? `0${index + 1}` : index + 1;
        this.monthList.push({ value: value, text: dates._months[index] });
      }

      for (let index = 0; index < 81; index++) {
        this.yearList.push({ value: year - index, text: year - index });
      }
    },

    async updateRecord() {
      this.$vs.loading();
      let _data = {};

      _data.id = this.membershipId;
      _data.type = this.radioMembershipType;
      _data.purchaseId = this.purchaseId;
      _data.itaCode = this.contactDto.itaCode;
      _data.countryOperationId = this.countryOperationId;
      _data.cityId = this.cityId;
      _data.indicativeId = this.indicativeId;
      _data.documentType = this.documentTypeId;
      _data.document = this.document;
      _data.gender = this.gender;
      _data.name = this.name;
      _data.lastName = this.lastName;
      _data.birthDate = `${this.birthYear}-${this.birthMonth}-${this.birthDay}`;
      _data.email = this.email;
      _data.address = this.address;
      _data.zipCode = this.zipCode;
      _data.phone = this.phone;
      _data.beneficiaryName = this.beneficiaryName;
      _data.beneficiaryLastName = this.beneficiaryLastName;
      _data.sponsor = this.sponsor;
      _data.center = this.businessCenterId;
      _data.sideId = this.sideId;
      _data.enroller = this.enroller;
      _data.presenter = this.presenter;
      _data.isAllpresenter = this.isAllpresenter ? 1 : 0;
      _data.installmentNumber = this.installmentProductOptionId;

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_MEMBERSHIP}affiliation/update`,
        data: _data,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale.toUpperCase(),
        },
      }).then(
        (result) => {
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          } else {
            this.membershipId = result.data.insertId;
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );

      this.$vs.loading.close();
    },

    async getItaByText(origin) {
      let getElementId;
      if (origin == "sponsor")
        getElementId = document.getElementById("sponsor");
      else if (origin == "enroller")
        getElementId = document.getElementById("enroller");
      else if (origin == "presenter")
        getElementId = document.getElementById("presenter");

      let text = getElementId.__vue__.search;

      if (text.length >= 3) {
        await axios({
          method: "GET",
          url: `${
            process.env.VUE_APP_OVAPI
          }Contact/getByText/${this.$i18n.locale.toUpperCase()}/${text}`,
          headers: {
            "content-type": "application/json",
            Authorization: `bearer ${localStorage.getItem("loggedIn")}`,
          },
        }).then(
          (result) => {
            if (!result.data) {
              this.$vs.notify({
                title: this.$i18n.t("Alert"),
                text: this.$i18n.t("MsgNotFoundInfo"),
                color: "warning",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle",
              });
              this.sponsorList = [];
            } else {
              if (origin == "sponsor") this.sponsorList = result.data;
              else if (origin == "enroller") this.enrollerList = result.data;
              else if (origin == "presenter") this.presenterList = result.data;
            }
          },
          (error) => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError") + "<br/>" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          }
        );
      }
    },

    async paymentEwallet(itaCode, referenceId) {
      this.$vs.loading();
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_MEMBERSHIP}payout/validStatus/${itaCode}/${referenceId}`,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale.toUpperCase(),
        },
      }).then(
        async (result) => {
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            await this.getMembership();
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },

    async paymentTransfer(stageId, referenceId) {
      this.$vs.loading();
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_MEMBERSHIP}payment/updateStage/${stageId}/${referenceId}`,
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale.toUpperCase(),
        },
      }).then(
        async (result) => {
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            await this.getMembership();
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },
    async getStagePayment() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_OVAPI}Stage/${this.$i18n.locale}/2`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (!result.data) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.stagePaymentList = [];
          } else {
            this.stagePaymentList = result.data;
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    async updateIdentifierStage() {
      this.$vs.loading();
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_MEMBERSHIP}payment/updateIdentifierStage`,
        data: {
          id: this.paymentId,
          stageId: this.stagePayment,
          identifier: this.identifierPurchase,
        },
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale.toUpperCase(),
        },
      }).then(
        async (result) => {
          this.$vs.loading.close();
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.popupEdit = false;
            await this.getMembership();
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },

    async updateOrderAndStage() {
      this.$vs.loading();
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_MEMBERSHIP}purchase/updateOrderAndStage`,
        data: {
          stageId: this.stageId,
          purchaseId: this.purchaseId,
          orderNumber: this.orderNumber,
        },
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale.toUpperCase(),
        },
      }).then(
        async (result) => {
          this.$vs.loading.close();
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.popupEditOrder = false;
            await this.getMembership();
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },

    openResponseMerchant(text) {
      this.responseMerchant = text;
      this.popupResMer = true;
    },

    openEditPay(stageId, id) {
      this.identifierPurchase = this.purchaseId;
      this.stagePayment = stageId;
      this.paymentId = id;
      this.popupEdit = true;
    },
  },
  async created() {
    this.$vs.loading();
    this.purchaseId = this.$route.params.purchaseId;
    await this.getBirtDate();
    await this.getCountry();
    await this.getCountryOperation();
    await this.getCurrency();
    await this.getYearsCrediCard();

    if (this.purchaseId != undefined && this.purchaseId != 0) {
      await this.getMembership();
      await this.setContact();
      await this.getStagePayment();
    }
    this.$emit("init");
    this.$vs.loading.close();
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/pages/profile.scss";
</style>
