<template>
  <div>
    <vx-card
      class="mb-2"
      :title="$t(resources.Filters.i18n) || resources.Filters.name"
    >
      <div class="vx-row">
        <div class="vx-col md:w-1/4 w-full mb-2">
          <label class="vs-input--label">{{
            $t(resources.StartDate.i18n) || resources.StartDate.name
          }}</label>
          <datepicker
            :language="languages[language]"
            format="d MMMM yyyy"
            v-model="startDate"
            v-validate="'required'"
          ></datepicker>
        </div>
        <div class="vx-col md:w-1/4 w-full mb-2">
          <label class="vs-input--label">{{
            $t(resources.EndDate.i18n) || resources.EndDate.name
          }}</label>
          <datepicker
            :language="languages[language]"
            format="d MMMM yyyy"
            v-model="endDate"
            v-validate="'required'"
          ></datepicker>
        </div>
        <div class="vx-col mb-2 mt-5">
          <vs-button
            color="primary"
            type="filled"
            class="mr-1"
            @click="validBasicInformation()"
            >{{ $t(resources.Search.i18n) || resources.Search.name }}</vs-button
          >
          <vs-button
            color="success"
            type="filled"
            @click="popupActiveCreate = true"
            >{{ $t(resources.Add.i18n) || resources.Add.name }}</vs-button
          >
        </div>
      </div>
    </vx-card>

    <div id="ag-grid-demo mt-5" style="padding-top: 7px">
      <vx-card
        :title="$t(resources.Promotion.i18n) || resources.Promotion.name"
      >
        <div class="flex flex-wrap justify-between items-center">
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left"></div>
          <div
            class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
          >
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              :placeholder="$t(resources.Search.i18n) || resources.Search.name"
            />
            <vs-button
              class="mb-4 md:mb-0 mr-1"
              @click="gridApi.exportDataAsCsv()"
            >
              {{ $t(resources.ExportAs.i18n) || resources.ExportAs.name }}
            </vs-button>
          </div>
        </div>

        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="listData"
          rowSelection="single"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          @row-clicked="onRowClicked"
        ></ag-grid-vue>
        <vs-pagination
          :total="totalNumberPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
    <vs-popup
      :title="$t(resources.Detail.i18n) || resources.Detail.name"
      :active.sync="popupActive"
    >
      <div class="vx-row mb-base">
        <div class="vx-col md:w-1/2 w-full mb-2">
          <label class="vs-input--label">{{
            $t(resources.StartDate.i18n) || resources.StartDate.name
          }}</label>
          <datepicker
            :language="languages[language]"
            format="d MMMM yyyy"
            v-model="promotionDto.startDate"
          ></datepicker>
        </div>
        <div class="vx-col md:w-1/2 w-full mb-2">
          <label class="vs-input--label">{{
            $t(resources.EndDate.i18n) || resources.EndDate.name
          }}</label>
          <datepicker
            :language="languages[language]"
            format="d MMMM yyyy"
            v-model="promotionDto.endDate"
          ></datepicker>
        </div>
        <div class="vx-col w-full mb-2">
          <vs-textarea
            :label="
              $t(resources.Description.i18n) || t(resources.Description.name)
            "
            class="vx-col w-full mb-4"
            v-model="promotionDto.description"
            maxlength="500"
          />
        </div>
        <div class="vx-col w-full mb-2">
          <vs-switch color="success" v-model="promotionDto.active">
            <span slot="on">{{
              $t(resources.Yes.i18n) || resources.Yes.name
            }}</span>
            <span slot="off">{{
              $t(resources.No.i18n) || resources.No.name
            }}</span>
          </vs-switch>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2 mt-5">
          <vs-button color="primary" type="filled" @click="update()">
            {{ $t(resources.Save.i18n) || resources.Save.name }}
          </vs-button>
        </div>
      </div>
    </vs-popup>
    <vs-popup
      :title="$t(resources.Create.i18n) || resources.Create.name"
      :active.sync="popupActiveCreate"
    >
      <div class="vx-row mb-base">
        <div class="vx-col md:w-1/2 w-full mb-2">
          <label class="vs-input--label">{{
            $t(resources.StartDate.i18n) || resources.StartDate.name
          }}</label>
          <datepicker
            :language="languages[language]"
            format="d MMMM yyyy"
            v-model="promotionCreateDto.startDate"
          ></datepicker>
        </div>
        <div class="vx-col md:w-1/2 w-full mb-2">
          <label class="vs-input--label">{{
            $t(resources.EndDate.i18n) || resources.EndDate.name
          }}</label>
          <datepicker
            :language="languages[language]"
            format="d MMMM yyyy"
            v-model="promotionCreateDto.endDate"
          ></datepicker>
        </div>
        <div class="vx-col w-full mb-2">
          <vs-textarea
            :label="
              $t(resources.Description.i18n) || t(resources.Description.name)
            "
            class="vx-col w-full mb-4"
            v-model="promotionCreateDto.description"
            maxlength="500"
          />
        </div>
        <div class="vx-col w-full mb-2">
          <vs-switch color="success" v-model="promotionCreateDto.active">
            <span slot="on">{{
              $t(resources.Yes.i18n) || resources.Yes.name
            }}</span>
            <span slot="off">{{
              $t(resources.No.i18n) || resources.No.name
            }}</span>
          </vs-switch>
        </div>
        <div class="vx-col w-full md:w-1/6 mb-2 mt-5">
          <vs-button color="primary" type="filled" @click="create()">
            {{ $t(resources.Save.i18n) || resources.Save.name }}
          </vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import moment from "moment";

export default {
  components: {
    AgGridVue,
    Datepicker,
  },
  data() {
    return {
      language: this.$i18n.locale.toUpperCase(),
      languages: lang,
      startDate: new Date(),
      endDate: new Date(),
      popupActive: false,
      promotionDto: {},
      popupActiveCreate: false,
      promotionCreateDto: {},
      resources: resources,
      alerts: alerts,
      activeConfirm: false,
      listData: [],
      totalNumberPages: 0,
      filterData: {},
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 10,
      gridApi: null,
      rows: 20,
      NumberPage: 0,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: this.$i18n.t("Description"),
          field: "description",
          filter: true,
          width: 400,
        },
        {
          headerName: this.$i18n.t("StartDate"),
          field: "startDate",
          filter: true,
        },
        {
          headerName: this.$i18n.t("EndDate"),
          field: "endDate",
          filter: true,
        },
        {
          headerName: this.$i18n.t("CreationDate"),
          field: "creationDate",
          filter: true,
          width: 200,
          cellRenderer: function (event) {
            return moment(event.node.data.creationDate).format(
              "YYYY-MM-DD hh:mm:ss"
            );
          },
        },
        {
          headerName: this.$i18n.t("Active"),
          field: "active",
          cellRenderer: function (event) {
            if (event.node.data.active)
              return '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-success), 1);">check_circle</i>';
            else
              return '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-danger), 1);">highlight_off</i>';
          },
        },
        {
          headerName: this.$i18n.t("CreatedBy"),
          field: "createdBy",
          filter: true,
          width: 220,
        },
      ],
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("level", null);
      } else this.gridOptions.columnApi.setColumnPinned("level", "left");
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 20;
    },
    totalPages() {
      if (this.totalNumberPages)
        return this.totalNumberPages.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.NumberPage = val;
        if (
          localStorage.getItem("tokenVT") != null &&
          localStorage.getItem("tokenVT") != undefined
        ) {
          this.validBasicInformation();
        }
      },
    },
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    validBasicInformation() {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          if (
            this.endDate != "" &&
            this.endDate != null &&
            this.startDate != null &&
            this.startDate != ""
          ) {
            await this.get();
          }
        } else {
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: this.$i18n.t("MsgRequiredField"),
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      });
    },

    async get() {
      let start_Date = "";
      let end_Date = "";

      if (this.startDate != null && this.endDate != null) {
        start_Date = moment(this.startDate).format("YYYY-MM-DD");
        end_Date = moment(this.endDate).format("YYYY-MM-DD");
      }

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Promotion/ListByFilters`,
        data: {
          searchKeys: {
            startDate: start_Date,
            endDate: end_Date,
          },
          page: 1,
          rows: 20,
          language: this.$i18n.locale.toUpperCase(),
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.listData = [];
          } else {
            this.listData = result.data.data;
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    onRowClicked(event) {
      this.promotionDto = event.node.data;
      this.popupActive = true;
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },

    async update() {
      this.$vs.loading();
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Promotion/Update`,
        data: {
          id: this.promotionDto.id,
          description: this.promotionDto.description,
          startDate: moment(this.promotionDto.startDate).format("YYYY-MM-DD"),
          endDate: moment(this.promotionDto.endDate).format("YYYY-MM-DD"),
          active: this.promotionDto.active,
          language: this.$i18n.locale.toUpperCase(),
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-check",
            });
            this.popupActive = false;
            this.get();
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },
    async create() {
      this.$vs.loading();
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Promotion/Create`,
        data: {
          description: this.promotionCreateDto.description,
          startDate: moment(this.promotionCreateDto.startDate).format(
            "YYYY-MM-DD"
          ),
          endDate: moment(this.promotionCreateDto.endDate).format("YYYY-MM-DD"),
          active: this.promotionCreateDto.active,
          createdBy: JSON.parse(localStorage.getItem("userInfo")).email,
          language: this.$i18n.locale.toUpperCase(),
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-check",
            });
            this.popupActiveCreate = false;
            this.get();
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },
  },
  async created() {
    this.$vs.loading();
    await this.get();
    this.$vs.loading.close();
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
};
</script>
