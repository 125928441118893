var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vx-card", [
        _c(
          "div",
          [
            _c("div", { staticClass: "vx-row" }, [
              _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-6" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        "icon-pack": "feather",
                        icon: "icon-user",
                        "icon-no-border": "",
                        label:
                          _vm.$t(_vm.resources.Name.i18n) ||
                          _vm.resources.Name.name
                      },
                      model: {
                        value: _vm.billingData.name,
                        callback: function($$v) {
                          _vm.$set(_vm.billingData, "name", $$v)
                        },
                        expression: "billingData.name"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-6" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        "icon-pack": "feather",
                        icon: "icon-user",
                        "icon-no-border": "",
                        label:
                          _vm.$t(_vm.resources.LastName.i18n) ||
                          _vm.resources.LastName.name
                      },
                      model: {
                        value: _vm.billingData.lastName,
                        callback: function($$v) {
                          _vm.$set(_vm.billingData, "lastName", $$v)
                        },
                        expression: "billingData.lastName"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-6" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        "icon-pack": "feather",
                        icon: "icon-mail",
                        "icon-no-border": "",
                        type: "email",
                        label:
                          _vm.$t(_vm.resources.Email.i18n) ||
                          _vm.resources.Email.name
                      },
                      model: {
                        value: _vm.billingData.email,
                        callback: function($$v) {
                          _vm.$set(_vm.billingData, "email", $$v)
                        },
                        expression: "billingData.email"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "vx-col md:w-1/4 w-full" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        "icon-pack": "feather",
                        icon: "icon-smartphone",
                        "icon-no-border": "",
                        label:
                          _vm.$t(_vm.resources.Mobile.i18n) ||
                          _vm.resources.Mobile.name
                      },
                      model: {
                        value: _vm.billingData.phone,
                        callback: function($$v) {
                          _vm.$set(_vm.billingData, "phone", $$v)
                        },
                        expression: "billingData.phone"
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col md:w-1/4 w-full" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Country.i18n) ||
                          _vm.resources.Country.name
                      )
                    )
                  ]),
                  _c("v-select", {
                    attrs: {
                      clearable: false,
                      options: _vm.countryList,
                      reduce: function(country) {
                        return country.id
                      },
                      label: "name",
                      hint: "" + _vm.billingData.countryId
                    },
                    on: {
                      input: function($event) {
                        return _vm.getStateByCountry(
                          "" + _vm.billingData.countryId
                        )
                      }
                    },
                    model: {
                      value: _vm.billingData.countryId,
                      callback: function($$v) {
                        _vm.$set(_vm.billingData, "countryId", $$v)
                      },
                      expression: "billingData.countryId"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col md:w-1/4 w-full mb-6" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.State.i18n) ||
                          _vm.resources.State.name
                      )
                    )
                  ]),
                  _c("v-select", {
                    attrs: {
                      clearable: false,
                      options: _vm.stateCountry,
                      reduce: function(state) {
                        return state.id
                      },
                      label: "name",
                      hint: "" + _vm.billingData.stateId
                    },
                    on: {
                      input: function($event) {
                        return _vm.getCityByState("" + _vm.billingData.stateId)
                      }
                    },
                    model: {
                      value: _vm.billingData.stateId,
                      callback: function($$v) {
                        _vm.$set(_vm.billingData, "stateId", $$v)
                      },
                      expression: "billingData.stateId"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col md:w-1/4 w-full mb-6" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Destination.i18n) ||
                          _vm.resources.Destination.name
                      )
                    )
                  ]),
                  _c("v-select", {
                    attrs: {
                      clearable: false,
                      options: _vm.destinationList,
                      reduce: function(city) {
                        return city.id
                      },
                      label: "name"
                    },
                    model: {
                      value: _vm.billingData.cityId,
                      callback: function($$v) {
                        _vm.$set(_vm.billingData, "cityId", $$v)
                      },
                      expression: "billingData.cityId"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-6" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        "icon-pack": "feather",
                        icon: "icon-map",
                        "icon-no-border": "",
                        label:
                          _vm.$t(_vm.resources.Address.i18n) ||
                          _vm.resources.Address.name
                      },
                      model: {
                        value: _vm.billingData.address,
                        callback: function($$v) {
                          _vm.$set(_vm.billingData, "address", $$v)
                        },
                        expression: "billingData.address"
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _c("div", { staticClass: "vx-row" }, [
              _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-6" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        "icon-pack": "feather",
                        icon: "icon-hash",
                        "icon-no-border": "",
                        label:
                          _vm.$t(_vm.resources.ZipCode.i18n) ||
                          _vm.resources.ZipCode.name
                      },
                      model: {
                        value: _vm.billingData.zipCode,
                        callback: function($$v) {
                          _vm.$set(_vm.billingData, "zipCode", $$v)
                        },
                        expression: "billingData.zipCode"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "vx-col md:w-1/4 w-full mb-6" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.DocumentType.i18n) ||
                          _vm.resources.DocumentType.name
                      )
                    )
                  ]),
                  _c("v-select", {
                    attrs: {
                      clearable: false,
                      options: _vm.identifiacionTypeList,
                      reduce: function(item) {
                        return item.id
                      },
                      label: "name"
                    },
                    model: {
                      value: _vm.billingData.documentTypeId,
                      callback: function($$v) {
                        _vm.$set(_vm.billingData, "documentTypeId", $$v)
                      },
                      expression: "billingData.documentTypeId"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-6" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        "icon-pack": "feather",
                        icon: "icon-hash",
                        "icon-no-border": "",
                        label:
                          _vm.$t(_vm.resources.DocumentNumber.i18n) ||
                          _vm.resources.DocumentNumber.name
                      },
                      model: {
                        value: _vm.billingData.documentNumber,
                        callback: function($$v) {
                          _vm.$set(_vm.billingData, "documentNumber", $$v)
                        },
                        expression: "billingData.documentNumber"
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _c(
              "vs-row",
              { attrs: { "vs-type": "flex", "vs-justify": "flex-end" } },
              [
                _vm.isCreateBillingData
                  ? _c(
                      "vs-button",
                      {
                        staticClass: "mr-0 mb-2",
                        on: {
                          click: function($event) {
                            return _vm.createBillingData(_vm.billingData)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.Save.i18n) ||
                              _vm.resources.Save.name
                          )
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.allowEdit ||
                _vm.emailSession == "a.holguin@visiontravelmail.net" ||
                  _vm.emailSession == "l.palacio@visiontravelmail.net"
                  ? _c(
                      "vs-button",
                      {
                        staticClass: "mr-0 mb-2",
                        on: {
                          click: function($event) {
                            return _vm.updateBillingData(_vm.billingData)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.Save.i18n) ||
                              _vm.resources.Save.name
                          )
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }