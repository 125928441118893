<template>
  <div>
    <div id="ag-grid-demo">
      <vx-card>
        <h4 class="font-semibold mb-base">
          {{ $t(resources.SupplierDestination.i18n) || resources.SupplierDestination.name }}
        </h4>
        <div class="flex flex-wrap justify-between items-center">
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2">
                  {{
                    currentPage * paginationPageSize - (paginationPageSize - 1)
                  }}
                  -
                  {{
                    accessData.length - currentPage * paginationPageSize > 0
                      ? currentPage * paginationPageSize
                      : accessData.length
                  }}
                  of {{ accessData.length }}
                </span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
          <div
            class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
          >
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              :placeholder="$t(resources.Search.i18n) || resources.Search.name"
            />
            <vs-button
              class="mb-4 md:mb-0 mr-1"
              @click="gridApi.exportDataAsCsv()"
            >
              {{ $t(resources.ExportAs.i18n) || resources.ExportAs.name }}
            </vs-button>
            <vs-button
              color="success"
              class="mb-4 md:mb-0"
              @click="popupCreate = true"
            >
              {{ $t(resources.Create.i18n) || resources.Create.name }}
            </vs-button>
          </div>
        </div>

        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="accessData"
          rowSelection="single"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          @row-clicked="onRowClicked"
        ></ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
    <!--Popup Update-->
    <div class="centerx">
      <vs-popup
        classContent="popup overFlow"
        :title="$t(resources.Update.i18n) || resources.Update.name"
        :active.sync="popupUpdate"
      >
      <div class="vx-row mb-base">
        
        <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.Country.i18n) || resources.Country.name
            }}</label>
            <v-select
              v-model="supplierDestination.countryId"
              :clearable="false"
              :options="countryList"
              :reduce="(country) => country.id"
              label="name"
              v-on:input="getStateByCountry(`${supplierDestination.countryId}`)"
              :hint="`${supplierDestination.countryId}`"
            />
          </div>

          <div class="vx-col w-full">
            <label class="vs-input--label">{{
              $t(resources.State.i18n) || resources.State.name
            }}</label>
            <v-select
              v-model="supplierDestination.stateId"
              :clearable="false"
              :options="stateCountry"
              :reduce="(state) => state.id"
              label="name"
              v-on:input="getCityByState(`${supplierDestination.stateId}`)"
              :hint="`${supplierDestination.stateId}`"
            />
          </div>
          <div class="vx-col w-full mb-4">
            <label class="vs-input--label">{{
              $t(resources.Destination.i18n) || resources.Destination.name
            }}</label>
            <v-select
              v-model="destinationId"
              :clearable="false"
              :options="destinationList"
              :reduce="(city) => city.id"
              label="name"
            />
          </div>
          <div class="vx-col w-full mb-2" v-if="supplierDestination.createdBy !='AidaPackage'">
          <vs-input
            type="text"
            class="w-full"
            icon-pack="feather"
            icon="icon-user"
            icon-no-border
            :label="
              $t(resources.Value.i18n) || resources.Value.name
            "
            v-model="supplierDestination.valueProvider"
            
          />
        </div>
        <div class="vx-col w-full mb-4" v-else>
          <h6>{{$t(resources.Value.i18n) || resources.Value.name}}</h6>
          <p>{{supplierDestination.valueProvider}}</p>
        </div>
      </div>
        <div class="vx-col mb-2 mt-5" style="text-align: right">
          <vs-button color="primary" type="filled" @click="update()">
            {{ $t(resources.Save.i18n) || resources.Save.name }}
          </vs-button>
        </div>
      </vs-popup>
    </div>

    <!--Popup create-->
    <div class="centerx">
      <vs-popup
        classContent="popup overFlow"
        :title="$t(resources.Create.i18n) || resources.Create.name"
        :active.sync="popupCreate"
      >
      <div class="vx-row mb-base">

        <div class="vx-col w-full">
          <label class="vs-input--label">{{
            $t(resources.SupplierName.i18n) || resources.SupplierName.name
          }}</label>
          <v-select
            v-model="supplierDestinationCreate.supplierId"
            :clearable="false"
            :options="supplierOption"
            :reduce="(supplier) => supplier.id"
            label="name"
          />
        </div>
        
        <div class="vx-col w-full">
          <label class="vs-input--label">{{
            $t(resources.Country.i18n) || resources.Country.name
          }}</label>
          <v-select
            v-model="supplierDestinationCreate.countryId"
            :clearable="false"
            :options="countryList"
            :reduce="(country) => country.id"
            label="name"
            v-on:input="getStateByCountry(`${supplierDestinationCreate.countryId}`)"
            :hint="`${supplierDestinationCreate.countryId}`"
          />
        </div>

        <div class="vx-col w-full">
          <label class="vs-input--label">{{
            $t(resources.State.i18n) || resources.State.name
          }}</label>
          <v-select
            v-model="supplierDestinationCreate.stateId"
            :clearable="false"
            :options="stateCountry"
            :reduce="(state) => state.id"
            label="name"
            v-on:input="getCityByState(`${supplierDestinationCreate.stateId}`)"
            :hint="`${supplierDestinationCreate.stateId}`"
          />
        </div>
        <div class="vx-col w-full mb-4">
          <label class="vs-input--label">{{
            $t(resources.Destination.i18n) || resources.Destination.name
          }}</label>
          <v-select
            v-model="supplierDestinationCreate.destinationId"
            :clearable="false"
            :options="destinationList"
            :reduce="(city) => city.id"
            label="name"
          />
        </div>
        <div class="vx-col w-full mb-2">
          <vs-input
            type="text"
            class="w-full"
            icon-pack="feather"
            icon="icon-user"
            icon-no-border
            :label="
              $t(resources.Value.i18n) || resources.Value.name
            "
            v-model="supplierDestinationCreate.valueProvider"
          />
        </div>
      </div>
        <div class="vx-col mb-2 mt-5" style="text-align: right">
          <vs-button color="primary" type="filled" @click="create()">
            {{ $t(resources.Save.i18n) || resources.Save.name }}
          </vs-button>
        </div>
      </vs-popup>
    </div>
  </div>
</template>
<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import vSelect from "vue-select";

export default {
  components: {
    AgGridVue,
    vSelect,
  },
  data() {
    return {
      resources: resources,
      alerts: alerts,
      language: this.$i18n.locale,
      popupUpdate: false,
      popupCreate: false,
      supplierOption:[],
      supplierDestination:{},
      supplierDestinationCreate:{},
      email: JSON.parse(localStorage.getItem("userInfo")).email,
      destinationList:[],
      stateCountry:[],
      countryList:[],
      destinationId:0,
      accessData: [],
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [
        {
          headerName: this.$i18n.t("Identifier"),
          field: "destinationId",
          filter: true,
          width: 200,
        },
        {
          headerName: this.$i18n.t("Value"),
          field: "valueProvider",
          filter: true,
          width: 200,
        },
        {
          headerName: this.$i18n.t("SupplierName"),
          field: "supplierName",
          filter: true,
          width: 200,
        },
        {
          headerName: this.$i18n.t("Destination"),
          field: "destinationName",
          filter: true,
          width: 200,
        },
        {
          headerName: this.$i18n.t("State"),
          field: "stateName",
          filter: true,
          width: 200,
        },
        {
          headerName: this.$i18n.t("Country"),
          field: "countryName",
          filter: true,
          width: 200,
        },
        {
          headerName: this.$i18n.t("CreatedBy"),
          field: "createdBy",
          filter: true,
          width: 200,
        },
      ],
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("level", null);
      } else this.gridOptions.columnApi.setColumnPinned("level", "left");
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 20;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      },
    },
  },
  created: function () {
    this.getCountry();
    this.getSupplier();
    this.get();
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    async getSupplier() {
      this.$vs.loading();
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Supplier`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (result.data) {
            this.supplierOption = result.data;
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.dataList = [];
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },
    async getStateByCountry(item) {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }State/GetByCountry/${this.$i18n.locale.toUpperCase()}/${item}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (result.data) this.stateCountry = result.data;
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },

    async getCityByState(item) {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }City/GetByState/${this.$i18n.locale.toUpperCase()}/${item}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (result.data) this.destinationList = result.data;
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },
    async getCountry() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Country/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (result.data) {
            this.countryList = result.data;
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },
    async onRowClicked(event) {
      this.supplierDestination = event.node.data;
      this.destinationId = this.supplierDestination.destinationId;
      if(this.supplierDestination != null && this.supplierDestination.countryId != 0){
          this.getStateByCountry(`${this.supplierDestination.countryId}`);
          this.getCityByState(`${this.supplierDestination.stateId}`);
      }
      this.popupUpdate = true;
    },
    async get() {
      this.$vs.loading();
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}SupplierDestination/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (result.data) {
            this.accessData = result.data;
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.dataList = [];
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },

    async update() {
        this.$vs.loading();
        let _value = "";
        if(this.supplierDestination.createdBy =="AidaPackage"){
            let _valueArray = this.supplierDestination.valueProvider.split(',');
            if(_valueArray.length > 0)
                _value = _valueArray[1];
            else
                _value = _valueArray[0];
        }
        else
        _value = this.supplierDestination.valueProvider;
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}SupplierDestination/Update`,
        data: {
          id: this.supplierDestination.id,
          destinationId: this.destinationId,
          value: _value,
          language: this.$i18n.locale.toUpperCase()
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        async (result) => {
          if (result.data.success) {
            await this.get();
            this.popupUpdate = false;

            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.dataList = [];
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },
    async create() {      
      this.$vs.loading();

      await axios({
        method: "POST",
        url: process.env.VUE_APP_APIVT + "SupplierDestination",
        data: {
          createdBy: this.email,
          destinationId: this.supplierDestinationCreate.destinationId,
          providerId: this.supplierDestinationCreate.supplierId,
          valueProvider: this.supplierDestinationCreate.valueProvider,
        },
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("tokenVT"),
        },
      }).then(
        (result) => {
          if (result.data == null) {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError"),
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-check",
            });
            setTimeout(() => {
              this.$vs.loading.close();
              window.location.reload();
            }, 1000);
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },

    openConfirm(event) {
      this.nodeId = event.node.data.Id;
      this.nodeItaCode = event.node.data.CodigoIta;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: this.$i18n.t("Confirm"),
        text: this.$i18n
          .t("MsgDisabledQuestion")
          .replace("{0}", event.node.data.CodigoIta),
        accept: this.inactive,
      });
    },

    async inactive() {
      this.$vs.loading();

      await axios({
        method: "POST",
        url: process.env.VUE_APP_OVAPI + "inactiveAccess",
        data: {
          id: this.nodeId,
          createdBy: this.email,
          distid: this.nodeItaCode,
        },
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("loggedIn"),
        },
      }).then(
        (result) => {
          if (result.data.code == 500) {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgError"),
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-check",
            });
            setTimeout(() => {
              this.$vs.loading.close();
              window.location.reload();
            }, 1000);
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
      this.$vs.loading.close();
    },

    async getProductByType() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Product/Prices/${this.$i18n.locale.toUpperCase()}/USA/USD/1`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`,
        },
      }).then(
        (result) => {
          if (result.data) {
            this.productSelect = result.data.filter(
              (x) =>
                x.code != "NEO001" &&
                x.code != "PRM001" &&
                x.code != "PRM003" &&
                x.code != "UPVIVE03" &&
                x.name.indexOf("Autoship")
            );
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
};
</script>
