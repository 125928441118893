<template>
  <div>
    <div>
      <vx-card class="mb-4">
        <h4 class="mb-4">
          {{ $t(resources.Filters.i18n) || resources.Filters.name }}
        </h4>
        <div class="vx-row">
          <div class="vx-col w-full md:w-1/5 mb-2">
            <label class="vs-input--label">
              {{ $t(resources.Date.i18n) || resources.Date.name }}
            </label>
            <datepicker
              :language="languages[language]"
              format="d MMMM yyyy"
              v-model="date"
            ></datepicker>
          </div>
          <!-- <div class="vx-col md:w-1/6 w-full mb-2">
            <label class="vs-input--label">{{
              $t(resources.Currency.i18n) || resources.Currency.name
            }}</label>
            <v-select
              :placeholder="$t(resources.Select.i18n) || resources.Select.name"
              v-model="currencyId"
              v-validate="'required'"
              :clearable="false"
              :options="currencyList"
              :reduce="item => item.id"
              label="text"
            />
          </div> -->

          <div class="vx-col mb-2 mt-5">
            <vs-button color="primary" type="filled" @click="getSummaryBooking()">{{
              $t(resources.Search.i18n) || resources.Search.name
            }}</vs-button>
          </div>
        </div>
      </vx-card>
      <vx-card class="mb-4">
        <h2
              class="text-center text-primary"
              v-if="summaryPayment.length > 0"
            >
              <strong>Resumen total pagos</strong>
            </h2>
            <vs-row vs-type="flex" vs-justify="center" class="mb-4">
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="3"
                vs-sm="3"
                vs-xs="12"
                class="mr-2 mb-2"
                :key="indextr"
                v-for="(item, indextr) in summaryPayment"
              >
                <vx-card card-background="success" content-color="#fff">
                  <h5 class="text-center text-white">
                    {{ item.currencyCode }}
                  </h5>
                  <h1 class="text-center text-white">
                    <strong>${{ item.total }}</strong>
                  </h1>
                </vx-card>
              </vs-col>
            </vs-row>
            <h2
              class="text-center text-primary"
              v-if="summaryBooking.length > 0"
            >
              <strong>Resumen total reservas</strong>
            </h2>
            <vs-row vs-type="flex" vs-justify="center" class="mb-4">
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="3"
                vs-sm="3"
                vs-xs="12"
                class="mr-2 mb-2"
                :key="indextr"
                v-for="(item, indextr) in summaryBooking"
              >
                <vx-card card-background="success" content-color="#fff">
                  <h5 class="text-center text-white">
                    {{ item.currencyCode }}
                  </h5>
                  <h1 class="text-center text-white">
                    <strong>${{ item.total }}</strong>
                  </h1>
                </vx-card>
              </vs-col>
            </vs-row>
      </vx-card>
      <div id="ag-grid-demo">
        <vx-card>
          <!-- TABLE ACTION ROW -->
          <div class="flex flex-wrap justify-between items-center">
            <!-- ITEMS PER PAGE -->
            <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
              <vs-dropdown vs-trigger-click class="cursor-pointer">
                <div
                  class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                  <span class="mr-2"
                    >{{
                      currentPage * paginationPageSize -
                        (paginationPageSize - 1)
                    }}
                    -
                    {{
                      dataList.length - currentPage * paginationPageSize > 0
                        ? currentPage * paginationPageSize
                        : dataList.length
                    }}
                    of {{ dataList.length }}</span
                  >
                  <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
                <vs-dropdown-menu>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                    <span>20</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                    <span>50</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                    <span>100</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                    <span>150</span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>

            <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
            <div
              class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
            >
              <vs-input
                class="mb-4 md:mb-0 mr-4"
                v-model="searchQuery"
                @input="updateSearchQuery"
                :placeholder="
                  $t(resources.Search.i18n) || resources.Search.name
                "
              />
              <vs-button
                class="mb-4 md:mb-0"
                @click="gridApi.exportDataAsCsv()"
              >
                {{ $t(resources.ExportAs.i18n) || resources.ExportAs.name }}
              </vs-button>
            </div>
          </div>
          <ag-grid-vue
            :gridOptions="gridOptions"
            class="ag-theme-material w-100 my-4 ag-grid-table"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :rowData="dataList"
            rowSelection="single"
            colResizeDefault="shift"
            :animateRows="true"
            :floatingFilter="true"
            :pagination="true"
            :paginationPageSize="paginationPageSize"
            :suppressPaginationPanel="true"
            @row-selected="onRowSelected"
          ></ag-grid-vue>
          <vs-pagination
            :total="totalPages"
            :max="maxPageNumbers"
            v-model="currentPage"
          />
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
//import vSelect from "vue-select";
import moment from "moment";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue,
    Datepicker
    //vSelect
  },
  data() {
    return {
      resources: resources,
      alerts: alerts,
      language: this.$i18n.locale,
      languages: lang,
      emailSession: JSON.parse(localStorage.getItem("userInfo")).email,
      date: new Date(),
      searchQuery: "",
      summaryPayment: [],
      summaryBooking: [],
      gridOptions: {},
      maxPageNumbers: 10,
      gridApi: null,
      rows: 20,
      NumberPage: 0,
      dataList: [],
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: "Booking Id",
          field: "bookingId",
          width: 100,
          filter: true
        },
        {
          headerName: this.$i18n.t("Stage"),
          field: "stageName",
          width: 120,
          filter: true
        },
        {
          headerName: this.$i18n.t("Currency"),
          field: "currencyCode",
          width: 100,
          filter: true
        },
        {
          headerName: this.$i18n.t("BookingCode"),
          field: "bookingCode",
          width: 150,
          filter: true
        },
        {
          headerName: this.$i18n.t("NetValue"),
          field: "net",
          width: 170,
          filter: true
        },
        {
          headerName: "Fee",
          field: "fee",
          width: 170,
          filter: true
        },
        {
          headerName: this.$i18n.t("AgentCommissionValue"),
          field: "agentCommissionValue",
          width: 170,
          filter: true
        },
        {
          headerName: this.$i18n.t("MerchantValue"),
          field: "merchant",
          width: 170,
          filter: true
        },
        {
          headerName: this.$i18n.t("BookingValue"),
          field: "bookingValue",
          width: 170,
          filter: true
        },
        {
          headerName: this.$i18n.t("CustomizedFee"),
          field: "customizedFee",
          width: 170,
          filter: true
        },
        {
          headerName: this.$i18n.t("Total"),
          field: "total",
          width: 170,
          filter: true
        },
        {
          headerName: this.$i18n.t("PaymentsReceived"),
          field: "totalPayments",
          width: 170,
          filter: true
        }
      ]
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("level", null);
      } else this.gridOptions.columnApi.setColumnPinned("level", "left");
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 50;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    }
  },
  async created() {
    await this.getSummaryBooking();
    //await this.getBooking();
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    async getSummaryBooking() {
      this.$vs.loading();
      let _date = "";

      if (this.date != null && this.date != null) {
        _date = moment(this.date).format("YYYY-MM-DD");
      }

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_ACCOUNTING}payment/getSummaryBooking`,
        data: { date: _date},
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      }).then(
        async result => {
          if (result.data.success && result.data.code == 204) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.summaryPayment = [];
            this.summaryBooking = [];
          } else {
            this.summaryPayment = result.data.data.filter(x => x.type == 1);
            this.summaryBooking = result.data.data.filter(x => x.type == 2);
            await this.getBooking();
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async getBooking() {
      this.$vs.loading();
      let _date = "";

      if (this.date != null && this.date != null) {
        _date = moment(this.date).format("YYYY-MM-DD");
      }

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_ACCOUNTING}payment/getBooking`,
        data: { date: _date},
        headers: {
          "content-type": "application/json",
          "accept-language": this.$i18n.locale
        }
      }).then(
        async result => {
          if (result.data.success && result.data.code == 204) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.dataList = [];
          } else {
            this.dataList = result.data.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    onRowSelected(event) {
      let bookingId = event.node.data.bookingId;
      this.$router.push({
        name: "booking-bookingdetail",
        params: {
          bookingId: bookingId
        }
      });
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  }
};
</script>
<style>
.includeIconOnly.large {
  width: 60px !important;
  height: 60px !important;
  font-size: 0.7em;
}

.vs-button.large {
  font-size: 3.25rem;
}

.vs-button.large .vs-button--icon {
  font-size: 3.25rem;
}
</style>
