<template>
  <div>
    <div id="ag-grid-demo">
      <vx-card class="mb-4">
        <vs-row vs-type="flex" vs-justify="center">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <vs-button
              radius
              color="success"
              icon-pack="feather"
              icon="icon-plus"
              size="large"
              @click="$router.push('/payment/settingsCreate').catch(() => {})"
            ></vs-button>
          </vs-col>
        </vs-row>

        <h4 class="mb-4">
          {{ $t(resources.Filters.i18n) || resources.Filters.name }}
        </h4>
        <div class="vx-row mb-2">
          <div class="vx-col w-full md:w-1/5 mb-2">
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                $t(resources.Application.i18n) || resources.Application.name
              }}</label>
              <v-select
                v-model="applicationId"
                :options="applicationsList"
                :reduce="item => item.id"
                label="name"
                v-validate="'required'"
                name="type"
              />
              <span class="text-danger text-sm" v-show="errors.has('type')">{{
                errors.first("type")
              }}</span>
            </div>
          </div>
          <div class="vx-col w-full md:w-1/5 mb-2">
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                $t(resources.Supplier.i18n) || resources.Supplier.name
              }}</label>
              <v-select
                v-model="supplierId"
                :clearable="true"
                :options="suppliersList"
                :reduce="item => item.id"
                label="name"
              />
            </div>
          </div>
          <div class="vx-col w-full md:w-1/5 mb-2">
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                $t(resources.Product.i18n) || resources.Product.name
              }}</label>
              <v-select
                v-model="productId"
                :options="productsList"
                :reduce="item => item.id"
                label="name"
                v-validate="'required'"
                name="product"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('product')"
                >{{ errors.first("product") }}</span
              >
            </div>
          </div>
          <div class="vx-col w-full md:w-1/5 mb-2">
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                $t(resources.Country.i18n) || resources.Country.name
              }}</label>
              <v-select
                v-model="countryOperationId"
                :clearable="true"
                :options="countriesList"
                :reduce="item => item.countryId"
                label="countryName"
              />
            </div>
          </div>
          <div class="vx-col w-full md:w-1/5 mb-2">
            <div class="vx-col w-full">
              <label class="vs-input--label">{{
                $t(resources.Type.i18n) || resources.Type.name
              }}</label>
              <v-select
                v-model="merchantType"
                :clearable="true"
                :options="merchantTypeList"
                :reduce="item => item.id"
                label="name"
              />
            </div>
          </div>
          <div class="vx-col w-full md:w-1/5">
            <vs-select
              class="vx-col w-full"
              :label="$t(resources.Status.i18n) || resources.Status.name"
              v-model="active"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="$t(item.i18n) || item.text"
                v-for="(item, index) in statusOption"
              />
            </vs-select>
          </div>
          <div class="vx-col w-full  md:w-1/5 mt-5">
            <vs-button
              color="primary"
              type="filled"
              class="mr-1"
              @click="get()"
            >
              {{ $t(resources.Search.i18n) || resources.Search.name }}
            </vs-button>
          </div>
        </div>
      </vx-card>

      <vx-card>
        <h4 class="font-semibold mb-base">
          {{ $t(resources.Detail.i18n) || resources.Detail.name }}
        </h4>
        <div class="flex flex-wrap justify-between items-center">
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2">
                  {{
                    currentPage * paginationPageSize - (paginationPageSize - 1)
                  }}
                  -
                  {{
                    dataList.length - currentPage * paginationPageSize > 0
                      ? currentPage * paginationPageSize
                      : dataList.length
                  }}
                  of {{ dataList.length }}
                </span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
          <div
            class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
          >
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              :placeholder="$t(resources.Search.i18n) || resources.Search.name"
            />
            <vs-button
              class="mb-4 md:mb-0 mr-1"
              @click="gridApi.exportDataAsCsv()"
            >
              {{ $t(resources.ExportAs.i18n) || resources.ExportAs.name }}
            </vs-button>
          </div>
        </div>

        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="dataList"
          rowSelection="single"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
        ></ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>
  </div>
</template>
<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import vSelect from "vue-select";
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import moment from "moment";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import database from "@/assets/resources/enums/database.json";

export default {
  components: {
    AgGridVue,
    vSelect
  },
  data() {
    return {
      resources: resources,
      alerts: alerts,
      language: this.$i18n.locale,
      database: database,
      popupUpdate: false,
      popupCreate: false,
      emailSession: JSON.parse(localStorage.getItem("userInfo")).email,
      dataList: [],
      merchantTypeList: [],
      suppliersList: [],
      countriesList: [],
      productsList: [],
      applicationsList: [],
      statusOption: [
        {
          text: "Active",
          value: true,
          i18n: "Active"
        },
        {
          text: "Inactive",
          value: false,
          i18n: "Inactive"
        }
      ],
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      supplierId: "",
      productId: "",
      merchantType: "",
      countryOperationId: "",
      applicationId: "",
      active: true,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: "#",
          field: "id",
          hide: true
        },
        {
          headerName: this.$i18n.t("Application"),
          field: "applicationName",
          filter: true,
          width: 150
        },
        {
          headerName: this.$i18n.t("WayToPay"),
          field: "wayToPay.merchantTypeName",
          filter: true,
          width: 200
        },
        {
          headerName: this.$i18n.t("Supplier"),
          field: "feature.providerName",
          filter: true,
          width: 200
        },
        {
          headerName: this.$i18n.t("Product"),
          field: "feature.productName",
          filter: true,
          width: 200
        },
        {
          headerName: this.$i18n.t("Country"),
          field: "wayToPay.countryCode",
          filter: true
        },
        {
          headerName: this.$i18n.t("Percent"),
          field: "isPercentage",
          width: 110,
          cellRenderer: function(event) {
            let object = "";
            if (event.node.data.isPercentage) {
              object =
                '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-success), 1);">check_circle</i>';
            } else
              object =
                '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-danger), 1);">highlight_off</i>';

            return object;
          }
        },
        {
          headerName: this.$i18n.t("Value"),
          field: "value",
          filter: true
        },
        {
          headerName: this.$i18n.t("ChargeIn"),
          field: "chargeInName",
          filter: true
        },
        {
          headerName: "Merchant",
          field: "wayToPay.merchantName",
          filter: true,
          width: 200
        },
        {
          headerName: this.$i18n.t("CreditCard"),
          field: "wayToPay.creditCardTypeName",
          filter: true,
          width: 200
        },
        {
          headerName: this.$i18n.t("AccountType"),
          field: "wayToPay.accountTypeName",
          filter: true,
          width: 200
        },
        {
          headerName: this.$i18n.t("Status"),
          field: "active",
          width: 110,
          cellRenderer: function(event) {
            let object = "";
            if (event.node.data.active) {
              object =
                '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-success), 1);">check_circle</i>';
            } else
              object =
                '<i class="vs-icon notranslate icon-scale material-icons small bg-small vs-icon-bg" style="color: rgba(var(--vs-danger), 1);">highlight_off</i>';

            return object;
          }
        },
        {
          headerName: this.$i18n.t("CreationDate"),
          field: "creationDate",
          filter: true,
          width: 200,
          cellRenderer: function(event) {
            return moment(event.node.data.creationDate).format(
              "YYYY-MM-DD hh:mm:ss"
            );
          }
        },
        {
          headerName: this.$i18n.t("CreatedBy"),
          field: "createdBy",
          filter: true,
          width: 220
        }
      ]
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("level", null);
      } else this.gridOptions.columnApi.setColumnPinned("level", "left");
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 20;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    }
  },
  async created() {
    await this.get();
    await this.getSupplier();
    await this.getMerchantType();
    await this.getCountries();
    await this.getProducts();
    await this.getApplications();
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    async get() {
      this.$vs.loading();

      let _keys = {
        active: this.active
      };

      if (this.applicationId != null && this.applicationId != "" && this.applicationId != 0)
        _keys["applicationid"] = this.applicationId;
      if (this.supplierId != null && this.supplierId != "" && this.supplierId != 0)
        _keys["supplierid"] = this.supplierId;
      if (this.productId != null && this.productId != "" && this.productId != 0)
        _keys["productid"] = this.productId;
      if (this.countryOperationId != null && this.countryOperationId != "" && this.countryOperationId != 0)
        _keys["countryoperationid"] = this.countryOperationId;
      if (this.merchantType != null && this.merchantType != "" && this.merchantType != 0)
        _keys["merchanttype"] = this.merchantType;

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}FeaturePaymentType/ListByFilters`,
        data: {
          searchKeys: _keys,
          page: 1,
          rows: 5000,
          language: "ES"
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
           if (result.data.data != null && result.data.data.length > 0) {
            this.dataList = result.data.data;
          } else {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: result.data.message,
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.dataList = [];
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    async getSupplier() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Supplier`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.suppliersList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getProducts() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Product/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.productsList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getMerchantType() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }MerchantType/GetAll/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
           if (result.data.data != null && result.data.data.length > 0) {
            this.merchantTypeList = result.data.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCountries() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }CountryOperation/GetPccCountry/${this.$i18n.locale.toUpperCase()}/1`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data.length > 0) {
            this.countriesList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getApplications() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Application`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.applicationsList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  }
};
</script>
