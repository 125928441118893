<template>
  <div class="vx-col w-full">
    <vx-card
      :title="
        $t(resources.Qualifications.i18n) || resources.Qualifications.name
      "
      class="mb-base"
    >
      <!-- Select with the ranks -->
      <div class="vx-row mb-4">
        <vs-select
          :label="$t(resources.Rank.i18n) || resources.Rank.name"
          v-model="rankId"
          @input="changeRanks"
          class="vx-col w-full lg:w-1/2 mb-2"
        >
          <vs-select-item
            :is-selected.sync="item.isSelected"
            :key="index"
            :value="item.value"
            :text="$t(item.i18n) || item.text"
            v-for="(item, index) in ranks"
          />
        </vs-select>
      </div>

      <!-- Business centers -->
      <vs-row class="mb-2" vs-w="12">
        <vs-col
          vs-offset="2"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="8"
          class="mb-2"
        >
          <h5>
            <strong>
              {{
              $t(resources.BusinessCenters.i18n) ||
              resources.BusinessCenters.name
              }}
            </strong>
          </h5>
        </vs-col>

        <!-- Icons with business center number -->
        <vs-col vs-offset="2" vs-type="flex" vs-justify="center" vs-align="center" vs-w="8">
          <vs-icon
            icon-pack="material-icons"
            icon="looks_one"
            size="large"
            v-bind:class="{
              active: 'text-dark',
              'text-success': nroCenters > 0
            }"
          ></vs-icon>
          <vs-icon
            icon-pack="material-icons"
            icon="looks_two"
            size="large"
            v-bind:class="{
              active: 'text-dark',
              'text-success': nroCenters > 1
            }"
          ></vs-icon>
          <vs-icon
            icon-pack="material-icons"
            icon="looks_3"
            size="large"
            v-bind:class="{
              active: 'text-dark',
              'text-success': nroCenters > 2
            }"
          ></vs-icon>
        </vs-col>
      </vs-row>

      <!-- Title "PersonalEnrollees" -->
      <vs-divider class="mb-2">
        <strong class="text-primary">
          {{
          $t(resources.PersonalEnrollees.i18n) ||
          resources.PersonalEnrollees.name
          }}:
        </strong>
      </vs-divider>

      <!-- Icons Left and right -->
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" class="mb-2">
        <vs-button
          color="success"
          type="flat"
          icon-pack="material-icons"
          icon="person"
          @click="getBQDetail('WRK27')"
        >
          <strong>{{ $t(resources.Left.i18n) || resources.Left.name }}</strong>
          ({{ enrolledLeft }})
        </vs-button>
        <vs-button
          color="success"
          type="flat"
          icon-pack="material-icons"
          icon="person"
          class="ml-2"
          @click="getBQDetail('WRK28')"
        >
          <strong>
            {{
            $t(resources.Right.i18n) || resources.Right.name
            }}
          </strong>
          ({{ enrolledRight }})
        </vs-button>
      </vs-col>

      <!-- Button "Enrolled Total" -->
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" class="mb-2">
        <vs-button color="rgb(187, 138, 200)" type="relief" @click="getEnrollers()">
          {{
          $t(resources.ViewEnrolledsTotal.i18n) ||
          resources.ViewEnrolledsTotal.name
          }}
        </vs-button>
      </vs-col>

      <!-- Information about cycles -->
      <vs-row vs-type="flex" vs-justify="center" vs-align="center">
        <vs-divider>
          <strong class="text-primary">
            {{
            $t(resources.RequiredEnrolleds.i18n) ||
            resources.RequiredEnrolleds.name
            }}
          </strong>
        </vs-divider>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          v-if="enrolledTotal > enrolledRequired"
          vs-w="12"
        >
          <div :key="enrolled" v-for="(tr, enrolled) in enrolledRequired">
            <vs-icon
              icon-pack="material-icons"
              icon="emoji_people"
              size="large"
              color="rgb(234, 181, 70)"
            ></vs-icon>
          </div>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          v-else-if="enrolledRequired > enrolledTotal"
          class="mb-4"
        >
          <div :key="enrolled1" v-for="(tr, enrolled1) in enrolledRequired">
            <vs-icon
              icon-pack="material-icons"
              icon="emoji_people"
              size="large"
              v-if="enrolled1 >= enrolledTotal"
              color="rgb(174,174,174)"
            ></vs-icon>
            <vs-icon
              icon-pack="material-icons"
              icon="emoji_people"
              size="large"
              v-else
              color="rgb(234, 181, 70)"
            ></vs-icon>
          </div>
        </vs-col>
        <vs-divider />
        <!-- Cycles requires -->
        <vs-col vs-type="flex" vs-justify="center" vs-align="center">
          <h5 class="mb-4">
            {{
            $t(resources.CyclesRequired.i18n) ||
            resources.CyclesRequired.name
            }}:
            <strong>{{ cyclesRequired }}</strong>
          </h5>
        </vs-col>

        <!-- Cycles total made -->
        <vs-col vs-type="flex" vs-justify="center" vs-align="center">
          <h5 class="mb-4">
            {{ $t(resources.CyclesTotal.i18n) || resources.CyclesTotal.name }}:
            <strong>{{ totalCycles }}</strong>
          </h5>
        </vs-col>

        <!-- Percent -->
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" class="mb-2">
          <vs-progress :height="12" :percent="percent" color="primary"></vs-progress>
          {{ percent }}%
        </vs-col>
      </vs-row>
    </vx-card>

    <!-- POPUP -->
    <div class="demo-alignment">
      <vs-popup
        :title="
          $t(resources.PersonalEnrollees.i18n) ||
            resources.PersonalEnrollees.name
        "
        :active.sync="popupActive2"
      >
        <vs-table :data="enrollers">
          <template slot="thead">
            <vs-th>
              {{
              $t(resources.DistributorID.i18n) || resources.DistributorID.name
              }}
            </vs-th>
            <vs-th>{{ $t(resources.Name.i18n) || resources.Name.name }}</vs-th>
            <vs-th>
              {{
              $t(resources.Status.i18n) || resources.Status.name
              }}
            </vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].distId">
                {{
                data[indextr].distId
                }}
              </vs-td>
              <vs-td :data="data[indextr].name">{{ data[indextr].name }}</vs-td>
              <vs-td :data="data[indextr].status">
                {{
                data[indextr].status
                }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <div class="vx-row">
          <div class="vx-col w-full text-right">
            <vs-button
              color="rgb(174,174,174)"
              type="filled"
              @click="popupActive2 = false"
            >{{ $t(resources.Close.i18n) || resources.Close.name }}</vs-button>
          </div>
        </div>
      </vs-popup>

      <vs-popup
        :title="
          ($t(resources.PersonalEnrollees.i18n) ||
            resources.PersonalEnrollees.name) +
            ' (' +
            titlePopup3 +
            ')'
        "
        :active.sync="popupActive3"
      >
        <vs-table :data="enrollersSide">
          <template slot="thead">
            <vs-th>
              {{
              $t(resources.DistributorID.i18n) || resources.DistributorID.name
              }}
            </vs-th>
            <vs-th>{{ $t(resources.Name.i18n) || resources.Name.name }}</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].BQD_Source_Dist_ID">
                {{
                data[indextr].BQD_Source_Dist_ID
                }}
              </vs-td>
              <vs-td :data="data[indextr].BQD_Source_Name">
                {{
                data[indextr].BQD_Source_Name
                }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <div class="vx-row">
          <div class="vx-col w-full text-right">
            <vs-button
              color="rgb(174,174,174)"
              type="filled"
              @click="popupActive3 = false"
            >{{ $t(resources.Close.i18n) || resources.Close.name }}</vs-button>
          </div>
        </div>
      </vs-popup>
    </div>
  </div>
</template>
<script>
import ranks from "@/assets/resources/ranks.js";
import resources from "@/i18n/resources.js";
import axios from "axios";

export default {
  props: {
    itaCode: String,
    period: String
  },
  data() {
    return {
      resources: resources,
      rankId: 0,
      ranks: ranks,
      cyclesRequired: 0,
      totalCycles: 0,
      percent: 100,
      enrolledRight: 0,
      enrolledLeft: 0,
      enrolledTotal: 0,
      enrollers: "",
      enrollersSide: "",
      enrolledRequired: 0,
      titlePopup3: "",
      nroCenters: 0,
      popupActive2: false,
      popupActive3: false,
      currentPeriod: JSON.parse(localStorage.getItem("periods")).PERIOD[0]
    };
  },
  created: function() {
    this.getBQRSummary();
  },
  methods: {
    changeRanks(value) {
      let rank = this.ranks.find(ranks => ranks.value == value);
      this.cyclesRequired = parseInt(rank.cycle);
      this.enrolledRequired = parseInt(rank.enrolled);
      this.percent = ((this.totalCycles * 100) / this.cyclesRequired).toFixed(
        2
      );
    },

    async getBQRSummary() {
      await axios({
        method: "POST",
        url: process.env.VUE_APP_OVAPI + "getBQRSummary",
        data: {
          infotoken: localStorage.getItem("tokenInfo"),
          distid: this.itaCode,
          period: this.period == "" ? this.currentPeriod : this.period
        },
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("loggedIn")
        }
      }).then(
        result => {
          this.setInformation(result.data.CURRENTVALUES);
        },
        error => {
          this.$vs.notify({
            title: "Error",
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    setInformation(data) {
      //Total cycles
      this.totalCycles = data[1];

      //Enrolled left
      this.enrolledLeft = data[2];

      //Enrolled left
      this.enrolledRight = data[3];

      //Enrolled total
      this.enrolledTotal = data[4];

      let rank = this.ranks.filter(ranks => ranks.cycle <= this.totalCycles);
      rank = rank[rank.length - 1];
      this.enrolledRequired = parseInt(rank.enrolled);
      this.rankId = parseInt(rank.value);
      this.cyclesRequired = parseInt(rank.cycle);
      this.nroCenters = localStorage.getItem("numberCenters");
    },

    //get businesses centers
    async getEnrollers() {
      this.$vs.loading;
      await axios({
        method: "POST",
        url: process.env.VUE_APP_OVAPI + "genealogyDetailBySavedQuery",
        data: {
          infotoken: localStorage.getItem("tokenInfo"),
          distid: this.itaCode,
          group: "MBTree",
          qryid: "SponsorDownline",
          filters: "status!=C&enroller=" + this.itaCode,
          sortFields: ""
        },
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("loggedIn")
        }
      }).then(
        result => {
          var data = result.data;
          this.setEnrollers(data);
        },
        error => {
          this.$vs.notify({
            title: "Error",
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    setEnrollers(data) {
      let enrollers = "[";
      let info = data.DATA;
      for (var i = 0; i < data.ROWCOUNT; i++) {
        enrollers = enrollers.concat(
          '{"distId":"' +
            info.DIST_ID[i] +
            '","name":"' +
            info.NAME[i] +
            '","status":"' +
            info.STATUS[i] +
            '"},'
        );
      }
      enrollers = enrollers.substring(0, enrollers.length - 1) + "]";
      let obj = JSON.parse(enrollers);
      this.deleteDuplicate(obj);
    },

    deleteDuplicate(obj) {
      var enrollers = {};
      obj.forEach(function(item) {
        var enroller = (enrollers[item.distId] = enrollers[item.name] || {});
        enroller[item.name] = true;
      });
      var json = "[";
      for (var distId in enrollers) {
        for (var name in enrollers[distId]) {
          let status = obj.find(obj => obj.distId == distId).status;
          json = json.concat(
            '{"distId":"' +
              distId +
              '","name":"' +
              name +
              '","status":"' +
              status +
              '"},'
          );
        }
      }
      json = json.substring(0, json.length - 1) + "]";
      var object = JSON.parse(json);
      this.enrollers = object.filter(object => object.distId != this.itaCode);
      this.popupActive2 = true;
    },

    async getBQDetail(fieldname) {
      await axios({
        method: "POST",
        url: process.env.VUE_APP_OVAPI + "getBQDetail",
        data: {
          infotoken: localStorage.getItem("tokenInfo"),
          distid: this.itaCode,
          fieldname: fieldname,
          period: this.period
        },
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("loggedIn")
        }
      }).then(
        result => {
          this.enrollersSide = result.data.CURRENT;
          this.titlePopup3 =
            fieldname == "WRK27" ? this.$i18n.t("Left") : this.$i18n.t("Right");
          this.popupActive3 = true;
        },
        error => {
          this.$vs.notify({
            title: "Error",
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    }
  },
  components: {}
};
</script>
